/**
 * @generated SignedSource<<19c12a4a94906c99d88eac625f06a4b9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ABTestPatternEditPageContainer_Query$variables = {
  slug: string;
  groupSlug: string;
};
export type ABTestPatternEditPageContainer_QueryVariables = ABTestPatternEditPageContainer_Query$variables;
export type ABTestPatternEditPageContainer_Query$data = {
  readonly abtestScenarioPage: {
    readonly page: {
      readonly id: string;
    };
    readonly " $fragmentSpreads": FragmentRefs<"ABTestPatternEditScreenContainer_abtestScenarioPage">;
  } | null;
  readonly abtestScenarioPageGroup: {
    readonly " $fragmentSpreads": FragmentRefs<"ABTestPatternEditScreenContainer_abtestScenarioPageGroup">;
  } | null;
};
export type ABTestPatternEditPageContainer_QueryResponse = ABTestPatternEditPageContainer_Query$data;
export type ABTestPatternEditPageContainer_Query = {
  variables: ABTestPatternEditPageContainer_QueryVariables;
  response: ABTestPatternEditPageContainer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "groupSlug"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "slug"
},
v2 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Page",
  "kind": "LinkedField",
  "name": "page",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "groupSlug"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "weight",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ABTestPatternEditPageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "AbtestScenarioPage",
        "kind": "LinkedField",
        "name": "abtestScenarioPage",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ABTestPatternEditScreenContainer_abtestScenarioPage"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "AbtestScenarioPageGroup",
        "kind": "LinkedField",
        "name": "abtestScenarioPageGroup",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ABTestPatternEditScreenContainer_abtestScenarioPageGroup"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ABTestPatternEditPageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "AbtestScenarioPage",
        "kind": "LinkedField",
        "name": "abtestScenarioPage",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v3/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          },
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "linkUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isInheritQuery",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AbtestScenarioPageContentConnection",
            "kind": "LinkedField",
            "name": "abtestScenarioPageContents",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AbtestScenarioPageContentEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "imageUrl",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "beforeSrc",
                            "storageKey": null
                          }
                        ],
                        "type": "AbtestScenarioPageContentScenario",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v4/*: any*/),
                        "type": "AbtestScenarioPageContentRedirect",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "targetType",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "actionType",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "targetValue",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "targetAttribute",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "replaceValue",
                            "storageKey": null
                          }
                        ],
                        "type": "AbtestScenarioPageContentTextReplace",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v4/*: any*/),
                        "type": "Node",
                        "abstractKey": "__isNode"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "AbtestScenarioPageGroup",
        "kind": "LinkedField",
        "name": "abtestScenarioPageGroup",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deliverKind",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AbtestScenarioPage",
            "kind": "LinkedField",
            "name": "originalAbtestScenarioPage",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Page",
            "kind": "LinkedField",
            "name": "page",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sortNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Funnel",
                "kind": "LinkedField",
                "name": "funnel",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "siteUrl",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "71fe65fe8e2998977c9959172fa3e58d",
    "id": null,
    "metadata": {},
    "name": "ABTestPatternEditPageContainer_Query",
    "operationKind": "query",
    "text": "query ABTestPatternEditPageContainer_Query(\n  $slug: String!\n  $groupSlug: String!\n) {\n  abtestScenarioPage(slug: $slug) {\n    page {\n      id\n    }\n    ...ABTestPatternEditScreenContainer_abtestScenarioPage\n    id\n  }\n  abtestScenarioPageGroup(slug: $groupSlug) {\n    ...ABTestPatternEditScreenContainer_abtestScenarioPageGroup\n    id\n  }\n}\n\nfragment ABTestPatternEditScreenContainer_abtestScenarioPage on AbtestScenarioPage {\n  id\n  title\n  slug\n  kind\n  weight\n  linkUrl\n  isInheritQuery\n  page {\n    id\n  }\n  abtestScenarioPageContents {\n    edges {\n      node {\n        __typename\n        ... on AbtestScenarioPageContentScenario {\n          id\n          imageUrl\n          beforeSrc\n        }\n        ... on AbtestScenarioPageContentRedirect {\n          id\n        }\n        ... on AbtestScenarioPageContentTextReplace {\n          id\n          targetType\n          actionType\n          targetValue\n          targetAttribute\n          replaceValue\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment ABTestPatternEditScreenContainer_abtestScenarioPageGroup on AbtestScenarioPageGroup {\n  id\n  title\n  deliverKind\n  originalAbtestScenarioPage {\n    id\n    weight\n  }\n  page {\n    name\n    kind\n    sortNumber\n    url\n    funnel {\n      name\n      siteUrl\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ab4a61dcc42e004cb3b6e75b81f61338";

export default node;
