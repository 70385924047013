import { useToast } from "@chakra-ui/toast";
import { FC, useCallback, useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { PatternContentJudgeStatus } from "~/src/__generated__/schema";
import { JudgementListPage_AbtestJudgeMentMutation } from "~/src/__relay_artifacts__/JudgementListPage_AbtestJudgeMentMutation.graphql";
import { JudgementListPage_PopupJudgeMentMutation } from "~/src/__relay_artifacts__/JudgementListPage_PopupJudgeMentMutation.graphql";
import { JudgementListPage_viewer$key } from "~/src/__relay_artifacts__/JudgementListPage_viewer.graphql";
import { PageLayout } from "~/src/components/features/global/PageLayout";
import { useMutationCommit } from "~/src/lib/react-relay";

import { JudgementListSection } from "./JudgementListSection";

export type Props = {
  userRef: JudgementListPage_viewer$key;
};

const abtestMutation = graphql`
  mutation JudgementListPage_AbtestJudgeMentMutation(
    $input: UpdateAbtestScenarioPageJudgeStatusInput!
  ) {
    updateAbtestScenarioPageJudgeStatus(input: $input) {
      abtestScenarioPage {
        id
        judgeStatus
      }
    }
  }
`;

const popupMutation = graphql`
  mutation JudgementListPage_PopupJudgeMentMutation(
    $input: UpdatePopupJudgeStatusInput!
  ) {
    updatePopupJudgeStatus(input: $input) {
      popup {
        id
        judgeStatus
      }
    }
  }
`;

const fragment = graphql`
  fragment JudgementListPage_viewer on User {
    wipAbtestScenarioPages(startDate: $startDate, endDate: $endDate) {
      edges {
        node {
          id
          title
          abtestScenarioPageGroup {
            title
          }
          abtestScenarioPageContents {
            edges {
              node {
                ... on AbtestScenarioPageContentScenario {
                  imageUrl
                  image
                }
              }
            }
          }
          page {
            name
            url
            funnel {
              name
            }
          }
        }
      }
    }
    wipPopups(startDate: $startDate, endDate: $endDate) {
      edges {
        node {
          id
          name
          popupGroup {
            name
          }
          popupImages {
            imageUrl
            image
          }
          page {
            name
            url
            funnel {
              name
            }
          }
        }
      }
    }
  }
`;

export const JudgementListPage: FC<Props> = ({ userRef }) => {
  const toast = useToast();
  const user = useFragment(fragment, userRef);

  const abtestMutate =
    useMutationCommit<JudgementListPage_AbtestJudgeMentMutation>(
      abtestMutation
    );

  const popupMutate =
    useMutationCommit<JudgementListPage_PopupJudgeMentMutation>(popupMutation);

  const handleSubmitByAbtest = useCallback(
    async (id: string, status: PatternContentJudgeStatus) => {
      try {
        const res = await abtestMutate({
          variables: {
            input: {
              abtestScenarioPageId: id,
              judgeStatus: status,
            },
          },
        });
        const updateAbtest =
          res.updateAbtestScenarioPageJudgeStatus?.abtestScenarioPage;
        if (!updateAbtest) {
          throw new Error("assertion failed");
        }
        toast({
          title: "承認ステータスを更新しました",
          status: "success",
        });
      } catch (err) {
        toast({
          title: "承認ステータスの更新に失敗しました",
          status: "error",
        });
      }
    },
    [toast, abtestMutate]
  );

  const handleSubmitByPopup = useCallback(
    async (id: string, status: PatternContentJudgeStatus) => {
      try {
        const res = await popupMutate({
          variables: {
            input: {
              popupId: id,
              judgeStatus: status,
            },
          },
        });
        const updatePopup = res.updatePopupJudgeStatus?.popup;
        if (!updatePopup) {
          throw new Error("assertion failed");
        }
        toast({
          title: "承認ステータスを更新しました",
          status: "success",
        });
      } catch (err) {
        toast({
          title: "承認ステータスの更新に失敗しました",
          status: "error",
        });
      }
    },
    [toast, popupMutate]
  );

  const popups = useMemo(() => {
    const edges = user.wipPopups.edges || [];
    return edges.map((edge) => {
      const node = edge?.node;
      if (!node) throw new Error("assertion failed");
      return {
        id: node.id,
        targetName: `${node.page?.funnel.name || "no funnel"}/${
          node.page?.name || "no page"
        }/${node.popupGroup.name}/${node.name}`,
        targetPage: node.page?.url || "",
        images: node.popupImages.map((popupImage) => popupImage.imageUrl || ""),
      };
    });
  }, [user]);

  const abtests = useMemo(() => {
    const edges = user.wipAbtestScenarioPages.edges || [];
    return edges.map((edge) => {
      const node = edge?.node;
      if (!node) throw new Error("assertion failed");
      const abtestScenarioPageContentEdges =
        node.abtestScenarioPageContents.edges || [];
      const images = abtestScenarioPageContentEdges.map(
        (abtestScenarioPageContentEdge) => {
          const abtestScenarioPageContentNode =
            abtestScenarioPageContentEdge?.node;
          if (!abtestScenarioPageContentNode)
            throw new Error("assertion failed");
          return abtestScenarioPageContentNode.imageUrl || "";
        }
      );
      return {
        id: node.id,
        targetName: `${node.page?.funnel.name || "no funnel"}/${
          node.page?.name || "no page"
        }/${node.abtestScenarioPageGroup.title}/${node.title}`,
        targetPage: node.page?.url || "",
        images,
      };
    });
  }, [user]);

  return (
    <>
      <PageLayout title="ポップアップ広告承認対象リスト">
        {popups.map((popup, index) => (
          <JudgementListSection
            key={index}
            id={popup.id}
            targetName={popup.targetName}
            targetPage={popup.targetPage}
            images={popup.images}
            onSubmit={handleSubmitByPopup}
          />
        ))}
      </PageLayout>

      <PageLayout title="ABテスト広告承認対象リスト">
        {abtests.map((abtest, index) => (
          <JudgementListSection
            key={index}
            id={abtest.id}
            targetName={abtest.targetName}
            targetPage={abtest.targetPage}
            images={abtest.images}
            onSubmit={handleSubmitByAbtest}
          />
        ))}
      </PageLayout>
    </>
  );
};
