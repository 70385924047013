import { FC } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";

import { PopupSetListPageContainer_Query } from "~/src/__relay_artifacts__/PopupSetListPageContainer_Query.graphql";

import { PopupSetList } from "./presentations";

export type Props = {};

const query = graphql`
  query PopupSetListPageContainer_Query(
    $siteSlug: String!
    $popupGroupSetId: ID!
  ) {
    site(slug: $siteSlug) {
      id
      slug
      name
      popupGroupSet(id: $popupGroupSetId) {
        ...PopupSetListPage_popupGroupSet
      }
    }
  }
`;

export const PopupSetListPageContainer: FC<Props> = () => {
  const { siteSlug = "", popupGroupSetId = "" } = useParams();
  const { site } = useLazyLoadQuery<PopupSetListPageContainer_Query>(query, {
    siteSlug,
    popupGroupSetId,
  });

  return (
    <PopupSetList siteSlug={site.slug} popupGroupSetRef={site.popupGroupSet} />
  );
};
