import { Box } from "@chakra-ui/react";

import { chakraFactory } from "~/src/lib/chakra-ui";

import { TABLE_ROW_HEIGHT_PX } from "../constants";
import { rowStyles } from "../styles";

type Props = {
  withColumnNumbersRow?: boolean;
};

export const TableHeaderRow = chakraFactory<Props>(Box, {
  baseStyle: (props) => ({
    ...rowStyles,
    position: "sticky",
    top: props.withColumnNumbersRow ? `${TABLE_ROW_HEIGHT_PX}px` : 0,
    zIndex: 2,
    backgroundColor: "#FAFAFA",
  }),
});
