/**
 * @generated SignedSource<<1491a98f7871d0039fe0dcf2b063c63b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExcludedIPListTabContainer_site$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ExcludedIPListTab_site">;
  readonly " $fragmentType": "ExcludedIPListTabContainer_site";
};
export type ExcludedIPListTabContainer_site = ExcludedIPListTabContainer_site$data;
export type ExcludedIPListTabContainer_site$key = {
  readonly " $data"?: ExcludedIPListTabContainer_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExcludedIPListTabContainer_site">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExcludedIPListTabContainer_site",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExcludedIPListTab_site"
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "1fbb416354c3e79be42b771e6499006f";

export default node;
