/**
 * @generated SignedSource<<2ca13833e2679d421ea2caee3fcf0b4c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DayOfWeek = "SUN" | "MON" | "TUE" | "WED" | "THU" | "FRI" | "SUT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PopupGroupDeliverDayOfWeeks_popupGroup$data = {
  readonly startDate: string | null;
  readonly startTime: string | null;
  readonly endDate: string | null;
  readonly endTime: string | null;
  readonly deliverDayOfWeeks: ReadonlyArray<{
    readonly weekOfMonth: number | null;
    readonly daysOfWeek: ReadonlyArray<DayOfWeek>;
  }>;
  readonly " $fragmentType": "PopupGroupDeliverDayOfWeeks_popupGroup";
};
export type PopupGroupDeliverDayOfWeeks_popupGroup = PopupGroupDeliverDayOfWeeks_popupGroup$data;
export type PopupGroupDeliverDayOfWeeks_popupGroup$key = {
  readonly " $data"?: PopupGroupDeliverDayOfWeeks_popupGroup$data;
  readonly " $fragmentSpreads": FragmentRefs<"PopupGroupDeliverDayOfWeeks_popupGroup">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PopupGroupDeliverDayOfWeeks_popupGroup",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DeliverDayOfWeek",
      "kind": "LinkedField",
      "name": "deliverDayOfWeeks",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "weekOfMonth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "daysOfWeek",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PopupGroup",
  "abstractKey": null
};

(node as any).hash = "88614f86ffeef9f249c81e0a909d9eb7";

export default node;
