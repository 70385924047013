import { HStack, VStack } from "@chakra-ui/react";
import { FilterAltOutlined as FilterAltOutlinedIcon } from "@mui/icons-material";
import { Form, Formik } from "formik";
import { uniq } from "ramda";
import { FC, useCallback, useMemo } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";
import * as yup from "yup";

import { GraphQLEnums } from "~/src/__generated__/GraphQLEnums";
import { ABTestPatternNameReportFilterButton_Query } from "~/src/__relay_artifacts__/ABTestPatternNameReportFilterButton_Query.graphql";
import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormikFormButtons } from "~/src/components/common/forms/FormikFormButtons";
import { FormikMultipleSelectField } from "~/src/components/common/forms/FormikMultipleSelectField";
import { FormikSelectField } from "~/src/components/common/forms/FormikSelectField";
import { IconButton } from "~/src/components/common/IconButton";
import { Modal } from "~/src/components/common/Modal";
import { FilteredTag } from "~/src/components/features/filter";
import { useOpenState } from "~/src/lib/hooks";

import { ABTestReportFilterFormValues as FormValues } from "./types";

export type Props = {
  filterValues: FormValues;
  onSubmit: (values: FormValues) => Promise<void> | void;
};

const query = graphql`
  query ABTestPatternNameReportFilterButton_Query($slug: String!) {
    sources {
      edges {
        node {
          id
          name
        }
      }
    }
    site(slug: $slug) {
      abtestScenarioPages {
        edges {
          node {
            id
            title
          }
        }
      }
    }
  }
`;

const validationSchema = yup.object({
  sourceId: yup.string().nullable(),
  device: yup.string().nullable(),
  abtestScenarioPageTitles: yup.array().of(yup.string()).nullable(),
});

export const ABTestPatternNameReportFilterButton: FC<Props> = ({
  filterValues,
  onSubmit,
}) => {
  const { siteSlug = "" } = useParams();
  const { isOpen, onClose, onOpen } = useOpenState();

  const data = useLazyLoadQuery<ABTestPatternNameReportFilterButton_Query>(
    query,
    {
      slug: siteSlug,
    }
  );

  const sourceOptions = useMemo(
    () => [
      { label: "すべて", value: null },
      ...(data.sources.edges || []).map((edge) => {
        const node = edge?.node;
        if (!node) throw new Error("assertion failed");
        return { label: node.name, value: node.id };
      }),
    ],
    [data.sources.edges]
  );

  const deviceOptions = useMemo(
    () => [{ label: "すべて", value: null }, ...GraphQLEnums.Device],
    []
  );

  const abtestScenarioPageOptions = useMemo(() => {
    const edges = data.site.abtestScenarioPages.edges || [];
    return uniq(
      edges.map((edge) => {
        const node = edge?.node;
        if (!node) throw new Error("assertion failed");
        return node.title || "";
      })
    )
      .map((title) => ({ label: title, value: title }))
      .filter((option) => option.value !== "");
  }, [data.site.abtestScenarioPages]);

  const handleSubmit = useCallback(
    (values: FormValues) => {
      onSubmit(values);
      onClose();
    },
    [onClose, onSubmit]
  );

  return (
    <HStack>
      {filterValues.abtestScenarioPageTitles.length > 0 &&
        filterValues.abtestScenarioPageTitles.map(
          (abtestScenarioPageTitle, index) => (
            <FilteredTag
              key={index}
              name="ABテスト"
              value={abtestScenarioPageTitle}
              options={abtestScenarioPageOptions}
            />
          )
        )}
      {filterValues.sourceId && (
        <FilteredTag
          name="流入元"
          value={filterValues.sourceId}
          options={sourceOptions}
        />
      )}
      {filterValues.device && (
        <FilteredTag
          name="デバイス"
          value={filterValues.device}
          options={deviceOptions}
        />
      )}
      <IconButton
        label="フィルタ"
        size="24px"
        icon={<FilterAltOutlinedIcon htmlColor="#282828" />}
        isActive={
          Object.values(filterValues).some((value) => value != null) || isOpen
        }
        onClick={onOpen}
      />
      <Modal title="フィルタ" isOpen={isOpen} onClose={onClose}>
        <Formik<FormValues>
          enableReinitialize
          initialValues={filterValues}
          validationSchema={validationSchema}
          validateOnMount={false}
          validateOnChange={false}
          onSubmit={handleSubmit}
        >
          <Form>
            <VStack spacing="16px">
              <FormControl label="ABテスト">
                <FormikMultipleSelectField
                  name="abtestScenarioPageTitles"
                  options={abtestScenarioPageOptions}
                />
              </FormControl>
              <FormControl label="流入元">
                <FormikSelectField name="sourceId" options={sourceOptions} />
              </FormControl>
              <FormControl label="デバイス">
                <FormikSelectField name="device" options={deviceOptions} />
              </FormControl>
            </VStack>
            <FormikFormButtons onCancelClick={onClose} />
          </Form>
        </Formik>
      </Modal>
    </HStack>
  );
};
