/**
 * @generated SignedSource<<1e817215691454b12cd11370619dd1c8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UnarchiveArchivableInput = {
  clientMutationId?: string | null;
  archivableId: string;
};
export type useUnarchiveHandler_Mutation$variables = {
  input: UnarchiveArchivableInput;
  connections: ReadonlyArray<string>;
};
export type useUnarchiveHandler_MutationVariables = useUnarchiveHandler_Mutation$variables;
export type useUnarchiveHandler_Mutation$data = {
  readonly unarchiveArchivable: {
    readonly archivable: {
      readonly id: string;
      readonly isArchive: boolean;
    };
  } | null;
};
export type useUnarchiveHandler_MutationResponse = useUnarchiveHandler_Mutation$data;
export type useUnarchiveHandler_Mutation = {
  variables: useUnarchiveHandler_MutationVariables;
  response: useUnarchiveHandler_Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isArchive",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useUnarchiveHandler_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "UnarchiveArchivablePayload",
        "kind": "LinkedField",
        "name": "unarchiveArchivable",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "archivable",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useUnarchiveHandler_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "UnarchiveArchivablePayload",
        "kind": "LinkedField",
        "name": "unarchiveArchivable",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "archivable",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "deleteEdge",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id",
                "handleArgs": [
                  {
                    "kind": "Variable",
                    "name": "connections",
                    "variableName": "connections"
                  }
                ]
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d14fb7bd4cded050ab6cd5adb1c00829",
    "id": null,
    "metadata": {},
    "name": "useUnarchiveHandler_Mutation",
    "operationKind": "mutation",
    "text": "mutation useUnarchiveHandler_Mutation(\n  $input: UnarchiveArchivableInput!\n) {\n  unarchiveArchivable(input: $input) {\n    archivable {\n      __typename\n      id\n      isArchive\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "90b33640caaae4698dd7cd7b62bd4958";

export default node;
