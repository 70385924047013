import { array, boolean, date, number, object, string } from "yup";

import {
  AgeRange,
  Browser,
  ChatbotGroupDeliverUrlInput,
  ChatbotGroupExcludeReferrerInput,
  ChatbotGroupReferrerInput,
  ChatbotGroupTargetCookieInput,
  DeliverDayOfWeekInput,
  DeliverKind,
  Device,
  DistributionStatus,
  Gender,
  Platform,
  VisitMatch,
} from "~/src/__generated__/schema";

export type FormValues = {
  name: string;
  funnelId: string;
  pageId: string;
  repeat: boolean;
  startDate: Date | null;
  endDate: Date | null;
  startTime: Date | null;
  endTime: Date | null;
  devices: Device[];
  platforms: Platform[];
  browsers: Browser[];
  genders: Gender[];
  ageRanges: AgeRange[];
  deliverKind: DeliverKind;
  sourceIds: string[];
  referrers: ChatbotGroupReferrerInput[];
  excludeReferrers: ChatbotGroupExcludeReferrerInput[];
  visitCount: number | null;
  visitMatchType: VisitMatch;
  deliverTargetUrls: ChatbotGroupDeliverUrlInput[];
  deliverExcludeUrls: ChatbotGroupDeliverUrlInput[];
  deliverTargetLabelIds: string[];
  deliverExcludeLabelIds: string[];
  targetCookies: ChatbotGroupTargetCookieInput[];
  deliverDayOfWeeks: DeliverDayOfWeekInput[];
  status: DistributionStatus;
};

export const validationSchema = object({
  name: string().required().max(50),
  funnelId: string().required(),
  pageId: string().required(),
  repeat: boolean().required(),
  startDatetime: date().nullable(),
  endDatetime: date().nullable(),
  startTime: string().nullable(),
  endTime: string().nullable(),
  devices: array().of(string()),
  platforms: array().of(string()),
  sourceIds: array().of(string()),
  browsers: array().of(string()),
  genders: array().of(string()),
  ageRanges: array().of(string()),
  deliverKind: string().required(),
  visitCount: number().nullable(),
  visitMatchType: string().required(),
  deliverTargetLabelIdIds: array().of(string()),
  deliverExcludeLabelIdIds: array().of(string()),
});
