/**
 * @generated SignedSource<<f59febc7c1ece417bfa075d61ed2aaf4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type HasForm = "HAS_FORM" | "NO_FORM" | "%future added value";
export type QueryMatch = "EQUAL_MATCH" | "INCLUDING_MATCH" | "PREFIX_MATCH" | "%future added value";
export type UrlMatch = "EQUAL_TYPE" | "HAS_ID_TYPE" | "EQUAL_OR_HAS_ID_TYPE" | "STARTS_WITH_PREFIX_MATCH" | "REGEX_MATCH_TYPE" | "%future added value";
export type UserRole = "SUPER" | "ADMIN_ALL" | "ADMIN" | "JUDGE" | "CLIENT" | "SAAS_CLIENT" | "%future added value";
export type FunnelEditFormContainer_Query$variables = {
  funnelSlug: string;
};
export type FunnelEditFormContainer_QueryVariables = FunnelEditFormContainer_Query$variables;
export type FunnelEditFormContainer_Query$data = {
  readonly viewer: {
    readonly role: UserRole;
  };
  readonly funnel: {
    readonly id: string;
    readonly slug: string;
    readonly name: string;
    readonly isExperiment: boolean;
    readonly landingPage: {
      readonly id: string;
      readonly name: string;
      readonly url: string;
      readonly mobileUrl: string | null;
      readonly tabletUrl: string | null;
      readonly hasForm: HasForm | null;
      readonly urlMatchType: UrlMatch;
      readonly queryMatchType: QueryMatch;
      readonly sortNumber: number;
      readonly isDisableWhiteout: boolean;
    } | null;
    readonly thanksPage: {
      readonly id: string;
      readonly name: string;
      readonly url: string;
      readonly mobileUrl: string | null;
      readonly tabletUrl: string | null;
      readonly urlMatchType: UrlMatch;
      readonly sortNumber: number;
      readonly isDisableWhiteout: boolean;
    } | null;
    readonly confirmPage: {
      readonly id: string;
      readonly name: string;
      readonly url: string;
      readonly mobileUrl: string | null;
      readonly tabletUrl: string | null;
      readonly urlMatchType: UrlMatch;
      readonly sortNumber: number;
      readonly isDisableWhiteout: boolean;
    } | null;
    readonly formPage: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly url: string;
      readonly mobileUrl: string | null;
      readonly tabletUrl: string | null;
      readonly urlMatchType: UrlMatch;
      readonly sortNumber: number;
      readonly isDisableWhiteout: boolean;
    }> | null;
  };
};
export type FunnelEditFormContainer_QueryResponse = FunnelEditFormContainer_Query$data;
export type FunnelEditFormContainer_Query = {
  variables: FunnelEditFormContainer_QueryVariables;
  response: FunnelEditFormContainer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "funnelSlug"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mobileUrl",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tabletUrl",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urlMatchType",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sortNumber",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isDisableWhiteout",
  "storageKey": null
},
v10 = [
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/)
],
v11 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "slug",
      "variableName": "funnelSlug"
    }
  ],
  "concreteType": "Funnel",
  "kind": "LinkedField",
  "name": "funnel",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isExperiment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Page",
      "kind": "LinkedField",
      "name": "landingPage",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasForm",
          "storageKey": null
        },
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "queryMatchType",
          "storageKey": null
        },
        (v8/*: any*/),
        (v9/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Page",
      "kind": "LinkedField",
      "name": "thanksPage",
      "plural": false,
      "selections": (v10/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Page",
      "kind": "LinkedField",
      "name": "confirmPage",
      "plural": false,
      "selections": (v10/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Page",
      "kind": "LinkedField",
      "name": "formPage",
      "plural": true,
      "selections": (v10/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FunnelEditFormContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      (v11/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FunnelEditFormContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      (v11/*: any*/)
    ]
  },
  "params": {
    "cacheID": "3ee7e2c83f97161dc579cd1fdb4d20c3",
    "id": null,
    "metadata": {},
    "name": "FunnelEditFormContainer_Query",
    "operationKind": "query",
    "text": "query FunnelEditFormContainer_Query(\n  $funnelSlug: String!\n) {\n  viewer {\n    role\n    id\n  }\n  funnel(slug: $funnelSlug) {\n    id\n    slug\n    name\n    isExperiment\n    landingPage {\n      id\n      name\n      url\n      mobileUrl\n      tabletUrl\n      hasForm\n      urlMatchType\n      queryMatchType\n      sortNumber\n      isDisableWhiteout\n    }\n    thanksPage {\n      id\n      name\n      url\n      mobileUrl\n      tabletUrl\n      urlMatchType\n      sortNumber\n      isDisableWhiteout\n    }\n    confirmPage {\n      id\n      name\n      url\n      mobileUrl\n      tabletUrl\n      urlMatchType\n      sortNumber\n      isDisableWhiteout\n    }\n    formPage {\n      id\n      name\n      url\n      mobileUrl\n      tabletUrl\n      urlMatchType\n      sortNumber\n      isDisableWhiteout\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8c12e946e3f39139aef37369789128cc";

export default node;
