import {
  Textarea as ChakraTextarea,
  SystemStyleObject,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ChangeEventHandler, FC, FocusEventHandler } from "react";

import { chakraFactory } from "~/src/lib/chakra-ui";

export type Props = {
  className?: string;
  name: string;
  mode: "outline" | "fill";
  value: string;
  disabled?: boolean;
  placeholder?: string;
  error?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  resize?: "both" | "horizontal" | "vertical" | "none";
};

const getTextareaProps = ({
  mode,
  error,
  resize,
}: Pick<Props, "mode" | "error" | "resize">): SystemStyleObject => {
  switch (mode) {
    case "fill": {
      const borderColor = error ? "#E21010" : "#D0D0D0";
      return {
        background: "#FAFAFA",
        border: "1px solid #D0D0D0",

        "&:focus": {
          borderColor,
          boxShadow: `0 0 0 1px ${borderColor}`,
          resize: resize ? resize : "none",
        },
      };
    }
    case "outline":
    default: {
      const borderColor = error ? "#E21010" : "#DADADA";
      return {
        border: "1px solid #DADADA",

        "&:focus": {
          borderColor,
          boxShadow: `0 0 0 1px ${borderColor}`,
          resize: resize ? resize : "none",
        },
      };
    }
  }
};

type StyledTextareaProps = Pick<Props, "mode" | "error" | "resize">;

const StyledTextarea = chakraFactory<StyledTextareaProps>(ChakraTextarea, {
  baseStyle: (props) => ({
    ...getTextareaProps(props),
    width: "100%",
    height: "165px",
    borderRadius: "2px",
    fontSize: "13px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.04em",
    boxSizing: "border-box",
    pr: "10px",
    borderColor: props.error ? "#E21010" : undefined,

    "&::placeholder": {
      color: "#797979",
    },
  }),
});

const StyledErrorText = chakraFactory(Text, {
  baseStyle: {
    fontSize: "10px",
    lineHeight: "14px",
    display: "flex",
    alignItems: "center",
    my: 0,
    mt: "8px",
    color: "#D9534F",
    alignSelf: "start",
  },
});

export const Textarea: FC<Props> = ({
  className,
  name,
  value,
  mode,
  disabled = false,
  placeholder,
  error,
  onBlur,
  onChange,
  resize,
}) => {
  return (
    <VStack className={className} spacing="8px">
      <StyledTextarea
        name={name}
        value={value}
        mode={mode}
        placeholder={placeholder}
        disabled={disabled}
        error={error}
        onBlur={onBlur}
        onChange={onChange}
        resize={resize}
      />
      {!!error && <StyledErrorText>{error}</StyledErrorText>}
    </VStack>
  );
};
