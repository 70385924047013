import { getAuth, updateEmail } from "firebase/auth";
import { FC, useCallback, useMemo, useState } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useNavigate } from "react-router";

import { AccountSettingsPageContainer_Mutation } from "~/src/__relay_artifacts__/AccountSettingsPageContainer_Mutation.graphql";
import { AccountSettingsPageContainer_Query } from "~/src/__relay_artifacts__/AccountSettingsPageContainer_Query.graphql";
import { useUser } from "~/src/contexts";
import { useFormErrorHandler, useOpenState } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";

import { AccountSettingsPage, AccountSettingsPageProps } from "./presentations";

const query = graphql`
  query AccountSettingsPageContainer_Query {
    viewer {
      name
      company
      tel
      email
      isHoneycomb
      itpUrls
      isSecure
      isMacbeeServer
      userTag
      isCrossDomainQuery
      isCrossDomainFragment
    }
  }
`;

const mutation = graphql`
  mutation AccountSettingsPageContainer_Mutation($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        name
        company
        tel
        email
        isHoneycomb
        itpUrls
        isSecure
        isMacbeeServer
        userTag
        isCrossDomainQuery
        isCrossDomainFragment
      }
    }
  }
`;

export const AccountSettingsPageContainer: FC = () => {
  const userContext = useUser();
  if (userContext === null) throw new Error("user context must be set.");
  const { setUser } = userContext;

  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useOpenState();
  const [reAuthenticated, setReAuthenticated] = useState(false);

  const modalProps = {
    reAuthenticated,
    isOpen,
    onClose,
    handleReAuthenticationStatus: (status: boolean) =>
      setReAuthenticated(status),
  };

  const { viewer: user } = useLazyLoadQuery<AccountSettingsPageContainer_Query>(
    query,
    {}
  );

  const initialValues = useMemo<AccountSettingsPageProps["initialValues"]>(
    () => ({
      ...user,
      company: user.company || "",
      tel: user.tel || "",
      name: user.name || "",
      itpUrls: user.itpUrls || "",
      userTag: user.userTag || undefined,
      reAuthPassword: "",
    }),
    [user]
  );

  const handleCancel = useCallback(() => navigate(-1), [navigate]);
  const { onFormError } = useFormErrorHandler();

  const mutate =
    useMutationCommit<AccountSettingsPageContainer_Mutation>(mutation);

  const handleSubmit = useCallback<AccountSettingsPageProps["onSubmit"]>(
    async ({ ...values }, { setErrors }) => {
      try {
        const auth = getAuth();
        const authUser = auth.currentUser;
        if (!authUser) throw new Error("認証されたユーザーが見つかりません!");

        const emailChanged = authUser?.email !== values.email;
        if (emailChanged) {
          if (reAuthenticated) {
            // Update email on firebase
            await updateEmail(authUser, values.email);
          } else {
            onOpen();
            return false;
          }
        }
        const { reAuthPassword, ...updateValues } = values; // Remove reAuthPassword from update fields

        const res = await mutate({
          variables: {
            input: {
              ...updateValues,
            },
          },
        });
        const user = res.updateUser?.user;
        if (!user) throw new Error("assertion failed");

        // Request a new idToken after updating email on firebase and database
        if (emailChanged) {
          const forceRefresh = true;
          const newIdToken = await authUser.getIdToken(forceRefresh);
          // Update user state
          setUser({
            ...authUser, // Get other user details from auth object
            idToken: newIdToken,
            email: values.email,
          });
        }

        navigate("/account");
      } catch (err) {
        onFormError(err, setErrors);
      }
    },
    [mutate, navigate, onFormError, onOpen, reAuthenticated, setUser]
  );

  return (
    <AccountSettingsPage
      initialValues={initialValues}
      onCancelClick={handleCancel}
      onSubmit={handleSubmit}
      modalProps={{ ...modalProps }}
    />
  );
};
