import { VStack } from "@chakra-ui/react";
import { Form, Formik, FormikConfig } from "formik";
import { FC } from "react";

import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormControlGroup } from "~/src/components/common/forms/FormControlGroup";
import { FormikFormButtons } from "~/src/components/common/forms/FormikFormButtons";
import { FormikSelectField } from "~/src/components/common/forms/FormikSelectField";
import { FormikTextField } from "~/src/components/common/forms/FormikTextField";

import { FormValues, validationSchema } from "./validationSchema";

export type Props = {
  initialValues?: FormValues;
  onCancelClick: () => void;
  onSubmit: FormikConfig<FormValues>["onSubmit"];
};

const defaultInitialValues = {
  ipAddress: "",
  status: "INACTIVE",
} as const;

const statusOptions = [
  { label: "除外する", value: "ACTIVE" },
  { label: "除外しない", value: "INACTIVE" },
];

export const ExcludedIPForm: FC<Props> = ({
  initialValues = defaultInitialValues,
  onCancelClick,
  onSubmit,
}) => (
  <Formik<FormValues>
    enableReinitialize
    initialValues={initialValues}
    validationSchema={validationSchema}
    validateOnMount={false}
    validateOnChange={false}
    onSubmit={onSubmit}
  >
    <Form>
      <VStack spacing="24px" alignItems="flex-start">
        <FormControlGroup>
          <FormControl required label="IPアドレス">
            <FormikTextField type="text" mode="fill" name="ipAddress" />
          </FormControl>
          <FormControl required label="ステータス">
            <FormikSelectField name="status" options={statusOptions} />
          </FormControl>
        </FormControlGroup>
        <FormikFormButtons onCancelClick={onCancelClick} />
      </VStack>
    </Form>
  </Formik>
);
