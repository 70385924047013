/**
 * @generated SignedSource<<c124561c5b1bc35bb7713cef049e61ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AbtestScenarioPageKind = "DEFAULT" | "SCENARIO" | "REDIRECT" | "TEXT_REPLACE" | "%future added value";
export type Device = "MOBILE" | "TABLET" | "PC" | "%future added value";
export type DistributionStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type PatternContentJudgeStatus = "PENDING" | "APPROVED" | "REJECTED" | "%future added value";
export type CopyAbtestScenarioPageInput = {
  clientMutationId?: string | null;
  abtestScenarioPageId: string;
};
export type ReportAnalyzerInput = {
  startOn: string;
  endOn: string;
  device?: Device | null;
  sourceId?: string | null;
  funnelId?: string | null;
};
export type ABTestPatternTableActions_CopyMutation$variables = {
  input: CopyAbtestScenarioPageInput;
  connections: ReadonlyArray<string>;
  analyzerInput: ReportAnalyzerInput;
};
export type ABTestPatternTableActions_CopyMutationVariables = ABTestPatternTableActions_CopyMutation$variables;
export type ABTestPatternTableActions_CopyMutation$data = {
  readonly copyAbtestScenarioPage: {
    readonly abtestScenarioPage: {
      readonly id: string;
      readonly slug: string;
      readonly title: string | null;
      readonly status: DistributionStatus;
      readonly isArchive: boolean;
      readonly uniqueUserCount: number;
      readonly cvUserCount: number;
      readonly cvr: number;
      readonly averageStayTime: number;
      readonly scrollRate: number;
      readonly kind: AbtestScenarioPageKind;
      readonly judgeStatus: PatternContentJudgeStatus;
      readonly weight: number | null;
    } | null;
  } | null;
};
export type ABTestPatternTableActions_CopyMutationResponse = ABTestPatternTableActions_CopyMutation$data;
export type ABTestPatternTableActions_CopyMutation = {
  variables: ABTestPatternTableActions_CopyMutationVariables;
  response: ABTestPatternTableActions_CopyMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "analyzerInput"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v3 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v4 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "analyzerInput"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "AbtestScenarioPage",
  "kind": "LinkedField",
  "name": "abtestScenarioPage",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isArchive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v4/*: any*/),
      "kind": "ScalarField",
      "name": "uniqueUserCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v4/*: any*/),
      "kind": "ScalarField",
      "name": "cvUserCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v4/*: any*/),
      "kind": "ScalarField",
      "name": "cvr",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v4/*: any*/),
      "kind": "ScalarField",
      "name": "averageStayTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v4/*: any*/),
      "kind": "ScalarField",
      "name": "scrollRate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "judgeStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weight",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ABTestPatternTableActions_CopyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "CopyAbtestScenarioPagePayload",
        "kind": "LinkedField",
        "name": "copyAbtestScenarioPage",
        "plural": false,
        "selections": [
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ABTestPatternTableActions_CopyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "CopyAbtestScenarioPagePayload",
        "kind": "LinkedField",
        "name": "copyAbtestScenarioPage",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "abtestScenarioPage",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "AbtestScenarioPageEdge"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b1658de43fa36ed742d133945f86d6e9",
    "id": null,
    "metadata": {},
    "name": "ABTestPatternTableActions_CopyMutation",
    "operationKind": "mutation",
    "text": "mutation ABTestPatternTableActions_CopyMutation(\n  $input: CopyAbtestScenarioPageInput!\n  $analyzerInput: ReportAnalyzerInput!\n) {\n  copyAbtestScenarioPage(input: $input) {\n    abtestScenarioPage {\n      id\n      slug\n      title\n      status\n      isArchive\n      uniqueUserCount(input: $analyzerInput)\n      cvUserCount(input: $analyzerInput)\n      cvr(input: $analyzerInput)\n      averageStayTime(input: $analyzerInput)\n      scrollRate(input: $analyzerInput)\n      kind\n      judgeStatus\n      weight\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "710f5eac6e57120fd9d39f37529fd326";

export default node;
