import { Box, HStack } from "@chakra-ui/layout";
import { Text } from "@chakra-ui/react";
import { DeleteOutline as DeleteOutlineIcon } from "@mui/icons-material";
import { useField } from "formik";
import { FC, useCallback } from "react";

import { UserRole } from "~/src/__generated__/schema";
import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormikCheckboxField } from "~/src/components/common/forms/FormikCheckboxField";
import { FormikTextField } from "~/src/components/common/forms/FormikTextField";
import { IconButton } from "~/src/components/common/IconButton";
import { ImageUploadField } from "~/src/components/features/uploads";

type Props = {
  index: number;
  onDelete: () => void;
  role: UserRole;
};

export const PopupImageFormContainer: FC<Props> = ({
  index,
  onDelete,
  role,
}) => {
  const [imageUrl] = useField<string>(`popupImages.${index}.imageUrl`);

  const handleDelete = useCallback(() => {
    onDelete();
  }, [onDelete]);

  return (
    <>
      <FormControl label={`画像-${index + 1}`}>
        <HStack justifyContent={"space-between"}>
          <Box>
            <ImageUploadField
              src={imageUrl.value}
              name={`popupImages.${index}.image`}
            />
          </Box>
          <Box>
            <IconButton
              size="24px"
              // eslint-disable-next-line react/jsx-no-bind
              onClick={handleDelete}
              icon={<DeleteOutlineIcon />}
            />
          </Box>
        </HStack>
        <Text fontSize={"10px"}>
          ※ 画像推奨サイズ: 450px ✕
          330pxです。指定範囲内常時表示型の場合は、縦を100pxにすることを推奨しています。
        </Text>
      </FormControl>
      {(role === "ADMIN" ||
        role === "SUPER" ||
        role === "ADMIN_ALL" ||
        role === "CLIENT") && (
        <FormControl label="YDAコード">
          <FormikTextField
            name={`popupImages.${index}.honeycombCode`}
            type="text"
            mode="fill"
            placeholder="iframeで読み込むURL"
          />
        </FormControl>
      )}
      <FormControl label="画像が表示されない場合の表記">
        <FormikTextField
          type="text"
          mode="fill"
          name={`popupImages.${index}.alt`}
        />
      </FormControl>
      <FormControl label="画像クリック時のURL">
        <FormikCheckboxField
          name={`popupImages.${index}.isInheritQuery`}
          label="クエリパラメータを引き継ぐ（※ページの?以降の文字を引き継ぎます）"
        />
        <FormikTextField
          type="text"
          mode="fill"
          name={`popupImages.${index}.linkUrl`}
          placeholder="https://"
        />
        <Text fontSize={"10px"} color={"#979797"}>
          ※ 空欄の場合はポップアップを閉じる挙動になります。
        </Text>
      </FormControl>
    </>
  );
};
