import { FC } from "react";

import { SiteListTabContainer } from "~/src/components/features/sites/SiteListTab";
import { TagTabContainer } from "~/src/components/features/sites/TagTab";
import { useTabs } from "~/src/lib/hooks";

import { SiteListPage } from "./presentations";

export const tabs = [
  { id: "sites", label: "サイト一覧" },
  { id: "tag", label: "タグ" },
] as const;

export const SiteListPageContainer: FC = () => {
  const { currentTabId, onTabChange } = useTabs(tabs);

  return (
    <SiteListPage
      tabs={tabs}
      currentTabId={currentTabId}
      onTabChange={onTabChange}
    >
      {currentTabId === "sites" && <SiteListTabContainer />}
      {currentTabId === "tag" && <TagTabContainer />}
    </SiteListPage>
  );
};
