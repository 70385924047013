import { StrictMode } from "react";
import ReactDOM from "react-dom";

import { App } from "~/src/App";

const element = document.getElementById("root");

if (!element) throw new Error("root element is not found.");

ReactDOM.createRoot(element).render(
  <StrictMode>
    <App />
  </StrictMode>
);
