import { array, object, string } from "yup";

export type PopupSetFormValues = {
  name: string;
  memo: string | null;
};

export const popupSetValidationSchema = object({
  name: string().required(),
  memo: string().nullable(),
});

export type PopupGroupLinkFormValues = {
  popupGroups: string[];
};

export const popupGroupLinkValidationSchema = object({
  popupGroups: array().of(string()),
});

export type PopupLinkFormValues = {
  popups: string[];
};

export const popupLinkValidationSchema = object({
  popups: array().of(string()),
});
