/**
 * @generated SignedSource<<880bc61ae8eee4e02f630168c3e9d82c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DistributionStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type GroupContentJudgeStatus = "PENDING" | "APPROVED" | "%future added value";
export type PageKind = "LANDING" | "FORM" | "CONFIRM" | "THANKS" | "%future added value";
export type PopupGroupLinkFormContainer_Query$variables = {
  siteSlug: string;
  searchTerm?: string | null;
  pageKind?: PageKind | null;
  status?: DistributionStatus | null;
};
export type PopupGroupLinkFormContainer_QueryVariables = PopupGroupLinkFormContainer_Query$variables;
export type PopupGroupLinkFormContainer_Query$data = {
  readonly site: {
    readonly popupGroups: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly slug: string;
          readonly name: string;
          readonly status: DistributionStatus;
          readonly judgeStatus: GroupContentJudgeStatus | null;
          readonly thumbnailImageUrl: string | null;
          readonly popups: {
            readonly totalCount: number;
          } | null;
          readonly page: {
            readonly id: string;
            readonly name: string;
            readonly kind: PageKind;
            readonly sortNumber: number;
            readonly funnel: {
              readonly id: string;
              readonly name: string;
            };
          };
        } | null;
      } | null> | null;
    };
  };
};
export type PopupGroupLinkFormContainer_QueryResponse = PopupGroupLinkFormContainer_Query$data;
export type PopupGroupLinkFormContainer_Query = {
  variables: PopupGroupLinkFormContainer_QueryVariables;
  response: PopupGroupLinkFormContainer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageKind"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchTerm"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "siteSlug"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v4 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "siteSlug"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "pageKind",
      "variableName": "pageKind"
    },
    {
      "kind": "Variable",
      "name": "searchTerm",
      "variableName": "searchTerm"
    },
    {
      "kind": "Variable",
      "name": "status",
      "variableName": "status"
    }
  ],
  "concreteType": "PopupGroupConnection",
  "kind": "LinkedField",
  "name": "popupGroups",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PopupGroupEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PopupGroup",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "slug",
              "storageKey": null
            },
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "judgeStatus",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "thumbnailImageUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PopupConnection",
              "kind": "LinkedField",
              "name": "popups",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "totalCount",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Page",
              "kind": "LinkedField",
              "name": "page",
              "plural": false,
              "selections": [
                (v5/*: any*/),
                (v6/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "kind",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sortNumber",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Funnel",
                  "kind": "LinkedField",
                  "name": "funnel",
                  "plural": false,
                  "selections": [
                    (v5/*: any*/),
                    (v6/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PopupGroupLinkFormContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "PopupGroupLinkFormContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cb17a6528f5a701c851aa14360742423",
    "id": null,
    "metadata": {},
    "name": "PopupGroupLinkFormContainer_Query",
    "operationKind": "query",
    "text": "query PopupGroupLinkFormContainer_Query(\n  $siteSlug: String!\n  $searchTerm: String\n  $pageKind: PageKind\n  $status: DistributionStatus\n) {\n  site(slug: $siteSlug) {\n    popupGroups(pageKind: $pageKind, searchTerm: $searchTerm, status: $status) {\n      edges {\n        node {\n          id\n          slug\n          name\n          status\n          judgeStatus\n          thumbnailImageUrl\n          popups {\n            totalCount\n          }\n          page {\n            id\n            name\n            kind\n            sortNumber\n            funnel {\n              id\n              name\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "d0ccba03bd36786cec4f75d92b5ca62c";

export default node;
