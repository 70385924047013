import { HStack, Text, VStack } from "@chakra-ui/react";
import { FC } from "react";

import { chakraFactory } from "~/src/lib/chakra-ui";

type Props = {
  label: string;
  datum?: {
    x: number | Date;
    y: string;
    yUnits: number;
  };
};

const StyledGroup = chakraFactory("g", {
  baseStyle: {
    pointerEvents: "none",
  },
});

const StyledForeignObject = chakraFactory("foreignObject", {
  baseStyle: {
    width: "10px",
    height: "10px",
    overflow: "visible",
  },
});

const StyledWrapperVStack = chakraFactory(VStack, {
  baseStyle: {
    alignItems: "flex-start",
    width: "280px",
    background: "#FFFFFF",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.14)",
    padding: "12px",
    fontSize: "12px",
  },
});

const StyledNumberText = chakraFactory(Text, {
  baseStyle: {
    fontSize: "16px",
  },
});

export const CustomVictoryTooltip: FC<Props> = ({ label, datum }) => (
  <StyledGroup>
    <StyledForeignObject x={100} y={0}>
      <StyledWrapperVStack spacing="16px">
        <VStack alignItems="flex-start">
          <div>{datum?.x}</div>
          <HStack>
            <p>{label}：</p>
            <StyledNumberText>{datum?.y}</StyledNumberText>
          </HStack>
        </VStack>
      </StyledWrapperVStack>
    </StyledForeignObject>
  </StyledGroup>
);
