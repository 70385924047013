/**
 * @generated SignedSource<<b93705008f0fe8a8ccaaed2bfbf1b5df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeletePopupGroupSettingInput = {
  clientMutationId?: string | null;
  popupGroupSettingId: string;
};
export type PopupGroupSetDetailLinkedPopupGroupListTableActions_Mutation$variables = {
  connections: ReadonlyArray<string>;
  input: DeletePopupGroupSettingInput;
};
export type PopupGroupSetDetailLinkedPopupGroupListTableActions_MutationVariables = PopupGroupSetDetailLinkedPopupGroupListTableActions_Mutation$variables;
export type PopupGroupSetDetailLinkedPopupGroupListTableActions_Mutation$data = {
  readonly deletePopupGroupSetting: {
    readonly deletedPopupGroupSettingId: string;
  } | null;
};
export type PopupGroupSetDetailLinkedPopupGroupListTableActions_MutationResponse = PopupGroupSetDetailLinkedPopupGroupListTableActions_Mutation$data;
export type PopupGroupSetDetailLinkedPopupGroupListTableActions_Mutation = {
  variables: PopupGroupSetDetailLinkedPopupGroupListTableActions_MutationVariables;
  response: PopupGroupSetDetailLinkedPopupGroupListTableActions_Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedPopupGroupSettingId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PopupGroupSetDetailLinkedPopupGroupListTableActions_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeletePopupGroupSettingPayload",
        "kind": "LinkedField",
        "name": "deletePopupGroupSetting",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PopupGroupSetDetailLinkedPopupGroupListTableActions_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeletePopupGroupSettingPayload",
        "kind": "LinkedField",
        "name": "deletePopupGroupSetting",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "deleteEdge",
            "key": "",
            "kind": "ScalarHandle",
            "name": "deletedPopupGroupSettingId",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1984fa284a6cbbb69b1edd955b7cd4a4",
    "id": null,
    "metadata": {},
    "name": "PopupGroupSetDetailLinkedPopupGroupListTableActions_Mutation",
    "operationKind": "mutation",
    "text": "mutation PopupGroupSetDetailLinkedPopupGroupListTableActions_Mutation(\n  $input: DeletePopupGroupSettingInput!\n) {\n  deletePopupGroupSetting(input: $input) {\n    deletedPopupGroupSettingId\n  }\n}\n"
  }
};
})();

(node as any).hash = "2f98de0ab70fe7b17db4f14a5826b14b";

export default node;
