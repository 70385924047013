/**
 * @generated SignedSource<<76146e6d4985dc651a26d1203fc3c36c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateSourceInput = {
  clientMutationId?: string | null;
  sourceId: string;
  name: string;
  description: string;
  tag: string;
};
export type SourceEditScreenContainer_Mutation$variables = {
  input: UpdateSourceInput;
};
export type SourceEditScreenContainer_MutationVariables = SourceEditScreenContainer_Mutation$variables;
export type SourceEditScreenContainer_Mutation$data = {
  readonly updateSource: {
    readonly source: {
      readonly " $fragmentSpreads": FragmentRefs<"SourceEditScreenContainer_source">;
    } | null;
  } | null;
};
export type SourceEditScreenContainer_MutationResponse = SourceEditScreenContainer_Mutation$data;
export type SourceEditScreenContainer_Mutation = {
  variables: SourceEditScreenContainer_MutationVariables;
  response: SourceEditScreenContainer_Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SourceEditScreenContainer_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateSourcePayload",
        "kind": "LinkedField",
        "name": "updateSource",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Source",
            "kind": "LinkedField",
            "name": "source",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SourceEditScreenContainer_source"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SourceEditScreenContainer_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateSourcePayload",
        "kind": "LinkedField",
        "name": "updateSource",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Source",
            "kind": "LinkedField",
            "name": "source",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tag",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "620d34059ad5550c0f972f9b944555c0",
    "id": null,
    "metadata": {},
    "name": "SourceEditScreenContainer_Mutation",
    "operationKind": "mutation",
    "text": "mutation SourceEditScreenContainer_Mutation(\n  $input: UpdateSourceInput!\n) {\n  updateSource(input: $input) {\n    source {\n      ...SourceEditScreenContainer_source\n      id\n    }\n  }\n}\n\nfragment SourceEditScreenContainer_source on Source {\n  id\n  name\n  tag\n  description\n}\n"
  }
};
})();

(node as any).hash = "9769d5075033216243a31135d6955b8b";

export default node;
