import { Image } from "@chakra-ui/react";
import { FC, Fragment } from "react";
import { graphql, useFragment } from "react-relay";
import { NavLink } from "react-router-dom";

import { ChatbotTableDataRow_chatbotGroup$key } from "~/src/__relay_artifacts__/ChatbotTableDataRow_chatbotGroup.graphql";
import {
  TableDataColumn,
  TableDataRow,
} from "~/src/components/common/tables/Table";
import { DistributionStatusSwitch } from "~/src/components/features/global/DistributionStatusSwitch";

import { ChatbotTableActions } from "./ChatbotTableActions";
import { ROW_HEIGHT } from "./styles";

export type Props = {
  chatbotRef: ChatbotTableDataRow_chatbotGroup$key;
  siteSlug: string;
  dataColumns: string[];
  connectionId: string;
};

const fragment = graphql`
  fragment ChatbotTableDataRow_chatbotGroup on ChatbotGroup {
    chatbots(kind: B_PATTERN) {
      totalCount
    }
    endDate
    id
    name
    slug
    startDate
    updatedAt
    status
    isArchive
    thumbnailImageUrl
    page {
      name
      slug
      funnel {
        name
        slug
      }
    }
  }
`;

export const ChatbotTableDataRow: FC<Props> = ({
  chatbotRef,
  siteSlug,
  dataColumns,
  connectionId,
}) => {
  const chatbot = useFragment(fragment, chatbotRef);

  return (
    <TableDataRow height={ROW_HEIGHT}>
      <TableDataColumn
        type="text"
        isLink
        data={
          <NavLink to={`/sites/${siteSlug}/chatbots/${chatbot.slug}`}>
            {chatbot.name}
          </NavLink>
        }
      />
      <TableDataColumn
        type="actions"
        data={
          <DistributionStatusSwitch
            id={chatbot.id}
            name={chatbot.name}
            isChecked={chatbot.status === "ACTIVE"}
          />
        }
      />
      <TableDataColumn
        type="text"
        data={
          chatbot.thumbnailImageUrl && (
            <Image
              src={chatbot.thumbnailImageUrl}
              boxSize={"60px"}
              fit={"contain"}
            />
          )
        }
      />
      {dataColumns.map((dataColumn) => (
        <Fragment key={dataColumn}>
          {dataColumn === "funnelTitle" && (
            <TableDataColumn type="text" data={chatbot.page?.funnel.name} />
          )}
          {dataColumn === "funnelPage" && (
            <TableDataColumn type="text" data={chatbot.page?.name} />
          )}
          {dataColumn === "patternCount" && (
            <TableDataColumn
              type="numeric"
              data={chatbot.chatbots.totalCount}
            />
          )}
          {dataColumn === "periodSince" && (
            <TableDataColumn type="text" data={chatbot.startDate} />
          )}
          {dataColumn === "periodTill" && (
            <TableDataColumn type="text" data={chatbot.endDate} />
          )}
          {dataColumn === "updatedAt" && (
            <TableDataColumn type="date" data={chatbot.updatedAt} />
          )}
        </Fragment>
      ))}
      <TableDataColumn
        type="actions"
        data={
          <ChatbotTableActions
            chatbotGroup={chatbot}
            connectionId={connectionId}
            filtered={chatbot.isArchive}
          />
        }
      />
    </TableDataRow>
  );
};
