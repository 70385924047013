/**
 * @generated SignedSource<<ce7195cb2127ba94de1ec91dc7ccfcd6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FunnelTransitionReportSection_site$data = {
  readonly dailyLandingPageDropRates: any;
  readonly dailyAverageStayTimes: any;
  readonly dailyAverageConversionTimes: any;
  readonly " $fragmentType": "FunnelTransitionReportSection_site";
};
export type FunnelTransitionReportSection_site = FunnelTransitionReportSection_site$data;
export type FunnelTransitionReportSection_site$key = {
  readonly " $data"?: FunnelTransitionReportSection_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"FunnelTransitionReportSection_site">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "analyzerInput"
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "analyzerInput"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "FunnelTransitionReportSection_site",
  "selections": [
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "dailyLandingPageDropRates",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "dailyAverageStayTimes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "dailyAverageConversionTimes",
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};
})();

(node as any).hash = "4229199652ef4318c44ae6ee3db88b3a";

export default node;
