import { useToast } from "@chakra-ui/toast";
import { format } from "date-fns";
import { concat } from "ramda";
import { FC, useCallback, useMemo } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useNavigate, useParams } from "react-router-dom";

import { FunnelBulkEditTabContainer_Mutation } from "~/src/__relay_artifacts__/FunnelBulkEditTabContainer_Mutation.graphql";
import { FunnelBulkEditTabContainer_Query } from "~/src/__relay_artifacts__/FunnelBulkEditTabContainer_Query.graphql";
// FIXME: move to lib
import {
  stringConvertToUrlMatchType,
  stringToHasForm,
} from "~/src/components/features/settings/FunnelForms/convertFunctions";
import { useMutationCommit } from "~/src/lib/react-relay";
import { delayChunkPromise } from "~/src/lib/utils";

import { FunnelBulkTab, FunnelBulkTabProps } from "./presentations";

const query = graphql`
  query FunnelBulkEditTabContainer_Query($slug: String!) {
    site(slug: $slug) {
      id
      slug
      name
      funnels {
        edges {
          node {
            id
            name
            isExperiment
            landingPage {
              id
              name
              url
              tabletUrl
              mobileUrl
              urlMatchType
              hasForm
            }
            formPage {
              id
              name
              url
              tabletUrl
              mobileUrl
              urlMatchType
            }
            confirmPage {
              id
              name
              url
              tabletUrl
              mobileUrl
              urlMatchType
            }
            thanksPage {
              id
              name
              url
              tabletUrl
              mobileUrl
              urlMatchType
            }
          }
        }
      }
    }
  }
`;

const mutation = graphql`
  mutation FunnelBulkEditTabContainer_Mutation($input: UpdateFunnelInput!) {
    updateFunnel(input: $input) {
      funnel {
        id
        slug
        name
        isExperiment
        landingPage {
          id
          name
          url
          mobileUrl
          tabletUrl
          hasForm
          urlMatchType
        }
        thanksPage {
          id
          name
          url
          mobileUrl
          tabletUrl
          urlMatchType
        }
        confirmPage {
          id
          name
          url
          mobileUrl
          tabletUrl
          urlMatchType
        }
        formPage {
          id
          name
          url
          mobileUrl
          tabletUrl
          urlMatchType
        }
      }
    }
  }
`;

export const FunnelBulkEditTabContainer: FC = () => {
  const { siteSlug = "" } = useParams();
  const toast = useToast();
  const navigate = useNavigate();

  const { site } = useLazyLoadQuery<FunnelBulkEditTabContainer_Query>(query, {
    slug: siteSlug,
  });

  const updateMutate =
    useMutationCommit<FunnelBulkEditTabContainer_Mutation>(mutation);

  const header = useMemo(
    () => [
      [
        "ファネルID",
        "ファネル名",
        "ランディングページID",
        "ランディングページ名",
        "ランディングページPC用URL",
        "ランディングページスマホ用URL",
        "ランディングページタブレット用URL",
        "ランディングページURLの一致タイプ",
        "ランディングページフォームあり",
        "フォームページID",
        "フォームページ名",
        "フォームページPC用URL",
        "フォームページスマホ用URL",
        "フォームページタブレット用URL",
        "フォームページURLの一致タイプ",
        "確認ページID",
        "確認ページ名",
        "確認ページPC用URL",
        "確認ページスマホ用URL",
        "確認ページタブレット用URL",
        "確認ページURLの一致タイプ",
        "サンクスページID",
        "サンクスページ名",
        "サンクスページPC用URL",
        "サンクスページスマホ用URL",
        "サンクスページタブレット用URL",
        "サンクスページURLの一致タイプ",
        "テスト用のファネル",
      ],
    ],
    []
  );

  const csvRows = useMemo(() => {
    const funnelsEdges = site.funnels.edges || [];
    const rows = funnelsEdges.map((edge) => {
      const node = edge?.node;
      if (!node) throw new Error("assertion failed");
      const formPage =
        node.formPage && node.formPage.length > 0
          ? node.formPage[0]
          : {
              id: "",
              name: "",
              url: "",
              mobileUrl: "",
              tabletUrl: "",
              urlMatchType: "EQUAL_TYPE",
            };
      return [
        node.id,
        node.name,
        node.landingPage?.id || "",
        node.landingPage?.name || "",
        node.landingPage?.url || "",
        node.landingPage?.mobileUrl || "",
        node.landingPage?.tabletUrl || "",
        node.landingPage?.urlMatchType,
        node.landingPage?.hasForm || "",

        formPage.id,
        formPage.name,
        formPage.url,
        formPage.mobileUrl,
        formPage.tabletUrl,
        formPage.urlMatchType,

        node.confirmPage?.id || "",
        node.confirmPage?.name || "",
        node.confirmPage?.url || "",
        node.confirmPage?.mobileUrl || "",
        node.confirmPage?.tabletUrl || "",
        node.confirmPage?.urlMatchType,

        node.thanksPage?.id || "",
        node.thanksPage?.name || "",
        node.thanksPage?.url || "",
        node.thanksPage?.mobileUrl || "",
        node.thanksPage?.tabletUrl || "",
        node.thanksPage?.urlMatchType,

        node.isExperiment,
      ];
    });
    return concat(header, rows)
      .map((row) => row.join(","))
      .join("\n");
  }, [header, site.funnels.edges]);

  const handleApplyClick = useCallback<FunnelBulkTabProps["onApplyClick"]>(
    async (csvObject) => {
      try {
        const rows = csvObject.rows.filter(
          (row) => row.length === header[0].length
        );
        const mutations: Promise<{}>[] = rows.map((row) => {
          return updateMutate({
            variables: {
              input: {
                funnelId: row[0],
                name: row[1],
                isExperiment: row[27] === "true" ? true : false,
                landingPageAttributes: {
                  id: row[2],
                  name: row[3] === "" ? "ランディングページ" : row[3],
                  url: row[4],
                  mobileUrl: row[5] === "" ? null : row[5],
                  tabletUrl: row[6] === "" ? null : row[6],
                  urlMatchType: stringConvertToUrlMatchType(row[7]),
                  hasForm: stringToHasForm(row[8]),
                },
                formPageAttributes: [
                  {
                    id: row[9],
                    name: row[10] === "" ? "フォームページ" : row[10],
                    url: row[11],
                    mobileUrl: row[12] === "" ? null : row[12],
                    tabletUrl: row[13] === "" ? null : row[13],
                    urlMatchType: stringConvertToUrlMatchType(row[14]),
                  },
                ],
                confirmPageAttributes: {
                  id: row[15],
                  name: row[16] === "" ? "確認ページ" : row[16],
                  url: row[17],
                  mobileUrl: row[18] === "" ? null : row[18],
                  tabletUrl: row[19] === "" ? null : row[19],
                  urlMatchType: stringConvertToUrlMatchType(row[20]),
                },
                thanksPageAttributes: {
                  id: row[21],
                  name: row[22] === "" ? "サンクスページ" : row[22],
                  url: row[23],
                  mobileUrl: row[24] === "" ? null : row[24],
                  tabletUrl: row[25] === "" ? null : row[25],
                  urlMatchType: stringConvertToUrlMatchType(row[26]),
                },
              },
            },
          });
        });

        await delayChunkPromise(mutations);

        toast({ title: "ファネルを一括変更しました", status: "success" });
        navigate(`/sites/${siteSlug}/settings`);
      } catch (err) {
        toast({
          title: "ファネルの一括変更に失敗しました",
          status: "error",
        });
      }
    },
    [toast, navigate, siteSlug, header, updateMutate]
  );

  return (
    <FunnelBulkTab
      onApplyClick={handleApplyClick}
      downloadCSVFilename={`${site.name}_ファネル_${format(
        new Date(),
        "yyyy-MM-dd"
      )}`}
      downloadCSVData={csvRows}
    />
  );
};
