import { Text, VStack } from "@chakra-ui/react";
import { format } from "date-fns";
import * as React from "react";
import { graphql, useFragment } from "react-relay";

import { GraphQLEnums } from "~/src/__generated__/GraphQLEnums";
import { ChatbotDeliverDayOfWeeks_chatbotGroup$key } from "~/src/__relay_artifacts__/ChatbotDeliverDayOfWeeks_chatbotGroup.graphql";
import { DefinitionTeam } from "~/src/components/common/DefinitionTeam";

type Props = {
  chatbotGroupRef: ChatbotDeliverDayOfWeeks_chatbotGroup$key;
};

const fragment = graphql`
  fragment ChatbotDeliverDayOfWeeks_chatbotGroup on ChatbotGroup {
    startDate
    startTime
    endDate
    endTime
    deliverDayOfWeeks {
      weekOfMonth
      daysOfWeek
    }
  }
`;

const timeFromISOString = (dateStr: string | null) => {
  if (!dateStr) return undefined;

  const date = new Date(dateStr);
  if (!date) return undefined;

  return format(date, "HH:mm");
};

export const ChatbotDeliverDayOfWeeks: React.VFC<Props> = ({
  chatbotGroupRef,
}) => {
  const chatbotGroup = useFragment(fragment, chatbotGroupRef);

  const dateDuration = React.useMemo(() => {
    if (chatbotGroup.startDate == null && chatbotGroup.endDate == null)
      return "";

    return `${chatbotGroup.startDate || ""} ~ ${chatbotGroup.endDate || ""}`;
  }, [chatbotGroup.endDate, chatbotGroup.startDate]);

  const timeDuration = React.useMemo(() => {
    if (chatbotGroup.startTime == null && chatbotGroup.endTime == null)
      return "";

    return `${timeFromISOString(chatbotGroup.startTime) || ""} ~ ${
      timeFromISOString(chatbotGroup.endTime) || ""
    }`;
  }, [chatbotGroup.endTime, chatbotGroup.startTime]);

  const deliverDayOfWeeks = React.useMemo(
    () =>
      chatbotGroup.deliverDayOfWeeks.map((deliverDayOfWeek) => {
        const weekOfMonth = deliverDayOfWeek.weekOfMonth
          ? `第${deliverDayOfWeek.weekOfMonth}週`
          : "毎週";
        const daysOfWeek = deliverDayOfWeek.daysOfWeek.map(
          (dayOfWeek) =>
            GraphQLEnums.DayOfWeek.find(({ value }) => value === dayOfWeek)
              ?.label
        );
        return `${weekOfMonth} ${daysOfWeek.join("・")}`;
      }),
    [chatbotGroup.deliverDayOfWeeks]
  );

  return (
    <>
      <DefinitionTeam label="実施期間" dense>
        {dateDuration}
      </DefinitionTeam>
      <DefinitionTeam label="実施時間" dense>
        {timeDuration}
      </DefinitionTeam>
      <DefinitionTeam label="実施曜日" dense>
        <VStack alignItems={"flex-start"}>
          {deliverDayOfWeeks.map((deliverDayOfWeek, index) => (
            <Text key={index}>{deliverDayOfWeek}</Text>
          ))}
        </VStack>
      </DefinitionTeam>
    </>
  );
};
