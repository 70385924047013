/**
 * @generated SignedSource<<d8d739ccaa7a8c7dc677f9903e6cfa22>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DistributionStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type GroupContentJudgeStatus = "PENDING" | "APPROVED" | "%future added value";
export type PageKind = "LANDING" | "FORM" | "CONFIRM" | "THANKS" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ABTestTableDataRow_abtestScenarioPageGroup$data = {
  readonly abtestScenarioPages: {
    readonly totalCount: number;
  } | null;
  readonly endDate: string | null;
  readonly id: string;
  readonly page: {
    readonly id: string;
    readonly slug: string;
    readonly name: string;
    readonly kind: PageKind;
    readonly sortNumber: number;
    readonly url: string;
    readonly funnel: {
      readonly name: string;
      readonly slug: string;
      readonly id: string;
      readonly siteUrl: string;
    };
  };
  readonly memo: string | null;
  readonly slug: string;
  readonly startDate: string | null;
  readonly status: DistributionStatus;
  readonly title: string;
  readonly updatedAt: string | null;
  readonly isArchive: boolean;
  readonly judgeStatus: GroupContentJudgeStatus | null;
  readonly " $fragmentType": "ABTestTableDataRow_abtestScenarioPageGroup";
};
export type ABTestTableDataRow_abtestScenarioPageGroup = ABTestTableDataRow_abtestScenarioPageGroup$data;
export type ABTestTableDataRow_abtestScenarioPageGroup$key = {
  readonly " $data"?: ABTestTableDataRow_abtestScenarioPageGroup$data;
  readonly " $fragmentSpreads": FragmentRefs<"ABTestTableDataRow_abtestScenarioPageGroup">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ABTestTableDataRow_abtestScenarioPageGroup",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "exceptKind",
          "value": "DEFAULT"
        }
      ],
      "concreteType": "AbtestScenarioPageConnection",
      "kind": "LinkedField",
      "name": "abtestScenarioPages",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": "abtestScenarioPages(exceptKind:\"DEFAULT\")"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Page",
      "kind": "LinkedField",
      "name": "page",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sortNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Funnel",
          "kind": "LinkedField",
          "name": "funnel",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v1/*: any*/),
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "siteUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "memo",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isArchive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "judgeStatus",
      "storageKey": null
    }
  ],
  "type": "AbtestScenarioPageGroup",
  "abstractKey": null
};
})();

(node as any).hash = "64fbb030e1f0574c4226f630ba5a0f10";

export default node;
