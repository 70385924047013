import { Button, HStack } from "@chakra-ui/react";
import { rgba } from "polished";
import { FC } from "react";

import { chakraFactory } from "~/src/lib/chakra-ui";

type TabItem = {
  id: string;
  label: string;
};

export type Props = {
  className?: string;
  currentTabId: TabItem["id"];
  tabs: readonly TabItem[];
  onClick: (tabId: any) => void;
};

const StyledButton = chakraFactory(Button, {
  baseStyle: {
    height: "46px",
    width: "160px",
    bg: "transparent",
    color: "#979797",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "23px",
    letterSpacing: "0.04em",
    borderRadius: "none",
    borderBottom: "3px solid #DADADA",

    _active: {
      bg: "transparent",
      color: "#1867C0",
      borderColor: "#1867C0",
      boxShadow: "none",
    },

    _hover: {
      bg: "transparent",
      color: rgba("#1867C0", 0.6),
      borderColor: rgba("#1867C0", 0.6),
      boxShadow: "none",
    },

    _focus: {
      boxShadow: "none",
    },
  },
});

export const Tab: FC<Props> = ({ className, currentTabId, tabs, onClick }) => (
  <HStack className={className} spacing="2px">
    {tabs.map((tab) => (
      <StyledButton
        key={tab.id}
        isActive={tab.id === currentTabId}
        // eslint-disable-next-line react/jsx-no-bind
        onClick={() => onClick(tab.id)}
      >
        {tab.label}
      </StyledButton>
    ))}
  </HStack>
);
