import { FC } from "react";

import { ExcludedIPCreateFormContainer } from "~/src/components/features/settings/ExcludedIPForms";
import { useExcludedIPCreatePageParams } from "~/src/routes/hooks";

import { ExcludedIPCreatePage } from "./presentations";

export const ExcludedIPCreatePageContainer: FC = () => {
  const { siteSlug } = useExcludedIPCreatePageParams();

  return (
    <ExcludedIPCreatePage siteSlug={siteSlug}>
      <ExcludedIPCreateFormContainer siteSlug={siteSlug} />
    </ExcludedIPCreatePage>
  );
};
