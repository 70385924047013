import { Box, Flex, Text } from "@chakra-ui/react";
import { FC, useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { FunnelTableSection_site$key } from "~/src/__relay_artifacts__/FunnelTableSection_site.graphql";
import { FunnelTable } from "~/src/components/features/dashboard/FunnelTable";
import { CSVDownloadLink } from "~/src/components/features/report/CSVDownloadLink";
import { chakraFactory } from "~/src/lib/chakra-ui";

const StyledWrapperBox = chakraFactory(Box, {
  baseStyle: {
    padding: "24px",
    background: "#FFFFFF",
    border: "1px solid #DADADA",
    boxSizing: "border-box",
  },
});

const StyledHeaderText = chakraFactory(Text, {
  baseStyle: {
    height: "26px",
    fontWeight: 500,
    fontSize: "18px",
    letterSpacing: "0.02em",
    color: "#282828",
  },
});

export type Props = {
  siteRef: FunnelTableSection_site$key;
};

type Data = {
  pageName: string;
  uniqueUser: number;
  kind: "LANDING" | "FORM" | "CONFIRM" | "THANKS";
};

const fragment = graphql`
  fragment FunnelTableSection_site on Site {
    landingPageUniqueUser(input: $analyzerInput)
    formPageUniqueUser(input: $analyzerInput)
    confirmPageUniqueUser(input: $analyzerInput)
    thanksPageUniqueUser(input: $analyzerInput)
    uniqueUserCountPerFunnel(input: $analyzerInput)
  }
`;

const csvHeaderRow = ["ファネル名", "ページ", "URL", "UU数", "LPからの到達率"];

export const FunnelTableSection: FC<Props> = ({ siteRef }) => {
  const site = useFragment(fragment, siteRef);

  const rows = useMemo(() => {
    const formUniqueUsers: Data[] = site.formPageUniqueUser.map(
      (formUniqueUser, index) => ({
        pageName: `フォームページ(${index + 1}ページ目)`,
        uniqueUser: formUniqueUser,
        kind: "FORM",
      })
    );
    return [
      {
        pageName: "ランディングページ",
        uniqueUser: site.landingPageUniqueUser,
        kind: "LANDING",
      },
      ...formUniqueUsers,
      {
        pageName: "確認ページ",
        uniqueUser: site.confirmPageUniqueUser,
        kind: "CONFIRM",
      },
      {
        pageName: "サンクスページ",
        uniqueUser: site.thanksPageUniqueUser,
        kind: "THANKS",
      },
    ] as Data[];
  }, [site]);

  const csvRows = useMemo(() => {
    const csvRows = site.uniqueUserCountPerFunnel.map((row) => {
      return [
        row.funnel_name,
        row.page_name,
        row.url,
        row.unique_user_count,
        row.transition_rate_from_landing_page,
      ];
    });
    csvRows.unshift(csvHeaderRow);
    return csvRows.map((row) => row.join(",")).join("\n");
  }, [site]);

  return (
    <StyledWrapperBox>
      <Flex justifyContent="space-between" alignItems="center" mb="16px">
        <StyledHeaderText>
          {"ファネル流入からコンバージョンまで"}
        </StyledHeaderText>
        <CSVDownloadLink
          name="ファネル流入からコンバージョンまで"
          data={csvRows}
        />
      </Flex>
      <FunnelTable rows={rows} />
    </StyledWrapperBox>
  );
};
