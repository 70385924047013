import { Box, HStack, Heading } from "@chakra-ui/react";
import { ArrowForwardIos as ArrowForwardIosIcon } from "@mui/icons-material";
import { FC } from "react";
import { Link } from "react-router-dom";

import { chakraFactory } from "~/src/lib/chakra-ui";

type Breadcrumb = {
  label: string;
  path: string;
};

export type Props = {
  title: string;
  breadcrumbs?: Breadcrumb[];
};

const StyledWrapperBox = chakraFactory(Box, {
  baseStyle: {
    width: "100%",
    padding: "24px 40px 40px 40px",
  },
});

const StyledDividerIcon = chakraFactory(ArrowForwardIosIcon, {
  baseStyle: {
    width: "8px !important",
    height: "8px !im8ortant",
    border: "none",
    color: "#CCCCCC",
  },
});

const StyledLink = chakraFactory(Link, {
  baseStyle: {
    fontSize: "10px",
    lineHeight: "14px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.04em",
    color: "#666666",
  },
});

const StyledTitle = chakraFactory(Heading, {
  baseStyle: {
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "29px",
    letterSpacing: "0.02em",
    color: "#282828",
  },
});

export const PageLayout: FC<Props> = ({ children, title, breadcrumbs }) => (
  <StyledWrapperBox>
    <HStack
      height="24px"
      mb="16px"
      spacing="14px"
      visibility={breadcrumbs ? "visible" : "hidden"}
      divider={<StyledDividerIcon />}
    >
      {breadcrumbs?.map((breadcrumb) => (
        <StyledLink key={breadcrumb.path} to={breadcrumb.path}>
          {breadcrumb.label}
        </StyledLink>
      ))}
    </HStack>
    <StyledTitle as="h2">{title}</StyledTitle>
    {children}
  </StyledWrapperBox>
);
