import { Flex } from "@chakra-ui/react";
import { format } from "date-fns";
import { FC, useCallback, useState } from "react";

import { AbtestReportOrderValue } from "~/src/__generated__/schema";
import { Table } from "~/src/components/common/tables/Table";

import { CSVDownloadLink } from "../../CSVDownloadLink";
import { DisableColumnSelectButton } from "../../DisableColumnSelectButton";
import {
  ColumnData,
  ReportTableDataRow,
  ReportTableHeaderRow,
} from "../../ReportTable";
import { RowHeightSettingButton } from "../../RowHeightSettingButton";
import { HeaderOption } from "../../utils";

export type Prop = {
  rows: ColumnData[][];
  footerRow: ColumnData[];
  headerRow: HeaderOption<AbtestReportOrderValue>[];
  onSortClick: (params: any) => void;
  disableColumnOptions: { label: string; value: number }[];
  csvRows: string;
};

export const ABTestReportTable: FC<Prop> = ({
  rows,
  footerRow,
  headerRow,
  onSortClick,
  disableColumnOptions,
  csvRows,
}) => {
  const [disableColumns, setDisableColumns] = useState<number[]>([]);
  const [rowHeight, setRowHeight] = useState<number>(56);

  const handleSubmitDisalbeColumnSelect = useCallback((values) => {
    setDisableColumns(values.disableColumns);
  }, []);

  const handleSubmitRowHeightSetting = useCallback((values) => {
    setRowHeight(values.rowHeight);
  }, []);

  return (
    <>
      <Flex justifyContent="flex-end" mb="24px" mt={4}>
        <RowHeightSettingButton
          formValues={{ rowHeight }}
          onSubmit={handleSubmitRowHeightSetting}
        />
        <DisableColumnSelectButton
          formValues={{ disableColumns }}
          onSubmit={handleSubmitDisalbeColumnSelect}
          options={disableColumnOptions}
        />
        <CSVDownloadLink
          name={`${"ABテスト別レポート"}-${format(
            new Date(),
            "yyyy-MM-dd"
          )}.csv`}
          data={csvRows}
          filteredColumns={disableColumns}
        />
      </Flex>

      <Table>
        <ReportTableHeaderRow
          disableColumns={disableColumns}
          onSortClick={onSortClick}
          headerOptions={headerRow}
        />
        {rows.map((row, i) => (
          <ReportTableDataRow
            key={i}
            row={row}
            disableColumns={disableColumns}
            rowHeight={`${rowHeight}px`}
          />
        ))}
        <ReportTableDataRow disableColumns={disableColumns} row={footerRow} />
      </Table>
    </>
  );
};
