/**
 * @generated SignedSource<<f3c0633e0987104f6b45def7dd01039a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateUserInput = {
  clientMutationId?: string | null;
  company?: string | null;
  email?: string | null;
  isHoneycomb?: boolean | null;
  isMacbeeServer?: boolean | null;
  isCrossDomainQuery?: boolean | null;
  isCrossDomainFragment?: boolean | null;
  isSecure?: boolean | null;
  itpUrls?: string | null;
  name?: string | null;
  tel?: string | null;
  userTag?: string | null;
};
export type AccountSwitch_Mutation$variables = {
  input: UpdateUserInput;
};
export type AccountSwitch_MutationVariables = AccountSwitch_Mutation$variables;
export type AccountSwitch_Mutation$data = {
  readonly updateUser: {
    readonly user: {
      readonly name: string | null;
      readonly isHoneycomb: boolean;
      readonly isSecure: boolean;
      readonly isMacbeeServer: boolean;
      readonly isCrossDomainQuery: boolean;
      readonly isCrossDomainFragment: boolean;
    };
  } | null;
};
export type AccountSwitch_MutationResponse = AccountSwitch_Mutation$data;
export type AccountSwitch_Mutation = {
  variables: AccountSwitch_MutationVariables;
  response: AccountSwitch_Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isHoneycomb",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isSecure",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isMacbeeServer",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isCrossDomainQuery",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isCrossDomainFragment",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountSwitch_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUserPayload",
        "kind": "LinkedField",
        "name": "updateUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountSwitch_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUserPayload",
        "kind": "LinkedField",
        "name": "updateUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1f96cdfcbbe1278a7c0b13f7eec82a51",
    "id": null,
    "metadata": {},
    "name": "AccountSwitch_Mutation",
    "operationKind": "mutation",
    "text": "mutation AccountSwitch_Mutation(\n  $input: UpdateUserInput!\n) {\n  updateUser(input: $input) {\n    user {\n      name\n      isHoneycomb\n      isSecure\n      isMacbeeServer\n      isCrossDomainQuery\n      isCrossDomainFragment\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "699884c1c41ecfd50d94997c3b9315cd";

export default node;
