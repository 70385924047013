import { FC } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";

import { PopupGroupPatternEditPageContainer_Query } from "~/src/__relay_artifacts__/PopupGroupPatternEditPageContainer_Query.graphql";

import { PopupGroupPatternEditScreenContainer } from "./PopupGroupPatternEditScreenContainer";

export type Props = {};

const query = graphql`
  query PopupGroupPatternEditPageContainer_Query(
    $siteSlug: String!
    $popupSlug: String!
    $popupGroupSlug: String!
  ) {
    viewer {
      role
    }
    site(slug: $siteSlug) {
      ...PopupGroupPatternEditScreenContainer_site
    }
    popup(slug: $popupSlug) {
      ...PopupGroupPatternEditScreenContainer_popup
    }
    popupGroup(slug: $popupGroupSlug) {
      ...PopupGroupPatternEditScreenContainer_popupGroup
    }
  }
`;

export const PopupGroupPatternEditPageContainer: FC<Props> = () => {
  const { siteSlug = "", patternSlug = "", popupGroupSlug = "" } = useParams();

  const { site, popup, popupGroup, viewer } =
    useLazyLoadQuery<PopupGroupPatternEditPageContainer_Query>(query, {
      siteSlug,
      popupSlug: patternSlug,
      popupGroupSlug,
    });

  if (!site || !popup || !popupGroup) throw new Error("assertion failed");

  return (
    <PopupGroupPatternEditScreenContainer
      siteRef={site}
      popupRef={popup}
      popupGroupRef={popupGroup}
      role={viewer.role}
    />
  );
};
