import { formatNumber } from "accounting";
import { groupBy, prop } from "ramda";
import { FC, useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { AbtestReportOrderValue } from "~/src/__generated__/schema";
import { ABTestDefaultReport_site$key } from "~/src/__relay_artifacts__/ABTestDefaultReport_site.graphql";
import { formatStayTime } from "~/src/lib/utils";

import { ColumnData } from "../../ReportTable";
import { HeaderOption } from "../../utils";

import { AbtestReportGraph } from "./AbtestReportGraph";
import { ABTestReportTable } from "./ABTestReportTable";

type Props = {
  onSortClick: (params: any) => void;
  siteRef: ABTestDefaultReport_site$key;
};

const fragment = graphql`
  fragment ABTestDefaultReport_site on Site {
    slug
    url
    reportAbtest(kind: $kind, filter: $filter, order: $order) {
      rows {
        abtestScenarioPageTitle
        abtestScenarioPageGroupTitle
        funnelName
        accessDate
        uniqueUser
        conversionUser
        cvr
        averageStayTime
        pcRate
        mobileRate
        tabletRate
        abtestScenarioPageSlug
        abtestScenarioPageGroupSlug
      }
      summary {
        abtestScenarioPageTitle
        abtestScenarioPageGroupTitle
        funnelName
        accessDate
        uniqueUser
        conversionUser
        cvr
        averageStayTime
        pcRate
        mobileRate
        tabletRate
      }
      graph {
        abtestScenarioPageId
        abtestScenarioPageTitle
        abtestScenarioPageGroupTitle
        funnelName
        accessDate
        uniqueUser
        conversionUser
        cvr
        averageStayTime
        pcRate
        mobileRate
        tabletRate
      }
      summaryGraph {
        accessDate
        uniqueUser
        conversionUser
        cvr
        averageStayTime
        pcRate
        mobileRate
        tabletRate
      }
    }
  }
`;

const headerRow: HeaderOption<AbtestReportOrderValue>[] = [
  { label: "A/Bテストパターン", orderField: "ABTEST" },
  { label: "A/Bテスト", orderField: "ABTEST_GROUP" },
  { label: "ファネル", orderField: "FUNNEL" },
  { label: "流入数", orderField: "UNIQUE_USER" },
  { label: "CV-U", orderField: "CONVERSION_USER" },
  { label: "CVR", orderField: "CVR" },
  { label: "LP平均滞在時間", orderField: "AVERAGE_STAY_TIME" },
  { label: "SP", orderField: "MOBILE_RATE" },
  { label: "TB", orderField: "TABLET_RATE" },
  { label: "PC", orderField: "PC_RATE" },
];

const disableColumnOptions = [
  { label: "A/Bテスト", value: 1 },
  { label: "ファネル", value: 2 },
  { label: "流入数", value: 3 },
  { label: "CV-U", value: 4 },
  { label: "CVR", value: 5 },
  { label: "LP平均滞在時間", value: 6 },
  { label: "SP", value: 7 },
  { label: "TB", value: 8 },
  { label: "PC", value: 9 },
];

const csvHeaderRow = [
  "A/Bテストパターン",
  "A/Bテスト",
  "ファネル",
  "流入数",
  "CV-U",
  "CVR",
  "LP平均滞在時間",
  "SP",
  "TB",
  "PC",
];

export const ABTestDefaultReport: FC<Props> = ({ siteRef, onSortClick }) => {
  const site = useFragment(fragment, siteRef);

  const isExistReportData = useMemo(() => {
    return site.reportAbtest.graph.length > 0;
  }, [site.reportAbtest.graph]);

  const rows = useMemo(() => {
    return site.reportAbtest.rows.map((row) => {
      return [
        {
          type: "NAV_LINK",
          value: row.abtestScenarioPageTitle || "",
          url: `/sites/${site.slug}/abtests/${row.abtestScenarioPageGroupSlug}/patterns/${row.abtestScenarioPageSlug}`,
        },
        { type: "TEXT", value: row.abtestScenarioPageGroupTitle || "" },
        { type: "URL_LINK", value: row.funnelName || "", url: site.url },
        { type: "TEXT", value: formatNumber(row.uniqueUser) },
        { type: "TEXT", value: formatNumber(row.conversionUser) },
        { type: "TEXT", value: row.cvr.toString() + "%" },
        { type: "TEXT", value: formatStayTime(row.averageStayTime) },
        { type: "TEXT", value: row.mobileRate.toString() + "%" },
        { type: "TEXT", value: row.tabletRate.toString() + "%" },
        { type: "TEXT", value: row.pcRate.toString() + "%" },
      ] as ColumnData[];
    });
  }, [site.reportAbtest.rows, site.slug, site.url]);

  const footerRow = useMemo(() => {
    if (site.reportAbtest.summary.length === 0) return [];
    const row = site.reportAbtest.summary[0];
    return [
      { type: "TEXT", value: "" },
      { type: "TEXT", value: "" },
      { type: "TEXT", value: "合計" },
      { type: "TEXT", value: formatNumber(row.uniqueUser) },
      { type: "TEXT", value: formatNumber(row.conversionUser) },
      { type: "TEXT", value: row.cvr.toString() + "%" },
      { type: "TEXT", value: formatStayTime(row.averageStayTime) },
      { type: "TEXT", value: row.mobileRate.toString() + "%" },
      { type: "TEXT", value: row.tabletRate.toString() + "%" },
      { type: "TEXT", value: row.pcRate.toString() + "%" },
    ] as ColumnData[];
  }, [site.reportAbtest.summary]);

  const csvRows = useMemo(() => {
    const csvRows = rows.map((row) => row.map((column) => column.value));
    const csvFooterRow = footerRow.map((column) => column.value);
    csvRows.unshift(csvHeaderRow);
    csvRows.push(csvFooterRow);
    return csvRows
      .map((row) => row.map((cell) => cell.replaceAll(",", "")).join(","))
      .join("\n");
  }, [rows, footerRow]);

  const graphData = useMemo(() => {
    const data = groupBy<any, any>(
      prop("abtestScenarioPageId"),
      site.reportAbtest.graph
    );
    if (site.reportAbtest.summaryGraph.length === 0) return data;
    data["summary"] = site.reportAbtest.summaryGraph.map((r) => {
      return {
        abtestScenarioPageTitle: "合計",
        accessDate: r.accessDate,
        uniqueUser: r.uniqueUser,
        conversionUser: r.conversionUser,
        cvr: r.cvr,
        averageStayTime: r.averageStayTime,
        pcRate: r.pcRate,
        mobileRate: r.mobileRate,
        tabletRate: r.tabletRate,
      };
    });
    return data;
  }, [site.reportAbtest.graph, site.reportAbtest.summaryGraph]);

  const graphOptions = useMemo(() => {
    const keys = Object.keys(graphData);
    return keys.map((key) => {
      const abtestTitle = graphData[key][0].abtestScenarioPageTitle;
      const funnelName = graphData[key][0].funnelName;
      return {
        value: key.toString(),
        label: funnelName ? `${abtestTitle}(${funnelName})` : abtestTitle,
      };
    });
  }, [graphData]);

  return (
    <>
      {isExistReportData && (
        <AbtestReportGraph graphData={graphData} graphOptions={graphOptions} />
      )}
      <ABTestReportTable
        rows={rows}
        footerRow={footerRow}
        headerRow={headerRow}
        onSortClick={onSortClick}
        disableColumnOptions={disableColumnOptions}
        csvRows={csvRows}
      />
    </>
  );
};
