/**
 * @generated SignedSource<<6feeb2fb9baf9fe58934bff629adc330>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FunnelListTabContainer_site$data = {
  readonly " $fragmentSpreads": FragmentRefs<"FunnelListTab_site">;
  readonly " $fragmentType": "FunnelListTabContainer_site";
};
export type FunnelListTabContainer_site = FunnelListTabContainer_site$data;
export type FunnelListTabContainer_site$key = {
  readonly " $data"?: FunnelListTabContainer_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"FunnelListTabContainer_site">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FunnelListTabContainer_site",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FunnelListTab_site"
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "bcfcbf8162460f3e7e9f0268efaeeeda";

export default node;
