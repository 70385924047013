import { Tooltip as ChakraTooltip, TooltipProps } from "@chakra-ui/react";

import { chakraFactory } from "~/src/lib/chakra-ui";

export type Props = TooltipProps;

export const Tooltip = chakraFactory(ChakraTooltip, {
  baseStyle: {
    fontSize: "10px",
    lineHeight: "12px",
    padding: "4px 8px",
    background: "rgba(95, 95, 95, 0.7)",
    borderRadius: "3p-rx",
    color: "#FFFFFF",
  },
});
