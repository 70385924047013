import { Box, Flex, HStack, Spacer, VStack } from "@chakra-ui/react";
import { format } from "date-fns";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { NavLink, useParams } from "react-router-dom";

import { GraphQLEnums } from "~/src/__generated__/GraphQLEnums";
import { ABTestDetailPageContainer_Query } from "~/src/__relay_artifacts__/ABTestDetailPageContainer_Query.graphql";
import { ABTestDetailPageContainer_updateDeliverKindMutation } from "~/src/__relay_artifacts__/ABTestDetailPageContainer_updateDeliverKindMutation.graphql";
import { ABTestDetailPageContainer_updateDeliverWeightMutation } from "~/src/__relay_artifacts__/ABTestDetailPageContainer_updateDeliverWeightMutation.graphql";
import { useTableItemSelect } from "~/src/components//common/tables/TableItemSelect";
import { ArchiveOrUnarchiveButton } from "~/src/components//features/archive/ArchiveOrUnarchiveButton";
import { OutlineButton, SolidButton } from "~/src/components/common/Button";
import { Chip } from "~/src/components/common/Chip";
import { DefinitionTeam } from "~/src/components/common/DefinitionTeam";
import { SectionCard } from "~/src/components/common/SectionCard";
import { Switch } from "~/src/components/common/Switch";
import { URLItem } from "~/src/components/common/URLItem";
import { DateRangeContext } from "~/src/components/features/global/HeaderNavigation/DateRangeField";
import { PageLayout } from "~/src/components/features/global/PageLayout";
import { TableItemSelectButton } from "~/src/components/features/global/TableItemSelectButton";
import { useDateRangeSearchParams } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";
import { delayChunkPromise } from "~/src/lib/utils";

import {
  ABTestDeliverDayOfWeeks,
  ABTestDeliverWeightButton,
  ABTestDeliverWeightButtonProps,
  ABTestDistributionSettingSection,
  ABTestGroupCopyButton,
  ABTestPatternFilterButton,
  ABTestPatternFilterFormValues,
  ABTestPatternReferrerReportTable,
  ABTestPatternTable,
  ABTestScenarioPage,
} from "./presentations";
import {
  allTableItems,
  initialSelectedTableItems,
} from "./presentations/constants";

export type Props = {};

const query = graphql`
  query ABTestDetailPageContainer_Query(
    $slug: String!
    $analyzerInput: ReportAnalyzerInput!
    $startDate: ISO8601Date
    $endDate: ISO8601Date
    $order: AbtestScenarioPageGroupReferrerReportOrder
  ) {
    viewer {
      role
    }
    abtestScenarioPageGroup(slug: $slug) {
      ...ABTestPatternTable_abtestScenarioPageGroup
        @arguments(analyzerInput: $analyzerInput)
      ...ABTestDistributionSettingSection_abtestGroup
      ...ABTestDeliverDayOfWeeks_abtestScenarioPageGroup
      ...ABTestPatternReferrerReportTable_abtestScenarioPageGroup
        @arguments(startDate: $startDate, endDate: $endDate, order: $order)
      ageRanges
      annualIncomes
      deliverKind
      devices
      browsers
      genders
      id
      isArchive
      memo
      page {
        id
        name
        slug
        kind
        sortNumber
        url
        funnel {
          id
          name
          slug
          siteUrl
        }
      }
      platforms
      repeat
      slug
      title
    }
  }
`;

const updateDeliverKindMutation = graphql`
  mutation ABTestDetailPageContainer_updateDeliverKindMutation(
    $input: UpdateDeliverableInput!
  ) {
    updateDeliverable(input: $input) {
      deliverable {
        id
        deliverKind
      }
    }
  }
`;

const updateDeliverWeightMutation = graphql`
  mutation ABTestDetailPageContainer_updateDeliverWeightMutation(
    $input: UpdateWeightableInput!
  ) {
    updateWeightable(input: $input) {
      weightable {
        id
        weight
      }
    }
  }
`;

export const ABTestDetailPageContainer: FC<Props> = () => {
  const {
    dateRange: [startOn, endOn],
  } = useContext(DateRangeContext);
  const { attachDateRangeParams } = useDateRangeSearchParams();
  const { abtestSlug = "", siteSlug = "" } = useParams();
  const [abtestScenarioPages, setAbtestScenarioPages] = useState<
    ABTestScenarioPage[]
  >([]);
  const [filterValues, setFilterValues] =
    useState<ABTestPatternFilterFormValues>({
      sourceId: null,
      device: null,
      withArchived: null,
      status: null,
    });
  const { abtestScenarioPageGroup, viewer } =
    useLazyLoadQuery<ABTestDetailPageContainer_Query>(query, {
      slug: abtestSlug,
      analyzerInput: {
        startOn: format(startOn, "yyyy-MM-dd"),
        endOn: format(endOn, "yyyy-MM-dd"),
      },
      startDate: format(startOn, "yyyy-MM-dd"),
      endDate: format(endOn, "yyyy-MM-dd"),
    });

  if (!abtestScenarioPageGroup) throw new Error("assertion failed");

  const { selectedTableItems, headerColumns, dataColumns, onApplyClick } =
    useTableItemSelect({
      cacheKey: "ABTestDetailPage",
      initialSelectedTableItems,
    });

  const updateDeliverKind =
    useMutationCommit<ABTestDetailPageContainer_updateDeliverKindMutation>(
      updateDeliverKindMutation
    );
  const updateDeliverWeight =
    useMutationCommit<ABTestDetailPageContainer_updateDeliverWeightMutation>(
      updateDeliverWeightMutation
    );

  const handleDeliverWeightSubmit = useCallback<
    ABTestDeliverWeightButtonProps["onSubmit"]
  >(
    async (values) => {
      const mutations: Promise<{}>[] = values.deliverWeights.map(
        (deliverWeight) => {
          return updateDeliverWeight({
            variables: {
              input: {
                weightableId: deliverWeight.id,
                weight: deliverWeight.weight,
              },
            },
          });
        }
      );
      if (abtestScenarioPageGroup.deliverKind !== values.deliverKind) {
        mutations.push(
          updateDeliverKind({
            variables: {
              input: {
                deliverableId: abtestScenarioPageGroup.id,
                deliverKind: values.deliverKind,
              },
            },
          })
        );
      }

      await delayChunkPromise(mutations);
    },
    [
      abtestScenarioPageGroup.deliverKind,
      abtestScenarioPageGroup.id,
      updateDeliverKind,
      updateDeliverWeight,
    ]
  );

  useEffect(() => {
    attachDateRangeParams();
  }, [attachDateRangeParams]);

  return (
    <PageLayout
      title="A/Bテスト詳細"
      breadcrumbs={[
        { label: "A/Bテスト一覧", path: `/sites/${siteSlug}/abtests` },
        {
          label: "A/Bテスト詳細",
          path: `/sites/${siteSlug}/abtests/${abtestSlug}`,
        },
      ]}
    >
      <Box mx={4}>
        <Box mt={4} />
        <Flex alignItems="center">
          <Box color="#282828" fontWeight="bold">
            {`${abtestScenarioPageGroup.title}`}
          </Box>
          <Spacer />
          <HStack spacing={3}>
            <ArchiveOrUnarchiveButton
              id={abtestScenarioPageGroup.id}
              isArchive={abtestScenarioPageGroup.isArchive}
            />
            <ABTestGroupCopyButton
              originalId={abtestScenarioPageGroup.id}
              currentValues={{
                funnelId: abtestScenarioPageGroup.page.funnel.id,
                pageKind: abtestScenarioPageGroup.page.kind,
                pageId: abtestScenarioPageGroup.page.id,
              }}
            />
          </HStack>
        </Flex>
        <HStack>
          <Box>
            {`実施ファネル: ${abtestScenarioPageGroup.page.funnel.name}`}
          </Box>
          <URLItem url={abtestScenarioPageGroup.page.funnel.siteUrl} />
        </HStack>
        <HStack>
          <Box>
            {`実施ページ: ${
              abtestScenarioPageGroup.page.kind === "FORM"
                ? abtestScenarioPageGroup.page.name +
                  `(${abtestScenarioPageGroup.page.sortNumber - 1})`
                : abtestScenarioPageGroup.page.name
            }`}
          </Box>
          <URLItem url={abtestScenarioPageGroup.page.url} />
        </HStack>

        <HStack justifyContent="flex-end" mb={4} spacing={4}></HStack>

        <Flex alignItems="center">
          <Box color="#282828" fontWeight="bold">
            パターン
          </Box>
          <Spacer />
          <HStack spacing={3}>
            <NavLink
              to={`/sites/${siteSlug}/abtests/${abtestSlug}/patterns/new`}
            >
              <SolidButton>パターン登録</SolidButton>
            </NavLink>
          </HStack>
        </Flex>
        <Flex justifyContent="flex-end" mt={4}>
          <HStack spacing="12px">
            <ABTestPatternFilterButton
              filterValues={filterValues}
              onSubmit={setFilterValues}
              onFilterChange={setFilterValues}
            />
            <ABTestDeliverWeightButton
              abtestScenarioPages={abtestScenarioPages}
              deliverKind={abtestScenarioPageGroup.deliverKind || "EQUAL"}
              onSubmit={handleDeliverWeightSubmit}
            />
            <TableItemSelectButton
              allTableItems={allTableItems}
              selectedTableItems={selectedTableItems}
              onApplyClick={onApplyClick}
            />
          </HStack>
        </Flex>
        <ABTestPatternTable
          abtestScenarioPageGroupRef={abtestScenarioPageGroup}
          filterValues={filterValues}
          siteSlug={siteSlug}
          headerColumns={headerColumns}
          dataColumns={dataColumns}
          onAbtestsChange={setAbtestScenarioPages}
          role={viewer.role}
        />

        <Flex alignItems="center">
          <Box color="#282828" fontWeight="bold">
            流入元レポート
          </Box>
          <Spacer />
        </Flex>
        <ABTestPatternReferrerReportTable
          abtestScenarioPageGroupRef={abtestScenarioPageGroup}
        />

        <Flex alignItems="center">
          <Box color="#282828" fontWeight="bold">
            基本設定
          </Box>
          <Spacer />
          <HStack spacing={3}>
            <NavLink to={`/sites/${siteSlug}/abtests/${abtestSlug}/edit`}>
              <OutlineButton>編集</OutlineButton>
            </NavLink>
          </HStack>
        </Flex>

        <VStack mt={4}>
          <SectionCard>
            <DefinitionTeam label="A/Bテスト名">
              {abtestScenarioPageGroup.title}
            </DefinitionTeam>
            <DefinitionTeam label="実施するファネル">
              {abtestScenarioPageGroup.page.funnel.name}
            </DefinitionTeam>
            <DefinitionTeam label="実施するページ">
              {abtestScenarioPageGroup.page.name}
            </DefinitionTeam>
            <DefinitionTeam label="期間設定">
              <Switch isChecked={abtestScenarioPageGroup.repeat} isReadOnly />
              {abtestScenarioPageGroup.repeat && (
                <ABTestDeliverDayOfWeeks
                  abtestScenarioPageGroupRef={abtestScenarioPageGroup}
                />
              )}
            </DefinitionTeam>
            <DefinitionTeam label="メモ">
              <Box wordBreak={"break-all"}>{abtestScenarioPageGroup.memo}</Box>
            </DefinitionTeam>
          </SectionCard>
        </VStack>

        <VStack mt={4}>
          <SectionCard>
            <DefinitionTeam label="デバイス">
              {(abtestScenarioPageGroup.devices || []).map((device) => {
                const label = GraphQLEnums.Device.find(
                  (d) => d.value === device
                )?.label;
                return label && <Chip label={label} key={device} />;
              })}
            </DefinitionTeam>
            <DefinitionTeam label="プラットフォーム">
              {(abtestScenarioPageGroup.platforms || []).map((platform) => {
                const label = GraphQLEnums.Platform.find(
                  (d) => d.value === platform
                )?.label;
                return label && <Chip label={label} key={platform} />;
              })}
            </DefinitionTeam>
            <DefinitionTeam label="ブラウザ">
              {(abtestScenarioPageGroup.browsers || []).map((browser) => {
                const label = GraphQLEnums.Browser.find(
                  (d) => d.value === browser
                )?.label;
                return label && <Chip label={label} key={browser} />;
              })}
            </DefinitionTeam>
            <DefinitionTeam label="性別">
              {(abtestScenarioPageGroup.genders || []).map((gender) => {
                const label = GraphQLEnums.Gender.find(
                  (d) => d.value === gender
                )?.label;
                return label && <Chip label={label} key={gender} />;
              })}
            </DefinitionTeam>
            <DefinitionTeam label="年齢">
              {(abtestScenarioPageGroup.ageRanges || []).map((ageRange) => {
                const label = GraphQLEnums.AgeRange.find(
                  (d) => d.value === ageRange
                )?.label;
                return label && <Chip label={label} key={ageRange} />;
              })}
            </DefinitionTeam>
            <DefinitionTeam label="年収">
              {(abtestScenarioPageGroup.annualIncomes || []).map(
                (annualIncome) => {
                  const label = GraphQLEnums.AnnualIncome.find(
                    (d) => d.value === annualIncome
                  )?.label;
                  return label && <Chip label={label} key={annualIncome} />;
                }
              )}
            </DefinitionTeam>
          </SectionCard>
        </VStack>

        <VStack mt={4}>
          <ABTestDistributionSettingSection
            abtestGroupRef={abtestScenarioPageGroup}
          />
        </VStack>

        <VStack mt={4}>
          <SectionCard>
            <DefinitionTeam label="配信方法">
              {
                GraphQLEnums.DeliverKind.find(
                  (d) => d.value === abtestScenarioPageGroup.deliverKind
                )?.label
              }
            </DefinitionTeam>
          </SectionCard>
        </VStack>
      </Box>
    </PageLayout>
  );
};
