import { Box, Text } from "@chakra-ui/react";
import { ContentCopy as ContentCopyIcon } from "@mui/icons-material";
import { FC, useCallback } from "react";

import { IconButton } from "~/src/components/common/IconButton";
import { SectionCard } from "~/src/components/common/SectionCard";
import { chakraFactory } from "~/src/lib/chakra-ui";

const StyledSectionCard = chakraFactory(SectionCard, {
  baseStyle: {
    maxWidth: "800px",
    margin: "84px auto 0 auto",
  },
});

const StyledDescriptionText = chakraFactory(Text, {
  baseStyle: {
    display: "flex",
    alignItems: "center",
    fontSize: "13px",
    color: "#000000",
  },
});

const StyledSnippetWrapper = chakraFactory(Box, {
  baseStyle: {
    position: "relative",
    background: "#FAFAFA",
    border: "1px solid #DADADA",
    borderRadius: "2px",
    padding: "12px",
    fontSize: "10px",
    letterSpacing: "0.02em",
    color: "#000000",
    whiteSpace: "pre-wrap",
  },
});

const StyledSnippetCopyButton = chakraFactory(IconButton, {
  baseStyle: {
    position: "absolute",
    top: "12px",
    right: "12px",
  },
});

type Props = {
  snippet: string;
};

export const SnippetCard: FC<Props> = ({ snippet }) => {
  const handleCopyButtonClick = useCallback(() => {
    navigator.clipboard.writeText(snippet);
  }, [snippet]);

  return (
    <StyledSectionCard description="ウェブサイトに計測用のタグを貼り付けましょう">
      <StyledDescriptionText mt="12px">
        {`ファネルに登録したすべてのウェブページの<head></head>タグ内に以下のタグを貼り付けて下さい。`}
      </StyledDescriptionText>
      <StyledSnippetWrapper my="24px">
        <StyledSnippetCopyButton
          size="16px"
          label="コピーする"
          icon={<ContentCopyIcon />}
          onClick={handleCopyButtonClick}
        />
        {snippet}
      </StyledSnippetWrapper>
      <StyledDescriptionText>
        データが取得されたらダッシュボードが表示されます。
      </StyledDescriptionText>
      <StyledDescriptionText>
        （タグを貼り付けてからデータを取得するまで時間がかかる場合があります）
      </StyledDescriptionText>
    </StyledSectionCard>
  );
};
