import { Formik, FormikConfig } from "formik";
import { FC, useCallback, useMemo } from "react";
import { graphql, useFragment } from "react-relay";
import { useNavigate, useParams } from "react-router-dom";

import { PopupGroupBulkPageEditForm_popupGroupConnection$key } from "~/src/__relay_artifacts__/PopupGroupBulkPageEditForm_popupGroupConnection.graphql";

import { PopupGroupBulkPageEditListForm } from "./PopupGroupBulkPageEditListForm";
import { Popup } from "./types";
import {
  ArrayFormValues,
  FormValues,
  validationSchema,
} from "./validationSchema";

export type Props = {
  popupGroupConnectionRef: PopupGroupBulkPageEditForm_popupGroupConnection$key;
  onSubmit: FormikConfig<ArrayFormValues>["onSubmit"];
};

const fragment = graphql`
  fragment PopupGroupBulkPageEditForm_popupGroupConnection on PopupGroupConnection {
    edges {
      node {
        id
        slug
        name
        status
        startDate
        endDate
        thumbnailImageUrl
        page {
          id
          kind
          name
          funnel {
            id
            name
          }
        }
      }
    }
  }
`;

export const PopupGroupBulkPageEditForm: FC<Props> = ({
  popupGroupConnectionRef,
  onSubmit,
}) => {
  const navigate = useNavigate();
  const { siteSlug, popupGroupSlugs } = useParams();
  const handleBack = useCallback(() => {
    navigate(`/sites/${siteSlug}/popup_groups`);
  }, [navigate, siteSlug]);

  const { edges } = useFragment(fragment, popupGroupConnectionRef);

  const popups = useMemo(() => {
    return (
      edges?.map((edge) => {
        const node = edge?.node;
        if (!node) throw new Error("assertion failed");
        const popup: Popup = {
          id: node.id,
          slug: node.slug,
          name: node.name,
          status: node.status,
          startDate: node.startDate,
          endDate: node.endDate,
          imageUrl: node.thumbnailImageUrl,
          pageKind: node.page.kind,
          pageName: node.page.name,
          funnelName: node.page.funnel.name,
        };
        return popup;
      }) || []
    );
  }, [edges]);

  const initialValues = useMemo(() => {
    const formValues = popups.map((popup) => {
      return {
        popupGroupId: popup.id,
        pageKind: popup.pageKind,
      } as FormValues;
    });
    return { formValues } as ArrayFormValues;
  }, [popups]);

  return (
    <Formik<ArrayFormValues>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      <PopupGroupBulkPageEditListForm
        popupGroupSlugs={popupGroupSlugs}
        popups={popups}
        handleBack={handleBack}
      />
    </Formik>
  );
};
