import { Box, Flex, Text } from "@chakra-ui/react";
import { FC } from "react";

import { chakraFactory } from "~/src/lib/chakra-ui";

import { CustomVictoryChart } from "./CustomVictoryChart";

export type Props = {
  title: string;
  toolTipTitle: string;
  data: { x: number | string; y: number }[];
};

const StyledWrapperBox = chakraFactory(Box, {
  baseStyle: {
    padding: "24px",
    background: "#FFFFFF",
    border: "1px solid #DADADA",
    boxSizing: "border-box",
  },
});

const StyledHeaderText = chakraFactory(Text, {
  baseStyle: {
    height: "26px",
    fontWeight: 500,
    fontSize: "18px",
    letterSpacing: "0.02em",
    color: "#282828",
  },
});

export const AreaGraph: FC<Props> = ({ title, data, toolTipTitle }) => (
  <StyledWrapperBox>
    <Flex justifyContent="space-between" alignItems="center" mb="16px">
      <StyledHeaderText>{title}</StyledHeaderText>
    </Flex>
    <CustomVictoryChart data={data} toolTipTitle={toolTipTitle} />
  </StyledWrapperBox>
);
