import { useToast } from "@chakra-ui/react";
import { getAuth, signOut } from "firebase/auth";
import { FC, useCallback, useMemo } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";

import { HeaderWithSidebarLayoutContainer_Query } from "~/src/__relay_artifacts__/HeaderWithSidebarLayoutContainer_Query.graphql";

import { HeaderWithSidebarLayout } from "./presentations";

const query = graphql`
  query HeaderWithSidebarLayoutContainer_Query {
    viewer {
      id
      company
      email
      role
    }
  }
`;

export const HeaderWithSidebarLayoutContainer: FC = ({ children }) => {
  const toast = useToast();
  const { siteSlug = "" } = useParams();
  const { viewer } = useLazyLoadQuery<HeaderWithSidebarLayoutContainer_Query>(
    query,
    {}
  );

  const { companyName, email } = useMemo(
    () => ({
      companyName: viewer.company || "",
      email: viewer.email,
    }),
    [viewer.company, viewer.email]
  );

  const handleLogoutClick = useCallback(async () => {
    try {
      const auth = getAuth();
      signOut(auth);
    } catch (err) {
      toast({
        title: "ログアウトに失敗しました",
        status: "error",
      });
    }
  }, [toast]);

  return (
    <HeaderWithSidebarLayout
      companyName={companyName}
      email={email}
      siteSlug={siteSlug}
      onLogoutClick={handleLogoutClick}
      role={viewer.role}
    >
      {children}
    </HeaderWithSidebarLayout>
  );
};
