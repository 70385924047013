/**
 * @generated SignedSource<<12ea4477eeefd8c5326d9fcd98bbc1c1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PopupPatternNameByDateReport_site$data = {
  readonly slug: string;
  readonly url: string;
  readonly reportPopup: {
    readonly rows: ReadonlyArray<{
      readonly popupName: string | null;
      readonly accessDate: string | null;
      readonly readyUser: number;
      readonly pageViewUser: number;
      readonly closeClick: number;
      readonly clickUser: number;
      readonly conversionUser: number;
      readonly impConversionUser: number;
      readonly readyConversionUser: number;
      readonly dropUser: number;
      readonly dropConversionUser: number;
      readonly uniqueUser: number;
      readonly totalConversionUser: number;
      readonly clickUserRate: number;
      readonly conversionClickRate: number;
      readonly conversionImpRate: number;
      readonly conversionDropRate: number;
      readonly totalConversionRate: number;
      readonly mobileRate: number;
      readonly pcRate: number;
      readonly tabletRate: number;
      readonly popupSlug: string | null;
      readonly popupGroupSlug: string | null;
    }>;
    readonly summary: ReadonlyArray<{
      readonly popupName: string | null;
      readonly readyUser: number;
      readonly pageViewUser: number;
      readonly closeClick: number;
      readonly clickUser: number;
      readonly conversionUser: number;
      readonly impConversionUser: number;
      readonly readyConversionUser: number;
      readonly dropUser: number;
      readonly dropConversionUser: number;
      readonly uniqueUser: number;
      readonly totalConversionUser: number;
      readonly clickUserRate: number;
      readonly conversionClickRate: number;
      readonly conversionImpRate: number;
      readonly conversionDropRate: number;
      readonly totalConversionRate: number;
      readonly mobileRate: number;
      readonly pcRate: number;
      readonly tabletRate: number;
    }>;
  };
  readonly " $fragmentType": "PopupPatternNameByDateReport_site";
};
export type PopupPatternNameByDateReport_site = PopupPatternNameByDateReport_site$data;
export type PopupPatternNameByDateReport_site$key = {
  readonly " $data"?: PopupPatternNameByDateReport_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"PopupPatternNameByDateReport_site">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "popupName",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "readyUser",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pageViewUser",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "closeClick",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clickUser",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "conversionUser",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "impConversionUser",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "readyConversionUser",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dropUser",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dropConversionUser",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uniqueUser",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalConversionUser",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clickUserRate",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "conversionClickRate",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "conversionImpRate",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "conversionDropRate",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalConversionRate",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mobileRate",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pcRate",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tabletRate",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "filter"
    },
    {
      "kind": "RootArgument",
      "name": "kind"
    },
    {
      "kind": "RootArgument",
      "name": "order"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PopupPatternNameByDateReport_site",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "filter"
        },
        {
          "kind": "Variable",
          "name": "kind",
          "variableName": "kind"
        },
        {
          "kind": "Variable",
          "name": "order",
          "variableName": "order"
        }
      ],
      "concreteType": "PopupReport",
      "kind": "LinkedField",
      "name": "reportPopup",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PopupReportRow",
          "kind": "LinkedField",
          "name": "rows",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "accessDate",
              "storageKey": null
            },
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            (v9/*: any*/),
            (v10/*: any*/),
            (v11/*: any*/),
            (v12/*: any*/),
            (v13/*: any*/),
            (v14/*: any*/),
            (v15/*: any*/),
            (v16/*: any*/),
            (v17/*: any*/),
            (v18/*: any*/),
            (v19/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "popupSlug",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "popupGroupSlug",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PopupReportRow",
          "kind": "LinkedField",
          "name": "summary",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            (v9/*: any*/),
            (v10/*: any*/),
            (v11/*: any*/),
            (v12/*: any*/),
            (v13/*: any*/),
            (v14/*: any*/),
            (v15/*: any*/),
            (v16/*: any*/),
            (v17/*: any*/),
            (v18/*: any*/),
            (v19/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};
})();

(node as any).hash = "f3a064b3f92840ac5cf918112a44070c";

export default node;
