import { useToast } from "@chakra-ui/react";
import { FormikBag, FormikErrors } from "formik";
import { camelCase } from "lodash";
import { useCallback } from "react";

type ValidationError = {
  path: string;
  message: string;
};

/** サーバーサイドへのリクエスト時にエラーが発生した場合の情報が格納されたオブジェクト */
type ServerError = {
  extensions?: {
    code: string;
    validationErrors?: ValidationError[];
  };
};

const extractValidationErrors = <T>(error: ServerError) => {
  if (!error.extensions || !error.extensions.validationErrors) return;
  return error.extensions.validationErrors.reduce(
    (acc, curr) => ({ ...acc, [camelCase(curr.path)]: curr.message }),
    {}
  ) as FormikErrors<T>;
};

export const useFormErrorHandler = () => {
  const toast = useToast();

  const onFormError = useCallback(
    (
      error: ServerError | Error,
      setErrors: FormikBag<any, any>["setErrors"]
    ) => {
      // NOTE: 500エラーが返ってきた場合はSnackbarにメッセージをそのまま表示する
      if (error instanceof Error) {
        toast({ title: error.message, status: "error" });
        return;
      }

      // NOTE: clientVersion のエラーをハンドリングする
      // 当該のエラーはフォーム上に表示する場所がないのでSnackbarにメッセージを表示する
      const code = error.extensions?.code;
      const validationErrors = error.extensions?.validationErrors || [];
      validationErrors.forEach((e) => {
        toast({ title: `${code}: ${e.message}`, status: "error" });
      });

      // NOTE: バリデーションエラーが返ってきた場合はエラーの配列をパースしてフォームにエラーをセットする
      const errors = extractValidationErrors(error);
      if (errors) setErrors(errors);
    },
    [toast]
  );

  return { onFormError };
};
