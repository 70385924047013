import { Box, Image, SimpleGrid } from "@chakra-ui/react";
import { ArrowForward as ArrowForwardIcon } from "@mui/icons-material";
import { FC } from "react";

import { DefinitionTeam } from "~/src/components/common/DefinitionTeam";
import { HorizontalSeparator } from "~/src/components/common/Separator";

type ScenarioContent = {
  readonly id?: string | undefined;
  readonly imageData?: string | null | undefined;
  readonly beforeSrc?: string | null | undefined;
  readonly imageUrl?: string | null | undefined;
};

export type Props = {
  scenarioContents: ScenarioContent[];
};

export const ABTestPatternScenarioDetailContainer: FC<Props> = ({
  scenarioContents,
}) => {
  return (
    <>
      <DefinitionTeam label="テストの種類">画像置換</DefinitionTeam>
      <HorizontalSeparator />

      {scenarioContents.map((content, index) => (
        <Box marginTop={"15px"} key={index}>
          <DefinitionTeam label="ターゲット" key={index}>
            <SimpleGrid columns={3} spacing={4} alignSelf={"baseline"}>
              <Image
                boxSize="150px"
                src={content.beforeSrc || ""}
                fallbackSrc="https://via.placeholder.com/150"
              />
              <Box alignSelf={"center"} textAlign={"center"}>
                <ArrowForwardIcon />
              </Box>
              <Image
                boxSize="150px"
                src={content.imageUrl || ""}
                fallbackSrc="https://via.placeholder.com/150"
              />
            </SimpleGrid>
          </DefinitionTeam>
          <HorizontalSeparator />
        </Box>
      ))}
    </>
  );
};
