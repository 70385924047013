import { format } from "date-fns";
import { FC, Fragment, useCallback, useContext, useMemo } from "react";
import { graphql, useRefetchableFragment } from "react-relay";
import { NavLink } from "react-router-dom";

import {
  PatternContentJudgeStatus,
  UserRole,
} from "~/src/__generated__/schema";
import { ABTestPatternTableDataRow_abtestPattern$key } from "~/src/__relay_artifacts__/ABTestPatternTableDataRow_abtestPattern.graphql";
import {
  ABTestPatternTableDataRow_RefetchQuery as RefetchQuery,
  ABTestPatternTableDataRow_RefetchQueryVariables as RefetchVariables,
} from "~/src/__relay_artifacts__/ABTestPatternTableDataRow_RefetchQuery.graphql";
import {
  TableDataColumn,
  TableDataRow,
} from "~/src/components/common/tables/Table";
import { DistributionStatusSwitch } from "~/src/components/features/global/DistributionStatusSwitch";
import { DateRangeContext } from "~/src/components/features/global/HeaderNavigation/DateRangeField";
import { useUpdateDebounce } from "~/src/lib/react-use";

import { FormValues } from "./ABTestPatternFilterButton";
import { ABTestPatternTableAcitons } from "./ABTestPatternTableActions";
import { ROW_HEIGHT } from "./styles";

const toStringFromJudgeStatus = (status: PatternContentJudgeStatus): string => {
  switch (status) {
    case "APPROVED":
      return "承認";
    case "REJECTED":
      return "却下";
    default:
      return "未承認";
  }
};

export type Props = {
  abtestPatternRef: ABTestPatternTableDataRow_abtestPattern$key;
  abtestSlug: string;
  dataColumns: string[];
  connectionId: string;
  siteSlug: string;
  filterValues: Pick<FormValues, "sourceId" | "device">;
  role: UserRole;
};

const abtestPatternFragment = graphql`
  fragment ABTestPatternTableDataRow_abtestPattern on AbtestScenarioPage
  @refetchable(queryName: "ABTestPatternTableDataRow_RefetchQuery")
  @argumentDefinitions(input: { type: "ReportAnalyzerInput!" }) {
    id
    slug
    title
    status
    isArchive
    uniqueUserCount(input: $input)
    cvUserCount(input: $input)
    cvr(input: $input)
    averageStayTime(input: $input)
    scrollRate(input: $input)
    kind
    judgeStatus
    weight
  }
`;

export const ABTestPatternTableDataRow: FC<Props> = ({
  abtestPatternRef,
  abtestSlug,
  dataColumns,
  siteSlug,
  connectionId,
  filterValues,
  role,
}) => {
  const {
    dateRange: [startOn, endOn],
  } = useContext(DateRangeContext);
  const [abtestPattern, refetch] = useRefetchableFragment<
    RefetchQuery,
    ABTestPatternTableDataRow_abtestPattern$key
  >(abtestPatternFragment, abtestPatternRef);

  const refetchVariables = useMemo<Partial<RefetchVariables>>(
    () => ({
      input: {
        startOn: format(startOn, "yyyy-MM-dd"),
        endOn: format(endOn, "yyyy-MM-dd"),
        sourceId: filterValues.sourceId,
        device: filterValues.device,
      },
    }),
    [endOn, filterValues.device, filterValues.sourceId, startOn]
  );

  const averageStayTime = useMemo(() => {
    return new Date(abtestPattern.averageStayTime * 1000)
      .toISOString()
      .substring(11, 19);
  }, [abtestPattern.averageStayTime]);

  const handleRefetch = useCallback(() => {
    refetch(refetchVariables);
  }, [refetch, refetchVariables]);

  useUpdateDebounce(
    () => {
      handleRefetch();
    },
    100,
    [handleRefetch]
  );

  return (
    <TableDataRow height={ROW_HEIGHT}>
      <TableDataColumn
        type="text"
        isLink={abtestPattern.kind !== "DEFAULT"}
        data={
          abtestPattern.kind !== "DEFAULT" ? (
            <NavLink
              to={`/sites/${siteSlug}/abtests/${abtestSlug}/patterns/${abtestPattern.slug}`}
            >
              {abtestPattern.title}
            </NavLink>
          ) : (
            abtestPattern.title
          )
        }
      />
      <TableDataColumn
        type="actions"
        data={
          <DistributionStatusSwitch
            id={abtestPattern.id}
            name={abtestPattern.title || "no title"}
            isChecked={abtestPattern.status === "ACTIVE"}
          />
        }
      />
      {role !== "SAAS_CLIENT" && (
        <TableDataColumn
          type="text"
          data={toStringFromJudgeStatus(abtestPattern.judgeStatus)}
        />
      )}
      <TableDataColumn
        type="text"
        data={abtestPattern.weight ? abtestPattern.weight : "ランダム配信"}
      />
      {(dataColumns || []).map((dataColumn) => (
        <Fragment key={dataColumn}>
          {dataColumn === "uniqueUserCount" && (
            <TableDataColumn type="text" data={abtestPattern.uniqueUserCount} />
          )}
          {dataColumn === "cvUserCount" && (
            <TableDataColumn type="text" data={abtestPattern.cvUserCount} />
          )}
          {dataColumn === "cvr" && (
            <TableDataColumn
              type="text"
              data={`${abtestPattern.cvr.toFixed(2)}%`}
            />
          )}
          {dataColumn === "averageStayTime" && (
            <TableDataColumn type="text" data={averageStayTime} />
          )}
          {dataColumn === "scrollRate" && (
            <TableDataColumn
              type="text"
              data={`${abtestPattern.scrollRate.toFixed(2)}%`}
            />
          )}
        </Fragment>
      ))}
      <TableDataColumn
        type="actions"
        data={
          abtestPattern.kind !== "DEFAULT" && (
            <ABTestPatternTableAcitons
              abtest={abtestPattern}
              siteSlug={siteSlug}
              abtestGroupSlug={abtestSlug}
              connectionId={connectionId}
            />
          )
        }
      />
    </TableDataRow>
  );
};
