export const GraphQLEnums = {
  AbtestReportKind: [
    { label: "ABテストパターン(タイトル別)", value: "ABTEST_TITLE" },
    { label: "ABテストパターン(ID別)", value: "ABTEST_ID" },
  ],
  AbtestReportOrderValue: [
    { label: "TODO: ラベル未実装", value: "ABTEST" },
    { label: "TODO: ラベル未実装", value: "ABTEST_GROUP" },
    { label: "TODO: ラベル未実装", value: "FUNNEL" },
    { label: "TODO: ラベル未実装", value: "DATE" },
    { label: "TODO: ラベル未実装", value: "UNIQUE_USER" },
    { label: "TODO: ラベル未実装", value: "CONVERSION_USER" },
    { label: "TODO: ラベル未実装", value: "CVR" },
    { label: "TODO: ラベル未実装", value: "AVERAGE_STAY_TIME" },
    { label: "TODO: ラベル未実装", value: "MOBILE_RATE" },
    { label: "TODO: ラベル未実装", value: "TABLET_RATE" },
    { label: "TODO: ラベル未実装", value: "PC_RATE" },
  ],
  AbtestScenarioPageContentTextReplaceActionKind: [
    { label: "テキスト変更", value: "TEXT_REPLACEMENT" },
    { label: "div追加", value: "DIV_ELEMENT_ADDITION" },
    { label: "attributeを変更", value: "ATTRIBUTE_REPLACEMENT" },
  ],
  AbtestScenarioPageContentTextReplaceTargetKind: [
    { label: "id", value: "ID" },
    { label: "selector", value: "SELECTOR" },
    { label: "xpath", value: "XPATH" },
  ],
  AbtestScenarioPageGroupOrderField: [
    { label: "作成日時", value: "CREATED_AT" },
    { label: "更新日時", value: "UPDATED_AT" },
    { label: "ステータス", value: "STATUS" },
    { label: "実施ファネル", value: "FUNNEL_TITLE" },
  ],
  AbtestScenarioPageGroupReferrerReportOrderField: [
    { label: "ユーザー数", value: "UNIQUE_USER" },
    { label: "コンバージョン数", value: "CONVERSION_USER" },
    { label: "コンバージョン人数", value: "CONVERSION_USER_RATE" },
  ],
  AbtestScenarioPageKind: [
    { label: "デフォルト", value: "DEFAULT" },
    { label: "画像変更", value: "SCENARIO" },
    { label: "リダイレクト", value: "REDIRECT" },
    { label: "テキスト置換", value: "TEXT_REPLACE" },
  ],
  AbtestScenarioPageOrderField: [
    { label: "作成日時", value: "CREATED_AT" },
    { label: "更新日時", value: "UPDATED_AT" },
    { label: "タイプ", value: "KIND" },
  ],
  AgeRange: [
    { label: "12歳未満", value: "LT12" },
    { label: "12歳～19歳", value: "GT12LT19" },
    { label: "20歳～24歳", value: "GT20LT24" },
    { label: "25歳～29歳", value: "GT25LT29" },
    { label: "30歳～34歳", value: "GT30LT34" },
    { label: "35歳～39歳", value: "GT35LT39" },
    { label: "40歳～44歳", value: "GT40LT44" },
    { label: "45歳～49歳", value: "GT45LT49" },
    { label: "50歳～54歳", value: "GT50LT54" },
    { label: "55歳～59歳", value: "GT55LT59" },
  ],
  AnnualIncome: [
    { label: "200万未満", value: "LT200" },
    { label: "200～400万未満", value: "GT200LT400" },
    { label: "400～600万未満", value: "GT400LT600" },
    { label: "600～800万未満", value: "GT600LT800" },
    { label: "800～1000万未満", value: "GT800LT1000" },
    { label: "1000～1200万未満", value: "GT1000LT1200" },
    { label: "1200～1500万未満", value: "GT1200LT1500" },
    { label: "1500～2000万未満", value: "GT1500LT2000" },
    { label: "2000万以上", value: "GT2000" },
  ],
  Browser: [
    { label: "ID", value: "IE" },
    { label: "Chrome", value: "CHROME" },
    { label: "Safari", value: "SAFARI" },
    { label: "Firefox", value: "FIREFOX" },
    { label: "Edge", value: "EDGE" },
    { label: "その他", value: "OTHER" },
  ],
  ChatbotActionKind: [
    { label: "ボタンクリック", value: "BUTTON_CLICK" },
    { label: "待機時間(秒)", value: "DURATION" },
  ],
  ChatbotBotKind: [
    { label: "フォームのみ", value: "FORM" },
    { label: "質問のみ", value: "QUESTION" },
    { label: "フォーム→質問", value: "FORM_AND_QUESTION" },
    { label: "質問→フォーム", value: "QUESTION_AND_FORM" },
  ],
  ChatbotColorKind: [
    { label: "白/緑", value: "DEFAULT" },
    { label: "灰", value: "GRAY" },
    { label: "黒", value: "BLACK" },
    { label: "赤", value: "RED" },
    { label: "青", value: "BLUE" },
    { label: "緑", value: "GREEN" },
    { label: "黃", value: "YELLOW" },
    { label: "橙", value: "ORANGE" },
    { label: "桃", value: "PINK" },
    { label: "濃桃", value: "DARK_PINK" },
    { label: "カスタム", value: "CUSTOM_COLOR" },
  ],
  ChatbotDisplayKind: [
    { label: "画面右下に表示", value: "RIGHT_BOTTOM" },
    { label: "画面左下に表示", value: "LEFT_BOTTOM" },
    { label: "画面右上に表示", value: "RIGHT_TOP" },
    { label: "画面左上に表示", value: "LEFT_TOP" },
  ],
  ChatbotGroupOrderField: [
    { label: "作成日時", value: "CREATED_AT" },
    { label: "更新日時", value: "UPDATED_AT" },
    { label: "終了日", value: "END_ON" },
  ],
  ChatbotKind: [
    { label: "TODO: ラベル未実装", value: "A_PATTERN" },
    { label: "TODO: ラベル未実装", value: "B_PATTERN" },
  ],
  ChatbotOrderField: [
    { label: "作成日時", value: "CREATED_AT" },
    { label: "更新日時", value: "UPDATED_AT" },
    { label: "タイプ", value: "KIND" },
  ],
  ChatbotSubmitMethodKind: [
    { label: "GET", value: "GET" },
    { label: "POST", value: "POST" },
  ],
  ChatMessageFlowNodeKind: [
    { label: "テキスト", value: "TEXT" },
    { label: "画像", value: "IMAGE" },
    { label: "送信ボタン", value: "SUBMIT" },
    { label: "リプライ", value: "REPLY" },
  ],
  ChatMessageKind: [
    { label: "テキスト", value: "TEXT" },
    { label: "画像", value: "IMAGE" },
    { label: "送信ボタン", value: "SUBMIT" },
  ],
  ChatMessageReplyGroupKind: [
    { label: "テキストボックス", value: "TEXT_BOX" },
    { label: "ラジオボタン", value: "RADIO_BUTTON" },
    { label: "チェックボックス", value: "CHECK_BOX" },
    { label: "セレクトボックス", value: "SELECT_BOX" },
    { label: "テキストエリア", value: "TEXT_AREA" },
  ],
  ChatMessageReplyKind: [
    { label: "カスタムテキスト", value: "CUSTOM_TEXT" },
    { label: "API", value: "API" },
    { label: "オプション", value: "OPTION" },
    { label: "氏名", value: "NAME" },
  ],
  DayOfWeek: [
    { label: "日曜日", value: "SUN" },
    { label: "月曜日", value: "MON" },
    { label: "火曜日", value: "TUE" },
    { label: "水曜日", value: "WED" },
    { label: "木曜日", value: "THU" },
    { label: "金曜日", value: "FRI" },
    { label: "土曜日", value: "SUT" },
  ],
  DeliverKind: [
    { label: "均等表示 (※ランダム配信)", value: "EQUAL" },
    { label: "配信比率をつける", value: "WEIGHT" },
  ],
  Device: [
    { label: "スマートフォン", value: "MOBILE" },
    { label: "タブレット", value: "TABLET" },
    { label: "PC", value: "PC" },
  ],
  DisplayPopupAgainStatus: [
    { label: "有効", value: "ACTIVE" },
    { label: "無効", value: "INACTIVE" },
  ],
  DistributionStatus: [
    { label: "有効", value: "ACTIVE" },
    { label: "無効", value: "INACTIVE" },
  ],
  ExcludedIpAddressStatus: [
    { label: "有効", value: "ACTIVE" },
    { label: "無効", value: "INACTIVE" },
  ],
  FunnelOrderField: [
    { label: "作成日時", value: "CREATED_AT" },
    { label: "更新日時", value: "UPDATED_AT" },
    { label: "ファネル名", value: "NAME" },
  ],
  Gender: [
    { label: "男性", value: "MALE" },
    { label: "女性", value: "FEMALE" },
  ],
  GroupContentJudgeStatus: [
    { label: "未承認", value: "PENDING" },
    { label: "承認", value: "APPROVED" },
  ],
  HasForm: [
    { label: "フォームあり", value: "HAS_FORM" },
    { label: "フォームなし", value: "NO_FORM" },
  ],
  MatchOp: [
    { label: "完全一致", value: "EQUAL" },
    { label: "含む", value: "CONTAINS" },
  ],
  NotificationCategory: [
    { label: "新機能・改善", value: "UPGRADE" },
    { label: "サポート", value: "SUPPORT" },
    { label: "障害", value: "INCIDENT" },
    { label: "セミナー・イベント", value: "EVENT" },
    { label: "その他", value: "OTHER" },
  ],
  OrderDirection: [
    { label: "昇順", value: "ASC" },
    { label: "降順", value: "DESC" },
  ],
  PageKind: [
    { label: "ランディングページ", value: "LANDING" },
    { label: "フォームページ", value: "FORM" },
    { label: "確認ページ", value: "CONFIRM" },
    { label: "サンクスページ", value: "THANKS" },
  ],
  PatternContentJudgeStatus: [
    { label: "未承認", value: "PENDING" },
    { label: "承認", value: "APPROVED" },
    { label: "却下", value: "REJECTED" },
  ],
  Platform: [
    { label: "iOS", value: "IOS" },
    { label: "Windows", value: "WINDOWS" },
    { label: "Android", value: "ANDROID" },
    { label: "macOS", value: "MAC" },
    { label: "Linux", value: "LINUX" },
    { label: "その他", value: "OTHER" },
  ],
  PopupActionKind: [
    { label: "待機時間", value: "DURATION" },
    { label: "スクロール率(%)", value: "SCROLL_RATE" },
    { label: "スクロール量(px)", value: "SCROLL_PIXEL" },
    { label: "離脱しようとした時", value: "EXIT_PAGE" },
    {
      label: "離脱しようとした時(スクロール率条件付き1)",
      value: "EXIT_AND_SCROLL_RATE_1",
    },
    {
      label: "離脱しようとした時(スクロール率条件付き2)",
      value: "EXIT_AND_SCROLL_RATE_2",
    },
    {
      label: "離脱しようとした時(スクロール率条件付き3)",
      value: "EXIT_AND_SCROLL_RATE_3",
    },
    { label: "指定範囲内常時表示型(スクロール率)", value: "SCROLL_RATE_RANGE" },
    { label: "カスタム", value: "CUSTOM_EVENT" },
  ],
  PopupAnimationKind: [
    { label: "なし", value: "NO_ANIMATION" },
    { label: "右から", value: "FROM_RIGHT" },
    { label: "左から", value: "FROM_LEFT" },
    { label: "上から", value: "FROM_TOP" },
    { label: "下から", value: "FROM_BOTTOM" },
  ],
  PopupCloseButtonPosition: [
    { label: "右上", value: "TOP_RIGHT" },
    { label: "左上", value: "TOP_LEFT" },
    { label: "左下", value: "BOTTOM_LEFT" },
    { label: "右下", value: "BOTTOM_RIGHT" },
  ],
  PopupClosePopupByClickingOutside: [
    { label: "非表示にしない", value: "DISABLED" },
    { label: "非表示にする", value: "ENABLED" },
  ],
  PopupColorKind: [
    { label: "白/緑", value: "DEFAULT" },
    { label: "白", value: "WHITE" },
    { label: "灰", value: "GRAY" },
    { label: "黒", value: "BLACK" },
    { label: "赤", value: "RED" },
    { label: "青", value: "BLUE" },
    { label: "緑", value: "GREEN" },
    { label: "黃", value: "YELLOW" },
  ],
  PopupContentKind: [
    { label: "画像", value: "IMAGE" },
    { label: "動画", value: "VIDEO" },
    { label: "テキストのみ", value: "TEXT" },
    { label: "HTML", value: "HTML" },
  ],
  PopupDisplayKind: [
    { label: "画面中央に表示", value: "CENTER_MODAL" },
    { label: "画面左端に表示", value: "LEFT_MODAL" },
    { label: "画面右端に表示", value: "RIGHT_MODAL" },
    { label: "画面上端に表示", value: "TOP_MODAL" },
    { label: "画面下端に表示", value: "BOTTOM_MODAL" },
    { label: "画面左下端に表示", value: "LEFT_BOTTOM_MODAL" },
    { label: "画面右下端に表示", value: "RIGHT_BOTTOM_MODAL" },
    { label: "カスタム指定", value: "CUSTOM_MODAL" },
  ],
  PopupGroupDisplayTargetContentKind: [
    { label: "ID", value: "ID" },
    { label: "Selector", value: "SELECTOR" },
  ],
  PopupGroupOrderField: [
    { label: "作成日時", value: "CREATED_AT" },
    { label: "更新日時", value: "UPDATED_AT" },
    { label: "ポップアップ名", value: "NAME" },
    { label: "実施ファネル", value: "FUNNEL_TITLE" },
  ],
  PopupHrefTarget: [
    { label: "別タブ(blank)", value: "BLANK" },
    { label: "同タブ(top)", value: "TOP" },
  ],
  PopupKind: [
    { label: "TODO: ラベル未実装", value: "A_PATTERN" },
    { label: "TODO: ラベル未実装", value: "B_PATTERN" },
  ],
  PopupOrderField: [
    { label: "作成日時", value: "CREATED_AT" },
    { label: "更新日時", value: "UPDATED_AT" },
    { label: "タイプ", value: "KIND" },
    { label: "名前", value: "NAME" },
  ],
  PopupReportKind: [
    { label: "ポップアップパターン(タイトル別)", value: "POPUP_NAME" },
    { label: "ポップアップパターン(ID別)", value: "POPUP_ID" },
    { label: "ポップアップパターン(ページ別)", value: "POPUP_PAGE" },
  ],
  PopupReportOrderValue: [
    { label: "TODO: ラベル未実装", value: "DATE" },
    { label: "TODO: ラベル未実装", value: "POPUP" },
    { label: "TODO: ラベル未実装", value: "POPUP_GROUP" },
    { label: "TODO: ラベル未実装", value: "FUNNEL" },
    { label: "TODO: ラベル未実装", value: "PAGE" },
    { label: "TODO: ラベル未実装", value: "PAGE_VIEW_USER" },
    { label: "TODO: ラベル未実装", value: "READY_USER" },
    { label: "TODO: ラベル未実装", value: "CLOSE_CLICK" },
    { label: "TODO: ラベル未実装", value: "CLICK_USER" },
    { label: "TODO: ラベル未実装", value: "CONVERSION_USER" },
    { label: "TODO: ラベル未実装", value: "IMP_CONVERSION_USER" },
    { label: "TODO: ラベル未実装", value: "READY_CONVERSION_USER" },
    { label: "TODO: ラベル未実装", value: "DROP_USER" },
    { label: "TODO: ラベル未実装", value: "DROP_CONVERSION_USER" },
    { label: "TODO: ラベル未実装", value: "UNIQUE_USER" },
    { label: "TODO: ラベル未実装", value: "TOTAL_CONVERSION_USER" },
    { label: "TODO: ラベル未実装", value: "CLICK_USER_RATE" },
    { label: "TODO: ラベル未実装", value: "CONVERSION_CLICK_RATE" },
    { label: "TODO: ラベル未実装", value: "CONVERSION_IMP_RATE" },
    { label: "TODO: ラベル未実装", value: "TOTAL_CONVERSION_RATE" },
    { label: "TODO: ラベル未実装", value: "CONVERSION_DROP_RATE" },
    { label: "TODO: ラベル未実装", value: "MOBILE_RATE" },
    { label: "TODO: ラベル未実装", value: "TABLET_RATE" },
    { label: "TODO: ラベル未実装", value: "PC_RATE" },
  ],
  PopupVideoScreenKind: [
    { label: "通常画面", value: "NORMAL" },
    { label: "全画面", value: "FULLSCREEN" },
  ],
  PopupYdaTrigger: [
    { label: "クリック時（アフィリエイトのみ）", value: "YDA_AFFI" },
    { label: "クリック時", value: "YDA_ALWAYS" },
    { label: "表示時", value: "YDA_SHOW" },
  ],
  QueryMatch: [
    { label: "全て含む", value: "EQUAL_MATCH" },
    { label: "含む", value: "INCLUDING_MATCH" },
    { label: "前方一致", value: "PREFIX_MATCH" },
  ],
  ScrapingPageContentDevice: [
    { label: "スマートフォン", value: "MOBILE" },
    { label: "タブレット", value: "TABLET" },
    { label: "PC", value: "PC" },
    { label: "他のデバイス", value: "OTHER_DEVICE" },
  ],
  SiteReportOrderField: [
    { label: "ユニークユーザー数", value: "UNIQUE_USER" },
    { label: "CV数", value: "CONVERSION" },
    { label: "CVR", value: "CVR" },
    { label: "LANDING PAGE DROP RATE", value: "LP_DROP_RATE" },
    { label: "LANDING PAGE SCROLL RATE", value: "LP_SCROLL_RATE" },
    { label: "LANDING PAGE AVERAGE STAY TIME", value: "LP_AVERAGE_STAY_TIME" },
    { label: "FORM DROP RATE", value: "FORM_DROP_RATE" },
    { label: "MOBILE", value: "MOBILE_RATE" },
    { label: "TABLET", value: "TABLET_RATE" },
    { label: "PC", value: "PC_RATE" },
    { label: "デバイス", value: "DEVICE" },
    { label: "配信人数", value: "PAGE_VIEW_USER" },
    { label: "チャットボット利用人数", value: "USE_CHATBOT_USER" },
    { label: "チャットボット利用率", value: "USE_CHATBOT_USER_RATE" },
    { label: "CVR(チャットボット)", value: "CHATBOT_CVR" },
    { label: "ポップアップReadyユーザー", value: "POPUP_READY_USER" },
    { label: "ポップアップを閉じた人数", value: "CLOSE_POPUP" },
    { label: "ポップアップクリック数", value: "CLICK_POPUP" },
    {
      label: "ポップアップクリックコンバージョン数",
      value: "POPUP_CLICK_CONVERSION",
    },
    {
      label: "ポップアップインプレッションコンバージョン数",
      value: "POPUP_IMPRESSION_CONVERSION",
    },
    {
      label: "ポップアップインプレッションコンバージョン数",
      value: "POPUP_READY_CONVERSION",
    },
    { label: "ポップアップ離脱アクションしたユーザ数", value: "POPUP_DROP" },
    {
      label: "ポップアップ離脱アクションしたCV人数",
      value: "POPUP_DROP_CONVERSION",
    },
    { label: "ポップアップコンバージョン数合計", value: "POPUP_CONVERSION" },
    { label: "ポップアップCTR", value: "POPUP_CTR" },
    { label: "ポップアップ合計CVR", value: "POPUP_CVR" },
    { label: "ポップアップクリックCVR", value: "POPUP_CLICK_CVR" },
    { label: "ポップアップインプレッションCVR", value: "POPUP_IMPRESSION_CVR" },
    { label: "ポップアップ離脱アクションしたCVR", value: "POPUP_DROP_CVR" },
  ],
  TargetPageKind: [
    { label: "ランディングページ", value: "LANDING" },
    { label: "フォームページ", value: "FORM" },
    { label: "確認ページ", value: "CONFIRM" },
    { label: "サンクスページ", value: "THANKS" },
    { label: "ファネル全体", value: "ALL" },
  ],
  UrlMatch: [
    { label: "完全一致のみ", value: "EQUAL_TYPE" },
    { label: "末尾にidを持つ", value: "HAS_ID_TYPE" },
    { label: "完全一致+末尾にidを持つ", value: "EQUAL_OR_HAS_ID_TYPE" },
    { label: "前方一致", value: "STARTS_WITH_PREFIX_MATCH" },
    { label: "正規表現", value: "REGEX_MATCH_TYPE" },
  ],
  UserRole: [
    { label: "TODO: ラベル未実装", value: "SUPER" },
    { label: "TODO: ラベル未実装", value: "ADMIN_ALL" },
    { label: "TODO: ラベル未実装", value: "ADMIN" },
    { label: "TODO: ラベル未実装", value: "JUDGE" },
    { label: "TODO: ラベル未実装", value: "CLIENT" },
    { label: "TODO: ラベル未実装", value: "SAAS_CLIENT" },
  ],
  VisitMatch: [
    { label: "等しい", value: "EQUAL_MATCH_TYPE" },
    { label: "以上", value: "OVER_MATCH_TYPE" },
    { label: "以下", value: "UNDER_MATCH_TYPE" },
  ],
} as const;
