import { Box, Flex, SimpleGrid } from "@chakra-ui/layout";
import { FC } from "react";

import { PatternContentJudgeStatus } from "~/src/__generated__/schema";
import { SolidButton } from "~/src/components/common/Button";
import { DefinitionTeam } from "~/src/components/common/DefinitionTeam";
import { SectionCard } from "~/src/components/common/SectionCard";

export type Props = {
  id: string;
  targetName: string;
  targetPage: string;
  images: string[];
  onSubmit: (id: string, status: PatternContentJudgeStatus) => Promise<void>;
};

export const JudgementListSection: FC<Props> = ({
  id,
  onSubmit,
  targetName,
  targetPage,
  images,
}) => {
  return (
    <Box marginTop={"8"}>
      <SectionCard>
        <Flex justifyContent={"flex-end"}>
          <Box paddingLeft={4}>
            <SolidButton
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() => onSubmit(id, "APPROVED")}
            >
              承認
            </SolidButton>
          </Box>
          <Box paddingLeft={4}>
            <SolidButton
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() => onSubmit(id, "REJECTED")}
              backgroundColor={"#ff5252"}
            >
              却下
            </SolidButton>
          </Box>
          <Box paddingLeft={4}>
            <SolidButton
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() => onSubmit(id, "PENDING")}
              backgroundColor={"#979797"}
            >
              未承認に戻す
            </SolidButton>
          </Box>
        </Flex>
        <DefinitionTeam label="案件">{targetName}</DefinitionTeam>
        <DefinitionTeam label="実施ページ">
          <a href={targetPage} target="_blank" rel="noreferrer">
            {targetPage}
          </a>
        </DefinitionTeam>
        <SimpleGrid spacing={10} columns={5}>
          {images.map((image, index) => (
            <Box key={index}>
              <img src={image} alt="" />
            </Box>
          ))}
        </SimpleGrid>
      </SectionCard>
    </Box>
  );
};
