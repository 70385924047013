import { Box } from "@chakra-ui/react";

import { chakraFactory } from "~/src/lib/chakra-ui";

import { TABLE_ROW_HEIGHT_PX } from "./constants";

export const Table = chakraFactory(Box, {
  baseStyle: {
    display: "grid",
    gridAutoFlow: "row",
    width: "100%",
    maxHeight: `${TABLE_ROW_HEIGHT_PX * 12}px`,
    overflow: "auto",
    my: "16px",
  },
});
