import { Box, Flex, Text } from "@chakra-ui/react";
import { DeleteOutline as DeleteOutlineIcon } from "@mui/icons-material";
import { FC, useCallback } from "react";

import { IconButton } from "~/src/components/common/IconButton";
import { HorizontalSeparator } from "~/src/components/common/Separator";
import { chakraFactory } from "~/src/lib/chakra-ui";

export type Props = {
  index: number;
  onDeleteClick: (index: Props["index"]) => void;
};

export const StyledDescriptionText = chakraFactory(Text, {
  baseStyle: {
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
    color: "#000000",
  },
});

export const FormUrlsFieldSeparator: FC<Props> = ({ index, onDeleteClick }) => {
  const handleDeleteClick = useCallback(() => {
    onDeleteClick(index);
  }, [index, onDeleteClick]);

  return (
    <Box width="100%">
      <HorizontalSeparator />
      <Flex my="16px" justifyContent="space-between" alignItems="center">
        <StyledDescriptionText>
          入力フォームページ-{index + 1}
        </StyledDescriptionText>
        <IconButton
          size="16px"
          icon={<DeleteOutlineIcon />}
          onClick={handleDeleteClick}
        />
      </Flex>
    </Box>
  );
};
