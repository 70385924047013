/**
 * @generated SignedSource<<c6f1180c3058d348ef0c54587d2e8861>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CopyPopupGroupInput = {
  clientMutationId?: string | null;
  popupGroupId: string;
  pageId: string;
};
export type PopupTableActions_CopyMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CopyPopupGroupInput;
};
export type PopupTableActions_CopyMutationVariables = PopupTableActions_CopyMutation$variables;
export type PopupTableActions_CopyMutation$data = {
  readonly copyPopupGroup: {
    readonly popupGroup: {
      readonly " $fragmentSpreads": FragmentRefs<"PopupTableDataRow_popupGroup">;
    } | null;
  } | null;
};
export type PopupTableActions_CopyMutationResponse = PopupTableActions_CopyMutation$data;
export type PopupTableActions_CopyMutation = {
  variables: PopupTableActions_CopyMutationVariables;
  response: PopupTableActions_CopyMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PopupTableActions_CopyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CopyPopupGroupPayload",
        "kind": "LinkedField",
        "name": "copyPopupGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PopupGroup",
            "kind": "LinkedField",
            "name": "popupGroup",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "PopupTableDataRow_popupGroup"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PopupTableActions_CopyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CopyPopupGroupPayload",
        "kind": "LinkedField",
        "name": "copyPopupGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PopupGroup",
            "kind": "LinkedField",
            "name": "popupGroup",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endDate",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "memo",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "page",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "kind",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sortNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Funnel",
                    "kind": "LinkedField",
                    "name": "funnel",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "siteUrl",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isArchive",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "kind",
                    "value": "B_PATTERN"
                  }
                ],
                "concreteType": "PopupConnection",
                "kind": "LinkedField",
                "name": "popups",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  }
                ],
                "storageKey": "popups(kind:\"B_PATTERN\")"
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "thumbnailImageUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updatedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "judgeStatus",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "popupGroup",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "PopupGroupEdge"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c35f97ee7907498cb494ded4b66084dd",
    "id": null,
    "metadata": {},
    "name": "PopupTableActions_CopyMutation",
    "operationKind": "mutation",
    "text": "mutation PopupTableActions_CopyMutation(\n  $input: CopyPopupGroupInput!\n) {\n  copyPopupGroup(input: $input) {\n    popupGroup {\n      ...PopupTableDataRow_popupGroup\n      id\n    }\n  }\n}\n\nfragment PopupTableDataRow_popupGroup on PopupGroup {\n  endDate\n  id\n  memo\n  name\n  page {\n    id\n    slug\n    name\n    kind\n    sortNumber\n    url\n    funnel {\n      id\n      name\n      slug\n      siteUrl\n    }\n  }\n  isArchive\n  popups(kind: B_PATTERN) {\n    totalCount\n  }\n  slug\n  status\n  startDate\n  thumbnailImageUrl\n  updatedAt\n  judgeStatus\n}\n"
  }
};
})();

(node as any).hash = "78c60a49424b436e4c1784721f422831";

export default node;
