import { FC, useCallback, useEffect, useMemo } from "react";
import { graphql, usePaginationFragment } from "react-relay";

import { UserRole } from "~/src/__generated__/schema";
import {
  PopupPatternTable_popupGroup,
  PopupPatternTable_popupGroup$key,
} from "~/src/__relay_artifacts__/PopupPatternTable_popupGroup.graphql";
import {
  PopupPatternTable_RefetchQuery as RefetchQuery,
  PopupPatternTable_RefetchQueryVariables as RefetchVariables,
} from "~/src/__relay_artifacts__/PopupPatternTable_RefetchQuery.graphql";
import {
  Table,
  TableHeaderColumn,
  TableHeaderRow,
} from "~/src/components/common/tables/Table";
import {
  TablePagination,
  useTablePaginationProps,
} from "~/src/components/common/tables/TablePagination";
import { useUpdateDebounce } from "~/src/lib/react-use";

import { FormValues } from "./PopupPatternFilterButton";
import { PopupPatternTableDataRow } from "./PopupPatternTableDataRow";

export type Popup = NonNullable<
  NonNullable<
    NonNullable<NonNullable<PopupPatternTable_popupGroup["popups"]>["edges"]>[0]
  >["node"]
>;

export type Props = {
  role: UserRole;
  readonly popupGroupRef: PopupPatternTable_popupGroup$key;
  readonly siteSlug: string;
  readonly filterValues: FormValues;
  readonly headerColumns: string[];
  readonly dataColumns: string[];
  readonly onPopupsChange: (popups: Popup[]) => void;
};

const fragment = graphql`
  fragment PopupPatternTable_popupGroup on PopupGroup
  @refetchable(queryName: "PopupPatternTable_RefetchQuery")
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 120 }
    cursor: { type: "String" }
    withArchived: { type: "Boolean" }
    orderBy: {
      type: "PopupOrder"
      defaultValue: { field: KIND, direction: ASC }
    }
    analyzerInput: { type: "ReportAnalyzerInput!" }
    status: { type: "DistributionStatus" }
  ) {
    slug
    popups(
      first: $count
      after: $cursor
      withArchived: $withArchived
      orderBy: $orderBy
      status: $status
    ) @connection(key: "PopupPatternTable_popupGroup_popups") {
      __id
      totalCount
      edges {
        node {
          id
          slug
          title
          weight
          ...PopupPatternTableDataRow_popup @arguments(input: $analyzerInput)
        }
      }
    }
  }
`;

export const PopupPatternTable: FC<Props> = ({
  popupGroupRef,
  filterValues,
  siteSlug,
  headerColumns,
  dataColumns,
  onPopupsChange,
  role,
}) => {
  const {
    data: popupGroup,
    hasNext,
    loadNext,
    refetch,
  } = usePaginationFragment<RefetchQuery, PopupPatternTable_popupGroup$key>(
    fragment,
    popupGroupRef
  );
  const { tablePaginationProps } = useTablePaginationProps({
    hasNext,
    loadNext,
    refetch,
    totalCount: popupGroup.popups?.totalCount || 0,
  });

  const refetchVariables = useMemo<Partial<RefetchVariables>>(
    () => ({
      count: tablePaginationProps.perPage,
      withArchived: filterValues.withArchived,
      orderBy: { field: "KIND", direction: "ASC" },
      status: filterValues.status,
    }),
    [
      filterValues.withArchived,
      tablePaginationProps.perPage,
      filterValues.status,
    ]
  );

  const popups = useMemo(() => {
    const from = tablePaginationProps.from;
    const to = tablePaginationProps.to;
    const egdes = popupGroup.popups?.edges || [];
    return egdes.slice(from, to).map((edge) => {
      const node = edge?.node;
      if (!node) throw new Error("assertion failed");
      return node;
    });
  }, [
    popupGroup.popups?.edges,
    tablePaginationProps.from,
    tablePaginationProps.to,
  ]);

  const handleRefetch = useCallback(() => {
    refetch(refetchVariables);
  }, [refetch, refetchVariables]);

  useEffect(() => {
    onPopupsChange(popups);
  }, [onPopupsChange, popups]);

  useUpdateDebounce(
    () => {
      handleRefetch();
    },
    100,
    [handleRefetch]
  );

  return (
    <>
      <Table>
        <TableHeaderRow>
          <TableHeaderColumn>名前</TableHeaderColumn>
          <TableHeaderColumn>実施ステータス</TableHeaderColumn>
          {role !== "SAAS_CLIENT" && (
            <TableHeaderColumn>承認状態</TableHeaderColumn>
          )}
          <TableHeaderColumn>配信比率</TableHeaderColumn>
          <TableHeaderColumn>画像</TableHeaderColumn>
          {headerColumns.map((headerColumn) => (
            <TableHeaderColumn key={headerColumn}>
              {headerColumn}
            </TableHeaderColumn>
          ))}
          <TableHeaderColumn>アクション</TableHeaderColumn>
        </TableHeaderRow>
        {popups.map((popup) => (
          <PopupPatternTableDataRow
            key={popup.slug}
            dataColumns={dataColumns}
            popupRef={popup}
            siteSlug={siteSlug}
            popupGroupSlug={popupGroup.slug}
            connectionId={popupGroup.popups?.__id || ""}
            filterValues={filterValues}
            role={role}
          />
        ))}
      </Table>
      <TablePagination {...tablePaginationProps} />
    </>
  );
};
