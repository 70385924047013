/**
 * @generated SignedSource<<9ee8d0fc6f375c14aea64276eec4e02b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ScriptTagSettingTabContainer_Mutation$variables = {
  siteId: string;
  userTag?: string | null;
};
export type ScriptTagSettingTabContainer_MutationVariables = ScriptTagSettingTabContainer_Mutation$variables;
export type ScriptTagSettingTabContainer_Mutation$data = {
  readonly upsertUserTag: {
    readonly site: {
      readonly id: string;
      readonly embedTag: string | null;
    };
  } | null;
};
export type ScriptTagSettingTabContainer_MutationResponse = ScriptTagSettingTabContainer_Mutation$data;
export type ScriptTagSettingTabContainer_Mutation = {
  variables: ScriptTagSettingTabContainer_MutationVariables;
  response: ScriptTagSettingTabContainer_Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "siteId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userTag"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "siteId",
            "variableName": "siteId"
          },
          {
            "kind": "Variable",
            "name": "userTag",
            "variableName": "userTag"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "UpsertUserTagPayload",
    "kind": "LinkedField",
    "name": "upsertUserTag",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "embedTag",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ScriptTagSettingTabContainer_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ScriptTagSettingTabContainer_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d349ee3ff97cd5e6cff974799f86fabb",
    "id": null,
    "metadata": {},
    "name": "ScriptTagSettingTabContainer_Mutation",
    "operationKind": "mutation",
    "text": "mutation ScriptTagSettingTabContainer_Mutation(\n  $siteId: ID!\n  $userTag: String\n) {\n  upsertUserTag(input: {siteId: $siteId, userTag: $userTag}) {\n    site {\n      id\n      embedTag\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a30c14f4a79b9dd0cb0a97f1baf9999e";

export default node;
