import { Box } from "@chakra-ui/layout";
import { FC, useCallback } from "react";
import { ConnectionHandler, graphql, useLazyLoadQuery } from "react-relay";
import { useNavigate, useParams } from "react-router-dom";

import { ABTestCreatePageContainer_Mutation } from "~/src/__relay_artifacts__/ABTestCreatePageContainer_Mutation.graphql";
import { ABTestCreatePageContainer_Query } from "~/src/__relay_artifacts__/ABTestCreatePageContainer_Query.graphql";
import { WizardStep } from "~/src/components/common/WizardStep";
import { ABTestForm, ABTestFormProps } from "~/src/components/features/abtest";
import { PageLayout } from "~/src/components/features/global/PageLayout";
import { useFormErrorHandler, useQueryString } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";

export type Props = {};

type Params = {
  funnel: string | null;
};

const query = graphql`
  query ABTestCreatePageContainer_Query($slug: String!) {
    site(slug: $slug) {
      id
      name
      slug
      ...ABTestForm_site
    }
  }
`;

const mutation = graphql`
  mutation ABTestCreatePageContainer_Mutation(
    $input: CreateAbtestScenarioPageGroupInput!
    $connections: [ID!]!
  ) {
    createAbtestScenarioPageGroup(input: $input) {
      abtestScenarioPageGroupEdge @appendEdge(connections: $connections) {
        node {
          id
          title
          slug
          page {
            id
            funnel {
              id
            }
          }
          platforms
          browsers
          devices
          genders
          ageRanges
          deliverKind
          repeat
          startDate
          startTime
          endDate
          endTime
          sources {
            id
            name
          }
          visitCount
          visitMatchType
          deliverTargetUrls {
            url
          }
          deliverExcludeUrls {
            url
          }
          deliverTargetLabels {
            id
            name
          }
          deliverExcludeLabels {
            id
            name
          }
          targetCookies {
            key
            value
            matchType
          }
          deliverDayOfWeeks {
            weekOfMonth
            daysOfWeek
          }
        }
      }
    }
  }
`;

export const ABTestCreatePageContainer: FC<Props> = () => {
  const navigate = useNavigate();
  const { siteSlug = "" } = useParams();
  const { params } = useQueryString<Params>();
  const { onFormError } = useFormErrorHandler();
  const { site } = useLazyLoadQuery<ABTestCreatePageContainer_Query>(query, {
    slug: siteSlug,
  });
  const mutate =
    useMutationCommit<ABTestCreatePageContainer_Mutation>(mutation);

  const handleCancel = useCallback(() => navigate(-1), [navigate]);

  const handleSubmit = useCallback<ABTestFormProps["onSubmit"]>(
    async ({ repeat, funnelId, ...values }, { setErrors }) => {
      try {
        const connectionId = ConnectionHandler.getConnectionID(
          site.id,
          "ABTestListPage_abtestScenarioPageGroups"
        );
        const { createAbtestScenarioPageGroup } = await mutate({
          variables: {
            connections: [connectionId],
            input: {
              ...values,
              startDate: repeat
                ? values.startDate?.toISOString() || null
                : null,
              startTime: repeat
                ? values.startTime?.toISOString() || null
                : null,
              endDate: repeat ? values.endDate?.toISOString() || null : null,
              endTime: repeat ? values.endTime?.toISOString() || null : null,
              deliverDayOfWeeks: repeat ? values.deliverDayOfWeeks : [],
            },
          },
        });
        const createdAbtestGroup =
          createAbtestScenarioPageGroup?.abtestScenarioPageGroupEdge?.node;
        if (!createdAbtestGroup) throw new Error("assertion failed");
        navigate(
          `/sites/${site.slug}/abtests/${createdAbtestGroup?.slug}/patterns/new`
        );
      } catch (err) {
        onFormError(err, setErrors);
      }
      return Promise.resolve();
    },
    [mutate, navigate, onFormError, site.id, site.slug]
  );

  return (
    <PageLayout
      title="A/Bテスト登録"
      breadcrumbs={[
        { label: "A/Bテスト一覧", path: `/sites/${siteSlug}/abtests` },
        {
          label: "A/Bテスト登録",
          path: `/sites/${siteSlug}/abtests/new`,
        },
      ]}
    >
      <Box mt="16px" p="16px">
        <WizardStep
          steps={[
            { label: "基本設定", active: true },
            { label: "パターン設定", active: false },
          ]}
        />
      </Box>
      <Box my="16px" p="16px">
        <ABTestForm
          siteRef={site}
          onCancelClick={handleCancel}
          onSubmit={handleSubmit}
          isFunnelDisabled={params.funnel ? true : false}
          funnelId={params.funnel}
        />
      </Box>
    </PageLayout>
  );
};
