import { Box, HStack, Text } from "@chakra-ui/layout";
import { FC, useCallback } from "react";

import { GhostButton, SolidButton } from "~/src/components/common/Button";
import { Modal } from "~/src/components/common/Modal";

type Props = {
  name: string | null;
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
};

export const DeleteConfirmDialog: FC<Props> = ({
  name,
  isOpen,
  onClose,
  onDelete,
}) => {
  const handleDelete = useCallback(() => {
    onDelete();
    onClose();
  }, [onClose, onDelete]);

  return (
    <Modal title="完全に削除" isOpen={isOpen} onClose={onClose}>
      <Box my="16px">
        <Text>
          {`${
            name ? `「${name}」を` : ""
          }削除しますか？削除すると元に戻すことはできません。`}
        </Text>
      </Box>
      <HStack mt="16px" spacing="8px" justifyContent="flex-end">
        <GhostButton onClick={onClose}>戻る</GhostButton>
        <SolidButton onClick={handleDelete}>削除</SolidButton>
      </HStack>
    </Modal>
  );
};
