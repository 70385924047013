import { FC } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";

import { PopupGroupListPageContainer_Query } from "~/src/__relay_artifacts__/PopupGroupListPageContainer_Query.graphql";
import { ListFilterFormValues as FormValues } from "~/src/components/features/filter";
import { useListFilterCache } from "~/src/lib/hooks";

import { PopupListPage } from "./presentations";

export type Props = {};

const query = graphql`
  query PopupGroupListPageContainer_Query(
    $siteSlug: String!
    $pageIds: [ID!]
    $funnelIds: [ID!]
    $pageKind: PageKind
    $sourceId: ID
    $device: Device
    $withArchived: Boolean
    $status: DistributionStatus
  ) {
    viewer {
      role
    }
    site(slug: $siteSlug) {
      name
      ...PopupListPage_site
        @arguments(
          pageIds: $pageIds
          funnelIds: $funnelIds
          pageKind: $pageKind
          sourceId: $sourceId
          device: $device
          withArchived: $withArchived
          status: $status
        )
    }
  }
`;

export const PopupGroupListPageContainer: FC<Props> = () => {
  const { siteSlug = "" } = useParams();
  const initialFilterFormValues: FormValues = {
    pageIds: [],
    funnelIds: [],
    pageKind: null,
    sourceId: null,
    device: null,
    withArchived: null,
    status: null,
  };
  const { filterValues, setFilterValues } = useListFilterCache({
    cacheKey: `PopupListPage_${siteSlug}`,
    initialFilterValues: initialFilterFormValues,
  });

  const { site, viewer } = useLazyLoadQuery<PopupGroupListPageContainer_Query>(
    query,
    {
      siteSlug,
      ...filterValues,
    }
  );

  return (
    <PopupListPage
      siteRef={site}
      role={viewer.role}
      filterValues={filterValues}
      setFilterValues={setFilterValues}
    />
  );
};
