/**
 * @generated SignedSource<<cfa8a9066f74d4eb3d83621e5abcf89b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Device = "MOBILE" | "TABLET" | "PC" | "%future added value";
export type DistributionStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type ReportAnalyzerInput = {
  startOn: string;
  endOn: string;
  device?: Device | null;
  sourceId?: string | null;
  funnelId?: string | null;
};
export type ChatbotPatternTable_Query$variables = {
  analyzerInput: ReportAnalyzerInput;
  count?: number | null;
  cursor?: string | null;
  status?: DistributionStatus | null;
  withArchived?: boolean | null;
  id: string;
};
export type ChatbotPatternTable_QueryVariables = ChatbotPatternTable_Query$variables;
export type ChatbotPatternTable_Query$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"ChatbotPatternTable_chatbotGroup">;
  } | null;
};
export type ChatbotPatternTable_QueryResponse = ChatbotPatternTable_Query$data;
export type ChatbotPatternTable_Query = {
  variables: ChatbotPatternTable_QueryVariables;
  response: ChatbotPatternTable_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "analyzerInput"
},
v1 = {
  "defaultValue": 120,
  "kind": "LocalArgument",
  "name": "count"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "withArchived"
},
v6 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v7 = {
  "kind": "Variable",
  "name": "status",
  "variableName": "status"
},
v8 = {
  "kind": "Variable",
  "name": "withArchived",
  "variableName": "withArchived"
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v12 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": {
      "direction": "ASC",
      "field": "KIND"
    }
  },
  (v7/*: any*/),
  (v8/*: any*/)
],
v13 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "analyzerInput"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ChatbotPatternTable_Query",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "analyzerInput",
                "variableName": "analyzerInput"
              },
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "ChatbotPatternTable_chatbotGroup"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "ChatbotPatternTable_Query",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v11/*: any*/),
              {
                "alias": null,
                "args": (v12/*: any*/),
                "concreteType": "ChatbotConnection",
                "kind": "LinkedField",
                "name": "chatbots",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ChatbotEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Chatbot",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          (v11/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "weight",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isArchive",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "kind",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": (v13/*: any*/),
                            "kind": "ScalarField",
                            "name": "pvUserCount",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": (v13/*: any*/),
                            "kind": "ScalarField",
                            "name": "startUserCount",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": (v13/*: any*/),
                            "kind": "ScalarField",
                            "name": "cvUserCount",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": (v13/*: any*/),
                            "kind": "ScalarField",
                            "name": "ctr",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": (v13/*: any*/),
                            "kind": "ScalarField",
                            "name": "cvr",
                            "storageKey": null
                          },
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v12/*: any*/),
                "filters": [
                  "withArchived",
                  "orderBy",
                  "status"
                ],
                "handle": "connection",
                "key": "ChatbotPatternTable_chatbotGroup_chatbots",
                "kind": "LinkedHandle",
                "name": "chatbots"
              }
            ],
            "type": "ChatbotGroup",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1b9265f8b8970c02ac5f4ad8745ef147",
    "id": null,
    "metadata": {},
    "name": "ChatbotPatternTable_Query",
    "operationKind": "query",
    "text": "query ChatbotPatternTable_Query(\n  $analyzerInput: ReportAnalyzerInput!\n  $count: Int = 120\n  $cursor: String\n  $status: DistributionStatus\n  $withArchived: Boolean\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...ChatbotPatternTable_chatbotGroup_D2HLY\n    id\n  }\n}\n\nfragment ChatbotPatternTableDataRow_chatbot_9vDjd on Chatbot {\n  id\n  slug\n  name\n  status\n  isArchive\n  weight\n  kind\n  pvUserCount(input: $analyzerInput)\n  startUserCount(input: $analyzerInput)\n  cvUserCount(input: $analyzerInput)\n  ctr(input: $analyzerInput)\n  cvr(input: $analyzerInput)\n}\n\nfragment ChatbotPatternTable_chatbotGroup_D2HLY on ChatbotGroup {\n  slug\n  chatbots(first: $count, after: $cursor, withArchived: $withArchived, orderBy: {field: KIND, direction: ASC}, status: $status) {\n    totalCount\n    edges {\n      node {\n        id\n        slug\n        name\n        weight\n        ...ChatbotPatternTableDataRow_chatbot_9vDjd\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "6decfca551355287eab58db8e12ddb5f";

export default node;
