import { Text } from "@chakra-ui/layout";
import { FC } from "react";
import { graphql, useFragment } from "react-relay";

import { GraphQLEnums } from "~/src/__generated__/GraphQLEnums";
import { PopupDistributionSettingSection_popupGroup$key } from "~/src/__relay_artifacts__/PopupDistributionSettingSection_popupGroup.graphql";
import { Chip } from "~/src/components/common/Chip";
import { DefinitionTeam } from "~/src/components/common/DefinitionTeam";
import { SectionCard } from "~/src/components/common/SectionCard";

type Props = {
  popupGroupRef: PopupDistributionSettingSection_popupGroup$key;
};

const fragment = graphql`
  fragment PopupDistributionSettingSection_popupGroup on PopupGroup {
    sources {
      id
      name
    }
    excludeSources {
      id
      name
    }
    referrers {
      referrer
      matchOp
    }
    excludeReferrers {
      referrer
      matchOp
    }
    visitCount
    visitMatchType
    deliverTargetUrls {
      id
      url
    }
    deliverExcludeUrls {
      id
      url
    }
    deliverTargetLabels {
      id
      name
    }
    deliverExcludeLabels {
      id
      name
    }
    targetCookies {
      id
      key
      value
      matchType
    }
    displayTargetContents {
      targetKind
      targetValue
    }
    disableTargetElements {
      targetKind
      targetValue
    }
  }
`;

export const PopupDistributionSettingSection: FC<Props> = ({
  popupGroupRef,
}) => {
  const popupGroup = useFragment(fragment, popupGroupRef);

  return (
    <SectionCard description="配信設定">
      <DefinitionTeam label="流入元カテゴリ">
        {popupGroup.sources.map((source) => (
          <Chip key={source.id} label={source.name} />
        ))}
      </DefinitionTeam>

      <DefinitionTeam label="非表示の流入元カテゴリ">
        {popupGroup.excludeSources.map((source) => (
          <Chip key={source.id} label={source.name} />
        ))}
      </DefinitionTeam>

      <DefinitionTeam label="流入元">
        {popupGroup.referrers.map((referrer, index) => (
          <DefinitionTeam
            key={index}
            dense
            label={
              GraphQLEnums.MatchOp.find((op) => op.value === referrer.matchOp)
                ?.label || ""
            }
          >
            {referrer.referrer}
          </DefinitionTeam>
        ))}
      </DefinitionTeam>

      <DefinitionTeam label="除外流入元">
        {popupGroup.excludeReferrers.map((referrer, index) => (
          <DefinitionTeam
            key={index}
            dense
            label={
              GraphQLEnums.MatchOp.find((op) => op.value === referrer.matchOp)
                ?.label || ""
            }
          >
            {referrer.referrer}
          </DefinitionTeam>
        ))}
      </DefinitionTeam>
      <DefinitionTeam label="ユーザーの訪問回数">
        <DefinitionTeam
          dense
          label={
            GraphQLEnums.VisitMatch.find(
              (op) => op.value === popupGroup.visitMatchType
            )?.label || ""
          }
        >
          {popupGroup.visitCount ? `${popupGroup.visitCount} 回` : "未設定"}
        </DefinitionTeam>
      </DefinitionTeam>
      <DefinitionTeam label="表示条件の要素">
        {(popupGroup.displayTargetContents || []).map((content, i) => (
          <DefinitionTeam
            key={i}
            dense
            label={
              GraphQLEnums.PopupGroupDisplayTargetContentKind.find(
                (op) => op.value === content.targetKind
              )?.label || ""
            }
          >
            {content.targetValue}
          </DefinitionTeam>
        ))}
      </DefinitionTeam>
      <DefinitionTeam label="非表示条件の要素">
        {(popupGroup.disableTargetElements || []).map((content, i) => (
          <DefinitionTeam
            key={i}
            dense
            label={
              GraphQLEnums.PopupGroupDisplayTargetContentKind.find(
                (op) => op.value === content.targetKind
              )?.label || ""
            }
          >
            {content.targetValue}
          </DefinitionTeam>
        ))}
      </DefinitionTeam>
      <DefinitionTeam label="URL">
        <Text>{popupGroup.deliverTargetUrls.map((url) => url.url)}</Text>
      </DefinitionTeam>
      <DefinitionTeam label="除外URL">
        {popupGroup.deliverExcludeUrls.map((url) => (
          <Text key={url.id}>{url.url}</Text>
        ))}
      </DefinitionTeam>
      <DefinitionTeam label="ラベル">
        {popupGroup.deliverTargetLabels.map((label) => (
          <Chip key={label.id} label={label.name} />
        ))}
      </DefinitionTeam>
      <DefinitionTeam label="除外ラベル">
        {popupGroup.deliverExcludeLabels.map((label) => (
          <Chip key={label.id} label={label.name} />
        ))}
      </DefinitionTeam>
      <DefinitionTeam label="Cookie">
        {popupGroup.targetCookies.map((cookie) => (
          <DefinitionTeam
            key={cookie.id}
            dense
            label={cookie.matchType ? "一致" : "一致しない"}
          >
            <DefinitionTeam dense label="key">
              {cookie.key}
            </DefinitionTeam>
            <DefinitionTeam dense label="value">
              {cookie.value}
            </DefinitionTeam>
          </DefinitionTeam>
        ))}
      </DefinitionTeam>
    </SectionCard>
  );
};
