import { VStack } from "@chakra-ui/react";
import { Form, Formik, FormikConfig } from "formik";
import { FC } from "react";

import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormikFormButtons } from "~/src/components/common/forms/FormikFormButtons";
import { FormikTextareaField } from "~/src/components/common/forms/FormikTextareaField";
import { FormikTextField } from "~/src/components/common/forms/FormikTextField";

import {
  PopupSetFormValues as FormValues,
  popupSetValidationSchema as validationSchema,
} from "./validationSchema";

export type Props = {
  initialValues?: FormValues;
  onCancelClick: () => void;
  onSubmit: FormikConfig<FormValues>["onSubmit"];
};

const defaultInitialValues = {
  name: "",
  memo: "",
};

export const PopupSetForm: FC<Props> = ({
  initialValues = defaultInitialValues,
  onCancelClick,
  onSubmit,
}) => (
  <Formik<FormValues>
    enableReinitialize
    initialValues={initialValues}
    validationSchema={validationSchema}
    validateOnMount={false}
    validateOnChange={false}
    onSubmit={onSubmit}
  >
    <Form>
      <VStack spacing="16px">
        <FormControl required label="名前">
          <FormikTextField type="text" mode="fill" name="name" />
        </FormControl>
        <FormControl required label="メモ">
          <FormikTextareaField mode="fill" name="memo" />
        </FormControl>
      </VStack>
      <FormikFormButtons onCancelClick={onCancelClick} />
    </Form>
  </Formik>
);
