/**
 * @generated SignedSource<<8533ac9199e1febf04eb15d0ff88e17d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChatbotFirstStepForm_Query$variables = {};
export type ChatbotFirstStepForm_QueryVariables = ChatbotFirstStepForm_Query$variables;
export type ChatbotFirstStepForm_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ChatbotDistributionSettingFields_root">;
};
export type ChatbotFirstStepForm_QueryResponse = ChatbotFirstStepForm_Query$data;
export type ChatbotFirstStepForm_Query = {
  variables: ChatbotFirstStepForm_QueryVariables;
  response: ChatbotFirstStepForm_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ChatbotFirstStepForm_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ChatbotDistributionSettingFields_root"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ChatbotFirstStepForm_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SourceConnection",
        "kind": "LinkedField",
        "name": "sources",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SourceEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Source",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": (v1/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserLabelConnection",
            "kind": "LinkedField",
            "name": "userLabels",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserLabelEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserLabel",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v1/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "43a8cc5ee2aab6322152889db6ed4e46",
    "id": null,
    "metadata": {},
    "name": "ChatbotFirstStepForm_Query",
    "operationKind": "query",
    "text": "query ChatbotFirstStepForm_Query {\n  ...ChatbotDistributionSettingFields_root\n}\n\nfragment ChatbotDistributionSettingFields_root on Query {\n  sources {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n  viewer {\n    userLabels {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "19f02aef0cf2f42f56fa1d2d52e001f5";

export default node;
