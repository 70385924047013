import { FC } from "react";

import {
  FunnelBulkCreateTabContainer,
  FunnelBulkEditTabContainer,
} from "~/src/components/features/settings/FunnelBulkTabs";
import { useTabs } from "~/src/lib/hooks";
// import { useFunnelBulkPageParams } from "~/src/routes/hooks";

import { FunnelBulkPage } from "./presentations";

export const tabs = [
  { id: "bulk_new", label: "追加" },
  { id: "bulk_edit", label: "変更" },
] as const;

export const FunnelBulkPageContainer: FC = () => {
  // const { siteSlug } = useFunnelBulkPageParams();
  const { currentTabId, onTabChange } = useTabs(tabs);

  return (
    <FunnelBulkPage
      tabs={tabs}
      currentTabId={currentTabId}
      onTabChange={onTabChange}
    >
      {currentTabId === "bulk_new" && <FunnelBulkCreateTabContainer />}
      {currentTabId === "bulk_edit" && <FunnelBulkEditTabContainer />}
    </FunnelBulkPage>
  );
};
