/**
 * @generated SignedSource<<b8e532d9a1424e52a6aaec50fff03551>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DistributionStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type GroupContentJudgeStatus = "PENDING" | "APPROVED" | "%future added value";
export type CopyAbtestScenarioPageGroupInput = {
  clientMutationId?: string | null;
  abtestScenarioPageGroupId: string;
  pageId: string;
};
export type FunnelABTestGroupTableActions_CopyMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CopyAbtestScenarioPageGroupInput;
};
export type FunnelABTestGroupTableActions_CopyMutationVariables = FunnelABTestGroupTableActions_CopyMutation$variables;
export type FunnelABTestGroupTableActions_CopyMutation$data = {
  readonly copyAbtestScenarioPageGroup: {
    readonly abtestScenarioPageGroup: {
      readonly abtestScenarioPages: {
        readonly totalCount: number;
      } | null;
      readonly endDate: string | null;
      readonly id: string;
      readonly memo: string | null;
      readonly slug: string;
      readonly startDate: string | null;
      readonly status: DistributionStatus;
      readonly title: string;
      readonly updatedAt: string | null;
      readonly isArchive: boolean;
      readonly judgeStatus: GroupContentJudgeStatus | null;
      readonly page: {
        readonly id: string;
      };
    } | null;
  } | null;
};
export type FunnelABTestGroupTableActions_CopyMutationResponse = FunnelABTestGroupTableActions_CopyMutation$data;
export type FunnelABTestGroupTableActions_CopyMutation = {
  variables: FunnelABTestGroupTableActions_CopyMutationVariables;
  response: FunnelABTestGroupTableActions_CopyMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "AbtestScenarioPageGroup",
  "kind": "LinkedField",
  "name": "abtestScenarioPageGroup",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "exceptKind",
          "value": "DEFAULT"
        }
      ],
      "concreteType": "AbtestScenarioPageConnection",
      "kind": "LinkedField",
      "name": "abtestScenarioPages",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": "abtestScenarioPages(exceptKind:\"DEFAULT\")"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "memo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isArchive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "judgeStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Page",
      "kind": "LinkedField",
      "name": "page",
      "plural": false,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FunnelABTestGroupTableActions_CopyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CopyAbtestScenarioPageGroupPayload",
        "kind": "LinkedField",
        "name": "copyAbtestScenarioPageGroup",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FunnelABTestGroupTableActions_CopyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CopyAbtestScenarioPageGroupPayload",
        "kind": "LinkedField",
        "name": "copyAbtestScenarioPageGroup",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "abtestScenarioPageGroup",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "AbtestScenarioPageGroupEdge"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f579381b787e700b5133871584bac224",
    "id": null,
    "metadata": {},
    "name": "FunnelABTestGroupTableActions_CopyMutation",
    "operationKind": "mutation",
    "text": "mutation FunnelABTestGroupTableActions_CopyMutation(\n  $input: CopyAbtestScenarioPageGroupInput!\n) {\n  copyAbtestScenarioPageGroup(input: $input) {\n    abtestScenarioPageGroup {\n      abtestScenarioPages(exceptKind: DEFAULT) {\n        totalCount\n      }\n      endDate\n      id\n      memo\n      slug\n      startDate\n      status\n      title\n      updatedAt\n      isArchive\n      judgeStatus\n      page {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "84267cf4598ddd281175a1272eca90ac";

export default node;
