import { useToast } from "@chakra-ui/toast";
import { AlignHorizontalLeftOutlined as AlignHorizontalLeftOutlinedIcon } from "@mui/icons-material";
import { FC, useCallback, useMemo } from "react";

import { DeliverKind } from "~/src/__generated__/schema";
import { IconButton } from "~/src/components/common/IconButton";
import {
  DeliverWeightForm,
  DeliverWeightFormProps,
} from "~/src/components/features/global/DeliverWeightForm";
import { useOpenState } from "~/src/lib/hooks";

import { Popup } from "./PopupPatternTable";

export type Props = {
  popups: Popup[];
  deliverKind: DeliverKind;
  onSubmit: (values: DeliverWeightFormProps["initialValues"]) => void;
};

export const PopupDeliverWeightButton: FC<Props> = ({
  popups,
  deliverKind,
  onSubmit,
}) => {
  const { isOpen, onClose, onOpen } = useOpenState();
  const toast = useToast();

  const initialValues = useMemo<DeliverWeightFormProps["initialValues"]>(() => {
    const deliverWeights = popups.map((p) => ({
      id: p.id,
      name: p.title || "",
      weight: p.weight || 1,
    }));
    return { deliverKind, deliverWeights };
  }, [deliverKind, popups]);

  const handleSubmit = useCallback<DeliverWeightFormProps["onSubmit"]>(
    (values) => {
      try {
        onSubmit(values);

        toast({
          title: "配信比率の一括更新に成功しました",
          status: "success",
        });
        onClose();
      } catch (err) {
        toast({
          title: "配信比率の一括更新に失敗しました",
          status: "error",
        });
      }
    },
    [onClose, onSubmit, toast]
  );

  return (
    <>
      <IconButton
        size="24px"
        icon={<AlignHorizontalLeftOutlinedIcon />}
        isDisabled={popups.length === 0}
        onClick={onOpen}
      />
      <DeliverWeightForm
        isOpen={isOpen}
        initialValues={initialValues}
        onClose={onClose}
        onSubmit={handleSubmit}
      />
    </>
  );
};
