import { useState } from "react";
import { useMount, useUpdateEffect } from "react-use";

import { ListFilterFormValues } from "~/src/components/features/filter";

type Parameters = {
  cacheKey: string;
  initialFilterValues: ListFilterFormValues;
};

export const useListFilterCache = ({
  cacheKey,
  initialFilterValues,
}: Parameters) => {
  const localStorageKey = `filter-values-of-${cacheKey}`;
  const [filterValues, setFilterValues] =
    useState<ListFilterFormValues>(initialFilterValues);

  // マウント時に localStorage のキャッシュから値を復元する
  useMount(() => {
    try {
      const cache = localStorage.getItem(localStorageKey);
      cache && setFilterValues(JSON.parse(cache));
    } catch {
      localStorage.removeItem(localStorageKey);
    }
  });

  // アイテムが更新された際に localStorage にキャッシュを保存する
  useUpdateEffect(() => {
    const jsonString = JSON.stringify(filterValues);
    localStorage.setItem(localStorageKey, jsonString);
  }, [localStorageKey, filterValues]);

  return {
    filterValues,
    setFilterValues,
  };
};
