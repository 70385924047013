import { Box, FormLabel, HStack, VStack } from "@chakra-ui/react";
import {
  AddCircle as AddCircleIcon,
  DeleteOutline as DeleteOutlineIcon,
} from "@mui/icons-material";
import { FieldArray, FieldArrayRenderProps, useField } from "formik";
import { FC, Fragment, useCallback } from "react";

import { SymbolOutlineButton } from "~/src/components/common/Button";
import { IconButton } from "~/src/components/common/IconButton";

import { ContentWithTextReplaceField } from "../../validationSchema";

import { TextReplaceField } from "./TextReplaceField";

export type Props = {
  name: string;
};

export const TextReplaceFieldArrayContainer: FC<Props> = ({ name }) => {
  const [textReplaceFields] = useField<ContentWithTextReplaceField[]>(name);

  const render = useCallback(
    (arrayHelpers: FieldArrayRenderProps) => (
      <>
        <VStack width="100%">
          {textReplaceFields.value &&
            textReplaceFields.value.length > 0 &&
            textReplaceFields.value.map((field, index) => (
              <Fragment key={index}>
                <HStack width={"100%"}>
                  <FormLabel fontSize={"sm"} fontWeight={"normal"}>
                    ターゲット-{index}
                  </FormLabel>
                  <Box flex={1} textAlign={"end"}>
                    <IconButton
                      size="24px"
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={() => arrayHelpers.remove(index)}
                      icon={<DeleteOutlineIcon />}
                    />
                  </Box>
                </HStack>
                <TextReplaceField name={name} index={index} />
              </Fragment>
            ))}
        </VStack>
        <Box width="100%">
          <SymbolOutlineButton
            icon={<AddCircleIcon />}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => arrayHelpers.push("")}
          >
            ターゲット追加
          </SymbolOutlineButton>
        </Box>
      </>
    ),
    [textReplaceFields, name]
  );

  return <FieldArray name={name} render={render} />;
};
