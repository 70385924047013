import { object, string } from "yup";

import { NotificationCategory } from "~/src/__generated__/schema";

export type FormValues = {
  title: string;
  body: string;
  category: NotificationCategory;
  publishedAt: string;
};

export const validationSchema = object({
  title: string().required(),
  body: string().required(),
  category: string().required(),
  publishedAt: string().required(),
});
