/**
 * @generated SignedSource<<0187214ef9bc632a1bdfbc87c8bbb052>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UserRole = "SUPER" | "ADMIN_ALL" | "ADMIN" | "JUDGE" | "CLIENT" | "SAAS_CLIENT" | "%future added value";
export type PopupBulkEditFormContainer_Query$variables = {};
export type PopupBulkEditFormContainer_QueryVariables = PopupBulkEditFormContainer_Query$variables;
export type PopupBulkEditFormContainer_Query$data = {
  readonly viewer: {
    readonly role: UserRole;
  };
};
export type PopupBulkEditFormContainer_QueryResponse = PopupBulkEditFormContainer_Query$data;
export type PopupBulkEditFormContainer_Query = {
  variables: PopupBulkEditFormContainer_QueryVariables;
  response: PopupBulkEditFormContainer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PopupBulkEditFormContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PopupBulkEditFormContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7645bb5285d365efd68aa8be4af4c2ff",
    "id": null,
    "metadata": {},
    "name": "PopupBulkEditFormContainer_Query",
    "operationKind": "query",
    "text": "query PopupBulkEditFormContainer_Query {\n  viewer {\n    role\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e360b9586dc5e39fac0d2a4c5d9f58c5";

export default node;
