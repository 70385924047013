/**
 * @generated SignedSource<<f7f8ea7d6bcc3df659742389fe626f01>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Device = "MOBILE" | "TABLET" | "PC" | "%future added value";
export type ReportAnalyzerInput = {
  startOn: string;
  endOn: string;
  device?: Device | null;
  sourceId?: string | null;
  funnelId?: string | null;
};
export type ChatbotPatternTableDataRow_RefetchQuery$variables = {
  input: ReportAnalyzerInput;
  id: string;
};
export type ChatbotPatternTableDataRow_RefetchQueryVariables = ChatbotPatternTableDataRow_RefetchQuery$variables;
export type ChatbotPatternTableDataRow_RefetchQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"ChatbotPatternTableDataRow_chatbot">;
  } | null;
};
export type ChatbotPatternTableDataRow_RefetchQueryResponse = ChatbotPatternTableDataRow_RefetchQuery$data;
export type ChatbotPatternTableDataRow_RefetchQuery = {
  variables: ChatbotPatternTableDataRow_RefetchQueryVariables;
  response: ChatbotPatternTableDataRow_RefetchQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ChatbotPatternTableDataRow_RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": (v3/*: any*/),
            "kind": "FragmentSpread",
            "name": "ChatbotPatternTableDataRow_chatbot"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ChatbotPatternTableDataRow_RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isArchive",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "weight",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "kind",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "kind": "ScalarField",
                "name": "pvUserCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "kind": "ScalarField",
                "name": "startUserCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "kind": "ScalarField",
                "name": "cvUserCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "kind": "ScalarField",
                "name": "ctr",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "kind": "ScalarField",
                "name": "cvr",
                "storageKey": null
              }
            ],
            "type": "Chatbot",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6a79168d870a333ede1fa86c439335cc",
    "id": null,
    "metadata": {},
    "name": "ChatbotPatternTableDataRow_RefetchQuery",
    "operationKind": "query",
    "text": "query ChatbotPatternTableDataRow_RefetchQuery(\n  $input: ReportAnalyzerInput!\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...ChatbotPatternTableDataRow_chatbot_2VV6jB\n    id\n  }\n}\n\nfragment ChatbotPatternTableDataRow_chatbot_2VV6jB on Chatbot {\n  id\n  slug\n  name\n  status\n  isArchive\n  weight\n  kind\n  pvUserCount(input: $input)\n  startUserCount(input: $input)\n  cvUserCount(input: $input)\n  ctr(input: $input)\n  cvr(input: $input)\n}\n"
  }
};
})();

(node as any).hash = "1a0c0f7cb01163b5f1bf3c81076ac432";

export default node;
