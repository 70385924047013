import { useMemo } from "react";
import { Environment as EnvironmentType } from "react-relay";
import {
  Environment,
  FetchFunction,
  Network,
  RecordSource,
  Store,
} from "relay-runtime";

const buildFetchQUery = ({ apiKey }: { apiKey: string }): FetchFunction => {
  return async (operation, variables, _cacheConfig, uploadables) => {
    if (!operation.text) throw new Error("operation text must be set");

    const headers = new Headers({ Accept: "application/json" });

    if (apiKey) {
      headers.set("Authorization", `Bearer ${apiKey}`);
    }

    let body: string | FormData;
    if (uploadables) {
      // ファイルアップロードの場合
      const formData = new FormData();
      formData.append("query", operation.text);
      formData.append("variables", JSON.stringify(variables));

      const map: Record<string, string[]> = {};
      Object.keys(uploadables).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(uploadables, key)) {
          map[key] = [key];
        }
      });
      formData.append("map", JSON.stringify(map));

      Object.keys(uploadables).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(uploadables, key)) {
          formData.append(key, uploadables[key]);
        }
      });

      body = formData;
    } else {
      // 通常のクエリの場合
      headers.append("Content-Type", "application/json");

      body = JSON.stringify({
        query: operation.text,
        variables,
      });
    }

    const response = await fetch(
      new URL("/graphql", process.env.REACT_APP_API_ENDPOINT).href,
      {
        method: "POST",
        headers,
        body,
      }
    );

    if (!response.ok) throw new Error(response.statusText);

    const json = await response.json();
    if (
      json &&
      json.errors &&
      json.errors[0] &&
      json.errors[0].extensions &&
      json.errors[0].extensions.code === "SERVER_ERROR"
    ) {
      throw new Error(json.errors[0].message);
    }
    return json;
  };
};

export const useEnvironment = ({ apiKey }: { apiKey: string }) => {
  const environment = useMemo(() => {
    const fetchQuery = buildFetchQUery({ apiKey });

    return new Environment({
      // Create a network layer from the fetch function
      // network: Network.create(fetchQuery(headers)),
      network: Network.create(fetchQuery),
      store: new Store(new RecordSource()),
    }) as EnvironmentType;
  }, [apiKey]);

  return { environment };
};
