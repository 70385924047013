/**
 * @generated SignedSource<<72a05747dd6af048f978e7161fd99b2c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PageKind = "LANDING" | "FORM" | "CONFIRM" | "THANKS" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PopupGroupForm_site$data = {
  readonly funnels: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
        readonly confirmPage: {
          readonly id: string;
          readonly name: string;
          readonly kind: PageKind;
          readonly url: string;
          readonly sortNumber: number;
        } | null;
        readonly landingPage: {
          readonly id: string;
          readonly name: string;
          readonly kind: PageKind;
          readonly url: string;
          readonly sortNumber: number;
        } | null;
        readonly formPage: ReadonlyArray<{
          readonly id: string;
          readonly name: string;
          readonly kind: PageKind;
          readonly url: string;
          readonly sortNumber: number;
        }> | null;
        readonly thanksPage: {
          readonly id: string;
          readonly name: string;
          readonly kind: PageKind;
          readonly url: string;
          readonly sortNumber: number;
        } | null;
      } | null;
    } | null> | null;
  };
  readonly " $fragmentType": "PopupGroupForm_site";
};
export type PopupGroupForm_site = PopupGroupForm_site$data;
export type PopupGroupForm_site$key = {
  readonly " $data"?: PopupGroupForm_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"PopupGroupForm_site">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "kind",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "sortNumber",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PopupGroupForm_site",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FunnelConnection",
      "kind": "LinkedField",
      "name": "funnels",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FunnelEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Funnel",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Page",
                  "kind": "LinkedField",
                  "name": "confirmPage",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Page",
                  "kind": "LinkedField",
                  "name": "landingPage",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Page",
                  "kind": "LinkedField",
                  "name": "formPage",
                  "plural": true,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Page",
                  "kind": "LinkedField",
                  "name": "thanksPage",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};
})();

(node as any).hash = "34562260b626bb661e7aff143ee1e413";

export default node;
