import { Box } from "@chakra-ui/react";
import { FC, useCallback } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useNavigate, useParams } from "react-router-dom";

import { ChatbotCreatePageContainer_Mutation } from "~/src/__relay_artifacts__/ChatbotCreatePageContainer_Mutation.graphql";
import { ChatbotCreatePageContainer_Query } from "~/src/__relay_artifacts__/ChatbotCreatePageContainer_Query.graphql";
import { WizardStep } from "~/src/components/common/WizardStep";
import {
  ChatbotFirstStepForm,
  ChatbotFirstStepFormProps,
} from "~/src/components/features/chatbot/ChatbotForms";
import { PageLayout } from "~/src/components/features/global/PageLayout";
import { useFormErrorHandler } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";

export type Props = {};

const query = graphql`
  query ChatbotCreatePageContainer_Query($slug: String!) {
    site(slug: $slug) {
      id
      name
      slug
      ...ChatbotFirstStepForm_site
    }
  }
`;

const mutation = graphql`
  mutation ChatbotCreatePageContainer_Mutation(
    $input: CreateChatbotGroupInput!
  ) {
    createChatbotGroup(input: $input) {
      chatbotGroup {
        id
        name
        slug
        page {
          id
          funnel {
            id
          }
        }
        platforms
        browsers
        devices
        genders
        ageRanges
        deliverKind
        repeat
        startDate
        startTime
        endDate
        endTime
        sources {
          id
          name
        }
        referrers {
          referrer
          matchOp
        }
        excludeReferrers {
          referrer
          matchOp
        }
        visitCount
        visitMatchType
        deliverTargetUrls {
          url
        }
        deliverExcludeUrls {
          url
        }
        deliverTargetLabels {
          id
          name
        }
        deliverExcludeLabels {
          id
          name
        }
        targetCookies {
          key
          value
          matchType
        }
        deliverDayOfWeeks {
          weekOfMonth
          daysOfWeek
        }
        status
      }
    }
  }
`;

export const ChatbotCreatePageContainer: FC<Props> = () => {
  const { siteSlug = "" } = useParams();
  const { site } = useLazyLoadQuery<ChatbotCreatePageContainer_Query>(query, {
    slug: siteSlug,
  });

  if (!site) throw new Error("assertion failed");

  const navigate = useNavigate();

  const handleCancel = useCallback(() => navigate(-1), [navigate]);
  const { onFormError } = useFormErrorHandler();

  const mutate =
    useMutationCommit<ChatbotCreatePageContainer_Mutation>(mutation);

  const handleSubmit = useCallback<ChatbotFirstStepFormProps["onSubmit"]>(
    async ({ funnelId, repeat, ...values }, { setErrors }) => {
      try {
        const res = await mutate({
          variables: {
            input: {
              ...values,
              startDate: repeat
                ? values.startDate?.toISOString() || null
                : null,
              startTime: repeat
                ? values.startTime?.toISOString() || null
                : null,
              endDate: repeat ? values.endDate?.toISOString() || null : null,
              endTime: repeat ? values.endTime?.toISOString() || null : null,
              deliverDayOfWeeks: repeat ? values.deliverDayOfWeeks : [],
            },
          },
        });

        const chatbogGroup = res.createChatbotGroup?.chatbotGroup;
        if (!chatbogGroup) {
          throw new Error("assertion failed");
        }

        navigate(
          `/sites/${site.slug}/chatbots/${chatbogGroup.slug}/patterns/new`
        );
      } catch (err) {
        onFormError(err, setErrors);
      }
    },
    [mutate, navigate, site.slug, onFormError]
  );

  return (
    <PageLayout
      title="チャットボット登録"
      breadcrumbs={[
        { label: "チャットボット一覧", path: `/sites/${siteSlug}/chatbots` },
        {
          label: "チャットボット作成",
          path: `/sites/${siteSlug}/chatbots/new`,
        },
      ]}
    >
      <Box mt="16px" p="16px">
        <WizardStep
          steps={[
            { label: "基本設定", active: true },
            { label: "パターン設定", active: false },
          ]}
        />
      </Box>
      <Box my="16px" p="16px">
        <ChatbotFirstStepForm
          siteRef={site}
          onCancelClick={handleCancel}
          onSubmit={handleSubmit}
          isFunnelDisabled={false}
        />
      </Box>
    </PageLayout>
  );
};
