import { useToast } from "@chakra-ui/react";
import { FC, useCallback } from "react";
import { graphql } from "react-relay";

import { PopupSetCreateFormContainer_Mutation } from "~/src/__relay_artifacts__/PopupSetCreateFormContainer_Mutation.graphql";
import { useFormErrorHandler } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";

import { PopupSetForm, PopupSetFormProps } from "./presentations";

export type Props = {
  onClose: () => void;
  popupGroupSetId: string;
  connectionId: string;
};

const mutation = graphql`
  mutation PopupSetCreateFormContainer_Mutation(
    $input: CreatePopupSetInput!
    $connections: [ID!]!
  ) {
    createPopupSet(input: $input) {
      popupSetEdge @appendEdge(connections: $connections) {
        node {
          id
          name
          memo
        }
      }
    }
  }
`;

export const PopupSetCreateFormContainer: FC<Props> = ({
  onClose,
  popupGroupSetId,
  connectionId,
}) => {
  const toast = useToast();
  const { onFormError } = useFormErrorHandler();
  const mutate =
    useMutationCommit<PopupSetCreateFormContainer_Mutation>(mutation);

  const handleSubmit = useCallback<PopupSetFormProps["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const res = await mutate({
          variables: {
            input: {
              popupGroupSetId,
              name: values.name,
              memo: values.memo,
            },
            connections: [connectionId],
          },
        });
        if (!res.createPopupSet?.popupSetEdge)
          throw new Error("assertion failed");
        toast({
          title: "ポップアップパターンセットを作成しました",
          status: "success",
        });
        onClose();
      } catch (err) {
        onFormError(err, setErrors);
      }
    },
    [mutate, onFormError, toast, onClose, popupGroupSetId, connectionId]
  );

  return <PopupSetForm onSubmit={handleSubmit} onCancelClick={onClose} />;
};
