import { Box, Text, VStack } from "@chakra-ui/react";
import { Form, Formik, FormikConfig } from "formik";
import { FC } from "react";

import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormControlGroup } from "~/src/components/common/forms/FormControlGroup";
import { FormikFormButtons } from "~/src/components/common/forms/FormikFormButtons";
import { FormikSwitchField } from "~/src/components/common/forms/FormikSwitchField";
import { FormikTextareaField } from "~/src/components/common/forms/FormikTextareaField";
import { FormikTextField } from "~/src/components/common/forms/FormikTextField";
import { chakraFactory } from "~/src/lib/chakra-ui";

import {
  AccountReAuthenticationModal,
  Props as ModalProps,
} from "./AccountReAuthenticationModal";
import { FormValues, validationSchema } from "./validationSchema";

export type Props = {
  initialValues: FormValues;
  onCancelClick: () => void;
  onSubmit: FormikConfig<FormValues>["onSubmit"];
  modalProps: ModalProps;
};

const StyledTitleText = chakraFactory(Text, {
  baseStyle: {
    fontWeight: "bold",
    fontSize: "22px",
    lineHeight: "32px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.04em",
    color: "#282828",
  },
});

export const AccountSettingsPage: FC<Props> = ({
  initialValues,
  onCancelClick,
  onSubmit,
  modalProps,
}) => (
  <Box margin="0 auto" maxWidth="800px">
    <StyledTitleText mb="16px">アカウント設定</StyledTitleText>
    <Formik<FormValues>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      <Form>
        <VStack spacing="24px" alignItems="flex-start" mb="4">
          <FormControlGroup>
            <FormControl required label="会社名">
              <FormikTextField type="text" mode="fill" name="company" />
            </FormControl>
            <FormControl label="電話番号">
              <FormikTextField type="text" mode="fill" name="tel" />
            </FormControl>
            <FormControl required label="担当者名">
              <FormikTextField type="text" mode="fill" name="name" />
            </FormControl>
            <FormControl label="メールアドレス">
              <FormikTextField type="text" mode="fill" name="email" />
            </FormControl>
            <FormControl label="ハニカム連携">
              <FormikSwitchField name="isHoneycomb" />
            </FormControl>
            <FormControl label="ITP対応用エンドポイント">
              <FormikTextField type="text" mode="fill" name="itpUrls" />
            </FormControl>
            <FormControl label="CookieのSecure設定">
              <FormikSwitchField name="isSecure" />
            </FormControl>
            <FormControl label="マクビーのサーバーでITP対応">
              <FormikSwitchField name="isMacbeeServer" />
            </FormControl>
            <FormControl label="クロスドメイン(クエリ)">
              <FormikSwitchField name="isCrossDomainQuery" />
            </FormControl>
            <FormControl label="クロスドメイン(ハッシュ文字)">
              <FormikSwitchField name="isCrossDomainFragment" />
            </FormControl>
            <FormControl
              label="JavaScriptタグ"
              help={
                <Text>
                  Robeeと一緒に発火させたいjavascriptコードのみを入力してください。
                  <br />
                  使用例： GTMをrobeeで発火させる　など
                </Text>
              }
            >
              <FormikTextareaField mode="fill" name="userTag" />
            </FormControl>
            <AccountReAuthenticationModal {...modalProps} />
          </FormControlGroup>
          <FormikFormButtons onCancelClick={onCancelClick} />
        </VStack>
      </Form>
    </Formik>
  </Box>
);
