/**
 * @generated SignedSource<<f55656c5f80b7fcb0aa990d3834561e8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DistributionStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type CopyChatbotGroupInput = {
  clientMutationId?: string | null;
  chatbotGroupId: string;
};
export type ChatbotTableActions_CopyMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CopyChatbotGroupInput;
};
export type ChatbotTableActions_CopyMutationVariables = ChatbotTableActions_CopyMutation$variables;
export type ChatbotTableActions_CopyMutation$data = {
  readonly copyChatbotGroup: {
    readonly chatbotGroup: {
      readonly id: string;
      readonly name: string;
      readonly slug: string;
      readonly chatbots: {
        readonly totalCount: number;
      };
      readonly endDate: string | null;
      readonly startDate: string | null;
      readonly updatedAt: string;
      readonly status: DistributionStatus;
      readonly page: {
        readonly name: string;
        readonly slug: string;
        readonly funnel: {
          readonly name: string;
          readonly slug: string;
        };
      };
    } | null;
  } | null;
};
export type ChatbotTableActions_CopyMutationResponse = ChatbotTableActions_CopyMutation$data;
export type ChatbotTableActions_CopyMutation = {
  variables: ChatbotTableActions_CopyMutationVariables;
  response: ChatbotTableActions_CopyMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ChatbotConnection",
  "kind": "LinkedField",
  "name": "chatbots",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChatbotTableActions_CopyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CopyChatbotGroupPayload",
        "kind": "LinkedField",
        "name": "copyChatbotGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ChatbotGroup",
            "kind": "LinkedField",
            "name": "chatbotGroup",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "page",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Funnel",
                    "kind": "LinkedField",
                    "name": "funnel",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChatbotTableActions_CopyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CopyChatbotGroupPayload",
        "kind": "LinkedField",
        "name": "copyChatbotGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ChatbotGroup",
            "kind": "LinkedField",
            "name": "chatbotGroup",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "page",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Funnel",
                    "kind": "LinkedField",
                    "name": "funnel",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "chatbotGroup",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "ChatbotGroupEdge"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bf352a75fa929e4e5ab4df3c7c1ad28e",
    "id": null,
    "metadata": {},
    "name": "ChatbotTableActions_CopyMutation",
    "operationKind": "mutation",
    "text": "mutation ChatbotTableActions_CopyMutation(\n  $input: CopyChatbotGroupInput!\n) {\n  copyChatbotGroup(input: $input) {\n    chatbotGroup {\n      id\n      name\n      slug\n      chatbots {\n        totalCount\n      }\n      endDate\n      startDate\n      updatedAt\n      status\n      page {\n        name\n        slug\n        funnel {\n          name\n          slug\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6296a96aaae90c1652514839f0d7b5f8";

export default node;
