import { List } from "@chakra-ui/react";
import { FC, useMemo } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";

import { SearchedUserList_Query } from "~/src/__relay_artifacts__/SearchedUserList_Query.graphql";

import { SearchedUserListItem } from "./SearchedUserListItem";

export type Props = {
  searchText: string;
  handleSelectUser: (selectedId: string, selectedName: string) => void;
};

const query = graphql`
  query SearchedUserList_Query($searchText: String) {
    viewers(searchTerm: $searchText) {
      edges {
        node {
          id
          company
          email
          name
        }
      }
    }
  }
`;

export const SearchedUserList: FC<Props> = ({
  searchText,
  handleSelectUser,
}) => {
  const { viewers } = useLazyLoadQuery<SearchedUserList_Query>(query, {
    searchText,
  });

  const users = useMemo(() => {
    return (
      viewers.edges?.map((edge) => {
        const node = edge?.node;
        if (!node) throw new Error("assertion failed");
        return node;
      }) || []
    );
  }, [viewers]);

  return (
    <List marginTop={"4"} spacing={3}>
      {users.map((user, index) => (
        <SearchedUserListItem
          key={index}
          id={user.id}
          company={user.company || ""}
          name={user.name || ""}
          email={user.email}
          handleSelectUser={handleSelectUser}
        />
      ))}
    </List>
  );
};
