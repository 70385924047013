import { Box, Text, VStack } from "@chakra-ui/react";
import { Form, Formik, FormikConfig } from "formik";
import { FC } from "react";

import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormikFormButtons } from "~/src/components/common/forms/FormikFormButtons";
import { FormikTextareaField } from "~/src/components/common/forms/FormikTextareaField";

import { FormValues, validationSchema } from "./validationSchema";

export type Props = {
  initialValues: FormValues;
  onCancelClick: () => void;
  onSubmit: FormikConfig<FormValues>["onSubmit"];
};

export const ScriptTagSettingForm: FC<Props> = ({
  initialValues,
  onCancelClick,
  onSubmit,
}) => (
  <Box margin="0 auto" maxWidth="800px">
    <Formik<FormValues>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      <Form>
        <VStack spacing="24px" alignItems="flex-start">
          <FormControl
            label="JavaScriptタグ"
            help={
              <Text>
                Robeeと一緒に発火させたいjavascriptコードのみを入力してください。
                <br />
                クライアントに紐づくjavascriptコードを設定します
                <br />
                使用例： GTMをrobeeで発火させる　など
              </Text>
            }
          >
            <FormikTextareaField mode="fill" name="userTag" resize="both" />
          </FormControl>
          <FormikFormButtons onCancelClick={onCancelClick} />
        </VStack>
      </Form>
    </Formik>
  </Box>
);
