import { Box } from "@chakra-ui/react";
import { FC } from "react";

import { chakraFactory } from "~/src/lib/chakra-ui";

import logoImage from "./assets/logo.png";

export type Props = {};

const StyledAuthCard = chakraFactory(Box, {
  baseStyle: {
    minWidth: "412px",
    minHeight: "348px",
    background: "#FFFFFF",
    border: "1px solid #DADADA",
    paddingX: "40px",
    paddingY: "36px",
    textAlign: "center",
  },
});

const StyledLogoImage = chakraFactory("img", {
  baseStyle: {
    height: "40px",
    width: "148.15px",
    justifyContent: "center",
    display: "inline",
    marginBottom: "33px",
  },
});

export const CommonCard: FC<Props> = ({ children }) => {
  return (
    <StyledAuthCard>
      <StyledLogoImage src={logoImage} alt="robee logo" />
      {children}
    </StyledAuthCard>
  );
};
