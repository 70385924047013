import { FC } from "react";

import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormikCheckboxField } from "~/src/components/common/forms/FormikCheckboxField";
import { FormikTextField } from "~/src/components/common/forms/FormikTextField";

type Props = {
  name: string;
};

export const RedirectField: FC<Props> = ({ name }) => (
  <FormControl label="クリック時のURL">
    <FormikCheckboxField
      name={`${name}.isInheritQuery`}
      label="クエリパラメータを引き継ぐ（※ページの?以降の文字を引き継ぎます）"
    />
    <FormikTextField
      type="text"
      mode="fill"
      name={`${name}.linkUrl`}
      placeholder="https://"
    />
  </FormControl>
);
