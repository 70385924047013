import { useToast } from "@chakra-ui/react";
import * as React from "react";
import { graphql } from "react-relay";
import { useNavigate, useParams } from "react-router-dom";

import { PopupGroupCopyButton_Mutation as Mutation } from "~/src/__relay_artifacts__/PopupGroupCopyButton_Mutation.graphql";
import { OutlineButton } from "~/src/components/common/Button";
import {
  CopyModal,
  FormValues,
} from "~/src/components/features/global/CopyModal";
import { useOpenState } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";

type Props = {
  originalId: string;
  currentValues: FormValues;
};

const mutation = graphql`
  mutation PopupGroupCopyButton_Mutation($input: CopyPopupGroupInput!) {
    copyPopupGroup(input: $input) {
      popupGroup {
        id
        name
        slug
      }
    }
  }
`;

export const PopupGroupCopyButton: React.FC<Props> = ({
  originalId,
  currentValues,
}) => {
  const toast = useToast();
  const navigate = useNavigate();
  const { siteSlug = "" } = useParams();
  const { isOpen, onOpen, onClose } = useOpenState();

  const mutate = useMutationCommit<Mutation>(mutation);

  const handleClick = React.useCallback(
    async (pageId: string) => {
      try {
        const res = await mutate({
          variables: {
            input: {
              popupGroupId: originalId,
              pageId,
            },
          },
        });

        const popupGroup = res.copyPopupGroup?.popupGroup;
        if (!popupGroup) throw new Error("assertion failed");

        toast({ title: `${popupGroup.name}を作成しました`, status: "success" });
        navigate(`/sites/${siteSlug}/popup_groups/${popupGroup.slug}/edit`);
      } catch (err) {
        toast({ title: "コピーに失敗しました", status: "error" });
      }
    },
    [mutate, navigate, originalId, siteSlug, toast]
  );

  return (
    <>
      <OutlineButton onClick={onOpen}>複製</OutlineButton>
      <CopyModal
        isOpen={isOpen}
        onClose={onClose}
        initialValues={currentValues}
        onSubmit={handleClick}
      />
    </>
  );
};
