import { FC, useMemo } from "react";

import { Tag } from "~/src/components/common/Tag";

type Props = {
  name: string;
  value: string | boolean;
  options: { label: string; value: string | boolean | null }[];
  onClear?: () => void;
};

export const FilteredTag: FC<Props> = ({ name, value, options, onClear }) => {
  const str = useMemo(
    () => `${name}: ${options.find((option) => option.value === value)?.label}`,
    [name, options, value]
  );

  return (
    <Tag variant="solid" onClose={onClear}>
      {str}
    </Tag>
  );
};
