import { Flex } from "@chakra-ui/react";
import { format } from "date-fns";
import { FC, useCallback, useState } from "react";

import { PopupReportOrderValue } from "~/src/__generated__/schema";
import { Table } from "~/src/components/common/tables/Table";
import { useReportDisableColumnsCache } from "~/src/lib/hooks";

import { CSVDownloadLink } from "../../CSVDownloadLink";
import { DisableColumnSelectButton } from "../../DisableColumnSelectButton";
import {
  ColumnData,
  ReportTableDataRow,
  ReportTableHeaderRow,
} from "../../ReportTable";
import { RowHeightSettingButton } from "../../RowHeightSettingButton";
import { HeaderOption } from "../../utils";

export type Prop = {
  rows: ColumnData[][];
  footerRow: ColumnData[];
  headerRow: HeaderOption<PopupReportOrderValue>[];
  onSortClick: (params: any) => void;
  disableColumnOptions: { label: string; value: number }[];
  csvRows: string;
  cacheKey: string;
};

export const PopupReportTable: FC<Prop> = ({
  rows,
  footerRow,
  headerRow,
  onSortClick,
  disableColumnOptions,
  csvRows,
  cacheKey,
}) => {
  const { disableColumns, setDisableColumns } = useReportDisableColumnsCache({
    cacheKey,
    initialValues: [],
  });
  const [rowHeight, setRowHeight] = useState<number>(56);

  const handleSubmitDisalbeColumnSelect = useCallback(
    (v) => {
      setDisableColumns(v.disableColumns);
    },
    [setDisableColumns]
  );

  const handleSubmitRowHeightSetting = useCallback((values) => {
    setRowHeight(values.rowHeight);
  }, []);

  return (
    <>
      <Flex justifyContent="flex-end" mb="24px" mt={4}>
        <RowHeightSettingButton
          formValues={{ rowHeight }}
          onSubmit={handleSubmitRowHeightSetting}
        />
        <DisableColumnSelectButton
          formValues={{ disableColumns }}
          onSubmit={handleSubmitDisalbeColumnSelect}
          options={disableColumnOptions}
        />
        <CSVDownloadLink
          name={`${"ポップアップ別レポート"}-${format(
            new Date(),
            "yyyy-MM-dd"
          )}.csv`}
          data={csvRows}
          filteredColumns={disableColumns}
        />
      </Flex>

      <Table>
        <ReportTableHeaderRow
          disableColumns={disableColumns}
          onSortClick={onSortClick}
          headerOptions={headerRow}
        />
        {rows.map((row, i) => (
          <ReportTableDataRow
            key={i}
            row={row}
            disableColumns={disableColumns}
            rowHeight={"100px"}
          />
        ))}
        <ReportTableDataRow
          disableColumns={disableColumns}
          row={footerRow}
          rowHeight={`${rowHeight}px`}
        />
      </Table>
    </>
  );
};
