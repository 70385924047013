import { useToast } from "@chakra-ui/toast";
import { FC, useCallback } from "react";
import { ConnectionHandler, graphql, useLazyLoadQuery } from "react-relay";
import { useNavigate } from "react-router";

import { FunnelCreateFormContainer_Mutation } from "~/src/__relay_artifacts__/FunnelCreateFormContainer_Mutation.graphql";
import { FunnelCreateFormContainer_Query } from "~/src/__relay_artifacts__/FunnelCreateFormContainer_Query.graphql";
import { useFormErrorHandler } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";

import {
  formConvertToPageAttributes,
  stringConvertToUrlMatchType,
  toQueryMatchType,
} from "./convertFunctions";
import { FunnelForm, FunnelFormProps } from "./presentations";

type Props = {
  siteSlug: string;
};

const query = graphql`
  query FunnelCreateFormContainer_Query($siteSlug: String!) {
    viewer {
      role
    }
    site(slug: $siteSlug) {
      id
    }
  }
`;

const mutation = graphql`
  mutation FunnelCreateFormContainer_Mutation(
    $input: CreateFunnelInput!
    $connections: [ID!]!
  ) {
    createFunnel(input: $input) {
      funnelEdge @appendEdge(connections: $connections) {
        node {
          id
          slug
          name
          isExperiment
          landingPage {
            name
            url
            mobileUrl
            tabletUrl
            hasForm
            urlMatchType
            queryMatchType
          }
          thanksPage {
            name
            url
            mobileUrl
            tabletUrl
            urlMatchType
          }
          confirmPage {
            name
            url
            mobileUrl
            tabletUrl
            urlMatchType
          }
          formPage {
            name
            url
            mobileUrl
            tabletUrl
            urlMatchType
          }
        }
      }
    }
  }
`;

export const FunnelCreateFormContainer: FC<Props> = ({ siteSlug }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const { onFormError } = useFormErrorHandler();
  const { viewer, site } = useLazyLoadQuery<FunnelCreateFormContainer_Query>(
    query,
    {
      siteSlug,
    }
  );
  const createFunnelMutation =
    useMutationCommit<FunnelCreateFormContainer_Mutation>(mutation);

  const handleSubmit = useCallback<FunnelFormProps["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const connectionId = ConnectionHandler.getConnectionID(
          site.id,
          "FunnelListTab_funnels",
          {
            searchText: "",
          }
        );
        const { createFunnel } = await createFunnelMutation({
          variables: {
            connections: [connectionId],
            input: {
              siteId: site.id,
              name: values.title,
              isExperiment: values.isExperiment,
              landingPageAttributes: {
                name: "ランディングページ",
                url: values.landingUrlForPC,
                mobileUrl: values.landingUrlForMobile,
                tabletUrl: values.landingUrlForTablet,
                hasForm: values.landingUrlFormIncluded ? "HAS_FORM" : "NO_FORM",
                urlMatchType: stringConvertToUrlMatchType(
                  values.landingUrlMatchType
                ),
                queryMatchType: toQueryMatchType(values.landingQueryMatchType),
                isDisableWhiteout: values.landingIsDisableWhiteout,
              },
              formPageAttributes: formConvertToPageAttributes(values.formUrls),
              confirmPageAttributes: {
                name: "確認ページ",
                url: values.confirmUrlForPC || "",
                mobileUrl: values.confirmUrlForMobile,
                tabletUrl: values.confirmUrlForTablet,
                urlMatchType: stringConvertToUrlMatchType(
                  values.confirmUrlMatchType
                ),
                isDisableWhiteout: values.confirmIsDisableWhiteout,
              },
              thanksPageAttributes: {
                name: "サンクスページ",
                url: values.thanksUrlForPC,
                mobileUrl: values.thanksUrlForMobile,
                tabletUrl: values.thanksUrlForTablet,
                urlMatchType: stringConvertToUrlMatchType(
                  values.thanksUrlMatchType
                ),
                isDisableWhiteout: values.thanksIsDisableWhiteout,
              },
            },
          },
        });
        if (!createFunnel?.funnelEdge) throw new Error("assertion failed");
        toast({ title: "ファネルを作成しました", status: "success" });
        navigate(`/sites/${siteSlug}/settings?tab=funnels`);
      } catch (err) {
        onFormError(err, setErrors);
      }
    },
    [createFunnelMutation, navigate, onFormError, site.id, siteSlug, toast]
  );

  const handleCancel = useCallback(() => navigate(-1), [navigate]);

  return (
    <FunnelForm
      onCancelClick={handleCancel}
      onSubmit={handleSubmit}
      userRole={viewer.role}
    />
  );
};
