/**
 * @generated SignedSource<<9dec2929fc137f9fc041fbb743611b17>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CopyAbtestScenarioPageGroupInput = {
  clientMutationId?: string | null;
  abtestScenarioPageGroupId: string;
  pageId: string;
};
export type ABTestTableActions_CopyMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CopyAbtestScenarioPageGroupInput;
};
export type ABTestTableActions_CopyMutationVariables = ABTestTableActions_CopyMutation$variables;
export type ABTestTableActions_CopyMutation$data = {
  readonly copyAbtestScenarioPageGroup: {
    readonly abtestScenarioPageGroup: {
      readonly " $fragmentSpreads": FragmentRefs<"ABTestTableDataRow_abtestScenarioPageGroup">;
    } | null;
  } | null;
};
export type ABTestTableActions_CopyMutationResponse = ABTestTableActions_CopyMutation$data;
export type ABTestTableActions_CopyMutation = {
  variables: ABTestTableActions_CopyMutationVariables;
  response: ABTestTableActions_CopyMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ABTestTableActions_CopyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CopyAbtestScenarioPageGroupPayload",
        "kind": "LinkedField",
        "name": "copyAbtestScenarioPageGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AbtestScenarioPageGroup",
            "kind": "LinkedField",
            "name": "abtestScenarioPageGroup",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ABTestTableDataRow_abtestScenarioPageGroup"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ABTestTableActions_CopyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CopyAbtestScenarioPageGroupPayload",
        "kind": "LinkedField",
        "name": "copyAbtestScenarioPageGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AbtestScenarioPageGroup",
            "kind": "LinkedField",
            "name": "abtestScenarioPageGroup",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "exceptKind",
                    "value": "DEFAULT"
                  }
                ],
                "concreteType": "AbtestScenarioPageConnection",
                "kind": "LinkedField",
                "name": "abtestScenarioPages",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  }
                ],
                "storageKey": "abtestScenarioPages(exceptKind:\"DEFAULT\")"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endDate",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "page",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "kind",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sortNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Funnel",
                    "kind": "LinkedField",
                    "name": "funnel",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v3/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "siteUrl",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "memo",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updatedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isArchive",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "judgeStatus",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "abtestScenarioPageGroup",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "AbtestScenarioPageGroupEdge"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2d819d21a9dbe5946dd86e9551ed9065",
    "id": null,
    "metadata": {},
    "name": "ABTestTableActions_CopyMutation",
    "operationKind": "mutation",
    "text": "mutation ABTestTableActions_CopyMutation(\n  $input: CopyAbtestScenarioPageGroupInput!\n) {\n  copyAbtestScenarioPageGroup(input: $input) {\n    abtestScenarioPageGroup {\n      ...ABTestTableDataRow_abtestScenarioPageGroup\n      id\n    }\n  }\n}\n\nfragment ABTestTableDataRow_abtestScenarioPageGroup on AbtestScenarioPageGroup {\n  abtestScenarioPages(exceptKind: DEFAULT) {\n    totalCount\n  }\n  endDate\n  id\n  page {\n    id\n    slug\n    name\n    kind\n    sortNumber\n    url\n    funnel {\n      name\n      slug\n      id\n      siteUrl\n    }\n  }\n  memo\n  slug\n  startDate\n  status\n  title\n  updatedAt\n  isArchive\n  judgeStatus\n}\n"
  }
};
})();

(node as any).hash = "9035e7f3e0a65cbf4e0dddaad9343753";

export default node;
