import { VStack } from "@chakra-ui/react";
import { Form, Formik, FormikConfig } from "formik";
import { FC } from "react";

import { GraphQLEnums } from "~/src/__generated__/GraphQLEnums";
import { UserRole } from "~/src/__generated__/schema";
import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormControlGroup } from "~/src/components/common/forms/FormControlGroup";
import { FormikFormButtons } from "~/src/components/common/forms/FormikFormButtons";
import { FormikSelectField } from "~/src/components/common/forms/FormikSelectField";
import { FormikSwitchField } from "~/src/components/common/forms/FormikSwitchField";
import { FormikTextField } from "~/src/components/common/forms/FormikTextField";

import { defaultFormUrlsValue } from "./constants";
import { FormUrlsField } from "./FormUrlsField";
import { FormValues, validationSchema } from "./validationSchema";

export type Props = {
  userRole: UserRole;
  initialValues?: FormValues;
  onCancelClick: () => void;
  onSubmit: FormikConfig<FormValues>["onSubmit"];
};

const defaultInitialValues = {
  title: "",
  landingUrlForPC: "",
  landingUrlForMobile: "",
  landingUrlForTablet: "",
  landingUrlMatchType: "EQUAL_TYPE",
  landingQueryMatchType: "EQUAL_MATCH",
  landingUrlFormIncluded: false,
  landingIsDisableWhiteout: false,
  formUrls: [defaultFormUrlsValue],
  confirmUrlForPC: "",
  confirmUrlForMobile: "",
  confirmUrlForTablet: "",
  confirmUrlMatchType: "EQUAL_TYPE",
  confirmUrlFormIncluded: false,
  confirmIsDisableWhiteout: false,
  thanksUrlForPC: "",
  thanksUrlForMobile: "",
  thanksUrlForTablet: "",
  thanksUrlMatchType: "EQUAL_TYPE",
  thanksUrlFormIncluded: false,
  thanksIsDisableWhiteout: false,
  isExperiment: false,
};

export const FunnelForm: FC<Props> = ({
  userRole,
  initialValues = defaultInitialValues,
  onCancelClick,
  onSubmit,
}) => (
  <Formik<FormValues>
    enableReinitialize
    initialValues={initialValues}
    validationSchema={validationSchema}
    validateOnMount={false}
    validateOnChange={false}
    onSubmit={onSubmit}
  >
    <Form>
      <VStack spacing="24px" alignItems="flex-start">
        <FormControlGroup>
          <FormControl required label="ファネル名">
            <FormikTextField type="text" mode="fill" name="title" />
          </FormControl>
        </FormControlGroup>
        <FormControlGroup description="ランディングページのURLを入力してください。このページを訪問し、サンクスページを訪問することでコンバージョンが計測されます。">
          <FormControl required label="PC用URL">
            <FormikTextField type="text" mode="fill" name="landingUrlForPC" />
          </FormControl>
          <FormControl label="モバイル用URL">
            <FormikTextField
              type="text"
              mode="fill"
              name="landingUrlForMobile"
            />
          </FormControl>
          <FormControl label="タブレット用URL">
            <FormikTextField
              type="text"
              mode="fill"
              name="landingUrlForTablet"
            />
          </FormControl>
          {(userRole === "ADMIN" ||
            userRole === "SUPER" ||
            userRole === "CLIENT" ||
            userRole === "ADMIN_ALL") && (
            <FormControl label="クエリの判定方法">
              <FormikSelectField
                mode="fill"
                name="landingQueryMatchType"
                options={GraphQLEnums.QueryMatch}
              />
            </FormControl>
          )}
          <FormControl label="URLの一致タイプ">
            <FormikSelectField
              mode="fill"
              name="landingUrlMatchType"
              options={GraphQLEnums.UrlMatch}
            />
          </FormControl>
          <FormControl label="フォームの有無">
            <FormikSwitchField name="landingUrlFormIncluded" />
          </FormControl>
          <FormControl label="ホワイトアウトを無効にする">
            <FormikSwitchField name="landingIsDisableWhiteout" />
          </FormControl>
        </FormControlGroup>
        <FormControlGroup description="入力フォームページのURLを入力してください。">
          <FormUrlsField />
        </FormControlGroup>
        <FormControlGroup description="確認ページのURLを入力してください。">
          <FormControl label="PC用URL">
            <FormikTextField type="text" mode="fill" name="confirmUrlForPC" />
          </FormControl>
          <FormControl label="モバイル用URL">
            <FormikTextField
              type="text"
              mode="fill"
              name="confirmUrlForMobile"
            />
          </FormControl>
          <FormControl label="タブレット用URL">
            <FormikTextField
              type="text"
              mode="fill"
              name="confirmUrlForTablet"
            />
          </FormControl>
          <FormControl label="URLの一致タイプ">
            <FormikSelectField
              mode="fill"
              name="confirmUrlMatchType"
              options={GraphQLEnums.UrlMatch}
            />
          </FormControl>
          <FormControl label="ホワイトアウトを無効にする">
            <FormikSwitchField name="confirmIsDisableWhiteout" />
          </FormControl>
        </FormControlGroup>
        <FormControlGroup description="サンクスページのURLを入力してください。ランディングページに訪問し、サンクスページに訪問することでコンバージョンが計測されます。">
          <FormControl required label="PC用URL">
            <FormikTextField type="text" mode="fill" name="thanksUrlForPC" />
          </FormControl>
          <FormControl label="モバイル用URL">
            <FormikTextField
              type="text"
              mode="fill"
              name="thanksUrlForMobile"
            />
          </FormControl>
          <FormControl label="タブレット用URL">
            <FormikTextField
              type="text"
              mode="fill"
              name="thanksUrlForTablet"
            />
          </FormControl>
          <FormControl label="URLの一致タイプ">
            <FormikSelectField
              mode="fill"
              name="thanksUrlMatchType"
              options={GraphQLEnums.UrlMatch}
            />
          </FormControl>
          <FormControl label="ホワイトアウトを無効にする">
            <FormikSwitchField name="thanksIsDisableWhiteout" />
          </FormControl>
        </FormControlGroup>
        <FormControlGroup>
          <FormControl label="テスト用のファネル">
            <FormikSwitchField name="isExperiment" />
          </FormControl>
        </FormControlGroup>
        <FormikFormButtons onCancelClick={onCancelClick} />
      </VStack>
    </Form>
  </Formik>
);
