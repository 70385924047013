/**
 * @generated SignedSource<<87b486194b2fe3acdf4048f7ae1a7f5a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DistributionStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type GroupContentJudgeStatus = "PENDING" | "APPROVED" | "%future added value";
export type PageKind = "LANDING" | "FORM" | "CONFIRM" | "THANKS" | "%future added value";
export type CopyPopupGroupInput = {
  clientMutationId?: string | null;
  popupGroupId: string;
  pageId: string;
};
export type FunnelPopupGroupTableActions_CopyMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CopyPopupGroupInput;
};
export type FunnelPopupGroupTableActions_CopyMutationVariables = FunnelPopupGroupTableActions_CopyMutation$variables;
export type FunnelPopupGroupTableActions_CopyMutation$data = {
  readonly copyPopupGroup: {
    readonly popupGroup: {
      readonly endDate: string | null;
      readonly id: string;
      readonly memo: string | null;
      readonly name: string;
      readonly isArchive: boolean;
      readonly popups: {
        readonly totalCount: number;
      } | null;
      readonly page: {
        readonly id: string;
        readonly kind: PageKind;
      };
      readonly slug: string;
      readonly status: DistributionStatus;
      readonly startDate: string | null;
      readonly thumbnailImageUrl: string | null;
      readonly updatedAt: string;
      readonly judgeStatus: GroupContentJudgeStatus | null;
    } | null;
  } | null;
};
export type FunnelPopupGroupTableActions_CopyMutationResponse = FunnelPopupGroupTableActions_CopyMutation$data;
export type FunnelPopupGroupTableActions_CopyMutation = {
  variables: FunnelPopupGroupTableActions_CopyMutationVariables;
  response: FunnelPopupGroupTableActions_CopyMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "PopupGroup",
  "kind": "LinkedField",
  "name": "popupGroup",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "memo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isArchive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "kind",
          "value": "B_PATTERN"
        }
      ],
      "concreteType": "PopupConnection",
      "kind": "LinkedField",
      "name": "popups",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": "popups(kind:\"B_PATTERN\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Page",
      "kind": "LinkedField",
      "name": "page",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnailImageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "judgeStatus",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FunnelPopupGroupTableActions_CopyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CopyPopupGroupPayload",
        "kind": "LinkedField",
        "name": "copyPopupGroup",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FunnelPopupGroupTableActions_CopyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CopyPopupGroupPayload",
        "kind": "LinkedField",
        "name": "copyPopupGroup",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "popupGroup",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "PopupGroupEdge"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "22c41f3da5d59ea67f2106c67de28f99",
    "id": null,
    "metadata": {},
    "name": "FunnelPopupGroupTableActions_CopyMutation",
    "operationKind": "mutation",
    "text": "mutation FunnelPopupGroupTableActions_CopyMutation(\n  $input: CopyPopupGroupInput!\n) {\n  copyPopupGroup(input: $input) {\n    popupGroup {\n      endDate\n      id\n      memo\n      name\n      isArchive\n      popups(kind: B_PATTERN) {\n        totalCount\n      }\n      page {\n        id\n        kind\n      }\n      slug\n      status\n      startDate\n      thumbnailImageUrl\n      updatedAt\n      judgeStatus\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c32eb7100e362fd2cbd497f97c535ce6";

export default node;
