/**
 * @generated SignedSource<<f034223c2adf308b509b37c1d78e3949>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type ChatbotKind = "A_PATTERN" | "B_PATTERN" | "%future added value";
export type DistributionStatus = "ACTIVE" | "INACTIVE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ChatbotPatternTableDataRow_chatbot$data = {
  readonly id: string;
  readonly slug: string;
  readonly name: string;
  readonly status: DistributionStatus;
  readonly isArchive: boolean;
  readonly weight: number | null;
  readonly kind: ChatbotKind | null;
  readonly pvUserCount: number;
  readonly startUserCount: number;
  readonly cvUserCount: number;
  readonly ctr: number;
  readonly cvr: number;
  readonly " $fragmentType": "ChatbotPatternTableDataRow_chatbot";
};
export type ChatbotPatternTableDataRow_chatbot = ChatbotPatternTableDataRow_chatbot$data;
export type ChatbotPatternTableDataRow_chatbot$key = {
  readonly " $data"?: ChatbotPatternTableDataRow_chatbot$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChatbotPatternTableDataRow_chatbot">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "input"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./ChatbotPatternTableDataRow_RefetchQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "ChatbotPatternTableDataRow_chatbot",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isArchive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "pvUserCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "startUserCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "cvUserCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "ctr",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "cvr",
      "storageKey": null
    }
  ],
  "type": "Chatbot",
  "abstractKey": null
};
})();

(node as any).hash = "1a0c0f7cb01163b5f1bf3c81076ac432";

export default node;
