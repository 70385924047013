import { Popover, PopoverContent, PopoverTrigger } from "@chakra-ui/react";
import { HelpOutlineOutlined as HelpOutlineOutlinedIcon } from "@mui/icons-material";
import { FC, ReactElement } from "react";

import { IconButton } from "~/src/components/common/IconButton";
import { chakraFactory } from "~/src/lib/chakra-ui";

export type Props = {
  text: string | ReactElement;
};

const StyledPopoverContent = chakraFactory(PopoverContent, {
  baseStyle: {
    width: "210px",
    padding: "16px",
    borderRadius: 0,
    background: "#FFFFFF",
    boxShadow: "0px 1px 22px rgba(0, 0, 0, 0.1)",
    fontSize: "11px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    color: "#666666",

    "&:focus": {
      outline: 0,
      boxShadow: "0px 1px 22px rgba(0, 0, 0, 0.1)",
    },
  },
});

export const HelpIcon: FC<Props> = ({ text }) => (
  <Popover placement="bottom-start">
    <PopoverTrigger>
      <IconButton size="16px" icon={<HelpOutlineOutlinedIcon />} />
    </PopoverTrigger>
    <StyledPopoverContent>{text}</StyledPopoverContent>
  </Popover>
);
