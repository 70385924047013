import { Box, HStack } from "@chakra-ui/layout";
import { FC, useCallback } from "react";
import { graphql, useFragment } from "react-relay";
import { useNavigate, useParams } from "react-router-dom";

import { UserRole } from "~/src/__generated__/schema";
import { PopupGroupPatternEditScreenContainer_Mutation } from "~/src/__relay_artifacts__/PopupGroupPatternEditScreenContainer_Mutation.graphql";
import { PopupGroupPatternEditScreenContainer_popup$key } from "~/src/__relay_artifacts__/PopupGroupPatternEditScreenContainer_popup.graphql";
import { PopupGroupPatternEditScreenContainer_popupGroup$key } from "~/src/__relay_artifacts__/PopupGroupPatternEditScreenContainer_popupGroup.graphql";
import { PopupGroupPatternEditScreenContainer_site$key } from "~/src/__relay_artifacts__/PopupGroupPatternEditScreenContainer_site.graphql";
import { URLItem } from "~/src/components/common/URLItem";
import { PageLayout } from "~/src/components/features/global/PageLayout";
import {
  PopupGroupPatternForm,
  PopupGroupPatternFormProps,
  PopupGroupPatternFormValues,
} from "~/src/components/features/popup";
import { useFormErrorHandler } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";

export type Props = {
  role: UserRole;
  siteRef: PopupGroupPatternEditScreenContainer_site$key;
  popupRef: PopupGroupPatternEditScreenContainer_popup$key;
  popupGroupRef: PopupGroupPatternEditScreenContainer_popupGroup$key;
};

const fragments = {
  site: graphql`
    fragment PopupGroupPatternEditScreenContainer_site on Site {
      id
      name
      slug
      funnels {
        edges {
          node {
            id
            name
          }
        }
      }
      pages {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `,
  popup: graphql`
    fragment PopupGroupPatternEditScreenContainer_popup on Popup {
      id
      slug
      page {
        id
        funnel {
          id
        }
      }
      popupImages {
        id
        alt
        linkUrl
        imageUrl
        isInheritQuery
        honeycombCode
      }
      popupVideo {
        linkUrl
        video
        screenType
      }
      name
      contentKind
      colorKind
      title
      description
      button
      isInheritQuery
      url
      hrefTarget
      contentWidth
      actionKind
      displayKind
      xOffset
      xOffsetUnit
      yOffset
      yOffsetUnit
      enableTapWindow
      backgroundOpacity
      quitTiming
      displayCountLimit
      status
      displayPopupAgain
      displayPopupAgainImageUrl
      displayPopupAgainMessage
      displayOnMouseOut
      displayOnTabFocus
      isClose
      htmlContent
      weight
      animationKind
      closePopupByClickingOutside
      closeButtonPosition
      hideByScrollPercentage
      hideByOnfocus
      ydaTrigger
      honeycombCode
      isDisplayWhenNoOperation
      timing
      scrollRateRange
      customEvent
    }
  `,
  popupGroup: graphql`
    fragment PopupGroupPatternEditScreenContainer_popupGroup on PopupGroup {
      id
      name
      deliverKind
      originalPopup {
        weight
      }
      page {
        name
        kind
        sortNumber
        url
        funnel {
          name
          siteUrl
        }
      }
    }
  `,
};

const mutation = graphql`
  mutation PopupGroupPatternEditScreenContainer_Mutation(
    $input: UpdatePopupInput!
    $originalInput: UpdateOriginalPopupInput!
    $withOriginal: Boolean!
  ) {
    updateOriginalPopup(input: $originalInput) @include(if: $withOriginal) {
      popup {
        id
        weight
      }
    }
    updatePopup(input: $input) {
      popup {
        slug
        ...PopupGroupPatternEditScreenContainer_popup
      }
    }
  }
`;

export const PopupGroupPatternEditScreenContainer: FC<Props> = ({
  siteRef,
  popupRef,
  popupGroupRef,
  role,
}) => {
  const { siteSlug = "", patternSlug = "", popupGroupSlug = "" } = useParams();

  const site = useFragment(fragments.site, siteRef);
  const popup = useFragment(fragments.popup, popupRef);
  const popupGroup = useFragment(fragments.popupGroup, popupGroupRef);

  const navigate = useNavigate();
  const handleCancel = useCallback(() => navigate(-1), [navigate]);

  const mutate =
    useMutationCommit<PopupGroupPatternEditScreenContainer_Mutation>(mutation);

  const { onFormError } = useFormErrorHandler();

  const handleSubmit = useCallback<PopupGroupPatternFormProps["onSubmit"]>(
    async (
      {
        enableTapWindow,
        popupImages,
        popupVideo,
        originalPatternWeight,
        displayPopupAgainImage,
        ...values
      },
      { setErrors }
    ) => {
      try {
        const uploadables: Record<string, File> = {};
        if (popupImages) {
          popupImages.forEach(({ image }, index) => {
            if (image)
              uploadables[`variables.input.popupImages.${index}.image`] = image;
          });
        }

        if (displayPopupAgainImage && displayPopupAgainImage.image) {
          uploadables[`variables.input.displayPopupAgainImage`] =
            displayPopupAgainImage.image;
        }

        const _popupImages = (popupImages || []).map(
          ({ image, imageUrl, ...rest }) => rest
        );

        const res = await mutate({
          variables: {
            input: {
              popupId: popup.id,
              enableTapWindow,
              popupImages: _popupImages,
              popupVideo,
              ...values,
            },
            withOriginal: popupGroup.deliverKind === "WEIGHT",
            originalInput: {
              popupGroupId: popupGroup.id,
              weight: originalPatternWeight,
            },
          },
          uploadables,
        });

        const updatePopup = res.updatePopup?.popup;

        if (!updatePopup) {
          throw new Error("assertion failed");
        }
        navigate(
          `/sites/${site.slug}/popup_groups/${popupGroupSlug}/patterns/${patternSlug}`
        );
        return Promise.resolve();
      } catch (err) {
        onFormError(err, setErrors);
      }
      return Promise.resolve();
    },
    [
      mutate,
      navigate,
      onFormError,
      patternSlug,
      popup.id,
      popupGroup.deliverKind,
      popupGroup.id,
      popupGroupSlug,
      site.slug,
    ]
  );

  const initialValues: PopupGroupPatternFormValues = {
    name: popup.name || "",
    contentKind: popup.contentKind || "IMAGE",
    colorKind: popup.colorKind || "DEFAULT",
    title: popup.title,
    description: popup.description,
    button: popup.button,
    isInheritQuery: popup.isInheritQuery,
    url: popup.url,
    hrefTarget: popup.hrefTarget,
    contentWidth: popup.contentWidth,
    actionKind: popup.actionKind || "EXIT_PAGE",
    displayKind: popup.displayKind || "CENTER_MODAL",
    xOffset: popup.xOffset || 0,
    xOffsetUnit: popup.xOffsetUnit,
    yOffset: popup.yOffset || 0,
    yOffsetUnit: popup.yOffsetUnit,
    enableTapWindow: popup.enableTapWindow,
    backgroundOpacity: popup.backgroundOpacity,
    // FIXME: DB schemaの更新後にAPIのnull制約も修正できるので、修正する
    // https://macbee-planet-88.slack.com/archives/C03SGKK7QKS/p1673596296969749
    quitTiming: popup.quitTiming || 0,
    displayCountLimit: popup.displayCountLimit,
    isClose: popup.isClose,
    status: popup.status,
    displayPopupAgain: popup.displayPopupAgain,
    displayPopupAgainMessage: popup.displayPopupAgainMessage,
    displayPopupAgainImage: { imageUrl: popup.displayPopupAgainImageUrl },
    displayOnTabFocus: popup.displayOnTabFocus,
    displayOnMouseOut: popup.displayOnMouseOut,
    popupImages: popup.popupImages.map((popupImage) => {
      return {
        alt: popupImage.alt === null ? undefined : popupImage.alt,
        honeycombCode:
          popupImage.honeycombCode === null
            ? undefined
            : popupImage.honeycombCode,
        id: popupImage.id,
        imageUrl: popupImage.imageUrl || "",
        isInheritQuery: popupImage.isInheritQuery,
        linkUrl: popupImage.linkUrl === null ? undefined : popupImage.linkUrl,
      };
    }),
    popupVideo: popup.popupVideo,
    htmlContent: popup.htmlContent,
    weight: popup.weight || 0,
    animationKind: popup.animationKind,
    closePopupByClickingOutside: popup.closePopupByClickingOutside,
    closeButtonPosition: popup.closeButtonPosition,
    hideByScrollPercentage: popup.hideByScrollPercentage,
    hideByOnfocus: popup.hideByOnfocus,
    originalPatternWeight: popupGroup.originalPopup.weight || 0,
    ydaTrigger: popup.ydaTrigger,
    honeycombCode: popup.honeycombCode,
    isDisplayWhenNoOperation: popup.isDisplayWhenNoOperation,
    timing: popup.timing,
    scrollRateRange: popup.scrollRateRange,
    customEvent: popup.customEvent || "",
  };

  return (
    <PageLayout
      title="ポップアップ編集"
      breadcrumbs={[
        { label: "ポップアップ一覧", path: `/sites/${siteSlug}/popup_groups` },
        {
          label: "ポップアップ詳細",
          path: `/sites/${siteSlug}/popup_groups/${popupGroupSlug}`,
        },
        {
          label: "パターン編集",
          path: `/sites/${siteSlug}/popup_groups/${popupGroupSlug}/patterns/${patternSlug}/edit`,
        },
      ]}
    >
      <HStack>
        <Box>{`実施グループ: ${popupGroup?.name}`}</Box>
      </HStack>
      <HStack>
        <Box>{`実施ファネル: ${popupGroup?.page.funnel.name}`}</Box>
        <URLItem url={popupGroup?.page.funnel.siteUrl || ""} />
      </HStack>
      <HStack>
        <Box>
          {`実施ページ: ${
            popupGroup?.page.kind === "FORM"
              ? popupGroup?.page.name + `(${popupGroup?.page.sortNumber - 1})`
              : popupGroup?.page.name
          }`}
        </Box>
        <URLItem url={popupGroup?.page.url || ""} />
      </HStack>

      <Box my="16px" p="16px">
        <PopupGroupPatternForm
          initialValues={initialValues}
          enableWeightFields={popupGroup?.deliverKind === "WEIGHT"}
          onCancelClick={handleCancel}
          onSubmit={handleSubmit}
          role={role}
        />
      </Box>
    </PageLayout>
  );
};
