/**
 * @generated SignedSource<<e74d7556df713d263aff8204e5ee70ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ChatMessageFlowNodeKind = "TEXT" | "IMAGE" | "SUBMIT" | "REPLY" | "%future added value";
export type ChatMessageKind = "TEXT" | "IMAGE" | "SUBMIT" | "%future added value";
export type ChatMessageReplyGroupKind = "TEXT_BOX" | "RADIO_BUTTON" | "CHECK_BOX" | "SELECT_BOX" | "TEXT_AREA" | "%future added value";
export type ChatMessageReplyKind = "CUSTOM_TEXT" | "API" | "OPTION" | "NAME" | "%future added value";
export type ChatbotActionKind = "BUTTON_CLICK" | "DURATION" | "%future added value";
export type ChatbotBotKind = "FORM" | "QUESTION" | "FORM_AND_QUESTION" | "QUESTION_AND_FORM" | "%future added value";
export type ChatbotColorKind = "DEFAULT" | "GRAY" | "BLACK" | "RED" | "BLUE" | "GREEN" | "YELLOW" | "ORANGE" | "PINK" | "DARK_PINK" | "CUSTOM_COLOR" | "%future added value";
export type ChatbotDisplayKind = "RIGHT_BOTTOM" | "LEFT_BOTTOM" | "RIGHT_TOP" | "LEFT_TOP" | "%future added value";
export type ChatbotSubmitMethodKind = "GET" | "POST" | "%future added value";
export type DistributionStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type AddChatbotInput = {
  clientMutationId?: string | null;
  actionKind: ChatbotActionKind;
  botImage?: File | Blog | null;
  botImageAlt?: string | null;
  botKind: ChatbotBotKind;
  botTextColor?: string | null;
  botBackgroundColor?: string | null;
  chatbotGroupId: string;
  colorKind: ChatbotColorKind;
  chatFormColor?: string | null;
  clientTextColor?: string | null;
  clientBackgroundColor?: string | null;
  displayKind: ChatbotDisplayKind;
  honeycombCode?: string | null;
  name: string;
  notFoundMessage: string;
  openButtonText?: string | null;
  openButtonImage?: File | Blog | null;
  status?: DistributionStatus | null;
  title: string;
  titleTextColor?: string | null;
  timing?: number | null;
  toFormButtonImage?: File | Blog | null;
  weight: number;
  submitMethodKind: ChatbotSubmitMethodKind;
  xOffset?: number | null;
  xOffsetUnit?: string | null;
  yOffset?: number | null;
  yOffsetUnit?: string | null;
  windowColor?: string | null;
  questionCsv?: File | Blog | null;
  questionFinishMessage?: string | null;
  chatMessageNodes: ReadonlyArray<ChatMessageFlowNodeInput>;
  chatMessageEdges: ReadonlyArray<ChatMessageFlowEdgeInput>;
  chatMessageReplies?: ReadonlyArray<ChatMessageReplyInput> | null;
  chatbotApiResults?: ReadonlyArray<ChatbotApiResultInput> | null;
};
export type ChatMessageFlowNodeInput = {
  nodeId: string;
  kind: ChatMessageFlowNodeKind;
  x: number;
  y: number;
  chatMessage: ChatMessageInput;
  chatMessageReplyGroup?: ChatMessageReplyGroupInput | null;
};
export type ChatMessageInput = {
  id?: string | null;
  messageType: ChatMessageKind;
  message?: string | null;
  submitUrl?: string | null;
  isInheritQuery: boolean;
  hasReply: boolean;
  saveReply: boolean;
  displaySpeed: number;
  transferReply: boolean;
  chatMessageImage?: ChatMessageImageInput | null;
};
export type ChatMessageImageInput = {
  id?: string | null;
  alt?: string | null;
  linkUrl?: string | null;
  image?: File | Blog | null;
  isInheritQuery?: boolean | null;
};
export type ChatMessageReplyGroupInput = {
  id?: string | null;
  replyGroupType: ChatMessageReplyGroupKind;
  label?: string | null;
  divergingPoint: boolean;
};
export type ChatMessageFlowEdgeInput = {
  edgeId: string;
  source: number;
  target: number;
};
export type ChatMessageReplyInput = {
  id?: string | null;
  parentNodeId: string;
  replyNodeId: string;
  replyType: ChatMessageReplyKind;
  label?: string | null;
  name: string;
  value?: string | null;
  regularExpression?: string | null;
  placeholder?: string | null;
  errorMessage?: string | null;
  apiAction?: string | null;
};
export type ChatbotApiResultInput = {
  replyIndex: number;
  nextMessageNumber: number;
  result: string;
};
export type UpdateOriginalChatbotInput = {
  clientMutationId?: string | null;
  chatbotGroupId: string;
  status?: DistributionStatus | null;
  weight?: number | null;
};
export type ChatbotPatternCreatePageContainer_Mutation$variables = {
  input: AddChatbotInput;
  originalInput: UpdateOriginalChatbotInput;
  withOriginal: boolean;
};
export type ChatbotPatternCreatePageContainer_MutationVariables = ChatbotPatternCreatePageContainer_Mutation$variables;
export type ChatbotPatternCreatePageContainer_Mutation$data = {
  readonly updateOriginalChatbot?: {
    readonly chatbot: {
      readonly id: string;
      readonly weight: number | null;
    };
  } | null;
  readonly addChatbot: {
    readonly chatbotEdge: {
      readonly node: {
        readonly id: string;
        readonly slug: string;
        readonly name: string;
      } | null;
    } | null;
  } | null;
};
export type ChatbotPatternCreatePageContainer_MutationResponse = ChatbotPatternCreatePageContainer_Mutation$data;
export type ChatbotPatternCreatePageContainer_Mutation = {
  variables: ChatbotPatternCreatePageContainer_MutationVariables;
  response: ChatbotPatternCreatePageContainer_Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "originalInput"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "withOriginal"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "condition": "withOriginal",
    "kind": "Condition",
    "passingValue": true,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "originalInput"
          }
        ],
        "concreteType": "UpdateOriginalChatbotPayload",
        "kind": "LinkedField",
        "name": "updateOriginalChatbot",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Chatbot",
            "kind": "LinkedField",
            "name": "chatbot",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "weight",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddChatbotPayload",
    "kind": "LinkedField",
    "name": "addChatbot",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ChatbotEdge",
        "kind": "LinkedField",
        "name": "chatbotEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Chatbot",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChatbotPatternCreatePageContainer_Mutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChatbotPatternCreatePageContainer_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "0f29ad6787bc36782caca89e6aeac10c",
    "id": null,
    "metadata": {},
    "name": "ChatbotPatternCreatePageContainer_Mutation",
    "operationKind": "mutation",
    "text": "mutation ChatbotPatternCreatePageContainer_Mutation(\n  $input: AddChatbotInput!\n  $originalInput: UpdateOriginalChatbotInput!\n  $withOriginal: Boolean!\n) {\n  updateOriginalChatbot(input: $originalInput) @include(if: $withOriginal) {\n    chatbot {\n      id\n      weight\n    }\n  }\n  addChatbot(input: $input) {\n    chatbotEdge {\n      node {\n        id\n        slug\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ada112332ac2908e0d188a51bb2acdf9";

export default node;
