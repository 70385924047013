import { HStack, VStack } from "@chakra-ui/react";
import { BarChart as BarChartIcon } from "@mui/icons-material";
import { Form, Formik } from "formik";
import { FC, useCallback } from "react";

import { SymbolSolidButton } from "~/src/components/common/Button";
import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormikFormButtons } from "~/src/components/common/forms/FormikFormButtons";
import {
  FormikMultipleSelectField,
  FormikMultipleSelectFieldProps,
} from "~/src/components/common/forms/FormikMultipleSelectField";
import {
  FormikSelectField,
  FormikSelectFieldProps,
} from "~/src/components/common/forms/FormikSelectField";
import { Modal } from "~/src/components/common/Modal";
import { useOpenState } from "~/src/lib/hooks";

export type FormValues = {
  [key in string]: string | string[] | null;
};

export type Props = {
  selectFields: SelectField[];
  formValues: FormValues;
  onSubmit: (formValues: FormValues) => Promise<void> | void;
};

type SelectField = {
  label: string;
  name: string;
  options:
    | FormikSelectFieldProps["options"]
    | FormikMultipleSelectFieldProps["options"];
  isMultiple: boolean;
};

export const GraphCustomizedButton: FC<Props> = ({
  selectFields,
  formValues,
  onSubmit,
}) => {
  const { isOpen, onClose, onOpen } = useOpenState();

  const handleSubmit = useCallback(
    (values: FormValues) => {
      onSubmit(values);
      onClose();
    },
    [onClose, onSubmit]
  );

  return (
    <HStack>
      <SymbolSolidButton
        size="24px"
        icon={<BarChartIcon />}
        isActive={isOpen}
        onClick={onOpen}
      >
        グラフをカスタマイズ
      </SymbolSolidButton>
      <Modal title="フィルタ" isOpen={isOpen} onClose={onClose}>
        <Formik<FormValues>
          enableReinitialize
          initialValues={formValues}
          validateOnMount={false}
          validateOnChange={false}
          onSubmit={handleSubmit}
        >
          <Form>
            <VStack spacing="16px">
              {selectFields.map((selectField, i) => (
                <FormControl key={i} label={selectField.label}>
                  {selectField.isMultiple ? (
                    <FormikMultipleSelectField
                      name={selectField.name}
                      options={selectField.options}
                    />
                  ) : (
                    <FormikSelectField
                      name={selectField.name}
                      options={selectField.options}
                    />
                  )}
                </FormControl>
              ))}
            </VStack>
            <FormikFormButtons onCancelClick={onClose} />
          </Form>
        </Formik>
      </Modal>
    </HStack>
  );
};
