import { HStack, useToast } from "@chakra-ui/react";
import { FC, useCallback } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";

import { PopupBulkArchiveModalContainer_Mutation } from "~/src/__relay_artifacts__/PopupBulkArchiveModalContainer_Mutation.graphql";
import { PopupBulkArchiveModalContainer_Query } from "~/src/__relay_artifacts__/PopupBulkArchiveModalContainer_Query.graphql";
import { GhostButton, SolidButton } from "~/src/components/common/Button";
import { useMutationCommit } from "~/src/lib/react-relay";

export type Props = {
  onClose: () => void;
  selectedSlug: string[];
};

const query = graphql`
  query PopupBulkArchiveModalContainer_Query($input: [String!]!) {
    popupGroups(slugs: $input) {
      __id
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const mutation = graphql`
  mutation PopupBulkArchiveModalContainer_Mutation(
    $input: ArchiveArchivableInput!
    $connections: [ID!]!
  ) {
    archiveArchivable(input: $input) {
      archivable {
        id @deleteEdge(connections: $connections)
        isArchive
      }
    }
  }
`;

export const PopupBulkArchiveModalContainer: FC<Props> = ({
  onClose,
  selectedSlug,
}) => {
  const { popupGroups } =
    useLazyLoadQuery<PopupBulkArchiveModalContainer_Query>(query, {
      input: selectedSlug,
    });
  const toast = useToast();
  const mutate =
    useMutationCommit<PopupBulkArchiveModalContainer_Mutation>(mutation);

  const onArchive = useCallback(async () => {
    popupGroups?.edges?.forEach(async (pg) => {
      try {
        if (!pg?.node?.id) throw new Error("assertion failed");
        const res = await mutate({
          variables: {
            input: {
              archivableId: pg.node.id,
            },
            connections: [popupGroups.__id],
          },
        });
        const archivable = res.archiveArchivable?.archivable;
        if (!archivable) throw new Error("assertion failed");

        toast({
          title: "アーカイブしました",
          status: "success",
        });
      } catch (err) {
        toast({
          title: "アーカイブに失敗しました",
          status: "error",
        });
      }

      onClose();
    });
  }, [popupGroups, mutate, toast, onClose]);

  return (
    <>
      選択したポップアップをアーカイブしますか？
      {popupGroups?.edges &&
        popupGroups.edges.map((popupGroup) => (
          <li key={"a"}>{popupGroup?.node?.name}</li>
        ))}
      <HStack mt="116px" spacing="8px" justifyContent="flex-end">
        <GhostButton onClick={onClose}>戻る</GhostButton>
        <SolidButton onClick={onArchive}>適用</SolidButton>
      </HStack>
    </>
  );
};
