/**
 * @generated SignedSource<<eb3c47cc1241d4c064ec9cf1695c10ee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FunnelSourceCreateFormContainer_funnel$data = {
  readonly id: string;
  readonly landingPage: {
    readonly id: string;
  } | null;
  readonly " $fragmentType": "FunnelSourceCreateFormContainer_funnel";
};
export type FunnelSourceCreateFormContainer_funnel = FunnelSourceCreateFormContainer_funnel$data;
export type FunnelSourceCreateFormContainer_funnel$key = {
  readonly " $data"?: FunnelSourceCreateFormContainer_funnel$data;
  readonly " $fragmentSpreads": FragmentRefs<"FunnelSourceCreateFormContainer_funnel">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FunnelSourceCreateFormContainer_funnel",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Page",
      "kind": "LinkedField",
      "name": "landingPage",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Funnel",
  "abstractKey": null
};
})();

(node as any).hash = "c3f6391d2bfebf94b73c0c667bac106c";

export default node;
