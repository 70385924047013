import { FC } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";

import { PopupGroupSetListPageContainer_Query } from "~/src/__relay_artifacts__/PopupGroupSetListPageContainer_Query.graphql";

import { PopupGroupSetList } from "./presentations";

export type Props = {};

const query = graphql`
  query PopupGroupSetListPageContainer_Query($siteSlug: String!) {
    site(slug: $siteSlug) {
      name
      ...PopupGroupSetListPage_site
    }
  }
`;

export const PopupGroupSetListPageContainer: FC<Props> = () => {
  const { siteSlug = "" } = useParams();
  const { site } = useLazyLoadQuery<PopupGroupSetListPageContainer_Query>(
    query,
    {
      siteSlug,
    }
  );

  return <PopupGroupSetList siteRef={site} />;
};
