/**
 * @generated SignedSource<<d4c1dfabfb0bff19ab55ecee105d0d74>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteAbtestScenarioPageContentInput = {
  clientMutationId?: string | null;
  abtestScenarioPageContentId: string;
};
export type ABTestPatternEditScreenContainer_DeleteMutation$variables = {
  input: DeleteAbtestScenarioPageContentInput;
};
export type ABTestPatternEditScreenContainer_DeleteMutationVariables = ABTestPatternEditScreenContainer_DeleteMutation$variables;
export type ABTestPatternEditScreenContainer_DeleteMutation$data = {
  readonly deleteAbtestScenarioPageContent: {
    readonly deletedAbtestScenarioPageContentId: string;
  } | null;
};
export type ABTestPatternEditScreenContainer_DeleteMutationResponse = ABTestPatternEditScreenContainer_DeleteMutation$data;
export type ABTestPatternEditScreenContainer_DeleteMutation = {
  variables: ABTestPatternEditScreenContainer_DeleteMutationVariables;
  response: ABTestPatternEditScreenContainer_DeleteMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteAbtestScenarioPageContentPayload",
    "kind": "LinkedField",
    "name": "deleteAbtestScenarioPageContent",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedAbtestScenarioPageContentId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ABTestPatternEditScreenContainer_DeleteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ABTestPatternEditScreenContainer_DeleteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3e69763ce276c886a6ac95d6c5fd85ec",
    "id": null,
    "metadata": {},
    "name": "ABTestPatternEditScreenContainer_DeleteMutation",
    "operationKind": "mutation",
    "text": "mutation ABTestPatternEditScreenContainer_DeleteMutation(\n  $input: DeleteAbtestScenarioPageContentInput!\n) {\n  deleteAbtestScenarioPageContent(input: $input) {\n    deletedAbtestScenarioPageContentId\n  }\n}\n"
  }
};
})();

(node as any).hash = "cfc8609551d493d078560a4ab7f73d0a";

export default node;
