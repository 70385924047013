import { Button } from "@chakra-ui/react";
import { rgba } from "polished";

import { chakraFactory } from "~/src/lib/chakra-ui";

import { buttonStyles } from "./styles";

export const OutlineButton = chakraFactory(Button, {
  baseStyle: {
    ...buttonStyles,
    color: "#2F68BA",
    background: "#FFFFFF",
    border: "1px solid #2F68BA",

    _active: {
      color: "#1867C0",
      borderColor: "#1867C0",
      boxShadow: "none",
    },

    _hover: {
      color: rgba("#1867C0", 0.6),
      borderColor: rgba("#1867C0", 0.6),
      boxShadow: "none",
    },

    _focus: {
      boxShadow: "none",
    },
  },
});
