/**
 * @generated SignedSource<<57d848c50c363a8e41954598904fa608>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeletePopupSetInput = {
  clientMutationId?: string | null;
  popupSetId: string;
};
export type PopupSetTableActions_Mutation$variables = {
  connections: ReadonlyArray<string>;
  input: DeletePopupSetInput;
};
export type PopupSetTableActions_MutationVariables = PopupSetTableActions_Mutation$variables;
export type PopupSetTableActions_Mutation$data = {
  readonly deletePopupSet: {
    readonly deletedPopupSetId: string;
  } | null;
};
export type PopupSetTableActions_MutationResponse = PopupSetTableActions_Mutation$data;
export type PopupSetTableActions_Mutation = {
  variables: PopupSetTableActions_MutationVariables;
  response: PopupSetTableActions_Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedPopupSetId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PopupSetTableActions_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeletePopupSetPayload",
        "kind": "LinkedField",
        "name": "deletePopupSet",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PopupSetTableActions_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeletePopupSetPayload",
        "kind": "LinkedField",
        "name": "deletePopupSet",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "deleteEdge",
            "key": "",
            "kind": "ScalarHandle",
            "name": "deletedPopupSetId",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9195a1161dbac8af3a4c09aaf022eef4",
    "id": null,
    "metadata": {},
    "name": "PopupSetTableActions_Mutation",
    "operationKind": "mutation",
    "text": "mutation PopupSetTableActions_Mutation(\n  $input: DeletePopupSetInput!\n) {\n  deletePopupSet(input: $input) {\n    deletedPopupSetId\n  }\n}\n"
  }
};
})();

(node as any).hash = "947b97098efed0661d36b963b86c8974";

export default node;
