/**
 * @generated SignedSource<<72ec9dc2a79fd8ed3915fe5425cc4736>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Device = "MOBILE" | "TABLET" | "PC" | "%future added value";
export type DistributionStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type PatternContentJudgeStatus = "PENDING" | "APPROVED" | "REJECTED" | "%future added value";
export type PopupKind = "A_PATTERN" | "B_PATTERN" | "%future added value";
export type CopyPopupInput = {
  clientMutationId?: string | null;
  popupId: string;
};
export type ReportAnalyzerInput = {
  startOn: string;
  endOn: string;
  device?: Device | null;
  sourceId?: string | null;
  funnelId?: string | null;
};
export type PopupPatternTableActions_CopyMutation$variables = {
  input: CopyPopupInput;
  analyzerInput: ReportAnalyzerInput;
  connections: ReadonlyArray<string>;
};
export type PopupPatternTableActions_CopyMutationVariables = PopupPatternTableActions_CopyMutation$variables;
export type PopupPatternTableActions_CopyMutation$data = {
  readonly copyPopup: {
    readonly popup: {
      readonly id: string;
      readonly slug: string;
      readonly name: string | null;
      readonly status: DistributionStatus;
      readonly isArchive: boolean;
      readonly kind: PopupKind | null;
      readonly judgeStatus: PatternContentJudgeStatus;
      readonly weight: number | null;
      readonly clickCvr: number;
      readonly clickCvUserCount: number;
      readonly clickUserCount: number;
      readonly clickUserRate: number;
      readonly cvr: number;
      readonly impressionCvUserCount: number;
      readonly impressionCvr: number;
      readonly impressionUserCount: number;
      readonly readyCvUserCount: number;
      readonly totalCvUserCount: number;
      readonly totalUserCount: number;
      readonly page: {
        readonly slug: string;
        readonly name: string;
        readonly funnel: {
          readonly name: string;
          readonly slug: string;
        };
      };
    } | null;
  } | null;
};
export type PopupPatternTableActions_CopyMutationResponse = PopupPatternTableActions_CopyMutation$data;
export type PopupPatternTableActions_CopyMutation = {
  variables: PopupPatternTableActions_CopyMutationVariables;
  response: PopupPatternTableActions_CopyMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "analyzerInput"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v3 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isArchive",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "judgeStatus",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "weight",
  "storageKey": null
},
v12 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "analyzerInput"
  }
],
v13 = {
  "alias": null,
  "args": (v12/*: any*/),
  "kind": "ScalarField",
  "name": "clickCvr",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": (v12/*: any*/),
  "kind": "ScalarField",
  "name": "clickCvUserCount",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": (v12/*: any*/),
  "kind": "ScalarField",
  "name": "clickUserCount",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": (v12/*: any*/),
  "kind": "ScalarField",
  "name": "clickUserRate",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": (v12/*: any*/),
  "kind": "ScalarField",
  "name": "cvr",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": (v12/*: any*/),
  "kind": "ScalarField",
  "name": "impressionCvUserCount",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": (v12/*: any*/),
  "kind": "ScalarField",
  "name": "impressionCvr",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": (v12/*: any*/),
  "kind": "ScalarField",
  "name": "impressionUserCount",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": (v12/*: any*/),
  "kind": "ScalarField",
  "name": "readyCvUserCount",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": (v12/*: any*/),
  "kind": "ScalarField",
  "name": "totalCvUserCount",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": (v12/*: any*/),
  "kind": "ScalarField",
  "name": "totalUserCount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PopupPatternTableActions_CopyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "CopyPopupPayload",
        "kind": "LinkedField",
        "name": "copyPopup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Popup",
            "kind": "LinkedField",
            "name": "popup",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "page",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Funnel",
                    "kind": "LinkedField",
                    "name": "funnel",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "PopupPatternTableActions_CopyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "CopyPopupPayload",
        "kind": "LinkedField",
        "name": "copyPopup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Popup",
            "kind": "LinkedField",
            "name": "popup",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "page",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Funnel",
                    "kind": "LinkedField",
                    "name": "funnel",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v5/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "popup",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "PopupEdge"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "257ce41b096306f380fd450dd00146c9",
    "id": null,
    "metadata": {},
    "name": "PopupPatternTableActions_CopyMutation",
    "operationKind": "mutation",
    "text": "mutation PopupPatternTableActions_CopyMutation(\n  $input: CopyPopupInput!\n  $analyzerInput: ReportAnalyzerInput!\n) {\n  copyPopup(input: $input) {\n    popup {\n      id\n      slug\n      name\n      status\n      isArchive\n      kind\n      judgeStatus\n      weight\n      clickCvr(input: $analyzerInput)\n      clickCvUserCount(input: $analyzerInput)\n      clickUserCount(input: $analyzerInput)\n      clickUserRate(input: $analyzerInput)\n      cvr(input: $analyzerInput)\n      impressionCvUserCount(input: $analyzerInput)\n      impressionCvr(input: $analyzerInput)\n      impressionUserCount(input: $analyzerInput)\n      readyCvUserCount(input: $analyzerInput)\n      totalCvUserCount(input: $analyzerInput)\n      totalUserCount(input: $analyzerInput)\n      page {\n        slug\n        name\n        funnel {\n          name\n          slug\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e686b858b388bcd839606292c1278dd6";

export default node;
