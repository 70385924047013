import { useToast } from "@chakra-ui/toast";
import { FC, useCallback } from "react";
import { graphql, useFragment } from "react-relay";

import { FunnelListTabContainer_Mutation } from "~/src/__relay_artifacts__/FunnelListTabContainer_Mutation.graphql";
import { FunnelListTabContainer_site$key } from "~/src/__relay_artifacts__/FunnelListTabContainer_site.graphql";
import { FunnelListTabContainer_user$key } from "~/src/__relay_artifacts__/FunnelListTabContainer_user.graphql";
import { useMutationCommit } from "~/src/lib/react-relay";

import { FunnelListTab } from "./presentations";

export type Props = {
  siteRef: FunnelListTabContainer_site$key;
  userRef: FunnelListTabContainer_user$key;
};

const siteFragment = graphql`
  fragment FunnelListTabContainer_site on Site {
    ...FunnelListTab_site
  }
`;

const userFragment = graphql`
  fragment FunnelListTabContainer_user on User {
    ...FunnelListTab_user
  }
`;

const mutation = graphql`
  mutation FunnelListTabContainer_Mutation(
    $input: DeleteFunnelInput!
    $connections: [ID!]!
  ) {
    deleteFunnel(input: $input) {
      deletedFunnelId @deleteEdge(connections: $connections)
    }
  }
`;

export const FunnelListTabContainer: FC<Props> = ({ siteRef, userRef }) => {
  const site = useFragment(siteFragment, siteRef);
  const user = useFragment(userFragment, userRef);
  const toast = useToast();

  const mutate = useMutationCommit<FunnelListTabContainer_Mutation>(mutation);

  const handleFunnelDeleteClick = useCallback(
    async (funnelId: string, connectionId: string) => {
      try {
        const res = await mutate({
          variables: {
            input: {
              funnelId,
            },
            connections: [connectionId],
          },
        });
        const deletedFunnelId = res.deleteFunnel?.deletedFunnelId;
        if (!deletedFunnelId) throw new Error("assertion faield");

        toast({
          title: `ファネルを削除しました`,
          status: "success",
        });
      } catch (err) {
        toast({ title: "ファネルの削除に失敗しました", status: "error" });
      }
    },
    [mutate, toast]
  );

  return (
    <FunnelListTab
      siteRef={site}
      userRef={user}
      onFunnelDeleteClick={handleFunnelDeleteClick}
    />
  );
};
