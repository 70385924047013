import { FC, MouseEventHandler, useCallback } from "react";
import {
  EdgeProps,
  getBezierPath,
  getEdgeCenter,
  useReactFlow,
} from "react-flow-renderer";

import { chakraFactory } from "~/src/lib/chakra-ui";

const foreignObjectSize = 40;

const EdgeButtonWrapper = chakraFactory("button", {
  baseStyle: {
    width: "20px",
    height: "20px",
    background: "#eee",
    border: "1px solid #fff",
    cursor: "pointer",
    borderRadius: "50%",
    fontSize: "12px",
    lineHeight: "1",
  },
});

const BodyWrapper = chakraFactory("body", {
  baseStyle: {
    background: "transparent",
    width: "40px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "40px",
  },
});

export const Edge: FC<EdgeProps<any>> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  markerEnd,
}) => {
  const { getEdges, setEdges } = useReactFlow();

  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e.stopPropagation();
      const edges = getEdges().filter((edge) => edge.id !== id);
      setEdges(edges);
    },
    [id, getEdges, setEdges]
  );

  return (
    <>
      <path
        id={id}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={edgeCenterX - foreignObjectSize / 2}
        y={edgeCenterY - foreignObjectSize / 2}
        className="edgebutton-foreignobject"
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <BodyWrapper>
          <EdgeButtonWrapper type="button" onClick={handleClick}>
            ×
          </EdgeButtonWrapper>
        </BodyWrapper>
      </foreignObject>
    </>
  );
};
