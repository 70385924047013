import { Box } from "@chakra-ui/react";
import { FC, useMemo } from "react";

import { PageLayout } from "~/src/components/features/global/PageLayout";

export type Props = {
  siteSlug: string;
  funnelSlug: string;
};

export const FunnelSourceCreatePage: FC<Props> = ({
  children,
  siteSlug,
  funnelSlug,
}) => {
  const breadcrumbs = useMemo(
    () => [
      { label: "設定", path: `/sites/${siteSlug}/settings` },
      {
        label: "流入元登録",
        path: `/sites/${siteSlug}/funnels/${funnelSlug}/sources/new`,
      },
    ],
    [funnelSlug, siteSlug]
  );

  return (
    <PageLayout title="流入元登録" breadcrumbs={breadcrumbs}>
      <Box mt="32px">{children}</Box>
    </PageLayout>
  );
};
