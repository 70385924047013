export const measureOptions = [
  { label: "配信人数", value: "PAGE_VIEW_USER" },
  { label: "利用人数", value: "START_USER" },
  { label: "CV", value: "CONVERSION_USER" },
  { label: "CTR", value: "START_USER_RATE" },
  { label: "CVR", value: "CONVERSION_USER_RATE" },
] as const;

export const disableColumnOptions = [
  { label: "配信人数", value: 2 },
  { label: "利用人数", value: 3 },
  { label: "CV数", value: 4 },
  { label: "CTR", value: 5 },
  { label: "CVR", value: 6 },
  { label: "SP", value: 7 },
  { label: "TB", value: 8 },
  { label: "PC", value: 9 },
] as const;
