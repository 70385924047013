import { Box, HStack, VStack } from "@chakra-ui/layout";
import { Image, Text } from "@chakra-ui/react";
import { FC } from "react";

import { Table } from "~/src/components/common/tables/Table";

type Props = {};

export const ScenarioFieldBeforeImageList: FC<Props> = () => {
  return (
    <Table width="45%" h="256px" backgroundColor="blackAlpha.100">
      <Box p={"2"}>
        <HStack>
          <Image
            maxW={"30%"}
            src="https://via.placeholder.com/420x360.jpg?text=Mobile"
          />
          <VStack>
            <Text fontSize={"xs"}>{"ファイル名 : xxxxx.gif"}</Text>
            <Text fontSize={"xs"}>{"幅 : 320px"}</Text>
            <Text fontSize={"xs"}>{"高さ : 100px"}</Text>
          </VStack>
        </HStack>
      </Box>
      <Box p={"2"}>
        <HStack>
          <Image
            maxW={"30%"}
            src="https://via.placeholder.com/420x360.jpg?text=Mobile"
          />
          <VStack>
            <Text fontSize={"xs"}>{"ファイル名 : xxxxx.gif"}</Text>
            <Text fontSize={"xs"}>{"幅 : 320px"}</Text>
            <Text fontSize={"xs"}>{"高さ : 100px"}</Text>
          </VStack>
        </HStack>
      </Box>
      <Box p={"2"}>
        <HStack>
          <Image
            maxW={"30%"}
            src="https://via.placeholder.com/420x360.jpg?text=Mobile"
          />
          <VStack>
            <Text fontSize={"xs"}>{"ファイル名 : xxxxx.gif"}</Text>
            <Text fontSize={"xs"}>{"幅 : 320px"}</Text>
            <Text fontSize={"xs"}>{"高さ : 100px"}</Text>
          </VStack>
        </HStack>
      </Box>
      <Box p={"2"}>
        <HStack>
          <Image
            maxW={"30%"}
            src="https://via.placeholder.com/420x360.jpg?text=Mobile"
          />
          <VStack>
            <Text fontSize={"xs"}>{"ファイル名 : xxxxx.gif"}</Text>
            <Text fontSize={"xs"}>{"幅 : 320px"}</Text>
            <Text fontSize={"xs"}>{"高さ : 100px"}</Text>
          </VStack>
        </HStack>
      </Box>
      <Box p={"2"}>
        <HStack>
          <Image
            maxW={"30%"}
            src="https://via.placeholder.com/420x360.jpg?text=Mobile"
          />
          <VStack>
            <Text fontSize={"xs"}>{"ファイル名 : xxxxx.gif"}</Text>
            <Text fontSize={"xs"}>{"幅 : 320px"}</Text>
            <Text fontSize={"xs"}>{"高さ : 100px"}</Text>
          </VStack>
        </HStack>
      </Box>
    </Table>
  );
};
