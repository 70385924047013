import { Box, HStack } from "@chakra-ui/react";
import { useField } from "formik";
// , useFormikContext
import { FC } from "react";

import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormikSelectField } from "~/src/components/common/forms/FormikSelectField";
import { FormikTextareaField } from "~/src/components/common/forms/FormikTextareaField";
import { FormikTextField } from "~/src/components/common/forms/FormikTextField";

import { ContentWithTextReplaceField } from "../../validationSchema";

export type Props = {
  name: string;
  index: number;
};

const targetOptions = [
  { label: "ID", value: "ID" },
  { label: "Selector", value: "SELECTOR" },
  { label: "XPath", value: "XPATH" },
];

const replaceOptions = [
  { label: "テキスト変更", value: "TEXT_REPLACEMENT" },
  { label: "div追加", value: "DIV_ELEMENT_ADDITION" },
  { label: "attributeを変更", value: "ATTRIBUTE_REPLACEMENT" },
];

export const TextReplaceField: FC<Props> = ({ name, index }) => {
  const [textReplaceFields] = useField<ContentWithTextReplaceField[]>(name);

  return (
    <FormControl label="変更対象">
      <HStack spacing={8}>
        <Box w="50%">
          <FormikSelectField
            mode="fill"
            name={`${name}.${index}.targetType`}
            options={targetOptions}
          />
        </Box>
        <Box w="50%">
          <FormikTextField
            type="text"
            mode="fill"
            name={`${name}.${index}.targetValue`}
          />
        </Box>
      </HStack>
      <Box w="50%" paddingTop={"4"}>
        <FormikSelectField
          mode="fill"
          name={`${name}.${index}.actionType`}
          options={replaceOptions}
        />
      </Box>
      {textReplaceFields.value[index].actionType ===
        "ATTRIBUTE_REPLACEMENT" && (
        <Box w="50%" paddingTop={"4"}>
          <FormikTextField
            mode="fill"
            type="text"
            name={`${name}.${index}.targetAttribute`}
          />
        </Box>
      )}
      <Box paddingTop={"4"}>
        <FormikTextareaField
          mode="fill"
          name={`${name}.${index}.replaceValue`}
          placeholder={"変更内容を入力してください"}
        />
      </Box>
    </FormControl>
  );
};
