import { FC, useCallback } from "react";
import { ConnectionHandler, graphql } from "react-relay";
import { useNavigate } from "react-router-dom";

import { NotificationCreatePage_Mutation } from "~/src/__relay_artifacts__/NotificationCreatePage_Mutation.graphql";
import { PageLayout } from "~/src/components/features/global/PageLayout";
import {
  NotificationForm,
  NotificationFormProps,
} from "~/src/components/features/settings/NotificationForms/presentations";
import { useFormErrorHandler } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";

const mutation = graphql`
  mutation NotificationCreatePage_Mutation(
    $connections: [ID!]!
    $input: CreateNotificationInput!
  ) {
    createNotification(input: $input) {
      notification
        @appendNode(
          connections: $connections
          edgeTypeName: "NotificationEdge"
        ) {
        id
        title
      }
    }
  }
`;

export const NotificationCreatePage: FC = () => {
  const navigate = useNavigate();
  const handleCancel = useCallback(() => navigate(-1), [navigate]);
  const { onFormError } = useFormErrorHandler();

  const mutate = useMutationCommit<NotificationCreatePage_Mutation>(mutation);

  const handleSubmit = useCallback<NotificationFormProps["onSubmit"]>(
    async ({ ...values }, { setErrors }) => {
      try {
        const connectionId = ConnectionHandler.getConnectionID(
          "client:root",
          "NotificationListPage_root_notifications"
        );
        const res = await mutate({
          variables: {
            input: {
              ...values,
            },
            connections: [connectionId],
          },
        });

        const notification = res.createNotification?.notification;
        if (!notification) throw new Error("assertion failed");

        navigate(`/notifications`);
      } catch (err) {
        onFormError(err, setErrors);
      }
    },
    [mutate, navigate, onFormError]
  );

  return (
    <PageLayout title="お知らせ">
      <NotificationForm onCancelClick={handleCancel} onSubmit={handleSubmit} />
    </PageLayout>
  );
};
