import { FC } from "react";
import {
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";

import { CustomVictoryTooltip } from "./CustomVictoryTooltip";
import type { Data } from "./types";

export type Props = {
  width: number;
  dataArray: { data: Data[] }[];
  label: string;
};

const baseLabelStyles = {
  fontFamily: "'Noto Sans JP'",
  fontSize: 12,
  letterSpacing: "normal",
  padding: 7,
  fill: "#979797",
  stroke: "transparent",
};

const centeredLabelStyles = { textAnchor: "middle", ...baseLabelStyles };

const theme = {
  axis: {
    style: {
      axis: {
        fill: "transparent",
        stroke: "#DADADA",
        strokeWidth: 1,
        strokeLinecap: "round",
        strokeLinejoin: "round",
      },
      axisLabel: { ...centeredLabelStyles, padding: 25 },
      grid: {
        fill: "none",
        stroke: "#DADADA",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        pointerEvents: "painted",
      },
      ticks: {
        fill: "transparent",
        size: 1,
        stroke: "transparent",
      },
      tickLabels: baseLabelStyles,
    },
  },
};

export const CustomVictoryChart: FC<Props> = ({ width, dataArray, label }) => (
  <VictoryChart
    width={width}
    height={300}
    padding={58}
    theme={theme}
    domainPadding={{ x: 50, y: 10 }}
    containerComponent={
      <VictoryVoronoiContainer
        // eslint-disable-next-line react/jsx-no-bind
        labels={() => " "}
        labelComponent={
          <VictoryTooltip
            flyoutComponent={<CustomVictoryTooltip label={label} />}
          />
        }
      />
    }
  >
    <linearGradient id="gradient" x1="0" x2="0" y1="0" y2="1">
      <stop offset="0%" stopColor="rgba(24, 103, 192, 0.4)" />
      <stop offset="100%" stopColor="rgba(24, 103, 192, 0)" />
    </linearGradient>
    <VictoryAxis
      // eslint-disable-next-line react/forbid-component-props
      style={{
        grid: {
          fill: "none",
          stroke: "none",
          pointerEvents: "painted",
        },
      }}
    />
    <VictoryAxis dependentAxis />
    {dataArray.map(({ data }, i) => (
      <VictoryLine
        key={i}
        data={data}
        /* eslint-disable */
        style={{ data: { stroke: "#1867C0", strokeWidth: 3 } }}
      />
    ))}
    {dataArray.map(({ data }, i) => (
      <VictoryScatter
        key={i}
        size={5}
        data={data}
        /* eslint-disable */
        style={{ data: { fill: "#1867C0" } }}
      />
    ))}
  </VictoryChart>
);
