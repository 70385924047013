/**
 * @generated SignedSource<<fc3acd6764b22952b85eabd95a4db477>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UserRole = "SUPER" | "ADMIN_ALL" | "ADMIN" | "JUDGE" | "CLIENT" | "SAAS_CLIENT" | "%future added value";
export type HeaderWithSidebarLayoutContainer_Query$variables = {};
export type HeaderWithSidebarLayoutContainer_QueryVariables = HeaderWithSidebarLayoutContainer_Query$variables;
export type HeaderWithSidebarLayoutContainer_Query$data = {
  readonly viewer: {
    readonly id: string;
    readonly company: string | null;
    readonly email: string;
    readonly role: UserRole;
  };
};
export type HeaderWithSidebarLayoutContainer_QueryResponse = HeaderWithSidebarLayoutContainer_Query$data;
export type HeaderWithSidebarLayoutContainer_Query = {
  variables: HeaderWithSidebarLayoutContainer_QueryVariables;
  response: HeaderWithSidebarLayoutContainer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "company",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "role",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "HeaderWithSidebarLayoutContainer_Query",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "HeaderWithSidebarLayoutContainer_Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "7b7b23f00e48358da96284465810cd77",
    "id": null,
    "metadata": {},
    "name": "HeaderWithSidebarLayoutContainer_Query",
    "operationKind": "query",
    "text": "query HeaderWithSidebarLayoutContainer_Query {\n  viewer {\n    id\n    company\n    email\n    role\n  }\n}\n"
  }
};
})();

(node as any).hash = "19c51baf6be22809741cf7e50fbde014";

export default node;
