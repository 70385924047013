import { Divider } from "@chakra-ui/react";
import { FC, MouseEventHandler, useCallback } from "react";
import {
  Handle,
  Node,
  NodeProps,
  Position,
  useReactFlow,
} from "react-flow-renderer";

import { Modal } from "~/src/components/common/Modal";
import { chakraFactory } from "~/src/lib/chakra-ui";
import { useOpenState } from "~/src/lib/hooks";

import { ChatMessageFormContainer } from "./ChatMessageFormContainer";
import { ReplyGroupFormContainer } from "./ReplayGroupFormContainer";
import {
  ChatMessageFormValues as FormValues,
  NodeData,
  ReplyFormValues,
  ReplyGroupFormValues,
} from "./types";

const TextNodeWrapper = chakraFactory("div", {
  baseStyle: {
    border: "1px solid #eee",
    padding: "5px",
    borderRadius: "5px",
    background: "white",
    width: "150px",
  },
});

const ModalButton = chakraFactory("button", {
  baseStyle: {
    display: "block",
    color: "#777",
    fontSize: "12px",
  },
});

const RemoveButton = chakraFactory("button", {
  baseStyle: {
    width: "20px",
    height: "20px",
    background: "#eee",
    border: "1px solid #fff",
    cursor: "pointer",
    borderRadius: "50%",
    fontSize: "12px",
    lineHeight: "1",
    marginLeft: "110px",
  },
});

const ReplyGroupOpenButton = chakraFactory("button", {
  baseStyle: {
    display: "block",
    color: "#497",
    fontSize: "12px",
  },
});

const generateReplyNodeId = (id: string, index: number): string => {
  return `${id}_reply_${index + 1}`;
};

export const TextNode: FC<NodeProps<NodeData>> = ({ id }) => {
  const { isOpen, onClose, onOpen } = useOpenState();
  const replyGroupOpenState = useOpenState();
  const { getNodes, setNodes, getNode, getEdges, setEdges } =
    useReactFlow<NodeData>();
  const initialMessageValues: FormValues = {
    id: null,
    kind: "TEXT",
    message: null,
    isSaveReply: false,
    isTransferReply: true,
    displaySpeed: 0,
    isInheritQuery: false,
    submitUrl: null,
  };
  const intialReplyGroupValues: ReplyGroupFormValues = {
    id: null,
    kind: "TEXT_BOX",
    label: null,
    divergingPoint: false,
    replies: [],
  };
  const messageValues = getNode(id)?.data.message;
  const replyGroupValues = getNode(id)?.data.replyGroup;
  const nodeHeight = 130;

  const handleDoubleClickForChatMessageForm = useCallback<
    MouseEventHandler<HTMLButtonElement>
  >(() => {
    onOpen();
  }, [onOpen]);

  const handleDoubleClickForReplyGroupForm = useCallback<
    MouseEventHandler<HTMLButtonElement>
  >(() => {
    replyGroupOpenState.onOpen();
  }, [replyGroupOpenState]);

  const handleChatmessageFormSubmit = useCallback(
    (values: FormValues) => {
      const nodes = getNodes().map((node) => {
        if (node.id !== id) return node;
        node.data.message = values;
        return node;
      });
      setNodes(nodes);
      onClose();
    },
    [onClose, id, getNodes, setNodes]
  );

  const generateReplyNodes = useCallback(
    (replies: ReplyFormValues[]) => {
      return replies.map((reply, index) => {
        return {
          id: generateReplyNodeId(id, index),
          type: "REPLY",
          position: { x: 15, y: 70 + 40 * (index + 1) },
          data: {
            label: `${index + 1}. ${reply.name}`,
            message: null,
            replyGroup: null,
          },
          parentNode: id,
        } as Node<NodeData>;
      });
    },
    [id]
  );

  const removeParentEdge = useCallback(() => {
    const currentEdges = getEdges();
    const edges = currentEdges.filter((edge) => edge.source !== id);
    setEdges(edges);
  }, [getEdges, id, setEdges]);

  const handleReplyGroupFormSubmit = useCallback(
    (values: ReplyGroupFormValues) => {
      const nodes = getNodes().map((node) => {
        if (node.id !== id) return node;
        node.data.replyGroup = values;

        if (!values.divergingPoint) return node;

        node.data.replyGroup.replies.forEach((reply, index) => {
          reply.replyNodeId = generateReplyNodeId(id, index);
        });

        return node;
      });

      if (values.divergingPoint) {
        removeParentEdge();
        const replyNodes = generateReplyNodes(values.replies);
        replyNodes.forEach((replyNode) => nodes.push(replyNode));
      }

      setNodes(nodes);
      replyGroupOpenState.onClose();
    },
    [
      getNodes,
      setNodes,
      replyGroupOpenState,
      id,
      removeParentEdge,
      generateReplyNodes,
    ]
  );

  const handleRemove = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
    const nodesWithoutReplyNodes = getNodes().filter(
      (node) => node.parentNode !== id
    );
    const nodesWithoutRemoveNode = nodesWithoutReplyNodes.filter(
      (node) => node.id !== id
    );
    setNodes(nodesWithoutRemoveNode);

    const edgesWithoutSourceMatchEdge = getEdges().filter(
      (edge) => edge.source !== id
    );
    const edgesWithoutTargetMatchEdge = edgesWithoutSourceMatchEdge.filter(
      (edge) => edge.target !== id
    );

    setEdges(edgesWithoutTargetMatchEdge);
  }, [getNodes, id, setNodes, getEdges, setEdges]);

  return (
    <TextNodeWrapper
      height={
        replyGroupValues && replyGroupValues.divergingPoint
          ? `${nodeHeight + replyGroupValues.replies.length * 40}px`
          : `${nodeHeight}px`
      }
    >
      <Handle type="target" position={Position.Top} />
      <RemoveButton type="button" onClick={handleRemove}>
        ×
      </RemoveButton>
      <ModalButton
        type="button"
        onDoubleClick={handleDoubleClickForChatMessageForm}
        onBlur={undefined}
      >
        ダブルクリックでメッセージ設定
      </ModalButton>
      <Divider />
      <ReplyGroupOpenButton
        type="button"
        onDoubleClick={handleDoubleClickForReplyGroupForm}
      >
        ダブルクリックでリプライグループ設定
      </ReplyGroupOpenButton>

      {/*   */}
      {(!replyGroupValues ||
        !replyGroupValues.divergingPoint ||
        replyGroupValues.replies.length === 0) && (
        <Handle type="source" position={Position.Bottom} id={id} />
      )}

      <Modal
        title="[テキスト]メッセージの設定"
        isOpen={isOpen}
        onClose={onClose}
      >
        <ChatMessageFormContainer
          initialValues={messageValues || initialMessageValues}
          handleSubmit={handleChatmessageFormSubmit}
          handleClose={onClose}
        />
      </Modal>
      <Modal
        title="リプライグループの設定"
        isOpen={replyGroupOpenState.isOpen}
        onClose={replyGroupOpenState.onClose}
      >
        <ReplyGroupFormContainer
          onClose={replyGroupOpenState.onClose}
          onSubmit={handleReplyGroupFormSubmit}
          initialValues={replyGroupValues || intialReplyGroupValues}
        />
      </Modal>
    </TextNodeWrapper>
  );
};
