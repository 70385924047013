import { Center, useToast } from "@chakra-ui/react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { FC, useCallback } from "react";

import {
  ForgotPasswordForm,
  ForgotPasswordFormProps,
} from "../../features/auth";

export type Props = {};

export const ForgotPasswordPage: FC<Props> = () => {
  const auth = getAuth();
  const toast = useToast();

  const handleSubmit = useCallback<ForgotPasswordFormProps["onSubmit"]>(
    async (values) => {
      sendPasswordResetEmail(auth, values.email);
      toast({
        title: "メールを送信しました。ご確認ください。",
        status: "success",
      });
    },
    [auth, toast]
  );

  return (
    <Center h={"100%"}>
      <ForgotPasswordForm onSubmit={handleSubmit} />
    </Center>
  );
};
