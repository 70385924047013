import { FC, useMemo } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";

import { FunnelABTestGroupList_Query } from "~/src/__relay_artifacts__/FunnelABTestGroupList_Query.graphql";
import {
  Table,
  TableHeaderColumn,
  TableHeaderRow,
} from "~/src/components/common/tables/Table";

import { FunnelABTestGroupTableDataRow } from "./FunnelABTestGroupTableDataRow";

const query = graphql`
  query FunnelABTestGroupList_Query(
    $siteSlug: String!
    $funnelIds: [ID!]
    $pageIds: [ID!]
    $count: Int
    $cursor: String
  ) {
    viewer {
      role
    }
    site(slug: $siteSlug) {
      abtestScenarioPageGroups(
        funnelIds: $funnelIds
        pageIds: $pageIds
        first: $count
        after: $cursor
      ) @connection(key: "FunnelABTestGroupList_abtestScenarioPageGroups") {
        __id
        totalCount
        edges {
          node {
            abtestScenarioPages(exceptKind: DEFAULT) {
              totalCount
            }
            page {
              id
              kind
              name
              url
              sortNumber
            }
            endDate
            id
            memo
            slug
            startDate
            status
            title
            updatedAt
            isArchive
            judgeStatus
          }
        }
      }
    }
  }
`;

type Props = {
  funnelId: string;
  pageId: string | null;
  siteSlug: string;
};

export const FunnelABTestGroupList: FC<Props> = ({
  siteSlug,
  funnelId,
  pageId,
}) => {
  const { site, viewer } = useLazyLoadQuery<FunnelABTestGroupList_Query>(
    query,
    {
      siteSlug,
      funnelIds: [funnelId],
      pageIds: pageId ? [pageId] : [],
    }
  );

  const abtests = useMemo(() => {
    return (
      site.abtestScenarioPageGroups.edges?.map((edge) => {
        const node = edge?.node;
        if (!node) throw new Error("assertion failed");
        return node;
      }) || []
    );
  }, [site.abtestScenarioPageGroups]);

  return (
    <Table>
      <TableHeaderRow>
        <TableHeaderColumn>ABテスト名</TableHeaderColumn>
        <TableHeaderColumn>実施ステータス</TableHeaderColumn>
        {viewer.role !== "SAAS_CLIENT" && (
          <TableHeaderColumn>承認状態</TableHeaderColumn>
        )}
        <TableHeaderColumn>実施ページ</TableHeaderColumn>
        <TableHeaderColumn>ABテストパターン数</TableHeaderColumn>
        <TableHeaderColumn>開始日</TableHeaderColumn>
        <TableHeaderColumn>終了日</TableHeaderColumn>
        <TableHeaderColumn>最終更新日</TableHeaderColumn>
        <TableHeaderColumn>アクション</TableHeaderColumn>
      </TableHeaderRow>
      {abtests.map((abtest, index) => (
        <FunnelABTestGroupTableDataRow
          key={index}
          siteSlug={siteSlug}
          role={viewer.role}
          abtest={{
            id: abtest.id,
            title: abtest.title,
            slug: abtest.slug,
            status: abtest.status,
            judgeStatus: abtest.judgeStatus,
            startDate: abtest.startDate,
            endDate: abtest.endDate,
            updatedAt: abtest.updatedAt,
            isArchive: abtest.isArchive,
            patternCount: abtest.abtestScenarioPages?.totalCount || 0,
          }}
          funnelId={funnelId}
          connectionId={site.abtestScenarioPageGroups.__id}
          page={{
            id: abtest.page.id,
            kind: abtest.page.kind,
            name: abtest.page.name,
            url: abtest.page.url,
            sortNumber: abtest.page.sortNumber,
          }}
        />
      ))}
    </Table>
  );
};
