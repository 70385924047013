import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FC } from "react";

import { chakraFactory } from "~/src/lib/chakra-ui";

export type Props = {
  className?: string;
  description: string;
};

const StyledAccordion = chakraFactory(Accordion, {
  baseStyle: {
    width: "100%",
  },
});

const StyledAccordionItem = chakraFactory(AccordionItem, {
  baseStyle: {
    width: "100%",
    background: "#FFFFFF",
    border: "1px solid #DADADA",
    padding: "23px",
  },
});

const StyledPaddedDescriptionText = chakraFactory(Text, {
  baseStyle: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    fontWeight: "500",
    fontSize: "13px",
    color: "#000000",
  },
});

const StyledAccordionButton = chakraFactory(AccordionButton, {
  baseStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "24px",
    padding: 0,

    _hover: {
      background: "#ffffff",
    },

    _focus: {
      boxShadow: "none",
    },
  },
});

const StyledAccordionPanel = chakraFactory(AccordionPanel, {
  baseStyle: {
    padding: "26px 0 0 0",
  },
});

export const FormControlGroupWithAccordion: FC<Props> = ({
  children,
  description,
}) => (
  <StyledAccordion allowMultiple>
    <StyledAccordionItem>
      <StyledAccordionButton>
        <StyledPaddedDescriptionText>{description}</StyledPaddedDescriptionText>
        <AccordionIcon />
      </StyledAccordionButton>
      <StyledAccordionPanel>
        <VStack spacing="16px">{children}</VStack>
      </StyledAccordionPanel>
    </StyledAccordionItem>
  </StyledAccordion>
);
