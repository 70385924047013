/**
 * @generated SignedSource<<1d93c7b01af42678f5e06b9c0a67dc16>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PageKind = "LANDING" | "FORM" | "CONFIRM" | "THANKS" | "%future added value";
export type PopupPageReportFilterButtonForm_Query$variables = {
  slug: string;
  pageKind?: PageKind | null;
  pageIds?: ReadonlyArray<string> | null;
  funnelIds?: ReadonlyArray<string> | null;
};
export type PopupPageReportFilterButtonForm_QueryVariables = PopupPageReportFilterButtonForm_Query$variables;
export type PopupPageReportFilterButtonForm_Query$data = {
  readonly sources: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
      } | null;
    } | null> | null;
  };
  readonly site: {
    readonly funnels: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string;
        } | null;
      } | null> | null;
    };
    readonly popups: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string | null;
          readonly page: {
            readonly id: string;
            readonly kind: PageKind;
            readonly name: string;
            readonly sortNumber: number;
            readonly funnel: {
              readonly id: string;
              readonly name: string;
            };
          };
        } | null;
      } | null> | null;
    };
    readonly pages: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly kind: PageKind;
          readonly name: string;
          readonly sortNumber: number;
          readonly funnel: {
            readonly id: string;
            readonly name: string;
          };
        } | null;
      } | null> | null;
    };
  };
};
export type PopupPageReportFilterButtonForm_QueryResponse = PopupPageReportFilterButtonForm_Query$data;
export type PopupPageReportFilterButtonForm_Query = {
  variables: PopupPageReportFilterButtonForm_QueryVariables;
  response: PopupPageReportFilterButtonForm_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "funnelIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageIds"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageKind"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "slug"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = [
  (v4/*: any*/),
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "SourceConnection",
  "kind": "LinkedField",
  "name": "sources",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SourceEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Source",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v6/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "FunnelConnection",
  "kind": "LinkedField",
  "name": "funnels",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FunnelEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Funnel",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v6/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "kind": "Variable",
  "name": "funnelIds",
  "variableName": "funnelIds"
},
v11 = {
  "kind": "Variable",
  "name": "pageKind",
  "variableName": "pageKind"
},
v12 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "kind",
    "storageKey": null
  },
  (v5/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "sortNumber",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Funnel",
    "kind": "LinkedField",
    "name": "funnel",
    "plural": false,
    "selections": (v6/*: any*/),
    "storageKey": null
  }
],
v13 = {
  "alias": null,
  "args": [
    (v10/*: any*/),
    {
      "kind": "Variable",
      "name": "pageIds",
      "variableName": "pageIds"
    },
    (v11/*: any*/)
  ],
  "concreteType": "PopupConnection",
  "kind": "LinkedField",
  "name": "popups",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PopupEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Popup",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Page",
              "kind": "LinkedField",
              "name": "page",
              "plural": false,
              "selections": (v12/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": [
    (v10/*: any*/),
    (v11/*: any*/)
  ],
  "concreteType": "PageConnection",
  "kind": "LinkedField",
  "name": "pages",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PageEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Page",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v12/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PopupPageReportFilterButtonForm_Query",
    "selections": [
      (v7/*: any*/),
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PopupPageReportFilterButtonForm_Query",
    "selections": [
      (v7/*: any*/),
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e8db9f02a8e43d3c51288877a70b90b0",
    "id": null,
    "metadata": {},
    "name": "PopupPageReportFilterButtonForm_Query",
    "operationKind": "query",
    "text": "query PopupPageReportFilterButtonForm_Query(\n  $slug: String!\n  $pageKind: PageKind\n  $pageIds: [ID!]\n  $funnelIds: [ID!]\n) {\n  sources {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n  site(slug: $slug) {\n    funnels {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n    popups(pageIds: $pageIds, pageKind: $pageKind, funnelIds: $funnelIds) {\n      edges {\n        node {\n          id\n          name\n          page {\n            id\n            kind\n            name\n            sortNumber\n            funnel {\n              id\n              name\n            }\n          }\n        }\n      }\n    }\n    pages(funnelIds: $funnelIds, pageKind: $pageKind) {\n      edges {\n        node {\n          id\n          kind\n          name\n          sortNumber\n          funnel {\n            id\n            name\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b12ff1486731cfb4ee088210ac5200de";

export default node;
