import { Box, HStack, Wrap } from "@chakra-ui/react";
import { FC } from "react";

import { chakraFactory } from "~/src/lib/chakra-ui";
import { nl2br } from "~/src/lib/utils";

export type Props = {
  label: string;
  dense?: boolean;
};

const StyledFlexWrapperBox = chakraFactory(Box, {
  baseStyle: {
    width: "100%",
    display: "flex",
    marginBottom: "8px",
  },
});

const StyledContentWrap = chakraFactory(Wrap, {
  baseStyle: {
    flex: "1",
    margin: "auto 0",
    fontSize: "12px",
    lineHeight: "17px",
    color: "#282828",
  },
});

const StyledLabelText = chakraFactory("span", {
  baseStyle: {
    height: "22px",
    fontSize: "13px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#000000",
  },
});

export const DefinitionTeam: FC<Props> = ({
  children,
  label,
  dense = false,
}) => (
  <StyledFlexWrapperBox>
    <HStack
      spacing="8px"
      w={dense ? 120 : [120, 180, 230]}
      height="40px"
      alignItems="center"
    >
      <StyledLabelText>{nl2br(label)}</StyledLabelText>
    </HStack>
    <StyledContentWrap>{children}</StyledContentWrap>
  </StyledFlexWrapperBox>
);
