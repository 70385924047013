import { DateRangeOutlined } from "@mui/icons-material";
import MUIDatePicker, {
  DatePickerProps as MUIDatePickerProps,
} from "@mui/lab/DatePicker";
import { ClickAwayListener } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";

import { MUIPickerProvider } from "../MUIPickerProvider";

import { DatePickerInput } from "./presentations";

export type Props = {
  value: Date | null;
  error?: string;
  disabled?: boolean;
  placeholder?: string;
  onChange: MUIDatePickerProps<Date>["onChange"];
};

export const DatePicker: React.FC<Props> = ({
  value,
  error,
  disabled = false,
  placeholder,
  onChange,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpen = React.useMemo(() => Boolean(anchorEl), [anchorEl]);

  const handleOpen = React.useCallback<React.MouseEventHandler<HTMLElement>>(
    (event) => {
      if (!isOpen && !disabled) setAnchorEl(event.currentTarget);
    },
    [disabled, isOpen]
  );

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleRender = React.useCallback<
    MUIDatePickerProps<Date>["renderInput"]
  >(
    ({ inputRef, inputProps, InputProps }) => (
      <Box onClick={handleOpen}>
        <DatePickerInput
          inputRef={inputRef}
          inputProps={{
            ...inputProps,
            placeholder,
          }}
          InputProps={{ ...InputProps, disabled }}
          icon={<DateRangeOutlined />}
          error={!!error}
          errorMessage={error}
        />
      </Box>
    ),
    [disabled, error, handleOpen, placeholder]
  );

  return (
    <MUIPickerProvider>
      <ClickAwayListener onClickAway={handleClose}>
        <Box>
          <MUIDatePicker
            value={value}
            open={isOpen}
            onChange={onChange}
            onAccept={handleClose}
            renderInput={handleRender}
            clearable
            mask="____/__/__"
            disabled={disabled}
          />
        </Box>
      </ClickAwayListener>
    </MUIPickerProvider>
  );
};
