import { useToast } from "@chakra-ui/toast";
import { ascend, includes, prop, sortWith } from "ramda";
import { FC, useCallback, useMemo } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useNavigate } from "react-router";

import { FunnelEditFormContainer_Mutation } from "~/src/__relay_artifacts__/FunnelEditFormContainer_Mutation.graphql";
import { FunnelEditFormContainer_PageMutation } from "~/src/__relay_artifacts__/FunnelEditFormContainer_PageMutation.graphql";
import { FunnelEditFormContainer_Query } from "~/src/__relay_artifacts__/FunnelEditFormContainer_Query.graphql";
import { useFormErrorHandler } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";
import { delayChunkPromise } from "~/src/lib/utils";

import {
  formConvertToPageAttributes,
  fromQueryMatchType,
  hasFormConvertToBoolean,
  responseConvertToForm,
  stringConvertToUrlMatchType,
  toQueryMatchType,
  urlMatchConvertToString,
} from "./convertFunctions";
import { FunnelForm, FunnelFormProps } from "./presentations";

export type Props = {
  siteSlug: string;
  funnelSlug: string;
};

const query = graphql`
  query FunnelEditFormContainer_Query($funnelSlug: String!) {
    viewer {
      role
    }
    funnel(slug: $funnelSlug) {
      id
      slug
      name
      isExperiment
      landingPage {
        id
        name
        url
        mobileUrl
        tabletUrl
        hasForm
        urlMatchType
        queryMatchType
        sortNumber
        isDisableWhiteout
      }
      thanksPage {
        id
        name
        url
        mobileUrl
        tabletUrl
        urlMatchType
        sortNumber
        isDisableWhiteout
      }
      confirmPage {
        id
        name
        url
        mobileUrl
        tabletUrl
        urlMatchType
        sortNumber
        isDisableWhiteout
      }
      formPage {
        id
        name
        url
        mobileUrl
        tabletUrl
        urlMatchType
        sortNumber
        isDisableWhiteout
      }
    }
  }
`;

const deleteMutation = graphql`
  mutation FunnelEditFormContainer_PageMutation($input: DeletePageInput!) {
    deletePage(input: $input) {
      deletedPageId
    }
  }
`;

const mutation = graphql`
  mutation FunnelEditFormContainer_Mutation($input: UpdateFunnelInput!) {
    updateFunnel(input: $input) {
      funnel {
        id
        slug
        name
        isExperiment
        landingPage {
          id
          name
          url
          mobileUrl
          tabletUrl
          hasForm
          urlMatchType
          queryMatchType
          isDisableWhiteout
        }
        thanksPage {
          id
          name
          url
          mobileUrl
          tabletUrl
          urlMatchType
          isDisableWhiteout
        }
        confirmPage {
          id
          name
          url
          mobileUrl
          tabletUrl
          urlMatchType
          isDisableWhiteout
        }
        formPage {
          id
          name
          url
          mobileUrl
          tabletUrl
          urlMatchType
          isDisableWhiteout
        }
      }
    }
  }
`;

export const FunnelEditFormContainer: FC<Props> = ({
  siteSlug,
  funnelSlug,
}) => {
  const navigate = useNavigate();
  const toast = useToast();
  const { onFormError } = useFormErrorHandler();
  const { funnel, viewer } = useLazyLoadQuery<FunnelEditFormContainer_Query>(
    query,
    {
      funnelSlug,
    }
  );
  const updateFunnelMutation =
    useMutationCommit<FunnelEditFormContainer_Mutation>(mutation);

  const deletePageMutate =
    useMutationCommit<FunnelEditFormContainer_PageMutation>(deleteMutation);

  const formPages = useMemo(() => {
    if (!funnel.formPage) return [];
    return sortWith([ascend(prop("sortNumber"))], funnel.formPage);
  }, [funnel.formPage]);

  const forms = useMemo(() => {
    return funnel.formPage?.map((form) => form) || [];
  }, [funnel]);

  const initialValues = useMemo(
    () => ({
      title: funnel?.name || "",
      isExperiment: funnel.isExperiment,
      landingUrlForPC: funnel?.landingPage?.url || "",
      landingUrlForMobile: funnel?.landingPage?.mobileUrl,
      landingUrlForTablet: funnel?.landingPage?.tabletUrl,
      landingUrlMatchType: urlMatchConvertToString(
        funnel?.landingPage?.urlMatchType
      ),
      landingUrlFormIncluded: hasFormConvertToBoolean(
        funnel?.landingPage?.hasForm
      ),
      landingQueryMatchType: fromQueryMatchType(
        funnel?.landingPage?.queryMatchType
      ),
      landingIsDisableWhiteout: funnel?.landingPage?.isDisableWhiteout || false,
      formUrls: responseConvertToForm(formPages),
      confirmUrlForPC: funnel?.confirmPage?.url || "",
      confirmUrlForMobile: funnel?.confirmPage?.mobileUrl,
      confirmUrlForTablet: funnel?.confirmPage?.tabletUrl,
      confirmUrlMatchType: urlMatchConvertToString(
        funnel?.confirmPage?.urlMatchType
          ? funnel.confirmPage.urlMatchType
          : "EQUAL_TYPE"
      ),
      confirmIsDisableWhiteout: funnel?.confirmPage?.isDisableWhiteout || false,
      thanksUrlForPC: funnel?.thanksPage?.url || "",
      thanksUrlForMobile: funnel?.thanksPage?.mobileUrl,
      thanksUrlForTablet: funnel?.thanksPage?.tabletUrl,
      thanksUrlMatchType: urlMatchConvertToString(
        funnel?.thanksPage?.urlMatchType
      ),
      thanksIsDisableWhiteout: funnel?.thanksPage?.isDisableWhiteout || false,
    }),
    [funnel, formPages]
  );

  const handleSubmit = useCallback<FunnelFormProps["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        if (!funnel) return;

        const formValues = formConvertToPageAttributes(
          values.formUrls,
          funnel.formPage
        );

        const { updateFunnel } = await updateFunnelMutation({
          variables: {
            input: {
              funnelId: funnel.id,
              name: values.title,
              isExperiment: values.isExperiment,
              landingPageAttributes: {
                id: funnel.landingPage?.id,
                name: funnel.landingPage?.name || "ランディングページ",
                url: values.landingUrlForPC,
                mobileUrl: values.landingUrlForMobile,
                tabletUrl: values.landingUrlForTablet,
                hasForm: values.landingUrlFormIncluded ? "HAS_FORM" : "NO_FORM",
                urlMatchType: stringConvertToUrlMatchType(
                  values.landingUrlMatchType
                ),
                queryMatchType: toQueryMatchType(values.landingQueryMatchType),
                isDisableWhiteout: values.landingIsDisableWhiteout,
              },
              formPageAttributes: formValues,
              confirmPageAttributes: {
                id: funnel.confirmPage?.id,
                name: funnel.confirmPage?.name || "確認ページ",
                url: values.confirmUrlForPC || "",
                mobileUrl: values.confirmUrlForMobile,
                tabletUrl: values.confirmUrlForTablet,
                urlMatchType: stringConvertToUrlMatchType(
                  values.confirmUrlMatchType
                ),
                isDisableWhiteout: values.confirmIsDisableWhiteout,
              },
              thanksPageAttributes: {
                id: funnel.thanksPage?.id,
                name: funnel.thanksPage?.name || "サンクスページ",
                url: values.thanksUrlForPC,
                mobileUrl: values.thanksUrlForMobile,
                tabletUrl: values.thanksUrlForTablet,
                urlMatchType: stringConvertToUrlMatchType(
                  values.thanksUrlMatchType
                ),
                isDisableWhiteout: values.thanksIsDisableWhiteout,
              },
            },
          },
        });
        if (!updateFunnel?.funnel) throw new Error("assertion failed");

        // MEMO: undefined is new page
        const formIds: string[] = [];
        formValues.forEach((formValue) => {
          const id = formValue.id?.toString();
          if (id) {
            formIds.push(id);
          }
        });

        const deleteTargetFormPageIds = forms
          .filter((form) => !includes(form.id, formIds))
          .map((form) => form.id);

        const deleteMutations: Promise<{}>[] = deleteTargetFormPageIds.map(
          (id) => {
            return deletePageMutate({
              variables: {
                input: {
                  pageId: id,
                },
              },
            });
          }
        );
        await delayChunkPromise(deleteMutations);

        toast({ title: "ファネルを更新しました", status: "success" });
        navigate(`/sites/${siteSlug}/settings?tab=funnels`);
      } catch (err) {
        onFormError(err, setErrors);
      }
    },
    [
      funnel,
      updateFunnelMutation,
      forms,
      toast,
      navigate,
      siteSlug,
      deletePageMutate,
      onFormError,
    ]
  );

  const handleCancel = useCallback(() => navigate(-1), [navigate]);

  return (
    <FunnelForm
      initialValues={initialValues}
      onCancelClick={handleCancel}
      onSubmit={handleSubmit}
      userRole={viewer.role}
    />
  );
};
