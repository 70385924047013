import { OpenInNew as OpenInNewIcon } from "@mui/icons-material";
import { FC } from "react";

import { chakraFactory } from "~/src/lib/chakra-ui";

export type Props = {
  name?: string;
  url: string;
};

const StyledAnchor = chakraFactory("a", {
  baseStyle: {
    display: "flex",
    alignItems: "center",
  },
});

const StyledOpenInNewIcon = chakraFactory(OpenInNewIcon, {
  baseStyle: {
    width: "16px",
    height: "16px",
    mr: "14px",
  },
});

export const URLItem: FC<Props> = ({ url, name }) => (
  <StyledAnchor href={url} target="_blank" rel="noopener noreferrer">
    <StyledOpenInNewIcon />
    {!!name ? name : url}
  </StyledAnchor>
);
