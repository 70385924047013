import { Flex } from "@chakra-ui/react";
import { format } from "date-fns";
import { useCallback, useContext, useState } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";

import { SiteReportOrder } from "~/src/__generated__/schema";
import { PublisherDetailReportContainer_Query } from "~/src/__relay_artifacts__/PublisherDetailReportContainer_Query.graphql";

import { DateRangeContext } from "../../global/HeaderNavigation/DateRangeField";
import { ReportFilterButton, ReportFilterValues } from "../ReportFilterButton";

import { PublisherDetailReportGraph } from "./PublisherDetailReportGraph";
import { PublisherDetailReportTable } from "./PublisherDetailReportTable";

const query = graphql`
  query PublisherDetailReportContainer_Query(
    $input: ReportInput!
    $slug: String!
  ) {
    site(slug: $slug) {
      ...PublisherDetailReportGraph_site
      ...PublisherDetailReportTable_site
    }
  }
`;

export const PublisherDetailReportContainer = () => {
  const { siteSlug = "" } = useParams();

  const [sort, setSort] = useState<SiteReportOrder>({
    field: "UNIQUE_USER",
    direction: "DESC",
  });

  const [filterValues, setFilterValues] = useState<ReportFilterValues>({
    sourceId: null,
    device: null,
    funnelIds: [],
    pageKind: null,
  });

  const handleSortClick = useCallback((params) => setSort(params), []);

  const {
    dateRange: [startOn, endOn],
  } = useContext(DateRangeContext);

  const queryRef = useLazyLoadQuery<PublisherDetailReportContainer_Query>(
    query,
    {
      slug: siteSlug,
      input: {
        startDate: format(startOn, "yyyy-MM-dd"),
        endDate: format(endOn, "yyyy-MM-dd"),
        sourceId: filterValues.sourceId,
        device: filterValues.device,
        funnelIds: filterValues.funnelIds,
        pageKind: filterValues.pageKind,
        orderBy: {
          direction: sort.direction,
          field: sort.field,
        },
      },
    }
  );

  return (
    <>
      <Flex justifyContent={"end"} mt={4} mb={4}>
        <ReportFilterButton
          filterValues={filterValues}
          onSubmit={setFilterValues}
          onFilterChange={setFilterValues}
        />
      </Flex>
      <PublisherDetailReportGraph siteRef={queryRef.site} />
      <PublisherDetailReportTable
        siteRef={queryRef.site}
        onSortClick={handleSortClick}
      />
    </>
  );
};
