/**
 * @generated SignedSource<<525e775a8fd6a4a19820b5f55fa60536>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeletePopupGroupSetInput = {
  clientMutationId?: string | null;
  popupGroupSetId: string;
};
export type PopupGroupSetTableActions_DeleteMutation$variables = {
  connections: ReadonlyArray<string>;
  input: DeletePopupGroupSetInput;
};
export type PopupGroupSetTableActions_DeleteMutationVariables = PopupGroupSetTableActions_DeleteMutation$variables;
export type PopupGroupSetTableActions_DeleteMutation$data = {
  readonly deletePopupGroupSet: {
    readonly deletedPopupGroupSetId: string;
  } | null;
};
export type PopupGroupSetTableActions_DeleteMutationResponse = PopupGroupSetTableActions_DeleteMutation$data;
export type PopupGroupSetTableActions_DeleteMutation = {
  variables: PopupGroupSetTableActions_DeleteMutationVariables;
  response: PopupGroupSetTableActions_DeleteMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedPopupGroupSetId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PopupGroupSetTableActions_DeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeletePopupGroupSetPayload",
        "kind": "LinkedField",
        "name": "deletePopupGroupSet",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PopupGroupSetTableActions_DeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeletePopupGroupSetPayload",
        "kind": "LinkedField",
        "name": "deletePopupGroupSet",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "deleteEdge",
            "key": "",
            "kind": "ScalarHandle",
            "name": "deletedPopupGroupSetId",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bd51bd94c83dd0b4ee2bd2cab38a2a8c",
    "id": null,
    "metadata": {},
    "name": "PopupGroupSetTableActions_DeleteMutation",
    "operationKind": "mutation",
    "text": "mutation PopupGroupSetTableActions_DeleteMutation(\n  $input: DeletePopupGroupSetInput!\n) {\n  deletePopupGroupSet(input: $input) {\n    deletedPopupGroupSetId\n  }\n}\n"
  }
};
})();

(node as any).hash = "b8122c48a8d5727ade40d402f042b81e";

export default node;
