/**
 * @generated SignedSource<<46f8431e85e261487fc55253e45df571>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FunnelSourceEditFormContainer_funnel$data = {
  readonly sourceTag: {
    readonly id: string;
    readonly key: string | null;
    readonly value: string | null;
    readonly slug: string;
    readonly source: {
      readonly id: string;
      readonly name: string;
    };
    readonly pageUrl: string;
  } | null;
  readonly " $fragmentType": "FunnelSourceEditFormContainer_funnel";
};
export type FunnelSourceEditFormContainer_funnel = FunnelSourceEditFormContainer_funnel$data;
export type FunnelSourceEditFormContainer_funnel$key = {
  readonly " $data"?: FunnelSourceEditFormContainer_funnel$data;
  readonly " $fragmentSpreads": FragmentRefs<"FunnelSourceEditFormContainer_funnel">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "sourceTagSlug"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "FunnelSourceEditFormContainer_funnel",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "slug",
          "variableName": "sourceTagSlug"
        }
      ],
      "concreteType": "SourceTag",
      "kind": "LinkedField",
      "name": "sourceTag",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Source",
          "kind": "LinkedField",
          "name": "source",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pageUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Funnel",
  "abstractKey": null
};
})();

(node as any).hash = "5cea252494df4d61d4fe676ce1032ce8";

export default node;
