import { Box, Code, HStack, Text, VStack } from "@chakra-ui/react";
import {
  AddCircle as AddCircleIcon,
  DeleteOutline as DeleteOutlineIcon,
} from "@mui/icons-material";
import { FieldArray, useField } from "formik";
import { FC, useCallback } from "react";

import { SymbolOutlineButton } from "~/src/components/common/Button";
import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormikSelectField } from "~/src/components/common/forms/FormikSelectField";
import { FormikTextField } from "~/src/components/common/forms/FormikTextField";
import { IconButton } from "~/src/components/common/IconButton";
import { Switch } from "~/src/components/common/Switch";
import { useOpenState } from "~/src/lib/hooks";

export type Props = {
  name: string;
  label: string;
};

export const ReferrerListField: FC<Props> = ({ name, label }) => {
  const [referrers, , { setValue }] =
    useField<{ referrer: string; matchOp: string }[]>(name);

  const { isOpen, onOpen, onClose } = useOpenState(
    referrers.value ? referrers.value.length > 0 : false
  );

  const handleChange = useCallback(() => {
    if (isOpen) {
      onClose();
      setValue([]);
    } else {
      onOpen();
    }
  }, [isOpen, onClose, onOpen, setValue]);

  return (
    <FieldArray
      name={name}
      // eslint-disable-next-line react/jsx-no-bind
      render={(arrayHelpers) => (
        <FormControl label={label}>
          <Box width="100%" display="block">
            <Switch isChecked={isOpen} onChange={handleChange} />
          </Box>
          <VStack width="100%">
            {isOpen && (
              <>
                {referrers.value &&
                  referrers.value.length > 0 &&
                  referrers.value.map((id, index) => (
                    <HStack key={index} width="100%">
                      <Box flex={1}>
                        <FormikTextField
                          type="text"
                          mode="fill"
                          name={`${name}.${index}.referrer`}
                          placeholder="google.co.jp"
                        />
                      </Box>

                      <Box width={120}>
                        <FormikSelectField
                          mode="fill"
                          name={`${name}.${index}.matchOp`}
                          options={[
                            { label: "完全一致", value: "EQUAL" },
                            { label: "含む", value: "CONTAINS" },
                          ]}
                        />
                      </Box>
                      <Box>
                        <IconButton
                          size="24px"
                          // eslint-disable-next-line react/jsx-no-bind
                          onClick={() => arrayHelpers.remove(index)}
                          icon={<DeleteOutlineIcon />}
                        />
                      </Box>
                    </HStack>
                  ))}
                <Text fontSize="xs" alignSelf="flex-start">
                  ※ノーリファラーを対象にしたい場合は
                  <Code> no referrer </Code>と記載してください
                </Text>
                <Box width="100%">
                  <SymbolOutlineButton
                    icon={<AddCircleIcon />}
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={() => arrayHelpers.push({})}
                  >
                    条件を追加
                  </SymbolOutlineButton>
                </Box>
              </>
            )}
          </VStack>
        </FormControl>
      )}
    />
  );
};
