import { Box } from "@chakra-ui/layout";
import { FC, useCallback, useState } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useNavigate, useParams } from "react-router-dom";

import { PopupGroupCreatePageContainer_Mutation } from "~/src/__relay_artifacts__/PopupGroupCreatePageContainer_Mutation.graphql";
import { PopupGroupCreatePageContainer_Query } from "~/src/__relay_artifacts__/PopupGroupCreatePageContainer_Query.graphql";
import { WizardStep } from "~/src/components/common/WizardStep";
import { PageLayout } from "~/src/components/features/global/PageLayout";
import {
  PopupGroupForm,
  PopupGroupFormProps,
} from "~/src/components/features/popup";
import { useFormErrorHandler, useQueryString } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";

export type Props = {};

type Params = {
  funnel: string | null;
};

const query = graphql`
  query PopupGroupCreatePageContainer_Query($siteSlug: String!) {
    site(slug: $siteSlug) {
      id
      name
      slug
      ...PopupGroupForm_site
    }
  }
`;

const mutation = graphql`
  mutation PopupGroupCreatePageContainer_Mutation(
    $input: CreatePopupGroupInput!
  ) {
    createPopupGroup(input: $input) {
      popupGroup {
        id
        name
        slug
        page {
          id
          funnel {
            id
          }
        }
        platforms
        browsers
        devices
        genders
        ageRanges
        deliverKind
        repeat
        startDate
        startTime
        endDate
        endTime
        sources {
          id
          name
        }
        excludeSources {
          id
          name
        }
        referrers {
          referrer
          matchOp
        }
        excludeReferrers {
          referrer
          matchOp
        }
        visitCount
        visitMatchType
        deliverTargetUrls {
          url
        }
        deliverExcludeUrls {
          url
        }
        deliverTargetLabels {
          id
          name
        }
        deliverExcludeLabels {
          id
          name
        }
        targetCookies {
          key
          value
          matchType
        }
        deliverDayOfWeeks {
          weekOfMonth
          daysOfWeek
        }
        displayTargetContents {
          targetKind
          targetValue
        }
        disableTargetElements {
          targetKind
          targetValue
        }
      }
    }
  }
`;

export const PopupGroupCreatePageContainer: FC<Props> = () => {
  const { siteSlug = "" } = useParams();
  const { params } = useQueryString<Params>();

  const data = useLazyLoadQuery<PopupGroupCreatePageContainer_Query>(query, {
    siteSlug,
  });
  const { site } = data;

  if (!site) throw new Error("assertion failed");

  const navigate = useNavigate();
  const handleCancel = useCallback(() => navigate(-1), [navigate]);

  const [repeat, setRepeat] = useState(false);

  const handleRepeatChange = useCallback(() => {
    setRepeat(!repeat);
  }, [repeat]);

  const mutate =
    useMutationCommit<PopupGroupCreatePageContainer_Mutation>(mutation);

  const { onFormError } = useFormErrorHandler();

  const handleSubmit = useCallback<PopupGroupFormProps["onSubmit"]>(
    async ({ funnelId, ...values }, { setErrors }) => {
      try {
        const res = await mutate({
          variables: {
            input: {
              ...values,
              isMulti: values.isMulti === "true",
              startDate: values.startDate?.toISOString() || null,
              startTime: values.startTime?.toISOString() || null,
              endDate: values.endDate?.toISOString() || null,
              endTime: values.endTime?.toISOString() || null,
              deliverDayOfWeeks: values.deliverDayOfWeeks,
            },
          },
        });

        const createdPopupGroup = res.createPopupGroup?.popupGroup;

        if (!createdPopupGroup) {
          throw new Error("assertion failed");
        }

        navigate(
          `/sites/${site.slug}/popup_groups/${createdPopupGroup?.slug}/patterns/new`
        );
      } catch (err) {
        onFormError(err, setErrors);
      }
      return Promise.resolve();
    },
    [mutate, navigate, onFormError, site.slug]
  );

  return (
    <PageLayout
      title="ポップアップ登録"
      breadcrumbs={[
        { label: "ポップアップ一覧", path: `/sites/${siteSlug}/popup_groups` },
        {
          label: "ポップアップ登録",
          path: `/sites/${siteSlug}/popup_groups/new`,
        },
      ]}
    >
      <Box mt="16px" p="16px">
        <WizardStep
          steps={[
            { label: "基本設定", active: true },
            { label: "パターン設定", active: false },
          ]}
        />
      </Box>
      <Box my="16px" p="16px">
        <PopupGroupForm
          siteRef={site}
          repeat={repeat}
          onRepeatChange={handleRepeatChange}
          onCancelClick={handleCancel}
          onSubmit={handleSubmit}
          isFunnelDisabled={params.funnel ? true : false}
          funnelId={params.funnel}
        />
      </Box>
    </PageLayout>
  );
};
