import { Box, Flex, HStack, Spacer } from "@chakra-ui/react";
import { FC, useMemo, useState } from "react";
import { graphql, usePaginationFragment } from "react-relay";
import { NavLink } from "react-router-dom";

import { ChatbotListPage_site$key } from "~/src/__relay_artifacts__/ChatbotListPage_site.graphql";
import { SolidButton } from "~/src/components/common/Button";
import {
  Table,
  TableHeaderColumn,
  TableHeaderRow,
} from "~/src/components/common/tables/Table";
import { useTableItemSelect } from "~/src/components/common/tables/TableItemSelect";
import {
  TablePagination,
  useTablePaginationProps,
} from "~/src/components/common/tables/TablePagination";
import { TableSearchField } from "~/src/components/common/tables/TableSearchField";
import { PageLayout } from "~/src/components/features/global/PageLayout";
import { TableItemSelectButton } from "~/src/components/features/global/TableItemSelectButton";
import { useUpdateDebounce } from "~/src/lib/react-use";

import { ChatbotFilterButton, FormValues } from "./ChatbotFilterButton";
import { ChatbotTableDataRow } from "./ChatbotTableDataRow";
import { allTableItems, initialSelectedTableItems } from "./constants";

export type Props = {
  siteRef: ChatbotListPage_site$key;
};

const siteFragment = graphql`
  fragment ChatbotListPage_site on Site
  @refetchable(queryName: "ChatbotListPage_Query")
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 120 }
    cursor: { type: "String" }
    searchTerm: { type: "String" }
    pageIds: { type: "[ID!]" }
    funnelIds: { type: "[ID!]" }
    sourceId: { type: "ID" }
    device: { type: "Device" }
    withArchived: { type: "Boolean" }
    status: { type: "DistributionStatus" }
    pageKind: { type: "PageKind" }
  ) {
    slug
    chatbotGroups(
      first: $count
      after: $cursor
      searchTerm: $searchTerm
      pageIds: $pageIds
      funnelIds: $funnelIds
      sourceId: $sourceId
      device: $device
      withArchived: $withArchived
      orderBy: { field: END_ON, direction: DESC }
      status: $status
      pageKind: $pageKind
    ) @connection(key: "ChatbotListPage_chatbotGroups") {
      __id
      totalCount
      edges {
        node {
          id
          slug
          ...ChatbotTableDataRow_chatbotGroup
        }
      }
    }
  }
`;

export const ChatbotListPage: FC<Props> = ({ siteRef }) => {
  const [searchText, setSearchText] = useState("");
  const [filterValues, setFilterValues] = useState<FormValues>({
    pageIds: [],
    funnelIds: [],
    sourceId: null,
    device: null,
    withArchived: null,
    status: null,
    pageKind: null,
  });
  const {
    data: site,
    loadNext,
    hasNext,
    refetch,
  } = usePaginationFragment(siteFragment, siteRef);
  const { tablePaginationProps } = useTablePaginationProps({
    totalCount: site.chatbotGroups.totalCount,
    hasNext,
    loadNext,
    refetch,
  });
  const { selectedTableItems, headerColumns, dataColumns, onApplyClick } =
    useTableItemSelect({
      cacheKey: "ChatbotListPage",
      initialSelectedTableItems,
    });

  const chatbots = useMemo(() => {
    const from = tablePaginationProps.from;
    const to = tablePaginationProps.to;
    const edges = site.chatbotGroups.edges || [];
    return edges.slice(from, to).map((edge) => {
      const node = edge?.node;
      if (!node) throw new Error("assertion failed");
      return node;
    });
  }, [
    site.chatbotGroups.edges,
    tablePaginationProps.from,
    tablePaginationProps.to,
  ]);

  useUpdateDebounce(
    () =>
      refetch({
        searchTerm: searchText,
        count: tablePaginationProps.perPage,
        ...filterValues,
      }),
    500,
    [filterValues, refetch, searchText, tablePaginationProps.perPage]
  );

  return (
    <PageLayout title="チャットボット">
      <Box my="16px">
        <HStack justifyContent="flex-end" mb="16px" spacing="16px">
          <NavLink to={`/sites/${site.slug}/chatbots/new`}>
            <SolidButton>登録</SolidButton>
          </NavLink>
        </HStack>
        <Flex alignItems="flex-end">
          <TableSearchField searchText={searchText} onChange={setSearchText} />
          <Spacer />
          <HStack spacing="12px">
            <ChatbotFilterButton
              siteSlug={site.slug}
              filterValues={filterValues}
              onFilterChange={setFilterValues}
              onSubmit={setFilterValues}
            />
            <TableItemSelectButton
              allTableItems={allTableItems}
              selectedTableItems={selectedTableItems}
              onApplyClick={onApplyClick}
            />
          </HStack>
        </Flex>
        <Table>
          <TableHeaderRow>
            <TableHeaderColumn>チャットボット名</TableHeaderColumn>
            <TableHeaderColumn>実施ステータス</TableHeaderColumn>
            <TableHeaderColumn>画像</TableHeaderColumn>
            {headerColumns.map((headerColumn) => (
              <TableHeaderColumn key={headerColumn}>
                {headerColumn}
              </TableHeaderColumn>
            ))}
            <TableHeaderColumn>アクション</TableHeaderColumn>
          </TableHeaderRow>
          {chatbots.map((chatbot) => (
            <ChatbotTableDataRow
              key={chatbot.slug}
              chatbotRef={chatbot}
              siteSlug={site.slug}
              dataColumns={dataColumns}
              connectionId={site.chatbotGroups.__id}
            />
          ))}
        </Table>
        <TablePagination {...tablePaginationProps} />
      </Box>
    </PageLayout>
  );
};
