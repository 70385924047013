/**
 * @generated SignedSource<<4848e2c0bce21b1b8d7c7faa22e75103>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PageKind = "LANDING" | "FORM" | "CONFIRM" | "THANKS" | "%future added value";
export type PopupDefaultReportFilterButtonForm_Query$variables = {
  slug: string;
  pageKind?: PageKind | null;
  funnelIds?: ReadonlyArray<string> | null;
};
export type PopupDefaultReportFilterButtonForm_QueryVariables = PopupDefaultReportFilterButtonForm_Query$variables;
export type PopupDefaultReportFilterButtonForm_Query$data = {
  readonly sources: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
      } | null;
    } | null> | null;
  };
  readonly site: {
    readonly funnels: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string;
        } | null;
      } | null> | null;
    };
    readonly popups: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string | null;
          readonly page: {
            readonly kind: PageKind;
            readonly name: string;
            readonly sortNumber: number;
            readonly funnel: {
              readonly id: string;
              readonly name: string;
            };
          };
        } | null;
      } | null> | null;
    };
  };
};
export type PopupDefaultReportFilterButtonForm_QueryResponse = PopupDefaultReportFilterButtonForm_Query$data;
export type PopupDefaultReportFilterButtonForm_Query = {
  variables: PopupDefaultReportFilterButtonForm_QueryVariables;
  response: PopupDefaultReportFilterButtonForm_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "funnelIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageKind"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "slug"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  (v3/*: any*/),
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "SourceConnection",
  "kind": "LinkedField",
  "name": "sources",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SourceEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Source",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v5/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "FunnelConnection",
  "kind": "LinkedField",
  "name": "funnels",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FunnelEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Funnel",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v5/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = [
  {
    "kind": "Variable",
    "name": "funnelIds",
    "variableName": "funnelIds"
  },
  {
    "kind": "Variable",
    "name": "pageKind",
    "variableName": "pageKind"
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sortNumber",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Funnel",
  "kind": "LinkedField",
  "name": "funnel",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PopupDefaultReportFilterButtonForm_Query",
    "selections": [
      (v6/*: any*/),
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          {
            "alias": null,
            "args": (v9/*: any*/),
            "concreteType": "PopupConnection",
            "kind": "LinkedField",
            "name": "popups",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PopupEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Popup",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Page",
                        "kind": "LinkedField",
                        "name": "page",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          (v4/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PopupDefaultReportFilterButtonForm_Query",
    "selections": [
      (v6/*: any*/),
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          {
            "alias": null,
            "args": (v9/*: any*/),
            "concreteType": "PopupConnection",
            "kind": "LinkedField",
            "name": "popups",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PopupEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Popup",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Page",
                        "kind": "LinkedField",
                        "name": "page",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          (v4/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b6f517c34346ed7b9e6079c51d53b375",
    "id": null,
    "metadata": {},
    "name": "PopupDefaultReportFilterButtonForm_Query",
    "operationKind": "query",
    "text": "query PopupDefaultReportFilterButtonForm_Query(\n  $slug: String!\n  $pageKind: PageKind\n  $funnelIds: [ID!]\n) {\n  sources {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n  site(slug: $slug) {\n    funnels {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n    popups(pageKind: $pageKind, funnelIds: $funnelIds) {\n      edges {\n        node {\n          id\n          name\n          page {\n            kind\n            name\n            sortNumber\n            funnel {\n              id\n              name\n            }\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "9ca0ce806dda7c5207c57914c6276189";

export default node;
