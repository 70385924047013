import { boolean, object, string } from "yup";

export type FormValues = {
  company: string;
  tel: string;
  name: string;
  email: string;
  isHoneycomb: boolean;
  itpUrls: string;
  isSecure: boolean;
  isMacbeeServer: boolean;
  userTag?: string;
  isCrossDomainQuery: boolean;
  isCrossDomainFragment: boolean;
  reAuthPassword: string;
};

export const validationSchema = object({
  company: string().required(),
  tel: string(),
  name: string().required(),
  email: string().email(),
  isHoneycomb: boolean().required(),
  itpUrls: string().url(),
  isSecure: boolean().required(),
  isMacbeeServer: boolean().required(),
  userTag: string().notRequired(),
  isCrossDomainQuery: boolean().required(),
  isCrossDomainFragment: boolean().required(),
});
