/**
 * @generated SignedSource<<2cb7ebb82a59833c558e0398d7a4df04>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Device = "MOBILE" | "TABLET" | "PC" | "%future added value";
export type OrderDirection = "ASC" | "DESC" | "%future added value";
export type PageKind = "LANDING" | "FORM" | "CONFIRM" | "THANKS" | "%future added value";
export type SiteReportOrderField = "UNIQUE_USER" | "CONVERSION" | "CVR" | "LP_DROP_RATE" | "LP_SCROLL_RATE" | "LP_AVERAGE_STAY_TIME" | "FORM_DROP_RATE" | "MOBILE_RATE" | "TABLET_RATE" | "PC_RATE" | "DEVICE" | "PAGE_VIEW_USER" | "USE_CHATBOT_USER" | "USE_CHATBOT_USER_RATE" | "CHATBOT_CVR" | "POPUP_READY_USER" | "CLOSE_POPUP" | "CLICK_POPUP" | "POPUP_CLICK_CONVERSION" | "POPUP_IMPRESSION_CONVERSION" | "POPUP_READY_CONVERSION" | "POPUP_DROP" | "POPUP_DROP_CONVERSION" | "POPUP_CONVERSION" | "POPUP_CTR" | "POPUP_CVR" | "POPUP_CLICK_CVR" | "POPUP_IMPRESSION_CVR" | "POPUP_DROP_CVR" | "%future added value";
export type ReportInput = {
  startDate?: string | null;
  endDate?: string | null;
  orderBy?: SiteReportOrder | null;
  device?: Device | null;
  sourceId?: string | null;
  funnelIds?: ReadonlyArray<string> | null;
  pageKind?: PageKind | null;
};
export type SiteReportOrder = {
  field: SiteReportOrderField;
  direction: OrderDirection;
};
export type ChatbotReportContainer_Query$variables = {
  input: ReportInput;
  slug: string;
};
export type ChatbotReportContainer_QueryVariables = ChatbotReportContainer_Query$variables;
export type ChatbotReportContainer_Query$data = {
  readonly site: {
    readonly " $fragmentSpreads": FragmentRefs<"ChatbotReportGraph_site" | "ChatbotReportTable_site">;
  };
};
export type ChatbotReportContainer_QueryResponse = ChatbotReportContainer_Query$data;
export type ChatbotReportContainer_Query = {
  variables: ChatbotReportContainer_QueryVariables;
  response: ChatbotReportContainer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChatbotReportContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ChatbotReportGraph_site"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ChatbotReportTable_site"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChatbotReportContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "input",
                "variableName": "input"
              }
            ],
            "kind": "ScalarField",
            "name": "reportChatbot",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "09ff8177e55d0b49d6666fd408bafbe8",
    "id": null,
    "metadata": {},
    "name": "ChatbotReportContainer_Query",
    "operationKind": "query",
    "text": "query ChatbotReportContainer_Query(\n  $input: ReportInput!\n  $slug: String!\n) {\n  site(slug: $slug) {\n    ...ChatbotReportGraph_site\n    ...ChatbotReportTable_site\n    id\n  }\n}\n\nfragment ChatbotReportGraph_site on Site {\n  reportChatbot(input: $input)\n}\n\nfragment ChatbotReportTable_site on Site {\n  reportChatbot(input: $input)\n}\n"
  }
};
})();

(node as any).hash = "31abebd0495272a3706f382d2e1aee67";

export default node;
