import { Text, VStack } from "@chakra-ui/react";
import { format } from "date-fns";
import * as React from "react";
import { graphql, useFragment } from "react-relay";

import { GraphQLEnums } from "~/src/__generated__/GraphQLEnums";
import { ABTestDeliverDayOfWeeks_abtestScenarioPageGroup$key } from "~/src/__relay_artifacts__/ABTestDeliverDayOfWeeks_abtestScenarioPageGroup.graphql";
import { DefinitionTeam } from "~/src/components/common/DefinitionTeam";

type Props = {
  abtestScenarioPageGroupRef: ABTestDeliverDayOfWeeks_abtestScenarioPageGroup$key;
};

const fragment = graphql`
  fragment ABTestDeliverDayOfWeeks_abtestScenarioPageGroup on AbtestScenarioPageGroup {
    startDate
    startTime
    endDate
    endTime
    deliverDayOfWeeks {
      weekOfMonth
      daysOfWeek
    }
  }
`;

const timeFromISOString = (dateStr: string | null) => {
  if (!dateStr) return undefined;

  const date = new Date(dateStr);
  if (!date) return undefined;

  return format(date, "HH:mm");
};

export const ABTestDeliverDayOfWeeks: React.VFC<Props> = ({
  abtestScenarioPageGroupRef,
}) => {
  const abtestGroup = useFragment(fragment, abtestScenarioPageGroupRef);

  const dateDuration = React.useMemo(() => {
    if (abtestGroup.startDate == null && abtestGroup.endDate == null) return "";

    return `${abtestGroup.startDate || ""} ~ ${abtestGroup.endDate || ""}`;
  }, [abtestGroup.endDate, abtestGroup.startDate]);

  const timeDuration = React.useMemo(() => {
    if (abtestGroup.startTime == null && abtestGroup.endTime == null) return "";

    return `${timeFromISOString(abtestGroup.startTime) || ""} ~ ${
      timeFromISOString(abtestGroup.endTime) || ""
    }`;
  }, [abtestGroup.endTime, abtestGroup.startTime]);

  const deliverDayOfWeeks = React.useMemo(
    () =>
      abtestGroup.deliverDayOfWeeks.map((deliverDayOfWeek) => {
        const weekOfMonth = deliverDayOfWeek.weekOfMonth
          ? `第${deliverDayOfWeek.weekOfMonth}週`
          : "毎週";
        const daysOfWeek = deliverDayOfWeek.daysOfWeek.map(
          (dayOfWeek) =>
            GraphQLEnums.DayOfWeek.find(({ value }) => value === dayOfWeek)
              ?.label
        );
        return `${weekOfMonth} ${daysOfWeek.join("・")}`;
      }),
    [abtestGroup.deliverDayOfWeeks]
  );

  return (
    <>
      <DefinitionTeam label="実施期間" dense>
        {dateDuration}
      </DefinitionTeam>
      <DefinitionTeam label="実施時間" dense>
        {timeDuration}
      </DefinitionTeam>
      <DefinitionTeam label="実施曜日" dense>
        <VStack alignItems={"flex-start"}>
          {deliverDayOfWeeks.map((deliverDayOfWeek, index) => (
            <Text key={index}>{deliverDayOfWeek}</Text>
          ))}
        </VStack>
      </DefinitionTeam>
    </>
  );
};
