/**
 * @generated SignedSource<<b74a7a1b11a45aef58209fb47423c393>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AgeRange = "LT12" | "GT12LT19" | "GT20LT24" | "GT25LT29" | "GT30LT34" | "GT35LT39" | "GT40LT44" | "GT45LT49" | "GT50LT54" | "GT55LT59" | "%future added value";
export type AnnualIncome = "LT200" | "GT200LT400" | "GT400LT600" | "GT600LT800" | "GT800LT1000" | "GT1000LT1200" | "GT1200LT1500" | "GT1500LT2000" | "GT2000" | "%future added value";
export type Browser = "IE" | "CHROME" | "SAFARI" | "FIREFOX" | "EDGE" | "OTHER" | "%future added value";
export type DayOfWeek = "SUN" | "MON" | "TUE" | "WED" | "THU" | "FRI" | "SUT" | "%future added value";
export type DeliverKind = "EQUAL" | "WEIGHT" | "%future added value";
export type Device = "MOBILE" | "TABLET" | "PC" | "%future added value";
export type DistributionStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type Gender = "MALE" | "FEMALE" | "%future added value";
export type GroupContentJudgeStatus = "PENDING" | "APPROVED" | "%future added value";
export type MatchOp = "EQUAL" | "CONTAINS" | "%future added value";
export type Platform = "IOS" | "WINDOWS" | "ANDROID" | "MAC" | "LINUX" | "OTHER" | "%future added value";
export type VisitMatch = "EQUAL_MATCH_TYPE" | "OVER_MATCH_TYPE" | "UNDER_MATCH_TYPE" | "%future added value";
export type CreateAbtestScenarioPageGroupInput = {
  clientMutationId?: string | null;
  ageRanges?: ReadonlyArray<AgeRange> | null;
  annualIncomes?: ReadonlyArray<AnnualIncome> | null;
  browsers?: ReadonlyArray<Browser> | null;
  deliverDayOfWeeks?: ReadonlyArray<DeliverDayOfWeekInput> | null;
  deliverKind?: DeliverKind | null;
  deliverTargetLabelIds: ReadonlyArray<string>;
  deliverExcludeLabelIds: ReadonlyArray<string>;
  deliverTargetUrls: ReadonlyArray<AbtestScenarioPageGroupDeliverUrlInput>;
  deliverExcludeUrls: ReadonlyArray<AbtestScenarioPageGroupDeliverUrlInput>;
  devices?: ReadonlyArray<Device> | null;
  endDate?: string | null;
  endTime?: string | null;
  excludeReferrers: ReadonlyArray<AbtestScenarioPageGroupExcludeReferrerInput>;
  genders?: ReadonlyArray<Gender> | null;
  judgeStatus?: GroupContentJudgeStatus | null;
  memo?: string | null;
  pageId?: string | null;
  platforms?: ReadonlyArray<Platform> | null;
  referrers: ReadonlyArray<AbtestScenarioPageGroupReferrerInput>;
  sourceIds: ReadonlyArray<string>;
  startDate?: string | null;
  startTime?: string | null;
  status?: DistributionStatus | null;
  targetCookies: ReadonlyArray<AbtestScenarioPageGroupTargetCookieInput>;
  title?: string | null;
  visitCount?: number | null;
  visitMatchType: VisitMatch;
  excludeSourceIds?: ReadonlyArray<string> | null;
};
export type DeliverDayOfWeekInput = {
  weekOfMonth?: number | null;
  daysOfWeek: ReadonlyArray<DayOfWeek>;
};
export type AbtestScenarioPageGroupDeliverUrlInput = {
  url: string;
};
export type AbtestScenarioPageGroupExcludeReferrerInput = {
  referrer: string;
  matchOp: MatchOp;
};
export type AbtestScenarioPageGroupReferrerInput = {
  referrer: string;
  matchOp: MatchOp;
};
export type AbtestScenarioPageGroupTargetCookieInput = {
  key: string;
  value: string;
  matchType: boolean;
};
export type ABTestCreatePageContainer_Mutation$variables = {
  input: CreateAbtestScenarioPageGroupInput;
  connections: ReadonlyArray<string>;
};
export type ABTestCreatePageContainer_MutationVariables = ABTestCreatePageContainer_Mutation$variables;
export type ABTestCreatePageContainer_Mutation$data = {
  readonly createAbtestScenarioPageGroup: {
    readonly abtestScenarioPageGroupEdge: {
      readonly node: {
        readonly id: string;
        readonly title: string;
        readonly slug: string;
        readonly page: {
          readonly id: string;
          readonly funnel: {
            readonly id: string;
          };
        };
        readonly platforms: ReadonlyArray<Platform>;
        readonly browsers: ReadonlyArray<Browser>;
        readonly devices: ReadonlyArray<Device>;
        readonly genders: ReadonlyArray<Gender>;
        readonly ageRanges: ReadonlyArray<AgeRange>;
        readonly deliverKind: DeliverKind | null;
        readonly repeat: boolean;
        readonly startDate: string | null;
        readonly startTime: string | null;
        readonly endDate: string | null;
        readonly endTime: string | null;
        readonly sources: ReadonlyArray<{
          readonly id: string;
          readonly name: string;
        }>;
        readonly visitCount: number | null;
        readonly visitMatchType: VisitMatch;
        readonly deliverTargetUrls: ReadonlyArray<{
          readonly url: string;
        }>;
        readonly deliverExcludeUrls: ReadonlyArray<{
          readonly url: string;
        }>;
        readonly deliverTargetLabels: ReadonlyArray<{
          readonly id: string;
          readonly name: string;
        }>;
        readonly deliverExcludeLabels: ReadonlyArray<{
          readonly id: string;
          readonly name: string;
        }>;
        readonly targetCookies: ReadonlyArray<{
          readonly key: string;
          readonly value: string;
          readonly matchType: boolean;
        }>;
        readonly deliverDayOfWeeks: ReadonlyArray<{
          readonly weekOfMonth: number | null;
          readonly daysOfWeek: ReadonlyArray<DayOfWeek>;
        }>;
      } | null;
    } | null;
  } | null;
};
export type ABTestCreatePageContainer_MutationResponse = ABTestCreatePageContainer_Mutation$data;
export type ABTestCreatePageContainer_Mutation = {
  variables: ABTestCreatePageContainer_MutationVariables;
  response: ABTestCreatePageContainer_Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Page",
  "kind": "LinkedField",
  "name": "page",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Funnel",
      "kind": "LinkedField",
      "name": "funnel",
      "plural": false,
      "selections": [
        (v3/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "platforms",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "browsers",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "devices",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "genders",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ageRanges",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deliverKind",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "repeat",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startTime",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endTime",
  "storageKey": null
},
v18 = [
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "Source",
  "kind": "LinkedField",
  "name": "sources",
  "plural": true,
  "selections": (v18/*: any*/),
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "visitCount",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "visitMatchType",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v23 = [
  (v22/*: any*/)
],
v24 = {
  "alias": null,
  "args": null,
  "concreteType": "UserLabel",
  "kind": "LinkedField",
  "name": "deliverTargetLabels",
  "plural": true,
  "selections": (v18/*: any*/),
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "concreteType": "UserLabel",
  "kind": "LinkedField",
  "name": "deliverExcludeLabels",
  "plural": true,
  "selections": (v18/*: any*/),
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "matchType",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "concreteType": "DeliverDayOfWeek",
  "kind": "LinkedField",
  "name": "deliverDayOfWeeks",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weekOfMonth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "daysOfWeek",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v30 = [
  (v22/*: any*/),
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ABTestCreatePageContainer_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CreateAbtestScenarioPageGroupPayload",
        "kind": "LinkedField",
        "name": "createAbtestScenarioPageGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AbtestScenarioPageGroupEdge",
            "kind": "LinkedField",
            "name": "abtestScenarioPageGroupEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AbtestScenarioPageGroup",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AbtestScenarioPageGroupDeliverTargetUrl",
                    "kind": "LinkedField",
                    "name": "deliverTargetUrls",
                    "plural": true,
                    "selections": (v23/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AbtestScenarioPageGroupDeliverExcludeUrl",
                    "kind": "LinkedField",
                    "name": "deliverExcludeUrls",
                    "plural": true,
                    "selections": (v23/*: any*/),
                    "storageKey": null
                  },
                  (v24/*: any*/),
                  (v25/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AbtestScenarioPageGroupTargetCookie",
                    "kind": "LinkedField",
                    "name": "targetCookies",
                    "plural": true,
                    "selections": [
                      (v26/*: any*/),
                      (v27/*: any*/),
                      (v28/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v29/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ABTestCreatePageContainer_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CreateAbtestScenarioPageGroupPayload",
        "kind": "LinkedField",
        "name": "createAbtestScenarioPageGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AbtestScenarioPageGroupEdge",
            "kind": "LinkedField",
            "name": "abtestScenarioPageGroupEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AbtestScenarioPageGroup",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AbtestScenarioPageGroupDeliverTargetUrl",
                    "kind": "LinkedField",
                    "name": "deliverTargetUrls",
                    "plural": true,
                    "selections": (v30/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AbtestScenarioPageGroupDeliverExcludeUrl",
                    "kind": "LinkedField",
                    "name": "deliverExcludeUrls",
                    "plural": true,
                    "selections": (v30/*: any*/),
                    "storageKey": null
                  },
                  (v24/*: any*/),
                  (v25/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AbtestScenarioPageGroupTargetCookie",
                    "kind": "LinkedField",
                    "name": "targetCookies",
                    "plural": true,
                    "selections": [
                      (v26/*: any*/),
                      (v27/*: any*/),
                      (v28/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v29/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendEdge",
            "key": "",
            "kind": "LinkedHandle",
            "name": "abtestScenarioPageGroupEdge",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "05a83e8eeb3ad6ba4e684f781e912418",
    "id": null,
    "metadata": {},
    "name": "ABTestCreatePageContainer_Mutation",
    "operationKind": "mutation",
    "text": "mutation ABTestCreatePageContainer_Mutation(\n  $input: CreateAbtestScenarioPageGroupInput!\n) {\n  createAbtestScenarioPageGroup(input: $input) {\n    abtestScenarioPageGroupEdge {\n      node {\n        id\n        title\n        slug\n        page {\n          id\n          funnel {\n            id\n          }\n        }\n        platforms\n        browsers\n        devices\n        genders\n        ageRanges\n        deliverKind\n        repeat\n        startDate\n        startTime\n        endDate\n        endTime\n        sources {\n          id\n          name\n        }\n        visitCount\n        visitMatchType\n        deliverTargetUrls {\n          url\n          id\n        }\n        deliverExcludeUrls {\n          url\n          id\n        }\n        deliverTargetLabels {\n          id\n          name\n        }\n        deliverExcludeLabels {\n          id\n          name\n        }\n        targetCookies {\n          key\n          value\n          matchType\n          id\n        }\n        deliverDayOfWeeks {\n          weekOfMonth\n          daysOfWeek\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bfb5bd5c419f4614e65a653f60d80b6b";

export default node;
