import { Box, HStack, Wrap } from "@chakra-ui/react";
import { FC } from "react";

import { Textarea } from "~/src/components/common/Textarea";
import { chakraFactory } from "~/src/lib/chakra-ui";

export type Props = {
  htmlContent: string;
};

const StyledFlexWrapperBox = chakraFactory(Box, {
  baseStyle: {
    width: "100%",
    display: "flex",
    marginBottom: "8px",
  },
});

const StyledContentWrap = chakraFactory(Wrap, {
  baseStyle: {
    flex: "1",
    margin: "auto 0",
    fontSize: "12px",
    lineHeight: "17px",
    color: "#282828",
  },
});

const StyledTextarea = chakraFactory(Textarea, {
  baseStyle: {
    width: "100%",
    paddingTop: "15px",
  },
});

const StyledLabelText = chakraFactory("span", {
  baseStyle: {
    height: "22px",
    fontSize: "13px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#000000",
  },
});

export const PopupPatternDetailHtmlContentContainer: FC<Props> = ({
  htmlContent,
}) => (
  <StyledFlexWrapperBox>
    <HStack spacing="8px" w={[120, 180, 230]} height="40px" alignItems="center">
      <StyledLabelText>HTML</StyledLabelText>
    </HStack>
    <StyledContentWrap>
      <StyledTextarea value={htmlContent} mode="fill" name="htmlContent" />
    </StyledContentWrap>
  </StyledFlexWrapperBox>
);
