/**
 * @generated SignedSource<<d942956ff1fa4c02ac980893e9c43eb8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Device = "MOBILE" | "TABLET" | "PC" | "%future added value";
export type ReportAnalyzerInput = {
  startOn: string;
  endOn: string;
  device?: Device | null;
  sourceId?: string | null;
  funnelId?: string | null;
};
export type SiteListCard_Query$variables = {
  input: ReportAnalyzerInput;
  searchTerm?: string | null;
  count?: number | null;
  cursor?: string | null;
};
export type SiteListCard_QueryVariables = SiteListCard_Query$variables;
export type SiteListCard_Query$data = {
  readonly viewer: {
    readonly id: string;
    readonly sites: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly slug: string;
          readonly name: string;
          readonly url: string;
          readonly uniqueUserCount: number;
          readonly conversionUserCount: number;
          readonly conversionUserRate: number;
          readonly popups: {
            readonly totalCount: number;
          };
          readonly abtestScenarioPages: {
            readonly totalCount: number;
          };
          readonly chatbots: {
            readonly totalCount: number;
          };
          readonly funnels: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly id: string;
                readonly landingPage: {
                  readonly imageUrl: string | null;
                } | null;
              } | null;
            } | null> | null;
          };
        } | null;
      } | null> | null;
    };
  };
};
export type SiteListCard_QueryResponse = SiteListCard_Query$data;
export type SiteListCard_Query = {
  variables: SiteListCard_QueryVariables;
  response: SiteListCard_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchTerm"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "kind": "Variable",
  "name": "searchTerm",
  "variableName": "searchTerm"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v9 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v10 = {
  "alias": null,
  "args": (v9/*: any*/),
  "kind": "ScalarField",
  "name": "uniqueUserCount",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": (v9/*: any*/),
  "kind": "ScalarField",
  "name": "conversionUserCount",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": (v9/*: any*/),
  "kind": "ScalarField",
  "name": "conversionUserRate",
  "storageKey": null
},
v13 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "PopupConnection",
  "kind": "LinkedField",
  "name": "popups",
  "plural": false,
  "selections": (v13/*: any*/),
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "AbtestScenarioPageConnection",
  "kind": "LinkedField",
  "name": "abtestScenarioPages",
  "plural": false,
  "selections": (v13/*: any*/),
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "ChatbotConnection",
  "kind": "LinkedField",
  "name": "chatbots",
  "plural": false,
  "selections": (v13/*: any*/),
  "storageKey": null
},
v17 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": {
      "direction": "DESC",
      "field": "UPDATED_AT"
    }
  }
],
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageUrl",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v22 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SiteListCard_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": "sites",
            "args": [
              (v5/*: any*/)
            ],
            "concreteType": "SiteConnection",
            "kind": "LinkedField",
            "name": "__SiteListCard_sites_connection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SiteEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Site",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      {
                        "alias": null,
                        "args": (v17/*: any*/),
                        "concreteType": "FunnelConnection",
                        "kind": "LinkedField",
                        "name": "funnels",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "FunnelEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Funnel",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Page",
                                    "kind": "LinkedField",
                                    "name": "landingPage",
                                    "plural": false,
                                    "selections": [
                                      (v18/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "funnels(first:1,orderBy:{\"direction\":\"DESC\",\"field\":\"UPDATED_AT\"})"
                      },
                      (v19/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v20/*: any*/)
                ],
                "storageKey": null
              },
              (v21/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "SiteListCard_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": (v22/*: any*/),
            "concreteType": "SiteConnection",
            "kind": "LinkedField",
            "name": "sites",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SiteEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Site",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      {
                        "alias": null,
                        "args": (v17/*: any*/),
                        "concreteType": "FunnelConnection",
                        "kind": "LinkedField",
                        "name": "funnels",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "FunnelEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Funnel",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Page",
                                    "kind": "LinkedField",
                                    "name": "landingPage",
                                    "plural": false,
                                    "selections": [
                                      (v18/*: any*/),
                                      (v4/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "funnels(first:1,orderBy:{\"direction\":\"DESC\",\"field\":\"UPDATED_AT\"})"
                      },
                      (v4/*: any*/),
                      (v19/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v20/*: any*/)
                ],
                "storageKey": null
              },
              (v21/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v22/*: any*/),
            "filters": [
              "searchTerm"
            ],
            "handle": "connection",
            "key": "SiteListCard_sites",
            "kind": "LinkedHandle",
            "name": "sites"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fef025d1c2157ed69664a136f1c81497",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": "count",
          "cursor": "cursor",
          "direction": "forward",
          "path": [
            "viewer",
            "sites"
          ]
        }
      ]
    },
    "name": "SiteListCard_Query",
    "operationKind": "query",
    "text": "query SiteListCard_Query(\n  $input: ReportAnalyzerInput!\n  $searchTerm: String\n  $count: Int\n  $cursor: String\n) {\n  viewer {\n    id\n    sites(searchTerm: $searchTerm, first: $count, after: $cursor) {\n      edges {\n        node {\n          slug\n          name\n          url\n          uniqueUserCount(input: $input)\n          conversionUserCount(input: $input)\n          conversionUserRate(input: $input)\n          popups {\n            totalCount\n          }\n          abtestScenarioPages {\n            totalCount\n          }\n          chatbots {\n            totalCount\n          }\n          funnels(orderBy: {field: UPDATED_AT, direction: DESC}, first: 1) {\n            edges {\n              node {\n                id\n                landingPage {\n                  imageUrl\n                  id\n                }\n              }\n            }\n          }\n          id\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a0c96b07687cf77f8ac50518641971e4";

export default node;
