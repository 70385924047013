/**
 * @generated SignedSource<<f7a8837e5ff3f04e922c4927ea91fdee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PageKind = "LANDING" | "FORM" | "CONFIRM" | "THANKS" | "%future added value";
export type PopupFilterButton_Query$variables = {
  siteSlug: string;
};
export type PopupFilterButton_QueryVariables = PopupFilterButton_Query$variables;
export type PopupFilterButton_Query$data = {
  readonly site: {
    readonly pages: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string;
          readonly kind: PageKind;
          readonly sortNumber: number;
          readonly funnel: {
            readonly id: string;
            readonly name: string;
          };
        } | null;
      } | null> | null;
    };
    readonly funnels: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string;
        } | null;
      } | null> | null;
    };
  };
  readonly sources: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
      } | null;
    } | null> | null;
  };
};
export type PopupFilterButton_QueryResponse = PopupFilterButton_Query$data;
export type PopupFilterButton_Query = {
  variables: PopupFilterButton_QueryVariables;
  response: PopupFilterButton_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "siteSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "siteSlug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "PageConnection",
  "kind": "LinkedField",
  "name": "pages",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PageEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Page",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "kind",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sortNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Funnel",
              "kind": "LinkedField",
              "name": "funnel",
              "plural": false,
              "selections": (v4/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "FunnelConnection",
  "kind": "LinkedField",
  "name": "funnels",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FunnelEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Funnel",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "SourceConnection",
  "kind": "LinkedField",
  "name": "sources",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SourceEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Source",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PopupFilterButton_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      (v7/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PopupFilterButton_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      (v7/*: any*/)
    ]
  },
  "params": {
    "cacheID": "08b664fa73f36f020b931ab0998fc254",
    "id": null,
    "metadata": {},
    "name": "PopupFilterButton_Query",
    "operationKind": "query",
    "text": "query PopupFilterButton_Query(\n  $siteSlug: String!\n) {\n  site(slug: $siteSlug) {\n    pages {\n      edges {\n        node {\n          id\n          name\n          kind\n          sortNumber\n          funnel {\n            id\n            name\n          }\n        }\n      }\n    }\n    funnels {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n    id\n  }\n  sources {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c5d8bc724bb456f55166813426a3a15f";

export default node;
