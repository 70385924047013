/**
 * @generated SignedSource<<1bf9dedc02398a4c94c58d5f714a4253>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DistributionStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type DeactivateDistributionStatusableInput = {
  clientMutationId?: string | null;
  distributionStatusableId: string;
};
export type DistributionStatusSwitch_DeactivateMutation$variables = {
  input: DeactivateDistributionStatusableInput;
};
export type DistributionStatusSwitch_DeactivateMutationVariables = DistributionStatusSwitch_DeactivateMutation$variables;
export type DistributionStatusSwitch_DeactivateMutation$data = {
  readonly updateStatus: {
    readonly distributionStatusable: {
      readonly status: DistributionStatus;
    };
  } | null;
};
export type DistributionStatusSwitch_DeactivateMutationResponse = DistributionStatusSwitch_DeactivateMutation$data;
export type DistributionStatusSwitch_DeactivateMutation = {
  variables: DistributionStatusSwitch_DeactivateMutationVariables;
  response: DistributionStatusSwitch_DeactivateMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DistributionStatusSwitch_DeactivateMutation",
    "selections": [
      {
        "alias": "updateStatus",
        "args": (v1/*: any*/),
        "concreteType": "DeactivateDistributionStatusablePayload",
        "kind": "LinkedField",
        "name": "deactivateDistributionStatusable",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "distributionStatusable",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DistributionStatusSwitch_DeactivateMutation",
    "selections": [
      {
        "alias": "updateStatus",
        "args": (v1/*: any*/),
        "concreteType": "DeactivateDistributionStatusablePayload",
        "kind": "LinkedField",
        "name": "deactivateDistributionStatusable",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "distributionStatusable",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "68c0c48a1d47569977290b8c1af98652",
    "id": null,
    "metadata": {},
    "name": "DistributionStatusSwitch_DeactivateMutation",
    "operationKind": "mutation",
    "text": "mutation DistributionStatusSwitch_DeactivateMutation(\n  $input: DeactivateDistributionStatusableInput!\n) {\n  updateStatus: deactivateDistributionStatusable(input: $input) {\n    distributionStatusable {\n      __typename\n      status\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e09538df9d31f93133ea0ed4d78e5eaf";

export default node;
