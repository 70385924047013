import { VStack } from "@chakra-ui/react";
import { FilterAltOutlined as FilterAltOutlinedIcon } from "@mui/icons-material";
import { Form, Formik } from "formik";
import { FC, useCallback, useMemo } from "react";
import * as yup from "yup";

import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormikFormButtons } from "~/src/components/common/forms/FormikFormButtons";
import { FormikSelectField } from "~/src/components/common/forms/FormikSelectField";
import { IconButton } from "~/src/components/common/IconButton";
import { Modal } from "~/src/components/common/Modal";
import { FilteredTag } from "~/src/components/features/filter";
import { useOpenState } from "~/src/lib/hooks";

export type FormValues = {
  withArchived: boolean | null;
  onlyExperiment: boolean | null;
};

export type Props = {
  filterValues: FormValues;
  onFilterChange: (filterValues: FormValues) => void;
  onSubmit: (values: FormValues) => Promise<void> | void;
};

const validationSchema = yup.object({
  withArchived: yup.boolean().nullable(),
  onlyExperiment: yup.boolean().nullable(),
});

export const FunnelFilterButton: FC<Props> = ({
  filterValues,
  onFilterChange,
  onSubmit,
}) => {
  const { isOpen, onClose, onOpen } = useOpenState();

  const includeArchiveOptions = useMemo(
    () => [
      { label: "デフォルト（アーカイブ含まない）", value: null },
      { label: "アーカイブ含む", value: true },
    ],
    []
  );

  const handleFilterChange = useCallback(
    (values: Partial<FormValues>) => () => {
      onFilterChange({ ...filterValues, ...values });
    },
    [filterValues, onFilterChange]
  );

  const handleSubmit = useCallback(
    (values: FormValues) => {
      onSubmit(values);
      onClose();
    },
    [onClose, onSubmit]
  );

  const onlyExperimentOptions = useMemo(
    () => [
      { label: "デフォルト（テスト用と本番用どちらも含む）", value: null },
      { label: "テスト用のみ", value: true },
    ],
    []
  );

  return (
    <>
      {filterValues.withArchived && (
        <FilteredTag
          name="アーカイブを含めるか"
          value={filterValues.withArchived}
          options={includeArchiveOptions}
          onClear={handleFilterChange({ withArchived: null })}
        />
      )}
      {filterValues.onlyExperiment && (
        <FilteredTag
          name="テスト用ファネルのみにするか"
          value={filterValues.onlyExperiment}
          options={onlyExperimentOptions}
          onClear={handleFilterChange({ onlyExperiment: null })}
        />
      )}

      <IconButton
        size="24px"
        icon={<FilterAltOutlinedIcon color={isOpen ? "primary" : undefined} />}
        onClick={onOpen}
      />
      <Modal title="フィルタ" isOpen={isOpen} onClose={onClose}>
        <Formik<FormValues>
          enableReinitialize
          initialValues={filterValues}
          validationSchema={validationSchema}
          validateOnMount={false}
          validateOnChange={false}
          onSubmit={handleSubmit}
        >
          <Form>
            <VStack spacing="16px">
              <FormControl label="アーカイブを含めるか">
                <FormikSelectField
                  name="withArchived"
                  options={includeArchiveOptions}
                />
              </FormControl>
              <FormControl label="テスト用ファネルのみにするか">
                <FormikSelectField
                  name="onlyExperiment"
                  options={onlyExperimentOptions}
                />
              </FormControl>
            </VStack>
            <FormikFormButtons onCancelClick={onClose} />
          </Form>
        </Formik>
      </Modal>
    </>
  );
};
