import { Box, Text } from "@chakra-ui/react";
import { CloudDownloadOutlined as CloudDownloadOutlinedIcon } from "@mui/icons-material";
import { FC, useCallback, useState } from "react";
import { CSVLink } from "react-csv";

import {
  SolidButton,
  SymbolOutlineButton,
} from "~/src/components/common/Button";
import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormControlGroup } from "~/src/components/common/forms/FormControlGroup";
import {
  Table,
  TableColumnNumbersRow,
  TableDataColumn,
  TableDataRow,
  TableEmptyColumn,
  TableHeaderColumn,
  TableHeaderRow,
} from "~/src/components/common/tables/Table";
import { chakraFactory } from "~/src/lib/chakra-ui";

import { CSVUploadButton, ParsedCSVObject } from "./CSVUploadButton";

export type Props = {
  onApplyClick: (csvObject: ParsedCSVObject) => void;
  downloadCSVFilename: string;
  downloadCSVData: string;
};

const StyledWrapperBox = chakraFactory(Box, {
  baseStyle: {
    width: "100%",
    my: "54px",
  },
});

const StyledFormControlGroup = chakraFactory(FormControlGroup, {
  baseStyle: {
    mb: "40px",
  },
});

const StyledCSVDownloadButton = chakraFactory(SymbolOutlineButton, {
  baseStyle: {
    my: "5px",
  },
});

const StyledTemplateDescriptionText = chakraFactory(Text, {
  baseStyle: {
    fontSize: "11px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.04em",
    color: "#000000",

    "> a": {
      color: "#1867C0",

      "&:hover": {
        textDecoration: "underline",
      },
    },
  },
});

const StyledApplyButton = chakraFactory(SolidButton, {
  baseStyle: {
    mb: "40px",
  },
});

export const FunnelBulkTab: FC<Props> = ({
  onApplyClick,
  downloadCSVFilename,
  downloadCSVData,
}) => {
  const [csvObject, setCSVObject] = useState<ParsedCSVObject | null>(null);

  const handleApplyClick = useCallback(() => {
    if (!csvObject) return;
    onApplyClick(csvObject);
  }, [csvObject, onApplyClick]);

  return (
    <StyledWrapperBox>
      <StyledFormControlGroup>
        <FormControl as="div" label="テンプレート">
          <CSVLink filename={downloadCSVFilename} data={downloadCSVData}>
            <StyledCSVDownloadButton icon={<CloudDownloadOutlinedIcon />}>
              CSVファイルをダウンロード
            </StyledCSVDownloadButton>
          </CSVLink>

          <StyledTemplateDescriptionText>
            {/* TODO: ヘルプページのリンクを埋める */}
            入力方法は<a href="/">ヘルプページ</a>を参考にしてください。
          </StyledTemplateDescriptionText>
        </FormControl>
        <FormControl as="div" label="ソースコード">
          <CSVUploadButton onCSVLoaded={setCSVObject} />
        </FormControl>
      </StyledFormControlGroup>
      <StyledApplyButton isDisabled={!csvObject} onClick={handleApplyClick}>
        適用
      </StyledApplyButton>
      {csvObject && (
        <Table withColumnNumbersRow>
          <TableColumnNumbersRow size={csvObject.headers.length} />
          <TableHeaderRow withColumnNumbersRow>
            <TableEmptyColumn />
            {csvObject.headers.map((header, i) => (
              <TableHeaderColumn key={i}>{header}</TableHeaderColumn>
            ))}
          </TableHeaderRow>
          {csvObject.rows.map((row, i) => (
            <TableDataRow key={i}>
              <TableEmptyColumn />
              {row.map((value, j) => (
                <TableDataColumn key={j} type="text" data={value} />
              ))}
            </TableDataRow>
          ))}
        </Table>
      )}
    </StyledWrapperBox>
  );
};
