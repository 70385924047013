/**
 * @generated SignedSource<<316197ce284ae4698fac7ec4fe102b05>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ChatMessageFlowNodeKind = "TEXT" | "IMAGE" | "SUBMIT" | "REPLY" | "%future added value";
export type ChatMessageKind = "TEXT" | "IMAGE" | "SUBMIT" | "%future added value";
export type ChatMessageReplyGroupKind = "TEXT_BOX" | "RADIO_BUTTON" | "CHECK_BOX" | "SELECT_BOX" | "TEXT_AREA" | "%future added value";
export type ChatMessageReplyKind = "CUSTOM_TEXT" | "API" | "OPTION" | "NAME" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ChatbotFlowContainer_chatbot$data = {
  readonly chatMessageFlowNodes: ReadonlyArray<{
    readonly nodeId: string;
    readonly kind: ChatMessageFlowNodeKind;
    readonly position: {
      readonly x: number;
      readonly y: number;
    };
    readonly chatMessage: {
      readonly id: string;
      readonly kind: ChatMessageKind;
      readonly message: string | null;
      readonly isHasReply: boolean;
      readonly isSaveReply: boolean;
      readonly isInheritQuery: boolean;
      readonly isTransferReply: boolean;
      readonly displaySpeed: number;
      readonly coordinate: string;
      readonly messageIndex: number;
      readonly nextMessageIndex: number;
      readonly submitUrl: string | null;
    };
    readonly replyGroup: {
      readonly id: string;
      readonly kind: ChatMessageReplyGroupKind;
      readonly label: string | null;
      readonly number: number;
      readonly divergingPoint: boolean;
      readonly coordinate: string;
      readonly replies: ReadonlyArray<{
        readonly id: string;
        readonly kind: ChatMessageReplyKind;
        readonly messageIndex: number;
        readonly nextMessageIndex: number;
        readonly errorMessage: string | null;
        readonly label: string | null;
        readonly name: string;
        readonly value: string | null;
        readonly regularExpression: string | null;
        readonly placeholder: string | null;
        readonly replyNodeId: string;
        readonly apiAction: string | null;
      }>;
    } | null;
  }>;
  readonly chatMessageReplyFlowNodes: ReadonlyArray<{
    readonly nodeId: string;
    readonly nodeLabel: string;
    readonly parentNode: string | null;
    readonly kind: ChatMessageFlowNodeKind;
  }>;
  readonly chatMessageFlowEdges: ReadonlyArray<{
    readonly source: string;
    readonly target: string;
  }>;
  readonly chatMessageReplyFlowEdges: ReadonlyArray<{
    readonly source: string;
    readonly target: string;
  }>;
  readonly " $fragmentType": "ChatbotFlowContainer_chatbot";
};
export type ChatbotFlowContainer_chatbot = ChatbotFlowContainer_chatbot$data;
export type ChatbotFlowContainer_chatbot$key = {
  readonly " $data"?: ChatbotFlowContainer_chatbot$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChatbotFlowContainer_chatbot">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "coordinate",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "messageIndex",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nextMessageIndex",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "source",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "target",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChatbotFlowContainer_chatbot",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ChatMessageFlowNode",
      "kind": "LinkedField",
      "name": "chatMessageFlowNodes",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ChatMessageFlowNodePosition",
          "kind": "LinkedField",
          "name": "position",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "x",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "y",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ChatMessage",
          "kind": "LinkedField",
          "name": "chatMessage",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "message",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isHasReply",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isSaveReply",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isInheritQuery",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isTransferReply",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displaySpeed",
              "storageKey": null
            },
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "submitUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ChatMessageReplyGroup",
          "kind": "LinkedField",
          "name": "replyGroup",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v1/*: any*/),
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "number",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "divergingPoint",
              "storageKey": null
            },
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "ChatMessageReply",
              "kind": "LinkedField",
              "name": "replies",
              "plural": true,
              "selections": [
                (v2/*: any*/),
                (v1/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "errorMessage",
                  "storageKey": null
                },
                (v6/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "value",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "regularExpression",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "placeholder",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "replyNodeId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "apiAction",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ChatMessageReplyFlowNode",
      "kind": "LinkedField",
      "name": "chatMessageReplyFlowNodes",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nodeLabel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "parentNode",
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ChatMessageFlowEdge",
      "kind": "LinkedField",
      "name": "chatMessageFlowEdges",
      "plural": true,
      "selections": (v7/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ChatMessageReplyFlowEdge",
      "kind": "LinkedField",
      "name": "chatMessageReplyFlowEdges",
      "plural": true,
      "selections": (v7/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Chatbot",
  "abstractKey": null
};
})();

(node as any).hash = "be9c09a76b55077aac2170dfa3ce85d1";

export default node;
