/**
 * @generated SignedSource<<89be023413a83a512d43f3bb0efa672a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PatternContentJudgeStatus = "PENDING" | "APPROVED" | "REJECTED" | "%future added value";
export type UpdateAbtestScenarioPageJudgeStatusInput = {
  clientMutationId?: string | null;
  abtestScenarioPageId: string;
  judgeStatus: PatternContentJudgeStatus;
};
export type JudgementListPage_AbtestJudgeMentMutation$variables = {
  input: UpdateAbtestScenarioPageJudgeStatusInput;
};
export type JudgementListPage_AbtestJudgeMentMutationVariables = JudgementListPage_AbtestJudgeMentMutation$variables;
export type JudgementListPage_AbtestJudgeMentMutation$data = {
  readonly updateAbtestScenarioPageJudgeStatus: {
    readonly abtestScenarioPage: {
      readonly id: string;
      readonly judgeStatus: PatternContentJudgeStatus;
    } | null;
  } | null;
};
export type JudgementListPage_AbtestJudgeMentMutationResponse = JudgementListPage_AbtestJudgeMentMutation$data;
export type JudgementListPage_AbtestJudgeMentMutation = {
  variables: JudgementListPage_AbtestJudgeMentMutationVariables;
  response: JudgementListPage_AbtestJudgeMentMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateAbtestScenarioPageJudgeStatusPayload",
    "kind": "LinkedField",
    "name": "updateAbtestScenarioPageJudgeStatus",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AbtestScenarioPage",
        "kind": "LinkedField",
        "name": "abtestScenarioPage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "judgeStatus",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "JudgementListPage_AbtestJudgeMentMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "JudgementListPage_AbtestJudgeMentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f7cb9a5b63a2c08018700f9c44b11814",
    "id": null,
    "metadata": {},
    "name": "JudgementListPage_AbtestJudgeMentMutation",
    "operationKind": "mutation",
    "text": "mutation JudgementListPage_AbtestJudgeMentMutation(\n  $input: UpdateAbtestScenarioPageJudgeStatusInput!\n) {\n  updateAbtestScenarioPageJudgeStatus(input: $input) {\n    abtestScenarioPage {\n      id\n      judgeStatus\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "18280ea248611f3f6ef1ea6103ef90a7";

export default node;
