import { FC } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";

import { ChatbotEditPageContainer_Query } from "~/src/__relay_artifacts__/ChatbotEditPageContainer_Query.graphql";

import { ChatbotEditScreenContainer } from "./ChatbotEditScreenContainer";

export type Props = {};

const query = graphql`
  query ChatbotEditPageContainer_Query(
    $chatbotSlug: String!
    $siteSlug: String!
  ) {
    chatbotGroup(slug: $chatbotSlug) {
      ...ChatbotEditScreenContainer_chatbotGroup
    }
    site(slug: $siteSlug) {
      ...ChatbotEditScreenContainer_site
    }
  }
`;

export const ChatbotEditPageContainer: FC<Props> = () => {
  const { chatbotSlug = "", siteSlug = "" } = useParams();

  const { chatbotGroup, site } =
    useLazyLoadQuery<ChatbotEditPageContainer_Query>(query, {
      chatbotSlug,
      siteSlug,
    });

  if (!chatbotGroup) throw new Error("assertion failed");

  return (
    <ChatbotEditScreenContainer chatbotGroupRef={chatbotGroup} siteRef={site} />
  );
};
