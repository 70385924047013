import { Box } from "@chakra-ui/react";
import { FC, useCallback } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useNavigate, useParams } from "react-router-dom";

import { ChatbotPatternCreatePageContainer_Mutation } from "~/src/__relay_artifacts__/ChatbotPatternCreatePageContainer_Mutation.graphql";
import { ChatbotPatternCreatePageContainer_Query } from "~/src/__relay_artifacts__/ChatbotPatternCreatePageContainer_Query.graphql";
import { WizardStep } from "~/src/components/common/WizardStep";
import {
  ChatbotPatternForm,
  ChatbotPatternFormProps,
} from "~/src/components/features/chatbot/ChatbotPatternForm";
import { PageLayout } from "~/src/components/features/global/PageLayout";
import { useFormErrorHandler } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";

type Props = {};

const query = graphql`
  query ChatbotPatternCreatePageContainer_Query($slug: String!) {
    viewer {
      role
    }
    chatbotGroup(slug: $slug) {
      id
      name
      slug
      deliverKind
    }
  }
`;

const mutation = graphql`
  mutation ChatbotPatternCreatePageContainer_Mutation(
    $input: AddChatbotInput!
    $originalInput: UpdateOriginalChatbotInput!
    $withOriginal: Boolean!
  ) {
    updateOriginalChatbot(input: $originalInput) @include(if: $withOriginal) {
      chatbot {
        id
        weight
      }
    }
    addChatbot(input: $input) {
      chatbotEdge {
        node {
          id
          slug
          name
        }
      }
    }
  }
`;

export const ChatbotPatternCreatePageContainer: FC<Props> = () => {
  const { siteSlug = "", chatbotSlug = "" } = useParams();
  const { chatbotGroup, viewer } =
    useLazyLoadQuery<ChatbotPatternCreatePageContainer_Query>(query, {
      slug: chatbotSlug,
    });

  if (!chatbotGroup) throw new Error("assertion failed");

  const navigate = useNavigate();

  const handleCancel = useCallback(() => navigate(-1), [navigate]);

  const { onFormError } = useFormErrorHandler();

  const mutate =
    useMutationCommit<ChatbotPatternCreatePageContainer_Mutation>(mutation);

  const handleSubmit = useCallback<ChatbotPatternFormProps["onSubmit"]>(
    async (
      {
        botImage,
        openButtonImage,
        toFormButtonImage,
        originalPatternWeight,
        questionCsv,
        flowNodes,
        flowEdges,
        replies,
        chatbotApiResults,
        ...values
      },
      { setErrors }
    ) => {
      const uploadables: Record<string, File> = {};
      if (botImage) uploadables[`variables.input.botImage`] = botImage;
      if (openButtonImage)
        uploadables[`variables.input.openButtonImage`] = openButtonImage;
      if (toFormButtonImage)
        uploadables[`variables.input.toFormButtonImage`] = toFormButtonImage;
      if (questionCsv) uploadables[`variables.input.questionCsv`] = questionCsv;

      flowNodes.forEach((node, index) => {
        if (
          node.chatMessage.chatMessageImage &&
          node.chatMessage.chatMessageImage.image
        ) {
          uploadables[
            `variables.input.chatMessageNodes.${index}.chatMessage.chatMessageImage.image`
          ] = node.chatMessage.chatMessageImage.image;
        }
      });

      try {
        const res = await mutate({
          variables: {
            input: {
              chatbotGroupId: chatbotGroup.id,
              chatMessageNodes: flowNodes,
              chatMessageEdges: flowEdges,
              chatMessageReplies: replies,
              chatbotApiResults,
              ...values,
            },
            withOriginal: chatbotGroup.deliverKind === "WEIGHT",
            originalInput: {
              chatbotGroupId: chatbotGroup.id,
              weight: originalPatternWeight,
            },
          },
          uploadables,
        });
        const chatbot = res.addChatbot?.chatbotEdge?.node;
        if (!chatbot) {
          throw new Error("assertion failed");
        }

        navigate(
          `/sites/${siteSlug}/chatbots/${chatbotSlug}/patterns/${chatbot.slug}`
        );
      } catch (err) {
        onFormError(err, setErrors);
      }
    },
    [
      chatbotGroup.deliverKind,
      chatbotGroup.id,
      chatbotSlug,
      mutate,
      navigate,
      onFormError,
      siteSlug,
    ]
  );

  return (
    <PageLayout
      title="チャットボットパターン登録"
      breadcrumbs={[
        { label: "チャットボット一覧", path: `/sites/${siteSlug}/chatbots` },
        {
          label: "チャットボット詳細",
          path: `/sites/${siteSlug}/chatbots/${chatbotSlug}`,
        },
        {
          label: "パターン登録",
          path: `/sites/${siteSlug}/chatbots/${chatbotSlug}/patterns/new`,
        },
      ]}
    >
      <Box mt="16px" p="16px">
        <WizardStep
          steps={[
            { label: "基本設定", active: true },
            { label: "パターン設定", active: true },
          ]}
        />
      </Box>
      <Box my="16px" p="16px">
        <ChatbotPatternForm
          enableWeightFields={chatbotGroup.deliverKind === "WEIGHT"}
          onCancelClick={handleCancel}
          onSubmit={handleSubmit}
          role={viewer.role}
        />
      </Box>
    </PageLayout>
  );
};
