import { Box } from "@chakra-ui/react";
import * as R from "ramda";
import { memo } from "react";

import { chakraFactory } from "~/src/lib/chakra-ui";

import { TableEmptyColumn } from "../columns/TableEmptyColumn";
import { columnStyles, rowStyles } from "../styles";

export type Props = {
  size: number;
};

const StyledNumbersRowBox = chakraFactory(Box, {
  baseStyle: {
    ...rowStyles,

    position: "sticky",
    top: 0,
    zIndex: 2,
    background: "#DBDBDB",
    border: "1px solid #DBDBDB",
  },
});

const StyledNumberColumnBox = chakraFactory(Box, {
  baseStyle: {
    ...columnStyles,
    justifyContent: "center",
    fontSize: "11px",
    lineHeight: "16px",
    letterSpacing: "0.06em",
  },
});

export const TableColumnNumbersRow = memo<Props>(({ size }) => (
  <StyledNumbersRowBox>
    <TableEmptyColumn />
    {R.times(
      (i) => (
        <StyledNumberColumnBox>{i}</StyledNumberColumnBox>
      ),
      size
    )}
  </StyledNumbersRowBox>
));
