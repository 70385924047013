import { Box } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/react";
import { FC, useCallback } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useNavigate, useParams } from "react-router-dom";

import { ABTestPatternCreatePageContainer_ImageContentMutation } from "~/src/__relay_artifacts__/ABTestPatternCreatePageContainer_ImageContentMutation.graphql";
import { ABTestPatternCreatePageContainer_Mutation } from "~/src/__relay_artifacts__/ABTestPatternCreatePageContainer_Mutation.graphql";
import { ABTestPatternCreatePageContainer_Query } from "~/src/__relay_artifacts__/ABTestPatternCreatePageContainer_Query.graphql";
import { ABTestPatternCreatePageContainer_TextReplaceContentMutation } from "~/src/__relay_artifacts__/ABTestPatternCreatePageContainer_TextReplaceContentMutation.graphql";
import { WizardStep } from "~/src/components/common/WizardStep";
import {
  ABTestPatternArrayFormContainer,
  ABTestPatternArrayFormProps,
} from "~/src/components/features/abtest";
import { PageLayout } from "~/src/components/features/global/PageLayout";
import { useMutationCommit } from "~/src/lib/react-relay";

export type Props = {};

const query = graphql`
  query ABTestPatternCreatePageContainer_Query($slug: String!) {
    abtestScenarioPageGroup(slug: $slug) {
      id
      title
      slug
      deliverKind
      page {
        id
      }
    }
  }
`;

const mutationScenario = graphql`
  mutation ABTestPatternCreatePageContainer_Mutation(
    $input: AddAbtestScenarioPageInput!
    $originalInput: UpdateOriginalAbtestScenarioPageInput!
    $withOriginal: Boolean!
  ) {
    updateOriginalAbtestScenarioPage(input: $originalInput)
      @include(if: $withOriginal) {
      abtestScenarioPage {
        id
        weight
      }
    }
    addAbtestScenarioPage(input: $input) {
      abtestScenarioPageEdge {
        node {
          id
          title
          slug
        }
      }
    }
  }
`;

const textReplaceContentMutation = graphql`
  mutation ABTestPatternCreatePageContainer_TextReplaceContentMutation(
    $input: AddAbtestScenarioPageContentTextReplaceInput!
  ) {
    addAbtestScenarioPageTextReplace(input: $input) {
      abtestScenarioPageContentEdge {
        node {
          __typename
          ... on AbtestScenarioPageContentTextReplace {
            id
            targetType
            targetValue
            targetAttribute
            actionType
            replaceValue
          }
        }
      }
    }
  }
`;

const imageContentMutation = graphql`
  mutation ABTestPatternCreatePageContainer_ImageContentMutation(
    $input: AddAbtestScenarioPageContentScenarioInput!
  ) {
    addAbtestScenarioPageContentScenario(input: $input) {
      abtestScenarioPageContentEdge {
        node {
          __typename
          ... on AbtestScenarioPageContentScenario {
            id
            image
            imageUrl
            beforeSrc
          }
        }
      }
    }
  }
`;

export const ABTestPatternCreatePageContainer: FC<Props> = () => {
  const { abtestSlug = "", siteSlug = "" } = useParams();
  const toast = useToast();

  const { abtestScenarioPageGroup } =
    useLazyLoadQuery<ABTestPatternCreatePageContainer_Query>(query, {
      slug: abtestSlug,
    });

  if (!abtestScenarioPageGroup || !abtestScenarioPageGroup.page)
    throw new Error("assertion failed");

  const navigate = useNavigate();
  const handleCancel = useCallback(() => navigate(-1), [navigate]);

  const pageMutate =
    useMutationCommit<ABTestPatternCreatePageContainer_Mutation>(
      mutationScenario
    );

  const textReplaceMutate =
    useMutationCommit<ABTestPatternCreatePageContainer_TextReplaceContentMutation>(
      textReplaceContentMutation
    );
  const imageMutate =
    useMutationCommit<ABTestPatternCreatePageContainer_ImageContentMutation>(
      imageContentMutation
    );

  const handleSubmit = useCallback<ABTestPatternArrayFormProps["onSubmit"]>(
    async (value) => {
      value.formValues.forEach(
        async ({
          scenarioContents,
          textReplaceContents,
          redirectContents,
          originalPatternWeight,
          ...values
        }) => {
          const { id, title, ...rest } = values;
          try {
            const pageRes = await pageMutate({
              variables: {
                input: {
                  abtestScenarioPageGroupId: abtestScenarioPageGroup.id,
                  pageId: abtestScenarioPageGroup.page.id,
                  linkUrl: redirectContents?.linkUrl,
                  isInheritQuery:
                    redirectContents?.isInheritQuery === true ? 1 : 0,
                  title: title ? title : "",
                  ...rest,
                },
                withOriginal: abtestScenarioPageGroup.deliverKind === "WEIGHT",
                originalInput: {
                  abtestScenarioPageGroupId: abtestScenarioPageGroup.id,
                  weight: originalPatternWeight,
                },
              },
            });
            const createdAbtestScenarioPage =
              pageRes.addAbtestScenarioPage?.abtestScenarioPageEdge?.node;
            if (!createdAbtestScenarioPage) throw new Error("assertion failed");

            switch (values.kind) {
              case "SCENARIO":
                if (!!scenarioContents && scenarioContents.length > 0) {
                  try {
                    // MEMO: ターゲットを作成した順にDBに登録する必要がある
                    // https://github.com/macbee-planet/robee/blob/master/app/controllers/v1/site_controller.rb#L453
                    // https://macbee-planet-88.slack.com/archives/C02GH7ZTXHR/p1678155906812749
                    scenarioContents.forEach(async (content) => {
                      const uploadables: Record<string, File> = {};
                      if (content.image) {
                        uploadables["variables.input.image"] = content.image;
                      }
                      const response = await imageMutate({
                        variables: {
                          input: {
                            abtestScenarioPageId: createdAbtestScenarioPage.id,
                            beforeSrc: content.beforeSrc || "",
                          },
                        },
                        uploadables,
                      });
                      if (
                        !response.addAbtestScenarioPageContentScenario
                          ?.abtestScenarioPageContentEdge
                      )
                        throw new Error("assertion failed");
                    });
                  } catch (err) {
                    throw new Error(err);
                  }
                }

                break;
              case "TEXT_REPLACE":
                if (!!textReplaceContents && textReplaceContents.length > 0) {
                  try {
                    // MEMO: ターゲットを作成した順にDBに登録する必要がある
                    // https://github.com/macbee-planet/robee/blob/master/app/controllers/v1/site_controller.rb#L453
                    // https://macbee-planet-88.slack.com/archives/C02GH7ZTXHR/p1678155906812749
                    textReplaceContents.forEach(async (textReplaceContent) => {
                      const response = await textReplaceMutate({
                        variables: {
                          input: {
                            abtestScenarioPageId: createdAbtestScenarioPage.id,
                            targetType: textReplaceContent.targetType,
                            actionType: textReplaceContent.actionType,
                            targetValue: textReplaceContent.targetValue,
                            targetAttribute: textReplaceContent.targetAttribute,
                            replaceValue: textReplaceContent.replaceValue,
                          },
                        },
                      });
                      if (
                        !response.addAbtestScenarioPageTextReplace
                          ?.abtestScenarioPageContentEdge
                      )
                        throw new Error("assertion failed");
                    });
                  } catch (err) {
                    throw new Error(err);
                  }
                }

                break;
              case "REDIRECT":
                break;
              default:
                throw new Error("asserion failed");
            }

            toast({
              title: "ABテストパターンを作成しました",
              status: "success",
            });
          } catch (err) {
            toast({
              title: "ABテストパターンの作成に失敗しました",
              status: "error",
            });
          }
        }
      );

      navigate(`/sites/${siteSlug}/abtests/${abtestSlug}`);

      return Promise.resolve();
    },
    [
      abtestScenarioPageGroup.deliverKind,
      abtestScenarioPageGroup.id,
      abtestScenarioPageGroup.page.id,
      abtestSlug,
      pageMutate,
      textReplaceMutate,
      navigate,
      imageMutate,
      toast,
      siteSlug,
    ]
  );

  return (
    <PageLayout
      title="A/Bテスト パターン登録"
      breadcrumbs={[
        { label: "A/Bテスト一覧", path: `/sites/${siteSlug}/abtests` },
        {
          label: "A/Bテスト詳細",
          path: `/sites/${siteSlug}/abtests/${abtestSlug}`,
        },
        {
          label: "A/Bテスト パターン登録",
          path: `/sites/${siteSlug}/abtests/${abtestSlug}/patterns/new`,
        },
      ]}
    >
      <Box mt="16px" p="16px">
        <WizardStep
          steps={[
            { label: "基本設定", active: true },
            { label: "パターン設定", active: true },
          ]}
        />
      </Box>
      <Box my="16px" p="16px">
        <ABTestPatternArrayFormContainer
          enableWeightFields={abtestScenarioPageGroup?.deliverKind === "WEIGHT"}
          onCancelClick={handleCancel}
          onSubmit={handleSubmit}
        />
      </Box>
    </PageLayout>
  );
};
