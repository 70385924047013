import { Box, Text } from "@chakra-ui/react";
import { FC } from "react";

import { chakraFactory } from "~/src/lib/chakra-ui";

export type Props = {
  className?: string;
  description?: string;
};

const StyledWrapperBox = chakraFactory(Box, {
  baseStyle: {
    width: "100%",
    background: "#FFFFFF",
    border: "1px solid #DADADA",
    padding: "24px",
  },
});

const StyledDescriptionText = chakraFactory(Text, {
  baseStyle: {
    display: "flex",
    alignItems: "center",
    fontWeight: "500",
    fontSize: "13px",
    color: "#000000",
    mb: "24px",
  },
});

export const SectionCard: FC<Props> = ({
  children,
  className,
  description,
}) => (
  <StyledWrapperBox className={className}>
    {description && (
      <StyledDescriptionText>{description}</StyledDescriptionText>
    )}
    {children}
  </StyledWrapperBox>
);
