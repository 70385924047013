/**
 * @generated SignedSource<<fde77ff8d03aa93b0b30ec6c8e18a6f2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type HasForm = "HAS_FORM" | "NO_FORM" | "%future added value";
export type UrlMatch = "EQUAL_TYPE" | "HAS_ID_TYPE" | "EQUAL_OR_HAS_ID_TYPE" | "STARTS_WITH_PREFIX_MATCH" | "REGEX_MATCH_TYPE" | "%future added value";
export type FunnelBulkEditTabContainer_Query$variables = {
  slug: string;
};
export type FunnelBulkEditTabContainer_QueryVariables = FunnelBulkEditTabContainer_Query$variables;
export type FunnelBulkEditTabContainer_Query$data = {
  readonly site: {
    readonly id: string;
    readonly slug: string;
    readonly name: string;
    readonly funnels: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string;
          readonly isExperiment: boolean;
          readonly landingPage: {
            readonly id: string;
            readonly name: string;
            readonly url: string;
            readonly tabletUrl: string | null;
            readonly mobileUrl: string | null;
            readonly urlMatchType: UrlMatch;
            readonly hasForm: HasForm | null;
          } | null;
          readonly formPage: ReadonlyArray<{
            readonly id: string;
            readonly name: string;
            readonly url: string;
            readonly tabletUrl: string | null;
            readonly mobileUrl: string | null;
            readonly urlMatchType: UrlMatch;
          }> | null;
          readonly confirmPage: {
            readonly id: string;
            readonly name: string;
            readonly url: string;
            readonly tabletUrl: string | null;
            readonly mobileUrl: string | null;
            readonly urlMatchType: UrlMatch;
          } | null;
          readonly thanksPage: {
            readonly id: string;
            readonly name: string;
            readonly url: string;
            readonly tabletUrl: string | null;
            readonly mobileUrl: string | null;
            readonly urlMatchType: UrlMatch;
          } | null;
        } | null;
      } | null> | null;
    };
  };
};
export type FunnelBulkEditTabContainer_QueryResponse = FunnelBulkEditTabContainer_Query$data;
export type FunnelBulkEditTabContainer_Query = {
  variables: FunnelBulkEditTabContainer_QueryVariables;
  response: FunnelBulkEditTabContainer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tabletUrl",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mobileUrl",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urlMatchType",
  "storageKey": null
},
v7 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/)
],
v8 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "slug",
        "variableName": "slug"
      }
    ],
    "concreteType": "Site",
    "kind": "LinkedField",
    "name": "site",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "slug",
        "storageKey": null
      },
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "FunnelConnection",
        "kind": "LinkedField",
        "name": "funnels",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FunnelEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Funnel",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isExperiment",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Page",
                    "kind": "LinkedField",
                    "name": "landingPage",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasForm",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Page",
                    "kind": "LinkedField",
                    "name": "formPage",
                    "plural": true,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Page",
                    "kind": "LinkedField",
                    "name": "confirmPage",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Page",
                    "kind": "LinkedField",
                    "name": "thanksPage",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FunnelBulkEditTabContainer_Query",
    "selections": (v8/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FunnelBulkEditTabContainer_Query",
    "selections": (v8/*: any*/)
  },
  "params": {
    "cacheID": "d0e822e61056e7bdf66d628179a5d92c",
    "id": null,
    "metadata": {},
    "name": "FunnelBulkEditTabContainer_Query",
    "operationKind": "query",
    "text": "query FunnelBulkEditTabContainer_Query(\n  $slug: String!\n) {\n  site(slug: $slug) {\n    id\n    slug\n    name\n    funnels {\n      edges {\n        node {\n          id\n          name\n          isExperiment\n          landingPage {\n            id\n            name\n            url\n            tabletUrl\n            mobileUrl\n            urlMatchType\n            hasForm\n          }\n          formPage {\n            id\n            name\n            url\n            tabletUrl\n            mobileUrl\n            urlMatchType\n          }\n          confirmPage {\n            id\n            name\n            url\n            tabletUrl\n            mobileUrl\n            urlMatchType\n          }\n          thanksPage {\n            id\n            name\n            url\n            tabletUrl\n            mobileUrl\n            urlMatchType\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e5be5f4734bc74fbd811e085957044ab";

export default node;
