import { Box, Flex, HStack, Spacer } from "@chakra-ui/react";
import { format } from "date-fns";
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { NavLink, useParams } from "react-router-dom";

import { PopupGroupDetailPageContainer_Query } from "~/src/__relay_artifacts__/PopupGroupDetailPageContainer_Query.graphql";
import { PopupGroupDetailPageContainer_updateDeliverKindMutation } from "~/src/__relay_artifacts__/PopupGroupDetailPageContainer_updateDeliverKindMutation.graphql";
import { PopupGroupDetailPageContainer_updateDeliverWeightMutation } from "~/src/__relay_artifacts__/PopupGroupDetailPageContainer_updateDeliverWeightMutation.graphql";
import { SolidButton } from "~/src/components/common/Button";
import { useTableItemSelect } from "~/src/components/common/tables/TableItemSelect";
import { URLItem } from "~/src/components/common/URLItem";
import { ArchiveOrUnarchiveButton } from "~/src/components/features/archive/ArchiveOrUnarchiveButton";
import { DateRangeContext } from "~/src/components/features/global/HeaderNavigation/DateRangeField";
import { PageLayout } from "~/src/components/features/global/PageLayout";
import { TableItemSelectButton } from "~/src/components/features/global/TableItemSelectButton";
import { useDateRangeSearchParams } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";
import { delayChunkPromise } from "~/src/lib/utils";

import {
  Popup,
  PopupDeliverWeightButton,
  PopupDeliverWeightButtonProps,
  PopupGroupCopyButton,
  PopupGroupDetailSection,
  PopupPatternFilterButton,
  PopupPatternFilterFormValues,
  PopupPatternTable,
} from "./presentations";
import {
  allTableItems,
  initialSelectedTableItems,
} from "./presentations/constants";

export type Props = {};

const query = graphql`
  query PopupGroupDetailPageContainer_Query(
    $slug: String!
    $analyzerInput: ReportAnalyzerInput!
  ) {
    viewer {
      role
    }
    popupGroup(slug: $slug) {
      id
      name
      page {
        id
        kind
        url
        sortNumber
        name
        funnel {
          id
          name
          siteUrl
        }
      }
      isArchive
      deliverKind
      ...PopupGroupDetailSection_popupGroup
      ...PopupPatternTable_popupGroup @arguments(analyzerInput: $analyzerInput)
    }
  }
`;

const updateDeliverKindMutation = graphql`
  mutation PopupGroupDetailPageContainer_updateDeliverKindMutation(
    $input: UpdateDeliverableInput!
  ) {
    updateDeliverable(input: $input) {
      deliverable {
        id
        deliverKind
      }
    }
  }
`;

const updateDeliverWeightMutation = graphql`
  mutation PopupGroupDetailPageContainer_updateDeliverWeightMutation(
    $input: UpdateWeightableInput!
  ) {
    updateWeightable(input: $input) {
      weightable {
        id
        weight
      }
    }
  }
`;

export const PopupGroupDetailPageContainer: FC<Props> = () => {
  const {
    dateRange: [startOn, endOn],
  } = useContext(DateRangeContext);
  const { attachDateRangeParams } = useDateRangeSearchParams();
  const { siteSlug = "", popupGroupSlug = "" } = useParams();
  const [popups, setPopups] = useState<Popup[]>([]);
  const [filterValues, setFilterValues] =
    useState<PopupPatternFilterFormValues>({
      sourceId: null,
      device: null,
      withArchived: null,
      status: null,
    });
  const { popupGroup, viewer } =
    useLazyLoadQuery<PopupGroupDetailPageContainer_Query>(query, {
      slug: popupGroupSlug,
      analyzerInput: {
        startOn: format(startOn, "yyyy-MM-dd"),
        endOn: format(endOn, "yyyy-MM-dd"),
      },
    });

  const { selectedTableItems, headerColumns, dataColumns, onApplyClick } =
    useTableItemSelect({
      cacheKey: "PopupDetailPage",
      initialSelectedTableItems,
    });

  if (!popupGroup) throw new Error("assertion failed");

  const breadcrumbs = useMemo(
    () => [
      {
        label: "ポップアップ一覧",
        path: `/sites/${siteSlug}/popup_groups`,
      },
      {
        label: "ポップアップ詳細",
        path: `/sites/${siteSlug}/popup_groups/${popupGroupSlug}`,
      },
    ],
    [popupGroupSlug, siteSlug]
  );

  const updateDeliverKind =
    useMutationCommit<PopupGroupDetailPageContainer_updateDeliverKindMutation>(
      updateDeliverKindMutation
    );
  const updateDeliverWeight =
    useMutationCommit<PopupGroupDetailPageContainer_updateDeliverWeightMutation>(
      updateDeliverWeightMutation
    );

  const handleDeliverWeightSubmit = useCallback<
    PopupDeliverWeightButtonProps["onSubmit"]
  >(
    async (values) => {
      const mutations: Promise<{}>[] = values.deliverWeights.map(
        (deliverWeight) => {
          return updateDeliverWeight({
            variables: {
              input: {
                weightableId: deliverWeight.id,
                weight: deliverWeight.weight,
              },
            },
          });
        }
      );
      if (popupGroup.deliverKind !== values.deliverKind) {
        mutations.push(
          updateDeliverKind({
            variables: {
              input: {
                deliverableId: popupGroup.id,
                deliverKind: values.deliverKind,
              },
            },
          })
        );
      }

      await delayChunkPromise(mutations);
    },
    [
      popupGroup.deliverKind,
      popupGroup.id,
      updateDeliverKind,
      updateDeliverWeight,
    ]
  );

  useEffect(() => {
    attachDateRangeParams();
  }, [attachDateRangeParams]);

  return (
    <PageLayout title="ポップアップグループ詳細" breadcrumbs={breadcrumbs}>
      <Box mx={4}>
        <Flex alignItems="center">
          <Box color="#282828" fontWeight="bold">
            {popupGroup.name}
          </Box>
          <Spacer />
          <HStack spacing={3}>
            <ArchiveOrUnarchiveButton
              id={popupGroup.id}
              isArchive={popupGroup.isArchive}
            />
            <PopupGroupCopyButton
              originalId={popupGroup.id}
              currentValues={{
                funnelId: popupGroup.page.funnel.id,
                pageKind: popupGroup.page.kind,
                pageId: popupGroup.page.id,
              }}
            />
          </HStack>
        </Flex>
        <HStack>
          <Box>{`実施ファネル: ${popupGroup.page.funnel.name}`}</Box>
          <URLItem url={popupGroup.page.funnel.siteUrl} />
        </HStack>
        <HStack>
          <Box>
            {`実施ページ: ${
              popupGroup.page.kind === "FORM"
                ? popupGroup.page.name + `(${popupGroup.page.sortNumber - 1})`
                : popupGroup.page.name
            }`}
          </Box>
          <URLItem url={popupGroup.page.url} />
        </HStack>

        <HStack justifyContent="flex-end" my={4} spacing={4}></HStack>
        <Flex alignItems="center">
          <Box color="#282828" fontWeight="bold">
            パターン
          </Box>
          <Spacer />
          <HStack spacing={3}>
            <NavLink
              to={`/sites/${siteSlug}/popup_groups/${popupGroupSlug}/patterns/new`}
            >
              <SolidButton>パターン登録</SolidButton>
            </NavLink>
          </HStack>
        </Flex>
        <Flex justifyContent="flex-end" mt={4}>
          <HStack spacing="12px">
            <PopupPatternFilterButton
              filterValues={filterValues}
              onFilterChange={setFilterValues}
              onSubmit={setFilterValues}
            />
            <PopupDeliverWeightButton
              popups={popups}
              deliverKind={popupGroup.deliverKind || "EQUAL"}
              onSubmit={handleDeliverWeightSubmit}
            />
            <TableItemSelectButton
              allTableItems={allTableItems}
              selectedTableItems={selectedTableItems}
              onApplyClick={onApplyClick}
            />
          </HStack>
        </Flex>
        <PopupPatternTable
          popupGroupRef={popupGroup}
          siteSlug={siteSlug}
          filterValues={filterValues}
          headerColumns={headerColumns}
          dataColumns={dataColumns}
          onPopupsChange={setPopups}
          role={viewer.role}
        />
        <PopupGroupDetailSection
          popupGroupRef={popupGroup}
          siteSlug={siteSlug}
        />
      </Box>
    </PageLayout>
  );
};
