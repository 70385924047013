/**
 * @generated SignedSource<<14c056d399a905476607c4e6abb24561>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdatePopupSetInput = {
  clientMutationId?: string | null;
  popupSetId: string;
  name: string;
  memo?: string | null;
};
export type PopupSetEditFormContainer_Mutation$variables = {
  input: UpdatePopupSetInput;
};
export type PopupSetEditFormContainer_MutationVariables = PopupSetEditFormContainer_Mutation$variables;
export type PopupSetEditFormContainer_Mutation$data = {
  readonly updatePopupSet: {
    readonly popupSet: {
      readonly id: string;
      readonly name: string;
      readonly memo: string | null;
    } | null;
  } | null;
};
export type PopupSetEditFormContainer_MutationResponse = PopupSetEditFormContainer_Mutation$data;
export type PopupSetEditFormContainer_Mutation = {
  variables: PopupSetEditFormContainer_MutationVariables;
  response: PopupSetEditFormContainer_Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdatePopupSetPayload",
    "kind": "LinkedField",
    "name": "updatePopupSet",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PopupSet",
        "kind": "LinkedField",
        "name": "popupSet",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "memo",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PopupSetEditFormContainer_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PopupSetEditFormContainer_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a17733fb3566eecd1cbb13246c48b798",
    "id": null,
    "metadata": {},
    "name": "PopupSetEditFormContainer_Mutation",
    "operationKind": "mutation",
    "text": "mutation PopupSetEditFormContainer_Mutation(\n  $input: UpdatePopupSetInput!\n) {\n  updatePopupSet(input: $input) {\n    popupSet {\n      id\n      name\n      memo\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0fe20fd472fc3d3672c7eb4dbfc3cefc";

export default node;
