import { useField, useFormikContext } from "formik";
import { ChangeEvent, FC, useCallback } from "react";

import { FormControl } from "~/src/components/common/forms/FormControl";
import { Textarea } from "~/src/components/common/Textarea";

import { ChatMessageFormValues as FormValues } from "./types";

export const MessageFormField: FC = () => {
  const [{ value, onBlur }, { error, touched }] = useField<string>("message");
  const { setFieldValue } = useFormikContext<FormValues>();

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement> | undefined) => {
      if (!e) return;
      setFieldValue("message", e.target.value);
      setFieldValue(
        "displaySpeed",
        Math.floor(e.target.value.length * 10) / 100
      );
    },
    [setFieldValue]
  );

  return (
    <FormControl label="メッセージ">
      <Textarea
        mode="fill"
        name="message"
        value={value}
        error={touched ? error : undefined}
        onBlur={onBlur}
        onChange={handleChange}
      />
    </FormControl>
  );
};
