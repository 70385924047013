import { Flex } from "@chakra-ui/react";
import { format } from "date-fns";
import { useCallback, useContext, useMemo, useState } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";

import { SiteReportOrder } from "~/src/__generated__/schema";
import { FunnelReportContainer_Query } from "~/src/__relay_artifacts__/FunnelReportContainer_Query.graphql";
import { DateRangeContext } from "~/src/components/features/global/HeaderNavigation/DateRangeField";

import { ReportFilterButton, ReportFilterValues } from "../ReportFilterButton";

import { FunnelReportGraph } from "./FunnelReportGraph";
import { FunnelReportTable } from "./FunnelReportTable";

const query = graphql`
  query FunnelReportContainer_Query($input: ReportInput!, $slug: String!) {
    site(slug: $slug) {
      ...FunnelReportGraph_site
      ...FunnelReportTable_site
      funnels {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

export const FunnelReportContainer = () => {
  const { siteSlug = "" } = useParams();

  const [sort, setSort] = useState<SiteReportOrder>({
    field: "UNIQUE_USER",
    direction: "DESC",
  });

  const [filterValues, setFilterValues] = useState<ReportFilterValues>({
    sourceId: null,
    device: null,
    funnelIds: [],
    pageKind: null,
  });

  const handleSortClick = useCallback((params) => setSort(params), []);

  const {
    dateRange: [startOn, endOn],
  } = useContext(DateRangeContext);

  const queryRef = useLazyLoadQuery<FunnelReportContainer_Query>(query, {
    slug: siteSlug,
    input: {
      startDate: format(startOn, "yyyy-MM-dd"),
      endDate: format(endOn, "yyyy-MM-dd"),
      sourceId: filterValues.sourceId,
      device: filterValues.device,
      funnelIds: filterValues.funnelIds,
      pageKind: filterValues.pageKind,
      orderBy: {
        direction: sort.direction,
        field: sort.field,
      },
    },
  });

  const funnels = useMemo(() => {
    return (
      queryRef.site.funnels.edges?.map((edge) => {
        const node = edge?.node;
        if (!node) throw new Error("assertion failed");
        return { id: node.id, name: node.name };
      }) || []
    );
  }, [queryRef.site.funnels]);

  return (
    <>
      <Flex justifyContent={"end"} mt={4} mb={4}>
        <ReportFilterButton
          filterValues={filterValues}
          onSubmit={setFilterValues}
          onFilterChange={setFilterValues}
        />
      </Flex>
      <FunnelReportGraph siteRef={queryRef.site} funnels={funnels} />
      <FunnelReportTable
        siteRef={queryRef.site}
        onSortClick={handleSortClick}
      />
    </>
  );
};
