/**
 * @generated SignedSource<<b4dbdc1946667c798afbd0d8dae9a87c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UnarchiveArchivableInput = {
  clientMutationId?: string | null;
  archivableId: string;
};
export type UnarchiveButton_Mutation$variables = {
  input: UnarchiveArchivableInput;
};
export type UnarchiveButton_MutationVariables = UnarchiveButton_Mutation$variables;
export type UnarchiveButton_Mutation$data = {
  readonly unarchiveArchivable: {
    readonly archivable: {
      readonly id: string;
      readonly isArchive: boolean;
    };
  } | null;
};
export type UnarchiveButton_MutationResponse = UnarchiveButton_Mutation$data;
export type UnarchiveButton_Mutation = {
  variables: UnarchiveButton_MutationVariables;
  response: UnarchiveButton_Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isArchive",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UnarchiveButton_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UnarchiveArchivablePayload",
        "kind": "LinkedField",
        "name": "unarchiveArchivable",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "archivable",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UnarchiveButton_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UnarchiveArchivablePayload",
        "kind": "LinkedField",
        "name": "unarchiveArchivable",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "archivable",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f3d4fa39eced32c4eecf96638755c845",
    "id": null,
    "metadata": {},
    "name": "UnarchiveButton_Mutation",
    "operationKind": "mutation",
    "text": "mutation UnarchiveButton_Mutation(\n  $input: UnarchiveArchivableInput!\n) {\n  unarchiveArchivable(input: $input) {\n    archivable {\n      __typename\n      id\n      isArchive\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b20be150e1c8580d9532f22ab179f9be";

export default node;
