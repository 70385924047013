import { CheckboxGroup, HStack, Text } from "@chakra-ui/react";
import { useField } from "formik";
import { remove } from "ramda";
import { FC, useCallback } from "react";

import { Checkbox, CheckboxProps } from "~/src/components/common/Checkbox";

export type Props = {
  readonly name: string;
  readonly options: { label: string; value: string }[];
  readonly disabled?: boolean;
};

export const FormikCheckBoxesField: FC<Props> = ({
  name,
  options,
  disabled = false,
}) => {
  const [{ value: values }, , { setValue }] = useField<string[] | null>(name);

  const handleAdd = useCallback(
    (value: string) => {
      setValue((values || []).concat(value));
    },
    [setValue, values]
  );

  const handleRemove = useCallback(
    (value: string) => {
      const index = (values || []).findIndex((val) => val === value);
      if (index === -1) return;

      setValue(remove(index, 1, values || []));
    },
    [setValue, values]
  );

  const handleChange = useCallback<NonNullable<CheckboxProps["onChange"]>>(
    (event) => {
      const value = event.currentTarget.value;

      (values || []).includes(value) ? handleRemove(value) : handleAdd(value);
    },
    [handleAdd, handleRemove, values]
  );

  return (
    <CheckboxGroup defaultValue={values || []}>
      <HStack spacing="8px" wrap={"wrap"}>
        {options.map((option) => (
          <Checkbox
            key={option.value}
            value={option.value}
            isChecked={(values || []).includes(option.value)}
            onChange={handleChange}
            sx={{ minW: "72px", _first: { ml: "8px" } }}
            isDisabled={disabled}
          >
            <Text fontSize="12px">{option.label}</Text>
          </Checkbox>
        ))}
      </HStack>
    </CheckboxGroup>
  );
};
