import { Text } from "@chakra-ui/react";
import { FC } from "react";

import { GraphQLEnums } from "~/src/__generated__/GraphQLEnums";
import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormikCheckboxField } from "~/src/components/common/forms/FormikCheckboxField";
import { FormikRadioButtonsField } from "~/src/components/common/forms/FormikRadioButtonsField";
import { FormikSelectField } from "~/src/components/common/forms/FormikSelectField";
import { FormikTextField } from "~/src/components/common/forms/FormikTextField";
import { FormValues } from "~/src/components/features/popup/PopupGroupPatternForm/validationSchema";

export type Props = {
  values: FormValues;
};

export const PopupDisplayOptionFormContainer: FC<Props> = ({ values }) => {
  return (
    <>
      <FormControl required label="配色デザイン">
        <FormikSelectField
          name="colorKind"
          options={GraphQLEnums.PopupColorKind}
        />
      </FormControl>

      <FormControl label="タイトル">
        <FormikTextField
          type="text"
          mode="fill"
          name="title"
          placeholder="ヘッダー部分に入れるテキストを入力 (HTML可能)"
          rightAdornment={`${values.title?.length || 0}/50`}
        />
      </FormControl>

      <FormControl label="説明文">
        <FormikTextField
          type="text"
          mode="fill"
          name="description"
          placeholder="ボディ部分に入れるテキストを入力 (HTML可能)"
          rightAdornment={`${values.description?.length || "".length}/250`}
        />
      </FormControl>

      <FormControl label="ボタンテキスト">
        <FormikTextField
          type="text"
          mode="fill"
          name="button"
          placeholder="ボタンのテキスト入力"
          rightAdornment={`${values.button?.length || "".length}/18`}
        />
      </FormControl>

      <FormControl label="クリック時のURL">
        <FormikCheckboxField
          name="isInheritQuery"
          label="クエリパラメータを引き継ぐ（※ページの?以降の文字を引き継ぎます）"
        />
        <FormikTextField
          type="text"
          mode="fill"
          name="url"
          placeholder="https://"
        />
        <Text fontSize={"10px"} color={"#979797"}>
          ※ 空欄の場合はポップアップを閉じる挙動になります。
        </Text>
      </FormControl>

      <FormControl label="クリック時のタブの扱い">
        <FormikRadioButtonsField
          name="hrefTarget"
          options={GraphQLEnums.PopupHrefTarget}
        />
      </FormControl>
    </>
  );
};
