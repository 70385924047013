import {
  IconButton as ChakraIconButton,
  IconButtonProps,
} from "@chakra-ui/react";
import { ReactNode, forwardRef } from "react";

import { Tooltip, TooltipProps } from "~/src/components/common/Tooltip";
import { chakraFactory } from "~/src/lib/chakra-ui";

export type Props = {
  className?: string;
  size: "16px" | "24px" | "28px" | "32px" | "40px";
  icon: ReactNode;
  color?: string;
  label?: string;
  tooltipPlacement?: TooltipProps["placement"];
} & Omit<IconButtonProps, "aria-label" | "icon">;

const StyledIconButton = chakraFactory(ChakraIconButton, {
  baseStyle: (props) => ({
    padding: "2px",
    color: "black",
    bg: "transparent",

    "&:hover": {
      bg: "transparent",
      color: "#1867C0",
      opacity: 0.6,
    },

    "&:focus": {
      boxShadow: "none",
    },

    _active: {
      bg: "transparent",
      color: "#1867C0",
    },

    svg: {
      width: props.minWidth,
      height: props.minWidth,
    },
  }),
});

export const IconButton = forwardRef<HTMLButtonElement, Props>(
  (
    { className, size, icon, label, tooltipPlacement = "bottom", ...props },
    ref
  ) => (
    <Tooltip label={label} isDisabled={!label} placement={tooltipPlacement}>
      <StyledIconButton
        {...props}
        minWidth={size}
        width={size}
        height={size}
        aria-label={label}
        className={className}
        ref={ref}
        icon={icon}
      />
    </Tooltip>
  )
);
