/**
 * @generated SignedSource<<1c00745182fa6413efbea74ba2df785a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Device = "MOBILE" | "TABLET" | "PC" | "%future added value";
export type DistributionStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type PageKind = "LANDING" | "FORM" | "CONFIRM" | "THANKS" | "%future added value";
export type UserRole = "SUPER" | "ADMIN_ALL" | "ADMIN" | "JUDGE" | "CLIENT" | "SAAS_CLIENT" | "%future added value";
export type PopupGroupListPageContainer_Query$variables = {
  siteSlug: string;
  pageIds?: ReadonlyArray<string> | null;
  funnelIds?: ReadonlyArray<string> | null;
  pageKind?: PageKind | null;
  sourceId?: string | null;
  device?: Device | null;
  withArchived?: boolean | null;
  status?: DistributionStatus | null;
};
export type PopupGroupListPageContainer_QueryVariables = PopupGroupListPageContainer_Query$variables;
export type PopupGroupListPageContainer_Query$data = {
  readonly viewer: {
    readonly role: UserRole;
  };
  readonly site: {
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"PopupListPage_site">;
  };
};
export type PopupGroupListPageContainer_QueryResponse = PopupGroupListPageContainer_Query$data;
export type PopupGroupListPageContainer_Query = {
  variables: PopupGroupListPageContainer_QueryVariables;
  response: PopupGroupListPageContainer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "device"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "funnelIds"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageIds"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageKind"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "siteSlug"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sourceId"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "withArchived"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v9 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "siteSlug"
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = {
  "kind": "Variable",
  "name": "device",
  "variableName": "device"
},
v12 = {
  "kind": "Variable",
  "name": "funnelIds",
  "variableName": "funnelIds"
},
v13 = {
  "kind": "Variable",
  "name": "pageIds",
  "variableName": "pageIds"
},
v14 = {
  "kind": "Variable",
  "name": "pageKind",
  "variableName": "pageKind"
},
v15 = {
  "kind": "Variable",
  "name": "sourceId",
  "variableName": "sourceId"
},
v16 = {
  "kind": "Variable",
  "name": "status",
  "variableName": "status"
},
v17 = {
  "kind": "Variable",
  "name": "withArchived",
  "variableName": "withArchived"
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v20 = [
  (v11/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 120
  },
  (v12/*: any*/),
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": {
      "direction": "DESC",
      "field": "NAME"
    }
  },
  (v13/*: any*/),
  (v14/*: any*/),
  (v15/*: any*/),
  (v16/*: any*/),
  (v17/*: any*/)
],
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PopupGroupListPageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v8/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          {
            "args": [
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "PopupListPage_site"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/),
      (v7/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Operation",
    "name": "PopupGroupListPageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v18/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          (v19/*: any*/),
          {
            "alias": null,
            "args": (v20/*: any*/),
            "concreteType": "PopupGroupConnection",
            "kind": "LinkedField",
            "name": "popupGroups",
            "plural": false,
            "selections": [
              (v21/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PopupGroupEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PopupGroup",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v19/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endDate",
                        "storageKey": null
                      },
                      (v18/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "memo",
                        "storageKey": null
                      },
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Page",
                        "kind": "LinkedField",
                        "name": "page",
                        "plural": false,
                        "selections": [
                          (v18/*: any*/),
                          (v19/*: any*/),
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "kind",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "sortNumber",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Funnel",
                            "kind": "LinkedField",
                            "name": "funnel",
                            "plural": false,
                            "selections": [
                              (v18/*: any*/),
                              (v10/*: any*/),
                              (v19/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "siteUrl",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isArchive",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "kind",
                            "value": "B_PATTERN"
                          }
                        ],
                        "concreteType": "PopupConnection",
                        "kind": "LinkedField",
                        "name": "popups",
                        "plural": false,
                        "selections": [
                          (v21/*: any*/)
                        ],
                        "storageKey": "popups(kind:\"B_PATTERN\")"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startDate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "thumbnailImageUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "updatedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "judgeStatus",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "kind": "ClientExtension",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__id",
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v20/*: any*/),
            "filters": [
              "searchTerm",
              "pageIds",
              "funnelIds",
              "sourceId",
              "device",
              "withArchived",
              "orderBy",
              "status",
              "pageKind"
            ],
            "handle": "connection",
            "key": "PopupListPage_popupGroups",
            "kind": "LinkedHandle",
            "name": "popupGroups"
          },
          (v18/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8668fe80fae51c020c600ee06e1c69ca",
    "id": null,
    "metadata": {},
    "name": "PopupGroupListPageContainer_Query",
    "operationKind": "query",
    "text": "query PopupGroupListPageContainer_Query(\n  $siteSlug: String!\n  $pageIds: [ID!]\n  $funnelIds: [ID!]\n  $pageKind: PageKind\n  $sourceId: ID\n  $device: Device\n  $withArchived: Boolean\n  $status: DistributionStatus\n) {\n  viewer {\n    role\n    id\n  }\n  site(slug: $siteSlug) {\n    name\n    ...PopupListPage_site_3lVnFj\n    id\n  }\n}\n\nfragment PopupListPage_site_3lVnFj on Site {\n  slug\n  popupGroups(first: 120, pageIds: $pageIds, funnelIds: $funnelIds, sourceId: $sourceId, device: $device, withArchived: $withArchived, orderBy: {field: NAME, direction: DESC}, status: $status, pageKind: $pageKind) {\n    totalCount\n    edges {\n      node {\n        slug\n        ...PopupTableDataRow_popupGroup\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n\nfragment PopupTableDataRow_popupGroup on PopupGroup {\n  endDate\n  id\n  memo\n  name\n  page {\n    id\n    slug\n    name\n    kind\n    sortNumber\n    url\n    funnel {\n      id\n      name\n      slug\n      siteUrl\n    }\n  }\n  isArchive\n  popups(kind: B_PATTERN) {\n    totalCount\n  }\n  slug\n  status\n  startDate\n  thumbnailImageUrl\n  updatedAt\n  judgeStatus\n}\n"
  }
};
})();

(node as any).hash = "66b4d01330c55c7e3434b5cf87410883";

export default node;
