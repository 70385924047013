import { Box, HStack, VStack } from "@chakra-ui/react";
import {
  AddCircle as AddCircleIcon,
  DeleteOutline as DeleteOutlineIcon,
} from "@mui/icons-material";
import { FieldArray, useField } from "formik";
import { FC, useCallback } from "react";

import { SymbolOutlineButton } from "~/src/components/common/Button";
import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormikTextField } from "~/src/components/common/forms/FormikTextField";
import { IconButton } from "~/src/components/common/IconButton";
import { Switch } from "~/src/components/common/Switch";
import { useOpenState } from "~/src/lib/hooks";

export type Props = {
  name: string;
  label: string;
};

export const DeliverUrlListField: FC<Props> = ({ name, label }) => {
  const [deliverUrls, , { setValue }] = useField<string[]>(name);

  const { isOpen, onOpen, onClose } = useOpenState(
    deliverUrls.value ? deliverUrls.value.length > 0 : false
  );

  const handleChange = useCallback(() => {
    if (isOpen) {
      onClose();
      setValue([]);
    } else {
      onOpen();
    }
  }, [isOpen, onClose, onOpen, setValue]);

  return (
    <FieldArray
      name={name}
      // eslint-disable-next-line react/jsx-no-bind
      render={(arrayHelpers) => (
        <FormControl label={label}>
          <Box width="100%" display="block">
            <Switch isChecked={isOpen} onChange={handleChange} />
          </Box>
          <VStack width="100%">
            {isOpen && (
              <>
                {deliverUrls.value &&
                  deliverUrls.value.length > 0 &&
                  deliverUrls.value.map((id, index) => (
                    <HStack key={index} width="100%">
                      <Box flex={1}>
                        <FormikTextField
                          type="text"
                          mode="fill"
                          name={`${name}.${index}.url`}
                          placeholder="https://"
                        />
                      </Box>

                      <Box>
                        <IconButton
                          size="24px"
                          // eslint-disable-next-line react/jsx-no-bind
                          onClick={() => arrayHelpers.remove(index)}
                          icon={<DeleteOutlineIcon />}
                        />
                      </Box>
                    </HStack>
                  ))}
                <Box width="100%">
                  <SymbolOutlineButton
                    icon={<AddCircleIcon />}
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={() => arrayHelpers.push({})}
                  >
                    条件を追加
                  </SymbolOutlineButton>
                </Box>
              </>
            )}
          </VStack>
        </FormControl>
      )}
    />
  );
};
