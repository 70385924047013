import { useToast } from "@chakra-ui/toast";
import { FC, useCallback } from "react";
import { graphql } from "react-relay";

import { UnarchiveButton_Mutation } from "~/src/__relay_artifacts__/UnarchiveButton_Mutation.graphql";
import { OutlineButton } from "~/src/components/common/Button";
import { useMutationCommit } from "~/src/lib/react-relay";

type Props = {
  id: string;
};

const unarchiveMutation = graphql`
  mutation UnarchiveButton_Mutation($input: UnarchiveArchivableInput!) {
    unarchiveArchivable(input: $input) {
      archivable {
        id
        isArchive
      }
    }
  }
`;

export const UnarchiveButton: FC<Props> = ({ id }) => {
  const toast = useToast();

  const mutate = useMutationCommit<UnarchiveButton_Mutation>(unarchiveMutation);

  const handleClick = useCallback(async () => {
    try {
      const res = await mutate({ variables: { input: { archivableId: id } } });
      if (!res.unarchiveArchivable?.archivable)
        throw new Error("assertion failed");

      toast({
        title: "アーカイブから戻しました",
        status: "success",
      });
    } catch (err) {
      toast({
        title: "アーカイブから戻すことに失敗しました",
        status: "error",
      });
    }
  }, [mutate, id, toast]);

  return (
    <OutlineButton onClick={handleClick}>アーカイブから戻す</OutlineButton>
  );
};
