import { Box, Flex } from "@chakra-ui/react";
import { FC } from "react";

import { UserRole } from "~/src/__generated__/schema";
import {
  HEADER_NAVIGATION_HEIGHT,
  HeaderLogo,
  HeaderNavigation,
  HeaderNavigationProps,
  HeaderSiteSelect,
} from "~/src/components/features/global/HeaderNavigation";
import { chakraFactory } from "~/src/lib/chakra-ui";

import { Sidebar, Props as SidebarProps } from "./Sidebar";

export type Props = {
  siteSlug: SidebarProps["siteSlug"];
  companyName: HeaderNavigationProps["companyName"];
  email: HeaderNavigationProps["email"];
  onLogoutClick: HeaderNavigationProps["onLogoutClick"];
  role: UserRole;
};

const StyledContentWrapperBox = chakraFactory(Box, {
  baseStyle: {
    display: "flex",
    flex: "1",
    minHeight: `calc(100% - ${HEADER_NAVIGATION_HEIGHT})`,
  },
});

export const HeaderWithSidebarLayout: FC<Props> = ({
  children,
  siteSlug,
  companyName,
  email,
  onLogoutClick,
  role,
}) => (
  <Flex width="100%" height="100%" flexDirection="column">
    <HeaderNavigation
      companyName={companyName}
      email={email}
      onLogoutClick={onLogoutClick}
    >
      <>
        <HeaderLogo />
        <HeaderSiteSelect siteSlug={siteSlug} />
      </>
    </HeaderNavigation>
    <StyledContentWrapperBox>
      <Sidebar siteSlug={siteSlug} role={role} />
      <Box flex="1" height="100%" overflow="auto">
        {children}
      </Box>
    </StyledContentWrapperBox>
  </Flex>
);
