/**
 * @generated SignedSource<<d911b6e132511ecb30de145cbfaa9263>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FunnelSourceEditPageContainer_Query$variables = {
  funnelSlug: string;
  sourceTagSlug: string;
};
export type FunnelSourceEditPageContainer_QueryVariables = FunnelSourceEditPageContainer_Query$variables;
export type FunnelSourceEditPageContainer_Query$data = {
  readonly funnel: {
    readonly " $fragmentSpreads": FragmentRefs<"FunnelSourceEditFormContainer_funnel">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"FunnelSourceEditFormContainer_root">;
};
export type FunnelSourceEditPageContainer_QueryResponse = FunnelSourceEditPageContainer_Query$data;
export type FunnelSourceEditPageContainer_Query = {
  variables: FunnelSourceEditPageContainer_QueryVariables;
  response: FunnelSourceEditPageContainer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "funnelSlug"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sourceTagSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "funnelSlug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FunnelSourceEditPageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Funnel",
        "kind": "LinkedField",
        "name": "funnel",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "FunnelSourceEditFormContainer_funnel"
          }
        ],
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "FunnelSourceEditFormContainer_root"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FunnelSourceEditPageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Funnel",
        "kind": "LinkedField",
        "name": "funnel",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "slug",
                "variableName": "sourceTagSlug"
              }
            ],
            "concreteType": "SourceTag",
            "kind": "LinkedField",
            "name": "sourceTag",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Source",
                "kind": "LinkedField",
                "name": "source",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pageUrl",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SourceConnection",
        "kind": "LinkedField",
        "name": "sources",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SourceEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Source",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cc49ccbc4c1370fb471d02af8517749d",
    "id": null,
    "metadata": {},
    "name": "FunnelSourceEditPageContainer_Query",
    "operationKind": "query",
    "text": "query FunnelSourceEditPageContainer_Query(\n  $funnelSlug: String!\n  $sourceTagSlug: String!\n) {\n  funnel(slug: $funnelSlug) {\n    ...FunnelSourceEditFormContainer_funnel\n    id\n  }\n  ...FunnelSourceEditFormContainer_root\n}\n\nfragment FunnelSourceEditFormContainer_funnel on Funnel {\n  sourceTag(slug: $sourceTagSlug) {\n    id\n    key\n    value\n    slug\n    source {\n      id\n      name\n    }\n    pageUrl\n  }\n}\n\nfragment FunnelSourceEditFormContainer_root on Query {\n  sources {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ac3f4bfcdcf1890108beff0ca35df9fe";

export default node;
