/**
 * @generated SignedSource<<6eb17973be626fbff086df52e7e11ed5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChatbotEditScreenContainer_site$data = {
  readonly id: string;
  readonly name: string;
  readonly slug: string;
  readonly " $fragmentSpreads": FragmentRefs<"ChatbotFirstStepForm_site">;
  readonly " $fragmentType": "ChatbotEditScreenContainer_site";
};
export type ChatbotEditScreenContainer_site = ChatbotEditScreenContainer_site$data;
export type ChatbotEditScreenContainer_site$key = {
  readonly " $data"?: ChatbotEditScreenContainer_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChatbotEditScreenContainer_site">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChatbotEditScreenContainer_site",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChatbotFirstStepForm_site"
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "7fe706ebea54cc8a25cad8aeed336e9e";

export default node;
