import { Box, Flex, HStack, Spacer, VStack } from "@chakra-ui/react";
import { FC } from "react";

import { OutlineButton } from "~/src/components/common/Button";
import { DefinitionTeam } from "~/src/components/common/DefinitionTeam";
import { Modal } from "~/src/components/common/Modal";
import { SectionCard } from "~/src/components/common/SectionCard";
import { PopupGroupSetEditFormContainer } from "~/src/components/features/popupSet";
import { useOpenState } from "~/src/lib/hooks";

type Props = {
  popupGroupSet: { id: string; name: string; memo: string | null };
};

export const PopupGroupSetDetailSection: FC<Props> = ({ popupGroupSet }) => {
  const { isOpen, onClose, onOpen } = useOpenState();

  return (
    <>
      <Flex alignItems="center">
        <Box color="#282828" fontWeight="bold">
          基本設定
        </Box>
        <Spacer />
        <HStack spacing={3}>
          <OutlineButton onClick={onOpen}>編集</OutlineButton>
        </HStack>
      </Flex>

      <VStack mt={4}>
        <SectionCard>
          <DefinitionTeam label="メモ">
            <Box wordBreak={"break-all"}>{popupGroupSet.memo || "未設定"}</Box>
          </DefinitionTeam>
        </SectionCard>
      </VStack>

      <Modal title="ポップアップセットの編集" isOpen={isOpen} onClose={onClose}>
        <PopupGroupSetEditFormContainer
          onClose={onClose}
          popupGroupSet={popupGroupSet}
        />
      </Modal>
    </>
  );
};
