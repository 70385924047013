import { FC } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";

import { FunnelSourceEditPageContainer_Query } from "~/src/__relay_artifacts__/FunnelSourceEditPageContainer_Query.graphql";
import { FunnelSourceEditFormContainer } from "~/src/components/features/settings/FunnelSourceForms";
import { useFunnelSourceEditPageParams } from "~/src/routes/hooks";

const query = graphql`
  query FunnelSourceEditPageContainer_Query(
    $funnelSlug: String!
    $sourceTagSlug: String!
  ) {
    funnel(slug: $funnelSlug) {
      ...FunnelSourceEditFormContainer_funnel
    }
    ...FunnelSourceEditFormContainer_root
  }
`;

export const FunnelSourceEditPageContainer: FC = () => {
  const { siteSlug, funnelSlug, sourceTagSlug } =
    useFunnelSourceEditPageParams();

  const queryRef = useLazyLoadQuery<FunnelSourceEditPageContainer_Query>(
    query,
    {
      funnelSlug,
      sourceTagSlug,
    }
  );

  return (
    <FunnelSourceEditFormContainer
      siteSlug={siteSlug}
      funnelSlug={funnelSlug}
      sourceTagSlug={sourceTagSlug}
      funnelRef={queryRef.funnel}
      sourceRef={queryRef}
    />
  );
};
