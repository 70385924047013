import { Flex, SimpleGrid } from "@chakra-ui/react";
import { format } from "date-fns";
import { FC, useCallback, useContext, useMemo, useState } from "react";
import { graphql, useRefetchableFragment } from "react-relay";
import { useParams } from "react-router-dom";

import { ReportListContainer_query$key } from "~/src/__relay_artifacts__/ReportListContainer_query.graphql";
import {
  ReportListContainerRefetchQuery as RefetchQuery,
  ReportListContainerRefetchQuery$variables as RefetchVariables,
} from "~/src/__relay_artifacts__/ReportListContainerRefetchQuery.graphql";
import { DateRangeContext } from "~/src/components/features/global/HeaderNavigation/DateRangeField";
import { useUpdateDebounce } from "~/src/lib/react-use";

import {
  DashboardFilterButton,
  FormValues as DashboardFilterFormValues,
} from "./DashboardFilterButton";
import { FunnelTableSection } from "./FunnelTableSection";
import { FunnelTransitionReportSection } from "./FunnelTransitionReportSection";

export type Props = {
  queryRef: ReportListContainer_query$key;
};

const fragment = graphql`
  fragment ReportListContainer_query on Query
  @refetchable(queryName: "ReportListContainerRefetchQuery") {
    site(slug: $slug) {
      ...FunnelTransitionReportSection_site
      ...FunnelTableSection_site
    }
  }
`;

export const ReportListContainer: FC<Props> = ({ queryRef }) => {
  const { siteSlug = "" } = useParams();
  const [filterValues, setFilterValues] = useState<DashboardFilterFormValues>({
    sourceId: null,
    device: null,
    funnelId: null,
    pageId: null,
  });
  const [data, refetch] = useRefetchableFragment<
    RefetchQuery,
    ReportListContainer_query$key
  >(fragment, queryRef);
  const {
    dateRange: [startOn, endOn],
  } = useContext(DateRangeContext);

  const refetchVariables = useMemo<Partial<RefetchVariables>>(
    () => ({
      slug: siteSlug,
      analyzerInput: {
        startOn: format(startOn, "yyyy-MM-dd"),
        endOn: format(endOn, "yyyy-MM-dd"),
        funnelId: filterValues.funnelId,
        sourceId: filterValues.sourceId,
        device: filterValues.device,
      },
    }),
    [
      siteSlug,
      startOn,
      endOn,
      filterValues.funnelId,
      filterValues.sourceId,
      filterValues.device,
    ]
  );

  const handleRefetch = useCallback(() => {
    refetch(refetchVariables);
  }, [refetch, refetchVariables]);

  useUpdateDebounce(
    () => {
      handleRefetch();
    },
    100,
    [handleRefetch, refetchVariables]
  );

  return (
    <>
      <Flex justifyContent={"end"} mt={4} mb={4}>
        <DashboardFilterButton
          filterValues={filterValues}
          onSubmit={setFilterValues}
          onFilterChange={setFilterValues}
        />
      </Flex>
      <SimpleGrid columns={1} spacing={8} mt={3}>
        <FunnelTableSection siteRef={data.site} />
        <FunnelTransitionReportSection siteRef={data.site} />
      </SimpleGrid>
    </>
  );
};
