import { FC, useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { ABTestDistributionSettingFields_root$key } from "~/src/__relay_artifacts__/ABTestDistributionSettingFields_root.graphql";
import {
  DeliverUrlListField,
  ReferrerListField,
  SelectResourceListField,
  TargetCookieListField,
  VisitCountField,
} from "~/src/components/features/global/DistributionSetingForm";

type Props = {
  rootRef: ABTestDistributionSettingFields_root$key;
};

const fragment = graphql`
  fragment ABTestDistributionSettingFields_root on Query {
    sources {
      edges {
        node {
          id
          name
        }
      }
    }
    viewer {
      userLabels {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

export const ABTestDistributionSettingFields: FC<Props> = ({ rootRef }) => {
  const { sources, viewer: user } = useFragment(fragment, rootRef);

  const sourceOptions = useMemo(
    () =>
      sources.edges?.map((edge) => {
        const node = edge?.node;
        if (!node) throw new Error("assertion failed");
        return { label: node.name, value: node.id };
      }) || [],
    [sources.edges]
  );

  const userLabelOptions = useMemo(
    () =>
      user.userLabels.edges?.map((edge) => {
        const node = edge?.node;
        if (!node) throw new Error("assertion failed");
        return { label: node.name || "", value: node.id };
      }) || [],
    [user.userLabels.edges]
  );

  return (
    <>
      <SelectResourceListField
        name="sourceIds"
        label="表示条件に流入元カテゴリを追加する"
        options={sourceOptions}
      />
      <SelectResourceListField
        name="excludeSourceIds"
        label="非表示条件に流入元カテゴリを追加する"
        options={sourceOptions}
      />
      <ReferrerListField name="referrers" label="表示条件に流入元を追加する" />
      <ReferrerListField
        name="excludeReferrers"
        label="表示条件に除外流入元を追加する"
      />
      <VisitCountField label="表示条件にユーザーの訪問回数を追加" />
      <DeliverUrlListField
        name="deliverTargetUrls"
        label="表示条件に該当ページを閲覧したユーザーを追加"
      />
      <DeliverUrlListField
        name="deliverExcludeUrls"
        label="非表示条件に該当ページを閲覧したユーザーを追加"
      />
      <SelectResourceListField
        name="deliverTargetLabelIds"
        label="表示条件にラベルを追加する"
        options={userLabelOptions}
      />
      <SelectResourceListField
        name="deliverExcludeLabelIds"
        label="表示条件に除外ラベルを追加する"
        options={userLabelOptions}
      />
      <TargetCookieListField name="targetCookies" label="Cookieの条件を追加" />
    </>
  );
};
