import { useFormikContext } from "formik";
import { FC, useEffect } from "react";

import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormikTextField } from "~/src/components/common/forms/FormikTextField";

import { FormValues } from "./validationSchema";

export const WeightFields: FC = () => {
  const { values, setValues } = useFormikContext<FormValues>();

  useEffect(() => {
    setValues((currentValues) => ({
      ...currentValues,
      deliverWeights: currentValues.deliverWeights.map((d) => ({
        ...d,
        weight: 1,
      })),
    }));
  }, [setValues, values.deliverKind]);

  return (
    <>
      {values.deliverWeights.map((deliverWeight, i) => (
        <FormControl key={deliverWeight.id} label={deliverWeight.name}>
          <FormikTextField
            type="number"
            mode="fill"
            name={`deliverWeights.${i}.weight`}
            disabled={values.deliverKind === "EQUAL"}
          />
        </FormControl>
      ))}
    </>
  );
};
