import { groupBy, includes, prop, uniqBy } from "ramda";
import { FC, useCallback, useMemo, useState } from "react";
import { graphql, useFragment } from "react-relay";

import { GraphQLEnums } from "~/src/__generated__/GraphQLEnums";
import { AgeRange } from "~/src/__generated__/schema";
import { AgeRangeReportGraph_site$key } from "~/src/__relay_artifacts__/AgeRangeReportGraph_site.graphql";

import { AreaGraph } from "../AreaGraph";
import {
  MeasureKey,
  findGraphTitleByMeasureKey,
  generateGraphDataArray,
  isMeasureKey,
  measureOptions,
} from "../utils";

const fragment = graphql`
  fragment AgeRangeReportGraph_site on Site {
    reportAgeRange(input: $input)
  }
`;

export type Props = {
  siteRef: AgeRangeReportGraph_site$key;
};

type FormValues = {
  ageRanges: AgeRange[];
  measureKey: MeasureKey | null;
};

export const AgeRangeReportGraph: FC<Props> = ({ siteRef }) => {
  const { reportAgeRange } = useFragment<typeof siteRef>(fragment, siteRef);

  const [formValues, setFormValues] = useState<FormValues>({
    ageRanges: [],
    measureKey: null,
  });

  const handleSubmit = useCallback(({ ageRanges, measureKey }) => {
    if (measureKey === null || isMeasureKey(measureKey, measureOptions)) {
      setFormValues({ ageRanges, measureKey });
    }
  }, []);

  const ageRangeOptions = useMemo(() => {
    const uniqueAgeRanges = uniqBy<any, any>(
      prop("age_bracket"),
      reportAgeRange.daily_report
    );
    return uniqueAgeRanges.map((row) => ({
      value: row.age_bracket,
      label: GraphQLEnums.AgeRange.find(
        (ageRange) => ageRange.label === row.age_bracket
      )?.label,
    }));
  }, [reportAgeRange]);

  const graphDataArray = useMemo(() => {
    if (formValues.ageRanges.length === 0) {
      return generateGraphDataArray(
        [groupBy<any, any>(prop("access_date"), reportAgeRange.daily_report)],
        formValues.measureKey || "UNIQUE_USER"
      );
    }
    const reports = reportAgeRange.daily_report as any[];
    const filteredReports = reports.filter((row) =>
      includes(row.age_bracket, formValues.ageRanges)
    );

    const reportsByAgeRange = groupBy<any, any>(
      prop("age_bracket"),
      filteredReports
    );

    const reportsByAgeRangeAndDate: Record<any, any[]>[] = [];
    Object.keys(reportsByAgeRange).forEach((key) => {
      reportsByAgeRangeAndDate.push(
        groupBy<any, any>(prop("access_date"), reportsByAgeRange[key])
      );
    });

    return generateGraphDataArray(
      reportsByAgeRangeAndDate,
      formValues.measureKey || "UNIQUE_USER"
    );
  }, [reportAgeRange, formValues]);

  return (
    <AreaGraph
      title={`日別推移｜${findGraphTitleByMeasureKey(formValues.measureKey)}`}
      label={`${findGraphTitleByMeasureKey(formValues.measureKey)}`}
      dataArray={graphDataArray}
      graphCustomizedButtonProps={{
        selectFields: [
          {
            name: "ageRanges",
            label: "年齢",
            options: ageRangeOptions,
            isMultiple: true,
          },
          {
            name: "measureKey",
            label: "項目",
            options: measureOptions,
            isMultiple: false,
          },
        ],
        formValues,
        onSubmit: handleSubmit,
      }}
    />
  );
};
