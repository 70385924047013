import { HStack, VStack } from "@chakra-ui/react";
import { HeightOutlined as HeightIcon } from "@mui/icons-material";
import { Form, Formik } from "formik";
import { FC, useCallback } from "react";

import { SymbolSolidButton } from "~/src/components/common/Button";
import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormikFormButtons } from "~/src/components/common/forms/FormikFormButtons";
import { FormikTextField } from "~/src/components/common/forms/FormikTextField";
import { Modal } from "~/src/components/common/Modal";
import { useOpenState } from "~/src/lib/hooks";

type FormValues = {
  rowHeight: number;
};

type Props = {
  formValues: FormValues;
  onSubmit: (formValues: FormValues) => Promise<void> | void;
};

export const RowHeightSettingButton: FC<Props> = ({ formValues, onSubmit }) => {
  const { isOpen, onClose, onOpen } = useOpenState();

  const handleSubmit = useCallback(
    (values: FormValues) => {
      onSubmit(values);
      onClose();
    },
    [onClose, onSubmit]
  );

  return (
    <HStack>
      <SymbolSolidButton
        size="24px"
        isActive={isOpen}
        icon={<HeightIcon />}
        onClick={onOpen}
      >
        テーブルの行の高さを変える
      </SymbolSolidButton>
      <Modal title="フィルタ" isOpen={isOpen} onClose={onClose}>
        <Formik<FormValues>
          enableReinitialize
          initialValues={formValues}
          validateOnMount={false}
          validateOnChange={false}
          onSubmit={handleSubmit}
        >
          <Form>
            <VStack spacing="16px">
              <FormControl label={"高さ"}>
                <FormikTextField name="rowHeight" type="number" mode="fill" />
              </FormControl>
            </VStack>
            <FormikFormButtons onCancelClick={onClose} />
          </Form>
        </Formik>
      </Modal>
    </HStack>
  );
};
