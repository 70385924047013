import { FC } from "react";
import { RelayEnvironmentProvider as EnvironmentProvider } from "react-relay/hooks";

import { useEnvironment } from "./useEnvironment";

type Props = {
  apiKey: string;
};

export const RelayEnvironmentProvider: FC<Props> = ({ children, apiKey }) => {
  const { environment } = useEnvironment({ apiKey });

  return (
    <EnvironmentProvider environment={environment}>
      {children}
    </EnvironmentProvider>
  );
};
