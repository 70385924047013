import MUIDateRangePicker, { DateRange } from "@mui/lab/DateRangePicker";
import { ExportedDateRangePickerInputProps as PickerInputProps } from "@mui/lab/DateRangePicker/DateRangePickerInput";
import { format } from "date-fns";
import * as React from "react";

import { MUIPickerProvider } from "../MUIPickerProvider";

import { DateRangePickerInput } from "./presentations";

type Props = {
  value: [Date, Date];
  isOpen: boolean;
  onClose: () => void;
  onClick: () => void;
  onChange: (value: DateRange<Date>) => void;
  onAccept?: (value: DateRange<Date>) => void;
};

export const DateRangePicker: React.FC<Props> = ({
  value,
  isOpen,
  onClose,
  onClick,
  onChange,
  onAccept,
}) => {
  const handleRender = React.useCallback<PickerInputProps["renderInput"]>(
    (startProps, endProps) => (
      <DateRangePickerInput
        label={`${format(value[0], "yyyy/MM/dd")} - ${format(
          value[1],
          "yyyy/MM/dd"
        )}`}
        startProps={startProps}
        endProps={endProps}
        onClick={onClick}
      />
    ),
    [onClick, value]
  );

  return (
    <MUIPickerProvider>
      <MUIDateRangePicker
        value={value}
        onChange={onChange}
        renderInput={handleRender}
        open={isOpen}
        onClose={onClose}
        onAccept={onAccept}
        mask="____/__/__"
      />
    </MUIPickerProvider>
  );
};
