import { Box } from "@chakra-ui/react";
import { FC } from "react";

import { Tab, TabProps } from "~/src/components/common/Tab";
import { PageLayout } from "~/src/components/features/global/PageLayout";
import { chakraFactory } from "~/src/lib/chakra-ui";

export type Props = {
  tabs: TabProps["tabs"];
  currentTabId: string;
  onTabChange: (tab: Props["tabs"][0]["id"]) => void;
};

const StyledTab = chakraFactory(Tab, {
  baseStyle: {
    mt: "10px",
  },
});

export const ReportPage: FC<Props> = ({
  children,
  tabs,
  currentTabId,
  onTabChange,
}) => (
  <PageLayout title="設定">
    <Box mt="10px">
      <StyledTab
        tabs={tabs}
        currentTabId={currentTabId}
        onClick={onTabChange}
      />
      {children}
    </Box>
  </PageLayout>
);
