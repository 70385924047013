import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/accordion";
import Linkify from "linkify-react";
import { FC } from "react";
import { graphql, useFragment } from "react-relay";

import { NotificationColumn_notification$key } from "~/src/__relay_artifacts__/NotificationColumn_notification.graphql";
import { chakraFactory } from "~/src/lib/chakra-ui";

import { ROW_HEIGHT } from "./styles";

type Props = {
  notificationRef: NotificationColumn_notification$key;
};

const fragment = graphql`
  fragment NotificationColumn_notification on Notification {
    title
    body
  }
`;

const StyledAccordionItem = chakraFactory(AccordionItem, {
  baseStyle: {
    borderWidth: "0px",
    "&:last-of-type": {
      borderBottomWidth: "0px",
    },
    a: {
      color: "#1867C0",
    },
  },
});

const StyledAccordionButton = chakraFactory(AccordionButton, {
  baseStyle: {
    height: ROW_HEIGHT,
    justifyContent: "space-between",
    fontSize: "unset",
    color: "#1867C0",
    _focus: {
      boxShadow: "unset",
    },
  },
});

export const NotificationColumn: FC<Props> = ({ notificationRef }) => {
  const notification = useFragment(fragment, notificationRef);

  return (
    <Accordion allowToggle sx={{ width: "100%", height: "100%" }}>
      <StyledAccordionItem>
        <StyledAccordionButton>
          {notification.title}
          <AccordionIcon />
        </StyledAccordionButton>
        <AccordionPanel borderTop="1px solid #DADADA">
          <Linkify options={{ nl2br: true }}>{notification.body}</Linkify>
        </AccordionPanel>
      </StyledAccordionItem>
    </Accordion>
  );
};
