import { Text } from "@chakra-ui/react";
import { useField } from "formik";
import { FC, ReactNode } from "react";

import { Checkbox } from "~/src/components/common/Checkbox";

export type Props = {
  name: string;
  label?: string;
  children?: ReactNode;
};

export const FormikCheckboxField: FC<Props> = ({ children, name, label }) => {
  const [{ value, onChange }] = useField<boolean>(name);

  return (
    <Checkbox name={name} isChecked={value} onChange={onChange}>
      {label && <Text fontSize="13px">{label}</Text>}
      {children}
    </Checkbox>
  );
};
