import { FC } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";

import { ABTestEditPageContainer_Query } from "~/src/__relay_artifacts__/ABTestEditPageContainer_Query.graphql";

import { ABTestEditScreenContainer } from "./ABTestEditScreenContainer";

export type Props = {};

const query = graphql`
  query ABTestEditPageContainer_Query(
    $abtestScenarioPageGroupSlug: String!
    $siteSlug: String!
  ) {
    abtestScenarioPageGroup(slug: $abtestScenarioPageGroupSlug) {
      ...ABTestEditScreenContainer_abtestScenarioPageGroup
    }
    site(slug: $siteSlug) {
      ...ABTestEditScreenContainer_site
    }
  }
`;

export const ABTestEditPageContainer: FC<Props> = () => {
  const { abtestSlug = "", siteSlug = "" } = useParams();

  const { abtestScenarioPageGroup, site } =
    useLazyLoadQuery<ABTestEditPageContainer_Query>(query, {
      abtestScenarioPageGroupSlug: abtestSlug,
      siteSlug,
    });

  if (!site || !abtestScenarioPageGroup) throw new Error("assertion failed");

  return (
    <ABTestEditScreenContainer
      abtestScenarioPageGroupRef={abtestScenarioPageGroup}
      siteRef={site}
    />
  );
};
