import { ArrowDropDownOutlined as ArrowDropDownOutlinedIcon } from "@mui/icons-material";
import { ExportedDateRangePickerInputProps as PickerInputProps } from "@mui/lab/DateRangePicker/DateRangePickerInput";
import * as React from "react";

import { OutlineButton } from "~/src/components/common/Button";
import { chakraFactory } from "~/src/lib/chakra-ui";

type Props = {
  label: string;
  startProps: Parameters<PickerInputProps["renderInput"]>[0];
  endProps: Parameters<PickerInputProps["renderInput"]>[1];
  onClick: () => void;
};

const InvisibleInput = chakraFactory("input", {
  baseStyle: {
    display: "none",
  },
});

const StyledButton = chakraFactory(OutlineButton, {
  baseStyle: {
    width: "230px",
    height: "40px",
    borderWidth: "0px 0px 1px",
    borderColor: "#DADADA",
    bgColor: "unset",
    color: "#282828",
  },
});

export const DateRangePickerInput: React.FC<Props> = ({
  label,
  startProps,
  endProps,
  onClick,
}) => {
  return (
    <>
      <InvisibleInput
        ref={startProps.inputRef as React.Ref<HTMLInputElement>}
        {...startProps.inputProps}
      />
      <InvisibleInput
        ref={endProps.inputRef as React.Ref<HTMLInputElement>}
        {...endProps.inputProps}
      />
      <StyledButton onClick={onClick}>
        {label}
        <ArrowDropDownOutlinedIcon />
      </StyledButton>
    </>
  );
};
