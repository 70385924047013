import { useField } from "formik";
import { FC } from "react";

import { TextField, TextFieldProps } from "~/src/components/common/TextField";

export type Props = {
  mode: TextFieldProps["mode"];
  type: TextFieldProps["type"];
  name: TextFieldProps["name"];
  placeholder?: TextFieldProps["placeholder"];
  rightAdornment?: TextFieldProps["rightAdornment"];
  disabled?: boolean;
};

export const FormikTextField: FC<Props> = ({
  mode,
  type,
  name,
  placeholder,
  rightAdornment,
  disabled = false,
}) => {
  const [{ value, onBlur, onChange }, { error, touched }] =
    useField<string>(name);

  return (
    <TextField
      type={type}
      mode={mode}
      name={name}
      value={value}
      placeholder={placeholder}
      rightAdornment={rightAdornment}
      error={touched ? error : undefined}
      disabled={disabled}
      onBlur={onBlur}
      onChange={onChange}
    />
  );
};
