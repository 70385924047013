import { Navigate, Route, Routes } from "react-router-dom";

import { ABTestCreatePageContainer } from "~/src/components/pages/ABTestCreatePage";
import { ABTestDetailPageContainer } from "~/src/components/pages/ABTestDetailPage";
import { ABTestEditPageContainer } from "~/src/components/pages/ABTestEditPage";
import { ABTestListPageContainer } from "~/src/components/pages/ABTestListPage";
import { ABTestPatternCreatePageContainer } from "~/src/components/pages/ABTestPatternCreatePage";
import { ABTestPatternDetailPageContainer } from "~/src/components/pages/ABTestPatternDetailPage";
import { ABTestPatternEditPageContainer } from "~/src/components/pages/ABTestPatternEditPage";
import { AccountDetailPageContainer } from "~/src/components/pages/AccountDetailPage";
import { AccountListPageContainer } from "~/src/components/pages/AccountListPage";
import { AccountSettingsPageContainer } from "~/src/components/pages/AccountSettingsPage";
import { ChatbotCreatePageContainer } from "~/src/components/pages/ChatbotCreatePage";
import { ChatbotDetailPageContainer } from "~/src/components/pages/ChatbotDetailPage";
import { ChatbotEditPageContainer } from "~/src/components/pages/ChatbotEditPage";
import { ChatbotListPageContainer } from "~/src/components/pages/ChatbotListPage";
import { ChatbotPatternCreatePageContainer } from "~/src/components/pages/ChatbotPatternCreatePage";
import { ChatbotPatternDetailPageContainer } from "~/src/components/pages/ChatbotPatternDetailPage";
import { ChatbotPatternEditPageContainer } from "~/src/components/pages/ChatbotPatternEditPage";
import { DashboardPageContainer } from "~/src/components/pages/DashboardPage";
import { EmbedTagPage } from "~/src/components/pages/EmbedTagPage";
import { ExcludedIPCreatePageContainer } from "~/src/components/pages/ExcludedIPCreatePage";
import { ExcludedIPEditPageContainer } from "~/src/components/pages/ExcludedIPEditPage";
import { ForgotPasswordPage } from "~/src/components/pages/ForgotPasswordPage";
import { FunnelBulkPageContainer } from "~/src/components/pages/FunnelBulkPage";
import { FunnelCreatePageContainer } from "~/src/components/pages/FunnelCreatePage";
import { FunnelDetailPageContainer } from "~/src/components/pages/FunnelDetailPage";
import { FunnelEditPageContainer } from "~/src/components/pages/FunnelEditPage";
import { FunnelSourceCreatePageContainer } from "~/src/components/pages/FunnelSourceCreatePage";
import { FunnelSourceEditPageContainer } from "~/src/components/pages/FunnelSourceEditPage";
import { FunnelSourceListPageContainer } from "~/src/components/pages/FunnelSourceListPage";
import { JudgementListPageContainer } from "~/src/components/pages/JudgementListPage";
import { NotificationCreatePageContainer } from "~/src/components/pages/NotificationCreatePage";
import { NotificationEditPageContainer } from "~/src/components/pages/NotificationEditPage";
import { NotificationListPageContainer } from "~/src/components/pages/NotificationListPage";
import { PopupGroupCreatePageContainer } from "~/src/components/pages/PopupGroupCreatePage";
import { PopupGroupDetailPageContainer } from "~/src/components/pages/PopupGroupDetailPage";
import { PopupGroupEditPageContainer } from "~/src/components/pages/PopupGroupEditPage";
import { PopupGroupListPageContainer } from "~/src/components/pages/PopupGroupListPage";
import { PopupGroupPatternCreatePageContainer } from "~/src/components/pages/PopupGroupPatternCreatePage";
import { PopupGroupPatternDetailPageContainer } from "~/src/components/pages/PopupGroupPatternDetailPage";
import { PopupGroupPatternEditPageContainer } from "~/src/components/pages/PopupGroupPatternEditPage";
import { PopupGroupSetDetailPageContainer } from "~/src/components/pages/PopupGroupSetDetailPage";
import { PopupGroupSetListPageContainer } from "~/src/components/pages/PopupGroupSetListPage";
import { PopupSetDetailPageContainer } from "~/src/components/pages/PopupSetDetailPage";
import { PopupSetListPageContainer } from "~/src/components/pages/PopupSetListPage";
import { ReportPageContainer } from "~/src/components/pages/ReportPage";
import { SettingsPageContainer } from "~/src/components/pages/SettingsPage";
import { SigninPage } from "~/src/components/pages/SigninPage";
import { SignupPage } from "~/src/components/pages/SignupPage";
import { SiteListPageContainer } from "~/src/components/pages/SiteListPage";
import { SourceCreatePageContainer } from "~/src/components/pages/SourceCreatePage";
import { SourceEditPageContainer } from "~/src/components/pages/SourceEditPage";
import { useUser } from "~/src/contexts";

import { HeaderOnlyLayoutOutlet } from "./HeaderOnlyLayoutOutlet";
import { HeaderWithSidebarLayoutOutlet } from "./HeaderWithSidebarLayoutOutlet";

export const RootRoutes = () => {
  const userContext = useUser();

  if (userContext === null) throw new Error("user context must be set.");

  const { user, loadingUser } = userContext;

  if (loadingUser) return <>Loading...</>;

  return user !== null ? (
    <Routes>
      <Route path="/notifications" element={<HeaderOnlyLayoutOutlet />}>
        {/* 0-0 お知らせ一覧 */}
        <Route index element={<NotificationListPageContainer />} />

        {/* 1-2 お知らせ追加 */}
        <Route
          path="/notifications/new"
          element={<NotificationCreatePageContainer />}
        />

        {/* 1-2 お知らせ編集 */}
        <Route
          path="/notifications/:notificationSlug/edit"
          element={<NotificationEditPageContainer />}
        />

        <Route path="*" element={<Navigate to="/" />} />
      </Route>

      <Route
        path="/sites/:siteSlug"
        element={<HeaderWithSidebarLayoutOutlet />}
      >
        {/* 0-2 ダッシュボードページ */}
        <Route index element={<DashboardPageContainer />} />

        {/* 6-1 ポップアップ一覧 */}
        <Route
          path="/sites/:siteSlug/popup_groups"
          element={<PopupGroupListPageContainer />}
        />

        {/* 6-2-1 ポップアップ作成 */}
        <Route
          path="/sites/:siteSlug/popup_groups/new"
          element={<PopupGroupCreatePageContainer />}
        />

        {/* 6-3 ポップアップグループ詳細 */}
        <Route
          path="/sites/:siteSlug/popup_groups/:popupGroupSlug"
          element={<PopupGroupDetailPageContainer />}
        />

        {/* 6-4 ポップアップグループ編集 */}
        <Route
          path="/sites/:siteSlug/popup_groups/:popupGroupSlug/edit"
          element={<PopupGroupEditPageContainer />}
        />

        {/* 6-8 ポップアップグループパターン作成 */}
        <Route
          path="/sites/:siteSlug/popup_groups/:popupGroupSlug/patterns/new"
          element={<PopupGroupPatternCreatePageContainer />}
        />

        {/* 6-7 ポップアップグループパターン詳細 */}
        <Route
          path="/sites/:siteSlug/popup_groups/:popupGroupSlug/patterns/:patternSlug"
          element={<PopupGroupPatternDetailPageContainer />}
        />

        {/* 6-8 ポップアップグループパターン編集 */}
        <Route
          path="/sites/:siteSlug/popup_groups/:popupGroupSlug/patterns/:patternSlug/edit"
          element={<PopupGroupPatternEditPageContainer />}
        />

        {/* List */}
        <Route
          path="/sites/:siteSlug/popup_group_sets"
          element={<PopupGroupSetListPageContainer />}
        />

        {/* Detail */}
        <Route
          path="/sites/:siteSlug/popup_group_sets/:popupGroupSetId"
          element={<PopupGroupSetDetailPageContainer />}
        />

        <Route
          path="/sites/:siteSlug/popup_group_sets/:popupGroupSetId/popup_sets"
          element={<PopupSetListPageContainer />}
        />

        <Route
          path="/sites/:siteSlug/popup_group_sets/:popupGroupSetId/popup_sets/:popupSetId"
          element={<PopupSetDetailPageContainer />}
        />

        {/* <Route
          path="/sites/:siteSlug/popup_group_sets/:popupGroupSetId/popup_sets/new"
          element={<PopupGroupListPageContainer />}
        />

        <Route
          path="/sites/:siteSlug/popup_group_sets/:popupGroupSetId/popup_sets/:popupSetId/edit"
          element={<PopupGroupListPageContainer />}
        /> */}

        {/* 7-1 ABテスト一覧 */}
        <Route
          path="/sites/:siteSlug/abtests"
          element={<ABTestListPageContainer />}
        />

        {/* 7-1 ABテスト作成 */}
        <Route
          path="/sites/:siteSlug/abtests/new"
          element={<ABTestCreatePageContainer />}
        />

        {/* 7-3 ABテスト詳細 */}
        <Route
          path="/sites/:siteSlug/abtests/:abtestSlug"
          element={<ABTestDetailPageContainer />}
        />

        {/* 7-3 ABテスト編集 */}
        <Route
          path="/sites/:siteSlug/abtests/:abtestSlug/edit"
          element={<ABTestEditPageContainer />}
        />

        {/* 7-? ABテストパターン登録 */}
        <Route
          path="/sites/:siteSlug/abtests/:abtestSlug/patterns/new"
          element={<ABTestPatternCreatePageContainer />}
        />

        {/* 7-5 ABテストパターン詳細 */}
        <Route
          path="/sites/:siteSlug/abtests/:abtestSlug/patterns/:patternSlug"
          element={<ABTestPatternDetailPageContainer />}
        />

        {/* 7-6 ABテストパターン編集 */}
        <Route
          path="/sites/:siteSlug/abtests/:abtestSlug/patterns/:patternSlug/edit"
          element={<ABTestPatternEditPageContainer />}
        />

        {/* 8-1 チャットボット一覧 */}
        <Route
          path="/sites/:siteSlug/chatbots"
          element={<ChatbotListPageContainer />}
        />

        {/* 8-2 チャットボット作成 */}
        <Route
          path="/sites/:siteSlug/chatbots/new"
          element={<ChatbotCreatePageContainer />}
        />

        {/* 8-4 チャットボット詳細 */}
        <Route
          path="/sites/:siteSlug/chatbots/:chatbotSlug"
          element={<ChatbotDetailPageContainer />}
        />

        {/* 8-5 チャットボット編集 */}
        <Route
          path="/sites/:siteSlug/chatbots/:chatbotSlug/edit"
          element={<ChatbotEditPageContainer />}
        />

        {/* チャットボット設定画面 チャットボットパターン作成 */}
        <Route
          path="/sites/:siteSlug/chatbots/:chatbotSlug/patterns/new"
          element={<ChatbotPatternCreatePageContainer />}
        />

        {/* チャットボットパターン詳細 チャットボットパターン詳細 */}
        <Route
          path="/sites/:siteSlug/chatbots/:chatbotSlug/patterns/:patternSlug"
          element={<ChatbotPatternDetailPageContainer />}
        />

        {/* チャットボット設定画面 チャットボットパターン編集 */}
        <Route
          path="/sites/:siteSlug/chatbots/:chatbotSlug/patterns/:patternSlug/edit"
          element={<ChatbotPatternEditPageContainer />}
        />

        {/* 5-1 設定画面 */}
        <Route
          path="/sites/:siteSlug/settings"
          element={<SettingsPageContainer />}
        />

        {/* 5-2 ファネル追加 */}
        <Route
          path="/sites/:siteSlug/funnels/new"
          element={<FunnelCreatePageContainer />}
        />

        {/* 5-2 ファネル編集 */}
        <Route
          path="/sites/:siteSlug/funnels/:funnelSlug/edit"
          element={<FunnelEditPageContainer />}
        />

        {/* 5-3 流入元一覧 */}
        <Route
          path="/sites/:siteSlug/funnels/:funnelSlug/sources"
          element={<FunnelSourceListPageContainer />}
        />

        {/* 5-4 流入元追加 */}
        <Route
          path="/sites/:siteSlug/funnels/:funnelSlug/sources/new"
          element={<FunnelSourceCreatePageContainer />}
        />

        <Route
          path="/sites/:siteSlug/funnels/:funnelSlug"
          element={<FunnelDetailPageContainer />}
        />

        {/* 5-4 流入元編集 */}
        <Route
          path="/sites/:siteSlug/funnels/:funnelSlug/sources/:sourceTagSlug/edit"
          element={<FunnelSourceEditPageContainer />}
        />

        {/* 5-1 ファネル一括管理 */}
        <Route
          path="/sites/:siteSlug/funnels/bulk"
          element={<FunnelBulkPageContainer />}
        />

        {/* 5-7 設定 - 流入元 - 追加 */}
        <Route
          path="/sites/:siteSlug/sources/new"
          element={<SourceCreatePageContainer />}
        />

        {/* 5-7 設定 - 流入元 - 編集 */}
        <Route
          path="/sites/:siteSlug/sources/:sourceSlug/edit"
          element={<SourceEditPageContainer />}
        />

        {/* 5-9 設定 - 除外IP - 追加 */}
        <Route
          path="/sites/:siteSlug/excluded_ips/new"
          element={<ExcludedIPCreatePageContainer />}
        />

        {/* 5-9 設定 - 除外IP - 編集 */}
        <Route
          path="/sites/:siteSlug/excluded_ips/:excludedIpSlug/edit"
          element={<ExcludedIPEditPageContainer />}
        />

        {/* 7-1 ABテスト一覧 */}
        <Route
          path="/sites/:siteSlug/reports"
          element={<ReportPageContainer />}
        />

        <Route
          path="/sites/:siteSlug/reports/:kind"
          element={<ReportPageContainer />}
        />

        <Route path="*" element={<Navigate to="/" />} />
      </Route>

      <Route path="/" element={<HeaderOnlyLayoutOutlet />}>
        {/* 2-1 サイト一覧, 2-3-* サイト一覧(フィルタ) /sites?(query) */}
        <Route index element={<SiteListPageContainer />} />

        {/* 0-6 アカウント設定 */}
        <Route path="/account" element={<AccountDetailPageContainer />} />

        {/* 0-6 アカウント設定 */}
        <Route path="/judgements" element={<JudgementListPageContainer />} />

        {/* 0-6 アカウント編集 */}
        <Route
          path="/account/edit"
          element={<AccountSettingsPageContainer />}
        />

        <Route path="/accounts" element={<AccountListPageContainer />} />

        {/* 2-4 埋め込みタグの表示 */}
        <Route path="/embed_tag" element={<EmbedTagPage />} />

        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  ) : (
    <Routes>
      <Route path="/signin" element={<SigninPage />} />
      <Route path="/signup" element={<SignupPage />} />
      <Route path="/forgot_password" element={<ForgotPasswordPage />} />
      <Route path="*" element={<Navigate to="/signin" />} />
    </Routes>
  );
};
