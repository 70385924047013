/**
 * @generated SignedSource<<804c3b180fb8182d67124c630ec3dc4c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AbtestScenarioPageContentTextReplaceActionKind = "TEXT_REPLACEMENT" | "DIV_ELEMENT_ADDITION" | "ATTRIBUTE_REPLACEMENT" | "%future added value";
export type AbtestScenarioPageContentTextReplaceTargetKind = "ID" | "SELECTOR" | "XPATH" | "%future added value";
export type AddAbtestScenarioPageContentTextReplaceInput = {
  clientMutationId?: string | null;
  abtestScenarioPageId: string;
  targetType?: AbtestScenarioPageContentTextReplaceTargetKind | null;
  actionType?: AbtestScenarioPageContentTextReplaceActionKind | null;
  targetValue?: string | null;
  targetAttribute?: string | null;
  replaceValue?: string | null;
};
export type ABTestPatternCreatePageContainer_TextReplaceContentMutation$variables = {
  input: AddAbtestScenarioPageContentTextReplaceInput;
};
export type ABTestPatternCreatePageContainer_TextReplaceContentMutationVariables = ABTestPatternCreatePageContainer_TextReplaceContentMutation$variables;
export type ABTestPatternCreatePageContainer_TextReplaceContentMutation$data = {
  readonly addAbtestScenarioPageTextReplace: {
    readonly abtestScenarioPageContentEdge: {
      readonly node: {
        readonly __typename: "AbtestScenarioPageContentTextReplace";
        readonly id: string;
        readonly targetType: AbtestScenarioPageContentTextReplaceTargetKind;
        readonly targetValue: string;
        readonly targetAttribute: string;
        readonly actionType: AbtestScenarioPageContentTextReplaceActionKind;
        readonly replaceValue: string | null;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      } | null;
    } | null;
  } | null;
};
export type ABTestPatternCreatePageContainer_TextReplaceContentMutationResponse = ABTestPatternCreatePageContainer_TextReplaceContentMutation$data;
export type ABTestPatternCreatePageContainer_TextReplaceContentMutation = {
  variables: ABTestPatternCreatePageContainer_TextReplaceContentMutationVariables;
  response: ABTestPatternCreatePageContainer_TextReplaceContentMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "targetType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "targetValue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "targetAttribute",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actionType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "replaceValue",
      "storageKey": null
    }
  ],
  "type": "AbtestScenarioPageContentTextReplace",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ABTestPatternCreatePageContainer_TextReplaceContentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddAbtestScenarioPageContentTextReplacePayload",
        "kind": "LinkedField",
        "name": "addAbtestScenarioPageTextReplace",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AbtestScenarioPageContentEdge",
            "kind": "LinkedField",
            "name": "abtestScenarioPageContentEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ABTestPatternCreatePageContainer_TextReplaceContentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddAbtestScenarioPageContentTextReplacePayload",
        "kind": "LinkedField",
        "name": "addAbtestScenarioPageTextReplace",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AbtestScenarioPageContentEdge",
            "kind": "LinkedField",
            "name": "abtestScenarioPageContentEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e1c290702738c3a9fcd9a21942b0a951",
    "id": null,
    "metadata": {},
    "name": "ABTestPatternCreatePageContainer_TextReplaceContentMutation",
    "operationKind": "mutation",
    "text": "mutation ABTestPatternCreatePageContainer_TextReplaceContentMutation(\n  $input: AddAbtestScenarioPageContentTextReplaceInput!\n) {\n  addAbtestScenarioPageTextReplace(input: $input) {\n    abtestScenarioPageContentEdge {\n      node {\n        __typename\n        ... on AbtestScenarioPageContentTextReplace {\n          id\n          targetType\n          targetValue\n          targetAttribute\n          actionType\n          replaceValue\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2144994775b9bee0fb10e5f5a78a575e";

export default node;
