/**
 * @generated SignedSource<<9a49e6e908ef1c5aa4ef7b28f48a3ade>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ArchiveArchivableInput = {
  clientMutationId?: string | null;
  archivableId: string;
};
export type ArchiveButton_Mutation$variables = {
  input: ArchiveArchivableInput;
};
export type ArchiveButton_MutationVariables = ArchiveButton_Mutation$variables;
export type ArchiveButton_Mutation$data = {
  readonly archiveArchivable: {
    readonly archivable: {
      readonly id: string;
      readonly isArchive: boolean;
    };
  } | null;
};
export type ArchiveButton_MutationResponse = ArchiveButton_Mutation$data;
export type ArchiveButton_Mutation = {
  variables: ArchiveButton_MutationVariables;
  response: ArchiveButton_Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isArchive",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ArchiveButton_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ArchiveArchivablePayload",
        "kind": "LinkedField",
        "name": "archiveArchivable",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "archivable",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ArchiveButton_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ArchiveArchivablePayload",
        "kind": "LinkedField",
        "name": "archiveArchivable",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "archivable",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d5d6697e08fbce1ff7c491a5f3bf4a7b",
    "id": null,
    "metadata": {},
    "name": "ArchiveButton_Mutation",
    "operationKind": "mutation",
    "text": "mutation ArchiveButton_Mutation(\n  $input: ArchiveArchivableInput!\n) {\n  archiveArchivable(input: $input) {\n    archivable {\n      __typename\n      id\n      isArchive\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5393559471bf6d4e481ee5cb8c1e5eca";

export default node;
