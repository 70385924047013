import { VStack } from "@chakra-ui/react";
import { Form, Formik, FormikConfig } from "formik";
import { FC } from "react";

import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormControlGroup } from "~/src/components/common/forms/FormControlGroup";
import { FormikFormButtons } from "~/src/components/common/forms/FormikFormButtons";
import { FormikTextField } from "~/src/components/common/forms/FormikTextField";

import { FormValues, validationSchema } from "./validationSchema";

export type Props = {
  initialValues?: FormValues;
  onCancelClick: () => void;
  onSubmit: FormikConfig<FormValues>["onSubmit"];
};

const defaultInitialValues = {
  name: "",
  tag: "",
  description: "",
};

export const SourceForm: FC<Props> = ({
  initialValues = defaultInitialValues,
  onCancelClick,
  onSubmit,
}) => (
  <Formik<FormValues>
    enableReinitialize
    initialValues={initialValues}
    validationSchema={validationSchema}
    validateOnMount={false}
    validateOnChange={false}
    onSubmit={onSubmit}
  >
    <Form>
      <VStack spacing="24px" alignItems="flex-start">
        <FormControlGroup>
          <FormControl required label="流入元">
            <FormikTextField type="text" mode="fill" name="name" />
          </FormControl>
          <FormControl required label="タグ">
            <FormikTextField type="text" mode="fill" name="tag" />
          </FormControl>
          <FormControl label="説明">
            <FormikTextField type="text" mode="fill" name="description" />
          </FormControl>
        </FormControlGroup>
        <FormikFormButtons onCancelClick={onCancelClick} />
      </VStack>
    </Form>
  </Formik>
);
