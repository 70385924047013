import { getAuth, onAuthStateChanged } from "firebase/auth";
import { FC, createContext, useContext, useEffect, useState } from "react";

import { createFirebaseApp } from "~/src/lib/firebase";

type UserContextProps = {
  user: User | null;
  setUser: (u: User) => void;
  loadingUser: boolean;
};

type User = {
  uid: string;
  displayName: string | null;
  email: string | null;
  photoURL: string | null;
  idToken: string;
};

export const UserContext = createContext<UserContextProps | null>(null);

export const UserProvider: FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loadingUser, setLoadingUser] = useState(true); // Helpful, to update the UI accordingly.

  useEffect(() => {
    // Listen authenticated user
    const app = createFirebaseApp();
    const auth = getAuth(app);
    const unsubscriber = onAuthStateChanged(auth, async (user) => {
      try {
        if (user) {
          // User is signed in.
          const { uid, displayName, email, photoURL } = user;
          const idToken = await user.getIdToken(true);

          // You could also look for the user doc in your Firestore (if you have one):
          // const userDoc = await firebase.firestore().doc(`users/${uid}`).get()
          setUser({ uid, displayName, email, photoURL, idToken });
        } else setUser(null);
      } catch (error) {
        // Most probably a connection error. Handle appropriately.
      } finally {
        setLoadingUser(false);
      }
    });

    // Unsubscribe auth listener on unmount
    return () => unsubscriber();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, loadingUser }}>
      {children}
    </UserContext.Provider>
  );
};

// Custom hook that shorthands the context!
export const useUser = () => useContext(UserContext);
