import { FC } from "react";

import { Tab, TabProps } from "~/src/components/common/Tab";
import { chakraFactory } from "~/src/lib/chakra-ui";

export type Props = {
  tabs: TabProps["tabs"];
  currentTabId: "sites" | "tag";
  onTabChange: (tab: Props["tabs"][0]["id"]) => void;
};

const StyledTab = chakraFactory(Tab, {
  baseStyle: {
    mb: "7px",
  },
});

export const SiteListPage: FC<Props> = ({
  children,
  tabs,
  currentTabId,
  onTabChange,
}) => (
  <>
    <StyledTab tabs={tabs} currentTabId={currentTabId} onClick={onTabChange} />
    {children}
  </>
);
