/**
 * @generated SignedSource<<16b6f6899609917b77ff254f5318dfb0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MatchOp = "EQUAL" | "CONTAINS" | "%future added value";
export type PopupGroupDisplayTargetContentKind = "ID" | "SELECTOR" | "%future added value";
export type VisitMatch = "EQUAL_MATCH_TYPE" | "OVER_MATCH_TYPE" | "UNDER_MATCH_TYPE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PopupDistributionSettingSection_popupGroup$data = {
  readonly sources: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly excludeSources: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly referrers: ReadonlyArray<{
    readonly referrer: string | null;
    readonly matchOp: MatchOp | null;
  }>;
  readonly excludeReferrers: ReadonlyArray<{
    readonly referrer: string | null;
    readonly matchOp: MatchOp | null;
  }>;
  readonly visitCount: number | null;
  readonly visitMatchType: VisitMatch | null;
  readonly deliverTargetUrls: ReadonlyArray<{
    readonly id: string;
    readonly url: string;
  }>;
  readonly deliverExcludeUrls: ReadonlyArray<{
    readonly id: string;
    readonly url: string;
  }>;
  readonly deliverTargetLabels: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly deliverExcludeLabels: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly targetCookies: ReadonlyArray<{
    readonly id: string;
    readonly key: string;
    readonly value: string;
    readonly matchType: boolean;
  }>;
  readonly displayTargetContents: ReadonlyArray<{
    readonly targetKind: PopupGroupDisplayTargetContentKind;
    readonly targetValue: string;
  }>;
  readonly disableTargetElements: ReadonlyArray<{
    readonly targetKind: PopupGroupDisplayTargetContentKind;
    readonly targetValue: string;
  }>;
  readonly " $fragmentType": "PopupDistributionSettingSection_popupGroup";
};
export type PopupDistributionSettingSection_popupGroup = PopupDistributionSettingSection_popupGroup$data;
export type PopupDistributionSettingSection_popupGroup$key = {
  readonly " $data"?: PopupDistributionSettingSection_popupGroup$data;
  readonly " $fragmentSpreads": FragmentRefs<"PopupDistributionSettingSection_popupGroup">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "referrer",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "matchOp",
    "storageKey": null
  }
],
v3 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
],
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "targetKind",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "targetValue",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PopupDistributionSettingSection_popupGroup",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Source",
      "kind": "LinkedField",
      "name": "sources",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Source",
      "kind": "LinkedField",
      "name": "excludeSources",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PopupGroupReferrer",
      "kind": "LinkedField",
      "name": "referrers",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PopupGroupExcludeReferrer",
      "kind": "LinkedField",
      "name": "excludeReferrers",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "visitCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "visitMatchType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PopupGroupDeliverTargetUrl",
      "kind": "LinkedField",
      "name": "deliverTargetUrls",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PopupGroupDeliverExcludeUrl",
      "kind": "LinkedField",
      "name": "deliverExcludeUrls",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserLabel",
      "kind": "LinkedField",
      "name": "deliverTargetLabels",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserLabel",
      "kind": "LinkedField",
      "name": "deliverExcludeLabels",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PopupGroupTargetCookie",
      "kind": "LinkedField",
      "name": "targetCookies",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "matchType",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PopupGroupDisplayTargetContent",
      "kind": "LinkedField",
      "name": "displayTargetContents",
      "plural": true,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PopupGroupDisableTargetElement",
      "kind": "LinkedField",
      "name": "disableTargetElements",
      "plural": true,
      "selections": (v4/*: any*/),
      "storageKey": null
    }
  ],
  "type": "PopupGroup",
  "abstractKey": null
};
})();

(node as any).hash = "2a6fcc2cf645d88077a428c1b678c7b1";

export default node;
