import { FC, useMemo } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";

import { TagTab_Query } from "~/src/__relay_artifacts__/TagTab_Query.graphql";
import { SnippetCard } from "~/src/components/features/global/SnippetCard";

const query = graphql`
  query TagTab_Query {
    viewer {
      tagScript
    }
  }
`;

export const TagTab: FC = () => {
  const { viewer: user } = useLazyLoadQuery<TagTab_Query>(query, {});

  const snippet = useMemo(() => {
    return user.tagScript;
  }, [user]);

  return <SnippetCard snippet={snippet} />;
};
