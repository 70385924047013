/**
 * @generated SignedSource<<749132b7fc3cd7cdc343722475a53bf1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Device = "MOBILE" | "TABLET" | "PC" | "%future added value";
export type DistributionStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type PageKind = "LANDING" | "FORM" | "CONFIRM" | "THANKS" | "%future added value";
export type ChatbotListPage_Query$variables = {
  count?: number | null;
  cursor?: string | null;
  device?: Device | null;
  funnelIds?: ReadonlyArray<string> | null;
  pageIds?: ReadonlyArray<string> | null;
  pageKind?: PageKind | null;
  searchTerm?: string | null;
  sourceId?: string | null;
  status?: DistributionStatus | null;
  withArchived?: boolean | null;
  id: string;
};
export type ChatbotListPage_QueryVariables = ChatbotListPage_Query$variables;
export type ChatbotListPage_Query$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"ChatbotListPage_site">;
  } | null;
};
export type ChatbotListPage_QueryResponse = ChatbotListPage_Query$data;
export type ChatbotListPage_Query = {
  variables: ChatbotListPage_QueryVariables;
  response: ChatbotListPage_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": 120,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "device"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "funnelIds"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageIds"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageKind"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchTerm"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sourceId"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "withArchived"
},
v11 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v12 = {
  "kind": "Variable",
  "name": "device",
  "variableName": "device"
},
v13 = {
  "kind": "Variable",
  "name": "funnelIds",
  "variableName": "funnelIds"
},
v14 = {
  "kind": "Variable",
  "name": "pageIds",
  "variableName": "pageIds"
},
v15 = {
  "kind": "Variable",
  "name": "pageKind",
  "variableName": "pageKind"
},
v16 = {
  "kind": "Variable",
  "name": "searchTerm",
  "variableName": "searchTerm"
},
v17 = {
  "kind": "Variable",
  "name": "sourceId",
  "variableName": "sourceId"
},
v18 = {
  "kind": "Variable",
  "name": "status",
  "variableName": "status"
},
v19 = {
  "kind": "Variable",
  "name": "withArchived",
  "variableName": "withArchived"
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v23 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v12/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v13/*: any*/),
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": {
      "direction": "DESC",
      "field": "END_ON"
    }
  },
  (v14/*: any*/),
  (v15/*: any*/),
  (v16/*: any*/),
  (v17/*: any*/),
  (v18/*: any*/),
  (v19/*: any*/)
],
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ChatbotListPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v11/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "ChatbotListPage_site"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "ChatbotListPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v11/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v20/*: any*/),
          (v21/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v22/*: any*/),
              {
                "alias": null,
                "args": (v23/*: any*/),
                "concreteType": "ChatbotGroupConnection",
                "kind": "LinkedField",
                "name": "chatbotGroups",
                "plural": false,
                "selections": [
                  (v24/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ChatbotGroupEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ChatbotGroup",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v21/*: any*/),
                          (v22/*: any*/),
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "kind",
                                "value": "B_PATTERN"
                              }
                            ],
                            "concreteType": "ChatbotConnection",
                            "kind": "LinkedField",
                            "name": "chatbots",
                            "plural": false,
                            "selections": [
                              (v24/*: any*/)
                            ],
                            "storageKey": "chatbots(kind:\"B_PATTERN\")"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endDate",
                            "storageKey": null
                          },
                          (v25/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startDate",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "updatedAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isArchive",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "thumbnailImageUrl",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Page",
                            "kind": "LinkedField",
                            "name": "page",
                            "plural": false,
                            "selections": [
                              (v25/*: any*/),
                              (v22/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Funnel",
                                "kind": "LinkedField",
                                "name": "funnel",
                                "plural": false,
                                "selections": [
                                  (v25/*: any*/),
                                  (v22/*: any*/),
                                  (v21/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v21/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v20/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v23/*: any*/),
                "filters": [
                  "searchTerm",
                  "pageIds",
                  "funnelIds",
                  "sourceId",
                  "device",
                  "withArchived",
                  "orderBy",
                  "status",
                  "pageKind"
                ],
                "handle": "connection",
                "key": "ChatbotListPage_chatbotGroups",
                "kind": "LinkedHandle",
                "name": "chatbotGroups"
              }
            ],
            "type": "Site",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9d6be25639a9b616b5010a6375d76aaf",
    "id": null,
    "metadata": {},
    "name": "ChatbotListPage_Query",
    "operationKind": "query",
    "text": "query ChatbotListPage_Query(\n  $count: Int = 120\n  $cursor: String\n  $device: Device\n  $funnelIds: [ID!]\n  $pageIds: [ID!]\n  $pageKind: PageKind\n  $searchTerm: String\n  $sourceId: ID\n  $status: DistributionStatus\n  $withArchived: Boolean\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...ChatbotListPage_site_6nV82\n    id\n  }\n}\n\nfragment ChatbotListPage_site_6nV82 on Site {\n  slug\n  chatbotGroups(first: $count, after: $cursor, searchTerm: $searchTerm, pageIds: $pageIds, funnelIds: $funnelIds, sourceId: $sourceId, device: $device, withArchived: $withArchived, orderBy: {field: END_ON, direction: DESC}, status: $status, pageKind: $pageKind) {\n    totalCount\n    edges {\n      node {\n        id\n        slug\n        ...ChatbotTableDataRow_chatbotGroup\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n\nfragment ChatbotTableDataRow_chatbotGroup on ChatbotGroup {\n  chatbots(kind: B_PATTERN) {\n    totalCount\n  }\n  endDate\n  id\n  name\n  slug\n  startDate\n  updatedAt\n  status\n  isArchive\n  thumbnailImageUrl\n  page {\n    name\n    slug\n    funnel {\n      name\n      slug\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "71e0f0df12c07d1ecc3c016509f052d9";

export default node;
