import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import jaLocale from "date-fns/locale/ja";
import * as React from "react";

import { MuiThemeProvider } from "~/src/lib/mui";

export const MUIPickerProvider: React.FC = ({ children }) => (
  <MuiThemeProvider>
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
      {children}
    </LocalizationProvider>
  </MuiThemeProvider>
);
