/**
 * @generated SignedSource<<0561e3994c20f9ad6a3fc716f2dd7278>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DisplayPopupAgainStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type DistributionStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type PopupActionKind = "DURATION" | "SCROLL_RATE" | "SCROLL_PIXEL" | "EXIT_PAGE" | "EXIT_AND_SCROLL_RATE_1" | "EXIT_AND_SCROLL_RATE_2" | "EXIT_AND_SCROLL_RATE_3" | "SCROLL_RATE_RANGE" | "CUSTOM_EVENT" | "%future added value";
export type PopupAnimationKind = "NO_ANIMATION" | "FROM_RIGHT" | "FROM_LEFT" | "FROM_TOP" | "FROM_BOTTOM" | "%future added value";
export type PopupCloseButtonPosition = "TOP_RIGHT" | "TOP_LEFT" | "BOTTOM_LEFT" | "BOTTOM_RIGHT" | "%future added value";
export type PopupClosePopupByClickingOutside = "DISABLED" | "ENABLED" | "%future added value";
export type PopupColorKind = "DEFAULT" | "WHITE" | "GRAY" | "BLACK" | "RED" | "BLUE" | "GREEN" | "YELLOW" | "%future added value";
export type PopupContentKind = "IMAGE" | "VIDEO" | "TEXT" | "HTML" | "%future added value";
export type PopupDisplayKind = "CENTER_MODAL" | "LEFT_MODAL" | "RIGHT_MODAL" | "TOP_MODAL" | "BOTTOM_MODAL" | "LEFT_BOTTOM_MODAL" | "RIGHT_BOTTOM_MODAL" | "CUSTOM_MODAL" | "%future added value";
export type PopupHrefTarget = "BLANK" | "TOP" | "%future added value";
export type PopupVideoScreenKind = "NORMAL" | "FULLSCREEN" | "%future added value";
export type PopupYdaTrigger = "YDA_AFFI" | "YDA_ALWAYS" | "YDA_SHOW" | "%future added value";
export type UpdatePopupInput = {
  clientMutationId?: string | null;
  popupId: string;
  actionKind?: PopupActionKind | null;
  animationKind: PopupAnimationKind;
  backgroundOpacity: number;
  button?: string | null;
  buttonGradationColor?: string | null;
  buttonColor?: string | null;
  buttonTextColor?: string | null;
  contentKind?: PopupContentKind | null;
  customEvent?: string | null;
  closePopupByClickingOutside: PopupClosePopupByClickingOutside;
  closeButtonPosition?: PopupCloseButtonPosition | null;
  colorKind?: PopupColorKind | null;
  contentWidth?: number | null;
  displayKind?: PopupDisplayKind | null;
  description?: string | null;
  displayOnTabFocus: boolean;
  displayOnMouseOut: boolean;
  enableTapWindow: boolean;
  hideByScrollPercentage: number;
  hideByOnfocus: boolean;
  htmlContent?: string | null;
  hrefTarget: PopupHrefTarget;
  honeycombCode?: string | null;
  isClose: boolean;
  isInheritQuery: boolean;
  name?: string | null;
  popupImages?: ReadonlyArray<PopupImageInput> | null;
  popupVideo?: PopupVideoInput | null;
  quitTiming?: number | null;
  scrollRateRange: string;
  status?: DistributionStatus | null;
  displayCountLimit?: number | null;
  displayPopupAgain?: DisplayPopupAgainStatus | null;
  displayPopupAgainImage?: File | Blog | null;
  displayPopupAgainMessage?: string | null;
  timing?: number | null;
  titleTextColor?: string | null;
  title?: string | null;
  url?: string | null;
  underlayerColor?: string | null;
  windowColor?: string | null;
  weight?: number | null;
  xOffset?: number | null;
  xOffsetUnit?: string | null;
  ydaTrigger?: PopupYdaTrigger | null;
  yOffset?: number | null;
  yOffsetUnit?: string | null;
  isDisplayWhenNoOperation: boolean;
};
export type PopupImageInput = {
  id?: string | null;
  alt?: string | null;
  linkUrl?: string | null;
  image?: File | Blog | null;
  isInheritQuery?: boolean | null;
  honeycombCode?: string | null;
};
export type PopupVideoInput = {
  video?: string | null;
  linkUrl?: string | null;
  screenType?: PopupVideoScreenKind | null;
  useOrgImage?: number | null;
  showControls?: number | null;
  showAnnotations?: number | null;
  stopMovieOnBlur?: number | null;
  optimizeDisplay?: number | null;
  mute?: number | null;
  autoPlay?: number | null;
  loop?: number | null;
};
export type UpdateOriginalPopupInput = {
  clientMutationId?: string | null;
  popupGroupId: string;
  status?: DistributionStatus | null;
  weight?: number | null;
};
export type PopupGroupPatternEditScreenContainer_Mutation$variables = {
  input: UpdatePopupInput;
  originalInput: UpdateOriginalPopupInput;
  withOriginal: boolean;
};
export type PopupGroupPatternEditScreenContainer_MutationVariables = PopupGroupPatternEditScreenContainer_Mutation$variables;
export type PopupGroupPatternEditScreenContainer_Mutation$data = {
  readonly updateOriginalPopup?: {
    readonly popup: {
      readonly id: string;
      readonly weight: number | null;
    };
  } | null;
  readonly updatePopup: {
    readonly popup: {
      readonly slug: string;
      readonly " $fragmentSpreads": FragmentRefs<"PopupGroupPatternEditScreenContainer_popup">;
    };
  } | null;
};
export type PopupGroupPatternEditScreenContainer_MutationResponse = PopupGroupPatternEditScreenContainer_Mutation$data;
export type PopupGroupPatternEditScreenContainer_Mutation = {
  variables: PopupGroupPatternEditScreenContainer_MutationVariables;
  response: PopupGroupPatternEditScreenContainer_Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "originalInput"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "withOriginal"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "weight",
  "storageKey": null
},
v3 = {
  "condition": "withOriginal",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "input",
          "variableName": "originalInput"
        }
      ],
      "concreteType": "UpdateOriginalPopupPayload",
      "kind": "LinkedField",
      "name": "updateOriginalPopup",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Popup",
          "kind": "LinkedField",
          "name": "popup",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ]
},
v4 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "linkUrl",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isInheritQuery",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "honeycombCode",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PopupGroupPatternEditScreenContainer_Mutation",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "UpdatePopupPayload",
        "kind": "LinkedField",
        "name": "updatePopup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Popup",
            "kind": "LinkedField",
            "name": "popup",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "PopupGroupPatternEditScreenContainer_popup"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PopupGroupPatternEditScreenContainer_Mutation",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "UpdatePopupPayload",
        "kind": "LinkedField",
        "name": "updatePopup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Popup",
            "kind": "LinkedField",
            "name": "popup",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "page",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Funnel",
                    "kind": "LinkedField",
                    "name": "funnel",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PopupImage",
                "kind": "LinkedField",
                "name": "popupImages",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "alt",
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "imageUrl",
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PopupVideo",
                "kind": "LinkedField",
                "name": "popupVideo",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "video",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "screenType",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "contentKind",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "colorKind",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "button",
                "storageKey": null
              },
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hrefTarget",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "contentWidth",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "actionKind",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayKind",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "xOffset",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "xOffsetUnit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "yOffset",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "yOffsetUnit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "enableTapWindow",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "backgroundOpacity",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "quitTiming",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayCountLimit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayPopupAgain",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayPopupAgainImageUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayPopupAgainMessage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayOnMouseOut",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayOnTabFocus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isClose",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "htmlContent",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "animationKind",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "closePopupByClickingOutside",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "closeButtonPosition",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hideByScrollPercentage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hideByOnfocus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ydaTrigger",
                "storageKey": null
              },
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isDisplayWhenNoOperation",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timing",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "scrollRateRange",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "customEvent",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bdcd9c49e81bd2b5cd2b3a3c3aa9ecb1",
    "id": null,
    "metadata": {},
    "name": "PopupGroupPatternEditScreenContainer_Mutation",
    "operationKind": "mutation",
    "text": "mutation PopupGroupPatternEditScreenContainer_Mutation(\n  $input: UpdatePopupInput!\n  $originalInput: UpdateOriginalPopupInput!\n  $withOriginal: Boolean!\n) {\n  updateOriginalPopup(input: $originalInput) @include(if: $withOriginal) {\n    popup {\n      id\n      weight\n    }\n  }\n  updatePopup(input: $input) {\n    popup {\n      slug\n      ...PopupGroupPatternEditScreenContainer_popup\n      id\n    }\n  }\n}\n\nfragment PopupGroupPatternEditScreenContainer_popup on Popup {\n  id\n  slug\n  page {\n    id\n    funnel {\n      id\n    }\n  }\n  popupImages {\n    id\n    alt\n    linkUrl\n    imageUrl\n    isInheritQuery\n    honeycombCode\n  }\n  popupVideo {\n    linkUrl\n    video\n    screenType\n  }\n  name\n  contentKind\n  colorKind\n  title\n  description\n  button\n  isInheritQuery\n  url\n  hrefTarget\n  contentWidth\n  actionKind\n  displayKind\n  xOffset\n  xOffsetUnit\n  yOffset\n  yOffsetUnit\n  enableTapWindow\n  backgroundOpacity\n  quitTiming\n  displayCountLimit\n  status\n  displayPopupAgain\n  displayPopupAgainImageUrl\n  displayPopupAgainMessage\n  displayOnMouseOut\n  displayOnTabFocus\n  isClose\n  htmlContent\n  weight\n  animationKind\n  closePopupByClickingOutside\n  closeButtonPosition\n  hideByScrollPercentage\n  hideByOnfocus\n  ydaTrigger\n  honeycombCode\n  isDisplayWhenNoOperation\n  timing\n  scrollRateRange\n  customEvent\n}\n"
  }
};
})();

(node as any).hash = "7617f64530175eac54e7388166357dbd";

export default node;
