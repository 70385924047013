import { HStack, Input, InputGroup, VStack } from "@chakra-ui/react";
import { format } from "date-fns";
import { dissoc, pick } from "ramda";
import {
  ChangeEvent,
  DragEventHandler,
  FC,
  MouseEventHandler,
  useCallback,
  useRef,
} from "react";
import { Edge, Node } from "react-flow-renderer";

import { chakraFactory } from "~/src/lib/chakra-ui";

import { NodeData } from "./types";

type Props = {
  nodes: Node<NodeData>[];
  edges: Edge<any>[];
  onUploaded: (
    uploadedNodes: Node<NodeData>[],
    uploadedEdges: Edge<any>[]
  ) => void;
};

const StyledInput = chakraFactory(Input, {
  baseStyle: {
    display: "none",
  },
});

const SidebarWrapper = chakraFactory("aside", {
  baseStyle: {
    borderTop: "1px solid #DADADA",
    padding: "15px 10px",
    fontSize: "12px",
    background: "white",
  },
});

const SidebarDescriptionWrapper = chakraFactory("div", {
  baseStyle: {},
});

const DraggableButtonWrapper = chakraFactory("div", {
  baseStyle: {
    height: "20px",
    padding: "4px",
    border: "1px solid #1a192b",
    borderRadius: "2px",
    marginBottom: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "grab",
    width: "50%",
  },
});

const ButtonWrapper = chakraFactory("button", {
  baseStyle: {
    height: "20px",
    padding: "4px",
    border: "1px solid #1a192b",
    borderRadius: "2px",
    marginBottom: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
  },
});

const readFile = async (file: File | Blob): Promise<string | null> => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result as string));
    reader.readAsText(file);
  });
};

export const Sidebar: FC<Props> = ({ nodes, edges, onUploaded }) => {
  const inputRef = useRef<any>();

  const handleTextButtonDragStart = useCallback<
    DragEventHandler<HTMLDivElement>
  >((e) => {
    e.dataTransfer.setData("application/chatbotflow", "TEXT");
    e.dataTransfer.effectAllowed = "move";
  }, []);

  const handleImageButtonDragStart = useCallback<
    DragEventHandler<HTMLDivElement>
  >((e) => {
    e.dataTransfer.setData("application/chatbotflow", "IMAGE");
    e.dataTransfer.effectAllowed = "move";
  }, []);

  const handleCloseButtonDragStart = useCallback<
    DragEventHandler<HTMLDivElement>
  >((e) => {
    e.dataTransfer.setData("application/chatbotflow", "SUBMIT");
    e.dataTransfer.effectAllowed = "move";
  }, []);

  const handleDownloadClick = useCallback<
    MouseEventHandler<HTMLButtonElement>
  >(() => {
    const ignoreIdNodes = nodes.map((node) => {
      const { data, ...nodeRest } = node;
      const { message, replyGroup, ...dataRest } = data;
      return {
        data: {
          message: data.message ? dissoc("id", data.message) : data.message,
          replyGroup: !replyGroup
            ? replyGroup
            : {
                replies: !replyGroup.replies
                  ? undefined
                  : replyGroup.replies.map((reply) => dissoc("id", reply)),
                ...pick(["kind", "label", "divergingPoint"], replyGroup),
              },
          ...dataRest,
        },
        ...nodeRest,
      };
    });

    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify({
        nodes: ignoreIdNodes,
        edges,
      })
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = `chatbot_scenario_${format(new Date(), "yyyy-MM-dd")}.json`;
    link.click();
  }, [nodes, edges]);

  const handleFileUpload = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target && e.target.files && e.target.files.length > 0) {
        const jsonString = await readFile(e.target.files[0]);
        if (!jsonString) return;

        const jsonObject = JSON.parse(jsonString);
        if (!jsonObject.nodes || !jsonObject.edges) return;

        const uploadedNodes = jsonObject.nodes as Node<NodeData>[];
        const uploadedEdges = jsonObject.edges as Edge<any>[];

        onUploaded(uploadedNodes, uploadedEdges);
      }
    },
    [onUploaded]
  );

  return (
    <SidebarWrapper>
      <HStack>
        <VStack width="50%">
          <SidebarDescriptionWrapper>
            パーツをドラッグしてください
          </SidebarDescriptionWrapper>
          <DraggableButtonWrapper
            onDragStart={handleTextButtonDragStart}
            draggable
          >
            テキスト
          </DraggableButtonWrapper>
          <DraggableButtonWrapper
            onDragStart={handleImageButtonDragStart}
            draggable
          >
            画像
          </DraggableButtonWrapper>
          <DraggableButtonWrapper
            onDragStart={handleCloseButtonDragStart}
            draggable
          >
            送信ボタン
          </DraggableButtonWrapper>
        </VStack>

        <VStack width="50%">
          <ButtonWrapper type="button" onClick={handleDownloadClick}>
            ダウンロード
          </ButtonWrapper>

          <InputGroup justifyContent={"center"}>
            <StyledInput
              type="file"
              accept=".json"
              onChange={handleFileUpload}
              ref={inputRef}
            />

            <ButtonWrapper
              type="button"
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() => inputRef.current.click()}
            >
              アップロード
            </ButtonWrapper>
          </InputGroup>
        </VStack>
      </HStack>
    </SidebarWrapper>
  );
};
