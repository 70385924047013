import { Flex } from "@chakra-ui/react";
import { DownloadOutlined as DownloadOutlinedIcon } from "@mui/icons-material";
import { format } from "date-fns";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useCSVDownloader, usePapaParse } from "react-papaparse";
import { graphql, useFragment } from "react-relay";

import { FunnelReportTable_site$key } from "~/src/__relay_artifacts__/FunnelReportTable_site.graphql";
import { IconButton } from "~/src/components/common/IconButton";
import { Table } from "~/src/components/common/tables/Table";

import { DisableColumnSelectButton } from "../DisableColumnSelectButton";
import {
  ColumnData,
  ReportTableDataRow,
  ReportTableHeaderRow,
} from "../ReportTable";
import { RowHeightSettingButton } from "../RowHeightSettingButton";
import { disableColumnOptions, headerOptionsForFunnelReport } from "../utils";

const fragment = graphql`
  fragment FunnelReportTable_site on Site {
    reportFunnel(input: $input)
  }
`;

export type Prop = {
  onSortClick: (params: any) => void;
  siteRef: FunnelReportTable_site$key;
};

export const FunnelReportTable: FC<Prop> = ({ siteRef, onSortClick }) => {
  const { CSVDownloader, Type } = useCSVDownloader();
  const { reportFunnel } = useFragment<typeof siteRef>(fragment, siteRef);

  const [disableColumns, setDisableColumns] = useState<number[]>([]);
  const [rowHeight, setRowHeight] = useState<number>(56);

  const { readString } = usePapaParse();

  const [data, setData] = useState<{ headers: string[]; rows: string[][] }>({
    headers: [],
    rows: [],
  });

  useEffect(() => {
    readString<any>(reportFunnel.summary_report.toString(), {
      worker: true,
      complete: (results: any) => {
        const [headers, ...rest] = results.data;
        setData({ headers, rows: rest });
      },
    });
  }, [readString, reportFunnel]);

  const handleSubmitDisalbeColumnSelect = useCallback((values) => {
    setDisableColumns(values.disableColumns);
  }, []);

  const handleSubmitRowHeightSetting = useCallback((values) => {
    setRowHeight(values.rowHeight);
  }, []);

  const tableRows = useMemo(() => {
    return data.rows.map((row) => {
      return row.map((column) => {
        return {
          type: "TEXT",
          value: column,
        } as ColumnData;
      });
    });
  }, [data]);

  const tableFooterRow = useMemo(() => {
    return [
      { type: "TEXT", value: "合計" },
      {
        type: "TEXT",
        value: reportFunnel.aggregated_report.unique_user as string,
      },
      {
        type: "TEXT",
        value: reportFunnel.aggregated_report.conversion_user as string,
      },
      {
        type: "TEXT",
        value: reportFunnel.aggregated_report.conversion_user_rate as string,
      },
      {
        type: "TEXT",
        value: reportFunnel.aggregated_report.lp_drop_rate as string,
      },
      {
        type: "TEXT",
        value: reportFunnel.aggregated_report.lp_scroll_rate as string,
      },
      {
        type: "TEXT",
        value: reportFunnel.aggregated_report.lp_average_stay_time as string,
      },
      {
        type: "TEXT",
        value: reportFunnel.aggregated_report.form_drop_rate as string,
      },
      { type: "TEXT", value: reportFunnel.aggregated_report.mobile as string },
      { type: "TEXT", value: reportFunnel.aggregated_report.tablet as string },
      { type: "TEXT", value: reportFunnel.aggregated_report.pc as string },
    ] as ColumnData[];
  }, [reportFunnel]);

  const csvRows = useMemo(() => {
    const rows = data.rows.map((row) => {
      const obj: any = {};
      row.forEach((column, i) =>
        Object.assign(obj, { [data.headers[i]]: column })
      );
      return obj;
    });
    rows.push({
      [data.headers[0]]: "合計",
      [data.headers[1]]: reportFunnel.aggregated_report.unique_user,
      [data.headers[2]]: reportFunnel.aggregated_report.conversion_user,
      [data.headers[3]]: reportFunnel.aggregated_report.conversion_user_rate,
      [data.headers[4]]: reportFunnel.aggregated_report.lp_drop_rate,
      [data.headers[5]]: reportFunnel.aggregated_report.lp_scroll_rate,
      [data.headers[6]]: reportFunnel.aggregated_report.lp_average_stay_time,
      [data.headers[7]]: reportFunnel.aggregated_report.form_drop_rate,
      [data.headers[8]]: reportFunnel.aggregated_report.mobile,
      [data.headers[9]]: reportFunnel.aggregated_report.tablet,
      [data.headers[10]]: reportFunnel.aggregated_report.pc,
    });
    return rows.map((row) => {
      disableColumns.forEach((disableColumn) => {
        delete row[data.headers[disableColumn]];
      });
      return row;
    });
  }, [data, reportFunnel, disableColumns]);

  return (
    <>
      <Flex justifyContent="flex-end" mb="24px" mt={6}>
        <RowHeightSettingButton
          formValues={{ rowHeight }}
          onSubmit={handleSubmitRowHeightSetting}
        />
        <DisableColumnSelectButton
          formValues={{ disableColumns }}
          onSubmit={handleSubmitDisalbeColumnSelect}
          options={disableColumnOptions}
        />
        <CSVDownloader
          type={Type.Link}
          filename={`ファネル別レポート-${format(new Date(), "yyyy-MM-dd")}`}
          data={csvRows}
        >
          <IconButton size="24px" icon={<DownloadOutlinedIcon />} />
        </CSVDownloader>
      </Flex>
      <Table>
        <ReportTableHeaderRow
          disableColumns={disableColumns}
          onSortClick={onSortClick}
          headerOptions={headerOptionsForFunnelReport}
        />
        {tableRows.map((row, i) => (
          <ReportTableDataRow
            key={i}
            row={row}
            disableColumns={disableColumns}
          />
        ))}
        <ReportTableDataRow
          disableColumns={disableColumns}
          row={tableFooterRow}
          rowHeight={`${rowHeight}px`}
        />
      </Table>
    </>
  );
};
