import { Box, HStack } from "@chakra-ui/layout";
import { includes } from "ramda";
import { FC, useCallback, useMemo } from "react";
import { graphql, useFragment } from "react-relay";
import { useNavigate, useParams } from "react-router-dom";

import { ABTestPatternEditScreenContainer_abtestScenarioPage$key } from "~/src/__relay_artifacts__/ABTestPatternEditScreenContainer_abtestScenarioPage.graphql";
import { ABTestPatternEditScreenContainer_abtestScenarioPageGroup$key } from "~/src/__relay_artifacts__/ABTestPatternEditScreenContainer_abtestScenarioPageGroup.graphql";
import { ABTestPatternEditScreenContainer_AddImageContentMutation } from "~/src/__relay_artifacts__/ABTestPatternEditScreenContainer_AddImageContentMutation.graphql";
import { ABTestPatternEditScreenContainer_AddTextReplaceContentMutation } from "~/src/__relay_artifacts__/ABTestPatternEditScreenContainer_AddTextReplaceContentMutation.graphql";
import { ABTestPatternEditScreenContainer_DeleteMutation } from "~/src/__relay_artifacts__/ABTestPatternEditScreenContainer_DeleteMutation.graphql";
import { ABTestPatternEditScreenContainer_Mutation } from "~/src/__relay_artifacts__/ABTestPatternEditScreenContainer_Mutation.graphql";
import { ABTestPatternEditScreenContainer_UpdateImageContentMutation } from "~/src/__relay_artifacts__/ABTestPatternEditScreenContainer_UpdateImageContentMutation.graphql";
import { ABTestPatternEditScreenContainer_UpdateTextReplaceContentMutation } from "~/src/__relay_artifacts__/ABTestPatternEditScreenContainer_UpdateTextReplaceContentMutation.graphql";
import { URLItem } from "~/src/components/common/URLItem";
import {
  ABTestPatternFormContainer,
  ABTestPatternFormProps,
  FormValues as ABTestPatternFormValues,
} from "~/src/components/features/abtest";
import { PageLayout } from "~/src/components/features/global/PageLayout";
import { useFormErrorHandler } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";
import { delayChunkPromise } from "~/src/lib/utils";

export type Props = {
  abtestScenarioPageRef: ABTestPatternEditScreenContainer_abtestScenarioPage$key;
  abtestScenarioPageGroupRef: ABTestPatternEditScreenContainer_abtestScenarioPageGroup$key;
};

const fragments = {
  abtestScenarioPage: graphql`
    fragment ABTestPatternEditScreenContainer_abtestScenarioPage on AbtestScenarioPage {
      id
      title
      slug
      kind
      weight
      linkUrl
      isInheritQuery
      kind

      page {
        id
      }
      abtestScenarioPageContents {
        edges {
          node {
            ... on AbtestScenarioPageContentScenario {
              id
              imageUrl
              beforeSrc
            }

            ... on AbtestScenarioPageContentRedirect {
              id
            }

            ... on AbtestScenarioPageContentTextReplace {
              id
              targetType
              actionType
              targetValue
              targetAttribute
              replaceValue
            }
          }
        }
      }
    }
  `,
  abtestScenarioPageGroup: graphql`
    fragment ABTestPatternEditScreenContainer_abtestScenarioPageGroup on AbtestScenarioPageGroup {
      id
      title
      deliverKind
      originalAbtestScenarioPage {
        id
        weight
      }
      page {
        name
        kind
        sortNumber
        url
        funnel {
          name
          siteUrl
        }
      }
    }
  `,
};

const mutationScenario = graphql`
  mutation ABTestPatternEditScreenContainer_Mutation(
    $input: UpdateAbtestScenarioPageInput!
    $originalInput: UpdateOriginalAbtestScenarioPageInput!
    $withOriginal: Boolean!
  ) {
    updateOriginalAbtestScenarioPage(input: $originalInput)
      @include(if: $withOriginal) {
      abtestScenarioPage {
        id
        weight
      }
    }
    updateAbtestScenarioPage(input: $input) {
      abtestScenarioPage {
        id
        slug
        ...ABTestPatternEditScreenContainer_abtestScenarioPage
      }
    }
  }
`;

const mutateUpdateImageContent = graphql`
  mutation ABTestPatternEditScreenContainer_UpdateImageContentMutation(
    $input: UpdateAbtestScenarioPageContentScenarioInput!
  ) {
    updateAbtestScenarioPageContentScenario(input: $input) {
      abtestScenarioPageContent {
        __typename
        ... on AbtestScenarioPageContentScenario {
          id
          image
          imageUrl
          beforeSrc
        }
      }
    }
  }
`;

const mutateUpdateTextReplaceContent = graphql`
  mutation ABTestPatternEditScreenContainer_UpdateTextReplaceContentMutation(
    $input: UpdateAbtestScenarioPageContentTextReplaceInput!
  ) {
    updateAbtestScenarioPageTextReplace(input: $input) {
      abtestScenarioPageContent {
        __typename
        ... on AbtestScenarioPageContentTextReplace {
          id
          targetType
          targetValue
          targetAttribute
          actionType
          replaceValue
        }
      }
    }
  }
`;

const mutateAddImageContent = graphql`
  mutation ABTestPatternEditScreenContainer_AddImageContentMutation(
    $input: AddAbtestScenarioPageContentScenarioInput!
  ) {
    addAbtestScenarioPageContentScenario(input: $input) {
      abtestScenarioPageContentEdge {
        node {
          __typename
          ... on AbtestScenarioPageContentScenario {
            id
            image
            imageUrl
            beforeSrc
          }
        }
      }
    }
  }
`;

const mutateAddTextReplaceContent = graphql`
  mutation ABTestPatternEditScreenContainer_AddTextReplaceContentMutation(
    $input: AddAbtestScenarioPageContentTextReplaceInput!
  ) {
    addAbtestScenarioPageTextReplace(input: $input) {
      abtestScenarioPageContentEdge {
        node {
          __typename
          ... on AbtestScenarioPageContentTextReplace {
            id
            targetType
            targetValue
            targetAttribute
            actionType
            replaceValue
          }
        }
      }
    }
  }
`;

const deleteMutation = graphql`
  mutation ABTestPatternEditScreenContainer_DeleteMutation(
    $input: DeleteAbtestScenarioPageContentInput!
  ) {
    deleteAbtestScenarioPageContent(input: $input) {
      deletedAbtestScenarioPageContentId
    }
  }
`;

export const ABTestPatternEditScreenContainer: FC<Props> = ({
  abtestScenarioPageRef,
  abtestScenarioPageGroupRef,
}) => {
  const { abtestSlug = "", siteSlug = "", patternSlug = "" } = useParams();

  const abtestScenarioPage = useFragment(
    fragments.abtestScenarioPage,
    abtestScenarioPageRef
  );
  const abtestScenarioPageGroup = useFragment(
    fragments.abtestScenarioPageGroup,
    abtestScenarioPageGroupRef
  );

  const scenarioContentIds = useMemo(() => {
    const ids =
      abtestScenarioPage.abtestScenarioPageContents.edges?.map((content) => {
        const node = content?.node;
        if (!node) throw new Error("assertion failed");
        return node.id || "";
      }) || [];
    return ids.filter((id) => id !== "");
  }, [abtestScenarioPage]);

  const navigate = useNavigate();
  const handleCancel = useCallback(() => navigate(-1), [navigate]);

  const mutate =
    useMutationCommit<ABTestPatternEditScreenContainer_Mutation>(
      mutationScenario
    );
  const addImageContentMutate =
    useMutationCommit<ABTestPatternEditScreenContainer_AddImageContentMutation>(
      mutateAddImageContent
    );
  const updateImageContentMutate =
    useMutationCommit<ABTestPatternEditScreenContainer_UpdateImageContentMutation>(
      mutateUpdateImageContent
    );
  const addTextReplaceContentMutate =
    useMutationCommit<ABTestPatternEditScreenContainer_AddTextReplaceContentMutation>(
      mutateAddTextReplaceContent
    );
  const updateTextReplaceContentMutate =
    useMutationCommit<ABTestPatternEditScreenContainer_UpdateTextReplaceContentMutation>(
      mutateUpdateTextReplaceContent
    );

  const deleteMutate =
    useMutationCommit<ABTestPatternEditScreenContainer_DeleteMutation>(
      deleteMutation
    );

  const { onFormError } = useFormErrorHandler();

  const handleSubmit = useCallback<ABTestPatternFormProps["onSubmit"]>(
    async (
      {
        scenarioContents,
        textReplaceContents,
        redirectContents,
        originalPatternWeight,
        ...values
      },
      { setErrors }
    ) => {
      const { id, ...rest } = values;
      try {
        const res = await mutate({
          variables: {
            input: {
              abtestScenarioPageId: abtestScenarioPage.id,
              linkUrl: redirectContents?.linkUrl,
              isInheritQuery: redirectContents?.isInheritQuery ? 1 : 0,
              ...rest,
            },
            withOriginal: abtestScenarioPageGroup.deliverKind === "WEIGHT",
            originalInput: {
              abtestScenarioPageGroupId: abtestScenarioPageGroup.id,
              weight: originalPatternWeight,
            },
          },
        });
        const updatedAbtestScenarioPage =
          res.updateAbtestScenarioPage?.abtestScenarioPage;

        if (!updatedAbtestScenarioPage) {
          throw new Error("assertion failed");
        }

        switch (values.kind) {
          case "SCENARIO":
            if (!!scenarioContents && scenarioContents.length > 0) {
              try {
                const mutations: Promise<{}>[] = scenarioContents.map(
                  (content) => {
                    const uploadables: Record<string, File> = {};
                    if (content.image) {
                      uploadables["variables.input.image"] = content.image;
                    }
                    if (!!content.id) {
                      return updateImageContentMutate({
                        variables: {
                          input: {
                            abtestScenarioPageContentId: content.id,
                            beforeSrc: content.beforeSrc || "",
                          },
                        },
                        uploadables,
                      });
                    }
                    return addImageContentMutate({
                      variables: {
                        input: {
                          abtestScenarioPageId: updatedAbtestScenarioPage.id,
                          beforeSrc: content.beforeSrc || "",
                        },
                      },
                      uploadables,
                    });
                  }
                );
                await delayChunkPromise(mutations);

                const ids = scenarioContents
                  .map((scenarioContent) => scenarioContent.id || "")
                  .filter((id) => id !== "");
                const deleteTargetIds = scenarioContentIds.filter(
                  (id) => !includes(id, ids)
                );

                const deleteMutations: Promise<{}>[] = deleteTargetIds.map(
                  (deleteTargetId) => {
                    return deleteMutate({
                      variables: {
                        input: {
                          abtestScenarioPageContentId: deleteTargetId,
                        },
                      },
                    });
                  }
                );
                await delayChunkPromise(deleteMutations);
              } catch (err) {
                throw new Error(err);
              }
            }
            break;
          case "TEXT_REPLACE":
            if (!!textReplaceContents && textReplaceContents.length > 0) {
              try {
                const mutations: Promise<{}>[] = textReplaceContents.map(
                  (textReplaceContent) => {
                    if (!!textReplaceContent.id) {
                      return updateTextReplaceContentMutate({
                        variables: {
                          input: {
                            abtestScenarioPageContentId: textReplaceContent.id,
                            targetType: textReplaceContent.targetType,
                            actionType: textReplaceContent.actionType,
                            targetValue: textReplaceContent.targetValue,
                            targetAttribute: textReplaceContent.targetAttribute,
                            replaceValue: textReplaceContent.replaceValue,
                          },
                        },
                      });
                    }
                    return addTextReplaceContentMutate({
                      variables: {
                        input: {
                          abtestScenarioPageId: updatedAbtestScenarioPage.id,
                          targetType: textReplaceContent.targetType,
                          actionType: textReplaceContent.actionType,
                          targetValue: textReplaceContent.targetValue,
                          targetAttribute: textReplaceContent.targetAttribute,
                          replaceValue: textReplaceContent.replaceValue,
                        },
                      },
                    });
                  }
                );
                await delayChunkPromise(mutations);

                const ids = textReplaceContents
                  .map((textReplaceContent) => textReplaceContent.id || "")
                  .filter((id) => id !== "");
                const deleteTargetIds = scenarioContentIds.filter(
                  (id) => !includes(id, ids)
                );

                const deleteMutations: Promise<{}>[] = deleteTargetIds.map(
                  (deleteTargetId) => {
                    return deleteMutate({
                      variables: {
                        input: {
                          abtestScenarioPageContentId: deleteTargetId,
                        },
                      },
                    });
                  }
                );
                await delayChunkPromise(deleteMutations);
              } catch (err) {
                throw new Error(err);
              }
            }
            break;
          case "REDIRECT":
            break;
          default:
            throw new Error("asserion failed");
        }

        navigate(
          `/sites/${siteSlug}/abtests/${abtestSlug}/patterns/${updatedAbtestScenarioPage.slug}`
        );
      } catch (err) {
        onFormError(err, setErrors);
      }
      return Promise.resolve();
    },
    [
      abtestScenarioPage.id,
      abtestScenarioPageGroup.deliverKind,
      abtestScenarioPageGroup.id,
      abtestSlug,
      mutate,
      navigate,
      addImageContentMutate,
      addTextReplaceContentMutate,
      updateImageContentMutate,
      updateTextReplaceContentMutate,
      onFormError,
      siteSlug,
      scenarioContentIds,
      deleteMutate,
    ]
  );

  const scenarioContents = useMemo(() => {
    if (abtestScenarioPage.kind === "SCENARIO") {
      return abtestScenarioPage.abtestScenarioPageContents?.edges?.map(
        (edge) => {
          if (!edge || !edge.node) throw new Error("assertion failed");
          const node = edge.node;
          if (!node) throw new Error("assertion failed");
          return node;
        }
      );
    }
    return undefined;
  }, [
    abtestScenarioPage.abtestScenarioPageContents?.edges,
    abtestScenarioPage.kind,
  ]);

  const textReplaceContents = useMemo(() => {
    if (abtestScenarioPage.kind === "TEXT_REPLACE") {
      return abtestScenarioPage.abtestScenarioPageContents?.edges?.map(
        (edge) => {
          if (!edge || !edge.node) throw new Error("assertion failed");
          const node = edge.node;
          if (!node) throw new Error("assertion failed");
          return {
            id: node.id,
            targetType: node.targetType,
            actionType: node.actionType,
            targetValue: node.targetValue,
            targetAttribute: node.targetAttribute,
            replaceValue: node.replaceValue,
          };
        }
      );
    }
    return undefined;
  }, [
    abtestScenarioPage.abtestScenarioPageContents?.edges,
    abtestScenarioPage.kind,
  ]);

  const redirectContents = useMemo(() => {
    if (abtestScenarioPage.kind === "REDIRECT") {
      return {
        linkUrl: abtestScenarioPage.linkUrl,
        isInheritQuery: abtestScenarioPage.isInheritQuery,
      };
    }
    return undefined;
  }, [
    abtestScenarioPage.isInheritQuery,
    abtestScenarioPage.kind,
    abtestScenarioPage.linkUrl,
  ]);

  const initialValues: ABTestPatternFormValues = {
    id: abtestScenarioPage.id,
    title: abtestScenarioPage.title,
    kind: abtestScenarioPage.kind,
    scenarioContents,
    textReplaceContents,
    redirectContents,
    weight: abtestScenarioPage.weight,
    originalPatternWeight:
      abtestScenarioPageGroup.originalAbtestScenarioPage.weight || 0,
  };

  return (
    <PageLayout
      title="A/Bテスト パターン編集"
      breadcrumbs={[
        { label: "A/Bテスト一覧", path: `/sites/${siteSlug}/abtests` },
        {
          label: "A/Bテスト詳細",
          path: `/sites/${siteSlug}/abtests/${abtestSlug}`,
        },
        {
          label: "A/Bテスト パターン編集",
          path: `/sites/${siteSlug}/abtests/${abtestSlug}/patterns/${patternSlug}/edit`,
        },
      ]}
    >
      <HStack>
        <Box>{`実施グループ: ${abtestScenarioPageGroup?.title}`}</Box>
      </HStack>
      <HStack>
        <Box>{`実施ファネル: ${abtestScenarioPageGroup?.page.funnel.name}`}</Box>
        <URLItem url={abtestScenarioPageGroup?.page.funnel.siteUrl || ""} />
      </HStack>
      <HStack>
        <Box>
          {`実施ページ: ${
            abtestScenarioPageGroup?.page.kind === "FORM"
              ? abtestScenarioPageGroup?.page.name +
                `(${abtestScenarioPageGroup?.page.sortNumber - 1})`
              : abtestScenarioPageGroup?.page.name
          }`}
        </Box>
        <URLItem url={abtestScenarioPageGroup?.page.url || ""} />
      </HStack>

      <Box my="16px" p="16px">
        <ABTestPatternFormContainer
          initialValues={initialValues}
          enableWeightFields={abtestScenarioPageGroup?.deliverKind === "WEIGHT"}
          onCancelClick={handleCancel}
          onSubmit={handleSubmit}
        />
      </Box>
    </PageLayout>
  );
};
