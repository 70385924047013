/**
 * @generated SignedSource<<070218c80c8521334a8a4caffecd53a1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PopupSetListPageContainer_Query$variables = {
  siteSlug: string;
  popupGroupSetId: string;
};
export type PopupSetListPageContainer_QueryVariables = PopupSetListPageContainer_Query$variables;
export type PopupSetListPageContainer_Query$data = {
  readonly site: {
    readonly id: string;
    readonly slug: string;
    readonly name: string;
    readonly popupGroupSet: {
      readonly " $fragmentSpreads": FragmentRefs<"PopupSetListPage_popupGroupSet">;
    };
  };
};
export type PopupSetListPageContainer_QueryResponse = PopupSetListPageContainer_Query$data;
export type PopupSetListPageContainer_Query = {
  variables: PopupSetListPageContainer_QueryVariables;
  response: PopupSetListPageContainer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "popupGroupSetId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "siteSlug"
},
v2 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "siteSlug"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "popupGroupSetId"
  }
],
v7 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 120
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PopupSetListPageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "PopupGroupSet",
            "kind": "LinkedField",
            "name": "popupGroupSet",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "PopupSetListPage_popupGroupSet"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PopupSetListPageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "PopupGroupSet",
            "kind": "LinkedField",
            "name": "popupGroupSet",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "PopupSetConnection",
                "kind": "LinkedField",
                "name": "popupSets",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PopupSetEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PopupSet",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "memo",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": "popupSets(first:120)"
              },
              {
                "alias": null,
                "args": (v7/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "PopupSetListPage_popupSets",
                "kind": "LinkedHandle",
                "name": "popupSets"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e96b16792f6aced9f9e72f8d44d1aefe",
    "id": null,
    "metadata": {},
    "name": "PopupSetListPageContainer_Query",
    "operationKind": "query",
    "text": "query PopupSetListPageContainer_Query(\n  $siteSlug: String!\n  $popupGroupSetId: ID!\n) {\n  site(slug: $siteSlug) {\n    id\n    slug\n    name\n    popupGroupSet(id: $popupGroupSetId) {\n      ...PopupSetListPage_popupGroupSet\n      id\n    }\n  }\n}\n\nfragment PopupSetListPage_popupGroupSet on PopupGroupSet {\n  id\n  name\n  popupSets(first: 120) {\n    totalCount\n    edges {\n      node {\n        id\n        name\n        memo\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "475c1de28a74330fe14e382598c5bdea";

export default node;
