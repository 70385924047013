import { groupBy, includes, prop, uniqBy } from "ramda";
import { FC, useCallback, useMemo, useState } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { GraphQLEnums } from "~/src/__generated__/GraphQLEnums";
import { Gender } from "~/src/__generated__/schema";
import type { GenderReportGraph_site$key } from "~/src/__relay_artifacts__/GenderReportGraph_site.graphql";

import { AreaGraph } from "../AreaGraph";
import {
  MeasureKey,
  findGraphTitleByMeasureKey,
  generateGraphDataArray,
  isMeasureKey,
  measureOptions,
} from "../utils";

export type Props = { siteRef: GenderReportGraph_site$key };

const fragment = graphql`
  fragment GenderReportGraph_site on Site {
    reportGender(input: $input)
  }
`;

type FormValues = {
  genders: Gender[];
  measureKey: MeasureKey | null;
};

export const GenderReportGraph: FC<Props> = ({ siteRef }) => {
  const { reportGender } = useFragment<typeof siteRef>(fragment, siteRef);

  const [formValues, setFormValues] = useState<FormValues>({
    genders: [],
    measureKey: null,
  });

  const genderOptions = useMemo(() => {
    const uniqueGenders = uniqBy<any, any>(
      prop("gender"),
      reportGender.daily_report
    );
    return uniqueGenders.map((row) => ({
      value: row.gender,
      label: GraphQLEnums.Gender.find((gender) => gender.value === row.gender)
        ?.label,
    }));
  }, [reportGender.daily_report]);

  const graphDataArray = useMemo(() => {
    if (formValues.genders.length === 0) {
      return generateGraphDataArray(
        [groupBy<any, any>(prop("access_date"), reportGender.daily_report)],
        formValues.measureKey || "UNIQUE_USER"
      );
    }
    const reports = reportGender.daily_report as any[];
    const filteredReports = reports.filter((row) =>
      includes(row.gender, formValues.genders)
    );

    const reportsByGender = groupBy<any, any>(prop("gender"), filteredReports);
    const reportsByGenderAndDate: Record<any, any[]>[] = [];
    Object.keys(reportsByGender).forEach((key) => {
      reportsByGenderAndDate.push(
        groupBy<any, any>(prop("access_date"), reportsByGender[key])
      );
    });

    return generateGraphDataArray(
      reportsByGenderAndDate,
      formValues.measureKey || "UNIQUE_USER"
    );
  }, [formValues.genders, formValues.measureKey, reportGender.daily_report]);

  const handleSubmit = useCallback(({ genders, measureKey }) => {
    if (measureKey === null || isMeasureKey(measureKey, measureOptions)) {
      setFormValues({ genders, measureKey });
    }
  }, []);

  return (
    <AreaGraph
      title={`日別推移｜${findGraphTitleByMeasureKey(formValues.measureKey)}`}
      label={findGraphTitleByMeasureKey(formValues.measureKey)}
      dataArray={graphDataArray}
      graphCustomizedButtonProps={{
        selectFields: [
          {
            name: "genders",
            label: "性別",
            options: genderOptions,
            isMultiple: true,
          },
          {
            name: "measureKey",
            label: "項目",
            options: measureOptions,
            isMultiple: false,
          },
        ],
        formValues,
        onSubmit: handleSubmit,
      }}
    />
  );
};
