import { FC } from "react";

import { ArchiveButton } from "./ArchiveButton";
import { UnarchiveButton } from "./UnarchiveButton";

type Props = {
  id: string;
  isArchive: boolean;
};

export const ArchiveOrUnarchiveButton: FC<Props> = ({ id, isArchive }) => {
  return isArchive ? <UnarchiveButton id={id} /> : <ArchiveButton id={id} />;
};
