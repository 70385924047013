import { HStack } from "@chakra-ui/react";
import { FilterAltOutlined as FilterAltOutlinedIcon } from "@mui/icons-material";
import { FC, Suspense, useMemo } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";

import { GraphQLEnums } from "~/src/__generated__/GraphQLEnums";
import { PopupDefaultReportFilterButton_Query } from "~/src/__relay_artifacts__/PopupDefaultReportFilterButton_Query.graphql";
import { IconButton } from "~/src/components/common/IconButton";
import { Modal } from "~/src/components/common/Modal";
import { PageLoading } from "~/src/components/common/PageLoading";
import { FilteredTag } from "~/src/components/features/filter";
import { useOpenState } from "~/src/lib/hooks";

import { PopupDefaultReportFilterButtonForm } from "./PopupDefaultReportFilterButtonForm";
import { PopupReportFilterFormValues as FormValues } from "./types";

export type Props = {
  filterValues: FormValues;
  onSubmit: (values: FormValues) => Promise<void> | void;
};

const query = graphql`
  query PopupDefaultReportFilterButton_Query($slug: String!) {
    sources {
      edges {
        node {
          id
          name
        }
      }
    }
    site(slug: $slug) {
      funnels {
        edges {
          node {
            id
            name
          }
        }
      }
      popups {
        edges {
          node {
            id
            name
            page {
              kind
              name
              sortNumber
              funnel {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const PopupDefaultReportFilterButton: FC<Props> = ({
  filterValues,
  onSubmit,
}) => {
  const { siteSlug = "" } = useParams();
  const { isOpen, onClose, onOpen } = useOpenState();

  const data = useLazyLoadQuery<PopupDefaultReportFilterButton_Query>(query, {
    slug: siteSlug,
  });

  const sourceOptions = useMemo(
    () => [
      { label: "すべて", value: null },
      ...(data.sources.edges || []).map((edge) => {
        const node = edge?.node;
        if (!node) throw new Error("assertion failed");
        return { label: node.name, value: node.id };
      }),
    ],
    [data.sources.edges]
  );

  const funnelOptions = useMemo(() => {
    return (
      data.site.funnels?.edges?.map((edge) => {
        const node = edge?.node;
        if (!node) throw new Error("assertion failed");
        return { label: node.name, value: node.id };
      }) || []
    );
  }, [data.site.funnels]);

  const pageKindOptions = useMemo(
    () => [{ label: "すべて", value: null }, ...GraphQLEnums.PageKind],
    []
  );

  const deviceOptions = useMemo(
    () => [{ label: "すべて", value: null }, ...GraphQLEnums.Device],
    []
  );

  const popupOptions = useMemo(() => {
    return [
      ...(data.site.popups.edges || []).map((edge) => {
        const node = edge?.node;
        if (!node) throw new Error("assertion failed");

        const pageName =
          node.page.kind === "FORM"
            ? `${node.page.name}(${(node.page.sortNumber - 1).toString()})`
            : node.page.name;
        return {
          label: `${node.name || ""}(${node.page.funnel.name})[${pageName}]`,
          value: node.id,
          funnelId: node.page.funnel.id,
          pageKind: node.page.kind,
        };
      }),
    ];
  }, [data.site.popups]);

  return (
    <HStack>
      {filterValues.funnelIds.length > 0 &&
        filterValues.funnelIds.map((funnelId, index) => (
          <FilteredTag
            key={index}
            name="ファネル"
            value={funnelId}
            options={funnelOptions}
          />
        ))}
      {filterValues.pageKind && (
        <FilteredTag
          name="ページタイプ"
          value={filterValues.pageKind}
          options={pageKindOptions}
        />
      )}
      {filterValues.popupIds.length > 0 &&
        filterValues.popupIds.map((popupId, index) => (
          <FilteredTag
            key={index}
            name="ポップアップ"
            value={popupId}
            options={popupOptions}
          />
        ))}
      {filterValues.sourceId && (
        <FilteredTag
          name="流入元"
          value={filterValues.sourceId}
          options={sourceOptions}
        />
      )}
      {filterValues.device && (
        <FilteredTag
          name="デバイス"
          value={filterValues.device}
          options={deviceOptions}
        />
      )}
      <IconButton
        label="フィルタ"
        size="24px"
        icon={<FilterAltOutlinedIcon htmlColor="#282828" />}
        isActive={
          Object.values(filterValues).some((value) => value != null) || isOpen
        }
        onClick={onOpen}
      />
      <Modal title="フィルタ" isOpen={isOpen} onClose={onClose}>
        <Suspense fallback={<PageLoading />}>
          <PopupDefaultReportFilterButtonForm
            onSubmit={onSubmit}
            onClose={onClose}
            filterValues={filterValues}
          />
        </Suspense>
      </Modal>
    </HStack>
  );
};
