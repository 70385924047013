import { useState } from "react";
import { useMount, useUpdateEffect } from "react-use";

type Parameters = {
  cacheKey: string;
  initialValues: number[];
};

export const useReportDisableColumnsCache = ({
  cacheKey,
  initialValues,
}: Parameters) => {
  const sessionStorageKey = `report-disable-columns-of-${cacheKey}`;
  const [disableColumns, setDisableColumns] = useState<number[]>(initialValues);

  // マウント時に sessionStorage のキャッシュから値を復元する
  useMount(() => {
    try {
      const cache = sessionStorage.getItem(sessionStorageKey);
      cache && setDisableColumns(JSON.parse(cache));
    } catch {
      sessionStorage.removeItem(sessionStorageKey);
    }
  });

  // アイテムが更新された際に sessionStorage にキャッシュを保存する
  useUpdateEffect(() => {
    const jsonString = JSON.stringify(disableColumns);
    sessionStorage.setItem(sessionStorageKey, jsonString);
  }, [sessionStorageKey, disableColumns]);

  return {
    disableColumns,
    setDisableColumns,
  };
};
