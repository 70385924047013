import { useToast } from "@chakra-ui/toast";
import {
  ArchiveOutlined as ArchiveOutlinedIcon,
  ContentCopyOutlined as ContentCopyOutlinedIcon,
  DeleteOutline as DeleteOutlineIcon,
  EditOutlined as EditOutlinedIcon,
  UnarchiveOutlined as UnarchiveOutlinedIcon,
} from "@mui/icons-material";
import { FC, useCallback, useMemo } from "react";
import { graphql } from "react-relay";

import { FunnelABTestGroupTableActions_CopyMutation as CopyMutation } from "~/src/__relay_artifacts__/FunnelABTestGroupTableActions_CopyMutation.graphql";
import { FunnelABTestGroupTableActions_DeleteMutation as DeleteMutation } from "~/src/__relay_artifacts__/FunnelABTestGroupTableActions_DeleteMutation.graphql";
import { TableActions } from "~/src/components/common/tables/TableActions";
import {
  useArchiveHandler,
  useUnarchiveHandler,
} from "~/src/components/features/archive";
import {
  CopyModal,
  FormValues,
} from "~/src/components/features/global/CopyModal";
import { DeleteConfirmDialog } from "~/src/components/features/global/DeleteConfirmDialog";
import { useOpenState } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";

type Props = {
  abtestScenarioPageGroup: { id: string; title: string; slug: string };
  currentValues: FormValues;
  connectionId: string;
  filtered: boolean;
  siteSlug: string;
};

const deleteMutation = graphql`
  mutation FunnelABTestGroupTableActions_DeleteMutation(
    $connections: [ID!]!
    $input: DeleteAbtestScenarioPageGroupInput!
  ) {
    deleteAbtestScenarioPageGroup(input: $input) {
      deletedAbtestScenarioPageGroupId @deleteEdge(connections: $connections)
    }
  }
`;

const copyMutation = graphql`
  mutation FunnelABTestGroupTableActions_CopyMutation(
    $connections: [ID!]!
    $input: CopyAbtestScenarioPageGroupInput!
  ) {
    copyAbtestScenarioPageGroup(input: $input) {
      abtestScenarioPageGroup
        @appendNode(
          connections: $connections
          edgeTypeName: "AbtestScenarioPageGroupEdge"
        ) {
        abtestScenarioPages(exceptKind: DEFAULT) {
          totalCount
        }
        endDate
        id
        memo
        slug
        startDate
        status
        title
        updatedAt
        isArchive
        judgeStatus
        page {
          id
        }
      }
    }
  }
`;

export const FunnelABTestGroupTableActions: FC<Props> = ({
  abtestScenarioPageGroup,
  connectionId,
  currentValues,
  filtered,
  siteSlug,
}) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useOpenState();
  const copyModalOpenState = useOpenState();

  const deleteMutate = useMutationCommit<DeleteMutation>(deleteMutation);
  const copyMutate = useMutationCommit<CopyMutation>(copyMutation);

  const handleArchive = useArchiveHandler(
    abtestScenarioPageGroup.id,
    connectionId
  );
  const handleUnarchive = useUnarchiveHandler(
    abtestScenarioPageGroup.id,
    connectionId
  );

  const handleDelete = useCallback(async () => {
    try {
      const res = await deleteMutate({
        variables: {
          input: {
            abtestScenarioPageGroupId: abtestScenarioPageGroup.id,
          },
          connections: [connectionId],
        },
      });
      const deletedAbtestScenarioPageGroupId =
        res.deleteAbtestScenarioPageGroup?.deletedAbtestScenarioPageGroupId;
      if (!deletedAbtestScenarioPageGroupId)
        throw new Error("assertion failed");

      toast({
        title: `${abtestScenarioPageGroup.title}を削除しました`,
        status: "success",
      });
    } catch (err) {
      toast({ title: "削除に失敗しました", status: "error" });
    }
  }, [
    connectionId,
    deleteMutate,
    abtestScenarioPageGroup.id,
    abtestScenarioPageGroup.title,
    toast,
  ]);

  const handleCopy = useCallback(
    async (pageId: string) => {
      try {
        const res = await copyMutate({
          variables: {
            input: {
              abtestScenarioPageGroupId: abtestScenarioPageGroup.id,
              pageId,
            },
            connections: [connectionId],
          },
        });
        const copiedAbtestScenarioPageGroup =
          res.copyAbtestScenarioPageGroup?.abtestScenarioPageGroup;
        if (!copiedAbtestScenarioPageGroup) throw new Error("assertion failed");

        toast({
          title: `${abtestScenarioPageGroup.title}をコピーしました`,
          status: "success",
        });
      } catch (err) {
        toast({ title: "コピーに失敗しました", status: "error" });
      }
    },
    [
      abtestScenarioPageGroup.id,
      abtestScenarioPageGroup.title,
      connectionId,
      copyMutate,
      toast,
    ]
  );

  const defaultActionMenus = useMemo(
    () => [
      {
        label: "編集",
        icon: <EditOutlinedIcon />,
        to: `/sites/${siteSlug}/abtests/${abtestScenarioPageGroup.slug}/edit`,
      },
      {
        label: "コピー",
        icon: <ContentCopyOutlinedIcon />,
        onClick: () => copyModalOpenState.onOpen(),
      },
      {
        label: "アーカイブ",
        icon: <ArchiveOutlinedIcon />,
        onClick: () => handleArchive(),
      },
    ],
    [handleArchive, copyModalOpenState, siteSlug, abtestScenarioPageGroup.slug]
  );

  const archivedActionMenus = useMemo(
    () => [
      {
        label: "編集",
        icon: <EditOutlinedIcon />,
        to: `/sites/${siteSlug}/abtests/${abtestScenarioPageGroup.slug}/edit`,
      },
      {
        label: "アーカイブから戻す",
        icon: <UnarchiveOutlinedIcon />,
        onClick: () => handleUnarchive(),
      },
      {
        label: "削除",
        icon: <DeleteOutlineIcon />,
        onClick: () => onOpen(),
      },
    ],
    [handleUnarchive, onOpen, siteSlug, abtestScenarioPageGroup.slug]
  );

  return (
    <>
      <TableActions
        actionMenus={filtered ? archivedActionMenus : defaultActionMenus}
      />
      <DeleteConfirmDialog
        name={abtestScenarioPageGroup.title}
        isOpen={isOpen}
        onClose={onClose}
        onDelete={handleDelete}
      />
      <CopyModal
        isOpen={copyModalOpenState.isOpen}
        onClose={copyModalOpenState.onClose}
        initialValues={currentValues}
        onSubmit={handleCopy}
      />
    </>
  );
};
