/**
 * @generated SignedSource<<98752d753923a6a1e2a627a9f1e13256>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ChatMessageFlowNodeKind = "TEXT" | "IMAGE" | "SUBMIT" | "REPLY" | "%future added value";
export type ChatMessageKind = "TEXT" | "IMAGE" | "SUBMIT" | "%future added value";
export type ChatMessageReplyGroupKind = "TEXT_BOX" | "RADIO_BUTTON" | "CHECK_BOX" | "SELECT_BOX" | "TEXT_AREA" | "%future added value";
export type ChatMessageReplyKind = "CUSTOM_TEXT" | "API" | "OPTION" | "NAME" | "%future added value";
export type ChatbotActionKind = "BUTTON_CLICK" | "DURATION" | "%future added value";
export type ChatbotBotKind = "FORM" | "QUESTION" | "FORM_AND_QUESTION" | "QUESTION_AND_FORM" | "%future added value";
export type ChatbotColorKind = "DEFAULT" | "GRAY" | "BLACK" | "RED" | "BLUE" | "GREEN" | "YELLOW" | "ORANGE" | "PINK" | "DARK_PINK" | "CUSTOM_COLOR" | "%future added value";
export type ChatbotDisplayKind = "RIGHT_BOTTOM" | "LEFT_BOTTOM" | "RIGHT_TOP" | "LEFT_TOP" | "%future added value";
export type ChatbotKind = "A_PATTERN" | "B_PATTERN" | "%future added value";
export type ChatbotSubmitMethodKind = "GET" | "POST" | "%future added value";
export type DeliverKind = "EQUAL" | "WEIGHT" | "%future added value";
export type DistributionStatus = "ACTIVE" | "INACTIVE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ChatbotPatternEditScreenContainer_chatbot$data = {
  readonly id: string;
  readonly chatbotGroup: {
    readonly id: string;
    readonly deliverKind: DeliverKind;
    readonly originalChatbot: {
      readonly weight: number | null;
    };
  };
  readonly actionKind: ChatbotActionKind | null;
  readonly botImageAlt: string | null;
  readonly botImageCache: string | null;
  readonly botImageUrl: string;
  readonly botKind: ChatbotBotKind | null;
  readonly botTextColor: string;
  readonly botBackgroundColor: string;
  readonly colorKind: ChatbotColorKind | null;
  readonly chatFormColor: string;
  readonly clientTextColor: string;
  readonly clientBackgroundColor: string;
  readonly displayKind: ChatbotDisplayKind | null;
  readonly honeycombCode: string | null;
  readonly kind: ChatbotKind | null;
  readonly name: string;
  readonly notFoundMessage: string | null;
  readonly openButtonText: string | null;
  readonly openButtonImageCache: string | null;
  readonly openButtonImageUrl: string;
  readonly slug: string;
  readonly status: DistributionStatus;
  readonly title: string | null;
  readonly titleTextColor: string | null;
  readonly timing: number | null;
  readonly toFormButtonImageCache: string | null;
  readonly weight: number | null;
  readonly submitMethodKind: ChatbotSubmitMethodKind | null;
  readonly xOffset: number | null;
  readonly xOffsetUnit: string | null;
  readonly yOffset: number | null;
  readonly yOffsetUnit: string | null;
  readonly windowColor: string;
  readonly questionCsvUrl: string | null;
  readonly questionFinishMessage: string | null;
  readonly toFormButtonImageUrl: string;
  readonly chatMessageFlowNodes: ReadonlyArray<{
    readonly nodeId: string;
    readonly kind: ChatMessageFlowNodeKind;
    readonly position: {
      readonly x: number;
      readonly y: number;
    };
    readonly chatMessage: {
      readonly id: string;
      readonly kind: ChatMessageKind;
      readonly message: string | null;
      readonly isHasReply: boolean;
      readonly isSaveReply: boolean;
      readonly isInheritQuery: boolean;
      readonly isTransferReply: boolean;
      readonly displaySpeed: number;
      readonly coordinate: string;
      readonly messageIndex: number;
      readonly nextMessageIndex: number;
      readonly submitUrl: string | null;
      readonly chatMessageImage: {
        readonly image: string | null;
        readonly alt: string | null;
        readonly id: string;
        readonly imageUrl: string | null;
        readonly isInheritQuery: boolean;
        readonly linkUrl: string | null;
      } | null;
    };
    readonly replyGroup: {
      readonly id: string;
      readonly kind: ChatMessageReplyGroupKind;
      readonly label: string | null;
      readonly number: number;
      readonly divergingPoint: boolean;
      readonly coordinate: string;
      readonly replies: ReadonlyArray<{
        readonly id: string;
        readonly kind: ChatMessageReplyKind;
        readonly messageIndex: number;
        readonly nextMessageIndex: number;
        readonly errorMessage: string | null;
        readonly label: string | null;
        readonly name: string;
        readonly value: string | null;
        readonly regularExpression: string | null;
        readonly placeholder: string | null;
        readonly replyNodeId: string;
        readonly apiAction: string | null;
        readonly chatbotApiResults: ReadonlyArray<{
          readonly nextMessageNumber: number;
          readonly result: string;
        }>;
      }>;
    } | null;
  }>;
  readonly chatMessageReplyFlowNodes: ReadonlyArray<{
    readonly nodeId: string;
    readonly nodeLabel: string;
    readonly parentNode: string | null;
    readonly kind: ChatMessageFlowNodeKind;
  }>;
  readonly chatMessageFlowEdges: ReadonlyArray<{
    readonly source: string;
    readonly target: string;
  }>;
  readonly chatMessageReplyFlowEdges: ReadonlyArray<{
    readonly source: string;
    readonly target: string;
  }>;
  readonly " $fragmentType": "ChatbotPatternEditScreenContainer_chatbot";
};
export type ChatbotPatternEditScreenContainer_chatbot = ChatbotPatternEditScreenContainer_chatbot$data;
export type ChatbotPatternEditScreenContainer_chatbot$key = {
  readonly " $data"?: ChatbotPatternEditScreenContainer_chatbot$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChatbotPatternEditScreenContainer_chatbot">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "weight",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isInheritQuery",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "coordinate",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "messageIndex",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nextMessageIndex",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v10 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "source",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "target",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChatbotPatternEditScreenContainer_chatbot",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ChatbotGroup",
      "kind": "LinkedField",
      "name": "chatbotGroup",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deliverKind",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Chatbot",
          "kind": "LinkedField",
          "name": "originalChatbot",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actionKind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "botImageAlt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "botImageCache",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "botImageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "botKind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "botTextColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "botBackgroundColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "colorKind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "chatFormColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clientTextColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clientBackgroundColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayKind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "honeycombCode",
      "storageKey": null
    },
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notFoundMessage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "openButtonText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "openButtonImageCache",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "openButtonImageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "titleTextColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timing",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "toFormButtonImageCache",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "submitMethodKind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "xOffset",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "xOffsetUnit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "yOffset",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "yOffsetUnit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "windowColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "questionCsvUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "questionFinishMessage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "toFormButtonImageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ChatMessageFlowNode",
      "kind": "LinkedField",
      "name": "chatMessageFlowNodes",
      "plural": true,
      "selections": [
        (v4/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ChatMessageFlowNodePosition",
          "kind": "LinkedField",
          "name": "position",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "x",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "y",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ChatMessage",
          "kind": "LinkedField",
          "name": "chatMessage",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "message",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isHasReply",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isSaveReply",
              "storageKey": null
            },
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isTransferReply",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displaySpeed",
              "storageKey": null
            },
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "submitUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ChatMessageImage",
              "kind": "LinkedField",
              "name": "chatMessageImage",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "image",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "alt",
                  "storageKey": null
                },
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "imageUrl",
                  "storageKey": null
                },
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "linkUrl",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ChatMessageReplyGroup",
          "kind": "LinkedField",
          "name": "replyGroup",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/),
            (v9/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "number",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "divergingPoint",
              "storageKey": null
            },
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "ChatMessageReply",
              "kind": "LinkedField",
              "name": "replies",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v2/*: any*/),
                (v7/*: any*/),
                (v8/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "errorMessage",
                  "storageKey": null
                },
                (v9/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "value",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "regularExpression",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "placeholder",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "replyNodeId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "apiAction",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ChatbotApiResult",
                  "kind": "LinkedField",
                  "name": "chatbotApiResults",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "nextMessageNumber",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "result",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ChatMessageReplyFlowNode",
      "kind": "LinkedField",
      "name": "chatMessageReplyFlowNodes",
      "plural": true,
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nodeLabel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "parentNode",
          "storageKey": null
        },
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ChatMessageFlowEdge",
      "kind": "LinkedField",
      "name": "chatMessageFlowEdges",
      "plural": true,
      "selections": (v10/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ChatMessageReplyFlowEdge",
      "kind": "LinkedField",
      "name": "chatMessageReplyFlowEdges",
      "plural": true,
      "selections": (v10/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Chatbot",
  "abstractKey": null
};
})();

(node as any).hash = "62e5b7112900f5fefd728da13b78338e";

export default node;
