import { Box, Flex, HStack } from "@chakra-ui/react";
import { FC, useMemo } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";

import { PopupGroupSetDetailPageContainer_Query } from "~/src/__relay_artifacts__/PopupGroupSetDetailPageContainer_Query.graphql";
import { PageLayout } from "~/src/components/features/global/PageLayout";

import {
  PopupGroupSetDetailLinkedPopupGroupListTable,
  PopupGroupSetDetailSection,
} from "./presentations";

export type Props = {};

const query = graphql`
  query PopupGroupSetDetailPageContainer_Query(
    $siteSlug: String!
    $popupGroupSetId: ID!
  ) {
    site(slug: $siteSlug) {
      popupGroupSet(id: $popupGroupSetId) {
        id
        name
        memo
        ...PopupGroupSetDetailLinkedPopupGroupListTable_popupGroupSet
      }
    }
  }
`;

export const PopupGroupSetDetailPageContainer: FC<Props> = () => {
  const { siteSlug = "", popupGroupSetId = "" } = useParams();
  const { site } = useLazyLoadQuery<PopupGroupSetDetailPageContainer_Query>(
    query,
    {
      siteSlug,
      popupGroupSetId,
    }
  );

  const popupGroupSet = useMemo(() => {
    return site.popupGroupSet;
  }, [site.popupGroupSet]);

  const breadcrumbs = useMemo(
    () => [
      {
        label: "ポップアップセット一覧",
        path: `/sites/${siteSlug}/popup_group_sets`,
      },
      {
        label: "ポップアップセット詳細",
        path: `/sites/${siteSlug}/popup_group_sets/${popupGroupSetId}`,
      },
    ],
    [popupGroupSetId, siteSlug]
  );

  return (
    <PageLayout title="ポップアップセット詳細" breadcrumbs={breadcrumbs}>
      <Box mx={4}>
        <Flex alignItems="center">
          <HStack spacing={3}>{/* 編集 */}</HStack>
        </Flex>
        <HStack justifyContent="flex-end" my={4} spacing={4}></HStack>
        <Flex alignItems="center">
          <Box color="#282828" fontWeight="bold">
            {popupGroupSet.name}
          </Box>
          {/* <Spacer />
          <HStack spacing={3}>
            <NavLink
              to={`/sites/${siteSlug}/popup_groups/${popupGroupSlug}/patterns/new`}
            >
              <SolidButton>パターン登録</SolidButton>
            </NavLink>
          </HStack> */}
        </Flex>
        {/* <Flex justifyContent="flex-end" mt={4}>
          <HStack spacing="12px">
            <PopupPatternFilterButton
              filterValues={filterValues}
              onFilterChange={setFilterValues}
              onSubmit={setFilterValues}
            />
            <PopupDeliverWeightButton
              popups={popups}
              deliverKind={popupGroup.deliverKind || "EQUAL"}
              onSubmit={handleDeliverWeightSubmit}
            />
            <TableItemSelectButton
              allTableItems={allTableItems}
              selectedTableItems={selectedTableItems}
              onApplyClick={onApplyClick}
            />
          </HStack>
        </Flex> */}
        <PopupGroupSetDetailSection popupGroupSet={popupGroupSet} />
        <PopupGroupSetDetailLinkedPopupGroupListTable
          siteSlug={siteSlug}
          popupGroupSetRef={site.popupGroupSet}
        />
      </Box>
    </PageLayout>
  );
};
