import { VStack } from "@chakra-ui/react";
import { Form, Formik, FormikConfig } from "formik";
import { FC } from "react";

import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormControlGroup } from "~/src/components/common/forms/FormControlGroup";
import { FormikFormButtons } from "~/src/components/common/forms/FormikFormButtons";
import { FormikSelectField } from "~/src/components/common/forms/FormikSelectField";
import { FormikTextField } from "~/src/components/common/forms/FormikTextField";

import { FormValues, validationSchema } from "./validationSchema";

export type Props = {
  initialValues: FormValues;
  onCancelClick: () => void;
  onSubmit: FormikConfig<FormValues>["onSubmit"];
  sourceOptions: { label: string; value: string }[];
};

export const FunnelSourceForm: FC<Props> = ({
  initialValues,
  onCancelClick,
  onSubmit,
  sourceOptions,
}) => (
  <Formik<FormValues>
    enableReinitialize
    initialValues={initialValues}
    validationSchema={validationSchema}
    validateOnMount={false}
    validateOnChange={false}
    onSubmit={onSubmit}
  >
    <Form>
      <VStack spacing="24px" alignItems="flex-start">
        <FormControlGroup>
          <FormControl required label="流入元">
            <FormikSelectField
              mode="fill"
              name="source"
              options={sourceOptions}
            />
          </FormControl>
          <FormControl required label="パラメータ名">
            <FormikTextField type="text" mode="fill" name="parameterName" />
          </FormControl>
          <FormControl required label="値">
            <FormikTextField type="text" mode="fill" name="parameterValue" />
          </FormControl>
        </FormControlGroup>
        <FormikFormButtons onCancelClick={onCancelClick} />
      </VStack>
    </Form>
  </Formik>
);
