export const allTableItems = [
  { label: "実施ファネル", value: "funnelTitle" },
  { label: "実施ページ", value: "funnelPage" },
  { label: "パターン数", value: "patternCount" },
  { label: "開始日", value: "periodSince" },
  { label: "終了日", value: "periodTill" },
  { label: "最終更新日", value: "updatedAt" },
];

export const initialSelectedTableItems = allTableItems;
