/**
 * @generated SignedSource<<5226cd58af852974c78c1cbb35cecd14>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MonthlyReportTable_site$data = {
  readonly reportMonthly: any;
  readonly " $fragmentType": "MonthlyReportTable_site";
};
export type MonthlyReportTable_site = MonthlyReportTable_site$data;
export type MonthlyReportTable_site$key = {
  readonly " $data"?: MonthlyReportTable_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"MonthlyReportTable_site">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "input"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "MonthlyReportTable_site",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "input",
          "variableName": "input"
        }
      ],
      "kind": "ScalarField",
      "name": "reportMonthly",
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "324161d67eaf6e7fe52a29283a545e61";

export default node;
