import { Box, useToast } from "@chakra-ui/react";
import { FC, useCallback, useMemo } from "react";
import { graphql, useFragment } from "react-relay";
import { useNavigate } from "react-router";

import { FunnelSourceEditFormContainer_funnel$key } from "~/src/__relay_artifacts__/FunnelSourceEditFormContainer_funnel.graphql";
import { FunnelSourceEditFormContainer_Mutation } from "~/src/__relay_artifacts__/FunnelSourceEditFormContainer_Mutation.graphql";
import { FunnelSourceEditFormContainer_root$key } from "~/src/__relay_artifacts__/FunnelSourceEditFormContainer_root.graphql";
import { useMutationCommit } from "~/src/lib/react-relay";

import { PageLayout } from "../../global/PageLayout";

import { FunnelSourceForm, FunnelSourceFormProps } from "./presentations";

export type Props = {
  siteSlug: string;
  funnelSlug: string;
  sourceTagSlug: string;
  sourceRef: FunnelSourceEditFormContainer_root$key;
  funnelRef: FunnelSourceEditFormContainer_funnel$key;
};

const sourceFragment = graphql`
  fragment FunnelSourceEditFormContainer_root on Query {
    sources {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const sourceTagFragment = graphql`
  fragment FunnelSourceEditFormContainer_funnel on Funnel {
    sourceTag(slug: $sourceTagSlug) {
      id
      key
      value
      slug
      source {
        id
        name
      }
      pageUrl
    }
  }
`;

const mutation = graphql`
  mutation FunnelSourceEditFormContainer_Mutation(
    $input: UpdateSourceTagInput!
  ) {
    updateSourceTag(input: $input) {
      sourceTag {
        id
        key
        value
        slug
        source {
          id
          name
        }
        pageUrl
      }
    }
  }
`;

export const FunnelSourceEditFormContainer: FC<Props> = ({
  siteSlug,
  funnelSlug,
  sourceTagSlug,
  funnelRef,
  sourceRef,
}) => {
  const navigate = useNavigate();
  const toast = useToast();
  const funnel = useFragment(sourceTagFragment, funnelRef);
  const sources = useFragment(sourceFragment, sourceRef);
  const mutate =
    useMutationCommit<FunnelSourceEditFormContainer_Mutation>(mutation);

  const sourceTag = useMemo(() => {
    if (!funnel.sourceTag) throw new Error("assertion failed");
    return funnel.sourceTag;
  }, [funnel]);

  const sourceOptions = useMemo(() => {
    const edges = sources.sources.edges || [];
    return edges.map((edge) => {
      const node = edge?.node;
      if (!node) throw new Error("assertion failed");
      return { label: node.name, value: node.id };
    });
  }, [sources]);

  const initialValues = useMemo(
    () => ({
      source: sourceTag.source.id,
      parameterName: sourceTag.key || "",
      parameterValue: sourceTag.value || "",
    }),
    [sourceTag]
  );

  const breadcrumbs = useMemo(
    () => [
      { label: "設定", path: `/sites/${siteSlug}/settings` },
      {
        label: "流入元編集",
        path: `/sites/${siteSlug}/funnels/${funnelSlug}/sources/${sourceTagSlug}/edit`,
      },
    ],
    [funnelSlug, sourceTagSlug, siteSlug]
  );

  const handleSubmit = useCallback<FunnelSourceFormProps["onSubmit"]>(
    async ({ ...values }) => {
      try {
        const res = await mutate({
          variables: {
            input: {
              sourceTagId: sourceTag.id,
              sourceId: values.source,
              key: values.parameterName,
              value: values.parameterValue,
            },
          },
        });

        const updatedSourceTag = res.updateSourceTag?.sourceTag;
        if (!updatedSourceTag) {
          throw new Error("assertion failed");
        }
        toast({
          title: "流入元を更新しました",
          status: "success",
        });
        navigate(`/sites/${siteSlug}/funnels/${funnelSlug}/sources`);
      } catch (err) {
        toast({
          title: "流入元の更新に失敗しました",
          status: "error",
        });
      }
    },
    [sourceTag.id, mutate, toast, navigate, siteSlug, funnelSlug]
  );

  const handleCancel = useCallback(() => navigate(-1), [navigate]);

  return (
    <PageLayout title="流入元編集" breadcrumbs={breadcrumbs}>
      <Box mt="32px">
        <FunnelSourceForm
          initialValues={initialValues}
          onCancelClick={handleCancel}
          onSubmit={handleSubmit}
          sourceOptions={sourceOptions}
        />
      </Box>
    </PageLayout>
  );
};
