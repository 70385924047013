/**
 * @generated SignedSource<<a092d4e272e9f843121433fbbe005dae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CopyChatbotGroupInput = {
  clientMutationId?: string | null;
  chatbotGroupId: string;
};
export type ChatbotGroupCopyButton_Mutation$variables = {
  input: CopyChatbotGroupInput;
};
export type ChatbotGroupCopyButton_MutationVariables = ChatbotGroupCopyButton_Mutation$variables;
export type ChatbotGroupCopyButton_Mutation$data = {
  readonly copyChatbotGroup: {
    readonly chatbotGroup: {
      readonly id: string;
      readonly name: string;
      readonly slug: string;
    } | null;
  } | null;
};
export type ChatbotGroupCopyButton_MutationResponse = ChatbotGroupCopyButton_Mutation$data;
export type ChatbotGroupCopyButton_Mutation = {
  variables: ChatbotGroupCopyButton_MutationVariables;
  response: ChatbotGroupCopyButton_Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CopyChatbotGroupPayload",
    "kind": "LinkedField",
    "name": "copyChatbotGroup",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ChatbotGroup",
        "kind": "LinkedField",
        "name": "chatbotGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChatbotGroupCopyButton_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChatbotGroupCopyButton_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ab9e9eae218a0cca4abd8dff33553cd9",
    "id": null,
    "metadata": {},
    "name": "ChatbotGroupCopyButton_Mutation",
    "operationKind": "mutation",
    "text": "mutation ChatbotGroupCopyButton_Mutation(\n  $input: CopyChatbotGroupInput!\n) {\n  copyChatbotGroup(input: $input) {\n    chatbotGroup {\n      id\n      name\n      slug\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "32759523887c02fdfaa328cf97f94567";

export default node;
