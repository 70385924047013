/**
 * @generated SignedSource<<fbb2d3c4463ab8562076f3351289f249>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChatbotActionKind = "BUTTON_CLICK" | "DURATION" | "%future added value";
export type ChatbotBotKind = "FORM" | "QUESTION" | "FORM_AND_QUESTION" | "QUESTION_AND_FORM" | "%future added value";
export type ChatbotColorKind = "DEFAULT" | "GRAY" | "BLACK" | "RED" | "BLUE" | "GREEN" | "YELLOW" | "ORANGE" | "PINK" | "DARK_PINK" | "CUSTOM_COLOR" | "%future added value";
export type ChatbotDisplayKind = "RIGHT_BOTTOM" | "LEFT_BOTTOM" | "RIGHT_TOP" | "LEFT_TOP" | "%future added value";
export type ChatbotKind = "A_PATTERN" | "B_PATTERN" | "%future added value";
export type ChatbotSubmitMethodKind = "GET" | "POST" | "%future added value";
export type DistributionStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type ChatbotPatternDetailPageContainer_Query$variables = {
  slug: string;
};
export type ChatbotPatternDetailPageContainer_QueryVariables = ChatbotPatternDetailPageContainer_Query$variables;
export type ChatbotPatternDetailPageContainer_Query$data = {
  readonly chatbot: {
    readonly id: string;
    readonly actionKind: ChatbotActionKind | null;
    readonly botImageAlt: string | null;
    readonly botImageCache: string | null;
    readonly botImageUrl: string;
    readonly botKind: ChatbotBotKind | null;
    readonly botTextColor: string;
    readonly botBackgroundColor: string;
    readonly chatbotGroup: {
      readonly id: string;
    };
    readonly colorKind: ChatbotColorKind | null;
    readonly chatFormColor: string;
    readonly clientTextColor: string;
    readonly clientBackgroundColor: string;
    readonly displayKind: ChatbotDisplayKind | null;
    readonly honeycombCode: string | null;
    readonly kind: ChatbotKind | null;
    readonly name: string;
    readonly notFoundMessage: string | null;
    readonly openButtonText: string | null;
    readonly openButtonImageCache: string | null;
    readonly openButtonImageUrl: string;
    readonly page: {
      readonly id: string;
    } | null;
    readonly slug: string;
    readonly status: DistributionStatus;
    readonly title: string | null;
    readonly titleTextColor: string | null;
    readonly timing: number | null;
    readonly toFormButtonImageCache: string | null;
    readonly toFormButtonImageUrl: string;
    readonly weight: number | null;
    readonly submitMethodKind: ChatbotSubmitMethodKind | null;
    readonly xOffset: number | null;
    readonly xOffsetUnit: string | null;
    readonly yOffset: number | null;
    readonly yOffsetUnit: string | null;
    readonly windowColor: string;
    readonly questionFinishMessage: string | null;
    readonly questionCsvUrl: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"ChatbotFlowContainer_chatbot">;
  } | null;
};
export type ChatbotPatternDetailPageContainer_QueryResponse = ChatbotPatternDetailPageContainer_Query$data;
export type ChatbotPatternDetailPageContainer_Query = {
  variables: ChatbotPatternDetailPageContainer_QueryVariables;
  response: ChatbotPatternDetailPageContainer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actionKind",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "botImageAlt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "botImageCache",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "botImageUrl",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "botKind",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "botTextColor",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "botBackgroundColor",
  "storageKey": null
},
v10 = [
  (v2/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "ChatbotGroup",
  "kind": "LinkedField",
  "name": "chatbotGroup",
  "plural": false,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "colorKind",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chatFormColor",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientTextColor",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientBackgroundColor",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayKind",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "honeycombCode",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notFoundMessage",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "openButtonText",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "openButtonImageCache",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "openButtonImageUrl",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "concreteType": "Page",
  "kind": "LinkedField",
  "name": "page",
  "plural": false,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "titleTextColor",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timing",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "toFormButtonImageCache",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "toFormButtonImageUrl",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "weight",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "submitMethodKind",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "xOffset",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "xOffsetUnit",
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "yOffset",
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "yOffsetUnit",
  "storageKey": null
},
v38 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "windowColor",
  "storageKey": null
},
v39 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "questionFinishMessage",
  "storageKey": null
},
v40 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "questionCsvUrl",
  "storageKey": null
},
v41 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
},
v42 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "coordinate",
  "storageKey": null
},
v43 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "messageIndex",
  "storageKey": null
},
v44 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nextMessageIndex",
  "storageKey": null
},
v45 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v46 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "source",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "target",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChatbotPatternDetailPageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Chatbot",
        "kind": "LinkedField",
        "name": "chatbot",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          (v28/*: any*/),
          (v29/*: any*/),
          (v30/*: any*/),
          (v31/*: any*/),
          (v32/*: any*/),
          (v33/*: any*/),
          (v34/*: any*/),
          (v35/*: any*/),
          (v36/*: any*/),
          (v37/*: any*/),
          (v38/*: any*/),
          (v39/*: any*/),
          (v40/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ChatbotFlowContainer_chatbot"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChatbotPatternDetailPageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Chatbot",
        "kind": "LinkedField",
        "name": "chatbot",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          (v28/*: any*/),
          (v29/*: any*/),
          (v30/*: any*/),
          (v31/*: any*/),
          (v32/*: any*/),
          (v33/*: any*/),
          (v34/*: any*/),
          (v35/*: any*/),
          (v36/*: any*/),
          (v37/*: any*/),
          (v38/*: any*/),
          (v39/*: any*/),
          (v40/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ChatMessageFlowNode",
            "kind": "LinkedField",
            "name": "chatMessageFlowNodes",
            "plural": true,
            "selections": [
              (v41/*: any*/),
              (v18/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ChatMessageFlowNodePosition",
                "kind": "LinkedField",
                "name": "position",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "x",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "y",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ChatMessage",
                "kind": "LinkedField",
                "name": "chatMessage",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v18/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "message",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isHasReply",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isSaveReply",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isInheritQuery",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isTransferReply",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displaySpeed",
                    "storageKey": null
                  },
                  (v42/*: any*/),
                  (v43/*: any*/),
                  (v44/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "submitUrl",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ChatMessageReplyGroup",
                "kind": "LinkedField",
                "name": "replyGroup",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v18/*: any*/),
                  (v45/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "number",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "divergingPoint",
                    "storageKey": null
                  },
                  (v42/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ChatMessageReply",
                    "kind": "LinkedField",
                    "name": "replies",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v18/*: any*/),
                      (v43/*: any*/),
                      (v44/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "errorMessage",
                        "storageKey": null
                      },
                      (v45/*: any*/),
                      (v19/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "value",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "regularExpression",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "placeholder",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "replyNodeId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "apiAction",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ChatMessageReplyFlowNode",
            "kind": "LinkedField",
            "name": "chatMessageReplyFlowNodes",
            "plural": true,
            "selections": [
              (v41/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nodeLabel",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "parentNode",
                "storageKey": null
              },
              (v18/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ChatMessageFlowEdge",
            "kind": "LinkedField",
            "name": "chatMessageFlowEdges",
            "plural": true,
            "selections": (v46/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ChatMessageReplyFlowEdge",
            "kind": "LinkedField",
            "name": "chatMessageReplyFlowEdges",
            "plural": true,
            "selections": (v46/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b3fb5def04db02452722189d3a71c356",
    "id": null,
    "metadata": {},
    "name": "ChatbotPatternDetailPageContainer_Query",
    "operationKind": "query",
    "text": "query ChatbotPatternDetailPageContainer_Query(\n  $slug: String!\n) {\n  chatbot(slug: $slug) {\n    id\n    actionKind\n    botImageAlt\n    botImageCache\n    botImageUrl\n    botKind\n    botTextColor\n    botBackgroundColor\n    chatbotGroup {\n      id\n    }\n    colorKind\n    chatFormColor\n    clientTextColor\n    clientBackgroundColor\n    displayKind\n    honeycombCode\n    kind\n    name\n    notFoundMessage\n    openButtonText\n    openButtonImageCache\n    openButtonImageUrl\n    page {\n      id\n    }\n    slug\n    status\n    title\n    titleTextColor\n    timing\n    toFormButtonImageCache\n    toFormButtonImageUrl\n    weight\n    submitMethodKind\n    xOffset\n    xOffsetUnit\n    yOffset\n    yOffsetUnit\n    windowColor\n    questionFinishMessage\n    questionCsvUrl\n    ...ChatbotFlowContainer_chatbot\n  }\n}\n\nfragment ChatbotFlowContainer_chatbot on Chatbot {\n  chatMessageFlowNodes {\n    nodeId\n    kind\n    position {\n      x\n      y\n    }\n    chatMessage {\n      id\n      kind\n      message\n      isHasReply\n      isSaveReply\n      isInheritQuery\n      isTransferReply\n      displaySpeed\n      coordinate\n      messageIndex\n      nextMessageIndex\n      submitUrl\n    }\n    replyGroup {\n      id\n      kind\n      label\n      number\n      divergingPoint\n      coordinate\n      replies {\n        id\n        kind\n        messageIndex\n        nextMessageIndex\n        errorMessage\n        label\n        name\n        value\n        regularExpression\n        placeholder\n        replyNodeId\n        apiAction\n      }\n    }\n  }\n  chatMessageReplyFlowNodes {\n    nodeId\n    nodeLabel\n    parentNode\n    kind\n  }\n  chatMessageFlowEdges {\n    source\n    target\n  }\n  chatMessageReplyFlowEdges {\n    source\n    target\n  }\n}\n"
  }
};
})();

(node as any).hash = "0a3da79304ece0a97eeaf5844a8c6ea8";

export default node;
