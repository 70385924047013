import { groupBy, includes, prop } from "ramda";
import { FC, useCallback, useMemo, useState } from "react";
import { graphql, useFragment } from "react-relay";

import { FunnelReportGraph_site$key } from "~/src/__relay_artifacts__/FunnelReportGraph_site.graphql";

import { AreaGraph } from "../AreaGraph";
import {
  MeasureKey,
  findGraphTitleByMeasureKey,
  generateGraphDataArray,
  isMeasureKey,
  measureOptions,
} from "../utils";

import { Funnel } from "./types";

const fragment = graphql`
  fragment FunnelReportGraph_site on Site {
    reportFunnel(input: $input)
  }
`;

export type Prop = {
  funnels: Funnel[];
  siteRef: FunnelReportGraph_site$key;
};

type FormValues = {
  funnelIds: string[];
  measureKey: MeasureKey | null;
};

export const FunnelReportGraph: FC<Prop> = ({ funnels, siteRef }) => {
  const { reportFunnel } = useFragment<typeof siteRef>(fragment, siteRef);

  const [formValues, setFormValues] = useState<FormValues>({
    funnelIds: [],
    measureKey: null,
  });

  const graphDataArray = useMemo(() => {
    if (formValues.funnelIds.length === 0) {
      return generateGraphDataArray(
        [groupBy<any, any>(prop("access_date"), reportFunnel.daily_report)],
        formValues.measureKey || "UNIQUE_USER"
      );
    }

    const decodedIds = formValues.funnelIds.map((funnelId) =>
      atob(funnelId).substring("Funnel-".length)
    );
    const filteredReports = reportFunnel.daily_report.filter((row: any) =>
      includes(row.funnel_id.toString(), decodedIds)
    );

    const reportsByFunnel = groupBy<any, any>(
      prop("funnel_id"),
      filteredReports
    );
    const reportsByFunnelAndDate: Record<any, any[]>[] = [];
    Object.keys(reportsByFunnel).forEach((key) => {
      reportsByFunnelAndDate.push(
        groupBy<any, any>(prop("access_date"), reportsByFunnel[key])
      );
    });

    return generateGraphDataArray(
      reportsByFunnelAndDate,
      formValues.measureKey || "UNIQUE_USER"
    );
  }, [reportFunnel, formValues]);

  const funnelOptions = useMemo(() => {
    return funnels.map((funnel) => {
      return { label: funnel.name, value: funnel.id };
    });
  }, [funnels]);

  const handleSubmit = useCallback(({ funnelIds, measureKey }) => {
    if (measureKey === null || isMeasureKey(measureKey, measureOptions)) {
      setFormValues({ funnelIds, measureKey });
    }
  }, []);

  return (
    <AreaGraph
      title={`日別推移｜${findGraphTitleByMeasureKey(formValues.measureKey)}`}
      label={findGraphTitleByMeasureKey(formValues.measureKey)}
      dataArray={graphDataArray}
      graphCustomizedButtonProps={{
        selectFields: [
          {
            name: "funnelIds",
            label: "ファネル",
            options: funnelOptions,
            isMultiple: true,
          },
          {
            name: "measureKey",
            label: "項目",
            options: measureOptions,
            isMultiple: false,
          },
        ],
        formValues,
        onSubmit: handleSubmit,
      }}
    />
  );
};
