import {
  TableDataColumn,
  TableHeaderColumn,
} from "~/src/components/common/tables/Table";
import { chakraFactory } from "~/src/lib/chakra-ui";

const DESCRIPTION_COLUMN_FLEX = 3;
const EXCLUDE_COLUMN_FLEX_WIDTH = "72px";

export const StyledDescriptionHeaderColumn = chakraFactory(TableHeaderColumn, {
  baseStyle: {
    flex: DESCRIPTION_COLUMN_FLEX,
    minWidth: "300px",
  },
});

export const StyledDescriptionDataColumn = chakraFactory(TableDataColumn, {
  baseStyle: {
    flex: DESCRIPTION_COLUMN_FLEX,
    minWidth: "300px",
  },
});

export const StyledExcludeHeaderColumn = chakraFactory(TableHeaderColumn, {
  baseStyle: {
    width: EXCLUDE_COLUMN_FLEX_WIDTH,
    minWidth: EXCLUDE_COLUMN_FLEX_WIDTH,
    flex: "0 1 auto",
  },
});

export const StyledExcludeDataColumn = chakraFactory(TableDataColumn, {
  baseStyle: {
    width: EXCLUDE_COLUMN_FLEX_WIDTH,
    minWidth: EXCLUDE_COLUMN_FLEX_WIDTH,
    flex: "0 1 auto",
  },
});
