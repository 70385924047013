import { Box, Image, Td, Tr } from "@chakra-ui/react";
import { FC, useCallback } from "react";

import {
  DistributionStatus,
  GroupContentJudgeStatus,
  PageKind,
} from "~/src/__generated__/schema";
import { TableDataCheckboxColumn } from "~/src/components/common/tables/Table";

export type PopupGroup = {
  id: string;
  name: string;
  status: DistributionStatus;
  judgeStatus: GroupContentJudgeStatus | null;
  imageUrl: string | null;
  funnelName: string;
  pageName: string;
  pageKind: PageKind;
  sortNumber: number;
  popupCount: number;
};

export type Props = {
  popupGroup: PopupGroup;
  onChange: (id: string) => void;
  isChecked: boolean;
};

export const PopupGroupLinkFormListItem: FC<Props> = ({
  popupGroup,
  onChange,
  isChecked,
}) => {
  const handleCheck = useCallback(() => {
    onChange(popupGroup.id);
  }, [onChange, popupGroup.id]);

  return (
    <Tr key={popupGroup.id}>
      <Td>
        <TableDataCheckboxColumn isChecked={isChecked} onChange={handleCheck} />
      </Td>
      <Td>{popupGroup.name}</Td>
      <Td>
        <Box
          borderRadius={"xl"}
          p={1}
          align="center"
          color={"white"}
          bg={popupGroup.status === "ACTIVE" ? "#4CAF50" : "#979797"}
        >
          {popupGroup.status === "ACTIVE" ? "実施中" : "停止中"}
        </Box>
        <Box
          borderRadius={"xl"}
          p={1}
          align="center"
          color={"white"}
          bg={popupGroup.judgeStatus === "APPROVED" ? "#4CAF50" : "#979797"}
        >
          {popupGroup.judgeStatus === "APPROVED" ? "承認" : "未承認"}
        </Box>
      </Td>
      <Td>
        {popupGroup.imageUrl && (
          <Image src={popupGroup.imageUrl} boxSize={"60px"} fit={"contain"} />
        )}
      </Td>
      <Td>{popupGroup.popupCount}</Td>
      <Td>{popupGroup.funnelName}</Td>
      <Td>
        {popupGroup.pageKind === "FORM"
          ? `${popupGroup.pageName}(${popupGroup.sortNumber - 1})`
          : popupGroup.pageName}
      </Td>
    </Tr>
  );
};
