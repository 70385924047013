import { useMemo, useState } from "react";
import { useMount, useUpdateEffect } from "react-use";

import { Props } from "./TableItemSelect";

type Parameters = {
  cacheKey: string;
  initialSelectedTableItems: Props["allTableItems"];
};

/** テーブルの表示項目を管理する hooks */
export const useTableItemSelect = ({
  cacheKey,
  initialSelectedTableItems,
}: Parameters) => {
  const localStorageKey = `table-item-select-cache-${cacheKey}`;
  const [selectedTableItems, setSelectedTableItems] = useState<
    Props["allTableItems"]
  >(initialSelectedTableItems);

  /** 表示するヘッダー列 */
  const headerColumns = useMemo(
    () => selectedTableItems.map((item) => item.label),
    [selectedTableItems]
  );

  /** 表示するデータ列 */
  const dataColumns = useMemo(
    () => selectedTableItems.map((item) => item.value),
    [selectedTableItems]
  );

  // マウント時に localStorage のキャッシュから値を復元する
  useMount(() => {
    try {
      const cache = localStorage.getItem(localStorageKey);
      cache && setSelectedTableItems(JSON.parse(cache));
    } catch {
      localStorage.removeItem(localStorageKey);
    }
  });

  // アイテムが更新された際に localStorage にキャッシュを保存する
  useUpdateEffect(() => {
    const jsonString = JSON.stringify(selectedTableItems);
    localStorage.setItem(localStorageKey, jsonString);
  }, [localStorageKey, selectedTableItems]);

  return {
    selectedTableItems,
    headerColumns,
    dataColumns,
    onApplyClick: setSelectedTableItems,
  };
};
