import { format } from "date-fns";
import { groupBy, prop, uniqBy } from "ramda";
import { FC, useCallback, useMemo, useState } from "react";
import { graphql, useFragment } from "react-relay";

import { DailyReportGraph_site$key } from "~/src/__relay_artifacts__/DailyReportGraph_site.graphql";

import { AreaGraph } from "../AreaGraph";
import {
  GraphProp,
  MeasureKey,
  findGraphTitleByMeasureKey,
  isMeasureKey,
  measureOptions,
  reduceByMeasureKey,
} from "../utils";

import { Source } from "./types";

const fragment = graphql`
  fragment DailyReportGraph_site on Site {
    reportDaily(input: $input)
  }
`;

export type Prop = {
  siteRef: DailyReportGraph_site$key;
};

type FormValues = {
  accessDate: string | null;
  measureKey: MeasureKey | null;
};

export const DailyReportGraph: FC<Prop> = ({ siteRef }) => {
  const { reportDaily } = useFragment<typeof siteRef>(fragment, siteRef);

  const [formValues, setFormValues] = useState<FormValues>({
    accessDate: null,
    measureKey: null,
  });

  const sources: Source[] = useMemo(() => {
    const uniqueSources = uniqBy<any, any>(
      prop("access_date"),
      reportDaily.daily_report
    );
    return uniqueSources.map((row) => {
      return { id: row.access_date, accessDate: row.access_date };
    });
  }, [reportDaily]);

  const dailyReports = useMemo(() => {
    if (formValues.accessDate === null) {
      return groupBy<any, any>(prop("access_date"), reportDaily.daily_report);
    }
    const reports = reportDaily.daily_report as any[];
    const filteredReports = reports.filter(
      (row) => row.access_date === formValues.accessDate
    );
    return groupBy<any, any>(prop("access_date"), filteredReports);
  }, [reportDaily, formValues]);

  const graphReports = useMemo(() => {
    const results: GraphProp[] = [];
    Object.keys(dailyReports).forEach((key) => {
      const result = reduceByMeasureKey(
        dailyReports[key],
        formValues.measureKey || "UNIQUE_USER"
      );
      results.push({ x: format(new Date(key), "yyyy/MM/dd"), y: result });
    });
    return results;
  }, [dailyReports, formValues]);

  const sourceOptions = useMemo(() => {
    return sources.map((source) => {
      return { label: source.accessDate, value: source.id };
    });
  }, [sources]);

  const handleSubmit = useCallback(({ accessDate, measureKey }) => {
    if (measureKey === null || isMeasureKey(measureKey, measureOptions)) {
      setFormValues({ accessDate, measureKey });
    }
  }, []);

  return (
    <AreaGraph
      title={`日別推移｜${findGraphTitleByMeasureKey(formValues.measureKey)}`}
      label={`${findGraphTitleByMeasureKey(formValues.measureKey)}`}
      dataArray={[{ data: graphReports }]}
      graphCustomizedButtonProps={{
        selectFields: [
          {
            name: "accessDate",
            label: "アクセス日",
            options: sourceOptions,
            isMultiple: false,
          },
          {
            name: "measureKey",
            label: "項目",
            options: measureOptions,
            isMultiple: false,
          },
        ],
        formValues,
        onSubmit: handleSubmit,
      }}
    />
  );
};
