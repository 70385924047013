import { Box } from "@chakra-ui/react";
import { FC, useMemo } from "react";

import { PageLayout } from "~/src/components/features/global/PageLayout";

export type Props = {
  siteSlug: string;
};

export const SourceCreatePage: FC<Props> = ({ children, siteSlug }) => {
  const breadcrumbs = useMemo(
    () => [
      { label: "設定", path: `/sites/${siteSlug}/settings` },
      { label: "流入元の登録", path: `/sites/${siteSlug}/sources/new` },
    ],
    [siteSlug]
  );

  return (
    <PageLayout title="流入元の登録" breadcrumbs={breadcrumbs}>
      <Box mt="32px">{children}</Box>
    </PageLayout>
  );
};
