import { useState } from "react";
import { useDebounce } from "react-use";

/** useDebounce hook that ignores the first invocation */
export const useUpdateDebounce: typeof useDebounce = (fn, ms, deps) => {
  const [updated, setUpdated] = useState(false);

  const result = useDebounce(
    () => {
      if (!updated) {
        setUpdated(true);
        return;
      }
      fn();
    },
    ms,
    deps
  );

  return result;
};
