import { useToast } from "@chakra-ui/react";
import { includes } from "ramda";
import { FC, useCallback, useMemo } from "react";
import { ConnectionHandler, graphql, useLazyLoadQuery } from "react-relay";

import { PopupLinkFormContainer_Mutation } from "~/src/__relay_artifacts__/PopupLinkFormContainer_Mutation.graphql";
import { PopupLinkFormContainer_Query } from "~/src/__relay_artifacts__/PopupLinkFormContainer_Query.graphql";
import { useMutationCommit } from "~/src/lib/react-relay";
import { delayChunkPromise } from "~/src/lib/utils";

import { Popup, PopupLinkFormList } from "./presentations";

export type Props = {
  onClose: () => void;
  siteSlug: string;
  popupGroupSetId: string;
  createdPopupIds: string[];
  popupSetId: string;
};

const query = graphql`
  query PopupLinkFormContainer_Query(
    $siteSlug: String!
    $popupGroupSetId: ID!
  ) {
    site(slug: $siteSlug) {
      popupGroupSet(id: $popupGroupSetId) {
        popups {
          edges {
            node {
              id
              name
              thumbnailImageUrl
              status
              judgeStatus
              popupGroup {
                id
                name
              }
              page {
                id
                name
                kind
                sortNumber
                funnel {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

const mutation = graphql`
  mutation PopupLinkFormContainer_Mutation(
    $input: CreatePopupSettingInput!
    $connections: [ID!]!
  ) {
    createPopupSetting(input: $input) {
      popupSettingEdge @appendEdge(connections: $connections) {
        node {
          id
          popupSet {
            id
            name
            memo
          }
          popup {
            id
            slug
            name
            status
            judgeStatus
            thumbnailImageUrl
            popupGroup {
              id
              slug
              name
            }
            page {
              id
              slug
              kind
              name
              sortNumber
              url
              funnel {
                id
                slug
                name
                siteUrl
              }
            }
            ...PopupBulkEditFormContainer_popup
          }
        }
      }
    }
  }
`;

export const PopupLinkFormContainer: FC<Props> = ({
  onClose,
  siteSlug,
  createdPopupIds,
  popupSetId,
  popupGroupSetId,
}) => {
  const toast = useToast();
  const { site } = useLazyLoadQuery<PopupLinkFormContainer_Query>(query, {
    siteSlug,
    popupGroupSetId,
  });
  const mutate = useMutationCommit<PopupLinkFormContainer_Mutation>(mutation);

  const popups: Popup[] = useMemo(() => {
    const edges = site.popupGroupSet.popups.edges || [];
    return edges
      .map((edge) => {
        const node = edge?.node;
        if (!node) throw new Error("assertion failed");
        return {
          id: node.id,
          name: node.name,
          judgeStatus: node.judgeStatus,
          status: node.status,
          pageName: node.page.name,
          funnelName: node.page.funnel.name,
          imageUrl: node.thumbnailImageUrl,
          pageKind: node.page.kind,
          sortNumber: node.page.sortNumber,
        };
      })
      .filter((popup) => !includes(popup.id, createdPopupIds))
      .filter((popup) => popup.name !== "デフォルト");
  }, [site.popupGroupSet.popups, createdPopupIds]);

  const handleSubmit = useCallback(
    async (ids: string[]) => {
      try {
        const connectionId = ConnectionHandler.getConnectionID(
          popupSetId,
          "PopupSetDetailLinkedPopupListTable_popupSet_popupSettings"
        );
        const mutations: Promise<{}>[] = ids.map((popupId) => {
          return mutate({
            variables: {
              input: {
                popupSetId,
                popupId,
              },
              connections: [connectionId],
            },
          });
        });

        await delayChunkPromise(mutations);

        toast({
          title: "ポップアップパターンを紐付けました",
          status: "success",
        });
        onClose();
      } catch (err) {
        toast({
          title: "ポップアップパターンの紐付けに失敗しました",
          status: "error",
        });
      }
    },
    [popupSetId, toast, onClose, mutate]
  );

  return (
    <PopupLinkFormList
      onClose={onClose}
      onClick={handleSubmit}
      popups={popups}
    />
  );
};
