/**
 * @generated SignedSource<<aa67cef936732524762dd3421842f4c3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PopupBulkArchiveModalContainer_Query$variables = {
  input: ReadonlyArray<string>;
};
export type PopupBulkArchiveModalContainer_QueryVariables = PopupBulkArchiveModalContainer_Query$variables;
export type PopupBulkArchiveModalContainer_Query$data = {
  readonly popupGroups: {
    readonly __id: string;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
      } | null;
    } | null> | null;
  };
};
export type PopupBulkArchiveModalContainer_QueryResponse = PopupBulkArchiveModalContainer_Query$data;
export type PopupBulkArchiveModalContainer_Query = {
  variables: PopupBulkArchiveModalContainer_QueryVariables;
  response: PopupBulkArchiveModalContainer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "slugs",
        "variableName": "input"
      }
    ],
    "concreteType": "PopupGroupConnection",
    "kind": "LinkedField",
    "name": "popupGroups",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PopupGroupEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PopupGroup",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "kind": "ClientExtension",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__id",
            "storageKey": null
          }
        ]
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PopupBulkArchiveModalContainer_Query",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PopupBulkArchiveModalContainer_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "738aec6e06930db27222eba1cb76ef55",
    "id": null,
    "metadata": {},
    "name": "PopupBulkArchiveModalContainer_Query",
    "operationKind": "query",
    "text": "query PopupBulkArchiveModalContainer_Query(\n  $input: [String!]!\n) {\n  popupGroups(slugs: $input) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e33490f290556fecad3234eec13a5459";

export default node;
