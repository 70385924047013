import { FC } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";

import { ExcludedIPEditPageContainer_Query } from "~/src/__relay_artifacts__/ExcludedIPEditPageContainer_Query.graphql";
import { useExcludedIPEditPageParams } from "~/src/routes/hooks";

import { ExcludedIPEditScreenContainer } from "./ExcludedIPEditScreenContainer";
import { ExcludedIPEditPage } from "./presentations";

const query = graphql`
  query ExcludedIPEditPageContainer_Query($slug: String!) {
    excludedIpAddress(slug: $slug) {
      ...ExcludedIPEditScreenContainer_excludedIpAddress
    }
  }
`;

export const ExcludedIPEditPageContainer: FC = () => {
  const { siteSlug, excludedIpSlug } = useExcludedIPEditPageParams();

  const { excludedIpAddress } =
    useLazyLoadQuery<ExcludedIPEditPageContainer_Query>(query, {
      slug: excludedIpSlug,
    });

  if (!excludedIpAddress) throw new Error("assertion failed");

  return (
    <ExcludedIPEditPage siteSlug={siteSlug} excludedIpSlug={excludedIpSlug}>
      <ExcludedIPEditScreenContainer excludedIpAddressRef={excludedIpAddress} />
    </ExcludedIPEditPage>
  );
};
