/**
 * @generated SignedSource<<b6d59396dfc9d52e3c07417f6f2df187>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserRole = "SUPER" | "ADMIN_ALL" | "ADMIN" | "JUDGE" | "CLIENT" | "SAAS_CLIENT" | "%future added value";
export type PopupGroupPatternEditPageContainer_Query$variables = {
  siteSlug: string;
  popupSlug: string;
  popupGroupSlug: string;
};
export type PopupGroupPatternEditPageContainer_QueryVariables = PopupGroupPatternEditPageContainer_Query$variables;
export type PopupGroupPatternEditPageContainer_Query$data = {
  readonly viewer: {
    readonly role: UserRole;
  };
  readonly site: {
    readonly " $fragmentSpreads": FragmentRefs<"PopupGroupPatternEditScreenContainer_site">;
  };
  readonly popup: {
    readonly " $fragmentSpreads": FragmentRefs<"PopupGroupPatternEditScreenContainer_popup">;
  } | null;
  readonly popupGroup: {
    readonly " $fragmentSpreads": FragmentRefs<"PopupGroupPatternEditScreenContainer_popupGroup">;
  } | null;
};
export type PopupGroupPatternEditPageContainer_QueryResponse = PopupGroupPatternEditPageContainer_Query$data;
export type PopupGroupPatternEditPageContainer_Query = {
  variables: PopupGroupPatternEditPageContainer_QueryVariables;
  response: PopupGroupPatternEditPageContainer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "popupGroupSlug"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "popupSlug"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "siteSlug"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "siteSlug"
  }
],
v5 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "popupSlug"
  }
],
v6 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "popupGroupSlug"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v10 = [
  (v7/*: any*/),
  (v8/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "linkUrl",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isInheritQuery",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "honeycombCode",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "weight",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PopupGroupPatternEditPageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PopupGroupPatternEditScreenContainer_site"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "Popup",
        "kind": "LinkedField",
        "name": "popup",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PopupGroupPatternEditScreenContainer_popup"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "PopupGroup",
        "kind": "LinkedField",
        "name": "popupGroup",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PopupGroupPatternEditScreenContainer_popupGroup"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PopupGroupPatternEditPageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FunnelConnection",
            "kind": "LinkedField",
            "name": "funnels",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FunnelEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Funnel",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v10/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageConnection",
            "kind": "LinkedField",
            "name": "pages",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PageEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Page",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v10/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "Popup",
        "kind": "LinkedField",
        "name": "popup",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Page",
            "kind": "LinkedField",
            "name": "page",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Funnel",
                "kind": "LinkedField",
                "name": "funnel",
                "plural": false,
                "selections": [
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PopupImage",
            "kind": "LinkedField",
            "name": "popupImages",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "alt",
                "storageKey": null
              },
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "imageUrl",
                "storageKey": null
              },
              (v12/*: any*/),
              (v13/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PopupVideo",
            "kind": "LinkedField",
            "name": "popupVideo",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "video",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "screenType",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "contentKind",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "colorKind",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "button",
            "storageKey": null
          },
          (v12/*: any*/),
          (v14/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hrefTarget",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "contentWidth",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "actionKind",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayKind",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "xOffset",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "xOffsetUnit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "yOffset",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "yOffsetUnit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "enableTapWindow",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "backgroundOpacity",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "quitTiming",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayCountLimit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayPopupAgain",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayPopupAgainImageUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayPopupAgainMessage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayOnMouseOut",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayOnTabFocus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isClose",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "htmlContent",
            "storageKey": null
          },
          (v15/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "animationKind",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "closePopupByClickingOutside",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "closeButtonPosition",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hideByScrollPercentage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hideByOnfocus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ydaTrigger",
            "storageKey": null
          },
          (v13/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isDisplayWhenNoOperation",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timing",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "scrollRateRange",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "customEvent",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "PopupGroup",
        "kind": "LinkedField",
        "name": "popupGroup",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deliverKind",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Popup",
            "kind": "LinkedField",
            "name": "originalPopup",
            "plural": false,
            "selections": [
              (v15/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Page",
            "kind": "LinkedField",
            "name": "page",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "kind",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sortNumber",
                "storageKey": null
              },
              (v14/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Funnel",
                "kind": "LinkedField",
                "name": "funnel",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "siteUrl",
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f41ba34deb3c09e4f69116cf6a34131a",
    "id": null,
    "metadata": {},
    "name": "PopupGroupPatternEditPageContainer_Query",
    "operationKind": "query",
    "text": "query PopupGroupPatternEditPageContainer_Query(\n  $siteSlug: String!\n  $popupSlug: String!\n  $popupGroupSlug: String!\n) {\n  viewer {\n    role\n    id\n  }\n  site(slug: $siteSlug) {\n    ...PopupGroupPatternEditScreenContainer_site\n    id\n  }\n  popup(slug: $popupSlug) {\n    ...PopupGroupPatternEditScreenContainer_popup\n    id\n  }\n  popupGroup(slug: $popupGroupSlug) {\n    ...PopupGroupPatternEditScreenContainer_popupGroup\n    id\n  }\n}\n\nfragment PopupGroupPatternEditScreenContainer_popup on Popup {\n  id\n  slug\n  page {\n    id\n    funnel {\n      id\n    }\n  }\n  popupImages {\n    id\n    alt\n    linkUrl\n    imageUrl\n    isInheritQuery\n    honeycombCode\n  }\n  popupVideo {\n    linkUrl\n    video\n    screenType\n  }\n  name\n  contentKind\n  colorKind\n  title\n  description\n  button\n  isInheritQuery\n  url\n  hrefTarget\n  contentWidth\n  actionKind\n  displayKind\n  xOffset\n  xOffsetUnit\n  yOffset\n  yOffsetUnit\n  enableTapWindow\n  backgroundOpacity\n  quitTiming\n  displayCountLimit\n  status\n  displayPopupAgain\n  displayPopupAgainImageUrl\n  displayPopupAgainMessage\n  displayOnMouseOut\n  displayOnTabFocus\n  isClose\n  htmlContent\n  weight\n  animationKind\n  closePopupByClickingOutside\n  closeButtonPosition\n  hideByScrollPercentage\n  hideByOnfocus\n  ydaTrigger\n  honeycombCode\n  isDisplayWhenNoOperation\n  timing\n  scrollRateRange\n  customEvent\n}\n\nfragment PopupGroupPatternEditScreenContainer_popupGroup on PopupGroup {\n  id\n  name\n  deliverKind\n  originalPopup {\n    weight\n    id\n  }\n  page {\n    name\n    kind\n    sortNumber\n    url\n    funnel {\n      name\n      siteUrl\n      id\n    }\n    id\n  }\n}\n\nfragment PopupGroupPatternEditScreenContainer_site on Site {\n  id\n  name\n  slug\n  funnels {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n  pages {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7bcec309d949dc20f3a4135179bf4465";

export default node;
