/**
 * @generated SignedSource<<1d06b3f9a9ce2532c614f53c326df5fa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JudgementListPage_viewer$data = {
  readonly wipAbtestScenarioPages: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly title: string | null;
        readonly abtestScenarioPageGroup: {
          readonly title: string;
        };
        readonly abtestScenarioPageContents: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly imageUrl?: string | null;
              readonly image?: string | null;
            } | null;
          } | null> | null;
        };
        readonly page: {
          readonly name: string;
          readonly url: string;
          readonly funnel: {
            readonly name: string;
          };
        };
      } | null;
    } | null> | null;
  };
  readonly wipPopups: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string | null;
        readonly popupGroup: {
          readonly name: string;
        };
        readonly popupImages: ReadonlyArray<{
          readonly imageUrl: string | null;
          readonly image: string | null;
        }>;
        readonly page: {
          readonly name: string;
          readonly url: string;
          readonly funnel: {
            readonly name: string;
          };
        };
      } | null;
    } | null> | null;
  };
  readonly " $fragmentType": "JudgementListPage_viewer";
};
export type JudgementListPage_viewer = JudgementListPage_viewer$data;
export type JudgementListPage_viewer$key = {
  readonly " $data"?: JudgementListPage_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"JudgementListPage_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "endDate",
    "variableName": "endDate"
  },
  {
    "kind": "Variable",
    "name": "startDate",
    "variableName": "startDate"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "imageUrl",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "image",
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Page",
  "kind": "LinkedField",
  "name": "page",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Funnel",
      "kind": "LinkedField",
      "name": "funnel",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "endDate"
    },
    {
      "kind": "RootArgument",
      "name": "startDate"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "JudgementListPage_viewer",
  "selections": [
    {
      "alias": null,
      "args": (v0/*: any*/),
      "concreteType": "AbtestScenarioPageConnection",
      "kind": "LinkedField",
      "name": "wipAbtestScenarioPages",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AbtestScenarioPageEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AbtestScenarioPage",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AbtestScenarioPageGroup",
                  "kind": "LinkedField",
                  "name": "abtestScenarioPageGroup",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AbtestScenarioPageContentConnection",
                  "kind": "LinkedField",
                  "name": "abtestScenarioPageContents",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AbtestScenarioPageContentEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": null,
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "InlineFragment",
                              "selections": (v3/*: any*/),
                              "type": "AbtestScenarioPageContentScenario",
                              "abstractKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v6/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "concreteType": "PopupConnection",
      "kind": "LinkedField",
      "name": "wipPopups",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PopupEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Popup",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PopupGroup",
                  "kind": "LinkedField",
                  "name": "popupGroup",
                  "plural": false,
                  "selections": (v5/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PopupImage",
                  "kind": "LinkedField",
                  "name": "popupImages",
                  "plural": true,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                },
                (v6/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "2ebcef59ca05800c29cafdc7970972e3";

export default node;
