/**
 * @generated SignedSource<<83708bfdd3f3db1ed6444799ef36a952>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChatbotListPageContainer_Query$variables = {
  siteSlug: string;
};
export type ChatbotListPageContainer_QueryVariables = ChatbotListPageContainer_Query$variables;
export type ChatbotListPageContainer_Query$data = {
  readonly site: {
    readonly " $fragmentSpreads": FragmentRefs<"ChatbotListPage_site">;
  };
};
export type ChatbotListPageContainer_QueryResponse = ChatbotListPageContainer_Query$data;
export type ChatbotListPageContainer_Query = {
  variables: ChatbotListPageContainer_QueryVariables;
  response: ChatbotListPageContainer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "siteSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "siteSlug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 120
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": {
      "direction": "DESC",
      "field": "END_ON"
    }
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChatbotListPageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ChatbotListPage_site"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChatbotListPageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "ChatbotGroupConnection",
            "kind": "LinkedField",
            "name": "chatbotGroups",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ChatbotGroupEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ChatbotGroup",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "kind",
                            "value": "B_PATTERN"
                          }
                        ],
                        "concreteType": "ChatbotConnection",
                        "kind": "LinkedField",
                        "name": "chatbots",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/)
                        ],
                        "storageKey": "chatbots(kind:\"B_PATTERN\")"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endDate",
                        "storageKey": null
                      },
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startDate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "updatedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isArchive",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "thumbnailImageUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Page",
                        "kind": "LinkedField",
                        "name": "page",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Funnel",
                            "kind": "LinkedField",
                            "name": "funnel",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              (v2/*: any*/),
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "kind": "ClientExtension",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__id",
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": "chatbotGroups(first:120,orderBy:{\"direction\":\"DESC\",\"field\":\"END_ON\"})"
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "filters": [
              "searchTerm",
              "pageIds",
              "funnelIds",
              "sourceId",
              "device",
              "withArchived",
              "orderBy",
              "status",
              "pageKind"
            ],
            "handle": "connection",
            "key": "ChatbotListPage_chatbotGroups",
            "kind": "LinkedHandle",
            "name": "chatbotGroups"
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "62c4d062a980adf86fe42955f09f4c37",
    "id": null,
    "metadata": {},
    "name": "ChatbotListPageContainer_Query",
    "operationKind": "query",
    "text": "query ChatbotListPageContainer_Query(\n  $siteSlug: String!\n) {\n  site(slug: $siteSlug) {\n    ...ChatbotListPage_site\n    id\n  }\n}\n\nfragment ChatbotListPage_site on Site {\n  slug\n  chatbotGroups(first: 120, orderBy: {field: END_ON, direction: DESC}) {\n    totalCount\n    edges {\n      node {\n        id\n        slug\n        ...ChatbotTableDataRow_chatbotGroup\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n\nfragment ChatbotTableDataRow_chatbotGroup on ChatbotGroup {\n  chatbots(kind: B_PATTERN) {\n    totalCount\n  }\n  endDate\n  id\n  name\n  slug\n  startDate\n  updatedAt\n  status\n  isArchive\n  thumbnailImageUrl\n  page {\n    name\n    slug\n    funnel {\n      name\n      slug\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "1b684904f8ec0c6110f4dd039a9ef0b3";

export default node;
