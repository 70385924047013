/**
 * @generated SignedSource<<3cee637f47fb22b36640e615d3e19572>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteChatbotInput = {
  clientMutationId?: string | null;
  chatbotId: string;
};
export type ChatbotPatternTableActions_DeleteMutation$variables = {
  input: DeleteChatbotInput;
  connections: ReadonlyArray<string>;
};
export type ChatbotPatternTableActions_DeleteMutationVariables = ChatbotPatternTableActions_DeleteMutation$variables;
export type ChatbotPatternTableActions_DeleteMutation$data = {
  readonly deleteChatbot: {
    readonly deletedChatbotId: string;
  } | null;
};
export type ChatbotPatternTableActions_DeleteMutationResponse = ChatbotPatternTableActions_DeleteMutation$data;
export type ChatbotPatternTableActions_DeleteMutation = {
  variables: ChatbotPatternTableActions_DeleteMutationVariables;
  response: ChatbotPatternTableActions_DeleteMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedChatbotId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ChatbotPatternTableActions_DeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "DeleteChatbotPayload",
        "kind": "LinkedField",
        "name": "deleteChatbot",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ChatbotPatternTableActions_DeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "DeleteChatbotPayload",
        "kind": "LinkedField",
        "name": "deleteChatbot",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "deleteEdge",
            "key": "",
            "kind": "ScalarHandle",
            "name": "deletedChatbotId",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b635748f614682e5b03794bc6078a08d",
    "id": null,
    "metadata": {},
    "name": "ChatbotPatternTableActions_DeleteMutation",
    "operationKind": "mutation",
    "text": "mutation ChatbotPatternTableActions_DeleteMutation(\n  $input: DeleteChatbotInput!\n) {\n  deleteChatbot(input: $input) {\n    deletedChatbotId\n  }\n}\n"
  }
};
})();

(node as any).hash = "c57be0f80e2048854a5e9e97f850a44b";

export default node;
