import { FC } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";

import { NotificationListPageContainer_Query } from "~/src/__relay_artifacts__/NotificationListPageContainer_Query.graphql";

import { NotificationListPage } from "./presentations";

const query = graphql`
  query NotificationListPageContainer_Query {
    ...NotificationListPage_root
  }
`;

export const NotificationListPageContainer: FC = () => {
  const data = useLazyLoadQuery<NotificationListPageContainer_Query>(query, {});

  return <NotificationListPage rootRef={data} />;
};
