import { getAnalytics } from "firebase/analytics";
import { getApps, initializeApp } from "firebase/app";

export const createFirebaseApp = () => {
  const clientCredentials = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  };

  if (getApps().length <= 0) {
    const app = initializeApp(clientCredentials);
    if (typeof window !== "undefined") {
      if ("measurementId" in clientCredentials) {
        getAnalytics();
      }
    }
    return app;
  }
};
