import { Flex, HStack, Text } from "@chakra-ui/react";
import {
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from "@mui/icons-material";
import { FC, useCallback, useMemo } from "react";

import { IconButton } from "~/src/components/common/IconButton";
import { Select, SelectProps } from "~/src/components/common/Select";
import { chakraFactory } from "~/src/lib/chakra-ui";

export type Props = {
  perPage: number;
  recordCount: number;
  from: number;
  to: number;
  hasPrev: boolean;
  hasNext: boolean;
  onPerPageChange: (perPage: number) => void;
  onPrevClick: () => void;
  onNextClick: () => void;
};

const StyledLabelText = chakraFactory(Text, {
  baseStyle: {
    fontSize: "16px",
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
    textAlign: "right",
    letterSpacing: "0.06em",
    color: "#282828",
  },
});

const StyledSelect = chakraFactory(Select, {
  baseStyle: {
    width: "80px",
  },
});

const perPageOptions = [
  { label: "5", value: 5 },
  { label: "30", value: 30 },
  { label: "60", value: 60 },
  { label: "90", value: 90 },
  { label: "120", value: 120 },
];

export const TablePagination: FC<Props> = ({
  perPage,
  recordCount,
  from,
  to,
  hasPrev,
  hasNext,
  onPerPageChange,
  onPrevClick,
  onNextClick,
}) => {
  const currentOption = useMemo(
    () => perPageOptions.find((option) => option.value === perPage),
    [perPage]
  );

  const handlePerPageChange = useCallback<NonNullable<SelectProps["onChange"]>>(
    // @ts-expect-error
    (option: typeof perPageOptions[0]) => onPerPageChange(option.value),
    [onPerPageChange]
  );

  return (
    <Flex justifyContent="flex-end">
      <HStack spacing="28px">
        <StyledLabelText>表示する行数:</StyledLabelText>
        <StyledSelect
          menuPlacement="auto"
          value={currentOption}
          defaultValue={currentOption}
          options={perPageOptions}
          onChange={handlePerPageChange}
        />
        <StyledLabelText>
          {recordCount > 0 ? from + 1 : 0} - {to}/{recordCount}
        </StyledLabelText>
        <HStack spacing="8px">
          <IconButton
            size="24px"
            icon={<KeyboardArrowLeftIcon />}
            isDisabled={!hasPrev}
            onClick={onPrevClick}
          />
          <IconButton
            size="24px"
            icon={<KeyboardArrowRightIcon />}
            isDisabled={!hasNext}
            onClick={onNextClick}
          />
        </HStack>
      </HStack>
    </Flex>
  );
};
