import { Box, useToast } from "@chakra-ui/react";
import { FC, useCallback, useMemo } from "react";
import { ConnectionHandler, graphql, useFragment } from "react-relay";
import { useNavigate } from "react-router";

import { FunnelSourceCreateFormContainer_funnel$key } from "~/src/__relay_artifacts__/FunnelSourceCreateFormContainer_funnel.graphql";
import { FunnelSourceCreateFormContainer_Mutation } from "~/src/__relay_artifacts__/FunnelSourceCreateFormContainer_Mutation.graphql";
import { FunnelSourceCreateFormContainer_root$key } from "~/src/__relay_artifacts__/FunnelSourceCreateFormContainer_root.graphql";
import { useMutationCommit } from "~/src/lib/react-relay";

import { PageLayout } from "../../global/PageLayout";

import { FunnelSourceForm, FunnelSourceFormProps } from "./presentations";

type Props = {
  siteSlug: string;
  funnelSlug: string;
  funnelRef: FunnelSourceCreateFormContainer_funnel$key;
  sourceRef: FunnelSourceCreateFormContainer_root$key;
};

const sourceFragment = graphql`
  fragment FunnelSourceCreateFormContainer_root on Query {
    sources {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const funnelFragment = graphql`
  fragment FunnelSourceCreateFormContainer_funnel on Funnel {
    id
    landingPage {
      id
    }
  }
`;
const mutation = graphql`
  mutation FunnelSourceCreateFormContainer_Mutation(
    $input: AddSourceTagInput!
    $connections: [ID!]!
  ) {
    addSourceTag(input: $input) {
      sourceTag
        @appendNode(connections: $connections, edgeTypeName: "SourceTagEdge") {
        id
        key
        value
        slug
        source {
          id
          name
        }
        pageUrl
      }
    }
  }
`;

export const FunnelSourceCreateFormContainer: FC<Props> = ({
  siteSlug,
  funnelSlug,
  funnelRef,
  sourceRef,
}) => {
  const navigate = useNavigate();
  const toast = useToast();

  const funnel = useFragment(funnelFragment, funnelRef);
  const sources = useFragment(sourceFragment, sourceRef);
  const mutate =
    useMutationCommit<FunnelSourceCreateFormContainer_Mutation>(mutation);

  const landingPage = useMemo(() => {
    if (!funnel.landingPage) throw new Error("assertion failed");
    return funnel.landingPage;
  }, [funnel]);

  const sourceOptions = useMemo(() => {
    const edges = sources.sources.edges || [];
    return edges.map((edge) => {
      const node = edge?.node;
      if (!node) throw new Error("assertion failed");
      return { label: node.name, value: node.id };
    });
  }, [sources]);

  const initialValues = useMemo(() => {
    return {
      source: sourceOptions[0].value,
      parameterName: "",
      parameterValue: "",
    };
  }, [sourceOptions]);

  const handleSubmit = useCallback<FunnelSourceFormProps["onSubmit"]>(
    async ({ ...values }) => {
      try {
        const connectionId = ConnectionHandler.getConnectionID(
          funnel.id,
          "FunnelSourceListPage_sourceTags"
        );
        const res = await mutate({
          variables: {
            input: {
              sourceId: values.source,
              key: values.parameterName,
              value: values.parameterValue,
              pageId: landingPage.id,
            },
            connections: [connectionId],
          },
        });
        const addedSourceTag = res.addSourceTag?.sourceTag;
        if (!addedSourceTag) {
          throw new Error("assertion failed");
        }
        toast({ title: "流入元を追加しました", status: "success" });
        navigate(`/sites/${siteSlug}/funnels/${funnelSlug}/sources`);
      } catch (err) {
        toast({ title: "流入元の追加に失敗しました", status: "error" });
      }
    },
    [funnel.id, mutate, landingPage.id, toast, navigate, siteSlug, funnelSlug]
  );

  const breadcrumbs = useMemo(
    () => [
      { label: "設定", path: `/sites/${siteSlug}/settings` },
      {
        label: "流入元登録",
        path: `/sites/${siteSlug}/funnels/${funnelSlug}/sources/new`,
      },
    ],
    [funnelSlug, siteSlug]
  );

  const handleCancel = useCallback(() => navigate(-1), [navigate]);

  return (
    <PageLayout title="流入元登録" breadcrumbs={breadcrumbs}>
      <Box mt="32px">
        <FunnelSourceForm
          initialValues={initialValues}
          onCancelClick={handleCancel}
          onSubmit={handleSubmit}
          sourceOptions={sourceOptions}
        />
      </Box>
    </PageLayout>
  );
};
