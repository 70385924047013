import { useCallback, useMemo, useState } from "react";

import { TablePaginationProps } from "./index";

type Parameters = {
  totalCount: number;
  defaultPerPage?: number;
};

export const useTableLocalPaginationProps = ({
  totalCount,
  defaultPerPage,
}: Parameters) => {
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(defaultPerPage ? defaultPerPage : 120);
  const lastPage = Math.ceil(totalCount / perPage) - 1;

  const handlePrevClick = useCallback(() => {
    if (page === 0) return;
    setPage(page - 1);
  }, [page, setPage]);

  const handleNextClick = useCallback(() => {
    if (totalCount <= perPage ? false : page < lastPage) setPage((p) => p + 1);
  }, [lastPage, page, perPage, totalCount]);

  const handlePerPageChange = useCallback((newPerPage: number) => {
    setPage(0);
    setPerPage(newPerPage);
  }, []);

  const tableLocalPaginationProps = useMemo<TablePaginationProps>(
    () => ({
      recordCount: totalCount,
      perPage,
      from: page * perPage,
      to: Math.min(page * perPage + perPage, totalCount),
      hasPrev: totalCount <= perPage ? false : page !== 0,
      hasNext: totalCount <= perPage ? false : page < lastPage,
      onPerPageChange: handlePerPageChange,
      onPrevClick: handlePrevClick,
      onNextClick: handleNextClick,
    }),
    [
      handleNextClick,
      handlePerPageChange,
      handlePrevClick,
      lastPage,
      page,
      perPage,
      totalCount,
    ]
  );

  return { tableLocalPaginationProps };
};
