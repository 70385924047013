import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import { FC } from "react";

import { HorizontalSeparator } from "~/src/components/common/Separator";
import { chakraFactory } from "~/src/lib/chakra-ui";

export type Props = {
  steps: { label: string; active: boolean }[];
};

const StyledStepNumber = chakraFactory(Box, {
  baseStyle: {
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    fontSize: "15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#FFFFFF",
  },
});

const StyledLabelText = chakraFactory(Text, {
  baseStyle: {
    display: "flex",
    alignItems: "center",
    height: "24px",
    ml: "10px",
    fontSize: "14px",
  },
});

const StyledHorizontalSeparator = chakraFactory(HorizontalSeparator, {
  baseStyle: {
    width: "80px",
  },
});

export const WizardStep: FC<Props> = ({ steps }) => (
  <HStack spacing="20px" divider={<StyledHorizontalSeparator />}>
    {steps.map(({ label, active }, i) => (
      <Flex key={i}>
        <StyledStepNumber background={active ? "#1867C0" : "#979797"}>
          {i + 1}
        </StyledStepNumber>
        <StyledLabelText color={active ? "#000000" : "#979797"}>
          {label}
        </StyledLabelText>
      </Flex>
    ))}
  </HStack>
);
