import { FC, MouseEventHandler, useCallback } from "react";
import { Handle, NodeProps, Position, useReactFlow } from "react-flow-renderer";

import { Modal } from "~/src/components/common/Modal";
import { chakraFactory } from "~/src/lib/chakra-ui";
import { useOpenState } from "~/src/lib/hooks";

import { ChatMessageFormContainer } from "./ChatMessageFormContainer";
import { ChatMessageFormValues as FormValues, NodeData } from "./types";

const CloseNodeWrapper = chakraFactory("div", {
  baseStyle: {
    height: "80px",
    border: "1px solid #eee",
    padding: "5px",
    borderRadius: "5px",
    background: "white",
    width: "150px",
  },
});

const ModalButton = chakraFactory("button", {
  baseStyle: {
    display: "block",
    color: "#777",
    fontSize: "12px",
  },
});

const RemoveButton = chakraFactory("button", {
  baseStyle: {
    width: "20px",
    height: "20px",
    background: "#eee",
    border: "1px solid #fff",
    cursor: "pointer",
    borderRadius: "50%",
    fontSize: "12px",
    lineHeight: "1",
    marginLeft: "110px",
  },
});

export const CloseNode: FC<NodeProps<NodeData>> = ({ id }) => {
  const { isOpen, onClose, onOpen } = useOpenState();
  const { getNodes, setNodes, getNode } = useReactFlow();
  const initialValues: FormValues = {
    kind: "SUBMIT",
    message: null,
    isInheritQuery: false,
    displaySpeed: 0,
    isSaveReply: false,
    isTransferReply: false,
    submitUrl: null,
    id: null,
  };
  const messageValues = getNode(id)?.data.message;

  const handleDoubleClick = useCallback<
    MouseEventHandler<HTMLButtonElement>
  >(() => {
    onOpen();
  }, [onOpen]);

  const handleSubmit = useCallback(
    (values: FormValues) => {
      const nodes = getNodes().map((node) => {
        if (node.id !== id) return node;
        node.data.message = values;
        return node;
      });
      setNodes(nodes);
      onClose();
    },
    [getNodes, id, onClose, setNodes]
  );

  const handleRemove = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
    const nodesWithRemoveNode = getNodes().filter((node) => node.id !== id);
    setNodes(nodesWithRemoveNode);
  }, [getNodes, id, setNodes]);

  return (
    <CloseNodeWrapper>
      <Handle type="target" position={Position.Top} />
      <RemoveButton type="button" onClick={handleRemove}>
        ×
      </RemoveButton>
      <ModalButton type="button" onDoubleClick={handleDoubleClick}>
        ダブルクリックでメッセージ設定
      </ModalButton>

      <Modal
        title="[送信ボタン]メッセージの設定"
        isOpen={isOpen}
        onClose={onClose}
      >
        <ChatMessageFormContainer
          initialValues={messageValues || initialValues}
          handleSubmit={handleSubmit}
          handleClose={onClose}
        />
      </Modal>
    </CloseNodeWrapper>
  );
};
