import { FC } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";

import { AccountListPageContainer_Query } from "~/src/__relay_artifacts__/AccountListPageContainer_Query.graphql";

import { AccountListPage } from "./presentations";

export type Props = {};

const query = graphql`
  query AccountListPageContainer_Query($searchTerm: String) {
    ...AccountListPage_viewers @arguments(searchTerm: $searchTerm)
  }
`;

export const AccountListPageContainer: FC<Props> = () => {
  const queryRef = useLazyLoadQuery<AccountListPageContainer_Query>(query, {
    searchTerm: null,
  });

  return <AccountListPage viewersRef={queryRef} />;
};
