import { VStack } from "@chakra-ui/react";
import { Form, Formik, FormikConfig } from "formik";
import { FC } from "react";

import { GraphQLEnums } from "~/src/__generated__/GraphQLEnums";
import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormControlGroup } from "~/src/components/common/forms/FormControlGroup";
import { FormikFormButtons } from "~/src/components/common/forms/FormikFormButtons";
import { FormikSelectField } from "~/src/components/common/forms/FormikSelectField";
import { FormikTextareaField } from "~/src/components/common/forms/FormikTextareaField";
import { FormikTextField } from "~/src/components/common/forms/FormikTextField";

import { FormValues, validationSchema } from "./validationSchema";

export type Props = {
  initialValues?: FormValues;
  onCancelClick: () => void;
  onSubmit: FormikConfig<FormValues>["onSubmit"];
};

const defaultInitialValues: FormValues = {
  category: "UPGRADE",
  title: "",
  body: "",
  publishedAt: "",
};

export const NotificationForm: FC<Props> = ({
  initialValues = defaultInitialValues,
  onCancelClick,
  onSubmit,
}) => (
  <Formik<FormValues>
    enableReinitialize
    initialValues={initialValues}
    validationSchema={validationSchema}
    validateOnMount={false}
    validateOnChange={false}
    onSubmit={onSubmit}
  >
    <Form>
      <VStack spacing="24px" alignItems="flex-start">
        <FormControlGroup>
          <FormControl required label="カテゴリー">
            <FormikSelectField
              mode="fill"
              name="category"
              options={GraphQLEnums.NotificationCategory}
            />
          </FormControl>
          <FormControl required label="タイトル">
            <FormikTextField type="text" mode="fill" name="title" />
          </FormControl>
          <FormControl required label="本文">
            <FormikTextareaField mode="fill" name="body" />
          </FormControl>
          <FormControl required label="公開日">
            <FormikTextField type="text" mode="fill" name="publishedAt" />
          </FormControl>
        </FormControlGroup>
        <FormikFormButtons onCancelClick={onCancelClick} />
      </VStack>
    </Form>
  </Formik>
);
