import { FC, Fragment, useCallback, useMemo } from "react";
import { graphql, useFragment } from "react-relay";
import { NavLink } from "react-router-dom";

import { UserRole } from "~/src/__generated__/schema";
import { ABTestTableDataRow_abtestScenarioPageGroup$key } from "~/src/__relay_artifacts__/ABTestTableDataRow_abtestScenarioPageGroup.graphql";
import {
  TableDataCheckboxColumn,
  TableDataColumn,
  TableDataRow,
} from "~/src/components/common/tables/Table";
import { URLItem } from "~/src/components/common/URLItem";
import { DistributionStatusSwitch } from "~/src/components/features/global/DistributionStatusSwitch";

import { ABTestTableActions } from "./ABTestTableActions";
import { ROW_HEIGHT } from "./styles";

export type Props = {
  abtestRef: ABTestTableDataRow_abtestScenarioPageGroup$key;
  siteSlug: string;
  dataColumns: string[];
  isChecked: boolean;
  connectionId: string;
  onCheck: (abtestSlug: string, isChecked: boolean) => void;
  role: UserRole;
};

const abtestScenarioPageGroupFragment = graphql`
  fragment ABTestTableDataRow_abtestScenarioPageGroup on AbtestScenarioPageGroup {
    abtestScenarioPages(exceptKind: DEFAULT) {
      totalCount
    }
    endDate
    id
    page {
      id
      slug
      name
      kind
      sortNumber
      url
      funnel {
        name
        slug
        id
        siteUrl
      }
    }
    memo
    slug
    startDate
    status
    title
    updatedAt
    isArchive
    judgeStatus
  }
`;

export const ABTestTableDataRow: FC<Props> = ({
  siteSlug,
  abtestRef,
  dataColumns,
  isChecked,
  connectionId,
  onCheck,
  role,
}) => {
  const abtest = useFragment(abtestScenarioPageGroupFragment, abtestRef);

  const handleCheck = useCallback(
    () => onCheck(abtest.slug, isChecked),
    [abtest.slug, onCheck, isChecked]
  );

  const abtestMemo = useMemo(() => abtest.memo || "", [abtest.memo]);

  return (
    <TableDataRow height={ROW_HEIGHT}>
      <TableDataCheckboxColumn isChecked={isChecked} onChange={handleCheck} />
      <TableDataColumn
        type="text"
        isLink
        data={
          <NavLink to={`/sites/${siteSlug}/abtests/${abtest.slug}`}>
            {abtest.title}
          </NavLink>
        }
      />
      <TableDataColumn
        type="actions"
        data={
          <DistributionStatusSwitch
            id={abtest.id}
            name={abtest.title}
            isChecked={abtest.status === "ACTIVE"}
          />
        }
      />
      {role !== "SAAS_CLIENT" && (
        <TableDataColumn
          type="text"
          data={abtest.judgeStatus === "APPROVED" ? "承認" : "未承認"}
        />
      )}
      {dataColumns.map((dataColumn, i) => (
        <Fragment key={i}>
          {dataColumn === "funnelTitle" && (
            <TableDataColumn
              type="text"
              data={
                <URLItem
                  url={abtest.page.funnel.siteUrl}
                  name={abtest.page.funnel.name}
                />
              }
            />
          )}
          {dataColumn === "funnelPage" && (
            <TableDataColumn
              type="text"
              data={
                <URLItem
                  url={abtest.page.url}
                  name={
                    abtest.page.kind !== "FORM"
                      ? abtest.page.name
                      : abtest.page.name +
                        "(" +
                        (abtest.page.sortNumber - 1) +
                        ")"
                  }
                />
              }
            />
          )}
          {dataColumn === "patternCount" && (
            <TableDataColumn
              type="numeric"
              data={abtest.abtestScenarioPages?.totalCount || 0}
            />
          )}
          {dataColumn === "periodSince" && (
            <TableDataColumn type="text" data={abtest.startDate} />
          )}
          {dataColumn === "periodTill" && (
            <TableDataColumn type="text" data={abtest.endDate} />
          )}
          {dataColumn === "memo" && (
            <TableDataColumn
              type="text"
              data={
                abtestMemo.length > 30
                  ? abtestMemo.substring(0, 30) + "..."
                  : abtestMemo
              }
            />
          )}
          {dataColumn === "updatedAt" && (
            <TableDataColumn type="date" data={abtest.updatedAt} />
          )}
        </Fragment>
      ))}
      <TableDataColumn
        type="actions"
        data={
          <ABTestTableActions
            abtestScenarioPageGroup={abtest}
            connectionId={connectionId}
            filtered={abtest.isArchive}
            currentValues={{
              funnelId: abtest.page.funnel.id,
              pageKind: abtest.page.kind,
              pageId: abtest.page.id,
            }}
          />
        }
      />
    </TableDataRow>
  );
};
