/**
 * @generated SignedSource<<77944af177bf8db608b11ea37928932c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FunnelListTabContainer_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"FunnelListTab_user">;
  readonly " $fragmentType": "FunnelListTabContainer_user";
};
export type FunnelListTabContainer_user = FunnelListTabContainer_user$data;
export type FunnelListTabContainer_user$key = {
  readonly " $data"?: FunnelListTabContainer_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"FunnelListTabContainer_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FunnelListTabContainer_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FunnelListTab_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "e4a5355ec3ec11016eb3b01463a505c7";

export default node;
