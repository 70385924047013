import { Box, Flex } from "@chakra-ui/react";
import { FC } from "react";

import {
  HeaderLogo,
  HeaderNavigation,
  HeaderNavigationProps,
} from "~/src/components/features/global/HeaderNavigation";

export type Props = {
  companyName: HeaderNavigationProps["companyName"];
  email: HeaderNavigationProps["email"];
  onLogoutClick: HeaderNavigationProps["onLogoutClick"];
};

export const HeaderOnlyLayout: FC<Props> = ({
  children,
  companyName,
  email,
  onLogoutClick,
}) => (
  <Flex w="100%" h="100%" flexDirection="column">
    <HeaderNavigation
      companyName={companyName}
      email={email}
      onLogoutClick={onLogoutClick}
    >
      <HeaderLogo />
    </HeaderNavigation>
    <Box flex="1" overflow="auto">
      <Box width="1300px" pt="100px" px="11px" margin="0 auto">
        {children}
      </Box>
    </Box>
  </Flex>
);
