/**
 * @generated SignedSource<<c70516ff3dadac683ae01c3705e3342e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserRole = "SUPER" | "ADMIN_ALL" | "ADMIN" | "JUDGE" | "CLIENT" | "SAAS_CLIENT" | "%future added value";
export type SettingsPageContainer_Query$variables = {
  siteSlug: string;
};
export type SettingsPageContainer_QueryVariables = SettingsPageContainer_Query$variables;
export type SettingsPageContainer_Query$data = {
  readonly viewer: {
    readonly role: UserRole;
    readonly " $fragmentSpreads": FragmentRefs<"FunnelListTabContainer_user">;
  };
  readonly site: {
    readonly " $fragmentSpreads": FragmentRefs<"FunnelListTabContainer_site" | "ExcludedIPListTabContainer_site" | "ScriptTagSettingTabContainer_site">;
  };
};
export type SettingsPageContainer_QueryResponse = SettingsPageContainer_Query$data;
export type SettingsPageContainer_Query = {
  variables: SettingsPageContainer_QueryVariables;
  response: SettingsPageContainer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "siteSlug"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "siteSlug"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v6 = {
  "kind": "Literal",
  "name": "first",
  "value": 120
},
v7 = [
  (v6/*: any*/),
  {
    "kind": "Literal",
    "name": "searchText",
    "value": ""
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v9 = [
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "tabletUrl",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "mobileUrl",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "urlMatchType",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "hasForm",
    "storageKey": null
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__id",
      "storageKey": null
    }
  ]
},
v14 = [
  (v6/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SettingsPageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "FunnelListTabContainer_user"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "FunnelListTabContainer_site"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ExcludedIPListTabContainer_site"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ScriptTagSettingTabContainer_site"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SettingsPageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "FunnelConnection",
            "kind": "LinkedField",
            "name": "funnels",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "FunnelEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Funnel",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isArchive",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Page",
                        "kind": "LinkedField",
                        "name": "landingPage",
                        "plural": false,
                        "selections": (v9/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Page",
                        "kind": "LinkedField",
                        "name": "formPage",
                        "plural": true,
                        "selections": (v9/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Page",
                        "kind": "LinkedField",
                        "name": "confirmPage",
                        "plural": false,
                        "selections": (v9/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Page",
                        "kind": "LinkedField",
                        "name": "thanksPage",
                        "plural": false,
                        "selections": (v9/*: any*/),
                        "storageKey": null
                      },
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              (v12/*: any*/),
              (v13/*: any*/)
            ],
            "storageKey": "funnels(first:120,searchText:\"\")"
          },
          {
            "alias": null,
            "args": (v7/*: any*/),
            "filters": [
              "searchText",
              "withArchived",
              "onlyExperiment",
              "orderBy"
            ],
            "handle": "connection",
            "key": "FunnelListTab_funnels",
            "kind": "LinkedHandle",
            "name": "funnels"
          },
          {
            "alias": null,
            "args": (v14/*: any*/),
            "concreteType": "ExcludedIpAddressConnection",
            "kind": "LinkedField",
            "name": "excludedIpAddresses",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ExcludedIpAddressEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExcludedIpAddress",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "ipAddress",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "updatedAt",
                        "storageKey": null
                      },
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              (v12/*: any*/),
              (v13/*: any*/)
            ],
            "storageKey": "excludedIpAddresses(first:120)"
          },
          {
            "alias": null,
            "args": (v14/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "ExcludedIPListTab_excludedIpAddresses",
            "kind": "LinkedHandle",
            "name": "excludedIpAddresses"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "embedTag",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "robeeTag",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "055d301412c22ed42fd095449d271baa",
    "id": null,
    "metadata": {},
    "name": "SettingsPageContainer_Query",
    "operationKind": "query",
    "text": "query SettingsPageContainer_Query(\n  $siteSlug: String!\n) {\n  viewer {\n    role\n    ...FunnelListTabContainer_user\n    id\n  }\n  site(slug: $siteSlug) {\n    ...FunnelListTabContainer_site\n    ...ExcludedIPListTabContainer_site\n    ...ScriptTagSettingTabContainer_site\n    id\n  }\n}\n\nfragment ExcludedIPListTabContainer_site on Site {\n  ...ExcludedIPListTab_site\n}\n\nfragment ExcludedIPListTab_site on Site {\n  id\n  slug\n  excludedIpAddresses(first: 120) {\n    totalCount\n    edges {\n      node {\n        slug\n        ...ExcludedIPTableDataRow_excludedIpAddress\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment ExcludedIPTableDataRow_excludedIpAddress on ExcludedIpAddress {\n  id\n  ipAddress\n  status\n  slug\n  createdAt\n  updatedAt\n}\n\nfragment FunnelListTabContainer_site on Site {\n  ...FunnelListTab_site\n}\n\nfragment FunnelListTabContainer_user on User {\n  ...FunnelListTab_user\n}\n\nfragment FunnelListTab_site on Site {\n  id\n  name\n  slug\n  funnels(first: 120, searchText: \"\") {\n    totalCount\n    edges {\n      node {\n        slug\n        id\n        name\n        ...FunnelTableDataRow_funnel\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment FunnelListTab_user on User {\n  role\n}\n\nfragment FunnelTableDataRow_funnel on Funnel {\n  id\n  slug\n  name\n  isArchive\n  landingPage {\n    id\n    url\n    tabletUrl\n    mobileUrl\n    urlMatchType\n    hasForm\n  }\n  formPage {\n    id\n    url\n    tabletUrl\n    mobileUrl\n    urlMatchType\n    hasForm\n  }\n  confirmPage {\n    id\n    url\n    tabletUrl\n    mobileUrl\n    urlMatchType\n    hasForm\n  }\n  thanksPage {\n    id\n    url\n    tabletUrl\n    mobileUrl\n    urlMatchType\n    hasForm\n  }\n}\n\nfragment ScriptTagSettingTabContainer_site on Site {\n  id\n  embedTag\n  robeeTag\n}\n"
  }
};
})();

(node as any).hash = "3aa0c42288e0b4e1f4f25e757e93ba11";

export default node;
