import { Text } from "@chakra-ui/layout";
import { FC } from "react";
import { graphql, useFragment } from "react-relay";

import { GraphQLEnums } from "~/src/__generated__/GraphQLEnums";
import { ABTestDistributionSettingSection_abtestGroup$key } from "~/src/__relay_artifacts__/ABTestDistributionSettingSection_abtestGroup.graphql";
import { Chip } from "~/src/components/common/Chip";
import { DefinitionTeam } from "~/src/components/common/DefinitionTeam";
import { SectionCard } from "~/src/components/common/SectionCard";

type Props = {
  abtestGroupRef: ABTestDistributionSettingSection_abtestGroup$key;
};

const fragment = graphql`
  fragment ABTestDistributionSettingSection_abtestGroup on AbtestScenarioPageGroup {
    sources {
      id
      name
    }
    excludeSources {
      id
      name
    }
    referrers {
      referrer
      matchOp
    }
    excludeReferrers {
      referrer
      matchOp
    }
    visitCount
    visitMatchType
    deliverTargetUrls {
      id
      url
    }
    deliverExcludeUrls {
      id
      url
    }
    deliverTargetLabels {
      id
      name
    }
    deliverExcludeLabels {
      id
      name
    }
    targetCookies {
      id
      key
      value
      matchType
    }
  }
`;

export const ABTestDistributionSettingSection: FC<Props> = ({
  abtestGroupRef,
}) => {
  const abtestGroup = useFragment(fragment, abtestGroupRef);

  return (
    <SectionCard description="配信設定">
      <DefinitionTeam label="流入元カテゴリ">
        {abtestGroup.sources.map((source) => (
          <Chip key={source.id} label={source.name} />
        ))}
      </DefinitionTeam>

      <DefinitionTeam label="非表示の流入元カテゴリ">
        {abtestGroup.excludeSources.map((source) => (
          <Chip key={source.id} label={source.name} />
        ))}
      </DefinitionTeam>

      <DefinitionTeam label="流入元">
        {abtestGroup.referrers.map((referrer, index) => (
          <DefinitionTeam
            key={index}
            dense
            label={
              GraphQLEnums.MatchOp.find((op) => op.value === referrer.matchOp)
                ?.label || ""
            }
          >
            {referrer.referrer}
          </DefinitionTeam>
        ))}
      </DefinitionTeam>

      <DefinitionTeam label="除外流入元">
        {abtestGroup.excludeReferrers.map((referrer, index) => (
          <DefinitionTeam
            key={index}
            dense
            label={
              GraphQLEnums.MatchOp.find((op) => op.value === referrer.matchOp)
                ?.label || ""
            }
          >
            {referrer.referrer}
          </DefinitionTeam>
        ))}
      </DefinitionTeam>
      <DefinitionTeam label="ユーザーの訪問回数">
        <DefinitionTeam
          dense
          label={
            GraphQLEnums.VisitMatch.find(
              (op) => op.value === abtestGroup.visitMatchType
            )?.label || ""
          }
        >
          {abtestGroup.visitCount ? `${abtestGroup.visitCount} 回` : "未設定"}
        </DefinitionTeam>
      </DefinitionTeam>
      <DefinitionTeam label="URL">
        <Text>{abtestGroup.deliverTargetUrls.map((url) => url.url)}</Text>
      </DefinitionTeam>
      <DefinitionTeam label="除外URL">
        {abtestGroup.deliverExcludeUrls.map((url) => (
          <Text key={url.id}>{url.url}</Text>
        ))}
      </DefinitionTeam>
      <DefinitionTeam label="ラベル">
        {abtestGroup.deliverTargetLabels.map((label) => (
          <Chip key={label.id} label={label.name} />
        ))}
      </DefinitionTeam>
      <DefinitionTeam label="除外ラベル">
        {abtestGroup.deliverExcludeLabels.map((label) => (
          <Chip key={label.id} label={label.name} />
        ))}
      </DefinitionTeam>
      <DefinitionTeam label="Cookie">
        {abtestGroup.targetCookies.map((cookie) => (
          <DefinitionTeam
            key={cookie.id}
            dense
            label={cookie.matchType ? "一致" : "一致しない"}
          >
            <DefinitionTeam dense label="key">
              {cookie.key}
            </DefinitionTeam>
            <DefinitionTeam dense label="value">
              {cookie.value}
            </DefinitionTeam>
          </DefinitionTeam>
        ))}
      </DefinitionTeam>
    </SectionCard>
  );
};
