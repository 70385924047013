import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { Suspense } from "react";

import { PageLoading } from "~/src/components/common/PageLoading";

import { PopupDefaultByDateReportContainer } from "./PopupDefaultByDateReportContainer";
import { PopupDefaultReportContainer } from "./PopupDefaultReportContainer";
import { PopupPageByDateReportContainer } from "./PopupPageByDateReportContainer";
import { PopupPageReportContainer } from "./PopupPageReportContainer";
import { PopupPatternNameByDateReportContainer } from "./PopupPatternNameByDateReportContainer";
import { PopupPatternNameReportContainer } from "./PopupPatternNameReportContainer";

export const PopupReportContainer = () => {
  return (
    <Tabs isFitted variant="enclosed">
      <TabList>
        <Tab>通常レポート</Tab>
        <Tab>通常レポート(日付別)</Tab>
        <Tab>パターン名別レポート</Tab>
        <Tab>パターン名別レポート(日付別)</Tab>
        <Tab>ページ別レポート</Tab>
        <Tab>ページ別レポート(日付別)</Tab>
      </TabList>

      <TabPanels>
        <TabPanel>
          <Suspense fallback={<PageLoading />}>
            <PopupDefaultReportContainer />
          </Suspense>
        </TabPanel>
        <TabPanel>
          <Suspense fallback={<PageLoading />}>
            <PopupDefaultByDateReportContainer />
          </Suspense>
        </TabPanel>
        <TabPanel>
          <Suspense fallback={<PageLoading />}>
            <PopupPatternNameReportContainer />
          </Suspense>
        </TabPanel>
        <TabPanel>
          <Suspense fallback={<PageLoading />}>
            <PopupPatternNameByDateReportContainer />
          </Suspense>
        </TabPanel>
        <TabPanel>
          <Suspense fallback={<PageLoading />}>
            <PopupPageReportContainer />
          </Suspense>
        </TabPanel>
        <TabPanel>
          <Suspense fallback={<PageLoading />}>
            <PopupPageByDateReportContainer />
          </Suspense>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
