import { FC } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";

import { ChatbotPatternEditPageContainer_Query } from "~/src/__relay_artifacts__/ChatbotPatternEditPageContainer_Query.graphql";

import { ChatbotPatternEditScreenContainer } from "./ChatbotPatternEditScreenContainer";

export type Props = {};

const query = graphql`
  query ChatbotPatternEditPageContainer_Query($slug: String!) {
    viewer {
      role
    }
    chatbot(slug: $slug) {
      ...ChatbotPatternEditScreenContainer_chatbot
    }
  }
`;

export const ChatbotPatternEditPageContainer: FC<Props> = () => {
  const { patternSlug = "" } = useParams();
  const { chatbot, viewer } =
    useLazyLoadQuery<ChatbotPatternEditPageContainer_Query>(query, {
      slug: patternSlug,
    });

  if (!chatbot) throw new Error("assertion failed");

  return (
    <ChatbotPatternEditScreenContainer
      chatbotRef={chatbot}
      role={viewer.role}
    />
  );
};
