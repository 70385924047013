import { FC } from "react";
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";

import { CustomVictoryTooltip } from "./CustomVictoryTooltip";

export type Props = {
  toolTipTitle: string;
  data: { x: number | string; y: number }[];
};

const baseLabelStyles = {
  fontFamily: "'Noto Sans JP'",
  fontSize: 12,
  letterSpacing: "normal",
  padding: 7,
  fill: "#979797",
  stroke: "transparent",
};

const centeredLabelStyles = { textAnchor: "middle", ...baseLabelStyles };

const theme = {
  axis: {
    style: {
      axis: {
        fill: "transparent",
        stroke: "#DADADA",
        strokeWidth: 1,
        strokeLinecap: "round",
        strokeLinejoin: "round",
      },
      axisLabel: { ...centeredLabelStyles, padding: 25 },
      grid: {
        fill: "none",
        stroke: "#DADADA",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        pointerEvents: "painted",
      },
      ticks: {
        fill: "transparent",
        size: 1,
        stroke: "transparent",
      },
      tickLabels: baseLabelStyles,
    },
  },
};

export const CustomVictoryChart: FC<Props> = ({ data, toolTipTitle }) => (
  <VictoryChart
    width={570}
    height={240}
    scale={{ x: "time" }}
    padding={{
      left: 50,
      right: 28,
      bottom: 28,
      top: 18,
    }}
    theme={theme}
    containerComponent={
      <VictoryVoronoiContainer
        // eslint-disable-next-line react/jsx-no-bind
        labels={() => " "}
        labelComponent={
          <VictoryTooltip
            flyoutComponent={<CustomVictoryTooltip title={toolTipTitle} />}
          />
        }
      />
    }
  >
    <linearGradient id="gradient" x1="0" x2="0" y1="0" y2="1">
      <stop offset="0%" stopColor="#F0D209" />
      <stop offset="100%" stopColor="rgba(240, 210, 9, 0)" />
    </linearGradient>
    <VictoryAxis
      // eslint-disable-next-line react/forbid-component-props
      style={{
        grid: {
          fill: "none",
          stroke: "none",
          pointerEvents: "painted",
        },
      }}
    />
    <VictoryAxis dependentAxis />
    <VictoryArea
      data={data}
      interpolation="basis"
      /* eslint-disable */
      style={{
        data: {
          fill: "url(#gradient)",
          stroke: "#F0D209",
          strokeWidth: 3,
        },
      }}
    />
  </VictoryChart>
);
