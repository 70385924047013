import { format } from "date-fns";
import { FC, useContext } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";

import { JudgementListPageContainer_Query } from "~/src/__relay_artifacts__/JudgementListPageContainer_Query.graphql";
import { DateRangeContext } from "~/src/components/features/global/HeaderNavigation/DateRangeField";

import { JudgementListPage } from "./presentations";

const query = graphql`
  query JudgementListPageContainer_Query(
    $startDate: ISO8601Date
    $endDate: ISO8601Date
  ) {
    viewer {
      id
      ...JudgementListPage_viewer
    }
  }
`;

export const JudgementListPageContainer: FC = () => {
  const {
    dateRange: [startOn, endOn],
  } = useContext(DateRangeContext);

  const { viewer } = useLazyLoadQuery<JudgementListPageContainer_Query>(query, {
    startDate: format(startOn, "yyyy-MM-dd"),
    endDate: format(endOn, "yyyy-MM-dd"),
  });

  return <JudgementListPage userRef={viewer} />;
};
