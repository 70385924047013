import { Center } from "@chakra-ui/react";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useFormErrorHandler } from "~/src/lib/hooks";

import { SignupForm, SignupFormProps } from "../../features/auth";

export type Props = {};

export const SignupPage: FC<Props> = () => {
  const navigate = useNavigate();
  const { onFormError } = useFormErrorHandler();

  const handleSubmit = useCallback<SignupFormProps["onSubmit"]>(
    async (values, { setErrors }) => {
      const auth = getAuth();

      try {
        await createUserWithEmailAndPassword(
          auth,
          values.email,
          values.password
        );
        navigate("/");
      } catch (error) {
        onFormError(error, setErrors);
      }
    },
    [navigate, onFormError]
  );

  return (
    <Center h={"100%"}>
      <SignupForm onSubmit={handleSubmit} />
    </Center>
  );
};
