import { FC } from "react";
import { Handle, NodeProps, Position } from "react-flow-renderer";

import { chakraFactory } from "~/src/lib/chakra-ui";

import { NodeData } from "./types";

const ReplyNodeWrapper = chakraFactory("div", {
  baseStyle: {
    border: "1px solid #eee",
    padding: "5px",
    borderRadius: "5px",
    background: "white",
    width: "120px",
    textAlign: "center",
  },
});

const LabelWrapper = chakraFactory("label", {
  baseStyle: {
    display: "block",
    color: "#777",
    fontSize: "12px",
  },
});

export const ReplyNode: FC<NodeProps<NodeData>> = ({ id, data }) => {
  return (
    <ReplyNodeWrapper>
      <Handle type="source" position={Position.Bottom} id={id} />
      <LabelWrapper>{data.label}</LabelWrapper>
    </ReplyNodeWrapper>
  );
};
