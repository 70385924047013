/**
 * @generated SignedSource<<8fe85c7a50921b04012f3549379f4357>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DeliverKind = "EQUAL" | "WEIGHT" | "%future added value";
export type UserRole = "SUPER" | "ADMIN_ALL" | "ADMIN" | "JUDGE" | "CLIENT" | "SAAS_CLIENT" | "%future added value";
export type PopupGroupPatternCreatePageContainer_Query$variables = {
  siteSlug: string;
  popupGroupSlug: string;
};
export type PopupGroupPatternCreatePageContainer_QueryVariables = PopupGroupPatternCreatePageContainer_Query$variables;
export type PopupGroupPatternCreatePageContainer_Query$data = {
  readonly viewer: {
    readonly role: UserRole;
  };
  readonly site: {
    readonly id: string;
    readonly name: string;
    readonly slug: string;
  };
  readonly popupGroup: {
    readonly id: string;
    readonly name: string;
    readonly slug: string;
    readonly deliverKind: DeliverKind;
  } | null;
};
export type PopupGroupPatternCreatePageContainer_QueryResponse = PopupGroupPatternCreatePageContainer_Query$data;
export type PopupGroupPatternCreatePageContainer_Query = {
  variables: PopupGroupPatternCreatePageContainer_QueryVariables;
  response: PopupGroupPatternCreatePageContainer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "popupGroupSlug"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "siteSlug"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "slug",
      "variableName": "siteSlug"
    }
  ],
  "concreteType": "Site",
  "kind": "LinkedField",
  "name": "site",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "slug",
      "variableName": "popupGroupSlug"
    }
  ],
  "concreteType": "PopupGroup",
  "kind": "LinkedField",
  "name": "popupGroup",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deliverKind",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PopupGroupPatternCreatePageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PopupGroupPatternCreatePageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      (v6/*: any*/),
      (v7/*: any*/)
    ]
  },
  "params": {
    "cacheID": "e53a75c119129bba3cce965782a3b8e1",
    "id": null,
    "metadata": {},
    "name": "PopupGroupPatternCreatePageContainer_Query",
    "operationKind": "query",
    "text": "query PopupGroupPatternCreatePageContainer_Query(\n  $siteSlug: String!\n  $popupGroupSlug: String!\n) {\n  viewer {\n    role\n    id\n  }\n  site(slug: $siteSlug) {\n    id\n    name\n    slug\n  }\n  popupGroup(slug: $popupGroupSlug) {\n    id\n    name\n    slug\n    deliverKind\n  }\n}\n"
  }
};
})();

(node as any).hash = "8bff1431a4c5a2117513badafbc2e83f";

export default node;
