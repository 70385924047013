import {
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  Text,
} from "@chakra-ui/react";
import { AddCircleOutlined as AddCircleOutlinedIcon } from "@mui/icons-material";
import { useFormikContext } from "formik";
import { ChangeEvent, useCallback, useState } from "react";

import { SymbolOutlineButton } from "~/src/components/common/Button";
import { chakraFactory } from "~/src/lib/chakra-ui";

type Props = {
  name: string;
  accept: string;
};

const StyledInput = chakraFactory(Input, {
  baseStyle: {
    display: "none",
  },
});

const StyledLabel = chakraFactory(FormLabel, {
  baseStyle: {
    fontSize: "unset",
  },
});

export const FileUploadField: React.FC<Props> = ({ name, accept }) => {
  const [filename, setFilename] = useState<string>();
  const { setFieldValue } = useFormikContext();

  const handleUpload = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      try {
        const file = e?.target?.files && e.target.files[0];
        if (!file) return;

        setFilename(file.name);
        setFieldValue(name, file);
      } catch (error) {
        console.error("error", error);
      }
    },
    [name, setFieldValue]
  );
  return (
    <FormControl>
      <InputGroup>
        <HStack spacing={"4px"}>
          <StyledInput type="file" accept={accept} onChange={handleUpload} />
          <SymbolOutlineButton icon={<AddCircleOutlinedIcon />}>
            <StyledLabel margin="auto">ファイルアップロード</StyledLabel>
          </SymbolOutlineButton>
          <Text>{filename}</Text>
        </HStack>
      </InputGroup>
    </FormControl>
  );
};
