/**
 * @generated SignedSource<<75a819ec768ab874b5b8c19f215d34aa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChatbotEditPageContainer_Query$variables = {
  chatbotSlug: string;
  siteSlug: string;
};
export type ChatbotEditPageContainer_QueryVariables = ChatbotEditPageContainer_Query$variables;
export type ChatbotEditPageContainer_Query$data = {
  readonly chatbotGroup: {
    readonly " $fragmentSpreads": FragmentRefs<"ChatbotEditScreenContainer_chatbotGroup">;
  } | null;
  readonly site: {
    readonly " $fragmentSpreads": FragmentRefs<"ChatbotEditScreenContainer_site">;
  };
};
export type ChatbotEditPageContainer_QueryResponse = ChatbotEditPageContainer_Query$data;
export type ChatbotEditPageContainer_Query = {
  variables: ChatbotEditPageContainer_QueryVariables;
  response: ChatbotEditPageContainer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "chatbotSlug"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "siteSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "chatbotSlug"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "siteSlug"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Funnel",
  "kind": "LinkedField",
  "name": "funnel",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
},
v7 = [
  (v3/*: any*/),
  (v4/*: any*/)
],
v8 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "referrer",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "matchOp",
    "storageKey": null
  }
],
v9 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  },
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChatbotEditPageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ChatbotGroup",
        "kind": "LinkedField",
        "name": "chatbotGroup",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ChatbotEditScreenContainer_chatbotGroup"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ChatbotEditScreenContainer_site"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChatbotEditPageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ChatbotGroup",
        "kind": "LinkedField",
        "name": "chatbotGroup",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Page",
            "kind": "LinkedField",
            "name": "page",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "browsers",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "devices",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "genders",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ageRanges",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deliverKind",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "platforms",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "repeat",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Source",
            "kind": "LinkedField",
            "name": "sources",
            "plural": true,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ChatbotGroupExcludeReferrer",
            "kind": "LinkedField",
            "name": "excludeReferrers",
            "plural": true,
            "selections": (v8/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ChatbotGroupReferrer",
            "kind": "LinkedField",
            "name": "referrers",
            "plural": true,
            "selections": (v8/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "visitCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "visitMatchType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ChatbotGroupDeliverTargetUrl",
            "kind": "LinkedField",
            "name": "deliverTargetUrls",
            "plural": true,
            "selections": (v9/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ChatbotGroupDeliverExcludeUrl",
            "kind": "LinkedField",
            "name": "deliverExcludeUrls",
            "plural": true,
            "selections": (v9/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserLabel",
            "kind": "LinkedField",
            "name": "deliverTargetLabels",
            "plural": true,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserLabel",
            "kind": "LinkedField",
            "name": "deliverExcludeLabels",
            "plural": true,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ChatbotGroupTargetCookie",
            "kind": "LinkedField",
            "name": "targetCookies",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "matchType",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DeliverDayOfWeek",
            "kind": "LinkedField",
            "name": "deliverDayOfWeeks",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "weekOfMonth",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "daysOfWeek",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FunnelConnection",
            "kind": "LinkedField",
            "name": "funnels",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FunnelEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Funnel",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageConnection",
            "kind": "LinkedField",
            "name": "pages",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PageEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Page",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "kind",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sortNumber",
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2767be717dd33b8eb84469b3d30110f2",
    "id": null,
    "metadata": {},
    "name": "ChatbotEditPageContainer_Query",
    "operationKind": "query",
    "text": "query ChatbotEditPageContainer_Query(\n  $chatbotSlug: String!\n  $siteSlug: String!\n) {\n  chatbotGroup(slug: $chatbotSlug) {\n    ...ChatbotEditScreenContainer_chatbotGroup\n    id\n  }\n  site(slug: $siteSlug) {\n    ...ChatbotEditScreenContainer_site\n    id\n  }\n}\n\nfragment ChatbotEditScreenContainer_chatbotGroup on ChatbotGroup {\n  id\n  name\n  slug\n  page {\n    id\n    funnel {\n      id\n    }\n  }\n  browsers\n  devices\n  genders\n  ageRanges\n  deliverKind\n  platforms\n  repeat\n  startDate\n  startTime\n  endDate\n  endTime\n  status\n  sources {\n    id\n    name\n  }\n  excludeReferrers {\n    referrer\n    matchOp\n  }\n  referrers {\n    referrer\n    matchOp\n  }\n  visitCount\n  visitMatchType\n  deliverTargetUrls {\n    url\n    id\n  }\n  deliverExcludeUrls {\n    url\n    id\n  }\n  deliverTargetLabels {\n    id\n    name\n  }\n  deliverExcludeLabels {\n    id\n    name\n  }\n  targetCookies {\n    key\n    value\n    matchType\n    id\n  }\n  deliverDayOfWeeks {\n    weekOfMonth\n    daysOfWeek\n  }\n}\n\nfragment ChatbotEditScreenContainer_site on Site {\n  id\n  name\n  slug\n  ...ChatbotFirstStepForm_site\n}\n\nfragment ChatbotFirstStepForm_site on Site {\n  funnels {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n  pages {\n    edges {\n      node {\n        id\n        name\n        kind\n        sortNumber\n        funnel {\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d19184c9ea92f27201be6d4b4afe5415";

export default node;
