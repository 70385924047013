import { useFormikContext } from "formik";
import { FC } from "react";

import {
  FormButtons,
  FormButtonsProps,
} from "~/src/components/common/forms/FormButtons";

export type Props = {
  onCancelClick: FormButtonsProps["onCancelClick"];
};

export const FormikFormButtons: FC<Props> = ({ onCancelClick }) => {
  const { isSubmitting, isValid, dirty } = useFormikContext();

  return (
    <FormButtons
      disabled={!isValid && dirty}
      loading={isSubmitting}
      onCancelClick={onCancelClick}
    />
  );
};
