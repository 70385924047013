import {
  DeleteOutline as DeleteOutlineIcon,
  EditOutlined as EditOutlinedIcon,
} from "@mui/icons-material";
import { FC, useCallback, useMemo } from "react";

import {
  TableDataCheckboxColumn,
  TableDataColumn,
  TableDataRow,
} from "~/src/components/common/tables/Table";
import { TableActions } from "~/src/components/common/tables/TableActions";

import { StyledUrlDataColumn } from "./styles";

export type SourceTag = {
  id: string;
  key: string | null;
  value: string | null;
  sourceName: string;
  pageUrl: string;
  slug: string;
};

export type Props = {
  siteSlug: string;
  funnelSlug: string;
  sourceTag: SourceTag;
  isChecked: boolean;
  onDeleteClick: (sourceTagId: string) => void;
  onCheck: (sourceTagSlug: string, isChecked: boolean) => void;
};

export const FunnelSourceTableDataRow: FC<Props> = ({
  siteSlug,
  funnelSlug,
  sourceTag,
  isChecked,
  onDeleteClick,
  onCheck,
}) => {
  const actionMenus = useMemo(
    () => [
      {
        label: "編集",
        icon: <EditOutlinedIcon />,
        to: `/sites/${siteSlug}/funnels/${funnelSlug}/sources/${sourceTag.slug}/edit`,
      },
      {
        label: "削除",
        icon: <DeleteOutlineIcon />,
        onClick: () => onDeleteClick(sourceTag.id),
      },
    ],
    [funnelSlug, sourceTag.id, sourceTag.slug, onDeleteClick, siteSlug]
  );

  const url = useMemo(() => {
    return sourceTag.pageUrl + "?" + sourceTag.key + "=" + sourceTag.value;
  }, [sourceTag.pageUrl, sourceTag.key, sourceTag.value]);

  const handleCheck = useCallback(
    () => onCheck(sourceTag.slug, isChecked),
    [sourceTag.slug, onCheck, isChecked]
  );

  return (
    <TableDataRow>
      <TableDataCheckboxColumn isChecked={isChecked} onChange={handleCheck} />
      <TableDataColumn type="text" data={sourceTag.sourceName} />
      <StyledUrlDataColumn type="text" data={url} />
      <TableDataColumn
        type="actions"
        data={<TableActions actionMenus={actionMenus} />}
      />
    </TableDataRow>
  );
};
