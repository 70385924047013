import { Box, Flex, HStack, Spacer, VStack } from "@chakra-ui/react";
import { FC, useMemo } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { NavLink, useParams } from "react-router-dom";

import { ABTestPatternDetailPageContainer_Query } from "~/src/__relay_artifacts__/ABTestPatternDetailPageContainer_Query.graphql";
import { OutlineButton } from "~/src/components/common/Button";
import { SectionCard } from "~/src/components/common/SectionCard";
import { URLItem } from "~/src/components/common/URLItem";
import { ArchiveButton } from "~/src/components/features/archive";
import { PageLayout } from "~/src/components/features/global/PageLayout";

import { ABTestPatternRedirectDetailContainer } from "./presentations/ABTestPatternRedirectDetail";
import { ABTestPatternScenarioDetailContainer } from "./presentations/ABTestPatternScenarioDetail";
import { ABTestPatternTextReplaceDetailContainer } from "./presentations/ABTestPatternTextReplaceDetail";

export type Props = {};

const query = graphql`
  query ABTestPatternDetailPageContainer_Query(
    $abtestSlug: String!
    $abtestGroupSlug: String!
  ) {
    abtestScenarioPageGroup(slug: $abtestGroupSlug) {
      page {
        name
        kind
        sortNumber
        url
        funnel {
          name
          siteUrl
        }
      }
      title
    }
    abtestScenarioPage(slug: $abtestSlug) {
      id
      title
      slug
      kind
      linkUrl
      isInheritQuery
      page {
        url
      }
      abtestScenarioPageContents {
        edges {
          node {
            ... on AbtestScenarioPageContentScenario {
              id
              beforeSrc
              imageUrl
            }
            ... on AbtestScenarioPageContentTextReplace {
              id
              targetType
              targetValue
              actionType
              targetAttribute
              replaceValue
            }
          }
        }
      }
    }
  }
`;

export const ABTestPatternDetailPageContainer: FC<Props> = () => {
  const { siteSlug = "", abtestSlug = "", patternSlug = "" } = useParams();

  const { abtestScenarioPage, abtestScenarioPageGroup } =
    useLazyLoadQuery<ABTestPatternDetailPageContainer_Query>(query, {
      abtestSlug: patternSlug,
      abtestGroupSlug: abtestSlug,
    });

  if (!abtestScenarioPage) throw new Error("assertion failed");

  const abtestScenarioPageContents = useMemo(() => {
    const edges = abtestScenarioPage.abtestScenarioPageContents?.edges || [];
    return edges.map((edge) => {
      if (!edge || !edge.node) throw new Error("assertion failed");
      return edge.node;
    });
  }, [abtestScenarioPage.abtestScenarioPageContents?.edges]);

  return (
    <PageLayout
      title="A/Bテスト パターン詳細"
      breadcrumbs={[
        { label: "A/Bテスト一覧", path: `/sites/${siteSlug}/abtests` },
        {
          label: "A/Bテスト詳細",
          path: `/sites/${siteSlug}/abtests/${abtestSlug}`,
        },
        {
          label: "A/Bテスト パターン詳細",
          path: `/sites/${siteSlug}/abtests/${abtestSlug}/patterns/new`,
        },
      ]}
    >
      <Box my={4} mx={4}>
        <Flex alignItems="center">
          <Box color={"#282828"} fontWeight={"bold"}>
            {abtestScenarioPage.title}
          </Box>
          <Spacer />
          <HStack spacing={3}>
            <ArchiveButton id={abtestScenarioPage.id} />
            <NavLink
              to={`/sites/${siteSlug}/abtests/${abtestSlug}/patterns/${patternSlug}/edit`}
            >
              <OutlineButton>編集</OutlineButton>
            </NavLink>
          </HStack>
        </Flex>
        <HStack>
          <Box>{`実施グループ: ${abtestScenarioPageGroup?.title}`}</Box>
        </HStack>
        <HStack>
          <Box>{`実施ファネル: ${abtestScenarioPageGroup?.page.funnel.name}`}</Box>
          <URLItem url={abtestScenarioPageGroup?.page.funnel.siteUrl || ""} />
        </HStack>
        <HStack>
          <Box>
            {`実施ページ: ${
              abtestScenarioPageGroup?.page.kind === "FORM"
                ? abtestScenarioPageGroup?.page.name +
                  `(${abtestScenarioPageGroup?.page.sortNumber - 1})`
                : abtestScenarioPageGroup?.page.name
            }`}
          </Box>
          <URLItem url={abtestScenarioPageGroup?.page.url || ""} />
        </HStack>

        <VStack mt={4}>
          <SectionCard>
            {abtestScenarioPage.kind === "SCENARIO" && (
              <ABTestPatternScenarioDetailContainer
                scenarioContents={abtestScenarioPageContents}
              />
            )}
            {abtestScenarioPage.kind === "REDIRECT" && (
              <ABTestPatternRedirectDetailContainer
                linkUrl={abtestScenarioPage.linkUrl}
                isInheritQuery={abtestScenarioPage.isInheritQuery}
                pageUrl={abtestScenarioPage.page.url}
              />
            )}
            {abtestScenarioPage.kind === "TEXT_REPLACE" && (
              <ABTestPatternTextReplaceDetailContainer
                textReplaceContents={abtestScenarioPageContents}
              />
            )}
          </SectionCard>
        </VStack>
        <Box my={4} />
      </Box>
    </PageLayout>
  );
};
