/**
 * @generated SignedSource<<a1f40fbeec36671d60587e649e95f89c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Device = "MOBILE" | "TABLET" | "PC" | "%future added value";
export type DistributionStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type OrderDirection = "ASC" | "DESC" | "%future added value";
export type PageKind = "LANDING" | "FORM" | "CONFIRM" | "THANKS" | "%future added value";
export type PopupGroupOrderField = "CREATED_AT" | "UPDATED_AT" | "NAME" | "FUNNEL_TITLE" | "%future added value";
export type PopupGroupOrder = {
  direction: OrderDirection;
  field: PopupGroupOrderField;
};
export type PopupListPage_Query$variables = {
  count?: number | null;
  cursor?: string | null;
  device?: Device | null;
  funnelIds?: ReadonlyArray<string> | null;
  order?: PopupGroupOrder | null;
  pageIds?: ReadonlyArray<string> | null;
  pageKind?: PageKind | null;
  searchTerm?: string | null;
  sourceId?: string | null;
  status?: DistributionStatus | null;
  withArchived?: boolean | null;
  id: string;
};
export type PopupListPage_QueryVariables = PopupListPage_Query$variables;
export type PopupListPage_Query$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"PopupListPage_site">;
  } | null;
};
export type PopupListPage_QueryResponse = PopupListPage_Query$data;
export type PopupListPage_Query = {
  variables: PopupListPage_QueryVariables;
  response: PopupListPage_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": 120,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "device"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "funnelIds"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v5 = {
  "defaultValue": {
    "direction": "DESC",
    "field": "NAME"
  },
  "kind": "LocalArgument",
  "name": "order"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageIds"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageKind"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchTerm"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sourceId"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "withArchived"
},
v12 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v13 = {
  "kind": "Variable",
  "name": "device",
  "variableName": "device"
},
v14 = {
  "kind": "Variable",
  "name": "funnelIds",
  "variableName": "funnelIds"
},
v15 = {
  "kind": "Variable",
  "name": "pageIds",
  "variableName": "pageIds"
},
v16 = {
  "kind": "Variable",
  "name": "pageKind",
  "variableName": "pageKind"
},
v17 = {
  "kind": "Variable",
  "name": "searchTerm",
  "variableName": "searchTerm"
},
v18 = {
  "kind": "Variable",
  "name": "sourceId",
  "variableName": "sourceId"
},
v19 = {
  "kind": "Variable",
  "name": "status",
  "variableName": "status"
},
v20 = {
  "kind": "Variable",
  "name": "withArchived",
  "variableName": "withArchived"
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v24 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v13/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v14/*: any*/),
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "order"
  },
  (v15/*: any*/),
  (v16/*: any*/),
  (v17/*: any*/),
  (v18/*: any*/),
  (v19/*: any*/),
  (v20/*: any*/)
],
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PopupListPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v12/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              (v13/*: any*/),
              (v14/*: any*/),
              {
                "kind": "Variable",
                "name": "order",
                "variableName": "order"
              },
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "PopupListPage_site"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "PopupListPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v12/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v21/*: any*/),
          (v22/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v23/*: any*/),
              {
                "alias": null,
                "args": (v24/*: any*/),
                "concreteType": "PopupGroupConnection",
                "kind": "LinkedField",
                "name": "popupGroups",
                "plural": false,
                "selections": [
                  (v25/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PopupGroupEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PopupGroup",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v23/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endDate",
                            "storageKey": null
                          },
                          (v22/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "memo",
                            "storageKey": null
                          },
                          (v26/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Page",
                            "kind": "LinkedField",
                            "name": "page",
                            "plural": false,
                            "selections": [
                              (v22/*: any*/),
                              (v23/*: any*/),
                              (v26/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "kind",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "sortNumber",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Funnel",
                                "kind": "LinkedField",
                                "name": "funnel",
                                "plural": false,
                                "selections": [
                                  (v22/*: any*/),
                                  (v26/*: any*/),
                                  (v23/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "siteUrl",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isArchive",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "kind",
                                "value": "B_PATTERN"
                              }
                            ],
                            "concreteType": "PopupConnection",
                            "kind": "LinkedField",
                            "name": "popups",
                            "plural": false,
                            "selections": [
                              (v25/*: any*/)
                            ],
                            "storageKey": "popups(kind:\"B_PATTERN\")"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startDate",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "thumbnailImageUrl",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "updatedAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "judgeStatus",
                            "storageKey": null
                          },
                          (v21/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v24/*: any*/),
                "filters": [
                  "searchTerm",
                  "pageIds",
                  "funnelIds",
                  "sourceId",
                  "device",
                  "withArchived",
                  "orderBy",
                  "status",
                  "pageKind"
                ],
                "handle": "connection",
                "key": "PopupListPage_popupGroups",
                "kind": "LinkedHandle",
                "name": "popupGroups"
              }
            ],
            "type": "Site",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ad6a8a6c48d311c8a6c27c0ca3efa3c2",
    "id": null,
    "metadata": {},
    "name": "PopupListPage_Query",
    "operationKind": "query",
    "text": "query PopupListPage_Query(\n  $count: Int = 120\n  $cursor: String\n  $device: Device\n  $funnelIds: [ID!]\n  $order: PopupGroupOrder = {field: NAME, direction: DESC}\n  $pageIds: [ID!]\n  $pageKind: PageKind\n  $searchTerm: String\n  $sourceId: ID\n  $status: DistributionStatus\n  $withArchived: Boolean\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...PopupListPage_site_3rVcRB\n    id\n  }\n}\n\nfragment PopupListPage_site_3rVcRB on Site {\n  slug\n  popupGroups(first: $count, after: $cursor, searchTerm: $searchTerm, pageIds: $pageIds, funnelIds: $funnelIds, sourceId: $sourceId, device: $device, withArchived: $withArchived, orderBy: $order, status: $status, pageKind: $pageKind) {\n    totalCount\n    edges {\n      node {\n        slug\n        ...PopupTableDataRow_popupGroup\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n\nfragment PopupTableDataRow_popupGroup on PopupGroup {\n  endDate\n  id\n  memo\n  name\n  page {\n    id\n    slug\n    name\n    kind\n    sortNumber\n    url\n    funnel {\n      id\n      name\n      slug\n      siteUrl\n    }\n  }\n  isArchive\n  popups(kind: B_PATTERN) {\n    totalCount\n  }\n  slug\n  status\n  startDate\n  thumbnailImageUrl\n  updatedAt\n  judgeStatus\n}\n"
  }
};
})();

(node as any).hash = "ceadd4dd00e82cdfce5e4f989cf6a23e";

export default node;
