/**
 * @generated SignedSource<<f93235713c0c88d0d876f3aad2e01b8e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AbtestScenarioPageContentTextReplaceActionKind = "TEXT_REPLACEMENT" | "DIV_ELEMENT_ADDITION" | "ATTRIBUTE_REPLACEMENT" | "%future added value";
export type AbtestScenarioPageContentTextReplaceTargetKind = "ID" | "SELECTOR" | "XPATH" | "%future added value";
export type AbtestScenarioPageKind = "DEFAULT" | "SCENARIO" | "REDIRECT" | "TEXT_REPLACE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ABTestPatternEditScreenContainer_abtestScenarioPage$data = {
  readonly id: string;
  readonly title: string | null;
  readonly slug: string;
  readonly kind: AbtestScenarioPageKind;
  readonly weight: number | null;
  readonly linkUrl: string | null;
  readonly isInheritQuery: boolean;
  readonly page: {
    readonly id: string;
  };
  readonly abtestScenarioPageContents: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id?: string;
        readonly imageUrl?: string | null;
        readonly beforeSrc?: string | null;
        readonly targetType?: AbtestScenarioPageContentTextReplaceTargetKind;
        readonly actionType?: AbtestScenarioPageContentTextReplaceActionKind;
        readonly targetValue?: string;
        readonly targetAttribute?: string;
        readonly replaceValue?: string | null;
      } | null;
    } | null> | null;
  };
  readonly " $fragmentType": "ABTestPatternEditScreenContainer_abtestScenarioPage";
};
export type ABTestPatternEditScreenContainer_abtestScenarioPage = ABTestPatternEditScreenContainer_abtestScenarioPage$data;
export type ABTestPatternEditScreenContainer_abtestScenarioPage$key = {
  readonly " $data"?: ABTestPatternEditScreenContainer_abtestScenarioPage$data;
  readonly " $fragmentSpreads": FragmentRefs<"ABTestPatternEditScreenContainer_abtestScenarioPage">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ABTestPatternEditScreenContainer_abtestScenarioPage",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "linkUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isInheritQuery",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Page",
      "kind": "LinkedField",
      "name": "page",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AbtestScenarioPageContentConnection",
      "kind": "LinkedField",
      "name": "abtestScenarioPageContents",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AbtestScenarioPageContentEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "imageUrl",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "beforeSrc",
                      "storageKey": null
                    }
                  ],
                  "type": "AbtestScenarioPageContentScenario",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v1/*: any*/),
                  "type": "AbtestScenarioPageContentRedirect",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "targetType",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "actionType",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "targetValue",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "targetAttribute",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "replaceValue",
                      "storageKey": null
                    }
                  ],
                  "type": "AbtestScenarioPageContentTextReplace",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AbtestScenarioPage",
  "abstractKey": null
};
})();

(node as any).hash = "eec9d0c41d9d456172e7ff1b50ddd41f";

export default node;
