import { Box, Flex, HStack, Select, VStack } from "@chakra-ui/react";
import {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  Suspense,
  useCallback,
  useMemo,
  useState,
} from "react";
import { graphql, useFragment } from "react-relay";
import { NavLink } from "react-router-dom";

import { UserRole } from "~/src/__generated__/schema";
import { FunnelDetailPage_funnel$key } from "~/src/__relay_artifacts__/FunnelDetailPage_funnel.graphql";
import { SolidButton } from "~/src/components/common/Button";
import { PageLoading } from "~/src/components/common/PageLoading";
import { URLItem } from "~/src/components/common/URLItem";
import { PageLayout } from "~/src/components/features/global/PageLayout";

import { FunnelABTestGroupList } from "./FunnelABTestGroupList";
import { FunnelPopupGroupList } from "./FunnelPopupGroupList";

const fragment = graphql`
  fragment FunnelDetailPage_funnel on Funnel {
    id
    name
    slug
    siteUrl
    landingPage {
      id
      name
      url
      slug
    }
    formPage {
      id
      name
      url
      sortNumber
      slug
    }
    confirmPage {
      id
      name
      url
      slug
    }
    thanksPage {
      id
      name
      url
      slug
    }
  }
`;

type Props = {
  siteSlug: string;
  funnelRef: FunnelDetailPage_funnel$key;
  role: UserRole;
};

export const FunnelDetailPage: FC<Props> = ({ siteSlug, funnelRef, role }) => {
  const [page, setPage] = useState<string | null>(null);
  const [pageUrl, setPageUrl] = useState<string | null>(null);

  const funnel = useFragment(fragment, funnelRef);

  const pageOptions = useMemo(() => {
    const options = [];
    if (funnel.landingPage) {
      options.push({
        label: funnel.landingPage.name,
        value: funnel.landingPage.id,
        url: funnel.landingPage.url,
      });
    }
    if (funnel.formPage) {
      funnel.formPage.forEach((fp) =>
        options.push({
          label: fp.name + (fp.sortNumber - 1).toString(),
          value: fp.id,
          url: fp.url,
        })
      );
    }
    if (funnel.confirmPage) {
      options.push({
        label: funnel.confirmPage.name,
        value: funnel.confirmPage.id,
        url: funnel.confirmPage.url,
      });
    }
    if (funnel.thanksPage) {
      options.push({
        label: funnel.thanksPage.name,
        value: funnel.thanksPage.id,
        url: funnel.thanksPage.url,
      });
    }
    return options;
  }, [
    funnel.landingPage,
    funnel.formPage,
    funnel.confirmPage,
    funnel.thanksPage,
  ]);

  const handleSelect = useCallback<ChangeEventHandler<HTMLSelectElement>>(
    (e: ChangeEvent<HTMLSelectElement>) => {
      setPage(e.target.value);
      const selectedPage = pageOptions.find(
        (option) => option.value === e.target.value
      );
      if (selectedPage) {
        setPageUrl(selectedPage.url);
        return;
      }
      setPageUrl(null);
    },
    [setPage, pageOptions, setPageUrl]
  );

  return (
    <PageLayout title="ファネル">
      <Box my="16px">
        <HStack mb={2}>
          <Box>{`実施ファネル: ${funnel.name}`}</Box>
          <URLItem url={funnel.siteUrl} />
        </HStack>

        {(role === "ADMIN" || role === "SUPER") && (
          <VStack alignItems={"flex-start"} mb={6}>
            <Box>本番動作確認用ID</Box>
            <Box>{`ファネル : ${funnel.slug}`}</Box>
            {funnel.landingPage && (
              <Box>{`ランディングページ : ${funnel.landingPage.slug}`}</Box>
            )}
            {funnel.formPage &&
              funnel.formPage.length > 0 &&
              funnel.formPage.map((formPage, index) => (
                <Box key={index}>{`フォームページ(${
                  formPage.sortNumber - 1
                }) : ${formPage.slug}`}</Box>
              ))}
            {funnel.confirmPage && (
              <Box>{`確認ページ : ${funnel.confirmPage.slug}`}</Box>
            )}
            {funnel.thanksPage && (
              <Box>{`サンクスページ : ${funnel.thanksPage.slug}`}</Box>
            )}
          </VStack>
        )}

        <Flex alignItems={"flex-start"}>
          <Select
            width={"md"}
            placeholder="ページの選択(すべてのページ)"
            onChange={handleSelect}
          >
            {pageOptions.map((pageOption) => (
              <option value={pageOption.value} key={pageOption.value}>
                {pageOption.label}
              </option>
            ))}
          </Select>
        </Flex>

        {pageUrl && (
          <HStack mt={6}>
            <Box>{`実施ページ`}</Box>
            <URLItem url={pageUrl} />
          </HStack>
        )}

        <Suspense fallback={<PageLoading />}>
          <Box mt={10}>ポップアップ一覧</Box>
          <HStack mb={6} justifyContent="flex-end">
            <NavLink
              to={`/sites/${siteSlug}/popup_groups/new?funnel=${funnel.id}`}
            >
              <SolidButton>登録</SolidButton>
            </NavLink>
          </HStack>
          <FunnelPopupGroupList
            siteSlug={siteSlug}
            funnelId={funnel.id}
            pageId={page}
          />
        </Suspense>
        <Suspense fallback={<PageLoading />}>
          <Box mt={10}>ABテスト一覧</Box>
          <HStack mb={6} justifyContent="flex-end">
            <NavLink to={`/sites/${siteSlug}/abtests/new?funnel=${funnel.id}`}>
              <SolidButton>登録</SolidButton>
            </NavLink>
          </HStack>
          <FunnelABTestGroupList
            siteSlug={siteSlug}
            funnelId={funnel.id}
            pageId={page}
          />
        </Suspense>
      </Box>
    </PageLayout>
  );
};
