import { FC } from "react";

import { FunnelEditFormContainer } from "~/src/components/features/settings/FunnelForms";
import { useFunnelEditPageParams } from "~/src/routes/hooks";

import { FunnelEditPage } from "./presentations";

export const FunnelEditPageContainer: FC = () => {
  const { siteSlug, funnelSlug } = useFunnelEditPageParams();

  return (
    <FunnelEditPage siteSlug={siteSlug} funnelSlug={funnelSlug}>
      <FunnelEditFormContainer siteSlug={siteSlug} funnelSlug={funnelSlug} />
    </FunnelEditPage>
  );
};
