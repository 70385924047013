import { Text } from "@chakra-ui/react";
import { SearchOutlined as SearchOutlinedIcon } from "@mui/icons-material";
import { useFormikContext } from "formik";
import { ChangeEventHandler, FC, Suspense, useCallback, useState } from "react";

import { FormControl } from "~/src/components/common/forms/FormControl";
import { PageLoading } from "~/src/components/common/PageLoading";
import { TextField } from "~/src/components/common/TextField";

import { SearchedUserList } from "./SearchedUserList";
import { SiteCreateFormValues as FormValues } from "./validationSchema";

export const SearchUserFormField: FC = () => {
  const [searchText, setSearchText] = useState("");

  const { setFieldValue } = useFormikContext<FormValues>();

  const handleSearchTextChange = useCallback<
    ChangeEventHandler<HTMLInputElement>
  >((e) => {
    setSearchText(e.target.value);
  }, []);

  const handleUserSelectChange = useCallback(
    (selectedId: string, selectedName: string) => {
      setSearchText(selectedName);
      setFieldValue("userId", selectedId);
    },
    [setFieldValue]
  );

  return (
    <>
      <FormControl required label="ユーザー">
        <Text fontSize={"13px"} fontWeight={400}>
          {searchText}
        </Text>
      </FormControl>
      <FormControl label="">
        <TextField
          type="text"
          mode={"outline"}
          name="userId"
          value={searchText}
          placeholder={"検索"}
          icon={<SearchOutlinedIcon />}
          onChange={handleSearchTextChange}
        />
        <Suspense fallback={<PageLoading />}>
          <SearchedUserList
            searchText={searchText}
            handleSelectUser={handleUserSelectChange}
          />
        </Suspense>
      </FormControl>
    </>
  );
};
