import { useToast } from "@chakra-ui/toast";
import { FC, useCallback } from "react";
import { graphql } from "react-relay";

import {
  AccountSwitch_Mutation,
  UpdateUserInput,
} from "~/src/__relay_artifacts__/AccountSwitch_Mutation.graphql";
import { Switch } from "~/src/components/common/Switch";
import { useMutationCommit } from "~/src/lib/react-relay";

export type Props = {
  isChecked: boolean;
  input: Pick<
    UpdateUserInput,
    | "isHoneycomb"
    | "isSecure"
    | "isMacbeeServer"
    | "isCrossDomainQuery"
    | "isCrossDomainFragment"
  >;
};

const mutation = graphql`
  mutation AccountSwitch_Mutation($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        name
        isHoneycomb
        isSecure
        isMacbeeServer
        isCrossDomainQuery
        isCrossDomainFragment
      }
    }
  }
`;

export const AccountSwitch: FC<Props> = ({ isChecked, input }) => {
  const toast = useToast();
  const mutate = useMutationCommit<AccountSwitch_Mutation>(mutation);

  const handleChange = useCallback(async () => {
    try {
      const res = await mutate({ variables: { input } });
      const user = res.updateUser?.user;
      if (!user) throw new Error("assertion failed");

      toast({
        title: `${user.name}を更新しました`,
        status: "success",
      });
    } catch (err) {
      toast({ title: "更新に失敗しました", status: "error" });
    }
  }, [input, mutate, toast]);

  return <Switch isChecked={isChecked} onChange={handleChange} />;
};
