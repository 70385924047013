/**
 * @generated SignedSource<<b5a794c24900304aa1378b4809b03df8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteChatbotGroupInput = {
  clientMutationId?: string | null;
  chatbotGroupId: string;
};
export type ChatbotTableActions_DeleteMutation$variables = {
  connections: ReadonlyArray<string>;
  input: DeleteChatbotGroupInput;
};
export type ChatbotTableActions_DeleteMutationVariables = ChatbotTableActions_DeleteMutation$variables;
export type ChatbotTableActions_DeleteMutation$data = {
  readonly deleteChatbotGroup: {
    readonly deletedChatbotGroupId: string;
  } | null;
};
export type ChatbotTableActions_DeleteMutationResponse = ChatbotTableActions_DeleteMutation$data;
export type ChatbotTableActions_DeleteMutation = {
  variables: ChatbotTableActions_DeleteMutationVariables;
  response: ChatbotTableActions_DeleteMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedChatbotGroupId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChatbotTableActions_DeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteChatbotGroupPayload",
        "kind": "LinkedField",
        "name": "deleteChatbotGroup",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChatbotTableActions_DeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteChatbotGroupPayload",
        "kind": "LinkedField",
        "name": "deleteChatbotGroup",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "deleteEdge",
            "key": "",
            "kind": "ScalarHandle",
            "name": "deletedChatbotGroupId",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0b597b54efa02059a732a017f73613ff",
    "id": null,
    "metadata": {},
    "name": "ChatbotTableActions_DeleteMutation",
    "operationKind": "mutation",
    "text": "mutation ChatbotTableActions_DeleteMutation(\n  $input: DeleteChatbotGroupInput!\n) {\n  deleteChatbotGroup(input: $input) {\n    deletedChatbotGroupId\n  }\n}\n"
  }
};
})();

(node as any).hash = "6efc2e575c2850df9fc9dff2588eca32";

export default node;
