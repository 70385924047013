/**
 * @generated SignedSource<<ddc821ce8ebf2e8474aee66b2983fa88>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MonthlyReportGraph_site$data = {
  readonly reportMonthly: any;
  readonly " $fragmentType": "MonthlyReportGraph_site";
};
export type MonthlyReportGraph_site = MonthlyReportGraph_site$data;
export type MonthlyReportGraph_site$key = {
  readonly " $data"?: MonthlyReportGraph_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"MonthlyReportGraph_site">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "input"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "MonthlyReportGraph_site",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "input",
          "variableName": "input"
        }
      ],
      "kind": "ScalarField",
      "name": "reportMonthly",
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "cd80ae2b2a4d701c2a1d89573a0a4c0b";

export default node;
