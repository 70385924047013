/**
 * @generated SignedSource<<28f63a11541040d57a785d96f50c1226>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DistributionStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type PageKind = "LANDING" | "FORM" | "CONFIRM" | "THANKS" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PopupGroupBulkPageEditForm_popupGroupConnection$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly id: string;
      readonly slug: string;
      readonly name: string;
      readonly status: DistributionStatus;
      readonly startDate: string | null;
      readonly endDate: string | null;
      readonly thumbnailImageUrl: string | null;
      readonly page: {
        readonly id: string;
        readonly kind: PageKind;
        readonly name: string;
        readonly funnel: {
          readonly id: string;
          readonly name: string;
        };
      };
    } | null;
  } | null> | null;
  readonly " $fragmentType": "PopupGroupBulkPageEditForm_popupGroupConnection";
};
export type PopupGroupBulkPageEditForm_popupGroupConnection = PopupGroupBulkPageEditForm_popupGroupConnection$data;
export type PopupGroupBulkPageEditForm_popupGroupConnection$key = {
  readonly " $data"?: PopupGroupBulkPageEditForm_popupGroupConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"PopupGroupBulkPageEditForm_popupGroupConnection">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PopupGroupBulkPageEditForm_popupGroupConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PopupGroupEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PopupGroup",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "slug",
              "storageKey": null
            },
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "thumbnailImageUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Page",
              "kind": "LinkedField",
              "name": "page",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "kind",
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Funnel",
                  "kind": "LinkedField",
                  "name": "funnel",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PopupGroupConnection",
  "abstractKey": null
};
})();

(node as any).hash = "a59df5d4a1d3b925d6e8184a700b9358";

export default node;
