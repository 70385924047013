import { Box } from "@chakra-ui/layout";
import { NotificationsOutlined as NotificationsOutlinedIcon } from "@mui/icons-material";
import { FC } from "react";

export type Props = {
  count: number;
};

export const NotificationWithBadgeIcon: FC<Props> = ({ count }) => {
  return (
    <>
      <NotificationsOutlinedIcon />
      {count > 0 && (
        <Box
          as={"span"}
          color={"white"}
          position={"absolute"}
          top={"-6px"}
          right={"-6px"}
          fontSize={"10px"}
          bgColor={"#D9534F"}
          borderRadius={"12px"}
          zIndex={9999}
          p={"1px 3px"}
        >
          {`+${count}`}
        </Box>
      )}
    </>
  );
};
