import { Box, HStack } from "@chakra-ui/layout";
import { FC } from "react";

import { FormControl } from "~/src/components/common/forms/FormControl";
import { ImageUploadField } from "~/src/components/features/uploads";

type Props = {
  imageUrl: string | null;
};

export const PopupAgainImageForm: FC<Props> = ({ imageUrl }) => {
  return (
    <FormControl label={"再表示ボタン用画像"}>
      <HStack justifyContent={"space-between"}>
        <Box>
          <ImageUploadField
            src={imageUrl || "未設定"}
            name={`displayPopupAgainImage.image`}
          />
        </Box>
      </HStack>
    </FormControl>
  );
};
