import { HStack, Table } from "@chakra-ui/react";
import { RestartAlt } from "@mui/icons-material";
import { Form, useFormikContext } from "formik";
import { FC, useCallback } from "react";

import { GraphQLEnums } from "~/src/__generated__/GraphQLEnums";
import { OutlineButton } from "~/src/components/common/Button";
import { FormikFormButtons } from "~/src/components/common/forms/FormikFormButtons";
import { Select } from "~/src/components/common/Select";
import {
  TableHeaderColumn,
  TableHeaderRow,
} from "~/src/components/common/tables/Table";
import { chakraFactory } from "~/src/lib/chakra-ui";

import { PopupGroupTableDataRow } from "./PopupGroupTableDataRow";
import { Popup } from "./types";
import { ArrayFormValues } from "./validationSchema";

export type Props = {
  popupGroupSlugs?: string;
  popups: Popup[];
  handleBack: () => void;
};

const StyledSelect = chakraFactory(Select, {
  baseStyle: {
    width: "177px",
    height: "40px",
    paddingRight: "14px",
  },
});

export const PopupGroupBulkPageEditListForm: FC<Props> = ({
  popupGroupSlugs,
  popups,
  handleBack,
}) => {
  const { resetForm, setFieldValue, values } =
    useFormikContext<ArrayFormValues>();

  const options = GraphQLEnums.TargetPageKind.map((k) => {
    return { label: k.label, value: k.value };
  });

  const handleChange = useCallback(
    (option: typeof options[number]) => {
      values.formValues.forEach((formValue, index) => {
        setFieldValue(`formValues[${index}].pageKind`, option.value);
      });
    },
    [values, setFieldValue]
  );

  return (
    <Form>
      <HStack paddingY={"16px"}>
        <OutlineButton onClick={resetForm}>
          <RestartAlt />
          リセット
        </OutlineButton>
        {popupGroupSlugs && (
          <StyledSelect
            onChange={handleChange}
            options={options}
            placeholder={"一括ページ変更"}
          />
        )}
      </HStack>
      <Table>
        <TableHeaderRow>
          <TableHeaderColumn>ポップアップグループ名</TableHeaderColumn>
          <TableHeaderColumn>クリエイティブ</TableHeaderColumn>
          <TableHeaderColumn>実施ファネル</TableHeaderColumn>
          <TableHeaderColumn>変更後の実施ページ</TableHeaderColumn>
          <TableHeaderColumn>開始日</TableHeaderColumn>
          <TableHeaderColumn>終了日</TableHeaderColumn>
          <TableHeaderColumn>実施ステータス</TableHeaderColumn>
        </TableHeaderRow>
        {popups.map((popup, i) => (
          <PopupGroupTableDataRow key={i} popup={popup} index={i} />
        ))}
      </Table>
      <HStack mt="116px" spacing="8px">
        <FormikFormButtons onCancelClick={handleBack} />
      </HStack>
    </Form>
  );
};
