/**
 * @generated SignedSource<<528b9d2b56515bcfdb7df3338b4cdbbb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GenderReportTable_site$data = {
  readonly reportGender: any;
  readonly " $fragmentType": "GenderReportTable_site";
};
export type GenderReportTable_site = GenderReportTable_site$data;
export type GenderReportTable_site$key = {
  readonly " $data"?: GenderReportTable_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"GenderReportTable_site">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "input"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "GenderReportTable_site",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "input",
          "variableName": "input"
        }
      ],
      "kind": "ScalarField",
      "name": "reportGender",
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "f94e6081425fd1c559b68598fbbc6fd2";

export default node;
