import accounting from "accounting";
import { FC } from "react";

import { chakraFactory } from "~/src/lib/chakra-ui";

export type Props = {
  uu: number;
  cv: number;
  cvr: number;
  popup: number;
  abTest: number;
  chatBot: number;
};

const StyledTable = chakraFactory("table", {
  baseStyle: {
    width: "100%",
    tableLayout: "fixed",
    borderCollapse: "collapse",
    borderSpacing: 0,

    "table, th, td": {
      border: "1px solid #DADADA !important",
    },
  },
});

const StyledThead = chakraFactory("thead", {
  baseStyle: {
    backgroundColor: "#FAFAFA",
  },
});

const StyledTh = chakraFactory("th", {
  baseStyle: {
    fontSize: "10px",
    fontWeight: "normal",
    lineHeight: "12px",
    letterSpacing: "0.04em",
    color: "#282828",
  },
});

const StyledSmallTh = chakraFactory(StyledTh, {
  baseStyle: {
    transform: "scale(0.8)",
    letterSpacing: "-0.09em",
  },
});

const StyledTd = chakraFactory("td", {
  baseStyle: {
    fontSize: "9px",
    lineHeight: "11px",
    letterSpacing: "0.01em",
    color: "#282828",
    px: "2px",
    py: "4px",
    textAlign: "end",
  },
});

export const SiteTable: FC<Props> = ({
  uu,
  cv,
  cvr,
  popup,
  abTest,
  chatBot,
}) => (
  <StyledTable>
    <StyledThead>
      <tr>
        <StyledTh>UU</StyledTh>
        <StyledTh>CV</StyledTh>
        <StyledTh>CVR</StyledTh>
        <StyledSmallTh>ポップアップ</StyledSmallTh>
        <StyledSmallTh>A/Bテスト</StyledSmallTh>
        <StyledSmallTh>チャットボット</StyledSmallTh>
      </tr>
    </StyledThead>
    <tbody>
      <tr>
        <StyledTd>{accounting.formatNumber(uu)}</StyledTd>
        <StyledTd>{accounting.formatNumber(cv)}</StyledTd>
        <StyledTd>{accounting.formatNumber(cvr)}</StyledTd>
        <StyledTd>{accounting.formatNumber(popup)}</StyledTd>
        <StyledTd>{accounting.formatNumber(abTest)}</StyledTd>
        <StyledTd>{accounting.formatNumber(chatBot)}</StyledTd>
      </tr>
    </tbody>
  </StyledTable>
);
