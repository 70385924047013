import { boolean, object, string } from "yup";

export type FormValues = {
  email: string;
  password: string;
  keepLogin: boolean;
};

export const validationSchema = object({
  email: string().email().required(),
  password: string().required(),
  keepLogin: boolean().required(),
});
