import { useToast } from "@chakra-ui/react";
import * as React from "react";
import { graphql } from "react-relay";
import { useNavigate, useParams } from "react-router-dom";

import { ChatbotGroupCopyButton_Mutation as Mutation } from "~/src/__relay_artifacts__/ChatbotGroupCopyButton_Mutation.graphql";
import { OutlineButton } from "~/src/components/common/Button";
import { useMutationCommit } from "~/src/lib/react-relay";

type Props = {
  originalId: string;
};

const mutation = graphql`
  mutation ChatbotGroupCopyButton_Mutation($input: CopyChatbotGroupInput!) {
    copyChatbotGroup(input: $input) {
      chatbotGroup {
        id
        name
        slug
      }
    }
  }
`;

export const ChatbotGroupCopyButton: React.FC<Props> = ({ originalId }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const { siteSlug = "" } = useParams();

  const mutate = useMutationCommit<Mutation>(mutation);

  const handleClick = React.useCallback(async () => {
    try {
      const res = await mutate({
        variables: {
          input: {
            chatbotGroupId: originalId,
          },
        },
      });

      const chatbotGroup = res.copyChatbotGroup?.chatbotGroup;
      if (!chatbotGroup) throw new Error("assertion failed");

      toast({ title: `${chatbotGroup.name}を作成しました`, status: "success" });
      navigate(`/sites/${siteSlug}/chatbots/${chatbotGroup.slug}/edit`);
    } catch (err) {
      toast({ title: "コピーに失敗しました", status: "error" });
    }
  }, [mutate, navigate, originalId, siteSlug, toast]);

  return <OutlineButton onClick={handleClick}>複製</OutlineButton>;
};
