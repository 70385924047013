import { ListItem } from "@chakra-ui/react";
import { FC, useCallback } from "react";

import { GhostButton } from "~/src/components/common/Button";

export type Props = {
  slug: string;
  name: string;
  handleSelectSite: (selectedSlug: string) => void;
};

export const SearchedSiteListItem: FC<Props> = ({
  slug,
  name,
  handleSelectSite,
}) => {
  const handleClick = useCallback(() => {
    handleSelectSite(slug);
  }, [slug, handleSelectSite]);

  return (
    <ListItem>
      <GhostButton onClick={handleClick}>{name}</GhostButton>
    </ListItem>
  );
};
