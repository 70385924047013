/**
 * @generated SignedSource<<c0345978ceb75dc5a909cbe2608fbaac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PopupGroupBulkEditFormContainer_Query$variables = {
  siteSlug: string;
};
export type PopupGroupBulkEditFormContainer_QueryVariables = PopupGroupBulkEditFormContainer_Query$variables;
export type PopupGroupBulkEditFormContainer_Query$data = {
  readonly site: {
    readonly id: string;
    readonly name: string;
    readonly slug: string;
    readonly " $fragmentSpreads": FragmentRefs<"PopupGroupForm_site">;
  };
};
export type PopupGroupBulkEditFormContainer_QueryResponse = PopupGroupBulkEditFormContainer_Query$data;
export type PopupGroupBulkEditFormContainer_Query = {
  variables: PopupGroupBulkEditFormContainer_QueryVariables;
  response: PopupGroupBulkEditFormContainer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "siteSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "siteSlug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v5 = [
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "kind",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "sortNumber",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PopupGroupBulkEditFormContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PopupGroupForm_site"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PopupGroupBulkEditFormContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FunnelConnection",
            "kind": "LinkedField",
            "name": "funnels",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FunnelEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Funnel",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Page",
                        "kind": "LinkedField",
                        "name": "confirmPage",
                        "plural": false,
                        "selections": (v5/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Page",
                        "kind": "LinkedField",
                        "name": "landingPage",
                        "plural": false,
                        "selections": (v5/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Page",
                        "kind": "LinkedField",
                        "name": "formPage",
                        "plural": true,
                        "selections": (v5/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Page",
                        "kind": "LinkedField",
                        "name": "thanksPage",
                        "plural": false,
                        "selections": (v5/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d8deb9c629f7ed624049339b33b00430",
    "id": null,
    "metadata": {},
    "name": "PopupGroupBulkEditFormContainer_Query",
    "operationKind": "query",
    "text": "query PopupGroupBulkEditFormContainer_Query(\n  $siteSlug: String!\n) {\n  site(slug: $siteSlug) {\n    id\n    name\n    slug\n    ...PopupGroupForm_site\n  }\n}\n\nfragment PopupGroupForm_site on Site {\n  funnels {\n    edges {\n      node {\n        id\n        name\n        confirmPage {\n          id\n          name\n          kind\n          url\n          sortNumber\n        }\n        landingPage {\n          id\n          name\n          kind\n          url\n          sortNumber\n        }\n        formPage {\n          id\n          name\n          kind\n          url\n          sortNumber\n        }\n        thanksPage {\n          id\n          name\n          kind\n          url\n          sortNumber\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9e44f6bdc90994706fa1ebe401e27b42";

export default node;
