import { Box, Flex, HStack, Spacer } from "@chakra-ui/react";
import { includes } from "ramda";
import { FC, useCallback, useMemo, useState } from "react";
import { graphql, useFragment, usePaginationFragment } from "react-relay";
import { NavLink } from "react-router-dom";

import { FunnelOrder } from "~/src/__generated__/schema";
import { FunnelListTab_site$key } from "~/src/__relay_artifacts__/FunnelListTab_site.graphql";
import { FunnelListTab_user$key } from "~/src/__relay_artifacts__/FunnelListTab_user.graphql";
import { OutlineButton, SolidButton } from "~/src/components/common/Button";
import {
  Table,
  TableHeaderCheckboxColumn,
  TableHeaderColumn,
  TableHeaderRow,
} from "~/src/components/common/tables/Table";
import {
  TablePagination,
  useTablePaginationProps,
} from "~/src/components/common/tables/TablePagination";
import { TableSearchField } from "~/src/components/common/tables/TableSearchField";
import { useBulkCheckboxes } from "~/src/lib/hooks";
import { useUpdateDebounce } from "~/src/lib/react-use";

import { FunnelBulkArchiveButton } from "./FunnelBulkArchiveButton";
import { FormValues, FunnelFilterButton } from "./FunnelFilterButton";
import {
  FunnelTableDataRow,
  Props as FunnelTableDataRowProps,
} from "./FunnelTableDataRow";

export type Props = {
  siteRef: FunnelListTab_site$key;
  userRef: FunnelListTab_user$key;
  sortBy?: "title" | "landingUrl" | "formUrl" | "confirmUrl" | "thanksUrl";
  onFunnelDeleteClick: FunnelTableDataRowProps["onDeleteClick"];
};

const siteFragment = graphql`
  fragment FunnelListTab_site on Site
  @refetchable(queryName: "FunnelListTab_Query")
  @argumentDefinitions(
    searchText: { type: "String", defaultValue: "" }
    count: { type: "Int", defaultValue: 120 }
    cursor: { type: "String" }
    withArchived: { type: "Boolean" }
    onlyExperiment: { type: "Boolean" }
    orderBy: { type: "FunnelOrder" }
  ) {
    id
    name
    slug
    funnels(
      first: $count
      after: $cursor
      searchText: $searchText
      withArchived: $withArchived
      onlyExperiment: $onlyExperiment
      orderBy: $orderBy
    ) @connection(key: "FunnelListTab_funnels") {
      __id
      totalCount
      edges {
        node {
          slug
          id
          name
          ...FunnelTableDataRow_funnel
        }
      }
    }
  }
`;

const userFragment = graphql`
  fragment FunnelListTab_user on User {
    role
  }
`;

export const FunnelListTab: FC<Props> = ({
  siteRef,
  onFunnelDeleteClick,
  userRef,
}) => {
  const [searchText, setSearchText] = useState("");
  const [filterValues, setFilterValues] = useState<FormValues>({
    withArchived: null,
    onlyExperiment: null,
  });
  const [sort, setSort] = useState<FunnelOrder>({
    field: "NAME",
    direction: "ASC",
  });
  const user = useFragment(userFragment, userRef);
  const {
    data: site,
    loadNext,
    hasNext,
    refetch,
  } = usePaginationFragment(siteFragment, siteRef);
  const { tablePaginationProps } = useTablePaginationProps({
    totalCount: site.funnels.totalCount,
    hasNext,
    loadNext,
    refetch,
  });

  const funnels = useMemo(() => {
    const edges = site.funnels.edges || [];
    const from = tablePaginationProps.from;
    const to = tablePaginationProps.to;
    return edges.slice(from, to).map((edge) => {
      const node = edge?.node;
      if (!node) throw new Error("assertion error");
      return node;
    });
  }, [site.funnels.edges, tablePaginationProps.from, tablePaginationProps.to]);

  const {
    selectedSlugs,
    isAllChecked,
    handleAllCheckboxClick,
    handleCheckboxClick,
  } = useBulkCheckboxes(funnels);

  const handleSortClick = useCallback((params) => setSort(params), []);

  const selectedFunnels = useMemo(() => {
    const filteredFunnels = funnels.filter((funnel) =>
      includes(funnel.slug, selectedSlugs)
    );
    return filteredFunnels.map((funnel) => {
      return {
        id: funnel.id,
        slug: funnel.slug,
        name: funnel.name,
      };
    });
  }, [funnels, selectedSlugs]);

  useUpdateDebounce(
    () => {
      refetch({
        searchText,
        withArchived: filterValues.withArchived,
        onlyExperiment: filterValues.onlyExperiment,
        orderBy: { field: sort.field, direction: sort.direction },
      });
    },
    500,
    [
      refetch,
      searchText,
      filterValues.withArchived,
      filterValues.onlyExperiment,
      sort,
    ]
  );

  return (
    <Box my="16px">
      <HStack justifyContent="flex-end" mb="16px" spacing="16px">
        <NavLink to={`/sites/${site.slug}/funnels/bulk`}>
          <OutlineButton>一括管理</OutlineButton>
        </NavLink>
        <NavLink to={`/sites/${site.slug}/funnels/new`}>
          <SolidButton>登録</SolidButton>
        </NavLink>
      </HStack>
      <Flex alignItems={"flex-end"}>
        {(user.role === "ADMIN" || user.role === "SUPER") && (
          <FunnelBulkArchiveButton
            selectedFunnels={selectedFunnels}
            connectionId={site.funnels.__id}
          />
        )}
        <TableSearchField searchText={searchText} onChange={setSearchText} />
        <Spacer />
        {(user.role === "ADMIN" || user.role === "SUPER") && (
          <FunnelFilterButton
            filterValues={filterValues}
            onFilterChange={setFilterValues}
            onSubmit={setFilterValues}
          />
        )}
      </Flex>
      <Table>
        <TableHeaderRow>
          {(user.role === "ADMIN" || user.role === "SUPER") && (
            <TableHeaderCheckboxColumn
              isChecked={isAllChecked}
              onChange={handleAllCheckboxClick}
            />
          )}
          <TableHeaderColumn field={"NAME"} onSortClick={handleSortClick}>
            ファネル名
          </TableHeaderColumn>
          <TableHeaderColumn>ランディングページ</TableHeaderColumn>
          <TableHeaderColumn>入力フォームページ</TableHeaderColumn>
          <TableHeaderColumn>確認ページ</TableHeaderColumn>
          <TableHeaderColumn>サンクスページ</TableHeaderColumn>
          <TableHeaderColumn>アクション</TableHeaderColumn>
        </TableHeaderRow>
        {funnels.map((funnel) => (
          <FunnelTableDataRow
            key={funnel.slug}
            funnelRef={funnel}
            connectionId={site.funnels.__id}
            siteSlug={site.slug}
            onDeleteClick={onFunnelDeleteClick}
            userRole={user.role}
            isChecked={selectedSlugs.includes(funnel.slug)}
            onCheck={handleCheckboxClick}
          />
        ))}
      </Table>
      <TablePagination {...tablePaginationProps} />
    </Box>
  );
};
