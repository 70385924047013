import { AccessTimeOutlined } from "@mui/icons-material";
import MUITimePicker, {
  TimePickerProps as MUITimePickerProps,
} from "@mui/lab/TimePicker";
import { Box, ClickAwayListener } from "@mui/material";
import * as React from "react";

import { DatePickerInput } from "~/src/components/common/datepicker/DatePicker";

import { MUIPickerProvider } from "../MUIPickerProvider";

export type Props = {
  value: Date | null;
  error?: string;
  disabled?: boolean;
  placeholder?: string;
  onChange: MUITimePickerProps<Date>["onChange"];
};

export const TimePicker: React.FC<Props> = ({
  value,
  disabled = false,
  error,
  placeholder,
  onChange,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpen = React.useMemo(() => Boolean(anchorEl), [anchorEl]);

  const handleOpen = React.useCallback<React.MouseEventHandler<HTMLElement>>(
    (event) => {
      if (!isOpen && !disabled) setAnchorEl(event.currentTarget);
    },
    [disabled, isOpen]
  );

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleRender = React.useCallback<
    MUITimePickerProps<Date>["renderInput"]
  >(
    ({ inputRef, inputProps, InputProps }) => (
      <Box onClick={handleOpen}>
        <DatePickerInput
          inputRef={inputRef}
          inputProps={{ ...inputProps, placeholder }}
          InputProps={{ ...InputProps, disabled }}
          icon={<AccessTimeOutlined />}
          error={!!error}
          errorMessage={error}
        />
      </Box>
    ),
    [disabled, error, handleOpen, placeholder]
  );
  return (
    <MUIPickerProvider>
      <ClickAwayListener onClickAway={handleClose}>
        <Box>
          <MUITimePicker
            value={value}
            open={isOpen}
            onChange={onChange}
            onAccept={handleClose}
            renderInput={handleRender}
            views={["hours", "minutes"]}
            mask="__:__"
            clearable
            disabled={disabled}
          />
        </Box>
      </ClickAwayListener>
    </MUIPickerProvider>
  );
};
