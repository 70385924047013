import { format } from "date-fns";
import { FC, useCallback, useMemo, useState } from "react";

import { AreaGraph, Data } from "../../AreaGraph";

const measureOptions = [
  { label: "流入数", value: "uniqueUser" },
  { label: "CV-U", value: "conversionUser" },
  { label: "CVR", value: "cvr" },
  { label: "LP平均滞在時間", value: "averageStayTime" },
  { label: "SP", value: "mobileRate" },
  { label: "TB", value: "tabletRate" },
  { label: "PC", value: "pcRate" },
] as const;

export type Prop = {
  graphData: Record<any, any>;
  graphOptions: { label: string; value: string }[];
};

type FormValues = {
  abtestScenarioPageIds: string[];
  measureKey: string | null;
};

export const AbtestReportGraph: FC<Prop> = ({ graphData, graphOptions }) => {
  const [formValues, setFormValues] = useState<FormValues>({
    abtestScenarioPageIds: ["summary"],
    measureKey: "uniqueUser",
  });

  const dataArray: { data: Data[] }[] = useMemo(() => {
    return formValues.abtestScenarioPageIds.map((id) => {
      return {
        data: !graphData[id]
          ? []
          : graphData[id].map((row: any) => {
              return {
                x: format(new Date(row.accessDate), "yyyy/MM/dd"),
                y: formValues.measureKey
                  ? row[formValues.measureKey]
                  : row["uniqueUser"],
              };
            }),
      };
    });
  }, [graphData, formValues]);

  const handleSubmit = useCallback(({ abtestScenarioPageIds, measureKey }) => {
    setFormValues({ abtestScenarioPageIds, measureKey });
  }, []);

  const graphLabel = useMemo(() => {
    return (
      measureOptions.find((option) => option.value === formValues.measureKey)
        ?.label || measureOptions[0].label
    );
  }, [formValues.measureKey]);

  return (
    <AreaGraph
      title={`日別推移｜${graphLabel}`}
      label={`${graphLabel}`}
      dataArray={dataArray}
      graphCustomizedButtonProps={{
        selectFields: [
          {
            name: "abtestScenarioPageIds",
            label: "A/Bテスト名",
            options: graphOptions,
            isMultiple: true,
          },
          {
            name: "measureKey",
            label: "項目",
            options: measureOptions,
            isMultiple: false,
          },
        ],
        formValues,
        onSubmit: handleSubmit,
      }}
    />
  );
};
