import { useField } from "formik";
import { Dispatch, FC, SetStateAction, useCallback, useMemo } from "react";

import {
  MultipleSelect,
  MultipleSelectProps,
} from "~/src/components/common/Select";

export type Props = {
  name: string;
  onSetStateAction?: Dispatch<SetStateAction<any>>;
} & MultipleSelectProps;

type Option = {
  label: string;
  value: string;
};

export const FormikMultipleSelectField: FC<Props> = ({
  name,
  options,
  ...props
}) => {
  const [{ value, onBlur }, { error, touched }, { setValue }] =
    useField<string[]>(name);

  const { onSetStateAction } = props;

  const calculatedValue = useMemo(() => {
    if (!options) return undefined;
    return options.filter((option) => value.includes(option.value));
  }, [options, value]);

  const handleChange = useCallback(
    (options: Option[]) => {
      if (onSetStateAction) {
        onSetStateAction(options.map((option) => option.value));
      }
      setValue(options.map((option) => option.value));
    },
    [setValue, onSetStateAction]
  );

  return (
    <MultipleSelect
      {...props}
      name={name}
      value={calculatedValue}
      options={options}
      error={touched ? error : undefined}
      onBlur={onBlur}
      // @ts-expect-error
      onChange={handleChange}
    />
  );
};
