/**
 * @generated SignedSource<<5cf3abd0dcb3ad1ab64128f996d4b3a2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReportListContainer_query$data = {
  readonly site: {
    readonly " $fragmentSpreads": FragmentRefs<"FunnelTransitionReportSection_site" | "FunnelTableSection_site">;
  };
  readonly " $fragmentType": "ReportListContainer_query";
};
export type ReportListContainer_query = ReportListContainer_query$data;
export type ReportListContainer_query$key = {
  readonly " $data"?: ReportListContainer_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReportListContainer_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "analyzerInput"
    },
    {
      "kind": "RootArgument",
      "name": "slug"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./ReportListContainerRefetchQuery.graphql')
    }
  },
  "name": "ReportListContainer_query",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "slug",
          "variableName": "slug"
        }
      ],
      "concreteType": "Site",
      "kind": "LinkedField",
      "name": "site",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FunnelTransitionReportSection_site"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FunnelTableSection_site"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "1150d12c7f9a88bf5b6c6483f9f079aa";

export default node;
