/**
 * @generated SignedSource<<8f873c723484324d727029557c74d7cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MarkAsReadNotificationInput = {
  clientMutationId?: string | null;
};
export type NotificationListPage_Mutation$variables = {
  input: MarkAsReadNotificationInput;
};
export type NotificationListPage_MutationVariables = NotificationListPage_Mutation$variables;
export type NotificationListPage_Mutation$data = {
  readonly markAsReadNotification: {
    readonly user: {
      readonly seenNotificationsCount: number;
    };
  } | null;
};
export type NotificationListPage_MutationResponse = NotificationListPage_Mutation$data;
export type NotificationListPage_Mutation = {
  variables: NotificationListPage_MutationVariables;
  response: NotificationListPage_Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "seenNotificationsCount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationListPage_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MarkAsReadNotificationPayload",
        "kind": "LinkedField",
        "name": "markAsReadNotification",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NotificationListPage_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MarkAsReadNotificationPayload",
        "kind": "LinkedField",
        "name": "markAsReadNotification",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "98a4ab04e8874fc7620e348d738a7c40",
    "id": null,
    "metadata": {},
    "name": "NotificationListPage_Mutation",
    "operationKind": "mutation",
    "text": "mutation NotificationListPage_Mutation(\n  $input: MarkAsReadNotificationInput!\n) {\n  markAsReadNotification(input: $input) {\n    user {\n      seenNotificationsCount\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "08043e3eb4ce42580f95e93b0628c89d";

export default node;
