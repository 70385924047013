import { Box, Flex, HStack, Spacer } from "@chakra-ui/react";
import { format } from "date-fns";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { NavLink, useParams } from "react-router-dom";

import { GraphQLEnums } from "~/src/__generated__/GraphQLEnums";
import { ChatbotDetailPageContainer_Query } from "~/src/__relay_artifacts__/ChatbotDetailPageContainer_Query.graphql";
import { ChatbotDetailPageContainer_updateDeliverKindMutation } from "~/src/__relay_artifacts__/ChatbotDetailPageContainer_updateDeliverKindMutation.graphql";
import { ChatbotDetailPageContainer_updateDeliverWeightMutation } from "~/src/__relay_artifacts__/ChatbotDetailPageContainer_updateDeliverWeightMutation.graphql";
import { OutlineButton, SolidButton } from "~/src/components/common/Button";
import { Chip } from "~/src/components/common/Chip";
import { DefinitionTeam } from "~/src/components/common/DefinitionTeam";
import { SectionCard } from "~/src/components/common/SectionCard";
import { Switch } from "~/src/components/common/Switch";
import { useTableItemSelect } from "~/src/components/common/tables/TableItemSelect";
import { ArchiveOrUnarchiveButton } from "~/src/components/features/archive/ArchiveOrUnarchiveButton";
import { DateRangeContext } from "~/src/components/features/global/HeaderNavigation/DateRangeField";
import { PageLayout } from "~/src/components/features/global/PageLayout";
import { TableItemSelectButton } from "~/src/components/features/global/TableItemSelectButton";
import { useDateRangeSearchParams } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";
import { delayChunkPromise } from "~/src/lib/utils";

import {
  Chatbot,
  ChatbotDeliverDayOfWeeks,
  ChatbotDeliverWeightButton,
  ChatbotDeliverWeightButtonProps,
  ChatbotDistributionSettingSection,
  ChatbotGroupCopyButton,
  ChatbotPatternFilterButton,
  ChatbotPatternFilterFormValues,
  ChatbotPatternTable,
} from "./presentations";
import {
  allTableItems,
  initialSelectedTableItems,
} from "./presentations/constants";

export type Props = {};

const query = graphql`
  query ChatbotDetailPageContainer_Query(
    $slug: String!
    $analyzerInput: ReportAnalyzerInput!
  ) {
    chatbotGroup(slug: $slug) {
      deliverKind
      id
      name
      slug
      page {
        name
        slug
        funnel {
          name
          slug
        }
      }
      devices
      browsers
      platforms
      ageRanges
      genders
      isArchive
      repeat
      ...ChatbotPatternTable_chatbotGroup
        @arguments(analyzerInput: $analyzerInput)
      ...ChatbotDistributionSettingSection_chatbotGroup
      ...ChatbotDeliverDayOfWeeks_chatbotGroup
    }
  }
`;

const updateDeliverKindMutation = graphql`
  mutation ChatbotDetailPageContainer_updateDeliverKindMutation(
    $input: UpdateDeliverableInput!
  ) {
    updateDeliverable(input: $input) {
      deliverable {
        id
        deliverKind
      }
    }
  }
`;

const updateDeliverWeightMutation = graphql`
  mutation ChatbotDetailPageContainer_updateDeliverWeightMutation(
    $input: UpdateWeightableInput!
  ) {
    updateWeightable(input: $input) {
      weightable {
        id
        weight
      }
    }
  }
`;

export const ChatbotDetailPageContainer: FC<Props> = () => {
  const {
    dateRange: [startOn, endOn],
  } = useContext(DateRangeContext);
  const { attachDateRangeParams } = useDateRangeSearchParams();
  const { siteSlug = "", chatbotSlug = "" } = useParams();
  const [chatbots, setChatbots] = useState<Chatbot[]>([]);
  const [filterValues, setFilterValues] =
    useState<ChatbotPatternFilterFormValues>({
      sourceId: null,
      device: null,
      withArchived: null,
      status: null,
    });
  const { chatbotGroup } = useLazyLoadQuery<ChatbotDetailPageContainer_Query>(
    query,
    {
      slug: chatbotSlug,
      analyzerInput: {
        startOn: format(startOn, "yyyy-MM-dd"),
        endOn: format(endOn, "yyyy-MM-dd"),
      },
    }
  );

  if (!chatbotGroup) throw new Error("assertion failed");

  const { selectedTableItems, headerColumns, dataColumns, onApplyClick } =
    useTableItemSelect({
      cacheKey: "ChatbotDetailPage",
      initialSelectedTableItems,
    });

  const updateDeliverKind =
    useMutationCommit<ChatbotDetailPageContainer_updateDeliverKindMutation>(
      updateDeliverKindMutation
    );
  const updateDeliverWeight =
    useMutationCommit<ChatbotDetailPageContainer_updateDeliverWeightMutation>(
      updateDeliverWeightMutation
    );

  const handleDeliverWeightSubmit = useCallback<
    ChatbotDeliverWeightButtonProps["onSubmit"]
  >(
    async (values) => {
      const mutations: Promise<{}>[] = values.deliverWeights.map(
        (deliverWeight) => {
          return updateDeliverWeight({
            variables: {
              input: {
                weightableId: deliverWeight.id,
                weight: deliverWeight.weight,
              },
            },
          });
        }
      );
      if (chatbotGroup.deliverKind !== values.deliverKind) {
        mutations.push(
          updateDeliverKind({
            variables: {
              input: {
                deliverableId: chatbotGroup.id,
                deliverKind: values.deliverKind,
              },
            },
          })
        );
      }
      await delayChunkPromise(mutations);
    },
    [
      chatbotGroup.deliverKind,
      chatbotGroup.id,
      updateDeliverKind,
      updateDeliverWeight,
    ]
  );

  useEffect(() => {
    attachDateRangeParams();
  }, [attachDateRangeParams]);

  return (
    <PageLayout
      title="チャットボット詳細"
      breadcrumbs={[
        { label: "チャットボット一覧", path: `/sites/${siteSlug}/chatbots` },
        {
          label: "チャットボット詳細",
          path: `/sites/${siteSlug}/chatbots/${chatbotSlug}`,
        },
      ]}
    >
      <Box mx={4}>
        <Box mt={4} />

        <Flex alignItems="center">
          <Box color="#282828" fontWeight="bold">
            {`${chatbotGroup.name}(実施ファネル: ${chatbotGroup.page.funnel.name})`}
          </Box>
          <Spacer />
          <HStack spacing={3}>
            <ArchiveOrUnarchiveButton
              id={chatbotGroup.id}
              isArchive={chatbotGroup.isArchive}
            />
            <ChatbotGroupCopyButton originalId={chatbotGroup.id} />
          </HStack>
        </Flex>

        <HStack justifyContent="flex-end" mb={4} spacing={4}></HStack>

        <Flex alignItems="center">
          <Box color="#282828" fontWeight="bold">
            パターン
          </Box>
          <Spacer />
          <HStack spacing={3}>
            <NavLink
              to={`/sites/${siteSlug}/chatbots/${chatbotGroup.slug}/patterns/new`}
            >
              <SolidButton>パターン登録</SolidButton>
            </NavLink>
          </HStack>
        </Flex>
        <Flex justifyContent="flex-end" mt={4}>
          <HStack spacing="12px">
            <ChatbotPatternFilterButton
              filterValues={filterValues}
              onFilterChange={setFilterValues}
              onSubmit={setFilterValues}
            />
            <ChatbotDeliverWeightButton
              chatbots={chatbots}
              deliverKind={chatbotGroup.deliverKind}
              onSubmit={handleDeliverWeightSubmit}
            />
            <TableItemSelectButton
              allTableItems={allTableItems}
              selectedTableItems={selectedTableItems}
              onApplyClick={onApplyClick}
            />
          </HStack>
        </Flex>
        <ChatbotPatternTable
          chatbotGroupRef={chatbotGroup}
          filterValues={filterValues}
          siteSlug={siteSlug}
          headerColumns={headerColumns}
          dataColumns={dataColumns}
          onChatbotsChange={setChatbots}
        />

        <Flex alignItems="center">
          <Box color="#282828" fontWeight="bold">
            基本設定
          </Box>
          <Spacer />
          <HStack spacing={3}>
            <NavLink
              to={`/sites/${siteSlug}/chatbots/${chatbotGroup.slug}/edit`}
            >
              <OutlineButton>編集</OutlineButton>
            </NavLink>
          </HStack>
        </Flex>

        <Box mt={4} />
        <SectionCard>
          <DefinitionTeam label="チャットボット名">
            {chatbotGroup.name}
          </DefinitionTeam>
          <DefinitionTeam label="実施ページ">
            {chatbotGroup.page?.name}
          </DefinitionTeam>
          <DefinitionTeam label="実施ファネル">
            {chatbotGroup.page?.funnel.name}
          </DefinitionTeam>
          <DefinitionTeam label="期間設定">
            <Switch isChecked={chatbotGroup.repeat} isReadOnly />
            {chatbotGroup.repeat && (
              <ChatbotDeliverDayOfWeeks chatbotGroupRef={chatbotGroup} />
            )}
          </DefinitionTeam>
        </SectionCard>
        <Box mt={4} />
        <SectionCard>
          <DefinitionTeam label="デバイス">
            {(chatbotGroup.devices || []).map((device) => {
              const label = GraphQLEnums.Device.find(
                (d) => d.value === device
              )?.label;
              return label && <Chip label={label} key={device} />;
            })}
          </DefinitionTeam>
          <DefinitionTeam label="プラットフォーム">
            {(chatbotGroup.platforms || []).map((platform) => {
              const label = GraphQLEnums.Platform.find(
                (d) => d.value === platform
              )?.label;
              return label && <Chip label={label} key={platform} />;
            })}
          </DefinitionTeam>
          <DefinitionTeam label="ブラウザ">
            {(chatbotGroup.browsers || []).map((browser) => {
              const label = GraphQLEnums.Browser.find(
                (d) => d.value === browser
              )?.label;
              return label && <Chip label={label} key={browser} />;
            })}
          </DefinitionTeam>
          <DefinitionTeam label="性別">
            {(chatbotGroup.genders || []).map((gender) => {
              const label = GraphQLEnums.Gender.find(
                (d) => d.value === gender
              )?.label;
              return label && <Chip label={label} key={gender} />;
            })}
          </DefinitionTeam>
          <DefinitionTeam label="年齢">
            {(chatbotGroup.ageRanges || []).map((ageRange) => {
              const label = GraphQLEnums.AgeRange.find(
                (d) => d.value === ageRange
              )?.label;
              return label && <Chip label={label} key={ageRange} />;
            })}
          </DefinitionTeam>
        </SectionCard>
        <Box mt={4} />
        <ChatbotDistributionSettingSection chatbotGroupRef={chatbotGroup} />
        <Box mt={4} />
        <SectionCard>
          <DefinitionTeam label="配信方法">
            {
              GraphQLEnums.DeliverKind.find(
                (d) => d.value === chatbotGroup.deliverKind
              )?.label
            }
          </DefinitionTeam>
        </SectionCard>
      </Box>
    </PageLayout>
  );
};
