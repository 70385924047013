import { Box, Flex, HStack, useToast } from "@chakra-ui/react";
import { FC, useCallback, useMemo, useState } from "react";
import { usePaginationFragment } from "react-relay";
import { NavLink } from "react-router-dom";
import { graphql } from "relay-runtime";

import { FunnelSourceListPage_funnel$key } from "~/src/__relay_artifacts__/FunnelSourceListPage_funnel.graphql";
import { FunnelSourceListPage_Mutation } from "~/src/__relay_artifacts__/FunnelSourceListPage_Mutation.graphql";
import { SolidButton } from "~/src/components/common/Button";
import {
  Table,
  TableHeaderCheckboxColumn,
  TableHeaderColumn,
  TableHeaderRow,
} from "~/src/components/common/tables/Table";
import {
  TablePagination,
  useTablePaginationProps,
} from "~/src/components/common/tables/TablePagination";
import { TableSearchField } from "~/src/components/common/tables/TableSearchField";
import { PageLayout } from "~/src/components/features/global/PageLayout";
import { useBulkCheckboxes } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";

import { FunnelSourceTableDataRow } from "./FunnelSourceTableDataRow";
import { StyledUrlHeaderColumn } from "./styles";
import { TransferSourceTagsSelect } from "./TransferSourceTagsSelect";

export type Props = {
  funnelRef: FunnelSourceListPage_funnel$key;
  siteSlug: string;
};

const fragment = graphql`
  fragment FunnelSourceListPage_funnel on Funnel
  @refetchable(queryName: "FunnelSourceListPageRefetchQuery")
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 120 }
    cursor: { type: "String" }
  ) {
    id
    name
    slug
    sourceTags(first: $count, after: $cursor)
      @connection(key: "FunnelSourceListPage_sourceTags") {
      totalCount
      __id
      edges {
        node {
          id
          slug
          key
          value
          source {
            name
          }
          pageUrl
        }
      }
    }
  }
`;

const mutation = graphql`
  mutation FunnelSourceListPage_Mutation(
    $input: DeleteSourceTagInput!
    $connections: [ID!]!
  ) {
    deleteSourceTag(input: $input) {
      deletedSourceTagId @deleteEdge(connections: $connections)
    }
  }
`;

export const FunnelSourceListPage: FC<Props> = ({ funnelRef, siteSlug }) => {
  const toast = useToast();
  const [searchText, setSearchText] = useState("");

  const { data, loadNext, hasNext, refetch } = usePaginationFragment(
    fragment,
    funnelRef
  );

  const { tablePaginationProps } = useTablePaginationProps({
    totalCount: data.sourceTags.totalCount,
    hasNext,
    loadNext,
    refetch,
  });

  const mutate = useMutationCommit<FunnelSourceListPage_Mutation>(mutation);

  const sourceTags = useMemo(() => {
    return (
      data.sourceTags.edges
        ?.slice(tablePaginationProps.from, tablePaginationProps.to)
        .map((edge) => {
          const node = edge?.node;
          if (!node) throw new Error("assertion failed");
          return node;
        }) || []
    );
  }, [data, tablePaginationProps.from, tablePaginationProps.to]);

  const breadcrumbs = useMemo(
    () => [
      { label: "設定", path: `/sites/${siteSlug}/settings` },
      {
        label: `${data.name}｜入稿用リンク一覧`,
        path: `/sites/${siteSlug}/funnels/${data.slug}/sources`,
      },
    ],
    [siteSlug, data.slug, data.name]
  );

  const {
    selectedSlugs,
    isAllChecked,
    handleAllCheckboxClick,
    handleCheckboxClick,
  } = useBulkCheckboxes(sourceTags);

  const handleDelete = useCallback(
    async (sourceTagId: string) => {
      try {
        const res = await mutate({
          variables: {
            input: {
              sourceTagId,
            },
            connections: [data.sourceTags.__id],
          },
        });
        const deletedSourceTagId = res.deleteSourceTag?.deletedSourceTagId;
        if (!deletedSourceTagId) {
          throw new Error("assertion failed");
        }
        toast({ title: "入稿用リンクを削除しました", status: "success" });
      } catch (err) {
        toast({ title: "入稿用リンクの削除に失敗しました", status: "error" });
      }
    },
    [data.sourceTags.__id, mutate, toast]
  );

  return (
    <PageLayout
      title={`${data.name}｜入稿用リンク一覧`}
      breadcrumbs={breadcrumbs}
    >
      <Box my="16px">
        <HStack justifyContent="flex-end" mb="16px" spacing="16px">
          <NavLink to={`/sites/${siteSlug}/funnels/${data.slug}/sources/new`}>
            <SolidButton>登録</SolidButton>
          </NavLink>
        </HStack>
        <Flex alignItems="flex-end">
          <TransferSourceTagsSelect
            sourceTagIds={selectedSlugs}
            currentValues={{ funnelId: data.id }}
          />
          <TableSearchField searchText={searchText} onChange={setSearchText} />
        </Flex>
        <Table>
          <TableHeaderRow>
            <TableHeaderCheckboxColumn
              isChecked={isAllChecked}
              onChange={handleAllCheckboxClick}
            />
            <TableHeaderColumn>流入元</TableHeaderColumn>
            <StyledUrlHeaderColumn>URL</StyledUrlHeaderColumn>
            <TableHeaderColumn>アクション</TableHeaderColumn>
          </TableHeaderRow>
          {sourceTags.map((sourceTag, index) => (
            <FunnelSourceTableDataRow
              key={index}
              siteSlug={siteSlug}
              funnelSlug={data.slug}
              sourceTag={{
                sourceName: sourceTag.source.name,
                slug: sourceTag.slug,
                id: sourceTag.id,
                key: sourceTag.key,
                value: sourceTag.value,
                pageUrl: sourceTag.pageUrl,
              }}
              onDeleteClick={handleDelete}
              isChecked={selectedSlugs.includes(sourceTag.slug)}
              onCheck={handleCheckboxClick}
            />
          ))}
        </Table>
        <TablePagination {...tablePaginationProps} />
      </Box>
    </PageLayout>
  );
};
