/**
 * @generated SignedSource<<1579f6b873f5cc437b0cc51f4d335845>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ABTestPatternNameReportFilterButton_Query$variables = {
  slug: string;
};
export type ABTestPatternNameReportFilterButton_QueryVariables = ABTestPatternNameReportFilterButton_Query$variables;
export type ABTestPatternNameReportFilterButton_Query$data = {
  readonly sources: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
      } | null;
    } | null> | null;
  };
  readonly site: {
    readonly abtestScenarioPages: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly title: string | null;
        } | null;
      } | null> | null;
    };
  };
};
export type ABTestPatternNameReportFilterButton_QueryResponse = ABTestPatternNameReportFilterButton_Query$data;
export type ABTestPatternNameReportFilterButton_Query = {
  variables: ABTestPatternNameReportFilterButton_QueryVariables;
  response: ABTestPatternNameReportFilterButton_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "SourceConnection",
  "kind": "LinkedField",
  "name": "sources",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SourceEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Source",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "AbtestScenarioPageConnection",
  "kind": "LinkedField",
  "name": "abtestScenarioPages",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AbtestScenarioPageEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AbtestScenarioPage",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ABTestPatternNameReportFilterButton_Query",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ABTestPatternNameReportFilterButton_Query",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "08c0792bf96b72b497e9039bbe25a05d",
    "id": null,
    "metadata": {},
    "name": "ABTestPatternNameReportFilterButton_Query",
    "operationKind": "query",
    "text": "query ABTestPatternNameReportFilterButton_Query(\n  $slug: String!\n) {\n  sources {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n  site(slug: $slug) {\n    abtestScenarioPages {\n      edges {\n        node {\n          id\n          title\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "041b9334b37700ceb4f4fd4ef0a899f8";

export default node;
