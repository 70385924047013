import { Box } from "@chakra-ui/layout";
import { format } from "date-fns";
import { FC, useCallback, useContext } from "react";
import { ConnectionHandler, graphql, useLazyLoadQuery } from "react-relay";
import { useNavigate, useParams } from "react-router-dom";

import { PopupGroupPatternCreatePageContainer_Mutation } from "~/src/__relay_artifacts__/PopupGroupPatternCreatePageContainer_Mutation.graphql";
import { PopupGroupPatternCreatePageContainer_Query } from "~/src/__relay_artifacts__/PopupGroupPatternCreatePageContainer_Query.graphql";
import { WizardStep } from "~/src/components/common/WizardStep";
import { DateRangeContext } from "~/src/components/features/global/HeaderNavigation/DateRangeField";
import { PageLayout } from "~/src/components/features/global/PageLayout";
import {
  PopupGroupPatternForm,
  PopupGroupPatternFormProps,
  PopupGroupPatternFormValues,
} from "~/src/components/features/popup";
import { useFormErrorHandler } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";

export type Props = {};

const query = graphql`
  query PopupGroupPatternCreatePageContainer_Query(
    $siteSlug: String!
    $popupGroupSlug: String!
  ) {
    viewer {
      role
    }
    site(slug: $siteSlug) {
      id
      name
      slug
    }
    popupGroup(slug: $popupGroupSlug) {
      id
      name
      slug
      deliverKind
    }
  }
`;

const mutation = graphql`
  mutation PopupGroupPatternCreatePageContainer_Mutation(
    $input: AddPopupInput!
    $originalInput: UpdateOriginalPopupInput!
    $withOriginal: Boolean!
    $analyzerInput: ReportAnalyzerInput!
    $connections: [ID!]!
  ) {
    updateOriginalPopup(input: $originalInput) @include(if: $withOriginal) {
      popup {
        id
        weight
      }
    }
    addPopup(input: $input) {
      popupEdge @appendEdge(connections: $connections) {
        node {
          id
          slug
          name
          status
          isArchive
          kind
          judgeStatus
          weight
          clickCvr(input: $analyzerInput)
          clickCvUserCount(input: $analyzerInput)
          clickUserCount(input: $analyzerInput)
          clickUserRate(input: $analyzerInput)
          cvr(input: $analyzerInput)
          impressionCvUserCount(input: $analyzerInput)
          impressionCvr(input: $analyzerInput)
          impressionUserCount(input: $analyzerInput)
          readyCvUserCount(input: $analyzerInput)
          totalCvUserCount(input: $analyzerInput)
          totalUserCount(input: $analyzerInput)
          page {
            slug
            name
            funnel {
              name
              slug
            }
          }
        }
      }
    }
  }
`;

const initialValues: PopupGroupPatternFormValues = {
  name: "",
  contentKind: "IMAGE",
  colorKind: "DEFAULT",
  title: "",
  description: "",
  contentWidth: null,
  button: "",
  isInheritQuery: false,
  url: "",
  hrefTarget: "BLANK",
  actionKind: "EXIT_PAGE",
  displayKind: "CENTER_MODAL",
  xOffset: 0,
  xOffsetUnit: "px",
  yOffset: 0,
  yOffsetUnit: "px",
  enableTapWindow: true,
  closePopupByClickingOutside: "DISABLED",
  closeButtonPosition: "TOP_RIGHT",
  isClose: true,
  backgroundOpacity: 0.4,
  hideByScrollPercentage: 0,
  hideByOnfocus: false,
  quitTiming: 0,
  displayCountLimit: 0,
  displayPopupAgain: "INACTIVE",
  displayPopupAgainMessage: null,
  displayPopupAgainImage: { imageUrl: null },
  displayOnTabFocus: false,
  displayOnMouseOut: false,
  animationKind: "NO_ANIMATION",
  status: "ACTIVE",
  popupImages: [],
  popupVideo: null,
  htmlContent: null,
  weight: 0,
  originalPatternWeight: 0,
  ydaTrigger: "YDA_ALWAYS",
  honeycombCode: null,
  isDisplayWhenNoOperation: false,
  timing: 0,
  scrollRateRange: "0~0",
  customEvent: "",
};

export const PopupGroupPatternCreatePageContainer: FC<Props> = () => {
  const { siteSlug = "", popupGroupSlug = "" } = useParams();
  const {
    dateRange: [startOn, endOn],
  } = useContext(DateRangeContext);
  const { site, popupGroup, viewer } =
    useLazyLoadQuery<PopupGroupPatternCreatePageContainer_Query>(query, {
      siteSlug,
      popupGroupSlug,
    });

  if (!site || !popupGroup) throw new Error("assertion failed");

  const navigate = useNavigate();
  const handleCancel = useCallback(() => navigate(-1), [navigate]);

  const mutate =
    useMutationCommit<PopupGroupPatternCreatePageContainer_Mutation>(mutation);

  const { onFormError } = useFormErrorHandler();

  const handleSubmit = useCallback<PopupGroupPatternFormProps["onSubmit"]>(
    async (
      {
        enableTapWindow,
        popupImages,
        displayPopupAgainImage,
        originalPatternWeight,
        ...values
      },
      { setErrors }
    ) => {
      try {
        const uploadables: Record<string, File> = {};

        if (popupImages) {
          popupImages.forEach(({ image }, index) => {
            if (image)
              uploadables[`variables.input.popupImages.${index}.image`] = image;
          });
        }

        if (displayPopupAgainImage && displayPopupAgainImage.image) {
          uploadables[`variables.input.displayPopupAgainImage`] =
            displayPopupAgainImage.image;
        }

        const _popupImages = popupImages.map(({ image, ...rest }) => rest);

        const connectionId = ConnectionHandler.getConnectionID(
          popupGroup.id,
          "PopupPatternTable_popupGroup_popups",
          { orderBy: { direction: "ASC", field: "KIND" } }
        );
        const res = await mutate({
          variables: {
            input: {
              popupGroupId: popupGroup.id,
              enableTapWindow,
              popupImages: _popupImages,
              ...values,
            },
            analyzerInput: {
              startOn: format(startOn, "yyyy-MM-dd"),
              endOn: format(endOn, "yyyy-MM-dd"),
            },
            withOriginal: popupGroup.deliverKind === "WEIGHT",
            originalInput: {
              popupGroupId: popupGroup.id,
              weight: originalPatternWeight,
            },
            connections: [connectionId],
          },
          uploadables,
        });

        const popup = res.addPopup?.popupEdge?.node;

        if (!popup) {
          throw new Error("assertion failed");
        }
        navigate(
          `/sites/${site.slug}/popup_groups/${popupGroupSlug}/patterns/${popup.slug}`
        );
      } catch (err) {
        onFormError(err, setErrors);
      }
      return Promise.resolve();
    },
    [
      mutate,
      navigate,
      onFormError,
      popupGroup.deliverKind,
      popupGroup.id,
      popupGroupSlug,
      site.slug,
      startOn,
      endOn,
    ]
  );

  return (
    <PageLayout
      title="ポップアップ登録"
      breadcrumbs={[
        { label: "ポップアップ一覧", path: `/sites/${siteSlug}/popup_groups` },
        {
          label: "ポップアップ詳細",
          path: `/sites/${siteSlug}/popup_groups/${popupGroupSlug}`,
        },
        {
          label: "パターン登録",
          path: `/sites/${siteSlug}/popup_groups/${popupGroupSlug}/patterns/new`,
        },
      ]}
    >
      <Box mt="16px" p="16px">
        <WizardStep
          steps={[
            { label: "基本設定", active: true },
            { label: "パターン設定", active: true },
          ]}
        />
      </Box>
      <Box my="16px" p="16px">
        <PopupGroupPatternForm
          initialValues={initialValues}
          enableWeightFields={popupGroup.deliverKind === "WEIGHT"}
          onCancelClick={handleCancel}
          onSubmit={handleSubmit}
          role={viewer.role}
        />
      </Box>
    </PageLayout>
  );
};
