import { HStack, VStack } from "@chakra-ui/react";
import { Form, Formik, FormikConfig } from "formik";
import { FC, useMemo, useState } from "react";
import { graphql, useFragment, useLazyLoadQuery } from "react-relay";

import { GraphQLEnums } from "~/src/__generated__/GraphQLEnums";
import { ChatbotFirstStepForm_Query } from "~/src/__relay_artifacts__/ChatbotFirstStepForm_Query.graphql";
import { ChatbotFirstStepForm_site$key } from "~/src/__relay_artifacts__/ChatbotFirstStepForm_site.graphql";
import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormControlGroup } from "~/src/components/common/forms/FormControlGroup";
import { FormControlGroupWithAccordion } from "~/src/components/common/forms/FormControlGroupWithAccordion";
import { FormikDateField } from "~/src/components/common/forms/FormikDateField";
import { FormikFormButtons } from "~/src/components/common/forms/FormikFormButtons";
import { FormikMultipleSelectField } from "~/src/components/common/forms/FormikMultipleSelectField";
import { FormikRadioButtonsField } from "~/src/components/common/forms/FormikRadioButtonsField";
import { FormikSelectField } from "~/src/components/common/forms/FormikSelectField";
import { FormikSwitchField } from "~/src/components/common/forms/FormikSwitchField";
import { FormikTextField } from "~/src/components/common/forms/FormikTextField";
import { FormikTimeField } from "~/src/components/common/forms/FormikTimeField";
import { DeliverDayOfWeeksFieldArray } from "~/src/components/features/deliverDayOfWeek";

import { ChatbotDistributionSettingFields } from "./ChatbotDistributionSettingFields";
import { FormValues, validationSchema } from "./validationSchema";

export type Props = {
  initialValues?: FormValues;
  siteRef: ChatbotFirstStepForm_site$key;
  onCancelClick: () => void;
  onSubmit: FormikConfig<FormValues>["onSubmit"];
  isFunnelDisabled: boolean;
};

const query = graphql`
  query ChatbotFirstStepForm_Query {
    ...ChatbotDistributionSettingFields_root
  }
`;

const siteFragment = graphql`
  fragment ChatbotFirstStepForm_site on Site {
    funnels {
      edges {
        node {
          id
          name
        }
      }
    }
    pages {
      edges {
        node {
          id
          name
          kind
          sortNumber
          funnel {
            id
          }
        }
      }
    }
  }
`;

const defaultInitialValues: FormValues = {
  name: "",
  funnelId: "",
  pageId: "",
  repeat: false,
  startDate: null,
  endDate: null,
  startTime: null,
  endTime: null,
  devices: GraphQLEnums.Device.map((device) => device.value),
  platforms: GraphQLEnums.Platform.map((platform) => platform.value),
  browsers: GraphQLEnums.Browser.map((browser) => browser.value),
  genders: GraphQLEnums.Gender.map((gender) => gender.value),
  ageRanges: GraphQLEnums.AgeRange.map((ageRange) => ageRange.value),
  deliverKind: "EQUAL",
  sourceIds: [],
  referrers: [],
  excludeReferrers: [],
  visitCount: null,
  visitMatchType: "EQUAL_MATCH_TYPE",
  deliverTargetUrls: [],
  deliverExcludeUrls: [],
  deliverTargetLabelIds: [],
  deliverExcludeLabelIds: [],
  targetCookies: [],
  deliverDayOfWeeks: [],
  status: "ACTIVE",
};

export const ChatbotFirstStepForm: FC<Props> = ({
  initialValues = defaultInitialValues,
  siteRef,
  onCancelClick,
  onSubmit,
  isFunnelDisabled,
}) => {
  const site = useFragment(siteFragment, siteRef);
  const data = useLazyLoadQuery<ChatbotFirstStepForm_Query>(query, {});

  const [funnelValue, setFunnelValue] = useState(initialValues.funnelId);

  const funnelOptions = useMemo(
    () =>
      site?.funnels.edges?.map((edge) => {
        const node = edge?.node;
        if (!node) throw new Error("assertion failed");
        return { label: node.name, value: node.id };
      }) || [],
    [site?.funnels.edges]
  );

  const pageOptions = useMemo(
    () =>
      site?.pages.edges
        ?.map((edge) => {
          const node = edge?.node;
          if (!node) throw new Error("assertion failed");
          const pageName =
            node.kind === "FORM"
              ? node.name + `(${node.sortNumber - 1})`
              : node.name;
          return { label: pageName, value: node.id, funnelId: node.funnel.id };
        })
        .filter((v) => v.funnelId === funnelValue) || [],
    [site?.pages.edges, funnelValue]
  );

  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {({ values }) => (
        <Form>
          <VStack spacing="24px" alignItems="flex-start">
            <FormControlGroup>
              <FormControl required label="チャットボット名">
                <FormikTextField
                  type="text"
                  mode="fill"
                  name="name"
                  rightAdornment={`${values.name.length}/50`}
                />
              </FormControl>
              <FormControl required label="実施するファネル">
                <FormikSelectField
                  mode="fill"
                  name="funnelId"
                  onSetStateAction={setFunnelValue}
                  options={funnelOptions}
                  isDisabled={isFunnelDisabled}
                />
              </FormControl>
              <FormControl required label="実施するページ">
                <FormikSelectField
                  mode="fill"
                  name="pageId"
                  options={pageOptions}
                />
              </FormControl>
              <FormControl label="期間設定">
                <FormikSwitchField name="repeat" />
              </FormControl>
              {values.repeat && (
                <>
                  <FormControl label="実施期間">
                    <HStack spacing={"36px"}>
                      <FormikDateField
                        name="startDate"
                        placeholder="開始日を選択"
                      />
                      <FormikDateField
                        name="endDate"
                        placeholder="終了日を選択"
                      />
                    </HStack>
                  </FormControl>
                  <FormControl label="実施時刻">
                    <HStack spacing={"36px"}>
                      <FormikTimeField
                        name="startTime"
                        placeholder="開始時刻を選択"
                      />
                      <FormikTimeField
                        name="endTime"
                        placeholder="終了時刻を選択"
                      />
                    </HStack>
                  </FormControl>
                  <FormControl label="実施曜日">
                    <DeliverDayOfWeeksFieldArray name="deliverDayOfWeeks" />
                  </FormControl>
                </>
              )}
            </FormControlGroup>

            <FormControlGroupWithAccordion description="配信セグメント">
              <FormControl label="デバイス">
                <FormikMultipleSelectField
                  name="devices"
                  options={GraphQLEnums.Device}
                />
              </FormControl>
              <FormControl label="プラットフォーム">
                <FormikMultipleSelectField
                  name="platforms"
                  options={GraphQLEnums.Platform}
                />
              </FormControl>
              <FormControl label="ブラウザ">
                <FormikMultipleSelectField
                  name="browsers"
                  options={GraphQLEnums.Browser}
                />
              </FormControl>
              <FormControl label="性別">
                <FormikMultipleSelectField
                  name="genders"
                  options={GraphQLEnums.Gender}
                />
              </FormControl>
              <FormControl label="年齢">
                <FormikMultipleSelectField
                  name="ageRanges"
                  options={GraphQLEnums.AgeRange}
                />
              </FormControl>
            </FormControlGroupWithAccordion>
            <FormControlGroupWithAccordion description="配信設定">
              <ChatbotDistributionSettingFields rootRef={data} />
            </FormControlGroupWithAccordion>
            <FormControlGroup>
              <FormControl required label="配信方法">
                <FormikRadioButtonsField
                  name="deliverKind"
                  options={GraphQLEnums.DeliverKind}
                />
              </FormControl>
            </FormControlGroup>

            <FormControlGroup>
              <FormControl label="ステータス">
                <FormikRadioButtonsField
                  name="status"
                  options={GraphQLEnums.DistributionStatus}
                />
              </FormControl>
            </FormControlGroup>

            <FormikFormButtons onCancelClick={onCancelClick} />
          </VStack>
        </Form>
      )}
    </Formik>
  );
};
