import { Box, VStack } from "@chakra-ui/react";
import { AddCircle as AddCircleIcon } from "@mui/icons-material";
import { FieldArray, useField } from "formik";
import * as React from "react";

import { DeliverDayOfWeek } from "~/src/__generated__/schema";
import { SymbolOutlineButton } from "~/src/components/common/Button";

import { DeliverDayOfWeeksField } from "./DeliverDayOfWeeksField";

type Props = {
  name: string;
  disabled?: boolean;
};

export const DeliverDayOfWeeksFieldArray: React.FC<Props> = ({
  name,
  disabled = false,
}) => {
  const [{ value: deliverDayOfWeeks }] = useField<
    Partial<DeliverDayOfWeek>[] | undefined
  >(name);

  return (
    <FieldArray
      name={name}
      // eslint-disable-next-line react/jsx-no-bind
      render={(arrayHelpers) => (
        <VStack width="100%">
          {deliverDayOfWeeks &&
            deliverDayOfWeeks.length > 0 &&
            deliverDayOfWeeks.map((_, index) => (
              <DeliverDayOfWeeksField
                key={index}
                fieldName={{
                  weekOfMonth: `${name}.${index}.weekOfMonth`,
                  daysOfWeek: `${name}.${index}.daysOfWeek`,
                }}
                // eslint-disable-next-line react/jsx-no-bind
                onDelete={() => arrayHelpers.remove(index)}
                disabled={disabled}
              />
            ))}
          <Box width="100%">
            <SymbolOutlineButton
              icon={<AddCircleIcon />}
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() =>
                arrayHelpers.push({ weekOfMonth: null, daysOfWeek: [] })
              }
              disabled={disabled}
            >
              曜日を追加
            </SymbolOutlineButton>
          </Box>
        </VStack>
      )}
    />
  );
};
