/**
 * @generated SignedSource<<5f2484bf969c32b8565a975ae8571ff7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ABTestEditPageContainer_Query$variables = {
  abtestScenarioPageGroupSlug: string;
  siteSlug: string;
};
export type ABTestEditPageContainer_QueryVariables = ABTestEditPageContainer_Query$variables;
export type ABTestEditPageContainer_Query$data = {
  readonly abtestScenarioPageGroup: {
    readonly " $fragmentSpreads": FragmentRefs<"ABTestEditScreenContainer_abtestScenarioPageGroup">;
  } | null;
  readonly site: {
    readonly " $fragmentSpreads": FragmentRefs<"ABTestEditScreenContainer_site">;
  };
};
export type ABTestEditPageContainer_QueryResponse = ABTestEditPageContainer_Query$data;
export type ABTestEditPageContainer_Query = {
  variables: ABTestEditPageContainer_QueryVariables;
  response: ABTestEditPageContainer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "abtestScenarioPageGroupSlug"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "siteSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "abtestScenarioPageGroupSlug"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "siteSlug"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  (v3/*: any*/),
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = [
  (v4/*: any*/),
  (v6/*: any*/)
],
v8 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "referrer",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "matchOp",
    "storageKey": null
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v10 = [
  (v4/*: any*/),
  (v6/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "kind",
    "storageKey": null
  },
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "sortNumber",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ABTestEditPageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AbtestScenarioPageGroup",
        "kind": "LinkedField",
        "name": "abtestScenarioPageGroup",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ABTestEditScreenContainer_abtestScenarioPageGroup"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ABTestEditScreenContainer_site"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ABTestEditPageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AbtestScenarioPageGroup",
        "kind": "LinkedField",
        "name": "abtestScenarioPageGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ageRanges",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "annualIncomes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "browsers",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DeliverDayOfWeek",
            "kind": "LinkedField",
            "name": "deliverDayOfWeeks",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "weekOfMonth",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "daysOfWeek",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AbtestScenarioPageGroupDeliverExcludeUrl",
            "kind": "LinkedField",
            "name": "deliverExcludeUrls",
            "plural": true,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserLabel",
            "kind": "LinkedField",
            "name": "deliverExcludeLabels",
            "plural": true,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AbtestScenarioPageGroupDeliverTargetUrl",
            "kind": "LinkedField",
            "name": "deliverTargetUrls",
            "plural": true,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserLabel",
            "kind": "LinkedField",
            "name": "deliverTargetLabels",
            "plural": true,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deliverKind",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "devices",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AbtestScenarioPageGroupExcludeReferrer",
            "kind": "LinkedField",
            "name": "excludeReferrers",
            "plural": true,
            "selections": (v8/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "genders",
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "memo",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Page",
            "kind": "LinkedField",
            "name": "page",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Funnel",
                "kind": "LinkedField",
                "name": "funnel",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "platforms",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AbtestScenarioPageGroupReferrer",
            "kind": "LinkedField",
            "name": "referrers",
            "plural": true,
            "selections": (v8/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "repeat",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startTime",
            "storageKey": null
          },
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Source",
            "kind": "LinkedField",
            "name": "sources",
            "plural": true,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Source",
            "kind": "LinkedField",
            "name": "excludeSources",
            "plural": true,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AbtestScenarioPageGroupTargetCookie",
            "kind": "LinkedField",
            "name": "targetCookies",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "matchType",
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "visitCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "visitMatchType",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v6/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FunnelConnection",
            "kind": "LinkedField",
            "name": "funnels",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FunnelEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Funnel",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Page",
                        "kind": "LinkedField",
                        "name": "confirmPage",
                        "plural": false,
                        "selections": (v10/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Page",
                        "kind": "LinkedField",
                        "name": "landingPage",
                        "plural": false,
                        "selections": (v10/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Page",
                        "kind": "LinkedField",
                        "name": "formPage",
                        "plural": true,
                        "selections": (v10/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Page",
                        "kind": "LinkedField",
                        "name": "thanksPage",
                        "plural": false,
                        "selections": (v10/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "80ba82f3b20801a88120598529e972f6",
    "id": null,
    "metadata": {},
    "name": "ABTestEditPageContainer_Query",
    "operationKind": "query",
    "text": "query ABTestEditPageContainer_Query(\n  $abtestScenarioPageGroupSlug: String!\n  $siteSlug: String!\n) {\n  abtestScenarioPageGroup(slug: $abtestScenarioPageGroupSlug) {\n    ...ABTestEditScreenContainer_abtestScenarioPageGroup\n    id\n  }\n  site(slug: $siteSlug) {\n    ...ABTestEditScreenContainer_site\n    id\n  }\n}\n\nfragment ABTestEditScreenContainer_abtestScenarioPageGroup on AbtestScenarioPageGroup {\n  ageRanges\n  annualIncomes\n  browsers\n  deliverDayOfWeeks {\n    weekOfMonth\n    daysOfWeek\n  }\n  deliverExcludeUrls {\n    url\n    id\n  }\n  deliverExcludeLabels {\n    id\n    name\n  }\n  deliverTargetUrls {\n    url\n    id\n  }\n  deliverTargetLabels {\n    id\n    name\n  }\n  deliverKind\n  devices\n  endDate\n  endTime\n  excludeReferrers {\n    referrer\n    matchOp\n  }\n  genders\n  id\n  memo\n  page {\n    id\n    funnel {\n      id\n    }\n  }\n  platforms\n  referrers {\n    referrer\n    matchOp\n  }\n  repeat\n  startDate\n  startTime\n  slug\n  sources {\n    id\n    name\n  }\n  excludeSources {\n    id\n    name\n  }\n  targetCookies {\n    key\n    value\n    matchType\n    id\n  }\n  title\n  visitCount\n  visitMatchType\n}\n\nfragment ABTestEditScreenContainer_site on Site {\n  id\n  name\n  slug\n  ...ABTestForm_site\n}\n\nfragment ABTestForm_site on Site {\n  funnels {\n    edges {\n      node {\n        id\n        name\n        confirmPage {\n          id\n          name\n          kind\n          url\n          sortNumber\n        }\n        landingPage {\n          id\n          name\n          kind\n          url\n          sortNumber\n        }\n        formPage {\n          id\n          name\n          kind\n          url\n          sortNumber\n        }\n        thanksPage {\n          id\n          name\n          kind\n          url\n          sortNumber\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6714e82646e91f693ff5ee299ae5c0c4";

export default node;
