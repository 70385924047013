/**
 * @generated SignedSource<<3408e9daf928639e21fb56c1dd021492>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FunnelDetailPage_funnel$data = {
  readonly id: string;
  readonly name: string;
  readonly slug: string;
  readonly siteUrl: string;
  readonly landingPage: {
    readonly id: string;
    readonly name: string;
    readonly url: string;
    readonly slug: string;
  } | null;
  readonly formPage: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly url: string;
    readonly sortNumber: number;
    readonly slug: string;
  }> | null;
  readonly confirmPage: {
    readonly id: string;
    readonly name: string;
    readonly url: string;
    readonly slug: string;
  } | null;
  readonly thanksPage: {
    readonly id: string;
    readonly name: string;
    readonly url: string;
    readonly slug: string;
  } | null;
  readonly " $fragmentType": "FunnelDetailPage_funnel";
};
export type FunnelDetailPage_funnel = FunnelDetailPage_funnel$data;
export type FunnelDetailPage_funnel$key = {
  readonly " $data"?: FunnelDetailPage_funnel$data;
  readonly " $fragmentSpreads": FragmentRefs<"FunnelDetailPage_funnel">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v4 = [
  (v0/*: any*/),
  (v1/*: any*/),
  (v3/*: any*/),
  (v2/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FunnelDetailPage_funnel",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "siteUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Page",
      "kind": "LinkedField",
      "name": "landingPage",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Page",
      "kind": "LinkedField",
      "name": "formPage",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sortNumber",
          "storageKey": null
        },
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Page",
      "kind": "LinkedField",
      "name": "confirmPage",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Page",
      "kind": "LinkedField",
      "name": "thanksPage",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Funnel",
  "abstractKey": null
};
})();

(node as any).hash = "0bbf8b7d45453e25f1f27d1cd1ed353d";

export default node;
