/**
 * @generated SignedSource<<306a6159eadb869c3bd24b2a3a281fc1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FunnelSourceCreatePageContainer_Query$variables = {
  funnelSlug: string;
};
export type FunnelSourceCreatePageContainer_QueryVariables = FunnelSourceCreatePageContainer_Query$variables;
export type FunnelSourceCreatePageContainer_Query$data = {
  readonly funnel: {
    readonly " $fragmentSpreads": FragmentRefs<"FunnelSourceCreateFormContainer_funnel">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"FunnelSourceCreateFormContainer_root">;
};
export type FunnelSourceCreatePageContainer_QueryResponse = FunnelSourceCreatePageContainer_Query$data;
export type FunnelSourceCreatePageContainer_Query = {
  variables: FunnelSourceCreatePageContainer_QueryVariables;
  response: FunnelSourceCreatePageContainer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "funnelSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "funnelSlug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FunnelSourceCreatePageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Funnel",
        "kind": "LinkedField",
        "name": "funnel",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "FunnelSourceCreateFormContainer_funnel"
          }
        ],
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "FunnelSourceCreateFormContainer_root"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FunnelSourceCreatePageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Funnel",
        "kind": "LinkedField",
        "name": "funnel",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Page",
            "kind": "LinkedField",
            "name": "landingPage",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SourceConnection",
        "kind": "LinkedField",
        "name": "sources",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SourceEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Source",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ce8dbe91599be1fdbc2571ca3f4119d6",
    "id": null,
    "metadata": {},
    "name": "FunnelSourceCreatePageContainer_Query",
    "operationKind": "query",
    "text": "query FunnelSourceCreatePageContainer_Query(\n  $funnelSlug: String!\n) {\n  funnel(slug: $funnelSlug) {\n    ...FunnelSourceCreateFormContainer_funnel\n    id\n  }\n  ...FunnelSourceCreateFormContainer_root\n}\n\nfragment FunnelSourceCreateFormContainer_funnel on Funnel {\n  id\n  landingPage {\n    id\n  }\n}\n\nfragment FunnelSourceCreateFormContainer_root on Query {\n  sources {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e14f4640ebcadb16c7e9c13147d49b96";

export default node;
