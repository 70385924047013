import { chakraFactory } from "~/src/lib/chakra-ui";

export const HorizontalSeparator = chakraFactory("div", {
  baseStyle: {
    width: "100%",
    height: "1px",
    bg: "#DBDBDB",
  },
});

export const VerticalSeparator = chakraFactory("div", {
  baseStyle: {
    width: "1px",
    height: "34px",
    bg: "#DBDBDB",
  },
});
