import { Box } from "@chakra-ui/react";

import { IconButton } from "~/src/components/common/IconButton";
import { chakraFactory } from "~/src/lib/chakra-ui";

export const StyledTableItemWrapperBox = chakraFactory(Box, {
  baseStyle: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    width: "280px",
    height: "36px",
    background: "#EEEEEE",
    borderRadius: "5px",
    fontSize: "12px",
    color: "#282828",
    px: "4px",
    userSelect: "none",

    "> svg": {
      width: "24px",
      height: "24px",
      color: "#797979",
      mr: "6px",
    },
  },
});

export const StyledTableItemIconButton = chakraFactory(IconButton, {
  baseStyle: {
    width: "16px",
    height: "16px",
    position: "absolute",
    right: "8px",
    margin: "auto 0",
    color: "#979797",
  },
});
