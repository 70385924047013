import { useToast } from "@chakra-ui/react";
import { FC, useCallback } from "react";
import { graphql } from "react-relay";

import { PopupSetEditFormContainer_Mutation } from "~/src/__relay_artifacts__/PopupSetEditFormContainer_Mutation.graphql";
import { useFormErrorHandler } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";

import { PopupSetForm, PopupSetFormProps } from "./presentations";

export type Props = {
  onClose: () => void;
  popupSet: { id: string; name: string; memo: string | null };
};

const mutation = graphql`
  mutation PopupSetEditFormContainer_Mutation($input: UpdatePopupSetInput!) {
    updatePopupSet(input: $input) {
      popupSet {
        id
        name
        memo
      }
    }
  }
`;

export const PopupSetEditFormContainer: FC<Props> = ({ onClose, popupSet }) => {
  const toast = useToast();
  const { onFormError } = useFormErrorHandler();
  const mutate =
    useMutationCommit<PopupSetEditFormContainer_Mutation>(mutation);
  const initialValues = {
    name: popupSet.name,
    memo: popupSet.memo || "",
  };

  const handleSubmit = useCallback<PopupSetFormProps["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const res = await mutate({
          variables: {
            input: {
              popupSetId: popupSet.id,
              name: values.name,
              memo: values.memo,
            },
          },
        });
        if (!res.updatePopupSet?.popupSet) throw new Error("assertion failed");
        toast({
          title: "ポップアップパターンセットを更新しました",
          status: "success",
        });
        onClose();
      } catch (err) {
        onFormError(err, setErrors);
      }
    },
    [mutate, onFormError, toast, onClose, popupSet.id]
  );

  return (
    <PopupSetForm
      onSubmit={handleSubmit}
      onCancelClick={onClose}
      initialValues={initialValues}
    />
  );
};
