/**
 * @generated SignedSource<<29fea63db87d28bb688d37c598642306>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type HasForm = "HAS_FORM" | "NO_FORM" | "%future added value";
export type QueryMatch = "EQUAL_MATCH" | "INCLUDING_MATCH" | "PREFIX_MATCH" | "%future added value";
export type UrlMatch = "EQUAL_TYPE" | "HAS_ID_TYPE" | "EQUAL_OR_HAS_ID_TYPE" | "STARTS_WITH_PREFIX_MATCH" | "REGEX_MATCH_TYPE" | "%future added value";
export type UpdateFunnelInput = {
  clientMutationId?: string | null;
  funnelId: string;
  name?: string | null;
  isExperiment: boolean;
  landingPageAttributes?: PageAttributes | null;
  formPageAttributes?: ReadonlyArray<PageAttributes> | null;
  confirmPageAttributes?: PageAttributes | null;
  thanksPageAttributes?: PageAttributes | null;
};
export type PageAttributes = {
  id?: string | null;
  name: string;
  url: string;
  mobileUrl?: string | null;
  tabletUrl?: string | null;
  hasForm?: HasForm | null;
  urlMatchType: UrlMatch;
  queryMatchType?: QueryMatch | null;
  isDisableWhiteout?: boolean | null;
};
export type FunnelBulkEditTabContainer_Mutation$variables = {
  input: UpdateFunnelInput;
};
export type FunnelBulkEditTabContainer_MutationVariables = FunnelBulkEditTabContainer_Mutation$variables;
export type FunnelBulkEditTabContainer_Mutation$data = {
  readonly updateFunnel: {
    readonly funnel: {
      readonly id: string;
      readonly slug: string;
      readonly name: string;
      readonly isExperiment: boolean;
      readonly landingPage: {
        readonly id: string;
        readonly name: string;
        readonly url: string;
        readonly mobileUrl: string | null;
        readonly tabletUrl: string | null;
        readonly hasForm: HasForm | null;
        readonly urlMatchType: UrlMatch;
      } | null;
      readonly thanksPage: {
        readonly id: string;
        readonly name: string;
        readonly url: string;
        readonly mobileUrl: string | null;
        readonly tabletUrl: string | null;
        readonly urlMatchType: UrlMatch;
      } | null;
      readonly confirmPage: {
        readonly id: string;
        readonly name: string;
        readonly url: string;
        readonly mobileUrl: string | null;
        readonly tabletUrl: string | null;
        readonly urlMatchType: UrlMatch;
      } | null;
      readonly formPage: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly url: string;
        readonly mobileUrl: string | null;
        readonly tabletUrl: string | null;
        readonly urlMatchType: UrlMatch;
      }> | null;
    } | null;
  } | null;
};
export type FunnelBulkEditTabContainer_MutationResponse = FunnelBulkEditTabContainer_Mutation$data;
export type FunnelBulkEditTabContainer_Mutation = {
  variables: FunnelBulkEditTabContainer_MutationVariables;
  response: FunnelBulkEditTabContainer_Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mobileUrl",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tabletUrl",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urlMatchType",
  "storageKey": null
},
v7 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/)
],
v8 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateFunnelPayload",
    "kind": "LinkedField",
    "name": "updateFunnel",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Funnel",
        "kind": "LinkedField",
        "name": "funnel",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isExperiment",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Page",
            "kind": "LinkedField",
            "name": "landingPage",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasForm",
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Page",
            "kind": "LinkedField",
            "name": "thanksPage",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Page",
            "kind": "LinkedField",
            "name": "confirmPage",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Page",
            "kind": "LinkedField",
            "name": "formPage",
            "plural": true,
            "selections": (v7/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FunnelBulkEditTabContainer_Mutation",
    "selections": (v8/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FunnelBulkEditTabContainer_Mutation",
    "selections": (v8/*: any*/)
  },
  "params": {
    "cacheID": "d23885dbda13794c98c217cc129cac3e",
    "id": null,
    "metadata": {},
    "name": "FunnelBulkEditTabContainer_Mutation",
    "operationKind": "mutation",
    "text": "mutation FunnelBulkEditTabContainer_Mutation(\n  $input: UpdateFunnelInput!\n) {\n  updateFunnel(input: $input) {\n    funnel {\n      id\n      slug\n      name\n      isExperiment\n      landingPage {\n        id\n        name\n        url\n        mobileUrl\n        tabletUrl\n        hasForm\n        urlMatchType\n      }\n      thanksPage {\n        id\n        name\n        url\n        mobileUrl\n        tabletUrl\n        urlMatchType\n      }\n      confirmPage {\n        id\n        name\n        url\n        mobileUrl\n        tabletUrl\n        urlMatchType\n      }\n      formPage {\n        id\n        name\n        url\n        mobileUrl\n        tabletUrl\n        urlMatchType\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "26823736784cbc18282f09d16723a319";

export default node;
