import { HStack, VStack } from "@chakra-ui/react";
import { FilterAltOutlined as FilterAltOutlinedIcon } from "@mui/icons-material";
import { Form, Formik } from "formik";
import { FC, useCallback, useMemo, useState } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";
import * as yup from "yup";

import { GraphQLEnums } from "~/src/__generated__/GraphQLEnums";
import { Device, PageKind } from "~/src/__generated__/schema";
import { ReportFilterButton_Query } from "~/src/__relay_artifacts__/ReportFilterButton_Query.graphql";
import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormikFormButtons } from "~/src/components/common/forms/FormikFormButtons";
import { FormikMultipleSelectField } from "~/src/components/common/forms/FormikMultipleSelectField";
import { FormikSelectField } from "~/src/components/common/forms/FormikSelectField";
import { IconButton } from "~/src/components/common/IconButton";
import { Modal } from "~/src/components/common/Modal";
import { FilteredTag } from "~/src/components/features/filter";
import { useOpenState } from "~/src/lib/hooks";

export type FormValues = {
  sourceId: string | null;
  device: Device | null;
  funnelIds: string[];
  pageKind: PageKind | null;
};

export type Props = {
  filterValues: FormValues;
  onFilterChange: (filterValues: FormValues) => void;
  onSubmit: (values: FormValues) => Promise<void> | void;
  enablePageKindForm?: boolean;
};

const query = graphql`
  query ReportFilterButton_Query($slug: String!) {
    sources {
      edges {
        node {
          id
          name
        }
      }
    }
    site(slug: $slug) {
      funnels {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

const validationSchema = yup.object({
  sourceId: yup.string().nullable(),
  device: yup.string().nullable(),
  funnelIds: yup.array().of(yup.string()).nullable(),
  pageKind: yup.string().nullable(),
});

export const ReportFilterButton: FC<Props> = ({
  filterValues,
  onFilterChange,
  onSubmit,
  enablePageKindForm = false,
}) => {
  const { siteSlug = "" } = useParams();
  const { isOpen, onClose, onOpen } = useOpenState();
  const [, setFunnelValues] = useState(filterValues.funnelIds);

  const data = useLazyLoadQuery<ReportFilterButton_Query>(query, {
    slug: siteSlug,
  });

  const sourceOptions = useMemo(
    () => [
      { label: "すべて", value: null },
      ...(data.sources.edges || []).map((edge) => {
        const node = edge?.node;
        if (!node) throw new Error("assertion failed");
        return { label: node.name, value: node.id };
      }),
    ],
    [data.sources.edges]
  );

  const funnelOptions = useMemo(() => {
    return (
      data.site.funnels?.edges?.map((edge) => {
        const node = edge?.node;
        if (!node) throw new Error("assertion failed");
        return { label: node.name, value: node.id };
      }) || []
    );
  }, [data.site.funnels]);

  const deviceOptions = useMemo(
    () => [{ label: "すべて", value: null }, ...GraphQLEnums.Device],
    []
  );

  const pageOptions = useMemo(
    () => [{ label: "すべて", value: null }, ...GraphQLEnums.PageKind],
    []
  );

  const handleFilterChange = useCallback(
    (values: Partial<FormValues>) => () => {
      onFilterChange({ ...filterValues, ...values });
    },
    [filterValues, onFilterChange]
  );

  const handleSubmit = useCallback(
    (values: FormValues) => {
      onSubmit(values);
      onClose();
    },
    [onClose, onSubmit]
  );

  return (
    <HStack>
      {filterValues.funnelIds.length > 0 &&
        filterValues.funnelIds.map((funnelId, index) => (
          <FilteredTag
            key={index}
            name="ファネル"
            value={funnelId}
            options={funnelOptions}
            onClear={handleFilterChange({
              funnelIds: filterValues.funnelIds.filter((id) => id !== funnelId),
            })}
          />
        ))}
      {filterValues.pageKind && (
        <FilteredTag
          name="ページタイプ"
          value={filterValues.pageKind}
          options={pageOptions}
          onClear={handleFilterChange({ pageKind: null })}
        />
      )}
      {filterValues.sourceId && (
        <FilteredTag
          name="流入元"
          value={filterValues.sourceId}
          options={sourceOptions}
          onClear={handleFilterChange({ sourceId: null })}
        />
      )}
      {filterValues.device && (
        <FilteredTag
          name="デバイス"
          value={filterValues.device}
          options={deviceOptions}
          onClear={handleFilterChange({ device: null })}
        />
      )}
      <IconButton
        label="フィルタ"
        size="24px"
        icon={<FilterAltOutlinedIcon htmlColor="#282828" />}
        isActive={
          Object.values(filterValues).some((value) => value != null) || isOpen
        }
        onClick={onOpen}
      />
      <Modal title="フィルタ" isOpen={isOpen} onClose={onClose}>
        <Formik<FormValues>
          enableReinitialize
          initialValues={filterValues}
          validationSchema={validationSchema}
          validateOnMount={false}
          validateOnChange={false}
          onSubmit={handleSubmit}
        >
          <Form>
            <VStack spacing="16px">
              <FormControl label="ファネル">
                <FormikMultipleSelectField
                  name="funnelIds"
                  options={funnelOptions}
                  onSetStateAction={setFunnelValues}
                />
              </FormControl>
              {enablePageKindForm && (
                <FormControl label="ページ">
                  <FormikSelectField name="pageKind" options={pageOptions} />
                </FormControl>
              )}
              <FormControl label="流入元">
                <FormikSelectField name="sourceId" options={sourceOptions} />
              </FormControl>
              <FormControl label="デバイス">
                <FormikSelectField name="device" options={deviceOptions} />
              </FormControl>
            </VStack>
            <FormikFormButtons onCancelClick={onClose} />
          </Form>
        </Formik>
      </Modal>
    </HStack>
  );
};
