import { groupBy, includes, prop, uniqBy } from "ramda";
import { FC, useCallback, useMemo, useState } from "react";
import { graphql, useFragment } from "react-relay";

import { PublisherReportGraph_site$key } from "~/src/__relay_artifacts__/PublisherReportGraph_site.graphql";

import { AreaGraph } from "../AreaGraph";
import {
  MeasureKey,
  findGraphTitleByMeasureKey,
  generateGraphDataArray,
  isMeasureKey,
  measureOptions,
} from "../utils";

import { Source } from "./types";

const fragment = graphql`
  fragment PublisherReportGraph_site on Site {
    reportPublisher(input: $input)
  }
`;

export type Prop = {
  siteRef: PublisherReportGraph_site$key;
};

export type FormValues = {
  domains: string[];
  measureKey: MeasureKey | null;
};

export const PublisherReportGraph: FC<Prop> = ({ siteRef }) => {
  const { reportPublisher } = useFragment<typeof siteRef>(fragment, siteRef);

  const [formValues, setFormValues] = useState<FormValues>({
    domains: [],
    measureKey: null,
  });

  const sources: Source[] = useMemo(() => {
    const uniqueSources = uniqBy<any, any>(
      prop("domain"),
      reportPublisher.daily_report
    );
    return uniqueSources.map((row) => {
      return { id: row.domain, domain: row.domain };
    });
  }, [reportPublisher]);

  const graphDataArray = useMemo(() => {
    if (formValues.domains.length === 0) {
      return generateGraphDataArray(
        [groupBy<any, any>(prop("access_date"), reportPublisher.daily_report)],
        formValues.measureKey || "UNIQUE_USER"
      );
    }
    const reports = reportPublisher.daily_report as any[];
    const filteredReports = reports.filter((row) =>
      includes(row.domain, formValues.domains)
    );
    const reportsByDomain = groupBy<any, any>(prop("domain"), filteredReports);
    const reportsByDomainAndDate: Record<any, any[]>[] = [];
    Object.keys(reportsByDomain).forEach((key) => {
      reportsByDomainAndDate.push(
        groupBy<any, any>(prop("access_date"), reportsByDomain[key])
      );
    });

    return generateGraphDataArray(
      reportsByDomainAndDate,
      formValues.measureKey || "UNIQUE_USER"
    );
  }, [reportPublisher, formValues]);

  const sourceOptions = useMemo(() => {
    return sources.map((source) => {
      return { label: source.domain, value: source.id };
    });
  }, [sources]);

  const handleSubmit = useCallback(({ domains, measureKey }) => {
    if (measureKey === null || isMeasureKey(measureKey, measureOptions)) {
      setFormValues({ domains, measureKey });
    }
  }, []);

  return (
    <AreaGraph
      title={`日別推移｜${findGraphTitleByMeasureKey(formValues.measureKey)}`}
      label={findGraphTitleByMeasureKey(formValues.measureKey)}
      dataArray={graphDataArray}
      graphCustomizedButtonProps={{
        selectFields: [
          {
            name: "domains",
            label: "ドメイン",
            options: sourceOptions,
            isMultiple: true,
          },
          {
            name: "measureKey",
            label: "項目",
            options: measureOptions,
            isMultiple: false,
          },
        ],
        formValues,
        onSubmit: handleSubmit,
      }}
    />
  );
};
