import { useToast } from "@chakra-ui/toast";
import {
  ArchiveOutlined as ArchiveOutlinedIcon,
  ContentCopyOutlined as ContentCopyOutlinedIcon,
  DeleteOutline as DeleteOutlineIcon,
  EditOutlined as EditOutlinedIcon,
  UnarchiveOutlined as UnarchiveOutlinedIcon,
} from "@mui/icons-material";
import { FC, useCallback, useMemo } from "react";
import { graphql } from "react-relay";

import { FunnelPopupGroupTableActions_CopyMutation } from "~/src/__relay_artifacts__/FunnelPopupGroupTableActions_CopyMutation.graphql";
import { FunnelPopupGroupTableActions_DeleteMutation } from "~/src/__relay_artifacts__/FunnelPopupGroupTableActions_DeleteMutation.graphql";
import { TableActions } from "~/src/components/common/tables/TableActions";
import {
  useArchiveHandler,
  useUnarchiveHandler,
} from "~/src/components/features/archive";
import {
  CopyModal,
  FormValues,
} from "~/src/components/features/global/CopyModal";
import { DeleteConfirmDialog } from "~/src/components/features/global/DeleteConfirmDialog";
import { useOpenState } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";

type Props = {
  popupGroup: { id: string; name: string; slug: string };
  currentValues: FormValues;
  connectionId: string;
  filtered: boolean;
  siteSlug: string;
};

const deleteMutation = graphql`
  mutation FunnelPopupGroupTableActions_DeleteMutation(
    $connections: [ID!]!
    $input: DeletePopupGroupInput!
  ) {
    deletePopupGroup(input: $input) {
      deletedPopupGroupId @deleteEdge(connections: $connections)
    }
  }
`;

const copyMutation = graphql`
  mutation FunnelPopupGroupTableActions_CopyMutation(
    $connections: [ID!]!
    $input: CopyPopupGroupInput!
  ) {
    copyPopupGroup(input: $input) {
      popupGroup
        @appendNode(connections: $connections, edgeTypeName: "PopupGroupEdge") {
        endDate
        id
        memo
        name
        isArchive
        popups(kind: B_PATTERN) {
          totalCount
        }
        page {
          id
          kind
        }
        slug
        status
        startDate
        thumbnailImageUrl
        updatedAt
        judgeStatus
      }
    }
  }
`;

export const FunnelPopupGroupTableActions: FC<Props> = ({
  popupGroup,
  currentValues,
  connectionId,
  filtered,
  siteSlug,
}) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useOpenState();
  const copyModalOpenState = useOpenState();

  const deleteMutate =
    useMutationCommit<FunnelPopupGroupTableActions_DeleteMutation>(
      deleteMutation
    );
  const copyMutate =
    useMutationCommit<FunnelPopupGroupTableActions_CopyMutation>(copyMutation);

  const handleArchive = useArchiveHandler(popupGroup.id, connectionId);
  const handleUnarchive = useUnarchiveHandler(popupGroup.id, connectionId);

  const handleDelete = useCallback(async () => {
    try {
      const res = await deleteMutate({
        variables: {
          input: {
            popupGroupId: popupGroup.id,
          },
          connections: [connectionId],
        },
      });
      const deletedPopupGroupId = res.deletePopupGroup?.deletedPopupGroupId;
      if (!deletedPopupGroupId) throw new Error("assertion failed");

      toast({
        title: `${popupGroup.name}を削除しました`,
        status: "success",
      });
    } catch (err) {
      toast({ title: "削除に失敗しました", status: "error" });
    }
  }, [connectionId, deleteMutate, popupGroup.id, popupGroup.name, toast]);

  const handleCopy = useCallback(
    async (pageId: string) => {
      try {
        const res = await copyMutate({
          variables: {
            input: {
              popupGroupId: popupGroup.id,
              pageId,
            },
            connections: [connectionId],
          },
        });
        const copiedPopupGroup = res.copyPopupGroup?.popupGroup;
        if (!copiedPopupGroup) throw new Error("assertion failed");

        toast({
          title: `${popupGroup.name}をコピーしました`,
          status: "success",
        });
      } catch (err) {
        toast({ title: "コピーに失敗しました", status: "error" });
      }
    },
    [connectionId, copyMutate, popupGroup.id, popupGroup.name, toast]
  );

  const defaultActionMenus = useMemo(
    () => [
      {
        label: "編集",
        icon: <EditOutlinedIcon />,
        to: `/sites/${siteSlug}/popup_groups/${popupGroup.slug}/edit`,
      },
      {
        label: "コピー",
        icon: <ContentCopyOutlinedIcon />,
        onClick: () => copyModalOpenState.onOpen(),
      },
      {
        label: "アーカイブ",
        icon: <ArchiveOutlinedIcon />,
        onClick: () => handleArchive(),
      },
    ],
    [copyModalOpenState, handleArchive, popupGroup.slug, siteSlug]
  );

  const archivedActionMenus = useMemo(
    () => [
      {
        label: "編集",
        icon: <EditOutlinedIcon />,
        to: `/sites/${siteSlug}/popup_groups/${popupGroup.slug}/edit`,
      },
      {
        label: "アーカイブから戻す",
        icon: <UnarchiveOutlinedIcon />,
        onClick: () => handleUnarchive(),
      },
      {
        label: "削除",
        icon: <DeleteOutlineIcon />,
        onClick: () => onOpen(),
      },
    ],
    [handleUnarchive, onOpen, popupGroup.slug, siteSlug]
  );

  return (
    <>
      <TableActions
        actionMenus={filtered ? archivedActionMenus : defaultActionMenus}
      />
      <DeleteConfirmDialog
        name={popupGroup.name}
        isOpen={isOpen}
        onClose={onClose}
        onDelete={handleDelete}
      />
      <CopyModal
        isOpen={copyModalOpenState.isOpen}
        onClose={copyModalOpenState.onClose}
        initialValues={currentValues}
        onSubmit={handleCopy}
      />
    </>
  );
};
