import { VStack } from "@chakra-ui/react";
import { Form, Formik, FormikConfig } from "formik";
import { FC } from "react";

import { UserRole } from "~/src/__generated__/schema";
import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormikFormButtons } from "~/src/components/common/forms/FormikFormButtons";
import { FormikTextField } from "~/src/components/common/forms/FormikTextField";

import { SearchUserFormField } from "./SearchUserFormField";
import {
  SiteCreateFormValues as FormValues,
  siteCreateFormValidationSchema as validationSchema,
} from "./validationSchema";

export type Props = {
  initialValues: FormValues;
  onCancelClick: () => void;
  onSubmit: FormikConfig<FormValues>["onSubmit"];
  role: UserRole;
};

export const SiteCreateForm: FC<Props> = ({
  initialValues,
  onCancelClick,
  onSubmit,
  role,
}) => {
  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      <Form>
        <VStack spacing="16px">
          <FormControl required label="サイト名">
            <FormikTextField type="text" mode="fill" name="name" />
          </FormControl>
          <FormControl required label="URL">
            <FormikTextField type="text" mode="fill" name="url" />
          </FormControl>
          {(role === "ADMIN" || role === "SUPER") && <SearchUserFormField />}
        </VStack>
        <FormikFormButtons onCancelClick={onCancelClick} />
      </Form>
    </Formik>
  );
};
