/**
 * @generated SignedSource<<7a9c0daeaf644804595f9930035ea5e8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FunnelReportTable_site$data = {
  readonly reportFunnel: any;
  readonly " $fragmentType": "FunnelReportTable_site";
};
export type FunnelReportTable_site = FunnelReportTable_site$data;
export type FunnelReportTable_site$key = {
  readonly " $data"?: FunnelReportTable_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"FunnelReportTable_site">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "input"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "FunnelReportTable_site",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "input",
          "variableName": "input"
        }
      ],
      "kind": "ScalarField",
      "name": "reportFunnel",
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "4432db9194b7b69eadba088141857e9a";

export default node;
