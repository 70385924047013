/**
 * @generated SignedSource<<a070e2a243341515b3d9fc4f5ea83c00>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DistributionStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type PageKind = "LANDING" | "FORM" | "CONFIRM" | "THANKS" | "%future added value";
export type PatternContentJudgeStatus = "PENDING" | "APPROVED" | "REJECTED" | "%future added value";
export type PopupLinkFormContainer_Query$variables = {
  siteSlug: string;
  popupGroupSetId: string;
};
export type PopupLinkFormContainer_QueryVariables = PopupLinkFormContainer_Query$variables;
export type PopupLinkFormContainer_Query$data = {
  readonly site: {
    readonly popupGroupSet: {
      readonly popups: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly name: string | null;
            readonly thumbnailImageUrl: string | null;
            readonly status: DistributionStatus;
            readonly judgeStatus: PatternContentJudgeStatus;
            readonly popupGroup: {
              readonly id: string;
              readonly name: string;
            };
            readonly page: {
              readonly id: string;
              readonly name: string;
              readonly kind: PageKind;
              readonly sortNumber: number;
              readonly funnel: {
                readonly id: string;
                readonly name: string;
              };
            };
          } | null;
        } | null> | null;
      };
    };
  };
};
export type PopupLinkFormContainer_QueryResponse = PopupLinkFormContainer_Query$data;
export type PopupLinkFormContainer_Query = {
  variables: PopupLinkFormContainer_QueryVariables;
  response: PopupLinkFormContainer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "popupGroupSetId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "siteSlug"
},
v2 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "siteSlug"
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "popupGroupSetId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = [
  (v4/*: any*/),
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "PopupConnection",
  "kind": "LinkedField",
  "name": "popups",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PopupEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Popup",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "thumbnailImageUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "judgeStatus",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PopupGroup",
              "kind": "LinkedField",
              "name": "popupGroup",
              "plural": false,
              "selections": (v6/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Page",
              "kind": "LinkedField",
              "name": "page",
              "plural": false,
              "selections": [
                (v4/*: any*/),
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "kind",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sortNumber",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Funnel",
                  "kind": "LinkedField",
                  "name": "funnel",
                  "plural": false,
                  "selections": (v6/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PopupLinkFormContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "PopupGroupSet",
            "kind": "LinkedField",
            "name": "popupGroupSet",
            "plural": false,
            "selections": [
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PopupLinkFormContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "PopupGroupSet",
            "kind": "LinkedField",
            "name": "popupGroupSet",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ec2bbed1db4a574695ec677598dee5b8",
    "id": null,
    "metadata": {},
    "name": "PopupLinkFormContainer_Query",
    "operationKind": "query",
    "text": "query PopupLinkFormContainer_Query(\n  $siteSlug: String!\n  $popupGroupSetId: ID!\n) {\n  site(slug: $siteSlug) {\n    popupGroupSet(id: $popupGroupSetId) {\n      popups {\n        edges {\n          node {\n            id\n            name\n            thumbnailImageUrl\n            status\n            judgeStatus\n            popupGroup {\n              id\n              name\n            }\n            page {\n              id\n              name\n              kind\n              sortNumber\n              funnel {\n                id\n                name\n              }\n            }\n          }\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "56282cfd5c6ce68363d8d2d2418cd192";

export default node;
