import { Tag as ChakraTag, TagCloseButton, TagProps } from "@chakra-ui/react";
import { FC, MouseEventHandler } from "react";

import { chakraFactory } from "~/src/lib/chakra-ui";

export type Props = {
  onClose?: MouseEventHandler<HTMLButtonElement>;
} & TagProps;

const StyledWrapperTag = chakraFactory(ChakraTag, {
  baseStyle: {
    background: "#2F68BA",
    fontSize: "10px",
    color: "#FFFFFF",
  },
});

const StyledWrapperTagCloseButton = chakraFactory(TagCloseButton, {
  baseStyle: {
    opacity: 1,
    background: "#FFFFFF",
    color: "#2F68BA",
    width: "16px",
    height: "16px",
    fontSize: "14px",
  },
});

export const Tag: FC<Props> = ({ children, onClose, ...props }) => (
  <StyledWrapperTag {...props}>
    {children}
    {onClose && <StyledWrapperTagCloseButton onClick={onClose} />}
  </StyledWrapperTag>
);
