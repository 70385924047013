import * as yup from "yup";

import { DeliverKind } from "~/src/__generated__/schema";

type DeliverWeight = {
  readonly id: string;
  readonly name: string;
  readonly weight: number;
};

export type FormValues = {
  readonly deliverKind: DeliverKind;
  readonly deliverWeights: readonly DeliverWeight[];
};

const deliverWeightSchema = yup.object({
  id: yup.string().required(),
  weight: yup.number().min(0),
});

export const validationSchema = yup.object({
  deliverKind: yup.string().required(),
  deliverWeights: yup.array().of(deliverWeightSchema),
});
