import { Box, Flex } from "@chakra-ui/react";
import { FC, useMemo, useState } from "react";
import { graphql, usePaginationFragment } from "react-relay";

import { AccountListPage_viewers$key } from "~/src/__relay_artifacts__/AccountListPage_viewers.graphql";
import {
  Table,
  TableHeaderColumn,
  TableHeaderRow,
} from "~/src/components/common/tables/Table";
import {
  TablePagination,
  useTablePaginationProps,
} from "~/src/components/common/tables/TablePagination";
import { TableSearchField } from "~/src/components/common/tables/TableSearchField";
import { PageLayout } from "~/src/components/features/global/PageLayout";
import { useUpdateDebounce } from "~/src/lib/react-use";

import { AccountTableDataRow } from "./AccountTableDataRow";

export type Props = {
  viewersRef: AccountListPage_viewers$key;
};

const fragment = graphql`
  fragment AccountListPage_viewers on Query
  @refetchable(queryName: "AccountListPageRefetchQuery")
  @argumentDefinitions(
    first: { type: "Int", defaultValue: 120 }
    after: { type: "String" }
    searchTerm: { type: "String" }
  ) {
    viewers(first: $first, after: $after, searchTerm: $searchTerm)
      @connection(key: "AccountListPage_viewers") {
      totalCount
      edges {
        node {
          id
          name
          email
          company
          tagScript
        }
      }
    }
  }
`;

export const AccountListPage: FC<Props> = ({ viewersRef }) => {
  const [searchText, setSearchText] = useState("");

  const {
    data: viewers,
    loadNext,
    hasNext,
    refetch,
  } = usePaginationFragment(fragment, viewersRef);

  const { tablePaginationProps } = useTablePaginationProps({
    totalCount: viewers.viewers.totalCount,
    hasNext,
    loadNext,
    refetch,
  });

  const users = useMemo(() => {
    const from = tablePaginationProps.from;
    const to = tablePaginationProps.to;
    const egdes = viewers.viewers.edges || [];
    return egdes.slice(from, to).map((edge) => {
      const node = edge?.node;
      if (!node) throw new Error("assertion failed");
      return node;
    });
  }, [
    viewers.viewers.edges,
    tablePaginationProps.from,
    tablePaginationProps.to,
  ]);

  useUpdateDebounce(
    () =>
      refetch({
        searchTerm: searchText,
        first: tablePaginationProps.perPage,
      }),
    500,
    [refetch, searchText, tablePaginationProps.perPage]
  );

  return (
    <PageLayout title="アカウント一覧">
      <Box my="16px">
        <Flex alignItems="flex-end">
          <TableSearchField searchText={searchText} onChange={setSearchText} />
        </Flex>
        <Table>
          <TableHeaderRow>
            <TableHeaderColumn>名前</TableHeaderColumn>
            <TableHeaderColumn>会社名</TableHeaderColumn>
            <TableHeaderColumn>メールアドレス</TableHeaderColumn>
            <TableHeaderColumn>タグ</TableHeaderColumn>
          </TableHeaderRow>
          {users.map((user, i) => (
            <AccountTableDataRow
              key={i}
              user={{
                name: user.name,
                email: user.email,
                company: user.company,
                tagScript: user.tagScript,
              }}
            />
          ))}
        </Table>
        <TablePagination {...tablePaginationProps} />
      </Box>
    </PageLayout>
  );
};
