import { Text } from "@chakra-ui/layout";
import { FC } from "react";
import { graphql, useFragment } from "react-relay";

import { GraphQLEnums } from "~/src/__generated__/GraphQLEnums";
import { ChatbotDistributionSettingSection_chatbotGroup$key } from "~/src/__relay_artifacts__/ChatbotDistributionSettingSection_chatbotGroup.graphql";
import { Chip } from "~/src/components/common/Chip";
import { DefinitionTeam } from "~/src/components/common/DefinitionTeam";
import { SectionCard } from "~/src/components/common/SectionCard";

type Props = {
  chatbotGroupRef: ChatbotDistributionSettingSection_chatbotGroup$key;
};

const fragment = graphql`
  fragment ChatbotDistributionSettingSection_chatbotGroup on ChatbotGroup {
    sources {
      id
      name
    }
    referrers {
      referrer
      matchOp
    }
    excludeReferrers {
      referrer
      matchOp
    }
    visitCount
    visitMatchType
    deliverTargetUrls {
      id
      url
    }
    deliverExcludeUrls {
      id
      url
    }
    deliverTargetLabels {
      id
      name
    }
    deliverExcludeLabels {
      id
      name
    }
    targetCookies {
      id
      key
      value
      matchType
    }
  }
`;

export const ChatbotDistributionSettingSection: FC<Props> = ({
  chatbotGroupRef,
}) => {
  const chatbotGroup = useFragment(fragment, chatbotGroupRef);

  return (
    <SectionCard description="配信設定">
      <DefinitionTeam label="流入元カテゴリ">
        {chatbotGroup.sources.map((source) => (
          <Chip key={source.id} label={source.name} />
        ))}
      </DefinitionTeam>

      <DefinitionTeam label="流入元">
        {chatbotGroup.referrers.map((referrer, index) => (
          <DefinitionTeam
            key={index}
            dense
            label={
              GraphQLEnums.MatchOp.find((op) => op.value === referrer.matchOp)
                ?.label || ""
            }
          >
            {referrer.referrer}
          </DefinitionTeam>
        ))}
      </DefinitionTeam>

      <DefinitionTeam label="除外流入元">
        {chatbotGroup.excludeReferrers.map((referrer, index) => (
          <DefinitionTeam
            key={index}
            dense
            label={
              GraphQLEnums.MatchOp.find((op) => op.value === referrer.matchOp)
                ?.label || ""
            }
          >
            {referrer.referrer}
          </DefinitionTeam>
        ))}
      </DefinitionTeam>
      <DefinitionTeam label="ユーザーの訪問回数">
        <DefinitionTeam
          dense
          label={
            GraphQLEnums.VisitMatch.find(
              (op) => op.value === chatbotGroup.visitMatchType
            )?.label || ""
          }
        >
          {chatbotGroup.visitCount ? `${chatbotGroup.visitCount} 回` : "未設定"}
        </DefinitionTeam>
      </DefinitionTeam>
      <DefinitionTeam label="URL">
        <Text>{chatbotGroup.deliverTargetUrls.map((url) => url.url)}</Text>
      </DefinitionTeam>
      <DefinitionTeam label="除外URL">
        {chatbotGroup.deliverExcludeUrls.map((url) => (
          <Text key={url.id}>{url.url}</Text>
        ))}
      </DefinitionTeam>
      <DefinitionTeam label="ラベル">
        {chatbotGroup.deliverTargetLabels.map((label) => (
          <Chip key={label.id} label={label.name} />
        ))}
      </DefinitionTeam>
      <DefinitionTeam label="除外ラベル">
        {chatbotGroup.deliverExcludeLabels.map((label) => (
          <Chip key={label.id} label={label.name} />
        ))}
      </DefinitionTeam>
      <DefinitionTeam label="Cookie">
        {chatbotGroup.targetCookies.map((cookie) => (
          <DefinitionTeam
            key={cookie.id}
            dense
            label={cookie.matchType ? "一致" : "一致しない"}
          >
            <DefinitionTeam dense label="key">
              {cookie.key}
            </DefinitionTeam>
            <DefinitionTeam dense label="value">
              {cookie.value}
            </DefinitionTeam>
          </DefinitionTeam>
        ))}
      </DefinitionTeam>
    </SectionCard>
  );
};
