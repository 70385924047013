import {
  DragEventHandler,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactFlow, {
  Background,
  Connection,
  Controls,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  ReactFlowInstance,
  ReactFlowProvider,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  useEdgesState,
  useNodesState,
} from "react-flow-renderer";

import { chakraFactory } from "~/src/lib/chakra-ui";

import { CloseNode } from "./CloseNode";
import { Edge as ButtonEdge } from "./Edge";
import { ImageNode } from "./ImageNode";
import { ReplyNode } from "./ReplyNode";
import { Sidebar } from "./Sidebar";
import { TextNode } from "./TextNode";
import { NodeData } from "./types";

const FormWrapper = chakraFactory("div", {
  baseStyle: {
    flexDirection: "column",
    display: "flex",
    flexGrow: "1",
    height: "640px",
    width: "100%",
    border: "1px solid #DADADA",
  },
});

const FlowWrapper = chakraFactory("div", {
  baseStyle: {
    flexGrow: "1",
    height: "100%",
  },
});

export type Props = {
  defaultNodes?: Node<NodeData>[];
  defaultEdges?: Edge<any>[];
  disabled: boolean;
  flowChange?: (nodes: Node<NodeData>[], edges: Edge<any>[]) => void;
};

const initialNodes: Node<NodeData>[] = [
  {
    id: "launch_chat_message",
    type: "input",
    data: { label: "チャット開始", message: null, replyGroup: null },
    position: { x: 10, y: 10 },
  },
];

export const ChatbotFlowForm: FC<Props> = ({
  disabled,
  defaultNodes,
  defaultEdges,
  flowChange,
}) => {
  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance<
    NodeData,
    any
  > | null>(null);
  const [nodes, setNodes] = useNodesState(defaultNodes || initialNodes);
  const [edges, setEdges] = useEdgesState(defaultEdges || []);

  const handleNodesChange = useCallback(
    (changes: NodeChange[]) => {
      setNodes((nds) => applyNodeChanges(changes, nds));
    },
    [setNodes]
  );

  const handleEdgesChange = useCallback(
    (changes: EdgeChange[]) => {
      setEdges((eds) => applyEdgeChanges(changes, eds));
    },
    [setEdges]
  );

  const handleConnect = useCallback(
    (connection: Connection) => {
      setEdges((eds) => addEdge({ ...connection, type: "buttonEdge" }, eds));
    },
    [setEdges]
  );

  const nodeTypes = useMemo(
    () => ({
      TEXT: TextNode,
      SUBMIT: CloseNode,
      REPLY: ReplyNode,
      IMAGE: ImageNode,
    }),
    []
  );

  const edgeTypes = useMemo(
    () => ({
      buttonEdge: ButtonEdge,
    }),
    []
  );

  const handleUploaded = useCallback(
    (uploadedNodes: Node<NodeData>[], uploadedEdges: Edge<any>[]) => {
      setNodes(uploadedNodes);
      setEdges(uploadedEdges);
    },
    [setNodes, setEdges]
  );

  const handleDrop = useCallback<DragEventHandler<HTMLDivElement>>(
    (e) => {
      e.preventDefault();
      if (!reactFlowWrapper) return;
      if (!reactFlowWrapper.current) return;

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = e.dataTransfer.getData("application/chatbotflow");

      if (typeof type === "undefined" || !type) return;
      if (!reactFlowInstance) return;

      const position = reactFlowInstance.project({
        x: e.clientX - reactFlowBounds.left,
        y: e.clientY - reactFlowBounds.top,
      });

      const node = {
        id: `${nodes.filter((node) => !node.id.includes("_reply_")).length}`,
        type,
        position,
        data: { label: `${type} node`, message: null, replyGroup: null },
      };

      setNodes((nodes) => nodes.concat(node));
    },
    [reactFlowInstance, setNodes, nodes]
  );

  const handleDragOver = useCallback<DragEventHandler<HTMLDivElement>>((e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
  }, []);

  useEffect(() => {
    if (flowChange)
      flowChange(
        nodes.filter((node) => node.id !== "launch_chat_message"),
        edges.filter((edge) => edge.source !== "launch_chat_message")
      );
  }, [nodes, edges, flowChange]);

  return (
    <FormWrapper>
      <ReactFlowProvider>
        <FlowWrapper ref={reactFlowWrapper}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={handleNodesChange}
            onEdgesChange={handleEdgesChange}
            onConnect={handleConnect}
            onInit={setReactFlowInstance}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            fitView
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            nodesConnectable={!disabled}
            nodesDraggable={!disabled}
            elementsSelectable={!disabled}
            panOnDrag={!disabled}
            selectNodesOnDrag={!disabled}
            connectOnClick={!disabled}
          >
            <Controls />
            <Background />
          </ReactFlow>
        </FlowWrapper>
        {!disabled && (
          <Sidebar nodes={nodes} edges={edges} onUploaded={handleUploaded} />
        )}
      </ReactFlowProvider>
    </FormWrapper>
  );
};
