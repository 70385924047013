/**
 * @generated SignedSource<<3c09d27dec21e2abf1bae5d18e9f76e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type AbtestScenarioPageKind = "DEFAULT" | "SCENARIO" | "REDIRECT" | "TEXT_REPLACE" | "%future added value";
export type DistributionStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type PatternContentJudgeStatus = "PENDING" | "APPROVED" | "REJECTED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ABTestPatternTableDataRow_abtestPattern$data = {
  readonly id: string;
  readonly slug: string;
  readonly title: string | null;
  readonly status: DistributionStatus;
  readonly isArchive: boolean;
  readonly uniqueUserCount: number;
  readonly cvUserCount: number;
  readonly cvr: number;
  readonly averageStayTime: number;
  readonly scrollRate: number;
  readonly kind: AbtestScenarioPageKind;
  readonly judgeStatus: PatternContentJudgeStatus;
  readonly weight: number | null;
  readonly " $fragmentType": "ABTestPatternTableDataRow_abtestPattern";
};
export type ABTestPatternTableDataRow_abtestPattern = ABTestPatternTableDataRow_abtestPattern$data;
export type ABTestPatternTableDataRow_abtestPattern$key = {
  readonly " $data"?: ABTestPatternTableDataRow_abtestPattern$data;
  readonly " $fragmentSpreads": FragmentRefs<"ABTestPatternTableDataRow_abtestPattern">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "input"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./ABTestPatternTableDataRow_RefetchQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "ABTestPatternTableDataRow_abtestPattern",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isArchive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "uniqueUserCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "cvUserCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "cvr",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "averageStayTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "scrollRate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "judgeStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weight",
      "storageKey": null
    }
  ],
  "type": "AbtestScenarioPage",
  "abstractKey": null
};
})();

(node as any).hash = "398abde6d8ccba69c869a321a01305e0";

export default node;
