import { Box } from "@chakra-ui/react";
import { FC, useMemo } from "react";

import { PageLayout } from "~/src/components/features/global/PageLayout";

export type Props = {
  siteSlug: string;
};

export const FunnelCreatePage: FC<Props> = ({ children, siteSlug }) => {
  const breadcrumbs = useMemo(
    () => [
      { label: "設定", path: `/sites/${siteSlug}/settings` },
      { label: "ファネル登録", path: `/sites/${siteSlug}/funnels/new` },
    ],
    [siteSlug]
  );

  return (
    <PageLayout title="ファネル登録" breadcrumbs={breadcrumbs}>
      <Box mt="32px">{children}</Box>
    </PageLayout>
  );
};
