import { useToast } from "@chakra-ui/toast";
import { FC, useCallback } from "react";
import { graphql, useFragment } from "react-relay";

import { ExcludedIPListTabContainer_deleteMutation } from "~/src/__relay_artifacts__/ExcludedIPListTabContainer_deleteMutation.graphql";
import { ExcludedIPListTabContainer_site$key } from "~/src/__relay_artifacts__/ExcludedIPListTabContainer_site.graphql";
import { useMutationCommit } from "~/src/lib/react-relay";

import { ExcludedIPListTab, ExcludedIPListTabProps } from "./presentations";
import { useUpdateExcludedIpStatus } from "./useUpdateExcludedIpStatus";

export type Props = {
  siteRef: ExcludedIPListTabContainer_site$key;
};

const siteFragment = graphql`
  fragment ExcludedIPListTabContainer_site on Site {
    ...ExcludedIPListTab_site
  }
`;

const mutation = graphql`
  mutation ExcludedIPListTabContainer_deleteMutation(
    $connections: [ID!]!
    $excludedIpAddressId: ID!
  ) {
    deleteExcludedIpAddress(
      input: { excludedIpAddressId: $excludedIpAddressId }
    ) {
      deletedExcludedIpAddressId @deleteEdge(connections: $connections)
    }
  }
`;

export const ExcludedIPListTabContainer: FC<Props> = ({ siteRef }) => {
  const toast = useToast();
  const site = useFragment(siteFragment, siteRef);
  const { updateExcludedIpStatus } = useUpdateExcludedIpStatus();
  const mutate =
    useMutationCommit<ExcludedIPListTabContainer_deleteMutation>(mutation);

  const handleExcludedIPDeleteClick = useCallback(
    async (excludedIpAddressId: string, connectionId: string) => {
      try {
        const res = await mutate({
          variables: {
            excludedIpAddressId,
            connections: [connectionId],
          },
        });
        const deletedExcludedIpAddressId =
          res.deleteExcludedIpAddress?.deletedExcludedIpAddressId;
        if (!deletedExcludedIpAddressId) throw new Error("assertion faield");

        toast({
          title: `除外IPを削除しました`,
          status: "success",
        });
      } catch (err) {
        toast({ title: "削除に失敗しました", status: "error" });
      }
    },
    [mutate, toast]
  );

  const handleExcludedIPExcludeChange = useCallback<
    ExcludedIPListTabProps["onExcludedIPExcludeChange"]
  >(
    async (excludedIpAddress) => {
      try {
        const { updateExcludedIpAddress } = await updateExcludedIpStatus({
          variables: {
            input: {
              excludedIpAddressId: excludedIpAddress.id,
              status:
                excludedIpAddress.status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
              ipAddress: excludedIpAddress.ipAddress,
            },
          },
        });
        if (!updateExcludedIpAddress?.excludedIpAddress) {
          throw new Error("assertion failed");
        }
        toast({ title: "ステータスを更新しました", status: "success" });
      } catch (err) {
        toast({ title: "ステータスの更新に失敗しました", status: "error" });
      }
    },
    [toast, updateExcludedIpStatus]
  );

  return (
    <ExcludedIPListTab
      siteRef={site}
      onExcludedIPDeleteClick={handleExcludedIPDeleteClick}
      onExcludedIPExcludeChange={handleExcludedIPExcludeChange}
    />
  );
};
