import { Add as AddIcon } from "@mui/icons-material";
import { memo, useCallback } from "react";

import { StyledTableItemIconButton, StyledTableItemWrapperBox } from "./styles";

export type Props = {
  label: string;
  index: number;
  onClick: (index: number) => void;
};

export const SelectableItem = memo<Props>(({ label, index, onClick }) => {
  const handleClick = useCallback(() => onClick(index), [index, onClick]);

  return (
    <StyledTableItemWrapperBox pl="10px" cursor="pointer" onClick={handleClick}>
      {label}
      <StyledTableItemIconButton
        pointerEvents="none"
        size="16px"
        icon={<AddIcon />}
      />
    </StyledTableItemWrapperBox>
  );
});
