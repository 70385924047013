import { Box, HStack, VStack } from "@chakra-ui/layout";
import {
  AddCircle as AddCircleIcon,
  DeleteOutline as DeleteOutlineIcon,
} from "@mui/icons-material";
import {
  FieldArray,
  FieldArrayRenderProps,
  useField,
  useFormikContext,
} from "formik";
import { FC, useCallback } from "react";

import { SymbolOutlineButton } from "~/src/components/common/Button";
import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormikSelectField } from "~/src/components/common/forms/FormikSelectField";
import { FormikTextField } from "~/src/components/common/forms/FormikTextField";
import { IconButton } from "~/src/components/common/IconButton";
import { chakraFactory } from "~/src/lib/chakra-ui";

import { ApiResultForm } from "./ApiResultForm";
import { ReplyFormValues as FormValues, ReplyGroupFormValues } from "./types";

const replyKindOptions = [
  { label: "カスタムテキスト", value: "CUSTOM_TEXT" },
  { label: "API", value: "API" },
  { label: "氏名", value: "NAME" },
];

const LabelWrapper = chakraFactory("p", {
  baseStyle: {
    height: "22px",
    fontSize: "13px",
    color: "#000000",
    alignItems: "center",
    justifyContent: "center",
  },
});

export type Props = {};

export const ReplyForm: FC<Props> = () => {
  const [replies] = useField<FormValues[]>("replies");
  const { values } = useFormikContext<ReplyGroupFormValues>();

  const render = useCallback(
    (arrayHelpers: FieldArrayRenderProps) => (
      <>
        {replies.value &&
          replies.value.length > 0 &&
          replies.value.map((field, index) => (
            <Box border="1px solid #eee" width={"95%"} p="3" key={index}>
              <HStack>
                <LabelWrapper width={"95%"}>リプライ設定</LabelWrapper>
                <IconButton
                  size="24px"
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={() => arrayHelpers.remove(index)}
                  icon={<DeleteOutlineIcon />}
                />
              </HStack>

              <VStack>
                {values.kind === "TEXT_BOX" || values.kind === "TEXT_AREA" ? (
                  <>
                    <FormControl label="タイプ">
                      <FormikSelectField
                        name={`replies.${index}.kind`}
                        options={replyKindOptions}
                      />
                    </FormControl>
                    <FormControl label="データ名">
                      <FormikTextField
                        type="text"
                        mode="fill"
                        name={`replies.${index}.name`}
                      />
                    </FormControl>
                    <FormControl label="プレースホルダ">
                      <FormikTextField
                        type="text"
                        mode="fill"
                        name={`replies.${index}.placeholder`}
                      />
                    </FormControl>
                    <FormControl label="入力時のエラーメッセージ">
                      <FormikTextField
                        type="text"
                        mode="fill"
                        name={`replies.${index}.errorMessage`}
                      />
                    </FormControl>
                    {(field.kind === "CUSTOM_TEXT" || field.kind === "API") && (
                      <FormControl label="正規表現">
                        <FormikTextField
                          type="text"
                          mode="fill"
                          name={`replies.${index}.regularExpression`}
                        />
                      </FormControl>
                    )}
                    {field.kind === "API" && (
                      <>
                        <FormControl label="アクション名">
                          <FormikTextField
                            type="text"
                            mode="fill"
                            name={`replies.${index}.apiAction`}
                          />
                        </FormControl>
                        <ApiResultForm
                          name={`replies.${index}.chatbotApiResults`}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <FormControl label="表示名">
                      <FormikTextField
                        type="text"
                        mode="fill"
                        name={`replies.${index}.label`}
                      />
                    </FormControl>
                    <FormControl label="データ名">
                      <FormikTextField
                        type="text"
                        mode="fill"
                        name={`replies.${index}.name`}
                      />
                    </FormControl>
                    <FormControl label="データの値">
                      <FormikTextField
                        type="text"
                        mode="fill"
                        name={`replies.${index}.value`}
                      />
                    </FormControl>
                  </>
                )}
              </VStack>
            </Box>
          ))}
        <SymbolOutlineButton
          icon={<AddCircleIcon />}
          // eslint-disable-next-line react/jsx-no-bind
          onClick={() => {
            let name = "";
            if (values.replies && values.replies.length > 0) {
              name = values.replies[0].name;
            }
            arrayHelpers.push({
              kind: "OPTION",
              name,
              label: "",
              placeholder: "",
              value: "",
            });
          }}
        >
          リプライ追加
        </SymbolOutlineButton>
      </>
    ),
    [replies, values]
  );

  return <FieldArray name={"replies"} render={render} />;
};
