import { useCallback, useMemo, useState } from "react";

export const useBulkCheckboxes = <T extends { slug: string }>(data: T[]) => {
  const [selectedSlugs, setSelectedSlugs] = useState<string[]>([]);

  const allSlugs = useMemo(() => data.map((item) => item.slug), [data]);

  const handleAllCheckboxClick = useCallback(() => {
    const isAllSlugsChecked = selectedSlugs.length === allSlugs.length;
    const newSelectedSlugs = isAllSlugsChecked ? [] : allSlugs;
    setSelectedSlugs(newSelectedSlugs);
  }, [allSlugs, selectedSlugs.length]);

  const handleCheckboxClick = useCallback(
    (checkedSlug: string) => {
      const newSelectedSlugs = selectedSlugs.includes(checkedSlug)
        ? selectedSlugs.filter((i) => i !== checkedSlug)
        : [...selectedSlugs, checkedSlug];
      setSelectedSlugs(newSelectedSlugs);
    },
    [selectedSlugs]
  );

  return {
    selectedSlugs,
    isAllChecked: allSlugs.length === selectedSlugs.length,
    handleAllCheckboxClick,
    handleCheckboxClick,
  };
};
