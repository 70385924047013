import { Image } from "@chakra-ui/react";
import { FC } from "react";
import { NavLink } from "react-router-dom";

import {
  DistributionStatus,
  GroupContentJudgeStatus,
  PageKind,
  UserRole,
} from "~/src/__generated__/schema";
import {
  TableDataColumn,
  TableDataRow,
} from "~/src/components/common/tables/Table";
import { URLItem } from "~/src/components/common/URLItem";
import { DistributionStatusSwitch } from "~/src/components/features/global/DistributionStatusSwitch";

import { FunnelPopupGroupTableActions } from "./FunnelPopupGroupTableActions";

type PopupGroup = {
  id: string;
  name: string;
  slug: string;
  status: DistributionStatus;
  judgeStatus: GroupContentJudgeStatus | null;
  imageUrl: string | null;
  startDate: string | null;
  endDate: string | null;
  updatedAt: string;
  popupCount: number;
  isArchive: boolean;
};

type Page = {
  id: string;
  kind: PageKind;
  name: string;
  url: string;
  sortNumber: number;
};

type Props = {
  siteSlug: string;
  popupGroup: PopupGroup;
  role: UserRole;
  connectionId: string;
  page: Page;
  funnelId: string;
};

export const FunnelPopupGroupTableDataRow: FC<Props> = ({
  popupGroup,
  siteSlug,
  role,
  connectionId,
  funnelId,
  page,
}) => {
  return (
    <TableDataRow height={"80px"}>
      <TableDataColumn
        type="text"
        isLink
        data={
          <NavLink to={`/sites/${siteSlug}/popup_groups/${popupGroup.slug}`}>
            {popupGroup.name}
          </NavLink>
        }
      ></TableDataColumn>
      <TableDataColumn
        type="actions"
        data={
          <DistributionStatusSwitch
            id={popupGroup.id}
            name={popupGroup.name}
            isChecked={popupGroup.status === "ACTIVE"}
          />
        }
      ></TableDataColumn>
      {role !== "SAAS_CLIENT" && (
        <TableDataColumn
          type="text"
          data={popupGroup.judgeStatus === "APPROVED" ? "承認" : "未承認"}
        />
      )}
      <TableDataColumn
        type="text"
        data={
          popupGroup.imageUrl && (
            <Image src={popupGroup.imageUrl} boxSize={"60px"} fit={"contain"} />
          )
        }
      />
      <TableDataColumn
        type="text"
        data={
          <URLItem
            url={page.url}
            name={
              page.kind !== "FORM"
                ? page.name
                : page.name + "(" + (page.sortNumber - 1) + ")"
            }
          />
        }
      />
      <TableDataColumn type="text" data={popupGroup.popupCount} />
      <TableDataColumn type="date" data={popupGroup.startDate} />
      <TableDataColumn type="date" data={popupGroup.endDate} />
      <TableDataColumn type="date" data={popupGroup.updatedAt} />
      <TableDataColumn
        type="actions"
        data={
          <FunnelPopupGroupTableActions
            popupGroup={{
              id: popupGroup.id,
              name: popupGroup.name,
              slug: popupGroup.slug,
            }}
            siteSlug={siteSlug}
            filtered={popupGroup.isArchive}
            connectionId={connectionId}
            currentValues={{
              funnelId,
              pageId: page.id,
              pageKind: page.kind,
            }}
          />
        }
      />
    </TableDataRow>
  );
};
