import { createElement } from "react";

const newlineRegex = /(\r\n|\r|\n)/g;

export const nl2br = (str: string) => {
  return str.split(newlineRegex).map((line) => {
    if (line.match(newlineRegex)) {
      return createElement("br");
    }
    return line;
  });
};
