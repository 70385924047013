import { Box } from "@chakra-ui/react";
import {
  ArrowDownward as ArrowDownwardIcon,
  ArrowUpward as ArrowUpwardIcon,
} from "@mui/icons-material";
import { FC, useCallback } from "react";

import { IconButton } from "~/src/components/common/IconButton";
import { chakraFactory } from "~/src/lib/chakra-ui";
import { useHover } from "~/src/lib/hooks";

import { columnStyles } from "../styles";

export type SortParams<F, D> = {
  field: F;
  direction: D;
};

export type Props = {
  className?: string;
  field?: string;
  onSortClick?: <F, D>(params: SortParams<F, D>) => void;
};

const StyledHeaderColumnBox = chakraFactory<{ isHover: boolean }>(Box, {
  baseStyle: (props) => ({
    ...columnStyles,
    position: "relative",
    justifyContent: "center",
    fontWeight: props.isHover ? "700" : "500",
    fontSize: "12px",
    lineHeight: "17px",
  }),
});

const StyledSortIconButton = chakraFactory(IconButton, {
  baseStyle: {
    position: "absolute",
    top: 0,
    right: "13px",
    bottom: 0,
    margin: "auto",
  },
});

export const TableHeaderColumn: FC<Props> = ({
  children,
  className,
  field,
  onSortClick,
}) => {
  const [ref, isHover] = useHover<HTMLDivElement>();

  const handleAscClick = useCallback(() => {
    if (!onSortClick || !field) return;
    onSortClick({ field, direction: "ASC" });
  }, [field, onSortClick]);

  const handleDescClick = useCallback(() => {
    if (!onSortClick || !field) return;
    onSortClick({ field, direction: "DESC" });
  }, [field, onSortClick]);

  return (
    <StyledHeaderColumnBox ref={ref} className={className} isHover={isHover}>
      {children}
      {!!onSortClick && !!field && isHover && (
        <>
          <StyledSortIconButton
            size="16px"
            icon={<ArrowUpwardIcon />}
            onClick={handleAscClick}
            bottom="12px"
          />
          <StyledSortIconButton
            size="16px"
            icon={<ArrowDownwardIcon />}
            onClick={handleDescClick}
            top="12px"
          />
        </>
      )}
    </StyledHeaderColumnBox>
  );
};
