import { object, string } from "yup";

export type FormValues = {
  email: string;
  password: string;
};

export const validationSchema = object({
  email: string().email().required(),
  password: string().required(),
});
