import { concat } from "ramda";
import { FC, useMemo } from "react";
import { Edge, Node } from "react-flow-renderer";
import { graphql, useFragment } from "react-relay";

import { ChatbotFlowContainer_chatbot$key } from "~/src/__relay_artifacts__/ChatbotFlowContainer_chatbot.graphql";
import {
  NodeData,
  ChatbotFlowForm as Renderer,
} from "~/src/components/features/chatbot/ChatbotFlowForms";

export type Props = {
  chatbotRef: ChatbotFlowContainer_chatbot$key;
};

const searchTarget = (
  edges: { source: string; target: string }[],
  currentEdge: { source: string; target: string }
) => {
  let targetEdge = currentEdge;
  edges.forEach((e) => {
    if (currentEdge.source === e.target) {
      targetEdge = searchTarget(edges, e);
    }
  });
  return targetEdge;
};

const fragment = graphql`
  fragment ChatbotFlowContainer_chatbot on Chatbot {
    chatMessageFlowNodes {
      nodeId
      kind
      position {
        x
        y
      }
      chatMessage {
        id
        kind
        message
        isHasReply
        isSaveReply
        isInheritQuery
        isTransferReply
        displaySpeed
        coordinate
        messageIndex
        nextMessageIndex
        submitUrl
      }
      replyGroup {
        id
        kind
        label
        number
        divergingPoint
        coordinate
        replies {
          id
          kind
          messageIndex
          nextMessageIndex
          errorMessage
          label
          name
          value
          regularExpression
          placeholder
          replyNodeId
          apiAction
        }
      }
    }
    chatMessageReplyFlowNodes {
      nodeId
      nodeLabel
      parentNode
      kind
    }
    chatMessageFlowEdges {
      source
      target
    }
    chatMessageReplyFlowEdges {
      source
      target
    }
  }
`;

const initialNode: Node<NodeData> = {
  id: "launch_chat_message",
  type: "input",
  data: { label: "チャット開始", message: null, replyGroup: null },
  position: { x: 0, y: 0 },
};

const initialEdge: Edge<any> = {
  id: "reactflow__edge-launch_chat_message-launch_chat_message",
  source: "launch_chat_message",
  target: "1",
  type: "buttonEdge",
};

export const ChatbotFlowContainer: FC<Props> = ({ chatbotRef }) => {
  const chatbot = useFragment(fragment, chatbotRef);

  const nodes = useMemo(() => {
    const chatMessageNodes = chatbot.chatMessageFlowNodes.map((node) => {
      return {
        id: node.nodeId,
        type: node.kind,
        data: {
          label: `${node.kind} node`,
          message: node.chatMessage,
          replyGroup: node.replyGroup,
          reply: null,
        },
        position: { x: node.position.x, y: node.position.y },
      } as Node<NodeData>;
    });
    const chatMessageReplyNodes = chatbot.chatMessageReplyFlowNodes.map(
      (node, index) => {
        return {
          id: node.nodeId,
          type: node.kind,
          position: { x: 15, y: 70 + 40 * (index + 1) },
          data: {
            label: node.nodeLabel,
          },
          parentNode: node.parentNode,
        } as Node<NodeData>;
      }
    );
    const nodes = concat(chatMessageNodes, chatMessageReplyNodes);
    if (nodes.length === 0) return [initialNode];

    const minX = chatMessageNodes.reduce(
      (prev, curr) => (prev > curr.position.x ? curr.position.x : prev),
      10000
    );
    const minY = chatMessageNodes.reduce(
      (prev, curr) => (prev > curr.position.y ? curr.position.y : prev),
      10000
    );
    initialNode.position.x = minX - 100;
    initialNode.position.y = minY - 100;
    return concat([initialNode], nodes);
  }, [chatbot]);

  const edges = useMemo(() => {
    const chatMessageEdges = chatbot.chatMessageFlowEdges.map((edge) => {
      return {
        id: "reactflow__edge-" + edge.source,
        type: "buttonEdge",
        ...edge,
      } as Edge<any>;
    });
    const chatMessageReplyEdges = chatbot.chatMessageReplyFlowEdges.map(
      (edge) => {
        return {
          id: "reactflow__edge-" + edge.source,
          type: "buttonEdge",
          ...edge,
        } as Edge<any>;
      }
    );
    const edges = concat(chatMessageEdges, chatMessageReplyEdges);
    if (edges.length === 0) return [initialEdge];

    const mergedEdges: { source: string; target: string }[] = [];
    chatbot.chatMessageFlowEdges.forEach((edge) =>
      mergedEdges.push({ source: edge.source, target: edge.target })
    );
    chatbot.chatMessageReplyFlowEdges.forEach((edge) =>
      mergedEdges.push({ source: edge.source, target: edge.target })
    );

    if (mergedEdges.length > 0) {
      const targetEdge = searchTarget(mergedEdges, mergedEdges[0]);
      initialEdge.target = targetEdge.source;
    }

    return concat([initialEdge], edges);
  }, [chatbot]);

  return <Renderer disabled defaultNodes={nodes} defaultEdges={edges} />;
};
