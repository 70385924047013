export const allTableItems = [
  { label: "実施ファネル", value: "funnelTitle", orderField: "FUNNEL_TITLE" },
  { label: "実施ページ", value: "funnelPage" },
  { label: "パターン数", value: "patternCount" },
  { label: "開始日", value: "periodSince" },
  { label: "終了日", value: "periodTill" },
  { label: "最終更新日", value: "updatedAt", orderField: "UPDATED_AT" },
  { label: "メモ", value: "memo" },
];

export const initialSelectedTableItems = allTableItems;
