/**
 * @generated SignedSource<<7e04ac0b84dced45a3d66896f2d676cd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AgeRange = "LT12" | "GT12LT19" | "GT20LT24" | "GT25LT29" | "GT30LT34" | "GT35LT39" | "GT40LT44" | "GT45LT49" | "GT50LT54" | "GT55LT59" | "%future added value";
export type Browser = "IE" | "CHROME" | "SAFARI" | "FIREFOX" | "EDGE" | "OTHER" | "%future added value";
export type DeliverKind = "EQUAL" | "WEIGHT" | "%future added value";
export type Device = "MOBILE" | "TABLET" | "PC" | "%future added value";
export type Gender = "MALE" | "FEMALE" | "%future added value";
export type Platform = "IOS" | "WINDOWS" | "ANDROID" | "MAC" | "LINUX" | "OTHER" | "%future added value";
export type ReportAnalyzerInput = {
  startOn: string;
  endOn: string;
  device?: Device | null;
  sourceId?: string | null;
  funnelId?: string | null;
};
export type ChatbotDetailPageContainer_Query$variables = {
  slug: string;
  analyzerInput: ReportAnalyzerInput;
};
export type ChatbotDetailPageContainer_QueryVariables = ChatbotDetailPageContainer_Query$variables;
export type ChatbotDetailPageContainer_Query$data = {
  readonly chatbotGroup: {
    readonly deliverKind: DeliverKind;
    readonly id: string;
    readonly name: string;
    readonly slug: string;
    readonly page: {
      readonly name: string;
      readonly slug: string;
      readonly funnel: {
        readonly name: string;
        readonly slug: string;
      };
    };
    readonly devices: ReadonlyArray<Device>;
    readonly browsers: ReadonlyArray<Browser>;
    readonly platforms: ReadonlyArray<Platform>;
    readonly ageRanges: ReadonlyArray<AgeRange>;
    readonly genders: ReadonlyArray<Gender>;
    readonly isArchive: boolean;
    readonly repeat: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"ChatbotPatternTable_chatbotGroup" | "ChatbotDistributionSettingSection_chatbotGroup" | "ChatbotDeliverDayOfWeeks_chatbotGroup">;
  } | null;
};
export type ChatbotDetailPageContainer_QueryResponse = ChatbotDetailPageContainer_Query$data;
export type ChatbotDetailPageContainer_Query = {
  variables: ChatbotDetailPageContainer_QueryVariables;
  response: ChatbotDetailPageContainer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "analyzerInput"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "slug"
},
v2 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deliverKind",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "devices",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "browsers",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "platforms",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ageRanges",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "genders",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isArchive",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "repeat",
  "storageKey": null
},
v14 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 120
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": {
      "direction": "ASC",
      "field": "KIND"
    }
  }
],
v15 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "analyzerInput"
  }
],
v16 = [
  (v4/*: any*/),
  (v5/*: any*/)
],
v17 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "referrer",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "matchOp",
    "storageKey": null
  }
],
v18 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ChatbotDetailPageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "ChatbotGroup",
        "kind": "LinkedField",
        "name": "chatbotGroup",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Page",
            "kind": "LinkedField",
            "name": "page",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Funnel",
                "kind": "LinkedField",
                "name": "funnel",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          {
            "args": [
              {
                "kind": "Variable",
                "name": "analyzerInput",
                "variableName": "analyzerInput"
              }
            ],
            "kind": "FragmentSpread",
            "name": "ChatbotPatternTable_chatbotGroup"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ChatbotDistributionSettingSection_chatbotGroup"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ChatbotDeliverDayOfWeeks_chatbotGroup"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ChatbotDetailPageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "ChatbotGroup",
        "kind": "LinkedField",
        "name": "chatbotGroup",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Page",
            "kind": "LinkedField",
            "name": "page",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Funnel",
                "kind": "LinkedField",
                "name": "funnel",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          {
            "alias": null,
            "args": (v14/*: any*/),
            "concreteType": "ChatbotConnection",
            "kind": "LinkedField",
            "name": "chatbots",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ChatbotEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Chatbot",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v6/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "weight",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      (v12/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "kind",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": (v15/*: any*/),
                        "kind": "ScalarField",
                        "name": "pvUserCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": (v15/*: any*/),
                        "kind": "ScalarField",
                        "name": "startUserCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": (v15/*: any*/),
                        "kind": "ScalarField",
                        "name": "cvUserCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": (v15/*: any*/),
                        "kind": "ScalarField",
                        "name": "ctr",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": (v15/*: any*/),
                        "kind": "ScalarField",
                        "name": "cvr",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "kind": "ClientExtension",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__id",
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": "chatbots(first:120,orderBy:{\"direction\":\"ASC\",\"field\":\"KIND\"})"
          },
          {
            "alias": null,
            "args": (v14/*: any*/),
            "filters": [
              "withArchived",
              "orderBy",
              "status"
            ],
            "handle": "connection",
            "key": "ChatbotPatternTable_chatbotGroup_chatbots",
            "kind": "LinkedHandle",
            "name": "chatbots"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Source",
            "kind": "LinkedField",
            "name": "sources",
            "plural": true,
            "selections": (v16/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ChatbotGroupReferrer",
            "kind": "LinkedField",
            "name": "referrers",
            "plural": true,
            "selections": (v17/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ChatbotGroupExcludeReferrer",
            "kind": "LinkedField",
            "name": "excludeReferrers",
            "plural": true,
            "selections": (v17/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "visitCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "visitMatchType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ChatbotGroupDeliverTargetUrl",
            "kind": "LinkedField",
            "name": "deliverTargetUrls",
            "plural": true,
            "selections": (v18/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ChatbotGroupDeliverExcludeUrl",
            "kind": "LinkedField",
            "name": "deliverExcludeUrls",
            "plural": true,
            "selections": (v18/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserLabel",
            "kind": "LinkedField",
            "name": "deliverTargetLabels",
            "plural": true,
            "selections": (v16/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserLabel",
            "kind": "LinkedField",
            "name": "deliverExcludeLabels",
            "plural": true,
            "selections": (v16/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ChatbotGroupTargetCookie",
            "kind": "LinkedField",
            "name": "targetCookies",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "matchType",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DeliverDayOfWeek",
            "kind": "LinkedField",
            "name": "deliverDayOfWeeks",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "weekOfMonth",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "daysOfWeek",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c1668ddfc12a7f587652efaa9f48d0b9",
    "id": null,
    "metadata": {},
    "name": "ChatbotDetailPageContainer_Query",
    "operationKind": "query",
    "text": "query ChatbotDetailPageContainer_Query(\n  $slug: String!\n  $analyzerInput: ReportAnalyzerInput!\n) {\n  chatbotGroup(slug: $slug) {\n    deliverKind\n    id\n    name\n    slug\n    page {\n      name\n      slug\n      funnel {\n        name\n        slug\n        id\n      }\n      id\n    }\n    devices\n    browsers\n    platforms\n    ageRanges\n    genders\n    isArchive\n    repeat\n    ...ChatbotPatternTable_chatbotGroup_4AzHBv\n    ...ChatbotDistributionSettingSection_chatbotGroup\n    ...ChatbotDeliverDayOfWeeks_chatbotGroup\n  }\n}\n\nfragment ChatbotDeliverDayOfWeeks_chatbotGroup on ChatbotGroup {\n  startDate\n  startTime\n  endDate\n  endTime\n  deliverDayOfWeeks {\n    weekOfMonth\n    daysOfWeek\n  }\n}\n\nfragment ChatbotDistributionSettingSection_chatbotGroup on ChatbotGroup {\n  sources {\n    id\n    name\n  }\n  referrers {\n    referrer\n    matchOp\n  }\n  excludeReferrers {\n    referrer\n    matchOp\n  }\n  visitCount\n  visitMatchType\n  deliverTargetUrls {\n    id\n    url\n  }\n  deliverExcludeUrls {\n    id\n    url\n  }\n  deliverTargetLabels {\n    id\n    name\n  }\n  deliverExcludeLabels {\n    id\n    name\n  }\n  targetCookies {\n    id\n    key\n    value\n    matchType\n  }\n}\n\nfragment ChatbotPatternTableDataRow_chatbot_9vDjd on Chatbot {\n  id\n  slug\n  name\n  status\n  isArchive\n  weight\n  kind\n  pvUserCount(input: $analyzerInput)\n  startUserCount(input: $analyzerInput)\n  cvUserCount(input: $analyzerInput)\n  ctr(input: $analyzerInput)\n  cvr(input: $analyzerInput)\n}\n\nfragment ChatbotPatternTable_chatbotGroup_4AzHBv on ChatbotGroup {\n  slug\n  chatbots(first: 120, orderBy: {field: KIND, direction: ASC}) {\n    totalCount\n    edges {\n      node {\n        id\n        slug\n        name\n        weight\n        ...ChatbotPatternTableDataRow_chatbot_9vDjd\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "82a8eaec9e288c950d3f024c9c7a731c";

export default node;
