/**
 * @generated SignedSource<<200de1679b33653e04ae95bd22ccdc2d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type DistributionStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type PatternContentJudgeStatus = "PENDING" | "APPROVED" | "REJECTED" | "%future added value";
export type PopupKind = "A_PATTERN" | "B_PATTERN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PopupPatternTableDataRow_popup$data = {
  readonly id: string;
  readonly slug: string;
  readonly name: string | null;
  readonly status: DistributionStatus;
  readonly isArchive: boolean;
  readonly kind: PopupKind | null;
  readonly judgeStatus: PatternContentJudgeStatus;
  readonly weight: number | null;
  readonly clickCvr: number;
  readonly clickCvUserCount: number;
  readonly clickUserCount: number;
  readonly clickUserRate: number;
  readonly cvr: number;
  readonly impressionCvUserCount: number;
  readonly impressionCvr: number;
  readonly impressionUserCount: number;
  readonly readyCvUserCount: number;
  readonly totalCvUserCount: number;
  readonly totalUserCount: number;
  readonly thumbnailImageUrl: string | null;
  readonly page: {
    readonly slug: string;
    readonly name: string;
    readonly funnel: {
      readonly name: string;
      readonly slug: string;
    };
  };
  readonly " $fragmentType": "PopupPatternTableDataRow_popup";
};
export type PopupPatternTableDataRow_popup = PopupPatternTableDataRow_popup$data;
export type PopupPatternTableDataRow_popup$key = {
  readonly " $data"?: PopupPatternTableDataRow_popup$data;
  readonly " $fragmentSpreads": FragmentRefs<"PopupPatternTableDataRow_popup">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "input"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./PopupPatternTableDataRow_RefetchQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "PopupPatternTableDataRow_popup",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isArchive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "judgeStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v2/*: any*/),
      "kind": "ScalarField",
      "name": "clickCvr",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v2/*: any*/),
      "kind": "ScalarField",
      "name": "clickCvUserCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v2/*: any*/),
      "kind": "ScalarField",
      "name": "clickUserCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v2/*: any*/),
      "kind": "ScalarField",
      "name": "clickUserRate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v2/*: any*/),
      "kind": "ScalarField",
      "name": "cvr",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v2/*: any*/),
      "kind": "ScalarField",
      "name": "impressionCvUserCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v2/*: any*/),
      "kind": "ScalarField",
      "name": "impressionCvr",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v2/*: any*/),
      "kind": "ScalarField",
      "name": "impressionUserCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v2/*: any*/),
      "kind": "ScalarField",
      "name": "readyCvUserCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v2/*: any*/),
      "kind": "ScalarField",
      "name": "totalCvUserCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v2/*: any*/),
      "kind": "ScalarField",
      "name": "totalUserCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnailImageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Page",
      "kind": "LinkedField",
      "name": "page",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Funnel",
          "kind": "LinkedField",
          "name": "funnel",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Popup",
  "abstractKey": null
};
})();

(node as any).hash = "d533e48d776399d265b331849074b8ab";

export default node;
