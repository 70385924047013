import { Progress, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { formatNumber } from "accounting";
import { FC } from "react";

export type Props = {
  rows: Data[];
};

type Data = {
  pageName: string;
  uniqueUser: number;
  kind: "LANDING" | "FORM" | "CONFIRM" | "THANKS";
};

const headers = ["ページ", "UU数", "到達率"];

const calculateForwardRatio = (targetRow: Data, rows: Data[]): number => {
  const landingPageUniqueUser =
    rows.find((row) => row.kind === "LANDING")?.uniqueUser || 0;

  if (targetRow.kind === "LANDING") return 100;

  const result = (targetRow.uniqueUser / landingPageUniqueUser) * 100;
  if (isNaN(result)) return 0;
  return Math.round(result);
};

export const FunnelTable: FC<Props> = ({ rows }) => {
  return (
    <Table
      size="md"
      border={"1px solid #DADADA"}
      marginTop={"1%"}
      marginBottom={"1%"}
      alignItems={"center"}
      w={"100%"}
    >
      <Thead background={"#FAFAFA"}>
        <Tr>
          {headers.map((header, index) => (
            <Th key={index} border={"1px solid #DADADA"} textAlign={"center"}>
              {header}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {rows.map((row, index) => (
          <Tr key={index}>
            <Td border={"1px solid #DADADA"} textAlign={"center"}>
              {row.pageName}
            </Td>
            <Td border={"1px solid #DADADA"} textAlign={"center"}>
              {formatNumber(row.uniqueUser)}
              <Progress
                colorScheme={"twitter"}
                hasStripe
                size="md"
                value={calculateForwardRatio(row, rows)}
                marginTop={"2"}
              />
            </Td>
            <Td border={"1px solid #DADADA"} textAlign={"center"}>
              {`${calculateForwardRatio(row, rows)}%`}
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};
