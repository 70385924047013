import { useToast } from "@chakra-ui/toast";
import { useCallback } from "react";
import { graphql } from "react-relay";

import { useUnarchiveHandler_Mutation } from "~/src/__relay_artifacts__/useUnarchiveHandler_Mutation.graphql";
import { useMutationCommit } from "~/src/lib/react-relay";

const mutation = graphql`
  mutation useUnarchiveHandler_Mutation(
    $input: UnarchiveArchivableInput!
    $connections: [ID!]!
  ) {
    unarchiveArchivable(input: $input) {
      archivable {
        id @deleteEdge(connections: $connections)
        isArchive
      }
    }
  }
`;

export const useUnarchiveHandler = (id: string, connectionId: string) => {
  const toast = useToast();
  const mutate = useMutationCommit<useUnarchiveHandler_Mutation>(mutation);
  const handleUnarchive = useCallback(async () => {
    try {
      const res = await mutate({
        variables: {
          input: {
            archivableId: id,
          },
          connections: [connectionId],
        },
      });
      const archivable = res.unarchiveArchivable?.archivable;
      if (!archivable) throw new Error("assertion failed");

      toast({
        title: "アーカイブを取り消しました",
        status: "success",
      });
    } catch (err) {
      toast({
        title: "アーカイブを取り消しに失敗しました",
        status: "error",
      });
    }
  }, [connectionId, id, mutate, toast]);

  return handleUnarchive;
};
