import { Box, VStack } from "@chakra-ui/react";
import { AddCircle as AddCircleIcon } from "@mui/icons-material";
import { useField } from "formik";
import * as R from "ramda";
import { FC, Fragment, useCallback } from "react";

import { GraphQLEnums } from "~/src/__generated__/GraphQLEnums";
import { SymbolOutlineButton } from "~/src/components/common/Button";
import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormikSelectField } from "~/src/components/common/forms/FormikSelectField";
import { FormikSwitchField } from "~/src/components/common/forms/FormikSwitchField";
import { FormikTextField } from "~/src/components/common/forms/FormikTextField";

import { defaultFormUrlsValue } from "../constants";
import { FormValues } from "../validationSchema";

import { FormUrlsFieldSeparator } from "./FormUrlsFieldSeparator";

export const FormUrlsField: FC = () => {
  const [{ value }, , { setValue }] =
    useField<FormValues["formUrls"]>("formUrls");

  const handleAddClick = useCallback(() => {
    setValue([...value, defaultFormUrlsValue]);
  }, [setValue, value]);

  const handleDeleteClick = useCallback(
    (index: number) => {
      setValue(R.remove(index, 1, value));
    },
    [setValue, value]
  );

  return (
    <VStack width="100%" spacing="16px" alignItems="flex-start">
      {value.map((_, i) => (
        <Fragment key={`formUrls[${i}]`}>
          {i > 0 && (
            <FormUrlsFieldSeparator
              index={i}
              onDeleteClick={handleDeleteClick}
            />
          )}
          <VStack width="100%" key={`formUrls[${i}]`} spacing="16px">
            <FormControl label="PC用URL">
              <FormikTextField
                mode="fill"
                type="text"
                name={`formUrls[${i}].formUrlForPC`}
              />
            </FormControl>
            <FormControl label="モバイル用URL">
              <FormikTextField
                mode="fill"
                type="text"
                name={`formUrls[${i}].formUrlForMobile`}
              />
            </FormControl>
            <FormControl label="タブレット用URL">
              <FormikTextField
                mode="fill"
                type="text"
                name={`formUrls[${i}].formUrlForTablet`}
              />
            </FormControl>
            <FormControl label="URLの一致タイプ">
              <FormikSelectField
                mode="fill"
                name={`formUrls[${i}].formUrlMatchType`}
                options={GraphQLEnums.UrlMatch}
              />
            </FormControl>
            <FormControl label="ホワイトアウトを無効にする">
              <FormikSwitchField
                name={`formUrls[${i}].formIsDisableWhiteout`}
              />
            </FormControl>
          </VStack>
        </Fragment>
      ))}
      <Box width="100%">
        <SymbolOutlineButton
          ml="260px"
          icon={<AddCircleIcon />}
          onClick={handleAddClick}
        >
          ページの追加
        </SymbolOutlineButton>
      </Box>
    </VStack>
  );
};
