/**
 * @generated SignedSource<<59f5ed89fe964c5d50daba1277bc4d23>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JudgementListPageContainer_Query$variables = {
  startDate?: string | null;
  endDate?: string | null;
};
export type JudgementListPageContainer_QueryVariables = JudgementListPageContainer_Query$variables;
export type JudgementListPageContainer_Query$data = {
  readonly viewer: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"JudgementListPage_viewer">;
  };
};
export type JudgementListPageContainer_QueryResponse = JudgementListPageContainer_Query$data;
export type JudgementListPageContainer_Query = {
  variables: JudgementListPageContainer_QueryVariables;
  response: JudgementListPageContainer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "endDate",
    "variableName": "endDate"
  },
  {
    "kind": "Variable",
    "name": "startDate",
    "variableName": "startDate"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageUrl",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = [
  (v7/*: any*/),
  (v2/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Page",
  "kind": "LinkedField",
  "name": "page",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Funnel",
      "kind": "LinkedField",
      "name": "funnel",
      "plural": false,
      "selections": (v8/*: any*/),
      "storageKey": null
    },
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "JudgementListPageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "JudgementListPage_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "JudgementListPageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "AbtestScenarioPageConnection",
            "kind": "LinkedField",
            "name": "wipAbtestScenarioPages",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AbtestScenarioPageEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AbtestScenarioPage",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AbtestScenarioPageGroup",
                        "kind": "LinkedField",
                        "name": "abtestScenarioPageGroup",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AbtestScenarioPageContentConnection",
                        "kind": "LinkedField",
                        "name": "abtestScenarioPageContents",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AbtestScenarioPageContentEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "__typename",
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v5/*: any*/),
                                      (v6/*: any*/)
                                    ],
                                    "type": "AbtestScenarioPageContentScenario",
                                    "abstractKey": null
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v2/*: any*/)
                                    ],
                                    "type": "Node",
                                    "abstractKey": "__isNode"
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "PopupConnection",
            "kind": "LinkedField",
            "name": "wipPopups",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PopupEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Popup",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PopupGroup",
                        "kind": "LinkedField",
                        "name": "popupGroup",
                        "plural": false,
                        "selections": (v8/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PopupImage",
                        "kind": "LinkedField",
                        "name": "popupImages",
                        "plural": true,
                        "selections": [
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4bd14e3fbc9d508c1e7c930b3fd295b2",
    "id": null,
    "metadata": {},
    "name": "JudgementListPageContainer_Query",
    "operationKind": "query",
    "text": "query JudgementListPageContainer_Query(\n  $startDate: ISO8601Date\n  $endDate: ISO8601Date\n) {\n  viewer {\n    id\n    ...JudgementListPage_viewer\n  }\n}\n\nfragment JudgementListPage_viewer on User {\n  wipAbtestScenarioPages(startDate: $startDate, endDate: $endDate) {\n    edges {\n      node {\n        id\n        title\n        abtestScenarioPageGroup {\n          title\n          id\n        }\n        abtestScenarioPageContents {\n          edges {\n            node {\n              __typename\n              ... on AbtestScenarioPageContentScenario {\n                imageUrl\n                image\n              }\n              ... on Node {\n                __isNode: __typename\n                id\n              }\n            }\n          }\n        }\n        page {\n          name\n          url\n          funnel {\n            name\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n  wipPopups(startDate: $startDate, endDate: $endDate) {\n    edges {\n      node {\n        id\n        name\n        popupGroup {\n          name\n          id\n        }\n        popupImages {\n          imageUrl\n          image\n          id\n        }\n        page {\n          name\n          url\n          funnel {\n            name\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "520c619822e13fbddbf809fc429a76f3";

export default node;
