import { Flex } from "@chakra-ui/react";
import { format } from "date-fns";
import { useCallback, useContext, useState } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";

import { AbtestReportOrderInput } from "~/src/__generated__/schema";
import { ABTestPatternNameReportContainer_Query } from "~/src/__relay_artifacts__/ABTestPatternNameReportContainer_Query.graphql";
import { DateRangeContext } from "~/src/components/features/global/HeaderNavigation/DateRangeField";
import { useABTestReportFilterCache } from "~/src/lib/hooks";

import {
  ABTestPatternNameReport,
  ABTestPatternNameReportFilterButton,
  ABTestReportFilterFormValues as FormValues,
} from "./presentations";

const query = graphql`
  query ABTestPatternNameReportContainer_Query(
    $kind: AbtestReportKindInput!
    $filter: AbtestReportFilterInput!
    $order: AbtestReportOrderInput
    $slug: String!
  ) {
    site(slug: $slug) {
      ...ABTestPatternNameReport_site
      abtestScenarioPageGroups {
        edges {
          node {
            id
            title
            abtestScenarioPages {
              edges {
                node {
                  id
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const ABTestPatternNameReportContainer = () => {
  const { siteSlug = "" } = useParams();

  const [sort, setSort] = useState<AbtestReportOrderInput>({
    value: "UNIQUE_USER",
    isDesc: false,
  });

  const initialFilterValues: FormValues = {
    sourceId: null,
    device: null,
    abtestScenarioPageTitles: [],
    funnelIds: [],
    pageKind: null,
    abtestScenarioPageIds: [],
  };

  const { filterValues, setFilterValues } = useABTestReportFilterCache({
    cacheKey: `ABTestPatternNameReport_${siteSlug}`,
    initialFilterValues,
  });

  const {
    dateRange: [startOn, endOn],
  } = useContext(DateRangeContext);

  const handleSortClick = useCallback((params: any) => {
    setSort({
      value: params.field,
      isDesc: params.direction === "DESC" ? true : false,
    });
  }, []);

  const { site } = useLazyLoadQuery<ABTestPatternNameReportContainer_Query>(
    query,
    {
      kind: {
        value: "ABTEST_TITLE",
        isDateExist: false,
      },
      filter: {
        startDate: format(startOn, "yyyy-MM-dd"),
        endDate: format(endOn, "yyyy-MM-dd"),
        source: filterValues.sourceId,
        device: filterValues.device,
        abtestScenarioPageTitles: filterValues.abtestScenarioPageTitles,
      },
      order: sort,
      slug: siteSlug,
    }
  );

  return (
    <>
      <Flex justifyContent={"end"}>
        <ABTestPatternNameReportFilterButton
          filterValues={filterValues}
          onSubmit={setFilterValues}
        />
      </Flex>
      <ABTestPatternNameReport siteRef={site} onSortClick={handleSortClick} />
    </>
  );
};
