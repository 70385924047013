/**
 * @generated SignedSource<<74c9969fd9857ed253ff25c1fd182450>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type HasForm = "HAS_FORM" | "NO_FORM" | "%future added value";
export type QueryMatch = "EQUAL_MATCH" | "INCLUDING_MATCH" | "PREFIX_MATCH" | "%future added value";
export type UrlMatch = "EQUAL_TYPE" | "HAS_ID_TYPE" | "EQUAL_OR_HAS_ID_TYPE" | "STARTS_WITH_PREFIX_MATCH" | "REGEX_MATCH_TYPE" | "%future added value";
export type CreateFunnelInput = {
  clientMutationId?: string | null;
  name: string;
  isExperiment: boolean;
  siteId: string;
  landingPageAttributes: PageAttributes;
  formPageAttributes?: ReadonlyArray<PageAttributes> | null;
  confirmPageAttributes?: PageAttributes | null;
  thanksPageAttributes: PageAttributes;
};
export type PageAttributes = {
  id?: string | null;
  name: string;
  url: string;
  mobileUrl?: string | null;
  tabletUrl?: string | null;
  hasForm?: HasForm | null;
  urlMatchType: UrlMatch;
  queryMatchType?: QueryMatch | null;
  isDisableWhiteout?: boolean | null;
};
export type FunnelBulkCreateTabContainer_Mutation$variables = {
  input: CreateFunnelInput;
};
export type FunnelBulkCreateTabContainer_MutationVariables = FunnelBulkCreateTabContainer_Mutation$variables;
export type FunnelBulkCreateTabContainer_Mutation$data = {
  readonly createFunnel: {
    readonly funnelEdge: {
      readonly node: {
        readonly id: string;
        readonly slug: string;
        readonly name: string;
        readonly isExperiment: boolean;
        readonly landingPage: {
          readonly id: string;
          readonly name: string;
          readonly url: string;
          readonly mobileUrl: string | null;
          readonly tabletUrl: string | null;
          readonly hasForm: HasForm | null;
          readonly urlMatchType: UrlMatch;
        } | null;
        readonly thanksPage: {
          readonly id: string;
          readonly name: string;
          readonly url: string;
          readonly mobileUrl: string | null;
          readonly tabletUrl: string | null;
          readonly urlMatchType: UrlMatch;
        } | null;
        readonly confirmPage: {
          readonly id: string;
          readonly name: string;
          readonly url: string;
          readonly mobileUrl: string | null;
          readonly tabletUrl: string | null;
          readonly urlMatchType: UrlMatch;
        } | null;
        readonly formPage: ReadonlyArray<{
          readonly id: string;
          readonly name: string;
          readonly url: string;
          readonly mobileUrl: string | null;
          readonly tabletUrl: string | null;
          readonly urlMatchType: UrlMatch;
        }> | null;
      } | null;
    } | null;
  } | null;
};
export type FunnelBulkCreateTabContainer_MutationResponse = FunnelBulkCreateTabContainer_Mutation$data;
export type FunnelBulkCreateTabContainer_Mutation = {
  variables: FunnelBulkCreateTabContainer_MutationVariables;
  response: FunnelBulkCreateTabContainer_Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mobileUrl",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tabletUrl",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urlMatchType",
  "storageKey": null
},
v7 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/)
],
v8 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateFunnelPayload",
    "kind": "LinkedField",
    "name": "createFunnel",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FunnelEdge",
        "kind": "LinkedField",
        "name": "funnelEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Funnel",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slug",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isExperiment",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "landingPage",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasForm",
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "thanksPage",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "confirmPage",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "formPage",
                "plural": true,
                "selections": (v7/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FunnelBulkCreateTabContainer_Mutation",
    "selections": (v8/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FunnelBulkCreateTabContainer_Mutation",
    "selections": (v8/*: any*/)
  },
  "params": {
    "cacheID": "dd8acddacc98d01762e62b8f45c5889f",
    "id": null,
    "metadata": {},
    "name": "FunnelBulkCreateTabContainer_Mutation",
    "operationKind": "mutation",
    "text": "mutation FunnelBulkCreateTabContainer_Mutation(\n  $input: CreateFunnelInput!\n) {\n  createFunnel(input: $input) {\n    funnelEdge {\n      node {\n        id\n        slug\n        name\n        isExperiment\n        landingPage {\n          id\n          name\n          url\n          mobileUrl\n          tabletUrl\n          hasForm\n          urlMatchType\n        }\n        thanksPage {\n          id\n          name\n          url\n          mobileUrl\n          tabletUrl\n          urlMatchType\n        }\n        confirmPage {\n          id\n          name\n          url\n          mobileUrl\n          tabletUrl\n          urlMatchType\n        }\n        formPage {\n          id\n          name\n          url\n          mobileUrl\n          tabletUrl\n          urlMatchType\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bc3aed701f66790d11dc3c037fd41cdf";

export default node;
