import {
  Flex,
  Popover,
  PopoverTrigger,
  Portal,
  Text,
  VStack,
} from "@chakra-ui/react";
import { AccountCircleOutlined as AccountCircleOutlinedIcon } from "@mui/icons-material";
import { FC } from "react";
import { NavLink } from "react-router-dom";

import { UserRole } from "~/src/__generated__/schema";
import { IconButton } from "~/src/components/common/IconButton";
import { PopoverContent } from "~/src/components/common/PopoverContent";
import { HorizontalSeparator } from "~/src/components/common/Separator";
import { chakraFactory } from "~/src/lib/chakra-ui";

type Props = {
  companyName: string;
  email: string;
  onLogoutClick: () => void;
  role: UserRole;
};

const StyledPopoverContent = chakraFactory(PopoverContent, {
  baseStyle: {
    width: "190px",
    height: "260px",
    padding: "24px",
  },
});

const StyledProfileIcon = chakraFactory(AccountCircleOutlinedIcon, {
  baseStyle: {
    width: "40px !important",
    height: "40px !important",
    color: "#1867C0",
  },
});

const StyledText = chakraFactory(Text, {
  baseStyle: {
    fontSize: "11px",
    lineHeight: "19px",
    letterSpacing: "0.04em",
    textAlign: "center",
    color: "#282828",
  },
});

const StyledLink = chakraFactory(NavLink, {
  baseStyle: {
    fontSize: "13px",
    lineHeight: "15px",
    alignSelf: "start",
    letterSpacing: "0.04em",
    color: " #282828",

    "&:hover": {
      textDecoration: "underline",
    },
  },
});

export const AccountProfileButton: FC<Props> = ({
  companyName,
  email,
  onLogoutClick,
  role,
}) => (
  <Popover placement="bottom-end">
    {({ isOpen, onClose }) => (
      <>
        <PopoverTrigger>
          <IconButton
            label="account profile"
            size="28px"
            isActive={isOpen}
            icon={<AccountCircleOutlinedIcon />}
            tooltipPlacement="bottom-end"
          />
        </PopoverTrigger>
        {isOpen && (
          <Portal>
            <StyledPopoverContent>
              <Flex justifyContent="center" w="100%" mb="16px">
                <StyledProfileIcon />
              </Flex>
              <StyledText>{companyName}</StyledText>
              <StyledText>{email}</StyledText>
              <HorizontalSeparator my="16px" />
              <VStack spacing="16px">
                <StyledLink to="/account" onClick={onClose}>
                  アカウント設定
                </StyledLink>
                <StyledLink to="/" onClick={onLogoutClick}>
                  ログアウト
                </StyledLink>
                {role === "JUDGE" && (
                  <StyledLink to="/judgements" onClick={onClose}>
                    広告承認
                  </StyledLink>
                )}
              </VStack>
            </StyledPopoverContent>
          </Portal>
        )}
      </>
    )}
  </Popover>
);
