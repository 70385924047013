import {
  Box,
  Flex,
  HStack,
  Image,
  Link,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FC } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { NavLink, useParams } from "react-router-dom";

import { GraphQLEnums } from "~/src/__generated__/GraphQLEnums";
import { ChatbotPatternDetailPageContainer_Query } from "~/src/__relay_artifacts__/ChatbotPatternDetailPageContainer_Query.graphql";
import { OutlineButton } from "~/src/components/common/Button";
import { DefinitionTeam } from "~/src/components/common/DefinitionTeam";
import { SectionCard } from "~/src/components/common/SectionCard";
import { HorizontalSeparator } from "~/src/components/common/Separator";
import { PageLayout } from "~/src/components/features/global/PageLayout";
import { chakraFactory } from "~/src/lib/chakra-ui";

import { ChatbotFlowContainer } from "./presentations";

export type Props = {};

const query = graphql`
  query ChatbotPatternDetailPageContainer_Query($slug: String!) {
    chatbot(slug: $slug) {
      id
      actionKind
      botImageAlt
      botImageCache
      botImageUrl
      botKind
      botTextColor
      botBackgroundColor
      chatbotGroup {
        id
      }
      colorKind
      chatFormColor
      clientTextColor
      clientBackgroundColor
      displayKind
      honeycombCode
      kind
      name
      notFoundMessage
      openButtonText
      openButtonImageCache
      openButtonImageUrl
      page {
        id
      }
      slug
      status
      title
      titleTextColor
      timing
      toFormButtonImageCache
      toFormButtonImageUrl
      weight
      submitMethodKind
      xOffset
      xOffsetUnit
      yOffset
      yOffsetUnit
      windowColor
      questionFinishMessage
      questionCsvUrl
      toFormButtonImageUrl
      ...ChatbotFlowContainer_chatbot
    }
  }
`;

const StyledLink = chakraFactory(Link, {
  baseStyle: {
    color: "#1867C0",
  },
});

export const ChatbotPatternDetailPageContainer: FC<Props> = () => {
  const { patternSlug = "", chatbotSlug = "", siteSlug = "" } = useParams();
  const { chatbot } = useLazyLoadQuery<ChatbotPatternDetailPageContainer_Query>(
    query,
    { slug: patternSlug }
  );

  if (!chatbot) throw new Error("assertion failed");

  const {
    actionKind,
    botImageUrl,
    botKind,
    colorKind,
    displayKind,
    name,
    openButtonText,
    openButtonImageUrl,
    notFoundMessage,
    submitMethodKind,
    title,
    weight,
    xOffset,
    xOffsetUnit,
    yOffset,
    yOffsetUnit,
    questionFinishMessage,
    questionCsvUrl,
    toFormButtonImageUrl,
  } = chatbot;

  return (
    <PageLayout
      title={"チャットボットパターン詳細"}
      breadcrumbs={[
        { label: "チャットボット一覧", path: `/sites/${siteSlug}/chatbots` },
        {
          label: "チャットボット詳細",
          path: `/sites/${siteSlug}/chatbots/${chatbotSlug}`,
        },
        {
          label: "パターン登録",
          path: `/sites/${siteSlug}/chatbots/${chatbotSlug}/patterns/${patternSlug}`,
        },
      ]}
    >
      <Box my={4} mx={4}>
        <Flex>
          <Box color={"#282828"} fontWeight={"bold"}>
            {name}
          </Box>
          <Spacer />
          <HStack spacing={3}>
            <OutlineButton>削除</OutlineButton>
            <NavLink
              to={`/sites/${siteSlug}/chatbots/${chatbotSlug}/patterns/${patternSlug}/edit`}
            >
              <OutlineButton>編集</OutlineButton>
            </NavLink>
          </HStack>
        </Flex>
        <Box my={4} />
        <SectionCard>
          <DefinitionTeam label="パターン名">{name}</DefinitionTeam>
          <DefinitionTeam label="配信比率">{weight}</DefinitionTeam>

          <Box pt={4} pb={4}>
            <HorizontalSeparator />
          </Box>

          <DefinitionTeam label="表示場所">
            <VStack alignItems={"flex-start"}>
              <Text>
                {
                  GraphQLEnums.ChatbotDisplayKind.find(
                    (d) => d.value === displayKind
                  )?.label
                }
              </Text>
              <Text>
                {`画面${
                  displayKind?.split("_")[0] === "LEFT" ? "左" : "右"
                }端から: ${xOffset}${xOffsetUnit}`}
              </Text>
              <Text>
                {`
                画面${
                  displayKind?.split("_")[1] === "TOP" ? "上" : "下"
                }端から: ${yOffset}${yOffsetUnit}
                `}
              </Text>
            </VStack>
          </DefinitionTeam>
          <DefinitionTeam label="表示タイミング">{actionKind}</DefinitionTeam>
          <DefinitionTeam label="配色デザイン">{colorKind}</DefinitionTeam>

          {/* 旧UI: ボット表示ボタンのテキスト */}
          <DefinitionTeam label="ボタンに表示するテキスト">
            {openButtonText}
          </DefinitionTeam>

          {/* 旧UI: ボット表示ボタン画像 */}
          <DefinitionTeam label="ボタンに表示する画像">
            <img src={openButtonImageUrl} alt="ボタンに表示する画像" />
          </DefinitionTeam>

          {/* 旧UI: タイトル */}
          <DefinitionTeam label="ボットヘッダーに表示するテキスト">
            {title}
          </DefinitionTeam>

          {/* 旧UI: ボット画像	 */}
          <DefinitionTeam label="画像">
            <img src={botImageUrl} alt="トークアイコン画像" />
          </DefinitionTeam>

          <Box pt={4} pb={4}>
            <HorizontalSeparator />
          </Box>

          <DefinitionTeam label="ボットの種類">
            {
              GraphQLEnums.ChatbotBotKind.find((d) => d.value === botKind)
                ?.label
            }
          </DefinitionTeam>
          {botKind === "FORM" && (
            <DefinitionTeam label="フォーム送信メソッド">
              {submitMethodKind}
            </DefinitionTeam>
          )}
          {botKind === "QUESTION" && (
            <>
              <DefinitionTeam label="質問データ">
                <StyledLink href={questionCsvUrl}>
                  運用中のCSVをダウンロード
                </StyledLink>
              </DefinitionTeam>
              <DefinitionTeam label="質問が見つからない時のメッセージ">
                {notFoundMessage}
              </DefinitionTeam>
              <DefinitionTeam label="質問終了時のメッセージ">
                {questionFinishMessage}
              </DefinitionTeam>
            </>
          )}
          {(botKind === "QUESTION_AND_FORM" ||
            botKind === "FORM_AND_QUESTION") && (
            <>
              <DefinitionTeam label="フォーム送信メソッド">
                {submitMethodKind}
              </DefinitionTeam>
              <DefinitionTeam label={"質問からフォームに\n移動するボタン画像"}>
                <Image
                  boxSize="300px"
                  src={toFormButtonImageUrl}
                  alt="質問からフォームに移動するボタン画像"
                />
              </DefinitionTeam>
              <DefinitionTeam label="質問データ">
                <StyledLink href={questionCsvUrl}>
                  運用中のCSVをダウンロード
                </StyledLink>
              </DefinitionTeam>
              <DefinitionTeam label="質問が見つからない時のメッセージ">
                {notFoundMessage}
              </DefinitionTeam>
              <DefinitionTeam label="質問終了時のメッセージ">
                {questionFinishMessage}
              </DefinitionTeam>
            </>
          )}
          {botKind !== "QUESTION" && (
            <ChatbotFlowContainer chatbotRef={chatbot} />
          )}
        </SectionCard>
      </Box>
    </PageLayout>
  );
};
