import { Box, Flex, HStack } from "@chakra-ui/react";
import { FC, useMemo } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";

import { PopupSetDetailPageContainer_Query } from "~/src/__relay_artifacts__/PopupSetDetailPageContainer_Query.graphql";
import { PageLayout } from "~/src/components/features/global/PageLayout";

import {
  PopupSetDetailLinkedPopupListTable,
  PopupSetDetailSection,
} from "./presentations";

export type Props = {};

const query = graphql`
  query PopupSetDetailPageContainer_Query(
    $siteSlug: String!
    $popupGroupSetId: ID!
    $popupSetId: ID!
  ) {
    site(slug: $siteSlug) {
      popupGroupSet(id: $popupGroupSetId) {
        id
        name
        popupSet(id: $popupSetId) {
          id
          name
          memo
          ...PopupSetDetailLinkedPopupListTable_popupSet
        }
      }
    }
  }
`;

export const PopupSetDetailPageContainer: FC<Props> = () => {
  const { siteSlug = "", popupGroupSetId = "", popupSetId = "" } = useParams();
  const { site } = useLazyLoadQuery<PopupSetDetailPageContainer_Query>(query, {
    siteSlug,
    popupGroupSetId,
    popupSetId,
  });

  const popupSet = useMemo(() => {
    return site.popupGroupSet.popupSet;
  }, [site.popupGroupSet.popupSet]);

  const breadcrumbs = useMemo(
    () => [
      {
        label: "ポップアップセット一覧",
        path: `/sites/${siteSlug}/popup_group_sets`,
      },
      {
        label: "ポップアップセット詳細",
        path: `/sites/${siteSlug}/popup_group_sets/${popupGroupSetId}`,
      },
      {
        label: "ポップアップパターンセット一覧",
        path: `/sites/${siteSlug}/popup_group_sets/${popupGroupSetId}/popup_sets`,
      },
    ],
    [popupGroupSetId, siteSlug]
  );

  return (
    <PageLayout
      title="ポップアップパターンセット詳細"
      breadcrumbs={breadcrumbs}
    >
      <Box mx={4}>
        <Flex alignItems="center">
          <HStack spacing={3}>{/* 編集 */}</HStack>
        </Flex>
        <HStack justifyContent="flex-end" my={4} spacing={4}></HStack>
        <Flex alignItems="center">
          <Box color="#282828" fontWeight="bold">
            {popupSet.name}
          </Box>
        </Flex>
        <PopupSetDetailSection popupSet={popupSet} />
        <PopupSetDetailLinkedPopupListTable
          siteSlug={siteSlug}
          popupGroupSetId={site.popupGroupSet.id}
          popupSetRef={site.popupGroupSet.popupSet}
        />
      </Box>
    </PageLayout>
  );
};
