import { VStack } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { FC } from "react";

import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormikCheckboxField } from "~/src/components/common/forms/FormikCheckboxField";
import { FormikFormButtons } from "~/src/components/common/forms/FormikFormButtons";
import { FormikTextField } from "~/src/components/common/forms/FormikTextField";

import { ChatMessageImageForm } from "./ChatMessageImageForm";
import { MessageFormField } from "./MessageFormField";
import { ChatMessageFormValues as FormValues } from "./types";
import { ChatMessageValidationSchema as validationSchema } from "./validationSchema";

export type Props = {
  initialValues: FormValues;
  handleSubmit: (values: FormValues) => void;
  handleClose: () => void;
};

export const ChatMessageFormContainer: FC<Props> = ({
  initialValues,
  handleSubmit,
  handleClose,
}) => {
  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={false}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      <Form>
        <VStack spacing="16px">
          <MessageFormField />
          {initialValues.kind === "IMAGE" && <ChatMessageImageForm />}
          {initialValues.kind === "SUBMIT" ? (
            <>
              <FormControl label="URL">
                <FormikTextField type="text" mode="fill" name="submitUrl" />
              </FormControl>
              <FormControl label="クエリパラメータを引き継ぐ">
                <FormikCheckboxField name="isInheritQuery" />
              </FormControl>
            </>
          ) : (
            <>
              <FormControl label="リプライを保存する">
                <FormikCheckboxField name="isSaveReply" />
              </FormControl>
              <FormControl label="リプライを送信する">
                <FormikCheckboxField name="isTransferReply" />
              </FormControl>
            </>
          )}
          <FormControl label="表示スピード">
            <FormikTextField type="number" mode="fill" name="displaySpeed" />
          </FormControl>
        </VStack>
        <FormikFormButtons onCancelClick={handleClose} />
      </Form>
    </Formik>
  );
};
