import { ButtonProps } from "@chakra-ui/react";
import { FC } from "react";

import { chakraFactory } from "~/src/lib/chakra-ui";

import { SolidButton } from "./SolidButton";

export type Props = {
  icon: React.ReactNode;
} & ButtonProps;

const StyledSolidButton = chakraFactory(SolidButton, {
  baseStyle: {
    height: "30px",
    minHeight: "30px",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "10px",
    color: "#ffffff",
    px: "8px",

    svg: {
      mr: "4px",
      width: "14px",
      height: "14px",
    },
  },
});

export const SymbolSolidButton: FC<Props> = ({ children, icon, ...props }) => (
  <StyledSolidButton {...props}>
    {icon}
    {children}
  </StyledSolidButton>
);
