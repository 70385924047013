import { Icon } from "@chakra-ui/react";
import { InsertInvitation as InsertInvitationIcon } from "@mui/icons-material";
import { DateRange } from "@mui/lab";
import * as React from "react";

import { DateRangePicker } from "~/src/components/common/datepicker/DateRangePicker";
import { chakraFactory } from "~/src/lib/chakra-ui";
import { useOpenState } from "~/src/lib/hooks";
import { noop } from "~/src/lib/utils";

const isNonNullDateRange = (
  dateRange: DateRange<Date>
): dateRange is [Date, Date] => {
  return dateRange[0] !== null && dateRange[1] !== null ? true : false;
};

const StyledIcon = chakraFactory(Icon, {
  baseStyle: {
    width: "28px !important",
    height: "28px !important",
    padding: "2px",
  },
});

type DateRangeContextProps = {
  dateRange: [Date, Date];
  setDateRange: React.Dispatch<React.SetStateAction<[Date, Date]>>;
};

export const DateRangeContext = React.createContext(
  {} as DateRangeContextProps
);

export const DateRangeField: React.FC = () => {
  const { isOpen, onOpen, onClose } = useOpenState();
  const { dateRange, setDateRange } = React.useContext(DateRangeContext);

  const handleChange = React.useCallback(
    (value: DateRange<Date>) => {
      if (isNonNullDateRange(value)) {
        setDateRange(value);
      }
    },
    [setDateRange]
  );

  return (
    <>
      <StyledIcon
        mr="8px"
        as={InsertInvitationIcon}
        color={isOpen ? "#1867C0" : undefined}
      />
      {isNonNullDateRange(dateRange) && (
        <DateRangePicker
          value={dateRange}
          isOpen={isOpen}
          onClose={onClose}
          onClick={onOpen}
          onChange={noop}
          onAccept={handleChange}
        />
      )}
    </>
  );
};
