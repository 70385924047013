import { FC } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";

import { ABTestPatternEditPageContainer_Query } from "~/src/__relay_artifacts__/ABTestPatternEditPageContainer_Query.graphql";

import { ABTestPatternEditScreenContainer } from "./ABTestPatternEditScreenContainer";

export type Props = {};

const query = graphql`
  query ABTestPatternEditPageContainer_Query(
    $slug: String!
    $groupSlug: String!
  ) {
    abtestScenarioPage(slug: $slug) {
      page {
        id
      }
      ...ABTestPatternEditScreenContainer_abtestScenarioPage
    }
    abtestScenarioPageGroup(slug: $groupSlug) {
      ...ABTestPatternEditScreenContainer_abtestScenarioPageGroup
    }
  }
`;

export const ABTestPatternEditPageContainer: FC<Props> = () => {
  const { abtestSlug = "", patternSlug = "" } = useParams();

  const { abtestScenarioPage, abtestScenarioPageGroup } =
    useLazyLoadQuery<ABTestPatternEditPageContainer_Query>(
      query,
      {
        slug: patternSlug,
        groupSlug: abtestSlug,
      },
      { fetchPolicy: "network-only" }
    );

  if (
    !abtestScenarioPage ||
    !abtestScenarioPage.page ||
    !abtestScenarioPageGroup
  )
    throw new Error("assertion failed");

  return (
    <ABTestPatternEditScreenContainer
      abtestScenarioPageRef={abtestScenarioPage}
      abtestScenarioPageGroupRef={abtestScenarioPageGroup}
    />
  );
};
