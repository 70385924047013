/**
 * @generated SignedSource<<b8a20c8848412e4779ad6203fdf214b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TransferSourceTagsInput = {
  clientMutationId?: string | null;
  funnelId: string;
  sourceTagIds: ReadonlyArray<string>;
};
export type TransferSourceTagsModalContainer_TransferMutation$variables = {
  input: TransferSourceTagsInput;
};
export type TransferSourceTagsModalContainer_TransferMutationVariables = TransferSourceTagsModalContainer_TransferMutation$variables;
export type TransferSourceTagsModalContainer_TransferMutation$data = {
  readonly transferSourceTags: {
    readonly sourceTags: ReadonlyArray<{
      readonly id: string;
      readonly key: string | null;
      readonly pageUrl: string;
      readonly slug: string;
      readonly value: string | null;
    }> | null;
    readonly failedTransferSourceTags: ReadonlyArray<{
      readonly id: string;
      readonly key: string | null;
      readonly value: string | null;
      readonly source: {
        readonly name: string;
      };
    }> | null;
  } | null;
};
export type TransferSourceTagsModalContainer_TransferMutationResponse = TransferSourceTagsModalContainer_TransferMutation$data;
export type TransferSourceTagsModalContainer_TransferMutation = {
  variables: TransferSourceTagsModalContainer_TransferMutationVariables;
  response: TransferSourceTagsModalContainer_TransferMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "SourceTag",
  "kind": "LinkedField",
  "name": "sourceTags",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    (v4/*: any*/)
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TransferSourceTagsModalContainer_TransferMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TransferSourceTagsPayload",
        "kind": "LinkedField",
        "name": "transferSourceTags",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SourceTag",
            "kind": "LinkedField",
            "name": "failedTransferSourceTags",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Source",
                "kind": "LinkedField",
                "name": "source",
                "plural": false,
                "selections": [
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TransferSourceTagsModalContainer_TransferMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TransferSourceTagsPayload",
        "kind": "LinkedField",
        "name": "transferSourceTags",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SourceTag",
            "kind": "LinkedField",
            "name": "failedTransferSourceTags",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Source",
                "kind": "LinkedField",
                "name": "source",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2425408ac57d11fb97fda88b6106426e",
    "id": null,
    "metadata": {},
    "name": "TransferSourceTagsModalContainer_TransferMutation",
    "operationKind": "mutation",
    "text": "mutation TransferSourceTagsModalContainer_TransferMutation(\n  $input: TransferSourceTagsInput!\n) {\n  transferSourceTags(input: $input) {\n    sourceTags {\n      id\n      key\n      pageUrl\n      slug\n      value\n    }\n    failedTransferSourceTags {\n      id\n      key\n      value\n      source {\n        name\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "42cd560f28b6d10c8747f8935cc4ffe6";

export default node;
