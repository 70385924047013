import { Box, SimpleGrid } from "@chakra-ui/react";
import { FC, useMemo } from "react";

import { GraphQLEnums } from "~/src/__generated__/GraphQLEnums";
// import {
//   AbtestScenarioPageContentTextReplaceActionKind,
//   AbtestScenarioPageContentTextReplaceTargetKind,
// } from "~/src/__generated__/schema";
import {
  AbtestScenarioPageContentTextReplaceActionKind,
  AbtestScenarioPageContentTextReplaceTargetKind,
} from "~/src/__relay_artifacts__/ABTestPatternDetailPageContainer_Query.graphql";
import { DefinitionTeam } from "~/src/components/common/DefinitionTeam";
import { HorizontalSeparator } from "~/src/components/common/Separator";

type TextReplaceContent = {
  readonly targetType?:
    | AbtestScenarioPageContentTextReplaceTargetKind
    | undefined;
  readonly targetValue?: string | undefined;
  readonly actionType?:
    | AbtestScenarioPageContentTextReplaceActionKind
    | undefined;
  readonly targetAttribute?: string | undefined;
  readonly replaceValue?: string | null | undefined;
};

export type Props = {
  textReplaceContents: TextReplaceContent[];
};

export const ABTestPatternTextReplaceDetailContainer: FC<Props> = ({
  textReplaceContents,
}) => {
  const actionTypes = useMemo(() => {
    const map: Record<string, any> = {};
    GraphQLEnums.AbtestScenarioPageContentTextReplaceActionKind.forEach(
      (kind) => {
        map[kind.value] = kind.label;
      }
    );
    return map;
  }, []);

  const targetTypes = useMemo(() => {
    const map: Record<string, any> = {};
    GraphQLEnums.AbtestScenarioPageContentTextReplaceTargetKind.forEach(
      (kind) => {
        map[kind.value] = kind.label;
      }
    );
    return map;
  }, []);
  return (
    <>
      <DefinitionTeam label="テストの種類">テキスト置換</DefinitionTeam>
      <HorizontalSeparator />

      {textReplaceContents.map((content, index) => (
        <Box marginTop={"15px"} key={index}>
          <DefinitionTeam label="ターゲット" key={index}>
            <SimpleGrid
              columns={3}
              spacing={4}
              alignSelf={"baseline"}
              width="80%"
            >
              <Box>{"変更対象"}</Box>
              <Box>
                {content.targetType === undefined
                  ? ""
                  : targetTypes[content.targetType]}
              </Box>
              <Box>{content.targetValue || ""}</Box>
              <Box>{"変更内容"}</Box>
              <Box>
                {content.actionType === undefined
                  ? ""
                  : actionTypes[content.actionType]}
              </Box>
              <Box>{content.replaceValue || ""}</Box>
            </SimpleGrid>
          </DefinitionTeam>
          <HorizontalSeparator />
        </Box>
      ))}
    </>
  );
};
