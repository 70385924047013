import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Flex,
  ListItem,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/toast";
import { Form, Formik } from "formik";
import { FC, useCallback, useMemo, useState } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";
import * as yup from "yup";

import { TransferSourceTagsModalContainer_Query } from "~/src/__relay_artifacts__/TransferSourceTagsModalContainer_Query.graphql";
import { TransferSourceTagsModalContainer_TransferMutation } from "~/src/__relay_artifacts__/TransferSourceTagsModalContainer_TransferMutation.graphql";
import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormikFormButtons } from "~/src/components/common/forms/FormikFormButtons";
import { FormikSelectField } from "~/src/components/common/forms/FormikSelectField";
import { useMutationCommit } from "~/src/lib/react-relay";

export type FormValues = {
  funnelId: string;
};

export type Props = {
  onClose: () => void;
  sourceTagIds: string[];
  initialValues: FormValues;
};

type FailedTransferSourceTag = {
  id: string;
  key: string | null;
  value: string | null;
  sourceName: string;
};

const query = graphql`
  query TransferSourceTagsModalContainer_Query($siteSlug: String!) {
    site(slug: $siteSlug) {
      pages {
        edges {
          node {
            id
            name
            kind
            sortNumber
            funnel {
              id
              name
            }
          }
        }
      }
      funnels {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

const transferMutation = graphql`
  mutation TransferSourceTagsModalContainer_TransferMutation(
    $input: TransferSourceTagsInput!
  ) {
    transferSourceTags(input: $input) {
      sourceTags {
        id
        key
        pageUrl
        slug
        value
      }
      failedTransferSourceTags {
        id
        key
        value
        source {
          name
        }
      }
    }
  }
`;

export const TransferSourceTagsModalContainer: FC<Props> = ({
  sourceTagIds,
  initialValues,
  onClose,
}) => {
  const toast = useToast();
  const { siteSlug = "" } = useParams();
  const { site } = useLazyLoadQuery<TransferSourceTagsModalContainer_Query>(
    query,
    {
      siteSlug,
    }
  );
  const [funnelValue, setFunnelValue] = useState<string>(
    initialValues.funnelId
  );
  const [failedTransferSourceTags, setFailedTransferSourceTags] = useState<
    FailedTransferSourceTag[]
  >([]);

  const funnelOptions = useMemo(
    () => [
      ...(site.funnels.edges || []).map((edge) => {
        const node = edge?.node;
        if (!node) throw new Error("assertion failed");
        return { label: node.name, value: node.id };
      }),
    ],
    [site.funnels.edges]
  );

  const validationSchema = yup.object({
    funnelId: yup.string().nullable(),
  });

  const transferMutate =
    useMutationCommit<TransferSourceTagsModalContainer_TransferMutation>(
      transferMutation
    );

  const handleSubmit = useCallback(async () => {
    try {
      const res = await transferMutate({
        variables: {
          input: {
            funnelId: funnelValue,
            sourceTagIds,
          },
        },
      });

      if (
        res.transferSourceTags?.failedTransferSourceTags &&
        res.transferSourceTags?.failedTransferSourceTags.length > 0
      ) {
        const _failedTransferSourceTags =
          res.transferSourceTags.failedTransferSourceTags.map((s) => {
            return {
              id: s.id,
              key: s.key,
              value: s.value,
              sourceName: s.source.name,
            };
          });
        setFailedTransferSourceTags(_failedTransferSourceTags);
        throw new Error(
          "重複している入稿用リンクがありますので一部の入稿用リンクをコピーできませんでした"
        );
      }

      toast({
        title: "入稿用リンクが正常にコピーされました",
        status: "success",
      });
      onClose();
    } catch (err) {
      toast({ title: "コピーに失敗しました: " + err.message, status: "error" });
    }
    return Promise.resolve();
  }, [funnelValue, sourceTagIds, transferMutate, onClose, toast]);

  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={false}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      <Form>
        <VStack spacing="16px">
          <FormControl label="ファネル">
            <FormikSelectField
              name="funnelId"
              options={funnelOptions}
              onSetStateAction={setFunnelValue}
            />
          </FormControl>
          <FormControl
            label={`コピーするアイテムの合計：${sourceTagIds.length}`}
          ></FormControl>
        </VStack>
        {failedTransferSourceTags.length > 0 &&
          sourceTagIds.length > failedTransferSourceTags.length && (
            <Alert
              status="success"
              variant="left-accent"
              fontSize="13px"
              mb={2}
            >
              <AlertIcon />
              <AlertTitle>
                {sourceTagIds.length - failedTransferSourceTags.length}
                つの入稿用リンクを正常にコピーしました
              </AlertTitle>
            </Alert>
          )}
        {failedTransferSourceTags.length > 0 && (
          <Alert
            status="error"
            variant="left-accent"
            alignItems="left"
            justifyContent="left"
            textAlign="left"
            fontSize="13px"
            flexWrap="wrap"
          >
            <AlertIcon />
            <AlertTitle>下記の入稿用リンクは既に存在します</AlertTitle>
            <AlertDescription width="100%">
              <UnorderedList mt={2} pl={2} pt={2}>
                {failedTransferSourceTags.map((failedSourceTag) => (
                  <ListItem key={failedSourceTag.id}>
                    <Flex alignItems="left" flexDirection="row">
                      <Box fontWeight="bold">{failedSourceTag.sourceName}:</Box>
                      <Box pl={2}>
                        {failedSourceTag.key}={failedSourceTag.value}
                      </Box>
                    </Flex>
                  </ListItem>
                ))}
              </UnorderedList>
            </AlertDescription>
          </Alert>
        )}
        <FormikFormButtons onCancelClick={onClose} />
      </Form>
    </Formik>
  );
};
