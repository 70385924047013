import { object, string } from "yup";

export type SiteEditFormValues = {
  name: string;
  url: string;
};

export type SiteCreateFormValues = {
  name: string;
  url: string;
  userId: string;
};

export const siteEditFormValidationSchema = object({
  name: string().required(),
  url: string().url().required(),
});

export const siteCreateFormValidationSchema = object({
  name: string().required(),
  url: string().url().required(),
  userId: string().required(),
});
