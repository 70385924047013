import { includes } from "ramda";
import { FC } from "react";

import {
  TABLE_ROW_HEIGHT_PX,
  TableDataRow,
} from "~/src/components/common/tables/Table";

import { ColumnData, ReportTableDataColumn } from "./ReportTableDataColumn";
import { ReportTableDataStickyColumn } from "./ReportTableDataStickyColumn";

export type Prop = {
  row: ColumnData[];
  disableColumns: number[];
  rowHeight?: string;
};

export const ReportTableDataRow: FC<Prop> = ({
  row,
  disableColumns,
  rowHeight,
}) => {
  return (
    <TableDataRow height={rowHeight ? rowHeight : `${TABLE_ROW_HEIGHT_PX}px`}>
      {row
        .filter((_, index) => !includes(index, disableColumns))
        .map((cell, i) =>
          i === 0 ? (
            <ReportTableDataStickyColumn data={cell} key={i} />
          ) : (
            <ReportTableDataColumn data={cell} key={i} />
          )
        )}
    </TableDataRow>
  );
};
