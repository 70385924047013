import { FC } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";

import { SourceEditPageContainer_Query } from "~/src/__relay_artifacts__/SourceEditPageContainer_Query.graphql";
import { useSourceEditPageParams } from "~/src/routes/hooks";

import { SourceEditPage } from "./presentations";
import { SourceEditScreenContainer } from "./SourceEditScreenContainer";

const query = graphql`
  query SourceEditPageContainer_Query($slug: String!) {
    source(slug: $slug) {
      ...SourceEditScreenContainer_source
    }
  }
`;

export const SourceEditPageContainer: FC = () => {
  const { siteSlug, sourceSlug } = useSourceEditPageParams();

  const { source } = useLazyLoadQuery<SourceEditPageContainer_Query>(query, {
    slug: sourceSlug,
  });

  if (!source) throw new Error("assertion failed");

  return (
    <SourceEditPage siteSlug={siteSlug} sourceSlug={sourceSlug}>
      <SourceEditScreenContainer sourceRef={source} />
    </SourceEditPage>
  );
};
