/**
 * @generated SignedSource<<03a13d6243e9e9e7d2519b91b561142b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Device = "MOBILE" | "TABLET" | "PC" | "%future added value";
export type ReportAnalyzerInput = {
  startOn: string;
  endOn: string;
  device?: Device | null;
  sourceId?: string | null;
  funnelId?: string | null;
};
export type ReportListContainerRefetchQuery$variables = {
  analyzerInput: ReportAnalyzerInput;
  slug: string;
};
export type ReportListContainerRefetchQueryVariables = ReportListContainerRefetchQuery$variables;
export type ReportListContainerRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ReportListContainer_query">;
};
export type ReportListContainerRefetchQueryResponse = ReportListContainerRefetchQuery$data;
export type ReportListContainerRefetchQuery = {
  variables: ReportListContainerRefetchQueryVariables;
  response: ReportListContainerRefetchQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "analyzerInput"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "analyzerInput"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ReportListContainerRefetchQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ReportListContainer_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReportListContainerRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "slug",
            "variableName": "slug"
          }
        ],
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "kind": "ScalarField",
            "name": "dailyLandingPageDropRates",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v1/*: any*/),
            "kind": "ScalarField",
            "name": "dailyAverageStayTimes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v1/*: any*/),
            "kind": "ScalarField",
            "name": "dailyAverageConversionTimes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v1/*: any*/),
            "kind": "ScalarField",
            "name": "landingPageUniqueUser",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v1/*: any*/),
            "kind": "ScalarField",
            "name": "formPageUniqueUser",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v1/*: any*/),
            "kind": "ScalarField",
            "name": "confirmPageUniqueUser",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v1/*: any*/),
            "kind": "ScalarField",
            "name": "thanksPageUniqueUser",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v1/*: any*/),
            "kind": "ScalarField",
            "name": "uniqueUserCountPerFunnel",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ff3b77006f7e27fc75a03d0e86ab5e88",
    "id": null,
    "metadata": {},
    "name": "ReportListContainerRefetchQuery",
    "operationKind": "query",
    "text": "query ReportListContainerRefetchQuery(\n  $analyzerInput: ReportAnalyzerInput!\n  $slug: String!\n) {\n  ...ReportListContainer_query\n}\n\nfragment FunnelTableSection_site on Site {\n  landingPageUniqueUser(input: $analyzerInput)\n  formPageUniqueUser(input: $analyzerInput)\n  confirmPageUniqueUser(input: $analyzerInput)\n  thanksPageUniqueUser(input: $analyzerInput)\n  uniqueUserCountPerFunnel(input: $analyzerInput)\n}\n\nfragment FunnelTransitionReportSection_site on Site {\n  dailyLandingPageDropRates(input: $analyzerInput)\n  dailyAverageStayTimes(input: $analyzerInput)\n  dailyAverageConversionTimes(input: $analyzerInput)\n}\n\nfragment ReportListContainer_query on Query {\n  site(slug: $slug) {\n    ...FunnelTransitionReportSection_site\n    ...FunnelTableSection_site\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "1150d12c7f9a88bf5b6c6483f9f079aa";

export default node;
