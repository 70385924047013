import { ViewWeekOutlined as ViewWeekOutlinedIcon } from "@mui/icons-material";
import { FC, useCallback } from "react";

import { IconButton } from "~/src/components/common/IconButton";
import {
  TableItemSelect,
  TableItemSelectProps,
} from "~/src/components/common/tables/TableItemSelect";
import { useOpenState } from "~/src/lib/hooks";

export type Props = {
  allTableItems: TableItemSelectProps["allTableItems"];
  selectedTableItems: TableItemSelectProps["initialSelectedItems"];
  onApplyClick: TableItemSelectProps["onApplyClick"];
};

export const TableItemSelectButton: FC<Props> = ({
  allTableItems,
  selectedTableItems,
  onApplyClick,
}) => {
  const { isOpen, onOpen, onClose } = useOpenState();

  const handleApplyClick = useCallback<TableItemSelectProps["onApplyClick"]>(
    (items) => {
      onApplyClick(items);
      onClose();
    },
    [onApplyClick, onClose]
  );

  return (
    <>
      <IconButton
        size="24px"
        icon={<ViewWeekOutlinedIcon />}
        isActive={isOpen}
        onClick={onOpen}
      />
      <TableItemSelect
        isOpen={isOpen}
        allTableItems={allTableItems}
        initialSelectedItems={selectedTableItems}
        onClose={onClose}
        onApplyClick={handleApplyClick}
      />
    </>
  );
};
