/**
 * @generated SignedSource<<38baaba810ac3a10bd184daa0f68b25e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PopupGroupForm_Query$variables = {};
export type PopupGroupForm_QueryVariables = PopupGroupForm_Query$variables;
export type PopupGroupForm_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PopupDistributionSettingFields_root">;
};
export type PopupGroupForm_QueryResponse = PopupGroupForm_Query$data;
export type PopupGroupForm_Query = {
  variables: PopupGroupForm_QueryVariables;
  response: PopupGroupForm_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PopupGroupForm_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "PopupDistributionSettingFields_root"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PopupGroupForm_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SourceConnection",
        "kind": "LinkedField",
        "name": "sources",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SourceEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Source",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": (v1/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserLabelConnection",
            "kind": "LinkedField",
            "name": "userLabels",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserLabelEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserLabel",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v1/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1fb8b9306fc2bf2b5ba7b024d0620263",
    "id": null,
    "metadata": {},
    "name": "PopupGroupForm_Query",
    "operationKind": "query",
    "text": "query PopupGroupForm_Query {\n  ...PopupDistributionSettingFields_root\n}\n\nfragment PopupDistributionSettingFields_root on Query {\n  sources {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n  viewer {\n    userLabels {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "1573e832de1777688b34a3b208703a8c";

export default node;
