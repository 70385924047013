/**
 * @generated SignedSource<<2174ba50b97abad368be6ae11b740dd1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PageKind = "LANDING" | "FORM" | "CONFIRM" | "THANKS" | "%future added value";
export type PopupDefaultReportFilterButton_Query$variables = {
  slug: string;
};
export type PopupDefaultReportFilterButton_QueryVariables = PopupDefaultReportFilterButton_Query$variables;
export type PopupDefaultReportFilterButton_Query$data = {
  readonly sources: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
      } | null;
    } | null> | null;
  };
  readonly site: {
    readonly funnels: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string;
        } | null;
      } | null> | null;
    };
    readonly popups: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string | null;
          readonly page: {
            readonly kind: PageKind;
            readonly name: string;
            readonly sortNumber: number;
            readonly funnel: {
              readonly id: string;
              readonly name: string;
            };
          };
        } | null;
      } | null> | null;
    };
  };
};
export type PopupDefaultReportFilterButton_QueryResponse = PopupDefaultReportFilterButton_Query$data;
export type PopupDefaultReportFilterButton_Query = {
  variables: PopupDefaultReportFilterButton_QueryVariables;
  response: PopupDefaultReportFilterButton_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "SourceConnection",
  "kind": "LinkedField",
  "name": "sources",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SourceEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Source",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "FunnelConnection",
  "kind": "LinkedField",
  "name": "funnels",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FunnelEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Funnel",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sortNumber",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Funnel",
  "kind": "LinkedField",
  "name": "funnel",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PopupDefaultReportFilterButton_Query",
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PopupConnection",
            "kind": "LinkedField",
            "name": "popups",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PopupEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Popup",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Page",
                        "kind": "LinkedField",
                        "name": "page",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v2/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PopupDefaultReportFilterButton_Query",
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PopupConnection",
            "kind": "LinkedField",
            "name": "popups",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PopupEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Popup",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Page",
                        "kind": "LinkedField",
                        "name": "page",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v2/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e93e85c1c75c272f4d43b1f32742cde9",
    "id": null,
    "metadata": {},
    "name": "PopupDefaultReportFilterButton_Query",
    "operationKind": "query",
    "text": "query PopupDefaultReportFilterButton_Query(\n  $slug: String!\n) {\n  sources {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n  site(slug: $slug) {\n    funnels {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n    popups {\n      edges {\n        node {\n          id\n          name\n          page {\n            kind\n            name\n            sortNumber\n            funnel {\n              id\n              name\n            }\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "3371a59dd1e6d28973c264f3afbce465";

export default node;
