import { VStack } from "@chakra-ui/react";
import { FilterAltOutlined as FilterAltOutlinedIcon } from "@mui/icons-material";
import { Form, Formik } from "formik";
import { FC, useCallback, useMemo } from "react";
import * as yup from "yup";

export type FormValues = yup.Asserts<typeof validationSchema>;

import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormikFormButtons } from "~/src/components/common/forms/FormikFormButtons";
import { FormikSelectField } from "~/src/components/common/forms/FormikSelectField";
import { IconButton } from "~/src/components/common/IconButton";
import { Modal } from "~/src/components/common/Modal";
import { FilteredTag } from "~/src/components/features/filter";
import { useOpenState } from "~/src/lib/hooks";

export type Props = {
  filterValues: FormValues;
  onSubmit: (values: FormValues) => Promise<void> | void;
};

const validationSchema = yup.object({
  published: yup.boolean().required(),
});

export const NotificationFilterButton: FC<Props> = ({
  filterValues,
  onSubmit,
}) => {
  const { isOpen, onClose, onOpen } = useOpenState();

  const publishedOptions = useMemo(
    () => [
      { label: "公開済み", value: true },
      { label: "未公開", value: false },
    ],
    []
  );

  const handleSubmit = useCallback(
    (values: FormValues) => {
      onSubmit(values);
      onClose();
    },
    [onClose, onSubmit]
  );

  return (
    <>
      {filterValues.published != null && (
        <FilteredTag
          name="公開状態"
          value={filterValues.published}
          options={publishedOptions}
        />
      )}
      <IconButton
        size="24px"
        icon={
          <FilterAltOutlinedIcon
            color={
              Object.values(filterValues).some((value) => value != null)
                ? "primary"
                : undefined
            }
          />
        }
        onClick={onOpen}
      />
      <Modal title="フィルタ" isOpen={isOpen} onClose={onClose}>
        <Formik<FormValues>
          enableReinitialize
          initialValues={filterValues}
          validationSchema={validationSchema}
          validateOnMount={false}
          validateOnChange={false}
          onSubmit={handleSubmit}
        >
          <Form>
            <VStack spacing="16px">
              <FormControl label="公開状態">
                <FormikSelectField
                  name="published"
                  options={publishedOptions}
                />
              </FormControl>
            </VStack>
            <FormikFormButtons onCancelClick={onClose} />
          </Form>
        </Formik>
      </Modal>
    </>
  );
};
