import { Box } from "@chakra-ui/layout";
import { FC, useCallback, useMemo, useState } from "react";
import { graphql, useFragment } from "react-relay";
import { useNavigate, useParams } from "react-router-dom";

import { PopupGroupEditScreenContainer_Mutation } from "~/src/__relay_artifacts__/PopupGroupEditScreenContainer_Mutation.graphql";
import { PopupGroupEditScreenContainer_popupGroup$key } from "~/src/__relay_artifacts__/PopupGroupEditScreenContainer_popupGroup.graphql";
import { PopupGroupEditScreenContainer_site$key } from "~/src/__relay_artifacts__/PopupGroupEditScreenContainer_site.graphql";
import {
  PopupGroupForm,
  PopupGroupFormProps,
} from "~/src/components/features/popup";
import { useFormErrorHandler } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";

import { PageLayout } from "../../features/global/PageLayout";

export type Props = {
  siteRef: PopupGroupEditScreenContainer_site$key;
  popupGroupRef: PopupGroupEditScreenContainer_popupGroup$key;
};

const fragments = {
  site: graphql`
    fragment PopupGroupEditScreenContainer_site on Site {
      id
      name
      slug
      ...PopupGroupForm_site
    }
  `,
  popupGroup: graphql`
    fragment PopupGroupEditScreenContainer_popupGroup on PopupGroup {
      ageRanges
      annualIncomes
      browsers
      deliverDayOfWeeks {
        weekOfMonth
        daysOfWeek
      }
      deliverExcludeUrls {
        url
      }
      deliverExcludeLabels {
        id
        name
      }
      deliverTargetUrls {
        url
      }
      deliverTargetLabels {
        id
        name
      }
      deliverKind
      devices
      displayTargetContents {
        targetKind
        targetValue
      }
      disableTargetElements {
        targetKind
        targetValue
      }
      platforms
      genders
      id
      isMulti
      memo
      name
      repeat
      endDate
      endTime
      page {
        id
        funnel {
          id
        }
      }
      redisplayPopupTime
      slug
      sources {
        id
        name
      }
      excludeSources {
        id
        name
      }
      startDate
      startTime
      status
      excludeReferrers {
        referrer
        matchOp
      }
      targetCookies {
        key
        value
        matchType
      }
      referrers {
        referrer
        matchOp
      }
      visitCount
      visitMatchType
    }
  `,
};

const mutation = graphql`
  mutation PopupGroupEditScreenContainer_Mutation(
    $input: UpdatePopupGroupInput!
  ) {
    updatePopupGroup(input: $input) {
      popupGroup {
        slug
        ...PopupGroupEditScreenContainer_popupGroup
      }
    }
  }
`;

export const PopupGroupEditScreenContainer: FC<Props> = ({
  siteRef,
  popupGroupRef,
}) => {
  const { siteSlug = "", popupGroupSlug = "" } = useParams();

  const site = useFragment(fragments.site, siteRef);
  const popupGroup = useFragment(fragments.popupGroup, popupGroupRef);

  const navigate = useNavigate();
  const handleCancel = useCallback(() => navigate(-1), [navigate]);

  const [repeat, setRepeat] = useState(popupGroup.repeat);

  const handleRepeatChange = useCallback(() => {
    setRepeat(!repeat);
  }, [repeat]);

  const mutate =
    useMutationCommit<PopupGroupEditScreenContainer_Mutation>(mutation);

  const { onFormError } = useFormErrorHandler();

  const handleSubmit = useCallback<PopupGroupFormProps["onSubmit"]>(
    async ({ funnelId, ...values }, { setErrors }) => {
      try {
        const res = await mutate({
          variables: {
            input: {
              ...values,
              popupGroupId: popupGroup.id,
              isMulti: values.isMulti === "true",
              startDate: values.startDate?.toISOString() || null,
              startTime: values.startTime?.toISOString() || null,
              endDate: values.endDate?.toISOString() || null,
              endTime: values.endTime?.toISOString() || null,
              deliverDayOfWeeks: values.deliverDayOfWeeks,
            },
          },
        });

        const updatedPopupGroup = res.updatePopupGroup?.popupGroup;

        if (!updatedPopupGroup) {
          throw new Error("assertion failed");
        }
        navigate(`/sites/${site.slug}/popup_groups/${updatedPopupGroup?.slug}`);
      } catch (err) {
        onFormError(err, setErrors);
      }
      return Promise.resolve();
    },
    [mutate, navigate, onFormError, popupGroup.id, site.slug]
  );

  const sourceIds = useMemo(
    () =>
      popupGroup.sources?.map((node) => {
        return node.id;
      }) || [],
    [popupGroup.sources]
  );

  const excludeSourceIds = useMemo(
    () =>
      popupGroup.excludeSources?.map((node) => {
        return node.id;
      }) || [],
    [popupGroup.excludeSources]
  );

  const deliverTargetLabelIds = useMemo(
    () => popupGroup.deliverTargetLabels.map((label) => label.id),
    [popupGroup.deliverTargetLabels]
  );

  const deliverExcludeLabelIds = useMemo(
    () => popupGroup.deliverExcludeLabels.map((label) => label.id),
    [popupGroup.deliverExcludeLabels]
  );

  const initialValues = useMemo(
    () => ({
      ageRanges: popupGroup.ageRanges || [],
      annualIncomes: popupGroup.annualIncomes || [],
      browsers: popupGroup.browsers || [],
      deliverDayOfWeeks: repeat ? popupGroup.deliverDayOfWeeks : null,
      deliverExcludeUrls: popupGroup.deliverExcludeUrls,
      deliverExcludeLabelIds,
      deliverTargetUrls: popupGroup.deliverTargetUrls,
      deliverTargetLabelIds,
      deliverKind: popupGroup.deliverKind,
      devices: popupGroup.devices || [],
      displayTargetContents: popupGroup.displayTargetContents || [],
      disableTargetElements: popupGroup.disableTargetElements || [],
      endDate:
        repeat && popupGroup.endDate ? new Date(popupGroup.endDate) : null,
      endTime:
        repeat && popupGroup.endTime ? new Date(popupGroup.endTime) : null,
      excludeReferrers: popupGroup.excludeReferrers,
      funnelId: popupGroup.page?.funnel.id,
      genders: popupGroup.genders || [],
      isMulti: popupGroup.isMulti.toString(),
      memo: popupGroup.memo,
      name: popupGroup.name,
      pageId: popupGroup.page?.id,
      platforms: popupGroup.platforms || [],
      status: popupGroup.status,
      redisplayPopupTime: popupGroup.redisplayPopupTime,
      referrers: popupGroup.referrers,
      sourceIds,
      excludeSourceIds,
      startDate:
        repeat && popupGroup.startDate ? new Date(popupGroup.startDate) : null,
      startTime:
        repeat && popupGroup.startTime ? new Date(popupGroup.startTime) : null,
      targetCookies: popupGroup.targetCookies,
      visitCount: popupGroup.visitCount,
      visitMatchType: popupGroup.visitMatchType,
    }),
    [
      deliverExcludeLabelIds,
      deliverTargetLabelIds,
      popupGroup,
      repeat,
      sourceIds,
      excludeSourceIds,
    ]
  );

  return (
    <PageLayout
      title="ポップアップ編集"
      breadcrumbs={[
        { label: "ポップアップ一覧", path: `/sites/${siteSlug}/popup_groups` },
        {
          label: "ポップアップ編集",
          path: `/sites/${siteSlug}/popup_groups/${popupGroupSlug}/edit`,
        },
      ]}
    >
      <Box my="16px" p="16px">
        <PopupGroupForm
          initialValues={initialValues as any}
          siteRef={site}
          repeat={repeat}
          onRepeatChange={handleRepeatChange}
          onCancelClick={handleCancel}
          onSubmit={handleSubmit}
          isFunnelDisabled
        />
      </Box>
    </PageLayout>
  );
};
