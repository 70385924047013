import { VStack } from "@chakra-ui/react";
import { FilterAltOutlined as FilterAltOutlinedIcon } from "@mui/icons-material";
import { Form, Formik } from "formik";
import { includes } from "ramda";
import { FC, useCallback, useMemo, useState } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import * as yup from "yup";

import { GraphQLEnums } from "~/src/__generated__/GraphQLEnums";
import {
  Device,
  DistributionStatus,
  PageKind,
} from "~/src/__generated__/schema";
import { ChatbotFilterButton_Query } from "~/src/__relay_artifacts__/ChatbotFilterButton_Query.graphql";
import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormikFormButtons } from "~/src/components/common/forms/FormikFormButtons";
import { FormikMultipleSelectField } from "~/src/components/common/forms/FormikMultipleSelectField";
import { FormikSelectField } from "~/src/components/common/forms/FormikSelectField";
import { IconButton } from "~/src/components/common/IconButton";
import { Modal } from "~/src/components/common/Modal";
import { FilteredTag } from "~/src/components/features/filter";
import { useOpenState } from "~/src/lib/hooks";

export type FormValues = {
  funnelIds: string[];
  pageKind: PageKind | null;
  pageIds: string[];
  sourceId: string | null;
  withArchived: boolean | null;
  status: DistributionStatus | null;
  device: Device | null;
};

export type Props = {
  siteSlug: string;
  filterValues: FormValues;
  onFilterChange: (filterValues: FormValues) => void;
  onSubmit: (values: FormValues) => Promise<void> | void;
};

const query = graphql`
  query ChatbotFilterButton_Query($siteSlug: String!) {
    site(slug: $siteSlug) {
      pages {
        edges {
          node {
            id
            name
            kind
            sortNumber
            funnel {
              id
              name
            }
          }
        }
      }
      funnels {
        edges {
          node {
            id
            name
          }
        }
      }
    }
    sources {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const validationSchema = yup.object({
  funnelIds: yup.array().of(yup.string()).nullable(),
  pageIds: yup.array().of(yup.string()).nullable(),
  sourceId: yup.string().nullable(),
  device: yup.string().nullable(),
  withArchived: yup.boolean().nullable(),
  status: yup.string().nullable(),
  pageKind: yup.string().nullable(),
});

export const ChatbotFilterButton: FC<Props> = ({
  siteSlug,
  filterValues,
  onFilterChange,
  onSubmit,
}) => {
  const { isOpen, onClose, onOpen } = useOpenState();
  const [funnelValues, setFunnelValues] = useState(filterValues.funnelIds);
  const [pageKindValue, setPageKindValue] = useState(filterValues.pageKind);
  const [pageValues, setPageValues] = useState(filterValues.pageIds);

  const { site, sources } = useLazyLoadQuery<ChatbotFilterButton_Query>(query, {
    siteSlug,
  });

  const funnelOptions = useMemo(
    () => [
      ...(site.funnels.edges || []).map((edge) => {
        const node = edge?.node;
        if (!node) throw new Error("assertion failed");
        return { label: node.name, value: node.id };
      }),
    ],
    [site.funnels.edges]
  );

  const pageKindOptions = useMemo(
    () => [{ label: "すべて", value: null }, ...GraphQLEnums.PageKind],
    []
  );

  const pageOptions = useMemo(() => {
    const options = [
      ...(site.pages.edges || []).map((edge) => {
        const node = edge?.node;
        if (!node) throw new Error("assertion failed");
        return {
          label:
            node.kind === "FORM"
              ? `${node.name}(${node.sortNumber - 1})[${node.funnel.name}]`
              : `${node.name}(${node.funnel.name})`,
          value: node.id,
          funnelId: node.funnel.id,
          pageKind: node.kind,
        };
      }),
    ].filter(
      (v) => includes(v.funnelId, funnelValues) || funnelValues.length === 0
    );
    if (pageKindValue === null) return options;
    return options.filter((v) => v.pageKind === pageKindValue);
  }, [site.pages.edges, funnelValues, pageKindValue]);

  const sourceOptions = useMemo(
    () => [
      { label: "すべて", value: null },
      ...(sources.edges || []).map((edge) => {
        const node = edge?.node;
        if (!node) throw new Error("assertion failed");
        return { label: node.name, value: node.id };
      }),
    ],
    [sources.edges]
  );

  const deviceOptions = useMemo(
    () => [{ label: "すべて", value: null }, ...GraphQLEnums.Device],
    []
  );

  const statusOptions = useMemo(
    () => [
      { label: "すべて", value: null },
      ...GraphQLEnums.DistributionStatus,
    ],
    []
  );

  const includeArchiveOptions = useMemo(
    () => [
      { label: "デフォルト（アーカイブ含まない）", value: null },
      { label: "アーカイブ含む", value: true },
    ],
    []
  );

  const handleFilterChange = useCallback(
    (values: Partial<FormValues>) => () => {
      if (values.funnelIds) {
        const funnelIds = values.funnelIds;
        setFunnelValues(funnelIds);
      }
      if (values.pageKind !== undefined) {
        const pageKind = values.pageKind;
        setPageKindValue(pageKind);
      }
      if (values.pageIds) {
        const pageIds = values.pageIds;
        setPageValues(pageIds);
      }
      onFilterChange({ ...filterValues, ...values });
    },
    [
      filterValues,
      onFilterChange,
      setPageValues,
      setPageKindValue,
      setFunnelValues,
    ]
  );

  const handleSubmit = useCallback(
    (values: FormValues) => {
      onSubmit(values);
      onClose();
    },
    [onClose, onSubmit]
  );

  return (
    <>
      {filterValues.funnelIds.length > 0 &&
        filterValues.funnelIds.map((funnelId, index) => (
          <FilteredTag
            key={index}
            name="ファネル"
            value={funnelId}
            options={funnelOptions}
            onClear={handleFilterChange({
              funnelIds: filterValues.funnelIds.filter((id) => id !== funnelId),
            })}
          />
        ))}
      {filterValues.pageKind && (
        <FilteredTag
          name="ページタイプ"
          value={filterValues.pageKind}
          options={pageKindOptions}
          onClear={handleFilterChange({ pageKind: null })}
        />
      )}
      {filterValues.pageIds.length > 0 &&
        filterValues.pageIds.map((pageId, index) => (
          <FilteredTag
            key={index}
            name="ページ"
            value={pageId}
            options={pageOptions}
            onClear={handleFilterChange({
              pageIds: filterValues.pageIds.filter((id) => id !== pageId),
            })}
          />
        ))}
      {filterValues.sourceId && (
        <FilteredTag
          name="流入元"
          value={filterValues.sourceId}
          options={sourceOptions}
          onClear={handleFilterChange({ sourceId: null })}
        />
      )}
      {filterValues.device && (
        <FilteredTag
          name="デバイス"
          value={filterValues.device}
          options={deviceOptions}
          onClear={handleFilterChange({ device: null })}
        />
      )}
      {filterValues.withArchived && (
        <FilteredTag
          name="アーカイブを含めるか"
          value={filterValues.withArchived}
          options={includeArchiveOptions}
          onClear={handleFilterChange({ withArchived: null })}
        />
      )}
      {filterValues.status && (
        <FilteredTag
          name="実施ステータス"
          value={filterValues.status}
          options={statusOptions}
          onClear={handleFilterChange({ status: null })}
        />
      )}
      <IconButton
        size="24px"
        icon={<FilterAltOutlinedIcon color={isOpen ? "primary" : undefined} />}
        onClick={onOpen}
      />
      <Modal title="フィルタ" isOpen={isOpen} onClose={onClose}>
        <Formik<FormValues>
          enableReinitialize
          initialValues={filterValues}
          validationSchema={validationSchema}
          validateOnMount={false}
          validateOnChange={false}
          onSubmit={handleSubmit}
        >
          <Form>
            <VStack spacing="16px">
              <FormControl label="ファネル">
                <FormikMultipleSelectField
                  name="funnelIds"
                  options={funnelOptions}
                  onSetStateAction={setFunnelValues}
                  isDisabled={pageValues.length > 0}
                />
              </FormControl>
              <FormControl label="ページタイプ">
                <FormikSelectField
                  name="pageKind"
                  options={pageKindOptions}
                  onSetStateAction={setPageKindValue}
                  isDisabled={pageValues.length > 0}
                />
              </FormControl>
              <FormControl label="ページ">
                <FormikMultipleSelectField
                  name="pageIds"
                  options={pageOptions}
                  onSetStateAction={setPageValues}
                />
              </FormControl>
              <FormControl label="流入元">
                <FormikSelectField name="sourceId" options={sourceOptions} />
              </FormControl>
              <FormControl label="デバイス">
                <FormikSelectField name="device" options={deviceOptions} />
              </FormControl>
              <FormControl label="アーカイブを含めるか">
                <FormikSelectField
                  name="withArchived"
                  options={includeArchiveOptions}
                />
              </FormControl>
              <FormControl label="実施ステータス">
                <FormikSelectField name="status" options={statusOptions} />
              </FormControl>
            </VStack>
            <FormikFormButtons onCancelClick={onClose} />
          </Form>
        </Formik>
      </Modal>
    </>
  );
};
