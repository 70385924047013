import { ComponentProps } from "react";
import ReactSelect from "react-select";

type ReactSelectProps = ComponentProps<typeof ReactSelect>;

export const customStyles: NonNullable<ReactSelectProps["customStyles"]> = {
  container: (provided: any) => ({
    ...provided,
    borderRadius: "2px",
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: "#FAFAFA",
    fontSize: "13px",
    lineHeight: "16px",
    minHeight: "40px",
    boxShadow: "none",
    border: "1px solid #DADADA",
    borderBottomLeftRadius: state.menuIsOpen ? "0" : "2px",
    borderBottomRightRadius: state.menuIsOpen ? "0" : "2px",

    "&:hover": {
      borderColor: "#D0D0D0",
      boxShadow: "0 0 0 1px #d0d0d0",
    },
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    backgroundColor: "#FAFAFA",
    borderTopLeftRadius: "2px",
    borderBottomLeftRadius: "2px",
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    backgroundColor: "#FAFAFA",
    borderTopRightRadius: "2px",
    borderBottomRightRadius: "2px",
  }),
  input: () => ({
    width: "100%",
    fontSize: "13px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.04em",
    boxSizing: "border-box",
    pr: "10px",
  }),
  menu: (provided: any) => ({
    ...provided,
    marginTop: 0,
    borderRadius: 0,
    border: "1px solid #DADADA",
    borderTop: "none",
    boxShadow: "none",
    zIndex: 3,
  }),
  menuList: (provided: any) => ({
    ...provided,
    padding: 0,
  }),
  option: (provided: any) => ({
    ...provided,
    fontSize: "13px",
    lineHeight: "16px",
    minHeight: "34px",
    padding: "8px 24px",
  }),
};
