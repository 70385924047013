import { Box, Text, VStack } from "@chakra-ui/react";
import { FC } from "react";

import { chakraFactory } from "~/src/lib/chakra-ui";

export type Props = {
  className?: string;
  description?: string;
};

const StyledWrapperBox = chakraFactory(Box, {
  baseStyle: {
    width: "100%",
    background: "#FFFFFF",
    border: "1px solid #DADADA",
    padding: "24px 30px 24px 24px",
  },
});

const StyledPaddedDescriptionText = chakraFactory(Text, {
  baseStyle: {
    display: "flex",
    alignItems: "center",
    padding: "5px 0 28px 0",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "19px",
    color: "#000000",
  },
});

export const FormControlGroup: FC<Props> = ({
  children,
  className,
  description,
}) => (
  <StyledWrapperBox className={className}>
    {description && (
      <StyledPaddedDescriptionText>{description}</StyledPaddedDescriptionText>
    )}
    <VStack spacing="16px">{children}</VStack>
  </StyledWrapperBox>
);
