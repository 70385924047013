import { Flex, Spinner } from "@chakra-ui/react";
import { FC } from "react";

import { chakraFactory } from "~/src/lib/chakra-ui";

const StyledSpinner = chakraFactory(Spinner, {
  baseStyle: {
    width: "120px",
    height: "120px",
  },
});

export const PageLoading: FC = () => (
  <Flex justifyContent="center" alignItems="center" width="100%" height="100%">
    <StyledSpinner
      thickness="4px"
      speed="0.65s"
      emptyColor="gray.200"
      color="#F0D209"
    />
  </Flex>
);
