/**
 * @generated SignedSource<<66ba6e773065717d240f34a93778f71f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateAbtestScenarioPageContentScenarioInput = {
  clientMutationId?: string | null;
  abtestScenarioPageContentId: string;
  image?: File | Blog | null;
  beforeSrc: string;
};
export type ABTestPatternEditScreenContainer_UpdateImageContentMutation$variables = {
  input: UpdateAbtestScenarioPageContentScenarioInput;
};
export type ABTestPatternEditScreenContainer_UpdateImageContentMutationVariables = ABTestPatternEditScreenContainer_UpdateImageContentMutation$variables;
export type ABTestPatternEditScreenContainer_UpdateImageContentMutation$data = {
  readonly updateAbtestScenarioPageContentScenario: {
    readonly abtestScenarioPageContent: {
      readonly __typename: "AbtestScenarioPageContentScenario";
      readonly id: string;
      readonly image: string | null;
      readonly imageUrl: string | null;
      readonly beforeSrc: string | null;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null;
  } | null;
};
export type ABTestPatternEditScreenContainer_UpdateImageContentMutationResponse = ABTestPatternEditScreenContainer_UpdateImageContentMutation$data;
export type ABTestPatternEditScreenContainer_UpdateImageContentMutation = {
  variables: ABTestPatternEditScreenContainer_UpdateImageContentMutationVariables;
  response: ABTestPatternEditScreenContainer_UpdateImageContentMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "image",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "beforeSrc",
      "storageKey": null
    }
  ],
  "type": "AbtestScenarioPageContentScenario",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ABTestPatternEditScreenContainer_UpdateImageContentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAbtestScenarioPageContentScenarioPayload",
        "kind": "LinkedField",
        "name": "updateAbtestScenarioPageContentScenario",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "abtestScenarioPageContent",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ABTestPatternEditScreenContainer_UpdateImageContentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAbtestScenarioPageContentScenarioPayload",
        "kind": "LinkedField",
        "name": "updateAbtestScenarioPageContentScenario",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "abtestScenarioPageContent",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1b9f6dbb3f498d46ac844361d35276ba",
    "id": null,
    "metadata": {},
    "name": "ABTestPatternEditScreenContainer_UpdateImageContentMutation",
    "operationKind": "mutation",
    "text": "mutation ABTestPatternEditScreenContainer_UpdateImageContentMutation(\n  $input: UpdateAbtestScenarioPageContentScenarioInput!\n) {\n  updateAbtestScenarioPageContentScenario(input: $input) {\n    abtestScenarioPageContent {\n      __typename\n      ... on AbtestScenarioPageContentScenario {\n        id\n        image\n        imageUrl\n        beforeSrc\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fa0ef416711fc8256fa94f691b6991f0";

export default node;
