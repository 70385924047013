import { Flex } from "@chakra-ui/react";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { usePapaParse } from "react-papaparse";
import { graphql, useFragment } from "react-relay";

import { PublisherDetailReportTable_site$key } from "~/src/__relay_artifacts__/PublisherDetailReportTable_site.graphql";
import { Table } from "~/src/components/common/tables/Table";

import { CSVDownloadLink } from "../CSVDownloadLink";
import { DisableColumnSelectButton } from "../DisableColumnSelectButton";
import {
  ColumnData,
  ReportTableDataRow,
  ReportTableHeaderRow,
} from "../ReportTable";
import { RowHeightSettingButton } from "../RowHeightSettingButton";
import { headerOptionsForPublisherDetailReport } from "../utils";

const disableColumnOptions = [
  { label: "流入数", value: 2 },
  { label: "CV-U", value: 3 },
  { label: "CVR", value: 4 },
  { label: "LP離脱率", value: 5 },
  { label: "LP読了率", value: 6 },
  { label: "LP平均滞在時間", value: 7 },
  { label: "EF離脱率", value: 8 },
  { label: "SP", value: 9 },
  { label: "TB", value: 10 },
  { label: "PC", value: 11 },
] as const;

const fragment = graphql`
  fragment PublisherDetailReportTable_site on Site {
    reportSource(input: $input)
  }
`;

export type Prop = {
  onSortClick: (params: any) => void;
  siteRef: PublisherDetailReportTable_site$key;
};

export const PublisherDetailReportTable: FC<Prop> = ({
  siteRef,
  onSortClick,
}) => {
  const { reportSource } = useFragment<typeof siteRef>(fragment, siteRef);

  const [disableColumns, setDisableColumns] = useState<number[]>([]);
  const [rowHeight, setRowHeight] = useState<number>(56);

  const { readString } = usePapaParse();

  const [data, setData] = useState<{ headers: string[]; rows: string[][] }>({
    headers: [],
    rows: [],
  });

  useEffect(() => {
    readString(reportSource.summary_report, {
      worker: true,
      complete: (results: any) => {
        const [headers, ...rest] = results.data;
        setData({ headers, rows: rest });
      },
    });
  }, [readString, reportSource]);

  const handleSubmitDisalbeColumnSelect = useCallback((values) => {
    setDisableColumns(values.disableColumns);
  }, []);

  const handleSubmitRowHeightSetting = useCallback((values) => {
    setRowHeight(values.rowHeight);
  }, []);

  const tableRows = useMemo(() => {
    return data.rows.map((row) => {
      return row.map((column) => {
        return {
          type: "TEXT",
          value: column,
        } as ColumnData;
      });
    });
  }, [data]);

  const tableFooterRow = useMemo(() => {
    return [
      { type: "TEXT", value: "" },
      { type: "TEXT", value: "合計" },
      {
        type: "TEXT",
        value: reportSource.aggregated_report.unique_user as string,
      },
      {
        type: "TEXT",
        value: reportSource.aggregated_report.conversion_user as string,
      },
      {
        type: "TEXT",
        value: reportSource.aggregated_report.conversion_user_rate as string,
      },
      {
        type: "TEXT",
        value: reportSource.aggregated_report.lp_drop_rate as string,
      },
      {
        type: "TEXT",
        value: reportSource.aggregated_report.lp_scroll_rate as string,
      },
      {
        type: "TEXT",
        value: reportSource.aggregated_report.lp_average_stay_time as string,
      },
      {
        type: "TEXT",
        value: reportSource.aggregated_report.form_drop_rate as string,
      },
      { type: "TEXT", value: reportSource.aggregated_report.mobile as string },
      { type: "TEXT", value: reportSource.aggregated_report.tablet as string },
      { type: "TEXT", value: reportSource.aggregated_report.pc as string },
    ] as ColumnData[];
  }, [reportSource]);

  const csvFooterRow = useMemo(() => {
    return [
      "",
      "合計",
      reportSource.aggregated_report.unique_user.replaceAll(",", ""),
      reportSource.aggregated_report.conversion_user.replaceAll(",", ""),
      reportSource.aggregated_report.conversion_user_rate,
      reportSource.aggregated_report.lp_drop_rate,
      reportSource.aggregated_report.lp_scroll_rate,
      reportSource.aggregated_report.lp_average_stay_time,
      reportSource.aggregated_report.form_drop_rate,
      reportSource.aggregated_report.mobile,
      reportSource.aggregated_report.tablet,
      reportSource.aggregated_report.pc,
    ].join(",");
  }, [reportSource]);

  return (
    <>
      <Flex justifyContent="flex-end" mb="24px" mt={6}>
        <RowHeightSettingButton
          formValues={{ rowHeight }}
          onSubmit={handleSubmitRowHeightSetting}
        />
        <DisableColumnSelectButton
          formValues={{ disableColumns }}
          onSubmit={handleSubmitDisalbeColumnSelect}
          options={disableColumnOptions}
        />
        <CSVDownloadLink
          name={"媒体別詳細レポート"}
          data={reportSource.summary_report + csvFooterRow}
          filteredColumns={disableColumns}
        />
      </Flex>
      <Table>
        <ReportTableHeaderRow
          disableColumns={disableColumns}
          onSortClick={onSortClick}
          headerOptions={headerOptionsForPublisherDetailReport}
        />
        {tableRows.map((row, i) => (
          <ReportTableDataRow
            key={i}
            row={row}
            disableColumns={disableColumns}
          />
        ))}
        <ReportTableDataRow
          disableColumns={disableColumns}
          row={tableFooterRow}
          rowHeight={`${rowHeight}px`}
        />
      </Table>
    </>
  );
};
