import { ArrowDropDownOutlined } from "@mui/icons-material";
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  TextFieldProps as MuiTextFieldProps,
  OutlinedInput,
  OutlinedInputProps,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useId } from "react";

export type Props = {
  icon: React.ReactNode;
  errorMessage?: string;
} & MuiTextFieldProps;

const StyledInput = styled(OutlinedInput, {
  shouldForwardProp: (prop) => prop !== "disabled",
})<OutlinedInputProps>(({ disabled }) => {
  return {
    background: "#FAFAFA",
    height: "40px",
    borderRadius: "2px",
    fontSize: "13px",
    ...(disabled && {
      opacity: 0.4,
      cursor: "not-allowed",
      "& .MuiOutlinedInput-input": {
        cursor: "not-allowed",
      },
    }),
  };
});

export const DatePickerInput: React.FC<Props> = ({
  inputRef,
  inputProps,
  InputProps,
  icon,
  error,
  errorMessage,
}) => {
  const id = useId();

  return (
    <FormControl
      error={error || errorMessage != null}
      disabled={InputProps?.disabled}
    >
      <StyledInput
        inputRef={inputRef}
        inputProps={inputProps}
        {...InputProps}
        startAdornment={
          <InputAdornment position="start">{icon}</InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end">
            <ArrowDropDownOutlined />
          </InputAdornment>
        }
        aria-describedby={id}
      />
      {errorMessage && <FormHelperText id={id}>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};
