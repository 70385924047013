type Option<T> = {
  label: string;
  value: T;
};

export const isMeasureKey = <T extends string>(
  key: string,
  options: readonly Option<T>[]
): key is T => {
  return options.map((op) => op.value as string).includes(key);
};
