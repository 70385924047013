/**
 * @generated SignedSource<<522c45a482cbecb255ace20b447a2110>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AgeRange = "LT12" | "GT12LT19" | "GT20LT24" | "GT25LT29" | "GT30LT34" | "GT35LT39" | "GT40LT44" | "GT45LT49" | "GT50LT54" | "GT55LT59" | "%future added value";
export type AnnualIncome = "LT200" | "GT200LT400" | "GT400LT600" | "GT600LT800" | "GT800LT1000" | "GT1000LT1200" | "GT1200LT1500" | "GT1500LT2000" | "GT2000" | "%future added value";
export type Browser = "IE" | "CHROME" | "SAFARI" | "FIREFOX" | "EDGE" | "OTHER" | "%future added value";
export type DayOfWeek = "SUN" | "MON" | "TUE" | "WED" | "THU" | "FRI" | "SUT" | "%future added value";
export type DeliverKind = "EQUAL" | "WEIGHT" | "%future added value";
export type Device = "MOBILE" | "TABLET" | "PC" | "%future added value";
export type DistributionStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type Gender = "MALE" | "FEMALE" | "%future added value";
export type GroupContentJudgeStatus = "PENDING" | "APPROVED" | "%future added value";
export type MatchOp = "EQUAL" | "CONTAINS" | "%future added value";
export type Platform = "IOS" | "WINDOWS" | "ANDROID" | "MAC" | "LINUX" | "OTHER" | "%future added value";
export type PopupGroupDisplayTargetContentKind = "ID" | "SELECTOR" | "%future added value";
export type VisitMatch = "EQUAL_MATCH_TYPE" | "OVER_MATCH_TYPE" | "UNDER_MATCH_TYPE" | "%future added value";
export type CreatePopupGroupInput = {
  clientMutationId?: string | null;
  ageRanges?: ReadonlyArray<AgeRange> | null;
  annualIncomes?: ReadonlyArray<AnnualIncome> | null;
  browsers?: ReadonlyArray<Browser> | null;
  deliverDayOfWeeks?: ReadonlyArray<DeliverDayOfWeekInput> | null;
  deliverKind?: DeliverKind | null;
  deliverTargetLabelIds: ReadonlyArray<string>;
  deliverExcludeLabelIds: ReadonlyArray<string>;
  deliverTargetUrls: ReadonlyArray<PopupGroupDeliverUrlInput>;
  deliverExcludeUrls: ReadonlyArray<PopupGroupDeliverUrlInput>;
  devices?: ReadonlyArray<Device> | null;
  displayTargetContents?: ReadonlyArray<PopupGroupDisplayTargetContentInput> | null;
  disableTargetElements?: ReadonlyArray<PopupGroupDisableTargetElementInput> | null;
  endDate?: string | null;
  endTime?: string | null;
  excludeReferrers: ReadonlyArray<PopupGroupExcludeReferrerInput>;
  genders?: ReadonlyArray<Gender> | null;
  isMulti?: boolean | null;
  judgeStatus?: GroupContentJudgeStatus | null;
  memo?: string | null;
  name: string;
  pageId: string;
  platforms?: ReadonlyArray<Platform> | null;
  referrers: ReadonlyArray<PopupGroupReferrerInput>;
  redisplayPopupTime: number;
  sourceIds: ReadonlyArray<string>;
  excludeSourceIds?: ReadonlyArray<string> | null;
  startDate?: string | null;
  startTime?: string | null;
  status?: DistributionStatus | null;
  targetCookies: ReadonlyArray<PopupGroupTargetCookieInput>;
  visitCount?: number | null;
  visitMatchType?: VisitMatch | null;
};
export type DeliverDayOfWeekInput = {
  weekOfMonth?: number | null;
  daysOfWeek: ReadonlyArray<DayOfWeek>;
};
export type PopupGroupDeliverUrlInput = {
  url: string;
};
export type PopupGroupDisplayTargetContentInput = {
  targetKind: PopupGroupDisplayTargetContentKind;
  targetValue: string;
};
export type PopupGroupDisableTargetElementInput = {
  targetKind: PopupGroupDisplayTargetContentKind;
  targetValue: string;
};
export type PopupGroupExcludeReferrerInput = {
  referrer: string;
  matchOp: MatchOp;
};
export type PopupGroupReferrerInput = {
  referrer: string;
  matchOp: MatchOp;
};
export type PopupGroupTargetCookieInput = {
  key: string;
  value: string;
  matchType: boolean;
};
export type PopupGroupCreatePageContainer_Mutation$variables = {
  input: CreatePopupGroupInput;
};
export type PopupGroupCreatePageContainer_MutationVariables = PopupGroupCreatePageContainer_Mutation$variables;
export type PopupGroupCreatePageContainer_Mutation$data = {
  readonly createPopupGroup: {
    readonly popupGroup: {
      readonly id: string;
      readonly name: string;
      readonly slug: string;
      readonly page: {
        readonly id: string;
        readonly funnel: {
          readonly id: string;
        };
      };
      readonly platforms: ReadonlyArray<Platform>;
      readonly browsers: ReadonlyArray<Browser>;
      readonly devices: ReadonlyArray<Device>;
      readonly genders: ReadonlyArray<Gender>;
      readonly ageRanges: ReadonlyArray<AgeRange>;
      readonly deliverKind: DeliverKind;
      readonly repeat: boolean;
      readonly startDate: string | null;
      readonly startTime: string | null;
      readonly endDate: string | null;
      readonly endTime: string | null;
      readonly sources: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
      }>;
      readonly excludeSources: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
      }>;
      readonly referrers: ReadonlyArray<{
        readonly referrer: string | null;
        readonly matchOp: MatchOp | null;
      }>;
      readonly excludeReferrers: ReadonlyArray<{
        readonly referrer: string | null;
        readonly matchOp: MatchOp | null;
      }>;
      readonly visitCount: number | null;
      readonly visitMatchType: VisitMatch | null;
      readonly deliverTargetUrls: ReadonlyArray<{
        readonly url: string;
      }>;
      readonly deliverExcludeUrls: ReadonlyArray<{
        readonly url: string;
      }>;
      readonly deliverTargetLabels: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
      }>;
      readonly deliverExcludeLabels: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
      }>;
      readonly targetCookies: ReadonlyArray<{
        readonly key: string;
        readonly value: string;
        readonly matchType: boolean;
      }>;
      readonly deliverDayOfWeeks: ReadonlyArray<{
        readonly weekOfMonth: number | null;
        readonly daysOfWeek: ReadonlyArray<DayOfWeek>;
      }>;
      readonly displayTargetContents: ReadonlyArray<{
        readonly targetKind: PopupGroupDisplayTargetContentKind;
        readonly targetValue: string;
      }>;
      readonly disableTargetElements: ReadonlyArray<{
        readonly targetKind: PopupGroupDisplayTargetContentKind;
        readonly targetValue: string;
      }>;
    } | null;
  } | null;
};
export type PopupGroupCreatePageContainer_MutationResponse = PopupGroupCreatePageContainer_Mutation$data;
export type PopupGroupCreatePageContainer_Mutation = {
  variables: PopupGroupCreatePageContainer_MutationVariables;
  response: PopupGroupCreatePageContainer_Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Page",
  "kind": "LinkedField",
  "name": "page",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Funnel",
      "kind": "LinkedField",
      "name": "funnel",
      "plural": false,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "platforms",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "browsers",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "devices",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "genders",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ageRanges",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deliverKind",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "repeat",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startTime",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endTime",
  "storageKey": null
},
v17 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "Source",
  "kind": "LinkedField",
  "name": "sources",
  "plural": true,
  "selections": (v17/*: any*/),
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "Source",
  "kind": "LinkedField",
  "name": "excludeSources",
  "plural": true,
  "selections": (v17/*: any*/),
  "storageKey": null
},
v20 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "referrer",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "matchOp",
    "storageKey": null
  }
],
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "PopupGroupReferrer",
  "kind": "LinkedField",
  "name": "referrers",
  "plural": true,
  "selections": (v20/*: any*/),
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "PopupGroupExcludeReferrer",
  "kind": "LinkedField",
  "name": "excludeReferrers",
  "plural": true,
  "selections": (v20/*: any*/),
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "visitCount",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "visitMatchType",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v26 = [
  (v25/*: any*/)
],
v27 = {
  "alias": null,
  "args": null,
  "concreteType": "UserLabel",
  "kind": "LinkedField",
  "name": "deliverTargetLabels",
  "plural": true,
  "selections": (v17/*: any*/),
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "concreteType": "UserLabel",
  "kind": "LinkedField",
  "name": "deliverExcludeLabels",
  "plural": true,
  "selections": (v17/*: any*/),
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "matchType",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "concreteType": "DeliverDayOfWeek",
  "kind": "LinkedField",
  "name": "deliverDayOfWeeks",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weekOfMonth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "daysOfWeek",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "targetKind",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "targetValue",
  "storageKey": null
},
v35 = [
  (v33/*: any*/),
  (v34/*: any*/)
],
v36 = [
  (v25/*: any*/),
  (v2/*: any*/)
],
v37 = [
  (v33/*: any*/),
  (v34/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PopupGroupCreatePageContainer_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreatePopupGroupPayload",
        "kind": "LinkedField",
        "name": "createPopupGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PopupGroup",
            "kind": "LinkedField",
            "name": "popupGroup",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PopupGroupDeliverTargetUrl",
                "kind": "LinkedField",
                "name": "deliverTargetUrls",
                "plural": true,
                "selections": (v26/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PopupGroupDeliverExcludeUrl",
                "kind": "LinkedField",
                "name": "deliverExcludeUrls",
                "plural": true,
                "selections": (v26/*: any*/),
                "storageKey": null
              },
              (v27/*: any*/),
              (v28/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PopupGroupTargetCookie",
                "kind": "LinkedField",
                "name": "targetCookies",
                "plural": true,
                "selections": [
                  (v29/*: any*/),
                  (v30/*: any*/),
                  (v31/*: any*/)
                ],
                "storageKey": null
              },
              (v32/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PopupGroupDisplayTargetContent",
                "kind": "LinkedField",
                "name": "displayTargetContents",
                "plural": true,
                "selections": (v35/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PopupGroupDisableTargetElement",
                "kind": "LinkedField",
                "name": "disableTargetElements",
                "plural": true,
                "selections": (v35/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PopupGroupCreatePageContainer_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreatePopupGroupPayload",
        "kind": "LinkedField",
        "name": "createPopupGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PopupGroup",
            "kind": "LinkedField",
            "name": "popupGroup",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PopupGroupDeliverTargetUrl",
                "kind": "LinkedField",
                "name": "deliverTargetUrls",
                "plural": true,
                "selections": (v36/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PopupGroupDeliverExcludeUrl",
                "kind": "LinkedField",
                "name": "deliverExcludeUrls",
                "plural": true,
                "selections": (v36/*: any*/),
                "storageKey": null
              },
              (v27/*: any*/),
              (v28/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PopupGroupTargetCookie",
                "kind": "LinkedField",
                "name": "targetCookies",
                "plural": true,
                "selections": [
                  (v29/*: any*/),
                  (v30/*: any*/),
                  (v31/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v32/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PopupGroupDisplayTargetContent",
                "kind": "LinkedField",
                "name": "displayTargetContents",
                "plural": true,
                "selections": (v37/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PopupGroupDisableTargetElement",
                "kind": "LinkedField",
                "name": "disableTargetElements",
                "plural": true,
                "selections": (v37/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "aabf3572d9c7f9c88d4d2359a33154ae",
    "id": null,
    "metadata": {},
    "name": "PopupGroupCreatePageContainer_Mutation",
    "operationKind": "mutation",
    "text": "mutation PopupGroupCreatePageContainer_Mutation(\n  $input: CreatePopupGroupInput!\n) {\n  createPopupGroup(input: $input) {\n    popupGroup {\n      id\n      name\n      slug\n      page {\n        id\n        funnel {\n          id\n        }\n      }\n      platforms\n      browsers\n      devices\n      genders\n      ageRanges\n      deliverKind\n      repeat\n      startDate\n      startTime\n      endDate\n      endTime\n      sources {\n        id\n        name\n      }\n      excludeSources {\n        id\n        name\n      }\n      referrers {\n        referrer\n        matchOp\n      }\n      excludeReferrers {\n        referrer\n        matchOp\n      }\n      visitCount\n      visitMatchType\n      deliverTargetUrls {\n        url\n        id\n      }\n      deliverExcludeUrls {\n        url\n        id\n      }\n      deliverTargetLabels {\n        id\n        name\n      }\n      deliverExcludeLabels {\n        id\n        name\n      }\n      targetCookies {\n        key\n        value\n        matchType\n        id\n      }\n      deliverDayOfWeeks {\n        weekOfMonth\n        daysOfWeek\n      }\n      displayTargetContents {\n        targetKind\n        targetValue\n        id\n      }\n      disableTargetElements {\n        targetKind\n        targetValue\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e60bcb562b69c7c6f3ab9685e26084ce";

export default node;
