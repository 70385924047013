/**
 * @generated SignedSource<<65eba573c2bc50c9f4ab9ea503ab99cc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type NotificationCategory = "UPGRADE" | "SUPPORT" | "INCIDENT" | "EVENT" | "OTHER" | "%future added value";
export type CreateNotificationInput = {
  clientMutationId?: string | null;
  body?: string | null;
  category: NotificationCategory;
  publishedAt: string;
  title: string;
};
export type NotificationCreatePage_Mutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreateNotificationInput;
};
export type NotificationCreatePage_MutationVariables = NotificationCreatePage_Mutation$variables;
export type NotificationCreatePage_Mutation$data = {
  readonly createNotification: {
    readonly notification: {
      readonly id: string;
      readonly title: string;
    };
  } | null;
};
export type NotificationCreatePage_MutationResponse = NotificationCreatePage_Mutation$data;
export type NotificationCreatePage_Mutation = {
  variables: NotificationCreatePage_MutationVariables;
  response: NotificationCreatePage_Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Notification",
  "kind": "LinkedField",
  "name": "notification",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationCreatePage_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateNotificationPayload",
        "kind": "LinkedField",
        "name": "createNotification",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NotificationCreatePage_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateNotificationPayload",
        "kind": "LinkedField",
        "name": "createNotification",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "notification",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "NotificationEdge"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6ff30fc795cd8110ab8d943c99f919b1",
    "id": null,
    "metadata": {},
    "name": "NotificationCreatePage_Mutation",
    "operationKind": "mutation",
    "text": "mutation NotificationCreatePage_Mutation(\n  $input: CreateNotificationInput!\n) {\n  createNotification(input: $input) {\n    notification {\n      id\n      title\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b689819f7c1f4ea1395d0abaacbbd13e";

export default node;
