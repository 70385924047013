/**
 * @generated SignedSource<<33f2181d5bdc942d208f922a21df7f58>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ExcludedIpAddressStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type UpdateExcludedIpAddressInput = {
  clientMutationId?: string | null;
  excludedIpAddressId: string;
  ipAddress: string;
  status: ExcludedIpAddressStatus;
};
export type useUpdateExcludedIpStatus_Mutation$variables = {
  input: UpdateExcludedIpAddressInput;
};
export type useUpdateExcludedIpStatus_MutationVariables = useUpdateExcludedIpStatus_Mutation$variables;
export type useUpdateExcludedIpStatus_Mutation$data = {
  readonly updateExcludedIpAddress: {
    readonly excludedIpAddress: {
      readonly id: string;
      readonly ipAddress: string;
      readonly status: ExcludedIpAddressStatus;
    } | null;
  } | null;
};
export type useUpdateExcludedIpStatus_MutationResponse = useUpdateExcludedIpStatus_Mutation$data;
export type useUpdateExcludedIpStatus_Mutation = {
  variables: useUpdateExcludedIpStatus_MutationVariables;
  response: useUpdateExcludedIpStatus_Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateExcludedIpAddressPayload",
    "kind": "LinkedField",
    "name": "updateExcludedIpAddress",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ExcludedIpAddress",
        "kind": "LinkedField",
        "name": "excludedIpAddress",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ipAddress",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUpdateExcludedIpStatus_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUpdateExcludedIpStatus_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5d6822aba52229eb093e2f1b0d2a9999",
    "id": null,
    "metadata": {},
    "name": "useUpdateExcludedIpStatus_Mutation",
    "operationKind": "mutation",
    "text": "mutation useUpdateExcludedIpStatus_Mutation(\n  $input: UpdateExcludedIpAddressInput!\n) {\n  updateExcludedIpAddress(input: $input) {\n    excludedIpAddress {\n      id\n      ipAddress\n      status\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7d0bec0c97f0667ca79fca71ee05a0d9";

export default node;
