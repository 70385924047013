/**
 * @generated SignedSource<<1d7e60016b77d80abbea81479e2f9d72>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AgeRange = "LT12" | "GT12LT19" | "GT20LT24" | "GT25LT29" | "GT30LT34" | "GT35LT39" | "GT40LT44" | "GT45LT49" | "GT50LT54" | "GT55LT59" | "%future added value";
export type AnnualIncome = "LT200" | "GT200LT400" | "GT400LT600" | "GT600LT800" | "GT800LT1000" | "GT1000LT1200" | "GT1200LT1500" | "GT1500LT2000" | "GT2000" | "%future added value";
export type Browser = "IE" | "CHROME" | "SAFARI" | "FIREFOX" | "EDGE" | "OTHER" | "%future added value";
export type DayOfWeek = "SUN" | "MON" | "TUE" | "WED" | "THU" | "FRI" | "SUT" | "%future added value";
export type DeliverKind = "EQUAL" | "WEIGHT" | "%future added value";
export type Device = "MOBILE" | "TABLET" | "PC" | "%future added value";
export type DistributionStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type Gender = "MALE" | "FEMALE" | "%future added value";
export type GroupContentJudgeStatus = "PENDING" | "APPROVED" | "%future added value";
export type MatchOp = "EQUAL" | "CONTAINS" | "%future added value";
export type Platform = "IOS" | "WINDOWS" | "ANDROID" | "MAC" | "LINUX" | "OTHER" | "%future added value";
export type VisitMatch = "EQUAL_MATCH_TYPE" | "OVER_MATCH_TYPE" | "UNDER_MATCH_TYPE" | "%future added value";
export type UpdateAbtestScenarioPageGroupInput = {
  clientMutationId?: string | null;
  abtestScenarioPageGroupId: string;
  ageRanges?: ReadonlyArray<AgeRange> | null;
  annualIncomes?: ReadonlyArray<AnnualIncome> | null;
  browsers?: ReadonlyArray<Browser> | null;
  deliverDayOfWeeks?: ReadonlyArray<DeliverDayOfWeekInput> | null;
  deliverKind?: DeliverKind | null;
  deliverTargetLabelIds?: ReadonlyArray<string> | null;
  deliverExcludeLabelIds?: ReadonlyArray<string> | null;
  deliverTargetUrls?: ReadonlyArray<AbtestScenarioPageGroupDeliverUrlInput> | null;
  deliverExcludeUrls?: ReadonlyArray<AbtestScenarioPageGroupDeliverUrlInput> | null;
  devices?: ReadonlyArray<Device> | null;
  endDate?: string | null;
  endTime?: string | null;
  excludeReferrers?: ReadonlyArray<AbtestScenarioPageGroupExcludeReferrerInput> | null;
  genders?: ReadonlyArray<Gender> | null;
  judgeStatus?: GroupContentJudgeStatus | null;
  memo?: string | null;
  pageId?: string | null;
  platforms?: ReadonlyArray<Platform> | null;
  referrers?: ReadonlyArray<AbtestScenarioPageGroupReferrerInput> | null;
  sourceIds?: ReadonlyArray<string> | null;
  startDate?: string | null;
  startTime?: string | null;
  status?: DistributionStatus | null;
  targetCookies?: ReadonlyArray<AbtestScenarioPageGroupTargetCookieInput> | null;
  title?: string | null;
  visitCount?: number | null;
  visitMatchType?: VisitMatch | null;
  excludeSourceIds?: ReadonlyArray<string> | null;
};
export type DeliverDayOfWeekInput = {
  weekOfMonth?: number | null;
  daysOfWeek: ReadonlyArray<DayOfWeek>;
};
export type AbtestScenarioPageGroupDeliverUrlInput = {
  url: string;
};
export type AbtestScenarioPageGroupExcludeReferrerInput = {
  referrer: string;
  matchOp: MatchOp;
};
export type AbtestScenarioPageGroupReferrerInput = {
  referrer: string;
  matchOp: MatchOp;
};
export type AbtestScenarioPageGroupTargetCookieInput = {
  key: string;
  value: string;
  matchType: boolean;
};
export type ABTestEditScreenContainer_Mutation$variables = {
  input: UpdateAbtestScenarioPageGroupInput;
};
export type ABTestEditScreenContainer_MutationVariables = ABTestEditScreenContainer_Mutation$variables;
export type ABTestEditScreenContainer_Mutation$data = {
  readonly updateAbtestScenarioPageGroup: {
    readonly abtestScenarioPageGroup: {
      readonly id: string;
      readonly slug: string;
      readonly " $fragmentSpreads": FragmentRefs<"ABTestEditScreenContainer_abtestScenarioPageGroup">;
    } | null;
  } | null;
};
export type ABTestEditScreenContainer_MutationResponse = ABTestEditScreenContainer_Mutation$data;
export type ABTestEditScreenContainer_Mutation = {
  variables: ABTestEditScreenContainer_MutationVariables;
  response: ABTestEditScreenContainer_Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  },
  (v2/*: any*/)
],
v5 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "referrer",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "matchOp",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ABTestEditScreenContainer_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAbtestScenarioPageGroupPayload",
        "kind": "LinkedField",
        "name": "updateAbtestScenarioPageGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AbtestScenarioPageGroup",
            "kind": "LinkedField",
            "name": "abtestScenarioPageGroup",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ABTestEditScreenContainer_abtestScenarioPageGroup"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ABTestEditScreenContainer_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAbtestScenarioPageGroupPayload",
        "kind": "LinkedField",
        "name": "updateAbtestScenarioPageGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AbtestScenarioPageGroup",
            "kind": "LinkedField",
            "name": "abtestScenarioPageGroup",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ageRanges",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "annualIncomes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "browsers",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DeliverDayOfWeek",
                "kind": "LinkedField",
                "name": "deliverDayOfWeeks",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "weekOfMonth",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "daysOfWeek",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AbtestScenarioPageGroupDeliverExcludeUrl",
                "kind": "LinkedField",
                "name": "deliverExcludeUrls",
                "plural": true,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserLabel",
                "kind": "LinkedField",
                "name": "deliverExcludeLabels",
                "plural": true,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AbtestScenarioPageGroupDeliverTargetUrl",
                "kind": "LinkedField",
                "name": "deliverTargetUrls",
                "plural": true,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserLabel",
                "kind": "LinkedField",
                "name": "deliverTargetLabels",
                "plural": true,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deliverKind",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "devices",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AbtestScenarioPageGroupExcludeReferrer",
                "kind": "LinkedField",
                "name": "excludeReferrers",
                "plural": true,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "genders",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "memo",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "page",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Funnel",
                    "kind": "LinkedField",
                    "name": "funnel",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "platforms",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AbtestScenarioPageGroupReferrer",
                "kind": "LinkedField",
                "name": "referrers",
                "plural": true,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "repeat",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Source",
                "kind": "LinkedField",
                "name": "sources",
                "plural": true,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Source",
                "kind": "LinkedField",
                "name": "excludeSources",
                "plural": true,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AbtestScenarioPageGroupTargetCookie",
                "kind": "LinkedField",
                "name": "targetCookies",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "key",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "matchType",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "visitCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "visitMatchType",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d8559c955a7022c282f377ff74011a73",
    "id": null,
    "metadata": {},
    "name": "ABTestEditScreenContainer_Mutation",
    "operationKind": "mutation",
    "text": "mutation ABTestEditScreenContainer_Mutation(\n  $input: UpdateAbtestScenarioPageGroupInput!\n) {\n  updateAbtestScenarioPageGroup(input: $input) {\n    abtestScenarioPageGroup {\n      id\n      slug\n      ...ABTestEditScreenContainer_abtestScenarioPageGroup\n    }\n  }\n}\n\nfragment ABTestEditScreenContainer_abtestScenarioPageGroup on AbtestScenarioPageGroup {\n  ageRanges\n  annualIncomes\n  browsers\n  deliverDayOfWeeks {\n    weekOfMonth\n    daysOfWeek\n  }\n  deliverExcludeUrls {\n    url\n    id\n  }\n  deliverExcludeLabels {\n    id\n    name\n  }\n  deliverTargetUrls {\n    url\n    id\n  }\n  deliverTargetLabels {\n    id\n    name\n  }\n  deliverKind\n  devices\n  endDate\n  endTime\n  excludeReferrers {\n    referrer\n    matchOp\n  }\n  genders\n  id\n  memo\n  page {\n    id\n    funnel {\n      id\n    }\n  }\n  platforms\n  referrers {\n    referrer\n    matchOp\n  }\n  repeat\n  startDate\n  startTime\n  slug\n  sources {\n    id\n    name\n  }\n  excludeSources {\n    id\n    name\n  }\n  targetCookies {\n    key\n    value\n    matchType\n    id\n  }\n  title\n  visitCount\n  visitMatchType\n}\n"
  }
};
})();

(node as any).hash = "a90c2b3c35fa9da27483b22ae61d2151";

export default node;
