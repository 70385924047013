import { ComponentProps, FC } from "react";
import ReactSelect from "react-select";

import { customStyles } from "./styles";

export type Props = ComponentProps<typeof ReactSelect>;

const components: NonNullable<Props["components"]> = {
  IndicatorSeparator: () => null,
};

export const Select: FC<Props> = (props) => (
  <ReactSelect
    isSearchable={false}
    styles={customStyles}
    components={components}
    {...props}
  />
);
