import { formatNumber } from "accounting";
import { FC, useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { AbtestReportOrderValue } from "~/src/__generated__/schema";
import { ABTestPatternNameByDateReport_site$key } from "~/src/__relay_artifacts__/ABTestPatternNameByDateReport_site.graphql";
import { formatStayTime } from "~/src/lib/utils";

import { ColumnData } from "../../ReportTable";
import { HeaderOption } from "../../utils";

import { ABTestReportTable } from "./ABTestReportTable";

type Props = {
  onSortClick: (params: any) => void;
  siteRef: ABTestPatternNameByDateReport_site$key;
};

const fragment = graphql`
  fragment ABTestPatternNameByDateReport_site on Site {
    slug
    url
    reportAbtest(kind: $kind, filter: $filter, order: $order) {
      rows {
        abtestScenarioPageTitle
        abtestScenarioPageGroupTitle
        funnelName
        accessDate
        uniqueUser
        conversionUser
        cvr
        averageStayTime
        pcRate
        mobileRate
        tabletRate
        abtestScenarioPageSlug
        abtestScenarioPageGroupSlug
      }
      summary {
        abtestScenarioPageTitle
        abtestScenarioPageGroupTitle
        funnelName
        accessDate
        uniqueUser
        conversionUser
        cvr
        averageStayTime
        pcRate
        mobileRate
        tabletRate
      }
    }
  }
`;

const headerRow: HeaderOption<AbtestReportOrderValue>[] = [
  { label: "A/Bテストパターン", orderField: "ABTEST" },
  { label: "日付", orderField: "DATE" },
  { label: "流入数", orderField: "UNIQUE_USER" },
  { label: "CV-U", orderField: "CONVERSION_USER" },
  { label: "CVR", orderField: "CVR" },
  { label: "LP平均滞在時間", orderField: "AVERAGE_STAY_TIME" },
  { label: "SP", orderField: "MOBILE_RATE" },
  { label: "TB", orderField: "TABLET_RATE" },
  { label: "PC", orderField: "PC_RATE" },
];

const disableColumnOptions = [
  { label: "日付", value: 1 },
  { label: "流入数", value: 2 },
  { label: "CV-U", value: 3 },
  { label: "CVR", value: 4 },
  { label: "LP平均滞在時間", value: 5 },
  { label: "SP", value: 6 },
  { label: "TB", value: 7 },
  { label: "PC", value: 8 },
];

const csvHeaderRow = [
  "A/Bテストパターン",
  "日付",
  "流入数",
  "CV-U",
  "CVR",
  "LP平均滞在時間",
  "SP",
  "TB",
  "PC",
];

export const ABTestPatternNameByDateReport: FC<Props> = ({
  siteRef,
  onSortClick,
}) => {
  const site = useFragment(fragment, siteRef);

  const rows = useMemo(() => {
    return site.reportAbtest.rows.map((row) => {
      return [
        {
          type: "NAV_LINK",
          value: row.abtestScenarioPageTitle || "",
          url: `/sites/${site.slug}/abtests/${row.abtestScenarioPageGroupSlug}/patterns/${row.abtestScenarioPageSlug}`,
        },
        { type: "TEXT", value: row.accessDate || "" },
        { type: "TEXT", value: formatNumber(row.uniqueUser) },
        { type: "TEXT", value: formatNumber(row.conversionUser) },
        { type: "TEXT", value: row.cvr.toString() + "%" },
        { type: "TEXT", value: formatStayTime(row.averageStayTime) },
        { type: "TEXT", value: row.mobileRate.toString() + "%" },
        { type: "TEXT", value: row.tabletRate.toString() + "%" },
        { type: "TEXT", value: row.pcRate.toString() + "%" },
      ] as ColumnData[];
    });
  }, [site.reportAbtest.rows, site.slug]);

  const footerRow = useMemo(() => {
    if (site.reportAbtest.summary.length === 0) return [];
    const row = site.reportAbtest.summary[0];
    return [
      { type: "TEXT", value: "" },
      { type: "TEXT", value: "合計" },
      { type: "TEXT", value: formatNumber(row.uniqueUser) },
      { type: "TEXT", value: formatNumber(row.conversionUser) },
      { type: "TEXT", value: row.cvr.toString() + "%" },
      { type: "TEXT", value: formatStayTime(row.averageStayTime) },
      { type: "TEXT", value: row.mobileRate.toString() + "%" },
      { type: "TEXT", value: row.tabletRate.toString() + "%" },
      { type: "TEXT", value: row.pcRate.toString() + "%" },
    ] as ColumnData[];
  }, [site.reportAbtest.summary]);

  const csvRows = useMemo(() => {
    const csvRows = rows.map((row) => row.map((column) => column.value));
    const csvFooterRow = footerRow.map((column) => column.value);
    csvRows.unshift(csvHeaderRow);
    csvRows.push(csvFooterRow);
    return csvRows
      .map((row) => row.map((cell) => cell.replaceAll(",", "")).join(","))
      .join("\n");
  }, [rows, footerRow]);

  return (
    <ABTestReportTable
      rows={rows}
      footerRow={footerRow}
      headerRow={headerRow}
      onSortClick={onSortClick}
      disableColumnOptions={disableColumnOptions}
      csvRows={csvRows}
    />
  );
};
