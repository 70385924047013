/**
 * @generated SignedSource<<ca06101434c7efdf5f02216a4c9904f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountDetailPageContainer_Query$variables = {};
export type AccountDetailPageContainer_QueryVariables = AccountDetailPageContainer_Query$variables;
export type AccountDetailPageContainer_Query$data = {
  readonly viewer: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"AccountDetailPage_viewer">;
  };
};
export type AccountDetailPageContainer_QueryResponse = AccountDetailPageContainer_Query$data;
export type AccountDetailPageContainer_Query = {
  variables: AccountDetailPageContainer_QueryVariables;
  response: AccountDetailPageContainer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountDetailPageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AccountDetailPage_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AccountDetailPageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "company",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tel",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isHoneycomb",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "itpUrls",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isSecure",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isMacbeeServer",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userTag",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isCrossDomainQuery",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isCrossDomainFragment",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2e0a919c5b6f33d578a476de7d849bd8",
    "id": null,
    "metadata": {},
    "name": "AccountDetailPageContainer_Query",
    "operationKind": "query",
    "text": "query AccountDetailPageContainer_Query {\n  viewer {\n    id\n    ...AccountDetailPage_viewer\n  }\n}\n\nfragment AccountDetailPage_viewer on User {\n  id\n  name\n  email\n  company\n  tel\n  isHoneycomb\n  itpUrls\n  isSecure\n  isMacbeeServer\n  userTag\n  isCrossDomainQuery\n  isCrossDomainFragment\n}\n"
  }
};
})();

(node as any).hash = "f501b5e9a287b0e605fc99c2b740ae6d";

export default node;
