import { FC } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";

import { SourceListTabContainer_Query } from "~/src/__relay_artifacts__/SourceListTabContainer_Query.graphql";

import { SourceListTab } from "./presentations";

export type Props = {
  siteSlug: string;
};

const query = graphql`
  query SourceListTabContainer_Query {
    ...SourceListTab_root
  }
`;

export const SourceListTabContainer: FC<Props> = ({ siteSlug }) => {
  const data = useLazyLoadQuery<SourceListTabContainer_Query>(query, {});

  return <SourceListTab rootRef={data} siteSlug={siteSlug} />;
};
