import { Box } from "@chakra-ui/layout";
import { FC, useCallback, useMemo } from "react";
import { graphql, useFragment } from "react-relay";
import { useNavigate, useParams } from "react-router-dom";

import { ABTestEditScreenContainer_abtestScenarioPageGroup$key } from "~/src/__relay_artifacts__/ABTestEditScreenContainer_abtestScenarioPageGroup.graphql";
import { ABTestEditScreenContainer_Mutation } from "~/src/__relay_artifacts__/ABTestEditScreenContainer_Mutation.graphql";
import { ABTestEditScreenContainer_site$key } from "~/src/__relay_artifacts__/ABTestEditScreenContainer_site.graphql";
import { ABTestForm, ABTestFormProps } from "~/src/components/features/abtest";
import { useFormErrorHandler } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";

import { PageLayout } from "../../features/global/PageLayout";

export type Props = {
  abtestScenarioPageGroupRef: ABTestEditScreenContainer_abtestScenarioPageGroup$key;
  siteRef: ABTestEditScreenContainer_site$key;
};

const fragments = {
  abtestScenarioPageGroup: graphql`
    fragment ABTestEditScreenContainer_abtestScenarioPageGroup on AbtestScenarioPageGroup {
      ageRanges
      annualIncomes
      browsers
      deliverDayOfWeeks {
        weekOfMonth
        daysOfWeek
      }
      deliverExcludeUrls {
        url
      }
      deliverExcludeLabels {
        id
        name
      }
      deliverTargetUrls {
        url
      }
      deliverTargetLabels {
        id
        name
      }
      deliverKind
      devices
      endDate
      endTime
      excludeReferrers {
        referrer
        matchOp
      }
      genders
      id
      memo
      page {
        id
        funnel {
          id
        }
      }
      platforms
      referrers {
        referrer
        matchOp
      }
      repeat
      startDate
      startTime
      slug
      sources {
        id
        name
      }
      excludeSources {
        id
        name
      }
      targetCookies {
        key
        value
        matchType
      }
      title
      visitCount
      visitMatchType
    }
  `,
  site: graphql`
    fragment ABTestEditScreenContainer_site on Site {
      id
      name
      slug
      ...ABTestForm_site
    }
  `,
};

const mutation = graphql`
  mutation ABTestEditScreenContainer_Mutation(
    $input: UpdateAbtestScenarioPageGroupInput!
  ) {
    updateAbtestScenarioPageGroup(input: $input) {
      abtestScenarioPageGroup {
        id
        slug
        ...ABTestEditScreenContainer_abtestScenarioPageGroup
      }
    }
  }
`;

export const ABTestEditScreenContainer: FC<Props> = ({
  abtestScenarioPageGroupRef,
  siteRef,
}) => {
  const { abtestSlug = "", siteSlug = "" } = useParams();

  const abtestScenarioPageGroup = useFragment(
    fragments.abtestScenarioPageGroup,
    abtestScenarioPageGroupRef
  );
  const site = useFragment(fragments.site, siteRef);

  const navigate = useNavigate();
  const handleCancel = useCallback(() => navigate(-1), [navigate]);

  const mutate =
    useMutationCommit<ABTestEditScreenContainer_Mutation>(mutation);

  const { onFormError } = useFormErrorHandler();

  const handleSubmit = useCallback<ABTestFormProps["onSubmit"]>(
    async ({ repeat, funnelId, ...values }, { setErrors }) => {
      try {
        const res = await mutate({
          variables: {
            input: {
              ...values,
              abtestScenarioPageGroupId: abtestScenarioPageGroup.id,
              startDate: repeat
                ? values.startDate?.toISOString() || null
                : null,
              startTime: repeat
                ? values.startTime?.toISOString() || null
                : null,
              endDate: repeat ? values.endDate?.toISOString() || null : null,
              endTime: repeat ? values.endTime?.toISOString() || null : null,
              deliverDayOfWeeks: repeat ? values.deliverDayOfWeeks : [],
            },
          },
        });

        const updatedAbtestGroup =
          res.updateAbtestScenarioPageGroup?.abtestScenarioPageGroup;
        if (!updatedAbtestGroup) {
          throw new Error("assertion failed");
        }
        navigate(`/sites/${site.slug}/abtests/${updatedAbtestGroup?.slug}`);
      } catch (err) {
        onFormError(err, setErrors);
      }
      return Promise.resolve();
    },
    [abtestScenarioPageGroup.id, mutate, navigate, onFormError, site.slug]
  );

  const sourceIds = useMemo(
    () =>
      abtestScenarioPageGroup.sources?.map((node) => {
        return node.id;
      }) || [],
    [abtestScenarioPageGroup.sources]
  );

  const excludeSourceIds = useMemo(
    () =>
      abtestScenarioPageGroup.excludeSources?.map((node) => {
        return node.id;
      }) || [],
    [abtestScenarioPageGroup.excludeSources]
  );

  const deliverTargetLabelIds = useMemo(
    () => abtestScenarioPageGroup.deliverTargetLabels.map((label) => label.id),
    [abtestScenarioPageGroup.deliverTargetLabels]
  );

  const deliverExcludeLabelIds = useMemo(
    () => abtestScenarioPageGroup.deliverExcludeLabels.map((label) => label.id),
    [abtestScenarioPageGroup.deliverExcludeLabels]
  );

  const initialValues = {
    ageRanges: abtestScenarioPageGroup.ageRanges,
    annualIncomes: abtestScenarioPageGroup.annualIncomes,
    browsers: abtestScenarioPageGroup.browsers,
    devices: abtestScenarioPageGroup.devices,
    deliverDayOfWeeks: abtestScenarioPageGroup.deliverDayOfWeeks,
    deliverExcludeLabelIds,
    deliverExcludeUrls: abtestScenarioPageGroup.deliverExcludeUrls,
    deliverKind: abtestScenarioPageGroup.deliverKind,
    deliverTargetUrls: abtestScenarioPageGroup.deliverTargetUrls,
    deliverTargetLabelIds,
    endDate: abtestScenarioPageGroup.endDate
      ? new Date(abtestScenarioPageGroup.endDate)
      : null,
    endTime: abtestScenarioPageGroup.endTime
      ? new Date(abtestScenarioPageGroup.endTime)
      : null,
    excludeReferrers: abtestScenarioPageGroup.excludeReferrers,
    funnelId: abtestScenarioPageGroup.page.funnel.id,
    genders: abtestScenarioPageGroup.genders,
    memo: abtestScenarioPageGroup.memo,
    pageId: abtestScenarioPageGroup.page.id,
    platforms: abtestScenarioPageGroup.platforms,
    referrers: abtestScenarioPageGroup.referrers,
    repeat: abtestScenarioPageGroup.repeat,
    sourceIds,
    excludeSourceIds,
    startDate: abtestScenarioPageGroup.startDate
      ? new Date(abtestScenarioPageGroup.startDate)
      : null,
    startTime: abtestScenarioPageGroup.startTime
      ? new Date(abtestScenarioPageGroup.startTime)
      : null,
    targetCookies: abtestScenarioPageGroup.targetCookies,
    title: abtestScenarioPageGroup.title || "",
    visitCount: abtestScenarioPageGroup.visitCount,
    visitMatchType: abtestScenarioPageGroup.visitMatchType,
  };

  return (
    <PageLayout
      title="A/Bテスト編集"
      breadcrumbs={[
        { label: "A/Bテスト一覧", path: `/sites/${siteSlug}/abtests` },
        {
          label: "A/Bテスト編集",
          path: `/sites/${siteSlug}/abtests/${abtestSlug}/edit`,
        },
      ]}
    >
      <Box my="16px" p="16px">
        <ABTestForm
          siteRef={site}
          onCancelClick={handleCancel}
          onSubmit={handleSubmit}
          isFunnelDisabled
          // TODO: do not use any
          initialValues={initialValues as any}
        />
      </Box>
    </PageLayout>
  );
};
