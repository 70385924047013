import { VStack } from "@chakra-ui/react";
import { Form, useFormikContext } from "formik";
import { FC, useEffect } from "react";

import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormikCheckboxField } from "~/src/components/common/forms/FormikCheckboxField";
import { FormikFormButtons } from "~/src/components/common/forms/FormikFormButtons";
import { FormikSelectField } from "~/src/components/common/forms/FormikSelectField";
import { FormikTextField } from "~/src/components/common/forms/FormikTextField";

import { ReplyForm } from "./ReplyForm";
import { ReplyGroupFormValues as FormValues } from "./types";

export type Props = {
  onClose: () => void;
};

const kindOptions = [
  { label: "テキストボックス", value: "TEXT_BOX" },
  { label: "ラジオボタン", value: "RADIO_BUTTON" },
  { label: "チェックボックス", value: "CHECK_BOX" },
  { label: "セレクトボックス", value: "SELECT_BOX" },
];

export const ReplyGroupForm: FC<Props> = ({ onClose }) => {
  const { values, setFieldValue } = useFormikContext<FormValues>();

  useEffect(() => {
    if (!values.replies) return;
    if (values.replies.length === 0) return;
    if (values.kind === "TEXT_BOX") return;

    values.replies.forEach((_, index) => {
      setFieldValue(`replies.${index}.kind`, "OPTION");
    });
  }, [values, setFieldValue]);

  return (
    <Form>
      <VStack spacing="16px">
        <FormControl label="タイプ">
          <FormikSelectField name="kind" options={kindOptions} />
        </FormControl>
        <FormControl label="表示名">
          <FormikTextField type="text" mode="fill" name="label" />
        </FormControl>
        {values.kind !== "TEXT_BOX" && (
          <FormControl label="メッセージ分岐">
            <FormikCheckboxField name="divergingPoint" />
          </FormControl>
        )}
        <ReplyForm />
      </VStack>
      <FormikFormButtons onCancelClick={onClose} />
    </Form>
  );
};
