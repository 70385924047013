import { Box, HStack, Link, VStack, Wrap } from "@chakra-ui/react";
import { Link as LinkIcon } from "@mui/icons-material";
import { FC } from "react";

import { chakraFactory } from "~/src/lib/chakra-ui";

export type Props = {
  linkUrl?: string | null;
};

const StyledFlexWrapperBox = chakraFactory(Box, {
  baseStyle: {
    width: "100%",
    display: "flex",
    marginBottom: "8px",
  },
});

const StyledContentWrap = chakraFactory(Wrap, {
  baseStyle: {
    flex: "1",
    margin: "auto 0",
    fontSize: "12px",
    lineHeight: "17px",
    color: "#282828",
  },
});

const StyledLink = chakraFactory(Link, {
  baseStyle: {
    marginTop: "10px",
    color: "#1867C0",
    textDecoration: "underline",
  },
});

const StyledIframeWrap = chakraFactory(Box, {
  baseStyle: {
    alignSelf: "baseline",
  },
});

const StyledLabelText = chakraFactory("span", {
  baseStyle: {
    height: "22px",
    fontSize: "13px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#000000",
  },
});

export const PopupPatternDetailVideoContainer: FC<Props> = ({ linkUrl }) => (
  <StyledFlexWrapperBox>
    <HStack spacing="8px" w={[120, 180, 230]} height="40px" alignItems="center">
      <StyledLabelText>動画のURL</StyledLabelText>
    </HStack>
    <StyledContentWrap>
      {linkUrl !== undefined && linkUrl !== null ? (
        <VStack spacing="8px">
          <StyledLink href={linkUrl} isExternal>
            {linkUrl} <LinkIcon />
          </StyledLink>
          <StyledIframeWrap>
            <iframe title="popupVideo" src={linkUrl} />
          </StyledIframeWrap>
        </VStack>
      ) : (
        <Box>設定されていません</Box>
      )}
    </StyledContentWrap>
  </StyledFlexWrapperBox>
);
