/**
 * @generated SignedSource<<659542efe424dcc01dd04993aa0fd511>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Device = "MOBILE" | "TABLET" | "PC" | "%future added value";
export type PageKind = "LANDING" | "FORM" | "CONFIRM" | "THANKS" | "%future added value";
export type PopupReportKind = "POPUP_NAME" | "POPUP_ID" | "POPUP_PAGE" | "%future added value";
export type PopupReportOrderValue = "DATE" | "POPUP" | "POPUP_GROUP" | "FUNNEL" | "PAGE" | "PAGE_VIEW_USER" | "READY_USER" | "CLOSE_CLICK" | "CLICK_USER" | "CONVERSION_USER" | "IMP_CONVERSION_USER" | "READY_CONVERSION_USER" | "DROP_USER" | "DROP_CONVERSION_USER" | "UNIQUE_USER" | "TOTAL_CONVERSION_USER" | "CLICK_USER_RATE" | "CONVERSION_CLICK_RATE" | "CONVERSION_IMP_RATE" | "TOTAL_CONVERSION_RATE" | "CONVERSION_DROP_RATE" | "MOBILE_RATE" | "TABLET_RATE" | "PC_RATE" | "%future added value";
export type PopupReportKindInput = {
  value: PopupReportKind;
  isDateExist: boolean;
};
export type PopupReportFilterInput = {
  startDate: string;
  endDate: string;
  device?: Device | null;
  source?: string | null;
  funnelIds?: ReadonlyArray<string> | null;
  popupIds?: ReadonlyArray<string> | null;
  pageKind?: PageKind | null;
  pageIds?: ReadonlyArray<string> | null;
  popupNames?: ReadonlyArray<string> | null;
};
export type PopupReportOrderInput = {
  value: PopupReportOrderValue;
  isDesc?: boolean | null;
};
export type PopupPatternNameByDateReportContainer_Query$variables = {
  kind: PopupReportKindInput;
  filter: PopupReportFilterInput;
  order?: PopupReportOrderInput | null;
  slug: string;
};
export type PopupPatternNameByDateReportContainer_QueryVariables = PopupPatternNameByDateReportContainer_Query$variables;
export type PopupPatternNameByDateReportContainer_Query$data = {
  readonly site: {
    readonly " $fragmentSpreads": FragmentRefs<"PopupPatternNameByDateReport_site">;
  };
};
export type PopupPatternNameByDateReportContainer_QueryResponse = PopupPatternNameByDateReportContainer_Query$data;
export type PopupPatternNameByDateReportContainer_Query = {
  variables: PopupPatternNameByDateReportContainer_QueryVariables;
  response: PopupPatternNameByDateReportContainer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "kind"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "slug"
},
v4 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "popupName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "readyUser",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pageViewUser",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "closeClick",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clickUser",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "conversionUser",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "impConversionUser",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "readyConversionUser",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dropUser",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dropConversionUser",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uniqueUser",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalConversionUser",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clickUserRate",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "conversionClickRate",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "conversionImpRate",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "conversionDropRate",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalConversionRate",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mobileRate",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pcRate",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tabletRate",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PopupPatternNameByDateReportContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PopupPatternNameByDateReport_site"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "PopupPatternNameByDateReportContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "filter",
                "variableName": "filter"
              },
              {
                "kind": "Variable",
                "name": "kind",
                "variableName": "kind"
              },
              {
                "kind": "Variable",
                "name": "order",
                "variableName": "order"
              }
            ],
            "concreteType": "PopupReport",
            "kind": "LinkedField",
            "name": "reportPopup",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PopupReportRow",
                "kind": "LinkedField",
                "name": "rows",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "accessDate",
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "popupSlug",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "popupGroupSlug",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PopupReportRow",
                "kind": "LinkedField",
                "name": "summary",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "26c72c82e3d77ac64b36064038ba6e38",
    "id": null,
    "metadata": {},
    "name": "PopupPatternNameByDateReportContainer_Query",
    "operationKind": "query",
    "text": "query PopupPatternNameByDateReportContainer_Query(\n  $kind: PopupReportKindInput!\n  $filter: PopupReportFilterInput!\n  $order: PopupReportOrderInput\n  $slug: String!\n) {\n  site(slug: $slug) {\n    ...PopupPatternNameByDateReport_site\n    id\n  }\n}\n\nfragment PopupPatternNameByDateReport_site on Site {\n  slug\n  url\n  reportPopup(kind: $kind, filter: $filter, order: $order) {\n    rows {\n      popupName\n      accessDate\n      readyUser\n      pageViewUser\n      closeClick\n      clickUser\n      conversionUser\n      impConversionUser\n      readyConversionUser\n      dropUser\n      dropConversionUser\n      uniqueUser\n      totalConversionUser\n      clickUserRate\n      conversionClickRate\n      conversionImpRate\n      conversionDropRate\n      totalConversionRate\n      mobileRate\n      pcRate\n      tabletRate\n      popupSlug\n      popupGroupSlug\n    }\n    summary {\n      popupName\n      readyUser\n      pageViewUser\n      closeClick\n      clickUser\n      conversionUser\n      impConversionUser\n      readyConversionUser\n      dropUser\n      dropConversionUser\n      uniqueUser\n      totalConversionUser\n      clickUserRate\n      conversionClickRate\n      conversionImpRate\n      conversionDropRate\n      totalConversionRate\n      mobileRate\n      pcRate\n      tabletRate\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "72a8bcf77d8b267a17630628c43b41a2";

export default node;
