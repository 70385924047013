export const allTableItems = [
  // "チャットボット画像", // NOTE: バックエンド未実装
  { label: "配信人数", value: "pvUserCount" },
  { label: "利用人数", value: "startUserCount" },
  { label: "CV", value: "cvUserCount" },
  { label: "CTR", value: "ctr" },
  { label: "CVR", value: "cvr" },
];

export const initialSelectedTableItems = allTableItems.slice(0, 5);
