/**
 * @generated SignedSource<<8f1d89a3839384994e081b6e349c0a68>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationListPageContainer_Query$variables = {};
export type NotificationListPageContainer_QueryVariables = NotificationListPageContainer_Query$variables;
export type NotificationListPageContainer_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"NotificationListPage_root">;
};
export type NotificationListPageContainer_QueryResponse = NotificationListPageContainer_Query$data;
export type NotificationListPageContainer_Query = {
  variables: NotificationListPageContainer_QueryVariables;
  response: NotificationListPageContainer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 120
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationListPageContainer_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "NotificationListPage_root"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NotificationListPageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "NotificationConnection",
        "kind": "LinkedField",
        "name": "notifications",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "NotificationEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Notification",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "publishedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "category",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "body",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__id",
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": "notifications(first:120)"
      },
      {
        "alias": null,
        "args": (v0/*: any*/),
        "filters": [
          "published"
        ],
        "handle": "connection",
        "key": "NotificationListPage_root_notifications",
        "kind": "LinkedHandle",
        "name": "notifications"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "seenNotificationsCount",
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6f135dcbff362495cdce416fecdb879e",
    "id": null,
    "metadata": {},
    "name": "NotificationListPageContainer_Query",
    "operationKind": "query",
    "text": "query NotificationListPageContainer_Query {\n  ...NotificationListPage_root\n}\n\nfragment NotificationColumn_notification on Notification {\n  title\n  body\n}\n\nfragment NotificationListPage_root on Query {\n  notifications(first: 120) {\n    totalCount\n    edges {\n      node {\n        id\n        ...NotificationTableDataRow_notification\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  viewer {\n    role\n    seenNotificationsCount\n    id\n  }\n}\n\nfragment NotificationTableDataRow_notification on Notification {\n  id\n  slug\n  title\n  publishedAt\n  category\n  ...NotificationColumn_notification\n}\n"
  }
};
})();

(node as any).hash = "a95847d70eeec291732bc4b529487931";

export default node;
