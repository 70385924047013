import { Switch as ChakraSwitch, SwitchProps } from "@chakra-ui/react";
import { FC } from "react";

import { chakraFactory } from "~/src/lib/chakra-ui";

export type Props = SwitchProps;

const StyledSwitch = chakraFactory(ChakraSwitch, {
  baseStyle: {
    "> input": {
      // NOTE: Switch コンポーネント内の input 要素が overflow: auto を壊してスクロール領域下部に余白を作ってしまうので非表示に
      display: "none",
    },
  },
});

export const Switch: FC<Props> = (props) => {
  return <StyledSwitch color="#2F68BA" size="lg" {...props} />;
};
