/**
 * @generated SignedSource<<92a6e5ba73fddf0e717ccf1979a69d3b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DailyReportGraph_site$data = {
  readonly reportDaily: any;
  readonly " $fragmentType": "DailyReportGraph_site";
};
export type DailyReportGraph_site = DailyReportGraph_site$data;
export type DailyReportGraph_site$key = {
  readonly " $data"?: DailyReportGraph_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"DailyReportGraph_site">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "input"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "DailyReportGraph_site",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "input",
          "variableName": "input"
        }
      ],
      "kind": "ScalarField",
      "name": "reportDaily",
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "138c525b7d5df9d25d26967c534ea638";

export default node;
