import { Suspense } from "react";
import { Outlet } from "react-router-dom";

import { PageLoading } from "~/src/components/common/PageLoading";
import { HeaderWithSidebarLayoutContainer } from "~/src/components/features/global/HeaderWithSidebarLayout";

export const HeaderWithSidebarLayoutOutlet = () => (
  <Suspense fallback={<PageLoading />}>
    <HeaderWithSidebarLayoutContainer>
      <Suspense fallback={<PageLoading />}>
        <Outlet />
      </Suspense>
    </HeaderWithSidebarLayoutContainer>
  </Suspense>
);
