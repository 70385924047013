import { VStack } from "@chakra-ui/react";
import { FilterAltOutlined as FilterAltOutlinedIcon } from "@mui/icons-material";
import { Form, Formik } from "formik";
import { FC, useCallback, useMemo } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import * as yup from "yup";

import { GraphQLEnums } from "~/src/__generated__/GraphQLEnums";
import { Device, DistributionStatus } from "~/src/__generated__/schema";
import { PopupPatternFilterButton_Query } from "~/src/__relay_artifacts__/PopupPatternFilterButton_Query.graphql";
import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormikFormButtons } from "~/src/components/common/forms/FormikFormButtons";
import { FormikSelectField } from "~/src/components/common/forms/FormikSelectField";
import { IconButton } from "~/src/components/common/IconButton";
import { Modal } from "~/src/components/common/Modal";
import { FilteredTag } from "~/src/components/features/filter";
import { useOpenState } from "~/src/lib/hooks";

export type FormValues = {
  sourceId: string | null;
  device: Device | null;
  withArchived: boolean | null;
  status: DistributionStatus | null;
};

export type Props = {
  filterValues: FormValues;
  onFilterChange: (filterValues: FormValues) => void;
  onSubmit: (values: FormValues) => Promise<void> | void;
};

const query = graphql`
  query PopupPatternFilterButton_Query {
    sources {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const validationSchema = yup.object({
  sourceId: yup.string().nullable(),
  device: yup.string().nullable(),
  withArchived: yup.boolean().nullable(),
  status: yup.string().nullable(),
});

export const PopupPatternFilterButton: FC<Props> = ({
  filterValues,
  onFilterChange,
  onSubmit,
}) => {
  const { isOpen, onClose, onOpen } = useOpenState();

  const { sources } = useLazyLoadQuery<PopupPatternFilterButton_Query>(
    query,
    {}
  );

  const sourceOptions = useMemo(
    () => [
      { label: "すべて", value: null },
      ...(sources.edges || []).map((edge) => {
        const node = edge?.node;
        if (!node) throw new Error("assertion failed");
        return { label: node.name, value: node.id };
      }),
    ],
    [sources.edges]
  );

  const deviceOptions = useMemo(
    () => [{ label: "すべて", value: null }, ...GraphQLEnums.Device],
    []
  );

  const statusOptions = useMemo(
    () => [
      { label: "すべて", value: null },
      ...GraphQLEnums.DistributionStatus,
    ],
    []
  );

  const includeArchiveOptions = useMemo(
    () => [
      { label: "デフォルト（アーカイブ含まない）", value: null },
      { label: "アーカイブ含む", value: true },
    ],
    []
  );

  const handleFilterChange = useCallback(
    (values: Partial<FormValues>) => () => {
      onFilterChange({ ...filterValues, ...values });
    },
    [filterValues, onFilterChange]
  );

  const handleSubmit = useCallback(
    (values: FormValues) => {
      onSubmit(values);
      onClose();
    },
    [onClose, onSubmit]
  );

  return (
    <>
      {filterValues.sourceId && (
        <FilteredTag
          name="流入元"
          value={filterValues.sourceId}
          options={sourceOptions}
          onClear={handleFilterChange({ sourceId: null })}
        />
      )}
      {filterValues.device && (
        <FilteredTag
          name="デバイス"
          value={filterValues.device}
          options={deviceOptions}
          onClear={handleFilterChange({ device: null })}
        />
      )}
      {filterValues.withArchived && (
        <FilteredTag
          name="アーカイブを含めるか"
          value={filterValues.withArchived}
          options={includeArchiveOptions}
          onClear={handleFilterChange({ withArchived: null })}
        />
      )}
      {filterValues.status && (
        <FilteredTag
          name="実施ステータス"
          value={filterValues.status}
          options={statusOptions}
          onClear={handleFilterChange({ status: null })}
        />
      )}
      <IconButton
        size="24px"
        icon={
          <FilterAltOutlinedIcon
            color={
              Object.values(filterValues).some((value) => value != null)
                ? "primary"
                : undefined
            }
          />
        }
        onClick={onOpen}
      />
      <Modal title="フィルタ" isOpen={isOpen} onClose={onClose}>
        <Formik<FormValues>
          enableReinitialize
          initialValues={filterValues}
          validationSchema={validationSchema}
          validateOnMount={false}
          validateOnChange={false}
          onSubmit={handleSubmit}
        >
          <Form>
            <VStack spacing="16px">
              <FormControl label="流入元">
                <FormikSelectField name="sourceId" options={sourceOptions} />
              </FormControl>
              <FormControl label="デバイス">
                <FormikSelectField name="device" options={deviceOptions} />
              </FormControl>
              <FormControl label="アーカイブを含めるか">
                <FormikSelectField
                  name="withArchived"
                  options={includeArchiveOptions}
                />
              </FormControl>
              <FormControl label="実施ステータス">
                <FormikSelectField name="status" options={statusOptions} />
              </FormControl>
            </VStack>
            <FormikFormButtons onCancelClick={onClose} />
          </Form>
        </Formik>
      </Modal>
    </>
  );
};
