import { FC } from "react";

import { GraphQLEnums } from "~/src/__generated__/GraphQLEnums";
import { UserRole } from "~/src/__generated__/schema";
import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormikSelectField } from "~/src/components/common/forms/FormikSelectField";
import { FormikTextField } from "~/src/components/common/forms/FormikTextField";

type Props = {
  role: UserRole;
};

export const PopupVideoFormContainer: FC<Props> = ({ role }) => {
  return (
    <>
      <FormControl label="動画のURL">
        <FormikTextField
          name={`popupVideo.video`}
          type="text"
          mode="fill"
          placeholder="https://"
        />
      </FormControl>
      {(role === "ADMIN" ||
        role === "SUPER" ||
        role === "ADMIN_ALL" ||
        role === "CLIENT") && (
        <>
          <FormControl label="画像クリック時のURL">
            <FormikTextField
              type="text"
              mode="fill"
              name={`popupVideo.linkUrl`}
              placeholder="https://"
            />
          </FormControl>
          <FormControl label="画面比率">
            <FormikSelectField
              name="popupVideo.screenType"
              options={GraphQLEnums.PopupVideoScreenKind}
            />
          </FormControl>
        </>
      )}
    </>
  );
};
