import { useToast } from "@chakra-ui/toast";
import {
  ArchiveOutlined as ArchiveOutlinedIcon,
  DeleteOutlined as DeleteOutlinedIcon,
  EditOutlined as EditOutlinedIcon,
  UnarchiveOutlined as UnarchiveOutlinedIcon,
} from "@mui/icons-material";
import { FC, useCallback, useMemo } from "react";
import { graphql } from "react-relay";

import { ChatbotPatternTableActions_DeleteMutation } from "~/src/__relay_artifacts__/ChatbotPatternTableActions_DeleteMutation.graphql";
import { TableActions } from "~/src/components/common/tables/TableActions";
import {
  useArchiveHandler,
  useUnarchiveHandler,
} from "~/src/components/features/archive";
import { DeleteConfirmDialog } from "~/src/components/features/global/DeleteConfirmDialog";
import { useOpenState } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";

type Props = {
  chatbot: {
    id: string;
    slug: string;
    name: string | null;
    isArchive: boolean;
  };
  siteSlug: string;
  chatbotGroupSlug: string;
  connectionId: string;
};

const deleteMutation = graphql`
  mutation ChatbotPatternTableActions_DeleteMutation(
    $input: DeleteChatbotInput!
    $connections: [ID!]!
  ) {
    deleteChatbot(input: $input) {
      deletedChatbotId @deleteEdge(connections: $connections)
    }
  }
`;

export const ChatbotPatternTableAcitons: FC<Props> = ({
  siteSlug,
  chatbotGroupSlug,
  chatbot,
  connectionId,
}) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useOpenState();

  const deleteMutate =
    useMutationCommit<ChatbotPatternTableActions_DeleteMutation>(
      deleteMutation
    );

  const handleArchive = useArchiveHandler(chatbot.id, connectionId);
  const handleUnarchive = useUnarchiveHandler(chatbot.id, connectionId);

  const handleDelete = useCallback(async () => {
    try {
      const res = await deleteMutate({
        variables: {
          input: {
            chatbotId: chatbot.id,
          },
          connections: [connectionId],
        },
      });
      if (!res.deleteChatbot?.deletedChatbotId)
        throw new Error("assertion failed");

      toast({
        title: `${chatbot.name}を削除しました`,
        status: "success",
      });
    } catch (err) {
      toast({ title: "削除に失敗しました", status: "error" });
    }
  }, [connectionId, deleteMutate, chatbot.id, chatbot.name, toast]);

  const actionMenus = useMemo(
    () => [
      {
        label: "編集",
        icon: <EditOutlinedIcon />,
        to: `/sites/${siteSlug}/chatbots/${chatbotGroupSlug}/patterns/${chatbot.slug}/edit`,
      },
      chatbot.isArchive
        ? [
            {
              label: "アーカイブから戻す",
              icon: <UnarchiveOutlinedIcon />,
              onClick: () => handleUnarchive(),
            },
            {
              label: "削除",
              icon: <DeleteOutlinedIcon />,
              onClick: () => onOpen(),
            },
          ]
        : {
            label: "アーカイブ",
            icon: <ArchiveOutlinedIcon />,
            onClick: () => handleArchive(),
          },
    ],
    [
      siteSlug,
      chatbotGroupSlug,
      chatbot.slug,
      chatbot.isArchive,
      handleUnarchive,
      onOpen,
      handleArchive,
    ]
  );

  return (
    <>
      <TableActions actionMenus={actionMenus.flat()} />
      <DeleteConfirmDialog
        name={chatbot.name}
        isOpen={isOpen}
        onClose={onClose}
        onDelete={handleDelete}
      />
    </>
  );
};
