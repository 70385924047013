import { useState } from "react";

import { useDateRangeSearchParams } from "~/src/lib/hooks";

import { DateRangeContext } from "./DateRangeField";

type Props = {
  children: React.ReactNode;
};

export const DateRangeProvider = ({ children }: Props) => {
  const {
    defaultDuration: { startOn, endOn },
  } = useDateRangeSearchParams();
  const [dateRange, setDateRange] = useState<[Date, Date]>([startOn, endOn]);

  return (
    <DateRangeContext.Provider value={{ dateRange, setDateRange }}>
      {children}
    </DateRangeContext.Provider>
  );
};
