import { FC } from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";

import { FunnelSourceListPageContainer_Query } from "~/src/__relay_artifacts__/FunnelSourceListPageContainer_Query.graphql";
import { useFunnelSourceListPageParams } from "~/src/routes/hooks";

import { FunnelSourceListPage } from "./presentations";

const query = graphql`
  query FunnelSourceListPageContainer_Query($funnelSlug: String!) {
    funnel(slug: $funnelSlug) {
      ...FunnelSourceListPage_funnel
    }
  }
`;

export const FunnelSourceListPageContainer: FC = () => {
  const { siteSlug, funnelSlug } = useFunnelSourceListPageParams();

  const { funnel } = useLazyLoadQuery<FunnelSourceListPageContainer_Query>(
    query,
    {
      funnelSlug,
    }
  );

  return <FunnelSourceListPage funnelRef={funnel} siteSlug={siteSlug} />;
};
