import { FC, useEffect, useMemo } from "react";
import { graphql, usePaginationFragment } from "react-relay";

import {
  ChatbotPatternTable_chatbotGroup,
  ChatbotPatternTable_chatbotGroup$key,
} from "~/src/__relay_artifacts__/ChatbotPatternTable_chatbotGroup.graphql";
import {
  Table,
  TableHeaderColumn,
  TableHeaderRow,
} from "~/src/components/common/tables/Table";
import {
  TablePagination,
  useTablePaginationProps,
} from "~/src/components/common/tables/TablePagination";
import { useUpdateDebounce } from "~/src/lib/react-use";

import { FormValues } from "./ChatbotPatternFilterButton";
import { ChatbotPatternTableDataRow } from "./ChatbotPatternTableDataRow";

type Props = {
  chatbotGroupRef: ChatbotPatternTable_chatbotGroup$key;
  filterValues: FormValues;
  siteSlug: string;
  headerColumns: string[];
  dataColumns: string[];
  onChatbotsChange: (chatbots: Chatbot[]) => void;
};

export type Chatbot = NonNullable<
  NonNullable<
    NonNullable<ChatbotPatternTable_chatbotGroup["chatbots"]["edges"]>[0]
  >["node"]
>;

const fragment = graphql`
  fragment ChatbotPatternTable_chatbotGroup on ChatbotGroup
  @refetchable(queryName: "ChatbotPatternTable_Query")
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 120 }
    cursor: { type: "String" }
    withArchived: { type: "Boolean" }
    analyzerInput: { type: "ReportAnalyzerInput!" }
    status: { type: "DistributionStatus" }
  ) {
    slug
    chatbots(
      first: $count
      after: $cursor
      withArchived: $withArchived
      orderBy: { field: KIND, direction: ASC }
      status: $status
    ) @connection(key: "ChatbotPatternTable_chatbotGroup_chatbots") {
      __id
      totalCount
      edges {
        node {
          id
          slug
          name
          weight
          ...ChatbotPatternTableDataRow_chatbot
            @arguments(input: $analyzerInput)
        }
      }
    }
  }
`;

export const ChatbotPatternTable: FC<Props> = ({
  chatbotGroupRef,
  filterValues,
  siteSlug,
  headerColumns,
  dataColumns,
  onChatbotsChange,
}) => {
  const {
    data: chatbotGroup,
    hasNext,
    loadNext,
    refetch,
  } = usePaginationFragment(fragment, chatbotGroupRef);
  const { tablePaginationProps } = useTablePaginationProps({
    hasNext,
    loadNext,
    refetch,
    totalCount: chatbotGroup.chatbots.totalCount,
  });

  const chatbots = useMemo(() => {
    const from = tablePaginationProps.from;
    const to = tablePaginationProps.to;
    const egdes = chatbotGroup.chatbots.edges || [];
    return egdes.slice(from, to).map((edge) => {
      const node = edge?.node;
      if (!node) throw new Error("assertion failed");
      return node;
    });
  }, [
    chatbotGroup.chatbots.edges,
    tablePaginationProps.from,
    tablePaginationProps.to,
  ]);

  useEffect(() => {
    onChatbotsChange(chatbots);
  }, [chatbots, onChatbotsChange]);

  useUpdateDebounce(
    () =>
      refetch({
        count: tablePaginationProps.perPage,
        ...filterValues,
      }),
    500,
    [filterValues, refetch, tablePaginationProps.perPage]
  );

  return (
    <>
      <Table>
        <TableHeaderRow>
          <TableHeaderColumn>パターン名</TableHeaderColumn>
          <TableHeaderColumn>実施ステータス</TableHeaderColumn>
          <TableHeaderColumn>配信比率</TableHeaderColumn>
          {headerColumns.map((headerColumn) => (
            <TableHeaderColumn key={headerColumn}>
              {headerColumn}
            </TableHeaderColumn>
          ))}
          <TableHeaderColumn>アクション</TableHeaderColumn>
        </TableHeaderRow>
        {chatbots.map((chatbot) => {
          return (
            <ChatbotPatternTableDataRow
              key={chatbot.slug}
              chatbotRef={chatbot}
              siteSlug={siteSlug}
              chatbotGroupSlug={chatbotGroup.slug}
              connectionId={chatbotGroup.chatbots.__id}
              dataColumns={dataColumns}
              filterValues={filterValues}
            />
          );
        })}
      </Table>
      <TablePagination {...tablePaginationProps} />
    </>
  );
};
