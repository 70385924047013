import { Box, Flex, HStack, Spacer, VStack } from "@chakra-ui/react";
import { FC, useMemo } from "react";
import { graphql, useFragment } from "react-relay";
import { NavLink } from "react-router-dom";

import { GraphQLEnums } from "~/src/__generated__/GraphQLEnums";
import { PopupGroupDetailSection_popupGroup$key } from "~/src/__relay_artifacts__/PopupGroupDetailSection_popupGroup.graphql";
import { OutlineButton } from "~/src/components/common/Button";
import { Chip } from "~/src/components/common/Chip";
import { DefinitionTeam } from "~/src/components/common/DefinitionTeam";
import { SectionCard } from "~/src/components/common/SectionCard";
import { Switch } from "~/src/components/common/Switch";

import { PopupDistributionSettingSection } from "./PopupDistributionSettingSection";
import { PopupGroupDeliverDayOfWeeks } from "./PopupGroupDeliverDayOfWeeks";

type Props = {
  readonly popupGroupRef: PopupGroupDetailSection_popupGroup$key;
  readonly siteSlug: string;
};

const popupGroupFragment = graphql`
  fragment PopupGroupDetailSection_popupGroup on PopupGroup {
    ageRanges
    annualIncomes
    browsers
    devices
    deliverKind
    genders
    id
    isMulti
    judgeStatus
    memo
    name
    page {
      name
      funnel {
        slug
        name
      }
    }
    platforms
    repeat
    redisplayPopupTime
    slug
    status
    visitMatchType
    ...PopupGroupDeliverDayOfWeeks_popupGroup
    ...PopupDistributionSettingSection_popupGroup
  }
`;

export const PopupGroupDetailSection: FC<Props> = ({
  siteSlug,
  popupGroupRef,
}) => {
  const popupGroup = useFragment(popupGroupFragment, popupGroupRef);

  const devices = useMemo(
    () =>
      (popupGroup.devices || [])
        .map((device) => ({
          label:
            GraphQLEnums.Device.find((d) => d.value === device)?.label || "",
          value: device,
        }))
        .filter(({ label }) => label !== ""),
    [popupGroup.devices]
  );

  const platforms = useMemo(
    () =>
      (popupGroup.platforms || [])
        .map((platform) => ({
          label:
            GraphQLEnums.Platform.find((d) => d.value === platform)?.label ||
            "",
          value: platform,
        }))
        .filter(({ label }) => label !== ""),
    [popupGroup.platforms]
  );

  (popupGroup.platforms || []).map((platform) => {
    const label = GraphQLEnums.Platform.find(
      (d) => d.value === platform
    )?.label;
    return label && <Chip label={label} key={platform} />;
  });

  const browsers = useMemo(
    () =>
      (popupGroup.browsers || [])
        .map((browser) => ({
          label:
            GraphQLEnums.Browser.find((d) => d.value === browser)?.label || "",
          value: browser,
        }))
        .filter(({ label }) => label !== ""),
    [popupGroup.browsers]
  );

  const genders = useMemo(
    () =>
      (popupGroup.genders || [])
        .map((gender) => ({
          label:
            GraphQLEnums.Gender.find((d) => d.value === gender)?.label || "",
          value: gender,
        }))
        .filter(({ label }) => label !== ""),
    [popupGroup.genders]
  );

  const ageRanges = useMemo(
    () =>
      (popupGroup.ageRanges || [])
        .map((ageRange) => ({
          label:
            GraphQLEnums.AgeRange.find((d) => d.value === ageRange)?.label ||
            "",
          value: ageRange,
        }))
        .filter(({ label }) => label !== ""),
    [popupGroup.ageRanges]
  );

  const annualIncomes = useMemo(
    () =>
      (popupGroup.annualIncomes || [])
        .map((annualIncome) => ({
          label:
            GraphQLEnums.AnnualIncome.find((d) => d.value === annualIncome)
              ?.label || "",
          value: annualIncome,
        }))
        .filter(({ label }) => label !== ""),
    [popupGroup.annualIncomes]
  );

  const deliverKind = useMemo(
    () =>
      GraphQLEnums.DeliverKind.find((d) => d.value === popupGroup.deliverKind)
        ?.label || "",
    [popupGroup.deliverKind]
  );

  return (
    <>
      <Flex alignItems="center">
        <Box color="#282828" fontWeight="bold">
          基本設定
        </Box>
        <Spacer />
        <HStack spacing={3}>
          <NavLink
            to={`/sites/${siteSlug}/popup_groups/${popupGroup.slug}/edit`}
          >
            <OutlineButton>編集</OutlineButton>
          </NavLink>
        </HStack>
      </Flex>

      <VStack mt={4}>
        <SectionCard>
          <DefinitionTeam label="ポップアップグループ名">
            {popupGroup.name}
          </DefinitionTeam>
          <DefinitionTeam label="実施するファネル">
            {popupGroup.page.funnel.name}
          </DefinitionTeam>
          <DefinitionTeam label="実施するページ">
            {popupGroup.page.name}
          </DefinitionTeam>
          <DefinitionTeam label="期間設定">
            <Switch isChecked={popupGroup.repeat} isReadOnly />
            {popupGroup.repeat && (
              <PopupGroupDeliverDayOfWeeks popupGroupRef={popupGroup} />
            )}
          </DefinitionTeam>
          <DefinitionTeam label="再表示禁止期間">
            {popupGroup.redisplayPopupTime || 0}分
          </DefinitionTeam>
          <DefinitionTeam label="メモ">
            <Box wordBreak={"break-all"}>{popupGroup.memo}</Box>
          </DefinitionTeam>
        </SectionCard>
      </VStack>

      <VStack mt={4}>
        <SectionCard description="セグメント設定">
          <DefinitionTeam label="デバイス">
            {devices.map(({ label, value }) => (
              <Chip key={value} label={label} />
            ))}
          </DefinitionTeam>
          <DefinitionTeam label="プラットフォーム">
            {platforms.map(({ label, value }) => (
              <Chip key={value} label={label} />
            ))}
          </DefinitionTeam>
          <DefinitionTeam label="ブラウザ">
            {browsers.map(({ label, value }) => (
              <Chip key={value} label={label} />
            ))}
          </DefinitionTeam>
          <DefinitionTeam label="性別">
            {genders.map(({ label, value }) => (
              <Chip key={value} label={label} />
            ))}
          </DefinitionTeam>
          <DefinitionTeam label="年齢">
            {ageRanges.map(({ label, value }) => (
              <Chip key={value} label={label} />
            ))}
          </DefinitionTeam>
          <DefinitionTeam label="年収">
            {annualIncomes.map(({ label, value }) => (
              <Chip key={value} label={label} />
            ))}
          </DefinitionTeam>
        </SectionCard>
      </VStack>

      <VStack mt={4}>
        <PopupDistributionSettingSection popupGroupRef={popupGroup} />
      </VStack>

      <VStack mt={4}>
        <SectionCard>
          <DefinitionTeam label="配信タイプ">
            {popupGroup.isMulti ? "マルチ配信" : "シングル配信"}
          </DefinitionTeam>
          <DefinitionTeam label="配信方法">{deliverKind}</DefinitionTeam>
        </SectionCard>
      </VStack>
    </>
  );
};
