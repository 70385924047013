/**
 * @generated SignedSource<<450b70530280d85480fab2e45a423b7d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Device = "MOBILE" | "TABLET" | "PC" | "%future added value";
export type OrderDirection = "ASC" | "DESC" | "%future added value";
export type PageKind = "LANDING" | "FORM" | "CONFIRM" | "THANKS" | "%future added value";
export type SiteReportOrderField = "UNIQUE_USER" | "CONVERSION" | "CVR" | "LP_DROP_RATE" | "LP_SCROLL_RATE" | "LP_AVERAGE_STAY_TIME" | "FORM_DROP_RATE" | "MOBILE_RATE" | "TABLET_RATE" | "PC_RATE" | "DEVICE" | "PAGE_VIEW_USER" | "USE_CHATBOT_USER" | "USE_CHATBOT_USER_RATE" | "CHATBOT_CVR" | "POPUP_READY_USER" | "CLOSE_POPUP" | "CLICK_POPUP" | "POPUP_CLICK_CONVERSION" | "POPUP_IMPRESSION_CONVERSION" | "POPUP_READY_CONVERSION" | "POPUP_DROP" | "POPUP_DROP_CONVERSION" | "POPUP_CONVERSION" | "POPUP_CTR" | "POPUP_CVR" | "POPUP_CLICK_CVR" | "POPUP_IMPRESSION_CVR" | "POPUP_DROP_CVR" | "%future added value";
export type ReportInput = {
  startDate?: string | null;
  endDate?: string | null;
  orderBy?: SiteReportOrder | null;
  device?: Device | null;
  sourceId?: string | null;
  funnelIds?: ReadonlyArray<string> | null;
  pageKind?: PageKind | null;
};
export type SiteReportOrder = {
  field: SiteReportOrderField;
  direction: OrderDirection;
};
export type PublisherReportContainer_Query$variables = {
  input: ReportInput;
  slug: string;
};
export type PublisherReportContainer_QueryVariables = PublisherReportContainer_Query$variables;
export type PublisherReportContainer_Query$data = {
  readonly site: {
    readonly " $fragmentSpreads": FragmentRefs<"PublisherReportTable_site" | "PublisherReportGraph_site">;
  };
};
export type PublisherReportContainer_QueryResponse = PublisherReportContainer_Query$data;
export type PublisherReportContainer_Query = {
  variables: PublisherReportContainer_QueryVariables;
  response: PublisherReportContainer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PublisherReportContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PublisherReportTable_site"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PublisherReportGraph_site"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PublisherReportContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "input",
                "variableName": "input"
              }
            ],
            "kind": "ScalarField",
            "name": "reportPublisher",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8c2922e5b18c364d26cf3813124c8c06",
    "id": null,
    "metadata": {},
    "name": "PublisherReportContainer_Query",
    "operationKind": "query",
    "text": "query PublisherReportContainer_Query(\n  $input: ReportInput!\n  $slug: String!\n) {\n  site(slug: $slug) {\n    ...PublisherReportTable_site\n    ...PublisherReportGraph_site\n    id\n  }\n}\n\nfragment PublisherReportGraph_site on Site {\n  reportPublisher(input: $input)\n}\n\nfragment PublisherReportTable_site on Site {\n  reportPublisher(input: $input)\n}\n"
  }
};
})();

(node as any).hash = "12db412af99a45021d8438ec45fe9aa9";

export default node;
