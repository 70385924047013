import { Box, Flex, HStack, Spacer } from "@chakra-ui/react";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { graphql, usePaginationFragment } from "react-relay";
import { NavLink } from "react-router-dom";

import { PopupGroupOrder, UserRole } from "~/src/__generated__/schema";
import { PopupListPage_site$key } from "~/src/__relay_artifacts__/PopupListPage_site.graphql";
import { SolidButton } from "~/src/components/common/Button";
import {
  Table,
  TableHeaderCheckboxColumn,
  TableHeaderColumn,
  TableHeaderRow,
} from "~/src/components/common/tables/Table";
import { useTableItemSelect } from "~/src/components/common/tables/TableItemSelect";
import {
  TablePagination,
  useTablePaginationProps,
} from "~/src/components/common/tables/TablePagination";
import { TableSearchField } from "~/src/components/common/tables/TableSearchField";
import { ListFilterFormValues as FormValues } from "~/src/components/features/filter";
import { PageLayout } from "~/src/components/features/global/PageLayout";
import { TableItemSelectButton } from "~/src/components/features/global/TableItemSelectButton";
import { useBulkCheckboxes } from "~/src/lib/hooks";
import { useUpdateDebounce } from "~/src/lib/react-use";

import { allTableItems, initialSelectedTableItems } from "./constants";
import { PopupBulkOperaionSelect } from "./PopupBulkOperationSelect";
import { PopupFilterButton } from "./PopupFilterButton";
import { PopupTableDataRow } from "./PopupTableDataRow";

export type Props = {
  role: UserRole;
  siteRef: PopupListPage_site$key;
  filterValues: FormValues;
  setFilterValues: Dispatch<SetStateAction<FormValues>>;
};

const siteFragment = graphql`
  fragment PopupListPage_site on Site
  @refetchable(queryName: "PopupListPage_Query")
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 120 }
    cursor: { type: "String" }
    searchTerm: { type: "String" }
    pageIds: { type: "[ID!]" }
    funnelIds: { type: "[ID!]" }
    pageKind: { type: "PageKind" }
    sourceId: { type: "ID" }
    device: { type: "Device" }
    withArchived: { type: "Boolean" }
    status: { type: "DistributionStatus" }
    order: {
      type: "PopupGroupOrder"
      defaultValue: { field: NAME, direction: DESC }
    }
  ) {
    slug
    popupGroups(
      first: $count
      after: $cursor
      searchTerm: $searchTerm
      pageIds: $pageIds
      funnelIds: $funnelIds
      sourceId: $sourceId
      device: $device
      withArchived: $withArchived
      orderBy: $order
      status: $status
      pageKind: $pageKind
    ) @connection(key: "PopupListPage_popupGroups") {
      __id
      totalCount
      edges {
        node {
          slug
          ...PopupTableDataRow_popupGroup
        }
      }
    }
  }
`;

export const PopupListPage: FC<Props> = ({
  siteRef,
  role,
  filterValues,
  setFilterValues,
}) => {
  const [searchText, setSearchText] = useState("");
  const [sort, setSort] = useState<PopupGroupOrder>({
    field: "NAME",
    direction: "DESC",
  });

  const {
    data: site,
    loadNext,
    hasNext,
    refetch,
  } = usePaginationFragment(siteFragment, siteRef);
  const { tablePaginationProps } = useTablePaginationProps({
    totalCount: site.popupGroups.totalCount,
    hasNext,
    loadNext,
    refetch,
  });
  const { selectedTableItems, dataColumns, onApplyClick } = useTableItemSelect({
    cacheKey: "PopupListPage",
    initialSelectedTableItems,
  });
  const handleSortClick = useCallback((params) => setSort(params), []);

  const popups = useMemo(() => {
    const from = tablePaginationProps.from;
    const to = tablePaginationProps.to;
    const egdes = site.popupGroups.edges || [];
    return egdes.slice(from, to).map((edge) => {
      const node = edge?.node;
      if (!node) throw new Error("assertion failed");
      return node;
    });
  }, [
    site.popupGroups.edges,
    tablePaginationProps.from,
    tablePaginationProps.to,
  ]);

  const {
    selectedSlugs,
    isAllChecked,
    handleAllCheckboxClick,
    handleCheckboxClick,
  } = useBulkCheckboxes(popups);

  useUpdateDebounce(
    () =>
      refetch({
        searchTerm: searchText,
        count: tablePaginationProps.perPage,
        order: {
          field: sort.field,
          direction: sort.direction,
        },
        ...filterValues,
      }),
    500,
    [filterValues, refetch, searchText, tablePaginationProps.perPage, sort]
  );

  return (
    <PageLayout title="ポップアップ">
      <Box my="16px">
        <HStack justifyContent="flex-end" mb="16px" spacing="16px">
          <NavLink to={`/sites/${site.slug}/popup_groups/new`}>
            <SolidButton>登録</SolidButton>
          </NavLink>
        </HStack>
        <Flex alignItems="flex-end">
          <PopupBulkOperaionSelect selectedSlug={selectedSlugs} />
          <TableSearchField searchText={searchText} onChange={setSearchText} />
          <Spacer />
          <HStack spacing="12px">
            <PopupFilterButton
              siteSlug={site.slug}
              filterValues={filterValues}
              onFilterChange={setFilterValues}
              onSubmit={setFilterValues}
            />
            <TableItemSelectButton
              allTableItems={allTableItems}
              selectedTableItems={selectedTableItems}
              onApplyClick={onApplyClick}
            />
          </HStack>
        </Flex>
        <Table>
          <TableHeaderRow>
            <TableHeaderCheckboxColumn
              isChecked={isAllChecked}
              onChange={handleAllCheckboxClick}
            />
            <TableHeaderColumn field={"NAME"} onSortClick={handleSortClick}>
              ポップアップ名
            </TableHeaderColumn>
            <TableHeaderColumn>実施ステータス</TableHeaderColumn>
            {role !== "SAAS_CLIENT" && (
              <TableHeaderColumn>承認状態</TableHeaderColumn>
            )}
            <TableHeaderColumn>画像</TableHeaderColumn>
            {selectedTableItems.map((item) =>
              item.orderField ? (
                <TableHeaderColumn
                  key={item.label}
                  field={item.orderField}
                  onSortClick={handleSortClick}
                >
                  {item.label}
                </TableHeaderColumn>
              ) : (
                <TableHeaderColumn key={item.label}>
                  {item.label}
                </TableHeaderColumn>
              )
            )}
            <TableHeaderColumn>アクション</TableHeaderColumn>
          </TableHeaderRow>
          {popups.map((popup) => (
            <PopupTableDataRow
              key={popup.slug}
              popupRef={popup}
              siteSlug={site.slug}
              dataColumns={dataColumns}
              isChecked={selectedSlugs.includes(popup.slug)}
              onCheck={handleCheckboxClick}
              connectionId={site.popupGroups.__id}
              role={role}
            />
          ))}
        </Table>
        <TablePagination {...tablePaginationProps} />
      </Box>
    </PageLayout>
  );
};
