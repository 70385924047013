import { groupBy, includes, prop, uniqBy } from "ramda";
import { FC, useCallback, useMemo, useState } from "react";
import { graphql, useFragment } from "react-relay";

import { PrefectureReportGraph_site$key } from "~/src/__relay_artifacts__/PrefectureReportGraph_site.graphql";

import { AreaGraph } from "../AreaGraph";
import {
  MeasureKey,
  findGraphTitleByMeasureKey,
  generateGraphDataArray,
  isMeasureKey,
  measureOptions,
} from "../utils";

const fragment = graphql`
  fragment PrefectureReportGraph_site on Site {
    reportPrefecture(input: $input)
  }
`;

export type Props = {
  siteRef: PrefectureReportGraph_site$key;
};

type FormValues = {
  prefectures: string[];
  measureKey: MeasureKey | null;
};

export const PrefectureReportGraph: FC<Props> = ({ siteRef }) => {
  const { reportPrefecture } = useFragment<typeof siteRef>(fragment, siteRef);

  const [formValues, setFormValues] = useState<FormValues>({
    prefectures: [],
    measureKey: null,
  });

  const handleSubmit = useCallback(({ prefectures, measureKey }) => {
    if (measureKey === null || isMeasureKey(measureKey, measureOptions)) {
      setFormValues({ prefectures, measureKey });
    }
  }, []);

  const prefectureOptions = useMemo(() => {
    const uniquePrefectures = uniqBy<any, any>(
      prop("prefecture_id"),
      reportPrefecture.daily_report
    );
    return uniquePrefectures.map((row) => ({
      value: row.prefecture_id,
      label: row.prefecture_id,
    }));
  }, [reportPrefecture]);

  const graphDataArray = useMemo(() => {
    if (formValues.prefectures.length === 0) {
      return generateGraphDataArray(
        [groupBy<any, any>(prop("access_date"), reportPrefecture.daily_report)],
        formValues.measureKey || "UNIQUE_USER"
      );
    }

    const reports = reportPrefecture.daily_report as any[];
    const filteredReports = reports.filter((row) =>
      includes(row.prefecture_id, formValues.prefectures)
    );

    const reportsByPrefecture = groupBy<any, any>(
      prop("prefecture_id"),
      filteredReports
    );
    const reportsByPrefectureAndDate: Record<any, any[]>[] = [];
    Object.keys(reportsByPrefecture).forEach((key) => {
      reportsByPrefectureAndDate.push(
        groupBy<any, any>(prop("access_date"), reportsByPrefecture[key])
      );
    });

    return generateGraphDataArray(
      reportsByPrefectureAndDate,
      formValues.measureKey || "UNIQUE_USER"
    );
  }, [reportPrefecture, formValues]);

  return (
    <AreaGraph
      title={`日別推移｜${findGraphTitleByMeasureKey(formValues.measureKey)}`}
      label={`${findGraphTitleByMeasureKey(formValues.measureKey)}`}
      dataArray={graphDataArray}
      graphCustomizedButtonProps={{
        selectFields: [
          {
            name: "prefectures",
            label: "都道府県",
            options: prefectureOptions,
            isMultiple: true,
          },
          {
            name: "measureKey",
            label: "項目",
            options: measureOptions,
            isMultiple: false,
          },
        ],
        formValues,
        onSubmit: handleSubmit,
      }}
    />
  );
};
