import { Box, Text, VStack } from "@chakra-ui/react";
import {
  AddCircle as AddCircleIcon,
  ContentCopy as ContentCopyIcon,
  DeleteOutline as DeleteOutlineIcon,
} from "@mui/icons-material";
import { FieldArray, Form, Formik, FormikConfig } from "formik";
import { FC } from "react";

import { SymbolOutlineButton } from "~/src/components/common/Button";
import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormControlGroup } from "~/src/components/common/forms/FormControlGroup";
import { FormikFormButtons } from "~/src/components/common/forms/FormikFormButtons";
import { FormikRadioButtonsField } from "~/src/components/common/forms/FormikRadioButtonsField";
import { FormikTextField } from "~/src/components/common/forms/FormikTextField";
import { IconButton } from "~/src/components/common/IconButton";
import { HorizontalSeparator } from "~/src/components/common/Separator";

import {
  RedirectField,
  ScenarioFieldArrayContainer,
  TextReplaceFieldArrayContainer,
} from "./presentations";
import { ArrayFormValues, arrayValidationSchema } from "./validationSchema";

export type Props = {
  initialValues?: ArrayFormValues;
  enableWeightFields: boolean;
  onCancelClick: () => void;
  onSubmit: FormikConfig<ArrayFormValues>["onSubmit"];
};

const defaultInitialValues: ArrayFormValues = {
  formValues: [
    {
      title: "",
      kind: "DEFAULT",
      weight: 0,
      originalPatternWeight: 0,
      redirectContents: {
        linkUrl: "",
        isInheritQuery: true,
      },
    },
  ],
};

export const ABTestPatternArrayFormContainer: FC<Props> = ({
  initialValues = defaultInitialValues,
  enableWeightFields,
  onCancelClick,
  onSubmit,
}) => {
  return (
    <Formik<ArrayFormValues>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={arrayValidationSchema}
      validateOnMount={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {({ values }) => {
        return (
          <Form>
            <FieldArray
              name={"formValues"}
              // eslint-disable-next-line react/jsx-no-bind
              render={(arrayHelpers) => (
                <>
                  {values.formValues &&
                    values.formValues.length > 0 &&
                    values.formValues.map((v, index) => (
                      <VStack
                        spacing="24px"
                        alignItems="flex-start"
                        paddingBottom={"1"}
                        key={index}
                      >
                        {enableWeightFields && (
                          <FormControlGroup>
                            <FormControl required label="パターン名">
                              <Text fontSize={"13px"} fontWeight={400}>
                                デフォルト
                              </Text>
                            </FormControl>
                            <FormControl required label="配信比率">
                              <FormikTextField
                                type="number"
                                mode="fill"
                                name={`formValues.${index}.originalPatternWeight`}
                              />
                              <Text fontSize={"13px"} textColor={"#D9534F"}>
                                ※0以上の数値を入力してください（より大きな数が優先されます）
                                <br />
                                ※0は配信対象外です
                              </Text>
                            </FormControl>
                          </FormControlGroup>
                        )}

                        <FormControlGroup>
                          <Box alignSelf={"flex-end"}>
                            <IconButton
                              size="24px"
                              // eslint-disable-next-line react/jsx-no-bind
                              onClick={() => arrayHelpers.push(v)}
                              icon={<ContentCopyIcon />}
                            />
                            <IconButton
                              size="24px"
                              // eslint-disable-next-line react/jsx-no-bind
                              onClick={() => arrayHelpers.remove(index)}
                              icon={<DeleteOutlineIcon />}
                            />
                          </Box>

                          <FormControl required label="パターン名">
                            <Box>
                              <FormikTextField
                                type="text"
                                mode="fill"
                                name={`formValues.${index}.title`}
                              />
                            </Box>
                          </FormControl>

                          {enableWeightFields && (
                            <FormControl required label="配信比率">
                              <FormikTextField
                                type="number"
                                mode="fill"
                                name={`formValues.${index}.weight`}
                              />
                              <Text fontSize={"13px"} textColor={"#D9534F"}>
                                ※0以上の数値を入力してください（より大きな数が優先されます）
                                <br />
                                ※0は配信対象外です
                              </Text>
                            </FormControl>
                          )}

                          <FormControl label="テストの種類">
                            <FormikRadioButtonsField
                              name={`formValues.${index}.kind`}
                              options={[
                                { label: "画像変更", value: "SCENARIO" },
                                { label: "リダイレクト", value: "REDIRECT" },
                                {
                                  label: "テキスト置換",
                                  value: "TEXT_REPLACE",
                                },
                              ]}
                            />
                          </FormControl>
                          <HorizontalSeparator />
                          {v.kind === "SCENARIO" && (
                            <ScenarioFieldArrayContainer
                              name={`formValues.${index}.scenarioContents`}
                            />
                          )}
                          {v.kind === "TEXT_REPLACE" && (
                            <TextReplaceFieldArrayContainer
                              name={`formValues.${index}.textReplaceContents`}
                            />
                          )}
                          {v.kind === "REDIRECT" && (
                            <RedirectField
                              name={`formValues.${index}.redirectContents`}
                            />
                          )}
                        </FormControlGroup>
                      </VStack>
                    ))}
                  <Box paddingTop={"4"}>
                    <SymbolOutlineButton
                      icon={<AddCircleIcon />}
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={() =>
                        arrayHelpers.push({
                          title: "",
                          kind: "REDIRECT",
                          weight: 0,
                          originalPatternWeight: 0,
                          redirectContents: {
                            linkUrl: "",
                            isInheritQuery: true,
                          },
                        })
                      }
                    >
                      パターン追加
                    </SymbolOutlineButton>
                  </Box>
                  <FormikFormButtons onCancelClick={onCancelClick} />
                </>
              )}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
