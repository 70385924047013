import { FC } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";

import { ChatbotListPageContainer_Query } from "~/src/__relay_artifacts__/ChatbotListPageContainer_Query.graphql";
import { ChatbotListPage } from "~/src/components/pages/ChatbotListPage/presentations/ChatbotListPage";

const query = graphql`
  query ChatbotListPageContainer_Query($siteSlug: String!) {
    site(slug: $siteSlug) {
      ...ChatbotListPage_site
    }
  }
`;

export const ChatbotListPageContainer: FC = () => {
  const { siteSlug = "" } = useParams();
  const { site } = useLazyLoadQuery<ChatbotListPageContainer_Query>(query, {
    siteSlug,
  });

  return <ChatbotListPage siteRef={site} />;
};
