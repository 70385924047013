/**
 * @generated SignedSource<<940e81b764c8ae4472b909db991a16cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ExcludedIpAddressStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type CreateExcludedIpAddressInput = {
  clientMutationId?: string | null;
  siteId: string;
  ipAddress: string;
  status: ExcludedIpAddressStatus;
};
export type ExcludedIPCreateFormContainer_Mutation$variables = {
  input: CreateExcludedIpAddressInput;
  connections: ReadonlyArray<string>;
};
export type ExcludedIPCreateFormContainer_MutationVariables = ExcludedIPCreateFormContainer_Mutation$variables;
export type ExcludedIPCreateFormContainer_Mutation$data = {
  readonly createExcludedIpAddress: {
    readonly excludedIpAddressEdge: {
      readonly node: {
        readonly id: string;
        readonly ipAddress: string;
        readonly status: ExcludedIpAddressStatus;
      } | null;
    } | null;
  } | null;
};
export type ExcludedIPCreateFormContainer_MutationResponse = ExcludedIPCreateFormContainer_Mutation$data;
export type ExcludedIPCreateFormContainer_Mutation = {
  variables: ExcludedIPCreateFormContainer_MutationVariables;
  response: ExcludedIPCreateFormContainer_Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ExcludedIpAddressEdge",
  "kind": "LinkedField",
  "name": "excludedIpAddressEdge",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ExcludedIpAddress",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ipAddress",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ExcludedIPCreateFormContainer_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CreateExcludedIpAddressPayload",
        "kind": "LinkedField",
        "name": "createExcludedIpAddress",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ExcludedIPCreateFormContainer_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CreateExcludedIpAddressPayload",
        "kind": "LinkedField",
        "name": "createExcludedIpAddress",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendEdge",
            "key": "",
            "kind": "LinkedHandle",
            "name": "excludedIpAddressEdge",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dfcefee9c4aa2ac3f755503f3f0db718",
    "id": null,
    "metadata": {},
    "name": "ExcludedIPCreateFormContainer_Mutation",
    "operationKind": "mutation",
    "text": "mutation ExcludedIPCreateFormContainer_Mutation(\n  $input: CreateExcludedIpAddressInput!\n) {\n  createExcludedIpAddress(input: $input) {\n    excludedIpAddressEdge {\n      node {\n        id\n        ipAddress\n        status\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cca832976a9c145efa2bbdd76dcf231e";

export default node;
