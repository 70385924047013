import { Button } from "@chakra-ui/react";
import { rgba } from "polished";

import { chakraFactory } from "~/src/lib/chakra-ui";

import { buttonStyles } from "./styles";

export const GhostButton = chakraFactory(Button, {
  baseStyle: {
    ...buttonStyles,
    color: "#282828",
    background: "white",

    _active: {
      color: rgba("black", 0.5),
      borderColor: rgba("black", 0.5),
      boxShadow: "none",
    },

    _hover: {
      color: rgba("black", 0.2),
      borderColor: rgba("black", 0.2),
      boxShadow: "none",
    },

    _focus: {
      boxShadow: "none",
    },
  },
});
