import { format } from "date-fns";
import { find, groupBy, includes, prop, uniqBy } from "ramda";
import { FC, useCallback, useMemo, useState } from "react";
import { graphql, useFragment } from "react-relay";

import { ChatbotReportGraph_site$key } from "~/src/__relay_artifacts__/ChatbotReportGraph_site.graphql";

import { AreaGraph } from "../AreaGraph";
import { GraphProp, isMeasureKey } from "../utils";

import { measureOptions } from "./options";
import { MeasureKey, Source } from "./types";

const fragment = graphql`
  fragment ChatbotReportGraph_site on Site {
    reportChatbot(input: $input)
  }
`;

export type Prop = {
  siteRef: ChatbotReportGraph_site$key;
};

type FormValues = {
  chatbotIds: string[];
  measureKey: MeasureKey | null;
};

const findGraphTitleByMeasureKey = (key: MeasureKey | null): string => {
  const option = find((v) => v.value === key, measureOptions);
  return option === undefined ? "CVR" : option.label;
};

const generateGraphDataArray = (
  reports: Record<any, any[]>[],
  measureKey: MeasureKey
): { data: GraphProp[] }[] => {
  const dataArray = reports.map((report) => {
    const results: GraphProp[] = [];
    Object.keys(report).forEach((key) => {
      const result = reduceByMeasureKey(report[key], measureKey);
      results.push({ x: format(new Date(key), "yyyy/MM/dd"), y: result });
    });
    return { data: results };
  });
  return dataArray;
};

const reduceByMeasureKey = (
  reports: any[],
  selectedMeasureKey: MeasureKey
): number => {
  const pageViewUser = reports.reduce(
    (prev, curr) => prev + curr.page_view_user,
    0
  );
  const startUser = reports.reduce((prev, curr) => prev + curr.start_user, 0);
  const conversionUser = reports.reduce(
    (prev, curr) => prev + curr.conversion_user,
    0
  );
  switch (selectedMeasureKey) {
    case "PAGE_VIEW_USER":
      return pageViewUser;
    case "START_USER":
      return startUser;
    case "CONVERSION_USER":
      return conversionUser;
    case "START_USER_RATE":
      return startUser / pageViewUser;
    case "CONVERSION_USER_RATE":
      return conversionUser / startUser;
    default:
      return 0;
  }
};

export const ChatbotReportGraph: FC<Prop> = ({ siteRef }) => {
  const { reportChatbot } = useFragment<typeof siteRef>(fragment, siteRef);

  const [formValues, setFormValues] = useState<FormValues>({
    chatbotIds: [],
    measureKey: null,
  });

  const sources: Source[] = useMemo(() => {
    const uniqueSources = uniqBy<any, any>(
      prop("chatbot_id"),
      reportChatbot.daily_report
    );
    return uniqueSources.map((row) => {
      return { id: row.chatbot_id, chatbotId: row.chatbot_id };
    });
  }, [reportChatbot]);

  const graphDataArray = useMemo(() => {
    if (formValues.chatbotIds.length === 0) {
      return generateGraphDataArray(
        [groupBy<any, any>(prop("access_date"), reportChatbot.daily_report)],
        formValues.measureKey || "CONVERSION_USER_RATE"
      );
    }
    const reports = reportChatbot.daily_report as any[];
    const filteredReports = reports.filter((row) =>
      includes(row.chatbot_id, formValues.chatbotIds)
    );

    const reportsByChatbot = groupBy<any, any>(
      prop("chatbot_id"),
      filteredReports
    );
    const reportsByChatbotAndDate: Record<any, any[]>[] = [];
    Object.keys(reportsByChatbot).forEach((key) => {
      reportsByChatbotAndDate.push(
        groupBy<any, any>(prop("access_date"), reportsByChatbot[key])
      );
    });

    return generateGraphDataArray(
      reportsByChatbotAndDate,
      formValues.measureKey || "CONVERSION_USER_RATE"
    );
  }, [reportChatbot, formValues]);

  const sourceOptions = useMemo(() => {
    return sources.map((source) => {
      return { label: source.chatbotId, value: source.id };
    });
  }, [sources]);

  const handleSubmit = useCallback(({ chatbotIds, measureKey }) => {
    if (measureKey === null || isMeasureKey(measureKey, measureOptions)) {
      setFormValues({ chatbotIds, measureKey });
    }
  }, []);

  return (
    <AreaGraph
      title={`日別推移｜${findGraphTitleByMeasureKey(formValues.measureKey)}`}
      label={`${findGraphTitleByMeasureKey(formValues.measureKey)}`}
      dataArray={graphDataArray}
      graphCustomizedButtonProps={{
        selectFields: [
          {
            name: "chatbotIds",
            label: "チャットボット名",
            options: sourceOptions,
            isMultiple: true,
          },
          {
            name: "measureKey",
            label: "項目",
            options: measureOptions,
            isMultiple: false,
          },
        ],
        formValues,
        onSubmit: handleSubmit,
      }}
    />
  );
};
