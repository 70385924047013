import { Box } from "@chakra-ui/react";
import {
  Cancel as CancelIcon,
  DragIndicator as DragIndicatorIcon,
} from "@mui/icons-material";
import { memo } from "react";
import { useDragLayer } from "react-dnd";

import { chakraFactory } from "~/src/lib/chakra-ui";
import { createPortalOnRootEl } from "~/src/lib/react";

import { StyledTableItemIconButton, StyledTableItemWrapperBox } from "./styles";
import { DraggableItemObject } from "./types";

const StyledWrapperBox = chakraFactory(Box, {
  baseStyle: {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 10000,
    width: "280px",
    height: "100%",
    pointerEvents: "none",
  },
});

export const SelectedItemPreview = memo(() => {
  const { item, isDragging, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem() as DraggableItemObject,
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  return isDragging
    ? createPortalOnRootEl(
        <StyledWrapperBox>
          <Box
            display={!currentOffset ? "none" : undefined}
            transform={
              currentOffset
                ? `translate(${currentOffset.x}px, ${currentOffset.y}px)`
                : undefined
            }
          >
            <StyledTableItemWrapperBox cursor="grabbing" opacity={0.5}>
              <DragIndicatorIcon />
              {item.label}
              <StyledTableItemIconButton size="16px" icon={<CancelIcon />} />
            </StyledTableItemWrapperBox>
          </Box>
        </StyledWrapperBox>
      )
    : null;
});
