/**
 * @generated SignedSource<<4e1515912ab0cd36a178ef1dcec84dcf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExcludedIpAddressStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type UpdateExcludedIpAddressInput = {
  clientMutationId?: string | null;
  excludedIpAddressId: string;
  ipAddress: string;
  status: ExcludedIpAddressStatus;
};
export type ExcludedIPEditScreenContainer_Mutation$variables = {
  input: UpdateExcludedIpAddressInput;
};
export type ExcludedIPEditScreenContainer_MutationVariables = ExcludedIPEditScreenContainer_Mutation$variables;
export type ExcludedIPEditScreenContainer_Mutation$data = {
  readonly updateExcludedIpAddress: {
    readonly excludedIpAddress: {
      readonly " $fragmentSpreads": FragmentRefs<"ExcludedIPEditScreenContainer_excludedIpAddress">;
    } | null;
  } | null;
};
export type ExcludedIPEditScreenContainer_MutationResponse = ExcludedIPEditScreenContainer_Mutation$data;
export type ExcludedIPEditScreenContainer_Mutation = {
  variables: ExcludedIPEditScreenContainer_MutationVariables;
  response: ExcludedIPEditScreenContainer_Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExcludedIPEditScreenContainer_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateExcludedIpAddressPayload",
        "kind": "LinkedField",
        "name": "updateExcludedIpAddress",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ExcludedIpAddress",
            "kind": "LinkedField",
            "name": "excludedIpAddress",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ExcludedIPEditScreenContainer_excludedIpAddress"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExcludedIPEditScreenContainer_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateExcludedIpAddressPayload",
        "kind": "LinkedField",
        "name": "updateExcludedIpAddress",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ExcludedIpAddress",
            "kind": "LinkedField",
            "name": "excludedIpAddress",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ipAddress",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c33a997e1e514ae762d9e469c08a4af2",
    "id": null,
    "metadata": {},
    "name": "ExcludedIPEditScreenContainer_Mutation",
    "operationKind": "mutation",
    "text": "mutation ExcludedIPEditScreenContainer_Mutation(\n  $input: UpdateExcludedIpAddressInput!\n) {\n  updateExcludedIpAddress(input: $input) {\n    excludedIpAddress {\n      ...ExcludedIPEditScreenContainer_excludedIpAddress\n      id\n    }\n  }\n}\n\nfragment ExcludedIPEditScreenContainer_excludedIpAddress on ExcludedIpAddress {\n  id\n  ipAddress\n  status\n}\n"
  }
};
})();

(node as any).hash = "5349502883f270953a2d7663d73aa15b";

export default node;
