import { useCallback, useEffect, useRef, useState } from "react";

// @see https://usehooks.com/useHover/
export const useHover = <T extends HTMLElement>() => {
  const [value, setValue] = useState(false);
  const ref = useRef<T>(null);

  const handleMouseOver = useCallback(() => setValue(true), []);

  const handleMouseOut = useCallback(() => setValue(false), []);

  useEffect(() => {
    const node = ref.current;
    if (node) {
      node.addEventListener("mouseenter", handleMouseOver);
      node.addEventListener("mouseleave", handleMouseOut);
      return () => {
        node.removeEventListener("mouseenter", handleMouseOver);
        node.removeEventListener("mouseleave", handleMouseOut);
      };
    }
  }, [handleMouseOut, handleMouseOver]);

  return [ref, value] as const;
};
