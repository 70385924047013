import { Flex, HStack, Spacer } from "@chakra-ui/react";
import {
  HelpOutline as HelpOutlineIcon,
  PeopleAltOutlined as PeopleAltOutlinedIcon,
} from "@mui/icons-material";
import { FC } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { NavLink } from "react-router-dom";

import { HeaderNavigation_Query } from "~/src/__relay_artifacts__/HeaderNavigation_Query.graphql";
import { IconButton } from "~/src/components/common/IconButton";
import { NotificationWithBadgeIcon } from "~/src/components/common/NotificationWithBadgeIcon";
import { VerticalSeparator } from "~/src/components/common/Separator";
import { chakraFactory } from "~/src/lib/chakra-ui";

import { AccountProfileButton } from "./AccountProfileButton";
import { HEADER_NAVIGATION_HEIGHT } from "./constants";
import { DateRangeField } from "./DateRangeField";

export type Props = {
  companyName: string;
  email: string;
  onLogoutClick: () => void;
};

const query = graphql`
  query HeaderNavigation_Query {
    notifications {
      totalCount
    }
    viewer {
      role
      seenNotificationsCount
    }
  }
`;

const StyledFlexWrapper = chakraFactory(Flex, {
  baseStyle: {
    alignItems: "center",
    height: HEADER_NAVIGATION_HEIGHT,
    px: "24px",
    bg: "#FAFAFA",
    borderBottom: "1px solid #DADADA",
  },
});

export const HeaderNavigation: FC<Props> = ({
  children,
  companyName,
  email,
  onLogoutClick,
}) => {
  const { notifications, viewer } = useLazyLoadQuery<HeaderNavigation_Query>(
    query,
    {}
  );

  return (
    <StyledFlexWrapper>
      {children}
      <Spacer />
      <DateRangeField />
      <VerticalSeparator mx="24px" />
      <HStack spacing="8px">
        <NavLink to="/notifications">
          <IconButton
            label="通知"
            size="28px"
            icon={
              <NotificationWithBadgeIcon
                count={
                  (notifications?.totalCount || 0) -
                  viewer.seenNotificationsCount
                }
              />
            }
          />
        </NavLink>
        <a
          href="https://help.robee.tech/hc/ja"
          target="_blank"
          rel="noreferrer"
        >
          <IconButton label="ヘルプ" size="28px" icon={<HelpOutlineIcon />} />
        </a>
        {(viewer.role === "ADMIN" || viewer.role === "SUPER") && (
          <NavLink to="/accounts">
            <IconButton
              label="アカウント一覧"
              size="28px"
              icon={<PeopleAltOutlinedIcon />}
            />
          </NavLink>
        )}
        <AccountProfileButton
          companyName={companyName}
          email={email}
          onLogoutClick={onLogoutClick}
          role={viewer.role}
        />
      </HStack>
    </StyledFlexWrapper>
  );
};
