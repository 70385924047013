import { FC, Suspense, useEffect } from "react";

import { Modal } from "~/src/components/common/Modal";
import { PageLoading } from "~/src/components/common/PageLoading";
import {
  SiteCreateFormContainer,
  SiteEditFormContainer,
} from "~/src/components/features/sites/SiteForms";
import {
  useDateRangeSearchParams,
  useEditModalState,
  useOpenState,
} from "~/src/lib/hooks";

import { SiteListTab } from "./presentations";

export const SiteListTabContainer: FC = () => {
  const createModalState = useOpenState();
  const editModalState = useEditModalState();
  const { attachDateRangeParams } = useDateRangeSearchParams();

  useEffect(() => {
    attachDateRangeParams();
  }, [attachDateRangeParams]);

  return (
    <>
      <SiteListTab
        onCreateClick={createModalState.onOpen}
        onEditClick={editModalState.onEditClick}
      />
      <Modal
        title="サイトの作成"
        isOpen={createModalState.isOpen}
        onClose={createModalState.onClose}
        width="1000"
      >
        <Suspense fallback={<PageLoading />}>
          <SiteCreateFormContainer onClose={createModalState.onClose} />
        </Suspense>
      </Modal>
      <Modal
        title="サイトの編集"
        isOpen={editModalState.isOpen}
        onClose={editModalState.onClose}
      >
        <Suspense fallback={<PageLoading />}>
          <SiteEditFormContainer
            siteSlug={editModalState.editId}
            onClose={editModalState.onClose}
          />
        </Suspense>
      </Modal>
    </>
  );
};
