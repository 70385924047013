import { Text, VStack } from "@chakra-ui/react";
import { Form, Formik, FormikConfig } from "formik";
import { FC } from "react";

import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormControlGroup } from "~/src/components/common/forms/FormControlGroup";
import { FormikFormButtons } from "~/src/components/common/forms/FormikFormButtons";
import { FormikRadioButtonsField } from "~/src/components/common/forms/FormikRadioButtonsField";
import { FormikTextField } from "~/src/components/common/forms/FormikTextField";
import { HorizontalSeparator } from "~/src/components/common/Separator";

import {
  RedirectField,
  ScenarioFieldArrayContainer,
  TextReplaceFieldArrayContainer,
} from "./presentations";
import { FormValues, validationSchema } from "./validationSchema";

export type Props = {
  initialValues?: FormValues;
  enableWeightFields: boolean;
  onCancelClick: () => void;
  onSubmit: FormikConfig<FormValues>["onSubmit"];
};

const defaultInitialValues: FormValues = {
  title: "",
  kind: "DEFAULT",
  weight: 0,
  originalPatternWeight: 0,
};

export const ABTestPatternFormContainer: FC<Props> = ({
  initialValues = defaultInitialValues,
  enableWeightFields,
  onCancelClick,
  onSubmit,
}) => {
  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {({ values }) => {
        return (
          <Form>
            <VStack spacing="24px" alignItems="flex-start">
              {enableWeightFields && (
                <FormControlGroup>
                  <FormControl required label="パターン名">
                    <Text fontSize={"13px"} fontWeight={400}>
                      デフォルト
                    </Text>
                  </FormControl>
                  <FormControl required label="配信比率">
                    <FormikTextField
                      type="number"
                      mode="fill"
                      name="originalPatternWeight"
                    />
                    <Text fontSize={"13px"} textColor={"#D9534F"}>
                      ※0以上の数値を入力してください（より大きな数が優先されます）
                      <br />
                      ※0は配信対象外です
                    </Text>
                  </FormControl>
                </FormControlGroup>
              )}

              <FormControlGroup>
                <FormControl required label="パターン名">
                  <FormikTextField type="text" mode="fill" name="title" />
                </FormControl>

                {enableWeightFields && (
                  <FormControl required label="配信比率">
                    <FormikTextField type="number" mode="fill" name="weight" />
                    <Text fontSize={"13px"} textColor={"#D9534F"}>
                      ※0以上の数値を入力してください（より大きな数が優先されます）
                      <br />
                      ※0は配信対象外です
                    </Text>
                  </FormControl>
                )}

                <FormControl label="テストの種類">
                  <FormikRadioButtonsField
                    name="kind"
                    options={[
                      { label: "画像変更", value: "SCENARIO" },
                      { label: "リダイレクト", value: "REDIRECT" },
                      { label: "テキスト置換", value: "TEXT_REPLACE" },
                    ]}
                  />
                </FormControl>
                <HorizontalSeparator />
                {values.kind === "SCENARIO" && (
                  <ScenarioFieldArrayContainer name={"scenarioContents"} />
                )}
                {values.kind === "TEXT_REPLACE" && (
                  <TextReplaceFieldArrayContainer
                    name={"textReplaceContents"}
                  />
                )}
                {values.kind === "REDIRECT" && (
                  <RedirectField name={"redirectContents"} />
                )}
              </FormControlGroup>

              <FormikFormButtons onCancelClick={onCancelClick} />
            </VStack>
          </Form>
        );
      }}
    </Formik>
  );
};
