import { SystemStyleObject } from "@chakra-ui/react";

import { TABLE_ROW_HEIGHT_PX } from "./constants";

export const rowStyles: SystemStyleObject = {
  display: "flex",
  height: `${TABLE_ROW_HEIGHT_PX}px`,

  "&:not(:last-of-type)": {
    borderBottom: "1px solid #DADADA",
  },
};

export const columnStyles: SystemStyleObject = {
  display: "flex",
  alignItems: "center",
  flex: 1,
  minWidth: "150px",
  height: "100%",
  color: "#282828",
  padding: "0 14px",
  wordBreak: "break-all",
};
