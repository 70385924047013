import { Box, HStack } from "@chakra-ui/react";
import { FC, useMemo } from "react";
import { graphql, usePaginationFragment } from "react-relay";
import { NavLink } from "react-router-dom";

import { PopupSetListPage_popupGroupSet$key } from "~/src/__relay_artifacts__/PopupSetListPage_popupGroupSet.graphql";
import { SolidButton } from "~/src/components/common/Button";
import { Modal } from "~/src/components/common/Modal";
import {
  Table,
  TableDataColumn,
  TableDataRow,
  TableHeaderColumn,
  TableHeaderRow,
} from "~/src/components/common/tables/Table";
import {
  TablePagination,
  useTablePaginationProps,
} from "~/src/components/common/tables/TablePagination";
import { PageLayout } from "~/src/components/features/global/PageLayout";
import { PopupSetCreateFormContainer } from "~/src/components/features/popupSet";
import { useOpenState } from "~/src/lib/hooks";

import { PopupSetTableActions } from "./PopupSetTableActions";

export type Props = {
  siteSlug: string;
  popupGroupSetRef: PopupSetListPage_popupGroupSet$key;
};

const fragment = graphql`
  fragment PopupSetListPage_popupGroupSet on PopupGroupSet
  @refetchable(queryName: "PopupSetListPageRefetchQuery")
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 120 }
    cursor: { type: "String" }
  ) {
    id
    name
    popupSets(first: $count, after: $cursor)
      @connection(key: "PopupSetListPage_popupSets") {
      __id
      totalCount
      edges {
        node {
          id
          name
          memo
        }
      }
    }
  }
`;

export const PopupSetList: FC<Props> = ({ popupGroupSetRef, siteSlug }) => {
  const { isOpen, onClose, onOpen } = useOpenState();

  const {
    data: popupGroupSet,
    loadNext,
    hasNext,
    refetch,
  } = usePaginationFragment(fragment, popupGroupSetRef);
  const { tablePaginationProps } = useTablePaginationProps({
    totalCount: popupGroupSet.popupSets.totalCount,
    hasNext,
    loadNext,
    refetch,
  });

  const popupSets = useMemo(() => {
    const from = tablePaginationProps.from;
    const to = tablePaginationProps.to;
    const egdes = popupGroupSet.popupSets.edges || [];
    return egdes.slice(from, to).map((edge) => {
      const node = edge?.node;
      if (!node) throw new Error("assertion failed");
      return node;
    });
  }, [
    popupGroupSet.popupSets.edges,
    tablePaginationProps.from,
    tablePaginationProps.to,
  ]);

  const breadcrumbs = useMemo(
    () => [
      {
        label: "ポップアップセット一覧",
        path: `/sites/${siteSlug}/popup_group_sets`,
      },
      {
        label: "ポップアップセット詳細",
        path: `/sites/${siteSlug}/popup_group_sets/${popupGroupSet.id}`,
      },
    ],
    [popupGroupSet.id, siteSlug]
  );

  return (
    <>
      <PageLayout title="ポップアップパターンセット" breadcrumbs={breadcrumbs}>
        <Box my="16px">
          <HStack justifyContent="flex-end" mb="16px" spacing="16px">
            <SolidButton onClick={onOpen}>作成</SolidButton>
          </HStack>
          <Table>
            <TableHeaderRow>
              <TableHeaderColumn>
                ポップアップパターンセット名
              </TableHeaderColumn>
              <TableHeaderColumn>メモ</TableHeaderColumn>
              <TableHeaderColumn>アクション</TableHeaderColumn>
            </TableHeaderRow>
            {popupSets.map((popupSet, index) => (
              <TableDataRow height={"80px"} key={index}>
                <TableDataColumn
                  type="text"
                  isLink
                  data={
                    <NavLink
                      to={`/sites/${siteSlug}/popup_group_sets/${popupGroupSet.id}/popup_sets/${popupSet.id}`}
                    >
                      {popupSet.name}
                    </NavLink>
                  }
                />
                <TableDataColumn
                  type="text"
                  data={
                    popupSet.memo && popupSet.memo.length > 30
                      ? popupSet.memo.substring(0, 30) + "..."
                      : popupSet.memo
                  }
                />

                <TableDataColumn
                  type="actions"
                  data={
                    <PopupSetTableActions
                      popupSet={popupSet}
                      connectionId={popupGroupSet.popupSets.__id}
                    />
                  }
                />
              </TableDataRow>
            ))}
          </Table>
          <TablePagination {...tablePaginationProps} />
        </Box>
      </PageLayout>
      <Modal
        title="ポップアップパターンセットの作成"
        isOpen={isOpen}
        onClose={onClose}
      >
        <PopupSetCreateFormContainer
          onClose={onClose}
          popupGroupSetId={popupGroupSet.id}
          connectionId={popupGroupSet.popupSets.__id}
        />
      </Modal>
    </>
  );
};
