/**
 * @generated SignedSource<<6c4a626b5e4fe5fcb172f52c5148f8bf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AgeRange = "LT12" | "GT12LT19" | "GT20LT24" | "GT25LT29" | "GT30LT34" | "GT35LT39" | "GT40LT44" | "GT45LT49" | "GT50LT54" | "GT55LT59" | "%future added value";
export type AnnualIncome = "LT200" | "GT200LT400" | "GT400LT600" | "GT600LT800" | "GT800LT1000" | "GT1000LT1200" | "GT1200LT1500" | "GT1500LT2000" | "GT2000" | "%future added value";
export type Browser = "IE" | "CHROME" | "SAFARI" | "FIREFOX" | "EDGE" | "OTHER" | "%future added value";
export type DayOfWeek = "SUN" | "MON" | "TUE" | "WED" | "THU" | "FRI" | "SUT" | "%future added value";
export type DeliverKind = "EQUAL" | "WEIGHT" | "%future added value";
export type Device = "MOBILE" | "TABLET" | "PC" | "%future added value";
export type DistributionStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type Gender = "MALE" | "FEMALE" | "%future added value";
export type MatchOp = "EQUAL" | "CONTAINS" | "%future added value";
export type Platform = "IOS" | "WINDOWS" | "ANDROID" | "MAC" | "LINUX" | "OTHER" | "%future added value";
export type PopupGroupDisplayTargetContentKind = "ID" | "SELECTOR" | "%future added value";
export type VisitMatch = "EQUAL_MATCH_TYPE" | "OVER_MATCH_TYPE" | "UNDER_MATCH_TYPE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PopupGroupEditScreenContainer_popupGroup$data = {
  readonly ageRanges: ReadonlyArray<AgeRange>;
  readonly annualIncomes: ReadonlyArray<AnnualIncome>;
  readonly browsers: ReadonlyArray<Browser>;
  readonly deliverDayOfWeeks: ReadonlyArray<{
    readonly weekOfMonth: number | null;
    readonly daysOfWeek: ReadonlyArray<DayOfWeek>;
  }>;
  readonly deliverExcludeUrls: ReadonlyArray<{
    readonly url: string;
  }>;
  readonly deliverExcludeLabels: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly deliverTargetUrls: ReadonlyArray<{
    readonly url: string;
  }>;
  readonly deliverTargetLabels: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly deliverKind: DeliverKind;
  readonly devices: ReadonlyArray<Device>;
  readonly displayTargetContents: ReadonlyArray<{
    readonly targetKind: PopupGroupDisplayTargetContentKind;
    readonly targetValue: string;
  }>;
  readonly disableTargetElements: ReadonlyArray<{
    readonly targetKind: PopupGroupDisplayTargetContentKind;
    readonly targetValue: string;
  }>;
  readonly platforms: ReadonlyArray<Platform>;
  readonly genders: ReadonlyArray<Gender>;
  readonly id: string;
  readonly isMulti: boolean;
  readonly memo: string | null;
  readonly name: string;
  readonly repeat: boolean;
  readonly endDate: string | null;
  readonly endTime: string | null;
  readonly page: {
    readonly id: string;
    readonly funnel: {
      readonly id: string;
    };
  };
  readonly redisplayPopupTime: number;
  readonly slug: string;
  readonly sources: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly excludeSources: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly startDate: string | null;
  readonly startTime: string | null;
  readonly status: DistributionStatus;
  readonly excludeReferrers: ReadonlyArray<{
    readonly referrer: string | null;
    readonly matchOp: MatchOp | null;
  }>;
  readonly targetCookies: ReadonlyArray<{
    readonly key: string;
    readonly value: string;
    readonly matchType: boolean;
  }>;
  readonly referrers: ReadonlyArray<{
    readonly referrer: string | null;
    readonly matchOp: MatchOp | null;
  }>;
  readonly visitCount: number | null;
  readonly visitMatchType: VisitMatch | null;
  readonly " $fragmentType": "PopupGroupEditScreenContainer_popupGroup";
};
export type PopupGroupEditScreenContainer_popupGroup = PopupGroupEditScreenContainer_popupGroup$data;
export type PopupGroupEditScreenContainer_popupGroup$key = {
  readonly " $data"?: PopupGroupEditScreenContainer_popupGroup$data;
  readonly " $fragmentSpreads": FragmentRefs<"PopupGroupEditScreenContainer_popupGroup">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/)
],
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "targetKind",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "targetValue",
    "storageKey": null
  }
],
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "referrer",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "matchOp",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PopupGroupEditScreenContainer_popupGroup",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ageRanges",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "annualIncomes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "browsers",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DeliverDayOfWeek",
      "kind": "LinkedField",
      "name": "deliverDayOfWeeks",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "weekOfMonth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "daysOfWeek",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PopupGroupDeliverExcludeUrl",
      "kind": "LinkedField",
      "name": "deliverExcludeUrls",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserLabel",
      "kind": "LinkedField",
      "name": "deliverExcludeLabels",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PopupGroupDeliverTargetUrl",
      "kind": "LinkedField",
      "name": "deliverTargetUrls",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserLabel",
      "kind": "LinkedField",
      "name": "deliverTargetLabels",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deliverKind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "devices",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PopupGroupDisplayTargetContent",
      "kind": "LinkedField",
      "name": "displayTargetContents",
      "plural": true,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PopupGroupDisableTargetElement",
      "kind": "LinkedField",
      "name": "disableTargetElements",
      "plural": true,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "platforms",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "genders",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isMulti",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "memo",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "repeat",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Page",
      "kind": "LinkedField",
      "name": "page",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Funnel",
          "kind": "LinkedField",
          "name": "funnel",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "redisplayPopupTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Source",
      "kind": "LinkedField",
      "name": "sources",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Source",
      "kind": "LinkedField",
      "name": "excludeSources",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PopupGroupExcludeReferrer",
      "kind": "LinkedField",
      "name": "excludeReferrers",
      "plural": true,
      "selections": (v5/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PopupGroupTargetCookie",
      "kind": "LinkedField",
      "name": "targetCookies",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "matchType",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PopupGroupReferrer",
      "kind": "LinkedField",
      "name": "referrers",
      "plural": true,
      "selections": (v5/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "visitCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "visitMatchType",
      "storageKey": null
    }
  ],
  "type": "PopupGroup",
  "abstractKey": null
};
})();

(node as any).hash = "f3927eb1b3ae90c1022e1a1d947ffa57";

export default node;
