import { useToast } from "@chakra-ui/react";
import { getAuth, signOut } from "firebase/auth";
import { FC, useCallback, useMemo } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";

import { HeaderOnlyLayoutContainer_Query } from "~/src/__relay_artifacts__/HeaderOnlyLayoutContainer_Query.graphql";

import { HeaderNavigationProps } from "../HeaderNavigation";

import { HeaderOnlyLayout } from "./presentations";

const query = graphql`
  query HeaderOnlyLayoutContainer_Query {
    viewer {
      company
      email
    }
  }
`;

export const HeaderOnlyLayoutContainer: FC = ({ children }) => {
  const { viewer } = useLazyLoadQuery<HeaderOnlyLayoutContainer_Query>(
    query,
    {}
  );

  const toast = useToast();

  const handleLogoutClick = useCallback(async () => {
    try {
      const auth = getAuth();
      signOut(auth);
    } catch (err) {
      toast({
        title: "ログアウトに失敗しました",
        status: "error",
      });
    }
  }, [toast]);

  const authProps: HeaderNavigationProps = useMemo(
    () => ({
      companyName: viewer.company || "未設定",
      email: viewer.email,
      onLogoutClick: handleLogoutClick,
    }),
    [handleLogoutClick, viewer.company, viewer.email]
  );

  return <HeaderOnlyLayout {...authProps}>{children}</HeaderOnlyLayout>;
};
