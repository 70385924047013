import { FC } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";

import { FunnelDetailPageContainer_Query } from "~/src/__relay_artifacts__/FunnelDetailPageContainer_Query.graphql";

import { FunnelDetailPage } from "./presentations";

const query = graphql`
  query FunnelDetailPageContainer_Query($funnelSlug: String!) {
    funnel(slug: $funnelSlug) {
      ...FunnelDetailPage_funnel
    }
    viewer {
      role
    }
  }
`;

export const FunnelDetailPageContainer: FC = () => {
  const { siteSlug = "", funnelSlug = "" } = useParams();

  const { funnel, viewer } = useLazyLoadQuery<FunnelDetailPageContainer_Query>(
    query,
    {
      funnelSlug,
    }
  );

  return (
    <FunnelDetailPage
      siteSlug={siteSlug}
      funnelRef={funnel}
      role={viewer.role}
    />
  );
};
