/**
 * @generated SignedSource<<06cd705328b3b7c07eda1f8b1f623572>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserRole = "SUPER" | "ADMIN_ALL" | "ADMIN" | "JUDGE" | "CLIENT" | "SAAS_CLIENT" | "%future added value";
export type FunnelDetailPageContainer_Query$variables = {
  funnelSlug: string;
};
export type FunnelDetailPageContainer_QueryVariables = FunnelDetailPageContainer_Query$variables;
export type FunnelDetailPageContainer_Query$data = {
  readonly funnel: {
    readonly " $fragmentSpreads": FragmentRefs<"FunnelDetailPage_funnel">;
  };
  readonly viewer: {
    readonly role: UserRole;
  };
};
export type FunnelDetailPageContainer_QueryResponse = FunnelDetailPageContainer_Query$data;
export type FunnelDetailPageContainer_Query = {
  variables: FunnelDetailPageContainer_QueryVariables;
  response: FunnelDetailPageContainer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "funnelSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "funnelSlug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v7 = [
  (v3/*: any*/),
  (v4/*: any*/),
  (v6/*: any*/),
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FunnelDetailPageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Funnel",
        "kind": "LinkedField",
        "name": "funnel",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "FunnelDetailPage_funnel"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FunnelDetailPageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Funnel",
        "kind": "LinkedField",
        "name": "funnel",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "siteUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Page",
            "kind": "LinkedField",
            "name": "landingPage",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Page",
            "kind": "LinkedField",
            "name": "formPage",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sortNumber",
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Page",
            "kind": "LinkedField",
            "name": "confirmPage",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Page",
            "kind": "LinkedField",
            "name": "thanksPage",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f07e42a9bb209ff2b0ed6f65e44b77ec",
    "id": null,
    "metadata": {},
    "name": "FunnelDetailPageContainer_Query",
    "operationKind": "query",
    "text": "query FunnelDetailPageContainer_Query(\n  $funnelSlug: String!\n) {\n  funnel(slug: $funnelSlug) {\n    ...FunnelDetailPage_funnel\n    id\n  }\n  viewer {\n    role\n    id\n  }\n}\n\nfragment FunnelDetailPage_funnel on Funnel {\n  id\n  name\n  slug\n  siteUrl\n  landingPage {\n    id\n    name\n    url\n    slug\n  }\n  formPage {\n    id\n    name\n    url\n    sortNumber\n    slug\n  }\n  confirmPage {\n    id\n    name\n    url\n    slug\n  }\n  thanksPage {\n    id\n    name\n    url\n    slug\n  }\n}\n"
  }
};
})();

(node as any).hash = "ac44d1b6a4b4561b9a38fd0cae93937f";

export default node;
