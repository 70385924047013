import { Box, Flex, Text } from "@chakra-ui/react";
import { FC, useMemo } from "react";
import { useMeasure } from "react-use";

import { chakraFactory } from "~/src/lib/chakra-ui";

import { CSVDownloadLink } from "../CSVDownloadLink";

import { CustomVictoryChart } from "./CustomVictoryChart";
import {
  GraphCustomizedButton,
  Props as GraphCustomizedButtonProps,
} from "./GraphCustomizedButton";
import { Data } from "./types";

export type Props = {
  title: string;
  dataArray: { data: Data[] }[];
  label: string;
  graphCustomizedButtonProps?: GraphCustomizedButtonProps;
};

const StyledWrapperBox = chakraFactory(Box, {
  baseStyle: {
    width: "100%",
    padding: "24px",
    background: "#FFFFFF",
    border: "1px solid #DADADA",
    boxSizing: "border-box",
  },
});

const StyledHeaderText = chakraFactory(Text, {
  baseStyle: {
    height: "26px",
    fontWeight: 500,
    fontSize: "18px",
    letterSpacing: "0.02em",
    color: "#282828",
    width: "95%",
  },
});

export const AreaGraph: FC<Props> = ({
  title,
  dataArray,
  label,
  graphCustomizedButtonProps,
}) => {
  const [ref, { width }] = useMeasure<HTMLDivElement>();

  const csvString = useMemo(() => {
    if (dataArray.length === 0) return "";
    const datas = dataArray[0].data;
    return [["date", label], ...datas.map((d) => [d.x, d.y])]
      .map((e) => e.join(","))
      .join("\n");
  }, [dataArray, label]);

  return (
    <StyledWrapperBox ref={ref}>
      <Flex justifyContent="space-between" alignItems="center" mb="16px">
        <StyledHeaderText>{title}</StyledHeaderText>
        {graphCustomizedButtonProps && (
          <GraphCustomizedButton
            selectFields={graphCustomizedButtonProps.selectFields}
            formValues={graphCustomizedButtonProps.formValues}
            onSubmit={graphCustomizedButtonProps.onSubmit}
          />
        )}
        <CSVDownloadLink name={"chart"} data={csvString} />
      </Flex>
      <CustomVictoryChart width={width} dataArray={dataArray} label={label} />
    </StyledWrapperBox>
  );
};
