import { useToast } from "@chakra-ui/react";
import { FC, useCallback, useMemo } from "react";
import { graphql, useFragment } from "react-relay";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";

import { ExcludedIPEditScreenContainer_excludedIpAddress$key } from "~/src/__relay_artifacts__/ExcludedIPEditScreenContainer_excludedIpAddress.graphql";
import { ExcludedIPEditScreenContainer_Mutation } from "~/src/__relay_artifacts__/ExcludedIPEditScreenContainer_Mutation.graphql";
import { useFormErrorHandler } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";

import {
  ExcludedIPForm,
  ExcludedIPFormProps,
} from "../../features/settings/ExcludedIPForms/presentations";

export type Props = {
  excludedIpAddressRef: ExcludedIPEditScreenContainer_excludedIpAddress$key;
};

const fragment = graphql`
  fragment ExcludedIPEditScreenContainer_excludedIpAddress on ExcludedIpAddress {
    id
    ipAddress
    status
  }
`;
const mutation = graphql`
  mutation ExcludedIPEditScreenContainer_Mutation(
    $input: UpdateExcludedIpAddressInput!
  ) {
    updateExcludedIpAddress(input: $input) {
      excludedIpAddress {
        ...ExcludedIPEditScreenContainer_excludedIpAddress
      }
    }
  }
`;

export const ExcludedIPEditScreenContainer: FC<Props> = ({
  excludedIpAddressRef,
}) => {
  const { siteSlug = "" } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const { onFormError } = useFormErrorHandler();
  const excludedIpAddress = useFragment(fragment, excludedIpAddressRef);

  const initialValues = useMemo(
    () =>
      ({
        ipAddress: excludedIpAddress?.ipAddress,
        status: excludedIpAddress.status === "ACTIVE" ? "ACTIVE" : "INACTIVE",
      } as const),
    [excludedIpAddress]
  );

  // TODO: handle not found
  if (excludedIpAddress === null) throw new Error("record not found");

  const updateExcludedIpAddressMutation =
    useMutationCommit<ExcludedIPEditScreenContainer_Mutation>(mutation);

  const handleSubmit = useCallback<ExcludedIPFormProps["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const res = await updateExcludedIpAddressMutation({
          variables: {
            input: {
              ...values,
              excludedIpAddressId: excludedIpAddress.id,
            },
          },
        });

        if (!res.updateExcludedIpAddress?.excludedIpAddress) {
          throw new Error("assertion failed");
        }
        toast({ title: "除外IPを更新しました", status: "success" });
        navigate(`/sites/${siteSlug}/settings?tab=excluded_ip`);
      } catch (err) {
        onFormError(err, setErrors);
      }
    },
    [
      onFormError,
      siteSlug,
      toast,
      navigate,
      excludedIpAddress,
      updateExcludedIpAddressMutation,
    ]
  );

  const handleCancel = useCallback(() => navigate(-1), [navigate]);

  return (
    <ExcludedIPForm
      initialValues={initialValues}
      onCancelClick={handleCancel}
      onSubmit={handleSubmit}
    />
  );
};
