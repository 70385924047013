import * as yup from "yup";

export const ChatMessageValidationSchema = yup.object({
  id: yup.string().nullable(),
  kind: yup.string().required(),
  message: yup.string().nullable(),
  isInheritQuery: yup.boolean().required(),
  displaySpeed: yup.number().positive(),
  submitUrl: yup
    .string()
    .url()
    .when("kind", (kind, schema) => {
      return kind === "SUBMIT" ? schema.required() : schema.nullable();
    }),
  isSaveReply: yup.boolean().required(),
  isTransferReply: yup.boolean().required(),
  chatMessageImage: yup
    .object()
    .shape({
      linkUrl: yup.string().url().nullable(),
    })
    .nullable(),
});

export const ApiResultValidationSchema = yup.object({
  nextMessageNumber: yup.number().required(),
  result: yup
    .string()
    .matches(/[a-zA-Z0-9_]+/, { message: "※半角英数字とアンダースコアのみ" })
    .required(),
});

export const ReplyValidationSchema = yup.object({
  id: yup.string().nullable(),
  kind: yup.string().required(),
  label: yup.string().nullable(),
  name: yup.string().required(),
  value: yup
    .string()
    .when("kind", (kind, schema) => {
      return kind === "OPTION" ? schema.required() : schema.nullable();
    })
    .when("name", (name, schema) => {
      return schema.notOneOf([name]);
    }),
  placeholder: yup.string().nullable(),
  errorMessage: yup.string().nullable(),
  chatbotApiResults: yup.array().of(ApiResultValidationSchema).min(0),
  apiAction: yup.string().when("kind", (kind, schema) => {
    return kind === "API"
      ? schema
          .matches(/[a-z0-9_]+/, {
            message: "※半角英数字(英字は小文字)とアンダースコアのみ",
            excludeEmptyString: true,
          })
          .required()
      : schema.nullable();
  }),
  regularExpression: yup.string().when("kind", (kind, schema) => {
    return kind === "CUSTOM_TEXT" || kind === "API"
      ? schema.required()
      : schema.nullable();
  }),
});

export const ReplyGroupValidationSchema = yup.object({
  id: yup.string().nullable(),
  kind: yup.string().required(),
  message: yup.string().nullable(),
  divergingPoint: yup.boolean().required(),
  replies: yup.array().of(ReplyValidationSchema).min(1),
});
