import { useToast } from "@chakra-ui/toast";
import {
  ArchiveOutlined as ArchiveOutlinedIcon,
  ContentCopyOutlined as ContentCopyOutlinedIcon,
  DeleteOutline as DeleteOutlineIcon,
  UnarchiveOutlined as UnarchiveOutlinedIcon,
} from "@mui/icons-material";
import { FC, useCallback, useMemo } from "react";
import { graphql } from "react-relay";

import { PopupTableActions_CopyMutation } from "~/src/__relay_artifacts__/PopupTableActions_CopyMutation.graphql";
import { PopupTableActions_DeleteMutation } from "~/src/__relay_artifacts__/PopupTableActions_DeleteMutation.graphql";
import { TableActions } from "~/src/components/common/tables/TableActions";
import {
  useArchiveHandler,
  useUnarchiveHandler,
} from "~/src/components/features/archive";
import {
  CopyModal,
  FormValues,
} from "~/src/components/features/global/CopyModal";
import { DeleteConfirmDialog } from "~/src/components/features/global/DeleteConfirmDialog";
import { useOpenState } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";

type Props = {
  popupGroup: { id: string; name: string; slug: string };
  currentValues: FormValues;
  connectionId: string;
  filtered: boolean;
};

const deleteMutation = graphql`
  mutation PopupTableActions_DeleteMutation(
    $connections: [ID!]!
    $input: DeletePopupGroupInput!
  ) {
    deletePopupGroup(input: $input) {
      deletedPopupGroupId @deleteEdge(connections: $connections)
    }
  }
`;

const copyMutation = graphql`
  mutation PopupTableActions_CopyMutation(
    $connections: [ID!]!
    $input: CopyPopupGroupInput!
  ) {
    copyPopupGroup(input: $input) {
      popupGroup
        @appendNode(connections: $connections, edgeTypeName: "PopupGroupEdge") {
        ...PopupTableDataRow_popupGroup
      }
    }
  }
`;

export const PopupTableActions: FC<Props> = ({
  popupGroup,
  currentValues,
  connectionId,
  filtered,
}) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useOpenState();
  const copyModalOpenState = useOpenState();

  const deleteMutate =
    useMutationCommit<PopupTableActions_DeleteMutation>(deleteMutation);
  const copyMutate =
    useMutationCommit<PopupTableActions_CopyMutation>(copyMutation);

  const handleArchive = useArchiveHandler(popupGroup.id, connectionId);
  const handleUnarchive = useUnarchiveHandler(popupGroup.id, connectionId);

  const handleDelete = useCallback(async () => {
    try {
      const res = await deleteMutate({
        variables: {
          input: {
            popupGroupId: popupGroup.id,
          },
          connections: [connectionId],
        },
      });
      const deletedPopupGroupId = res.deletePopupGroup?.deletedPopupGroupId;
      if (!deletedPopupGroupId) throw new Error("assertion failed");

      toast({
        title: `${popupGroup.name}を削除しました`,
        status: "success",
      });
    } catch (err) {
      toast({ title: "削除に失敗しました", status: "error" });
    }
  }, [connectionId, deleteMutate, popupGroup.id, popupGroup.name, toast]);

  const handleCopy = useCallback(
    async (pageId: string) => {
      try {
        const res = await copyMutate({
          variables: {
            input: {
              popupGroupId: popupGroup.id,
              pageId,
            },
            connections: [connectionId],
          },
        });
        const copiedPopupGroup = res.copyPopupGroup?.popupGroup;
        if (!copiedPopupGroup) throw new Error("assertion failed");

        toast({
          title: `${popupGroup.name}をコピーしました`,
          status: "success",
        });
      } catch (err) {
        toast({ title: "コピーに失敗しました", status: "error" });
      }
    },
    [connectionId, copyMutate, popupGroup.id, popupGroup.name, toast]
  );

  const defaultActionMenus = useMemo(
    () => [
      {
        label: "コピー",
        icon: <ContentCopyOutlinedIcon />,
        onClick: () => copyModalOpenState.onOpen(),
      },
      {
        label: "アーカイブ",
        icon: <ArchiveOutlinedIcon />,
        onClick: () => handleArchive(),
      },
    ],
    [copyModalOpenState, handleArchive]
  );

  const archivedActionMenus = useMemo(
    () => [
      {
        label: "アーカイブから戻す",
        icon: <UnarchiveOutlinedIcon />,
        onClick: () => handleUnarchive(),
      },
      {
        label: "削除",
        icon: <DeleteOutlineIcon />,
        onClick: () => onOpen(),
      },
    ],
    [handleUnarchive, onOpen]
  );

  return (
    <>
      <TableActions
        actionMenus={filtered ? archivedActionMenus : defaultActionMenus}
      />
      <DeleteConfirmDialog
        name={popupGroup.name}
        isOpen={isOpen}
        onClose={onClose}
        onDelete={handleDelete}
      />
      <CopyModal
        isOpen={copyModalOpenState.isOpen}
        onClose={copyModalOpenState.onClose}
        initialValues={currentValues}
        onSubmit={handleCopy}
      />
    </>
  );
};
