/**
 * @generated SignedSource<<76ec74ea22920ef27c8d02fb740c847e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DistributionStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type GroupContentJudgeStatus = "PENDING" | "APPROVED" | "%future added value";
export type PageKind = "LANDING" | "FORM" | "CONFIRM" | "THANKS" | "%future added value";
export type UserRole = "SUPER" | "ADMIN_ALL" | "ADMIN" | "JUDGE" | "CLIENT" | "SAAS_CLIENT" | "%future added value";
export type FunnelABTestGroupList_Query$variables = {
  siteSlug: string;
  funnelIds?: ReadonlyArray<string> | null;
  pageIds?: ReadonlyArray<string> | null;
  count?: number | null;
  cursor?: string | null;
};
export type FunnelABTestGroupList_QueryVariables = FunnelABTestGroupList_Query$variables;
export type FunnelABTestGroupList_Query$data = {
  readonly viewer: {
    readonly role: UserRole;
  };
  readonly site: {
    readonly abtestScenarioPageGroups: {
      readonly __id: string;
      readonly totalCount: number;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly abtestScenarioPages: {
            readonly totalCount: number;
          } | null;
          readonly page: {
            readonly id: string;
            readonly kind: PageKind;
            readonly name: string;
            readonly url: string;
            readonly sortNumber: number;
          };
          readonly endDate: string | null;
          readonly id: string;
          readonly memo: string | null;
          readonly slug: string;
          readonly startDate: string | null;
          readonly status: DistributionStatus;
          readonly title: string;
          readonly updatedAt: string | null;
          readonly isArchive: boolean;
          readonly judgeStatus: GroupContentJudgeStatus | null;
        } | null;
      } | null> | null;
    };
  };
};
export type FunnelABTestGroupList_QueryResponse = FunnelABTestGroupList_Query$data;
export type FunnelABTestGroupList_Query = {
  variables: FunnelABTestGroupList_QueryVariables;
  response: FunnelABTestGroupList_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "funnelIds"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageIds"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "siteSlug"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "siteSlug"
  }
],
v7 = {
  "kind": "Variable",
  "name": "funnelIds",
  "variableName": "funnelIds"
},
v8 = {
  "kind": "Variable",
  "name": "pageIds",
  "variableName": "pageIds"
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = [
  (v9/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "AbtestScenarioPageGroupEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AbtestScenarioPageGroup",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "exceptKind",
                "value": "DEFAULT"
              }
            ],
            "concreteType": "AbtestScenarioPageConnection",
            "kind": "LinkedField",
            "name": "abtestScenarioPages",
            "plural": false,
            "selections": [
              (v9/*: any*/)
            ],
            "storageKey": "abtestScenarioPages(exceptKind:\"DEFAULT\")"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Page",
            "kind": "LinkedField",
            "name": "page",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "kind",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sortNumber",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endDate",
            "storageKey": null
          },
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "memo",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isArchive",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "judgeStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "kind": "ClientExtension",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__id",
        "storageKey": null
      }
    ]
  }
],
v12 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v7/*: any*/),
  (v8/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FunnelABTestGroupList_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          {
            "alias": "abtestScenarioPageGroups",
            "args": [
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "concreteType": "AbtestScenarioPageGroupConnection",
            "kind": "LinkedField",
            "name": "__FunnelABTestGroupList_abtestScenarioPageGroups_connection",
            "plural": false,
            "selections": (v11/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "FunnelABTestGroupList_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v12/*: any*/),
            "concreteType": "AbtestScenarioPageGroupConnection",
            "kind": "LinkedField",
            "name": "abtestScenarioPageGroups",
            "plural": false,
            "selections": (v11/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v12/*: any*/),
            "filters": [
              "funnelIds",
              "pageIds"
            ],
            "handle": "connection",
            "key": "FunnelABTestGroupList_abtestScenarioPageGroups",
            "kind": "LinkedHandle",
            "name": "abtestScenarioPageGroups"
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "56fe955ad0dee735b533caff38b7ed4f",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": "count",
          "cursor": "cursor",
          "direction": "forward",
          "path": [
            "site",
            "abtestScenarioPageGroups"
          ]
        }
      ]
    },
    "name": "FunnelABTestGroupList_Query",
    "operationKind": "query",
    "text": "query FunnelABTestGroupList_Query(\n  $siteSlug: String!\n  $funnelIds: [ID!]\n  $pageIds: [ID!]\n  $count: Int\n  $cursor: String\n) {\n  viewer {\n    role\n    id\n  }\n  site(slug: $siteSlug) {\n    abtestScenarioPageGroups(funnelIds: $funnelIds, pageIds: $pageIds, first: $count, after: $cursor) {\n      totalCount\n      edges {\n        node {\n          abtestScenarioPages(exceptKind: DEFAULT) {\n            totalCount\n          }\n          page {\n            id\n            kind\n            name\n            url\n            sortNumber\n          }\n          endDate\n          id\n          memo\n          slug\n          startDate\n          status\n          title\n          updatedAt\n          isArchive\n          judgeStatus\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a76046dfa96fb8ff08949ee5729a0e09";

export default node;
