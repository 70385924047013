import { FormLabel, Input, InputGroup } from "@chakra-ui/react";
import { Folder as FolderIcon } from "@mui/icons-material";
import { ChangeEvent, FC, useCallback, useRef, useState } from "react";

import { SymbolSolidButton } from "~/src/components/common/Button";
import { chakraFactory } from "~/src/lib/chakra-ui";

export type ParsedCSVObject = {
  headers: string[];
  rows: string[][];
};

export type Props = {
  onCSVLoaded: (_: ParsedCSVObject) => void;
};

const StyledSymbolSolidButton = chakraFactory(SymbolSolidButton, {
  baseStyle: {
    my: "5px",
  },
});

const StyledInput = chakraFactory(Input, {
  baseStyle: {
    display: "none",
  },
});

const StyledLabel = chakraFactory(FormLabel, {
  baseStyle: {
    fontSize: "13px",
    justifyContent: "center",
    alignItems: "center",
    color: "#000000",
    display: "flex",
    marginLeft: "10px",
    marginRight: "0px",
    marginBottom: "0px",
  },
});

const readFile = async (file: File | Blob): Promise<string | null> => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result as string));
    reader.readAsText(file);
  });
};

export const CSVUploadButton: FC<Props> = ({ onCSVLoaded }) => {
  const inputRef = useRef<any>();
  const [filename, setFileName] = useState("");

  const handleFileLoad = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target && e.target.files && e.target.files.length > 0) {
        setFileName(e.target.files[0].name);

        const csvObject = await readFile(e.target.files[0]);
        if (!csvObject) return;

        const csvArray = csvObject.split(/\r\n|\n|\r/g);
        const header = csvArray[0].split(",");
        const rows = csvArray.slice(1).map((row) => row.split(","));

        onCSVLoaded({ headers: header, rows });
      }
    },
    [onCSVLoaded]
  );
  return (
    <InputGroup>
      <StyledInput
        type="file"
        accept=".csv"
        onChange={handleFileLoad}
        ref={inputRef}
      />
      <StyledSymbolSolidButton
        icon={<FolderIcon />}
        // eslint-disable-next-line react/jsx-no-bind
        onClick={() => inputRef.current.click()}
      >
        ファイルを選択
      </StyledSymbolSolidButton>
      <StyledLabel>{filename}</StyledLabel>
    </InputGroup>
  );
};
