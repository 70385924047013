import { ListItem } from "@chakra-ui/react";
import { FC, useCallback } from "react";

import { GhostButton } from "~/src/components/common/Button";

export type Props = {
  id: string;
  name: string;
  company: string;
  email: string;
  handleSelectUser: (selectedId: string, selectedName: string) => void;
};

export const SearchedUserListItem: FC<Props> = ({
  id,
  name,
  company,
  email,
  handleSelectUser,
}) => {
  const handleClick = useCallback(() => {
    const text = `${name}（${company}、${email}）`;

    handleSelectUser(id, text);
  }, [handleSelectUser, id, name, company, email]);

  return (
    <ListItem>
      <GhostButton onClick={handleClick}>
        {name}（{company}、{email}）
      </GhostButton>
    </ListItem>
  );
};
