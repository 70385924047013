/**
 * @generated SignedSource<<a0430579d40f7b9d91646f74f0e047af>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ABTestPatternReferrerReportTable_abtestScenarioPageGroup$data = {
  readonly reportReferrer: string;
  readonly id: string;
  readonly " $fragmentType": "ABTestPatternReferrerReportTable_abtestScenarioPageGroup";
};
export type ABTestPatternReferrerReportTable_abtestScenarioPageGroup = ABTestPatternReferrerReportTable_abtestScenarioPageGroup$data;
export type ABTestPatternReferrerReportTable_abtestScenarioPageGroup$key = {
  readonly " $data"?: ABTestPatternReferrerReportTable_abtestScenarioPageGroup$data;
  readonly " $fragmentSpreads": FragmentRefs<"ABTestPatternReferrerReportTable_abtestScenarioPageGroup">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endDate"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "order"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startDate"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./ABTestPatternReferrerReportTable_RefetchQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "ABTestPatternReferrerReportTable_abtestScenarioPageGroup",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "endDate",
          "variableName": "endDate"
        },
        {
          "kind": "Variable",
          "name": "order",
          "variableName": "order"
        },
        {
          "kind": "Variable",
          "name": "startDate",
          "variableName": "startDate"
        }
      ],
      "kind": "ScalarField",
      "name": "reportReferrer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "AbtestScenarioPageGroup",
  "abstractKey": null
};

(node as any).hash = "b9c399d635f7ee0bc680663596870340";

export default node;
