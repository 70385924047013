import {
  Box,
  Checkbox,
  Flex,
  HStack,
  Image,
  Spacer,
  VStack,
} from "@chakra-ui/react";
import { FC, useMemo } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { NavLink, useParams } from "react-router-dom";

import { GraphQLEnums } from "~/src/__generated__/GraphQLEnums";
import { PopupGroupPatternDetailPageContainer_Query } from "~/src/__relay_artifacts__/PopupGroupPatternDetailPageContainer_Query.graphql";
import { OutlineButton } from "~/src/components/common/Button";
import { DefinitionTeam } from "~/src/components/common/DefinitionTeam";
import { SectionCard } from "~/src/components/common/SectionCard";
import { HorizontalSeparator } from "~/src/components/common/Separator";
import { URLItem } from "~/src/components/common/URLItem";
import { PageLayout } from "~/src/components/features/global/PageLayout";
import { useOpenState } from "~/src/lib/hooks";

import { PopupPreviewModal } from "../../features/popup";

import { PopupPatternDetailHtmlContentContainer } from "./PopupPatternDetailHtmlContent";
import { PopupPatternDetailImagesContainer } from "./PopupPatternDetailImages";
import { PopupPatternDetailVideoContainer } from "./PopupPatternDetailVideo";

export type Props = {};

const query = graphql`
  query PopupGroupPatternDetailPageContainer_Query(
    $popupSlug: String!
    $popupGroupSlug: String!
  ) {
    viewer {
      role
    }
    popupGroup(slug: $popupGroupSlug) {
      name
      page {
        kind
        url
        sortNumber
        name
        funnel {
          name
          siteUrl
        }
      }
    }
    popup(slug: $popupSlug) {
      id
      actionKind
      backgroundOpacity
      button
      buttonGradationColor
      buttonColor
      buttonTextColor
      contentKind
      customEvent
      closePopupByClickingOutside
      backgroundOpacity
      hideByScrollPercentage
      hideByOnfocus
      quitTiming
      displayCountLimit
      displayPopupAgain
      displayPopupAgainMessage
      displayPopupAgainImageUrl
      closeButtonPosition
      colorKind
      contentWidth
      displayKind
      description
      displayOnTabFocus
      displayOnMouseOut
      enableTapWindow
      htmlContent
      hrefTarget
      honeycombCode
      isBeforeunload
      isClose
      isInheritQuery
      isDisplayWhenNoOperation
      name
      page {
        id
      }
      scrollRateRange
      slug
      status
      kind
      timing
      titleTextColor
      title
      url
      underlayerColor
      windowColor
      weight
      xOffset
      ydaTrigger
      yOffset
      popupVideo {
        video
      }
      animationKind
      popupImages {
        alt
        linkUrl
        imageUrl
        isInheritQuery
        honeycombCode
      }
    }
  }
`;

export const PopupGroupPatternDetailPageContainer: FC<Props> = () => {
  const { isOpen, onClose, onOpen } = useOpenState();
  const { patternSlug = "", siteSlug = "", popupGroupSlug = "" } = useParams();
  const { popup, viewer, popupGroup } =
    useLazyLoadQuery<PopupGroupPatternDetailPageContainer_Query>(query, {
      popupSlug: patternSlug,
      popupGroupSlug,
    });

  if (!popup) throw new Error("assertion failed");

  const breadcrumbs = useMemo(
    () => [
      { label: "ポップアップ一覧", path: `/sites/${siteSlug}/popup_groups` },
      {
        label: "ポップアップ詳細",
        path: `/sites/${siteSlug}/popup_groups/${popupGroupSlug}`,
      },
      {
        label: "パターン詳細",
        path: `/sites/${siteSlug}/popup_groups/${popupGroupSlug}/patterns/${patternSlug}`,
      },
    ],
    [patternSlug, popupGroupSlug, siteSlug]
  );

  const popupImages = useMemo(
    () =>
      popup.popupImages.map((popupImage) => ({
        alt: popupImage.alt,
        linkUrl: popupImage.linkUrl,
        imageData: null,
        imageUrl: popupImage.imageUrl,
        isInheritQuery: popupImage.isInheritQuery,
        honeycombCode: popupImage.honeycombCode,
      })),
    [popup]
  );

  return (
    <PageLayout title="パターン詳細" breadcrumbs={breadcrumbs}>
      <Box mx={4}>
        <Box mt={4} />

        <Flex alignItems="center">
          <Box color="#282828" fontWeight="bold">
            {popup.name}
          </Box>
          <Spacer />
          <HStack spacing={3}>
            <OutlineButton
              onClick={onOpen}
              isDisabled={popupImages.length === 0}
            >
              プレビュー
            </OutlineButton>
            <NavLink
              to={`/sites/${siteSlug}/popup_groups/${popupGroupSlug}/patterns/${patternSlug}/edit`}
            >
              <OutlineButton>編集</OutlineButton>
            </NavLink>
          </HStack>
        </Flex>
        <HStack>
          <Box>{`実施グループ: ${popupGroup?.name}`}</Box>
        </HStack>
        <HStack>
          <Box>{`実施ファネル: ${popupGroup?.page.funnel.name}`}</Box>
          <URLItem url={popupGroup?.page.funnel.siteUrl || ""} />
        </HStack>
        <HStack>
          <Box>
            {`実施ページ: ${
              popupGroup?.page.kind === "FORM"
                ? popupGroup?.page.name + `(${popupGroup?.page.sortNumber - 1})`
                : popupGroup?.page.name
            }`}
          </Box>
          <URLItem url={popupGroup?.page.url || ""} />
        </HStack>

        <PopupPreviewModal
          isOpen={isOpen}
          onClose={onClose}
          closeButtonPosition={popup.closeButtonPosition}
          width={popup.contentWidth ? `${popup.contentWidth}px` : "450px"}
        >
          {popupImages.length > 0 && (
            <>
              {popupImages.map((popupImage, index) => (
                <Image src={popupImage.imageUrl || ""} key={index} />
              ))}
            </>
          )}
        </PopupPreviewModal>

        <VStack mt={4}>
          <SectionCard>
            <DefinitionTeam label="パターン名">{popup.name}</DefinitionTeam>
            <DefinitionTeam label="ポップアップの種類">
              {GraphQLEnums.PopupContentKind.find(
                (v) => v.value === popup.contentKind
              )?.label || "未設定"}
            </DefinitionTeam>

            <HorizontalSeparator />

            {popup.contentKind === "IMAGE" && (
              <PopupPatternDetailImagesContainer
                popupImages={popupImages}
                role={viewer.role}
              />
            )}
            {popup.contentKind === "VIDEO" && (
              <PopupPatternDetailVideoContainer
                linkUrl={popup.popupVideo?.video}
              />
            )}
            {popup.contentKind === "HTML" && (
              <PopupPatternDetailHtmlContentContainer
                htmlContent={popup.htmlContent || ""}
              />
            )}
          </SectionCard>
        </VStack>

        <VStack mt={4}>
          <SectionCard>
            <DefinitionTeam label="閉じるボタンの表示">
              {popup.isClose ? "ON" : "OFF"}
            </DefinitionTeam>
            {popup.isClose && (
              <DefinitionTeam label="閉じるボタンの位置">
                {
                  GraphQLEnums.PopupCloseButtonPosition.find(
                    (position) => position.value === popup.closeButtonPosition
                  )?.label
                }
              </DefinitionTeam>
            )}
            <DefinitionTeam label="ポップアップの幅">
              {popup.contentWidth || "未設定"}
            </DefinitionTeam>
          </SectionCard>
        </VStack>

        <VStack mt={4}>
          <SectionCard>
            <DefinitionTeam label="配色デザイン">
              {popup.colorKind || "未設定"}
            </DefinitionTeam>
            <DefinitionTeam label="タイトル">
              {popup.title || "未設定"}
            </DefinitionTeam>

            <DefinitionTeam label="説明文">
              {popup.description || "未設定"}
            </DefinitionTeam>

            <DefinitionTeam label="ボタンテキスト">
              {popup.button || "未設定"}
            </DefinitionTeam>

            <DefinitionTeam label="クリック時のURL">
              {popup.url || "未設定"}
            </DefinitionTeam>

            <DefinitionTeam label="クリック時のタブの扱い">
              {GraphQLEnums.PopupHrefTarget.find(
                (v) => v.value === popup.hrefTarget
              )?.label || "未設定"}
            </DefinitionTeam>
          </SectionCard>
        </VStack>

        <VStack mt={4}>
          <SectionCard>
            <DefinitionTeam label="表示タイミング">
              {GraphQLEnums.PopupActionKind.find(
                (kind) => kind.value === popup.actionKind
              )?.label || "未設定"}
            </DefinitionTeam>
            <DefinitionTeam label="表示タイミングの値">
              {popup.timing || "未設定"}
            </DefinitionTeam>
            {popup.actionKind === "SCROLL_RATE_RANGE" && (
              <DefinitionTeam label="スクロール範囲">
                {popup.scrollRateRange}
              </DefinitionTeam>
            )}
            <DefinitionTeam label="タブの切り替えで表示">
              <Checkbox
                isDisabled
                defaultChecked={popup.displayOnTabFocus || false}
              />
            </DefinitionTeam>
            <DefinitionTeam label="ポインタが外れた際にポップアップを表示">
              <Checkbox
                isDisabled
                defaultChecked={popup.displayOnMouseOut || false}
              />
            </DefinitionTeam>
            <DefinitionTeam label="15秒以上ユーザー操作がない場合ポップアップを表示">
              <Checkbox
                isDisabled
                defaultChecked={popup.isDisplayWhenNoOperation}
              />
            </DefinitionTeam>
            <DefinitionTeam label="背景の操作">
              {popup.enableTapWindow ? "クリックさせる" : "クリックさせない"}
            </DefinitionTeam>
            <DefinitionTeam label="背景をクリック時にポップアップを非表示">
              {popup.closePopupByClickingOutside === "ENABLED"
                ? "有効"
                : "無効"}
            </DefinitionTeam>
            <DefinitionTeam label="背景濃度">
              {popup.backgroundOpacity || "未設定"}
            </DefinitionTeam>
            <DefinitionTeam label="スクロール率制限">
              {popup.hideByScrollPercentage}
            </DefinitionTeam>
            <DefinitionTeam label="入力中にポップアップを表示させない">
              {popup.hideByOnfocus ? "有効" : "無効"}
            </DefinitionTeam>
            <DefinitionTeam label="表示時間">
              {popup.quitTiming || "未設定"}
            </DefinitionTeam>
            <DefinitionTeam label="表示回数制限">
              {popup.displayCountLimit}
            </DefinitionTeam>
            <DefinitionTeam label="再表示ボタン">
              {popup.displayPopupAgain === "ACTIVE" ? "有効" : "無効"}
            </DefinitionTeam>
            {popup.displayPopupAgain === "ACTIVE" && (
              <DefinitionTeam label="再表示ボタン用の画像">
                <Image
                  src={popup.displayPopupAgainImageUrl || "未設定"}
                  boxSize="100px"
                  fallbackSrc="https://via.placeholder.com/100"
                />
              </DefinitionTeam>
            )}
            {popup.displayPopupAgain === "ACTIVE" && (
              <DefinitionTeam label="再表示ボタン用のメッセージ">
                {popup.displayPopupAgainMessage || "未設定"}
              </DefinitionTeam>
            )}
            <DefinitionTeam label="アニメーション">
              {GraphQLEnums.PopupAnimationKind.find(
                (v) => v.value === popup.animationKind
              )?.label || "未設定"}
            </DefinitionTeam>
          </SectionCard>
        </VStack>
      </Box>
    </PageLayout>
  );
};
