import { FC } from "react";

import { FunnelCreateFormContainer } from "~/src/components/features/settings/FunnelForms";
import { useFunnelCreatePageParams } from "~/src/routes/hooks";

import { FunnelCreatePage } from "./presentations";

export const FunnelCreatePageContainer: FC = () => {
  const { siteSlug } = useFunnelCreatePageParams();

  return (
    <FunnelCreatePage siteSlug={siteSlug}>
      <FunnelCreateFormContainer siteSlug={siteSlug} />
    </FunnelCreatePage>
  );
};
