import { useCallback, useMemo, useState } from "react";
import { usePaginationFragment } from "react-relay";

import { TablePaginationProps } from "./index";

type UsePaginationFragmentReturnValues = ReturnType<
  typeof usePaginationFragment
>;

type Parameters = {
  totalCount: number;
} & Pick<UsePaginationFragmentReturnValues, "loadNext" | "hasNext" | "refetch">;

export const useTablePaginationProps = ({
  totalCount,
  hasNext,
  loadNext,
  refetch,
}: Parameters) => {
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(120);
  const lastPage = Math.ceil(totalCount / perPage) - 1;

  const handlePrevClick = useCallback(() => {
    setPage(page - 1);
  }, [page, setPage]);

  const handleNextClick = useCallback(() => {
    hasNext && loadNext(perPage);
    setPage((p) => p + 1);
  }, [hasNext, loadNext, perPage, setPage]);

  const handlePerPageChange = useCallback(
    (newPerPage: number) => {
      setPage(0);
      setPerPage(newPerPage);
      refetch({ count: newPerPage });
    },
    [refetch]
  );

  const tablePaginationProps = useMemo<TablePaginationProps>(
    () => ({
      recordCount: totalCount,
      perPage,
      from: page * perPage,
      to: Math.min(page * perPage + perPage, totalCount),
      hasPrev: totalCount <= perPage ? false : page !== 0,
      hasNext: totalCount <= perPage ? false : page < lastPage,
      onPerPageChange: handlePerPageChange,
      onPrevClick: handlePrevClick,
      onNextClick: handleNextClick,
    }),
    [
      handleNextClick,
      handlePerPageChange,
      handlePrevClick,
      lastPage,
      page,
      perPage,
      totalCount,
    ]
  );

  return { tablePaginationProps };
};
