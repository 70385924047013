import { HStack, VStack } from "@chakra-ui/react";
import { FilterAltOutlined as FilterAltOutlinedIcon } from "@mui/icons-material";
import { Form, Formik } from "formik";
import { FC, useCallback, useMemo } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import * as yup from "yup";

import { GraphQLEnums } from "~/src/__generated__/GraphQLEnums";
import { Device } from "~/src/__generated__/schema";
import { SiteFilterButton_Query } from "~/src/__relay_artifacts__/SiteFilterButton_Query.graphql";
import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormikFormButtons } from "~/src/components/common/forms/FormikFormButtons";
import { FormikSelectField } from "~/src/components/common/forms/FormikSelectField";
import { IconButton } from "~/src/components/common/IconButton";
import { Modal } from "~/src/components/common/Modal";
import { FilteredTag } from "~/src/components/features/filter";
import { useOpenState } from "~/src/lib/hooks";

export type FormValues = {
  sourceId: string | null;
  device: Device | null;
};

export type Props = {
  filterValues: FormValues;
  onFilterChange: (filterValues: FormValues) => void;
  onSubmit: (values: FormValues) => Promise<void> | void;
};

const query = graphql`
  query SiteFilterButton_Query {
    sources {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const validationSchema = yup.object({
  sourceId: yup.string().nullable(),
  device: yup.string().nullable(),
});

export const SiteFilterButton: FC<Props> = ({
  filterValues,
  onFilterChange,
  onSubmit,
}) => {
  const { isOpen, onClose, onOpen } = useOpenState();

  const { sources } = useLazyLoadQuery<SiteFilterButton_Query>(query, {});

  const sourceOptions = useMemo(
    () => [
      { label: "すべて", value: null },
      ...(sources.edges || []).map((edge) => {
        const node = edge?.node;
        if (!node) throw new Error("assertion failed");
        return { label: node.name, value: node.id };
      }),
    ],
    [sources.edges]
  );

  const deviceOptions = useMemo(
    () => [{ label: "すべて", value: null }, ...GraphQLEnums.Device],
    []
  );

  const handleFilterChange = useCallback(
    (values: Partial<FormValues>) => () => {
      onFilterChange({ ...filterValues, ...values });
    },
    [filterValues, onFilterChange]
  );

  const handleSubmit = useCallback(
    (values: FormValues) => {
      onSubmit(values);
      onClose();
    },
    [onClose, onSubmit]
  );

  return (
    <HStack>
      {filterValues.sourceId && (
        <FilteredTag
          name="流入元"
          value={filterValues.sourceId}
          options={sourceOptions}
          onClear={handleFilterChange({ sourceId: null })}
        />
      )}
      {filterValues.device && (
        <FilteredTag
          name="デバイス"
          value={filterValues.device}
          options={deviceOptions}
          onClear={handleFilterChange({ device: null })}
        />
      )}
      <IconButton
        label="フィルタ"
        size="24px"
        icon={<FilterAltOutlinedIcon htmlColor="#282828" />}
        isActive={
          Object.values(filterValues).some((value) => value != null) || isOpen
        }
        onClick={onOpen}
      />
      <Modal title="フィルタ" isOpen={isOpen} onClose={onClose}>
        <Formik<FormValues>
          enableReinitialize
          initialValues={filterValues}
          validationSchema={validationSchema}
          validateOnMount={false}
          validateOnChange={false}
          onSubmit={handleSubmit}
        >
          <Form>
            <VStack spacing="16px">
              <FormControl label="流入元">
                <FormikSelectField name="sourceId" options={sourceOptions} />
              </FormControl>
              <FormControl label="デバイス">
                <FormikSelectField name="device" options={deviceOptions} />
              </FormControl>
            </VStack>
            <FormikFormButtons onCancelClick={onClose} />
          </Form>
        </Formik>
      </Modal>
    </HStack>
  );
};
