import { Image, Text, VStack } from "@chakra-ui/react";
import { FC } from "react";
import { NavLink } from "react-router-dom";

import { TableDataColumn } from "~/src/components/common/tables/Table";
import { URLItem } from "~/src/components/common/URLItem";

export type ColumnType = "TEXT" | "IMAGE" | "URL_LINK" | "NAV_LINK" | "GROUP";

export type ColumnData = {
  type: ColumnType;
  value: string;
  url?: string;
  columns?: ColumnData[];
};

export type Prop = {
  data: ColumnData;
  key: number;
};

export const ReportTableDataColumn: FC<Prop> = ({ data, key }) => {
  if (data.type === "GROUP" && data.columns && data.columns.length > 0)
    return (
      <TableDataColumn
        type="text"
        data={
          <VStack>
            {data.columns.map((c, index) => {
              if (c.type === "IMAGE")
                return (
                  <Image
                    src={c.value}
                    boxSize={"50px"}
                    fit={"contain"}
                    key={index}
                  />
                );
              if (c.type === "URL_LINK")
                return <URLItem url={c.url || ""} name={c.value} key={index} />;
              if (c.type === "NAV_LINK" && c.url !== undefined)
                return (
                  <NavLink to={c.url} key={index}>
                    <Text as="u">{c.value}</Text>
                  </NavLink>
                );
              return <Text key={index}>{c.value}</Text>;
            })}
          </VStack>
        }
        key={key}
      />
    );
  if (data.type === "IMAGE")
    return (
      <TableDataColumn
        type="text"
        data={<Image src={data.value} boxSize={"50px"} fit={"contain"} />}
        key={key}
      />
    );

  if (data.type === "URL_LINK")
    return (
      <TableDataColumn
        type="text"
        data={<URLItem url={data.url || ""} name={data.value} />}
        key={key}
      />
    );
  if (data.type === "NAV_LINK" && data.url !== undefined)
    return (
      <TableDataColumn
        type="text"
        data={
          <NavLink to={data.url}>
            <Text as="u">{data.value}</Text>
          </NavLink>
        }
        key={key}
      />
    );
  return <TableDataColumn type="text" data={data.value} key={key} />;
};
