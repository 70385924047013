/**
 * @generated SignedSource<<ca71ad78ae1e07d9ac0e36536e9a4db2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountDetailPage_viewer$data = {
  readonly id: string;
  readonly name: string | null;
  readonly email: string;
  readonly company: string | null;
  readonly tel: string | null;
  readonly isHoneycomb: boolean;
  readonly itpUrls: string | null;
  readonly isSecure: boolean;
  readonly isMacbeeServer: boolean;
  readonly userTag: string | null;
  readonly isCrossDomainQuery: boolean;
  readonly isCrossDomainFragment: boolean;
  readonly " $fragmentType": "AccountDetailPage_viewer";
};
export type AccountDetailPage_viewer = AccountDetailPage_viewer$data;
export type AccountDetailPage_viewer$key = {
  readonly " $data"?: AccountDetailPage_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountDetailPage_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountDetailPage_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "company",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isHoneycomb",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "itpUrls",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSecure",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isMacbeeServer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userTag",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCrossDomainQuery",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCrossDomainFragment",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "9dc29249ef95bdd05995cb29d4adf79d";

export default node;
