import { Flex } from "@chakra-ui/react";
import { format } from "date-fns";
import { useCallback, useContext, useState } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";

import { PopupReportOrderInput } from "~/src/__generated__/schema";
import { PopupPageByDateReportContainer_Query } from "~/src/__relay_artifacts__/PopupPageByDateReportContainer_Query.graphql";
import { DateRangeContext } from "~/src/components/features/global/HeaderNavigation/DateRangeField";
import { usePopupReportFilterCache } from "~/src/lib/hooks";

import {
  PopupReportFilterFormValues as FormValues,
  PopupPageByDateReport,
  PopupPageReportFilterButton,
} from "./presentations";

const query = graphql`
  query PopupPageByDateReportContainer_Query(
    $kind: PopupReportKindInput!
    $filter: PopupReportFilterInput!
    $order: PopupReportOrderInput
    $slug: String!
  ) {
    site(slug: $slug) {
      ...PopupPageByDateReport_site
    }
  }
`;

export const PopupPageByDateReportContainer = () => {
  const { siteSlug = "" } = useParams();
  const [sort, setSort] = useState<PopupReportOrderInput>({
    value: "READY_USER",
    isDesc: false,
  });

  const initialFilterValues: FormValues = {
    sourceId: null,
    device: null,
    funnelIds: [],
    popupIds: [],
    pageKind: null,
    popupNames: [],
    pageIds: [],
  };

  const { filterValues, setFilterValues } = usePopupReportFilterCache({
    cacheKey: `PopupPageByDateReport_${siteSlug}`,
    initialFilterValues,
  });

  const {
    dateRange: [startOn, endOn],
  } = useContext(DateRangeContext);

  const handleSortClick = useCallback((params: any) => {
    setSort({
      value: params.field,
      isDesc: params.direction === "DESC" ? true : false,
    });
  }, []);

  const { site } = useLazyLoadQuery<PopupPageByDateReportContainer_Query>(
    query,
    {
      kind: {
        value: "POPUP_PAGE",
        isDateExist: true,
      },
      filter: {
        startDate: format(startOn, "yyyy-MM-dd"),
        endDate: format(endOn, "yyyy-MM-dd"),
        source: filterValues.sourceId,
        device: filterValues.device,
        funnelIds: filterValues.funnelIds,
        popupIds: filterValues.popupIds,
        pageIds: filterValues.pageIds,
        pageKind: filterValues.pageKind,
      },
      order: sort,
      slug: siteSlug,
    }
  );

  return (
    <>
      <Flex justifyContent={"end"}>
        <PopupPageReportFilterButton
          filterValues={filterValues}
          onSubmit={setFilterValues}
        />
      </Flex>
      <PopupPageByDateReport siteRef={site} onSortClick={handleSortClick} />
    </>
  );
};
