import { array, object, string } from "yup";

import { TargetPageKind } from "~/src/__generated__/schema";

export type ArrayFormValues = {
  formValues: FormValues[];
};

export type FormValues = {
  popupGroupId: string;
  pageKind: TargetPageKind;
};

export const validationSchema = object().shape({
  formValues: array()
    .of(
      object().shape({
        popupGroupId: string().required(),
        pageKind: string().required(),
      })
    )
    .min(1),
});
