import { useField } from "formik";
import { FC } from "react";

import { Textarea, TextareaProps } from "~/src/components/common/Textarea";

export type Props = {
  mode: TextareaProps["mode"];
  name: TextareaProps["name"];
  disabled?: TextareaProps["disabled"];
  placeholder?: TextareaProps["placeholder"];
  resize?: TextareaProps["resize"];
};

export const FormikTextareaField: FC<Props> = ({
  mode,
  name,
  disabled,
  placeholder,
  resize,
}) => {
  const [{ value, onBlur, onChange }, { error, touched }] =
    useField<string>(name);

  return (
    <Textarea
      mode={mode}
      name={name}
      value={value}
      disabled={disabled}
      placeholder={placeholder}
      error={touched ? error : undefined}
      onBlur={onBlur}
      onChange={onChange}
      resize={resize}
    />
  );
};
