import { useToast } from "@chakra-ui/toast";
import { FC, useCallback } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useNavigate, useParams } from "react-router-dom";

import { FunnelBulkCreateTabContainer_Mutation } from "~/src/__relay_artifacts__/FunnelBulkCreateTabContainer_Mutation.graphql";
import { FunnelBulkCreateTabContainer_Query } from "~/src/__relay_artifacts__/FunnelBulkCreateTabContainer_Query.graphql";
// FIXME: move to lib
import {
  stringConvertToUrlMatchType,
  stringToHasForm,
} from "~/src/components/features/settings/FunnelForms/convertFunctions";
import { useMutationCommit } from "~/src/lib/react-relay";
import { delayChunkPromise } from "~/src/lib/utils";

import { FunnelBulkTab, FunnelBulkTabProps } from "./presentations";

const query = graphql`
  query FunnelBulkCreateTabContainer_Query($siteSlug: String!) {
    site(slug: $siteSlug) {
      id
    }
  }
`;

const mutation = graphql`
  mutation FunnelBulkCreateTabContainer_Mutation($input: CreateFunnelInput!) {
    createFunnel(input: $input) {
      funnelEdge {
        node {
          id
          slug
          name
          isExperiment
          landingPage {
            id
            name
            url
            mobileUrl
            tabletUrl
            hasForm
            urlMatchType
          }
          thanksPage {
            id
            name
            url
            mobileUrl
            tabletUrl
            urlMatchType
          }
          confirmPage {
            id
            name
            url
            mobileUrl
            tabletUrl
            urlMatchType
          }
          formPage {
            id
            name
            url
            mobileUrl
            tabletUrl
            urlMatchType
          }
        }
      }
    }
  }
`;

export const FunnelBulkCreateTabContainer: FC = () => {
  const { siteSlug = "" } = useParams();
  const toast = useToast();
  const navigate = useNavigate();

  const { site } = useLazyLoadQuery<FunnelBulkCreateTabContainer_Query>(query, {
    siteSlug,
  });

  const createMutate =
    useMutationCommit<FunnelBulkCreateTabContainer_Mutation>(mutation);

  const downloadCSVData = [
    "ファネル名",
    "ランディングページ名",
    "ランディングページPC用URL",
    "ランディングページスマホ用URL",
    "ランディングページタブレット用URL",
    "ランディングページURLの一致タイプ",
    "ランディングページフォームあり",
    "フォームページ名",
    "フォームページPC用URL",
    "フォームページスマホ用URL",
    "フォームページタブレット用URL",
    "フォームページURLの一致タイプ",
    "確認ページ名",
    "確認ページPC用URL",
    "確認ページスマホ用URL",
    "確認ページタブレット用URL",
    "確認ページURLの一致タイプ",
    "サンクスページ名",
    "サンクスページPC用URL",
    "サンクスページスマホ用URL",
    "サンクスページタブレット用URL",
    "サンクスページURLの一致タイプ",
    "テスト用のファネル",
  ].join(",");

  const handleApplyClick = useCallback<FunnelBulkTabProps["onApplyClick"]>(
    async (csvObject) => {
      try {
        const rows = csvObject.rows.filter(
          (row) => row.length === downloadCSVData.split(",").length
        );
        const mutations: Promise<{}>[] = rows.map((row) => {
          return createMutate({
            variables: {
              input: {
                siteId: site.id,
                name: row[0],
                isExperiment: row[22] === "true" ? true : false,
                landingPageAttributes: {
                  name: row[1] === "" ? "ランディングページ" : row[1],
                  url: row[2],
                  mobileUrl: row[3] === "" ? null : row[3],
                  tabletUrl: row[4] === "" ? null : row[4],
                  urlMatchType: stringConvertToUrlMatchType(row[5]),
                  hasForm: stringToHasForm(row[6]),
                },
                formPageAttributes: [
                  {
                    name: row[7] === "" ? "フォームページ" : row[7],
                    url: row[8],
                    mobileUrl: row[9] === "" ? null : row[9],
                    tabletUrl: row[10] === "" ? null : row[10],
                    urlMatchType: stringConvertToUrlMatchType(row[11]),
                  },
                ],
                confirmPageAttributes: {
                  name: row[12] === "" ? "確認ページ" : row[12],
                  url: row[13],
                  mobileUrl: row[14] === "" ? null : row[14],
                  tabletUrl: row[15] === "" ? null : row[15],
                  urlMatchType: stringConvertToUrlMatchType(row[16]),
                },
                thanksPageAttributes: {
                  name: row[17] === "" ? "サンクスページ" : row[17],
                  url: row[18],
                  mobileUrl: row[19] === "" ? null : row[19],
                  tabletUrl: row[20] === "" ? null : row[20],
                  urlMatchType: stringConvertToUrlMatchType(row[21]),
                },
              },
            },
          });
        });

        await delayChunkPromise(mutations);

        toast({ title: "ファネルを一括作成しました", status: "success" });
        navigate(`/sites/${siteSlug}/settings`);
      } catch (err) {
        toast({
          title: "ファネルの一括作成に失敗しました",
          status: "error",
        });
      }
    },
    [toast, navigate, siteSlug, downloadCSVData, createMutate, site.id]
  );

  return (
    <FunnelBulkTab
      onApplyClick={handleApplyClick}
      downloadCSVData={downloadCSVData}
      downloadCSVFilename={"ファネル一括登録"}
    />
  );
};
