/**
 * @generated SignedSource<<fc17b92265be6352a5360df218293fa2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TagTab_Query$variables = {};
export type TagTab_QueryVariables = TagTab_Query$variables;
export type TagTab_Query$data = {
  readonly viewer: {
    readonly tagScript: string;
  };
};
export type TagTab_QueryResponse = TagTab_Query$data;
export type TagTab_Query = {
  variables: TagTab_QueryVariables;
  response: TagTab_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tagScript",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TagTab_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "TagTab_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5c20db89f57fe920f908ec763b6d8b8e",
    "id": null,
    "metadata": {},
    "name": "TagTab_Query",
    "operationKind": "query",
    "text": "query TagTab_Query {\n  viewer {\n    tagScript\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "7543ddf017f69d24bc6585fe0d09c4c7";

export default node;
