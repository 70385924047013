/**
 * @generated SignedSource<<f5c732209cd45ab1944e310ff4dfb964>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteSourceTagInput = {
  clientMutationId?: string | null;
  sourceTagId: string;
};
export type FunnelSourceListPage_Mutation$variables = {
  input: DeleteSourceTagInput;
  connections: ReadonlyArray<string>;
};
export type FunnelSourceListPage_MutationVariables = FunnelSourceListPage_Mutation$variables;
export type FunnelSourceListPage_Mutation$data = {
  readonly deleteSourceTag: {
    readonly deletedSourceTagId: string;
  } | null;
};
export type FunnelSourceListPage_MutationResponse = FunnelSourceListPage_Mutation$data;
export type FunnelSourceListPage_Mutation = {
  variables: FunnelSourceListPage_MutationVariables;
  response: FunnelSourceListPage_Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedSourceTagId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FunnelSourceListPage_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "DeleteSourceTagPayload",
        "kind": "LinkedField",
        "name": "deleteSourceTag",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "FunnelSourceListPage_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "DeleteSourceTagPayload",
        "kind": "LinkedField",
        "name": "deleteSourceTag",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "deleteEdge",
            "key": "",
            "kind": "ScalarHandle",
            "name": "deletedSourceTagId",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a1480f7d296782e16bd313985b25768d",
    "id": null,
    "metadata": {},
    "name": "FunnelSourceListPage_Mutation",
    "operationKind": "mutation",
    "text": "mutation FunnelSourceListPage_Mutation(\n  $input: DeleteSourceTagInput!\n) {\n  deleteSourceTag(input: $input) {\n    deletedSourceTagId\n  }\n}\n"
  }
};
})();

(node as any).hash = "319bc667883c2549e7f0463b63ac37e8";

export default node;
