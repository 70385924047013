import { useToast } from "@chakra-ui/react";
import * as React from "react";
import { graphql } from "react-relay";
import { useNavigate, useParams } from "react-router-dom";

import { ABTestGroupCopyButton_Mutation as Mutation } from "~/src/__relay_artifacts__/ABTestGroupCopyButton_Mutation.graphql";
import { OutlineButton } from "~/src/components/common/Button";
import {
  CopyModal,
  FormValues,
} from "~/src/components/features/global/CopyModal";
import { useOpenState } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";

type Props = {
  originalId: string;
  currentValues: FormValues;
};

const mutation = graphql`
  mutation ABTestGroupCopyButton_Mutation(
    $input: CopyAbtestScenarioPageGroupInput!
  ) {
    copyAbtestScenarioPageGroup(input: $input) {
      abtestScenarioPageGroup {
        id
        title
        slug
      }
    }
  }
`;

export const ABTestGroupCopyButton: React.FC<Props> = ({
  originalId,
  currentValues,
}) => {
  const toast = useToast();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useOpenState();
  const { siteSlug = "" } = useParams();

  const mutate = useMutationCommit<Mutation>(mutation);

  const handleClick = React.useCallback(
    async (pageId: string) => {
      try {
        const res = await mutate({
          variables: {
            input: {
              abtestScenarioPageGroupId: originalId,
              pageId,
            },
          },
        });

        const abtestScenarioPageGroup =
          res.copyAbtestScenarioPageGroup?.abtestScenarioPageGroup;
        if (!abtestScenarioPageGroup) throw new Error("assertion failed");

        toast({
          title: `${abtestScenarioPageGroup.title}を作成しました`,
          status: "success",
        });
        navigate(
          `/sites/${siteSlug}/abtests/${abtestScenarioPageGroup.slug}/edit`
        );
      } catch (err) {
        toast({ title: "コピーに失敗しました", status: "error" });
      }
    },
    [mutate, navigate, originalId, siteSlug, toast]
  );

  return (
    <>
      <OutlineButton onClick={onOpen}>複製</OutlineButton>
      <CopyModal
        isOpen={isOpen}
        onClose={onClose}
        initialValues={currentValues}
        onSubmit={handleClick}
      />
    </>
  );
};
