/**
 * @generated SignedSource<<f237644c7861afbbb98e203acd2b4407>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DailyReportTable_site$data = {
  readonly reportDaily: any;
  readonly " $fragmentType": "DailyReportTable_site";
};
export type DailyReportTable_site = DailyReportTable_site$data;
export type DailyReportTable_site$key = {
  readonly " $data"?: DailyReportTable_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"DailyReportTable_site">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "input"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "DailyReportTable_site",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "input",
          "variableName": "input"
        }
      ],
      "kind": "ScalarField",
      "name": "reportDaily",
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "620df76f9537df1b8dc51c4212ceeeb6";

export default node;
