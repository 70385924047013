/**
 * @generated SignedSource<<fc00d4c023272d201e5a390661026dd6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ExcludedIpAddressStatus = "ACTIVE" | "INACTIVE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ExcludedIPTableDataRow_excludedIpAddress$data = {
  readonly id: string;
  readonly ipAddress: string;
  readonly status: ExcludedIpAddressStatus;
  readonly slug: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly " $fragmentType": "ExcludedIPTableDataRow_excludedIpAddress";
};
export type ExcludedIPTableDataRow_excludedIpAddress = ExcludedIPTableDataRow_excludedIpAddress$data;
export type ExcludedIPTableDataRow_excludedIpAddress$key = {
  readonly " $data"?: ExcludedIPTableDataRow_excludedIpAddress$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExcludedIPTableDataRow_excludedIpAddress">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExcludedIPTableDataRow_excludedIpAddress",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ipAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    }
  ],
  "type": "ExcludedIpAddress",
  "abstractKey": null
};

(node as any).hash = "c093e30cb732ec8ff7eefb419de650d9";

export default node;
