import { useToast } from "@chakra-ui/toast";
import { FC, useCallback } from "react";
import { graphql } from "react-relay";

import { ArchiveButton_Mutation } from "~/src/__relay_artifacts__/ArchiveButton_Mutation.graphql";
import { OutlineButton } from "~/src/components/common/Button";
import { useMutationCommit } from "~/src/lib/react-relay";

type Props = {
  id: string;
};

const mutation = graphql`
  mutation ArchiveButton_Mutation($input: ArchiveArchivableInput!) {
    archiveArchivable(input: $input) {
      archivable {
        id
        isArchive
      }
    }
  }
`;

export const ArchiveButton: FC<Props> = ({ id }) => {
  const toast = useToast();

  const mutate = useMutationCommit<ArchiveButton_Mutation>(mutation);

  const handleClick = useCallback(async () => {
    try {
      const res = await mutate({ variables: { input: { archivableId: id } } });
      if (!res.archiveArchivable?.archivable)
        throw new Error("assertion failed");

      toast({
        title: "アーカイブしました",
        status: "success",
      });
    } catch (err) {
      toast({
        title: "アーカイブに失敗しました",
        status: "error",
      });
    }
  }, [mutate, id, toast]);

  return <OutlineButton onClick={handleClick}>アーカイブ</OutlineButton>;
};
