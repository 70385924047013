import { useCallback, useState } from "react";

import { useOpenState } from "~/src/lib/hooks";

export const useEditModalState = () => {
  const { isOpen, onOpen, onClose } = useOpenState();
  const [editId, setEditId] = useState("");

  const onEditClick = useCallback(
    (id: string) => {
      setEditId(id);
      onOpen();
    },
    [onOpen]
  );

  return {
    isOpen: !!editId && isOpen,
    editId,
    onEditClick,
    onClose,
  };
};
