import { FC } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";

import { PopupGroupEditPageContainer_Query } from "~/src/__relay_artifacts__/PopupGroupEditPageContainer_Query.graphql";

import { PopupGroupEditScreenContainer } from "./PopupGroupEditScreenContainer";

export type Props = {};

const query = graphql`
  query PopupGroupEditPageContainer_Query(
    $siteSlug: String!
    $popupSlug: String!
  ) {
    site(slug: $siteSlug) {
      ...PopupGroupEditScreenContainer_site
    }
    popupGroup(slug: $popupSlug) {
      ...PopupGroupEditScreenContainer_popupGroup
    }
  }
`;

export const PopupGroupEditPageContainer: FC<Props> = () => {
  const { siteSlug = "", popupGroupSlug = "" } = useParams();

  const { site, popupGroup } =
    useLazyLoadQuery<PopupGroupEditPageContainer_Query>(query, {
      siteSlug,
      popupSlug: popupGroupSlug,
    });

  if (!site || !popupGroup) throw new Error("assertion failed");

  return (
    <PopupGroupEditScreenContainer siteRef={site} popupGroupRef={popupGroup} />
  );
};
