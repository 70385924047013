/**
 * @generated SignedSource<<a14cf8e1ce23ebe0f25620fb6e3ed485>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateWeightableInput = {
  clientMutationId?: string | null;
  weightableId: string;
  weight: number;
};
export type ABTestDetailPageContainer_updateDeliverWeightMutation$variables = {
  input: UpdateWeightableInput;
};
export type ABTestDetailPageContainer_updateDeliverWeightMutationVariables = ABTestDetailPageContainer_updateDeliverWeightMutation$variables;
export type ABTestDetailPageContainer_updateDeliverWeightMutation$data = {
  readonly updateWeightable: {
    readonly weightable: {
      readonly id: string;
      readonly weight: number;
    };
  } | null;
};
export type ABTestDetailPageContainer_updateDeliverWeightMutationResponse = ABTestDetailPageContainer_updateDeliverWeightMutation$data;
export type ABTestDetailPageContainer_updateDeliverWeightMutation = {
  variables: ABTestDetailPageContainer_updateDeliverWeightMutationVariables;
  response: ABTestDetailPageContainer_updateDeliverWeightMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "weight",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ABTestDetailPageContainer_updateDeliverWeightMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateWeightablePayload",
        "kind": "LinkedField",
        "name": "updateWeightable",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "weightable",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ABTestDetailPageContainer_updateDeliverWeightMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateWeightablePayload",
        "kind": "LinkedField",
        "name": "updateWeightable",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "weightable",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6d432871c1aa7c85ec8feb7b3d8be9ed",
    "id": null,
    "metadata": {},
    "name": "ABTestDetailPageContainer_updateDeliverWeightMutation",
    "operationKind": "mutation",
    "text": "mutation ABTestDetailPageContainer_updateDeliverWeightMutation(\n  $input: UpdateWeightableInput!\n) {\n  updateWeightable(input: $input) {\n    weightable {\n      __typename\n      id\n      weight\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3fcbd21f1ae3c60c438bc13c543ebbff";

export default node;
