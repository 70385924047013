/**
 * @generated SignedSource<<074f3ea764de20f3feb9c3e19a1c3736>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AccountSettingsPageContainer_Query$variables = {};
export type AccountSettingsPageContainer_QueryVariables = AccountSettingsPageContainer_Query$variables;
export type AccountSettingsPageContainer_Query$data = {
  readonly viewer: {
    readonly name: string | null;
    readonly company: string | null;
    readonly tel: string | null;
    readonly email: string;
    readonly isHoneycomb: boolean;
    readonly itpUrls: string | null;
    readonly isSecure: boolean;
    readonly isMacbeeServer: boolean;
    readonly userTag: string | null;
    readonly isCrossDomainQuery: boolean;
    readonly isCrossDomainFragment: boolean;
  };
};
export type AccountSettingsPageContainer_QueryResponse = AccountSettingsPageContainer_Query$data;
export type AccountSettingsPageContainer_Query = {
  variables: AccountSettingsPageContainer_QueryVariables;
  response: AccountSettingsPageContainer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "company",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tel",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isHoneycomb",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "itpUrls",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isSecure",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isMacbeeServer",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userTag",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isCrossDomainQuery",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isCrossDomainFragment",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountSettingsPageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AccountSettingsPageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "01e44dc6a61820421cc1cca9e4b41cd7",
    "id": null,
    "metadata": {},
    "name": "AccountSettingsPageContainer_Query",
    "operationKind": "query",
    "text": "query AccountSettingsPageContainer_Query {\n  viewer {\n    name\n    company\n    tel\n    email\n    isHoneycomb\n    itpUrls\n    isSecure\n    isMacbeeServer\n    userTag\n    isCrossDomainQuery\n    isCrossDomainFragment\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "17da76e3117b838bb813200204ba3cdf";

export default node;
