/**
 * @generated SignedSource<<54e28a3d6508358ab8f91aa1960e3667>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FunnelReportGraph_site$data = {
  readonly reportFunnel: any;
  readonly " $fragmentType": "FunnelReportGraph_site";
};
export type FunnelReportGraph_site = FunnelReportGraph_site$data;
export type FunnelReportGraph_site$key = {
  readonly " $data"?: FunnelReportGraph_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"FunnelReportGraph_site">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "input"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "FunnelReportGraph_site",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "input",
          "variableName": "input"
        }
      ],
      "kind": "ScalarField",
      "name": "reportFunnel",
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "b32588cad621b2f7d01c2aa644049b64";

export default node;
