import { VStack } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { FC } from "react";

import { GraphQLEnums } from "~/src/__generated__/GraphQLEnums";
import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormikFormButtons } from "~/src/components/common/forms/FormikFormButtons";
import { FormikRadioButtonsField } from "~/src/components/common/forms/FormikRadioButtonsField";
import { Modal } from "~/src/components/common/Modal";

import { FormValues, validationSchema } from "./validationSchema";
import { WeightFields } from "./WeightFields";

export type Props = {
  readonly isOpen: boolean;
  readonly initialValues: FormValues;
  readonly onClose: () => void;
  readonly onSubmit: (values: FormValues) => void;
};

export const DeliverWeightForm: FC<Props> = ({
  isOpen,
  initialValues,
  onClose,
  onSubmit,
}) => (
  <Modal title="配信比率変更" isOpen={isOpen} onClose={onClose}>
    <Formik<FormValues>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      <Form>
        <VStack spacing="16px">
          <FormControl required label="配信方法">
            <FormikRadioButtonsField
              name="deliverKind"
              options={GraphQLEnums.DeliverKind}
            />
          </FormControl>
          <WeightFields />
        </VStack>
        <FormikFormButtons onCancelClick={onClose} />
      </Form>
    </Formik>
  </Modal>
);
