import { useToast } from "@chakra-ui/react";
import { includes } from "ramda";
import { FC, useCallback, useMemo, useState } from "react";
import { ConnectionHandler, graphql, useLazyLoadQuery } from "react-relay";

import { DistributionStatus, PageKind } from "~/src/__generated__/schema";
import { PopupGroupLinkFormContainer_Mutation } from "~/src/__relay_artifacts__/PopupGroupLinkFormContainer_Mutation.graphql";
import { PopupGroupLinkFormContainer_Query } from "~/src/__relay_artifacts__/PopupGroupLinkFormContainer_Query.graphql";
import { useMutationCommit } from "~/src/lib/react-relay";
import { delayChunkPromise } from "~/src/lib/utils";

import { PopupGroup, PopupGroupLinkFormList } from "./presentations";

export type Props = {
  onClose: () => void;
  siteSlug: string;
  createdPopupGroupIds: string[];
  popupGroupSetId: string;
};

const query = graphql`
  query PopupGroupLinkFormContainer_Query(
    $siteSlug: String!
    $searchTerm: String
    $pageKind: PageKind
    $status: DistributionStatus
  ) {
    site(slug: $siteSlug) {
      popupGroups(
        pageKind: $pageKind
        searchTerm: $searchTerm
        status: $status
      ) {
        edges {
          node {
            id
            slug
            name
            status
            judgeStatus
            thumbnailImageUrl
            popups {
              totalCount
            }
            page {
              id
              name
              kind
              sortNumber
              funnel {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

const mutation = graphql`
  mutation PopupGroupLinkFormContainer_Mutation(
    $input: CreatePopupGroupSettingInput!
    $connections: [ID!]!
  ) {
    createPopupGroupSetting(input: $input) {
      popupGroupSettingEdge @appendEdge(connections: $connections) {
        node {
          id
          popupGroupSet {
            id
            name
            memo
          }
          popupGroup {
            id
            slug
            name
            status
            judgeStatus
            thumbnailImageUrl
            popups {
              totalCount
            }
            page {
              id
              slug
              kind
              name
              url
              sortNumber
              funnel {
                id
                slug
                name
                siteUrl
              }
            }
            ...PopupGroupBulkEditFormContainer_popupGroup
          }
        }
      }
    }
  }
`;

export const PopupGroupLinkFormContainer: FC<Props> = ({
  onClose,
  siteSlug,
  createdPopupGroupIds,
  popupGroupSetId,
}) => {
  const [pageKind, setPageKind] = useState<PageKind | undefined>(undefined);
  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState<DistributionStatus | undefined>(
    undefined
  );

  const toast = useToast();

  const { site } = useLazyLoadQuery<PopupGroupLinkFormContainer_Query>(query, {
    siteSlug,
    pageKind,
    searchTerm: searchText,
    status,
  });
  const mutate =
    useMutationCommit<PopupGroupLinkFormContainer_Mutation>(mutation);

  const popupGroups: PopupGroup[] = useMemo(() => {
    const edges = site.popupGroups.edges || [];
    return edges
      .map((edge) => {
        const node = edge?.node;
        if (!node) throw new Error("assertion failed");
        return {
          id: node.id,
          name: node.name,
          funnelName: node.page.funnel.name,
          pageName: node.page.name,
          imageUrl: node.thumbnailImageUrl,
          status: node.status,
          judgeStatus: node.judgeStatus,
          pageKind: node.page.kind,
          sortNumber: node.page.sortNumber,
          popupCount: node.popups?.totalCount || 0,
        };
      })
      .filter((v) => !includes(v.id, createdPopupGroupIds));
  }, [site.popupGroups, createdPopupGroupIds]);

  const handleSubmit = useCallback(
    async (ids: string[]) => {
      try {
        const connectionId = ConnectionHandler.getConnectionID(
          popupGroupSetId,
          "PopupGroupSetDetailLinkedPopupGroupListTable_popupGroupSet_popupGroupSettings"
        );
        const mutations: Promise<{}>[] = ids.map((popupGroupId) => {
          return mutate({
            variables: {
              input: {
                popupGroupSetId,
                popupGroupId,
              },
              connections: [connectionId],
            },
          });
        });

        await delayChunkPromise(mutations);

        toast({ title: "ポップアップを紐付けました", status: "success" });
        onClose();
      } catch (err) {
        toast({ title: "ポップアップを紐付けに失敗しました", status: "error" });
      }
    },
    [popupGroupSetId, toast, onClose, mutate]
  );

  return (
    <PopupGroupLinkFormList
      onClose={onClose}
      onSubmit={handleSubmit}
      popupGroups={popupGroups}
      setPageKind={setPageKind}
      searchText={searchText}
      setSearchText={setSearchText}
      setDistributionStatus={setStatus}
    />
  );
};
