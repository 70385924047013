import { format } from "date-fns";
import { FC, useCallback, useMemo, useState } from "react";

import { AreaGraph, Data } from "../../AreaGraph";

const measureOptions = [
  { label: "Ready人数", value: "readyUser" },
  { label: "流入数", value: "uniqueUser" },
  { label: "Imp人数", value: "pageViewUser" },
  { label: "閉じる", value: "closeClick" },
  { label: "クリック人数", value: "clickUser" },
  { label: "CV(計)", value: "totalConversionUser" },
  { label: "CV(click)", value: "conversionUser" },
  { label: "CV(imp)", value: "impConversionUser" },
  { label: "CV(ready)", value: "readyConversionUser" },
  { label: "CTR", value: "clickUserRate" },
  { label: "CVR(計)", value: "totalConversionRate" },
  { label: "CVR(click)", value: "conversionClickRate" },
  { label: "CVR(imp)", value: "conversionImpRate" },
  { label: "SP", value: "mobileRate" },
  { label: "TB", value: "tabletRate" },
  { label: "PC", value: "pcRate" },
  { label: "離脱アクションしたユーザー数", value: "dropUser" },
  { label: "離脱アクションしたCV人数", value: "dropConversionUser" },
  { label: "離脱アクションしたCVR", value: "conversionDropRate" },
] as const;

export type Prop = {
  graphData: Record<any, any>;
  graphOptions: { label: string; value: string }[];
};

type FormValues = {
  popupIds: string[];
  measureKey: string | null;
};

export const PopupReportGraph: FC<Prop> = ({ graphData, graphOptions }) => {
  const [formValues, setFormValues] = useState<FormValues>({
    popupIds: ["summary"],
    measureKey: "uniqueUser",
  });
  const dataArray: { data: Data[] }[] = useMemo(() => {
    return formValues.popupIds.map((id) => {
      return {
        data: !graphData[id]
          ? []
          : graphData[id].map((row: any) => {
              return {
                x: format(new Date(row.accessDate), "yyyy/MM/dd"),
                y: formValues.measureKey
                  ? row[formValues.measureKey]
                  : row["readyUser"],
              };
            }),
      };
    });
  }, [graphData, formValues]);

  const handleSubmit = useCallback(({ popupIds, measureKey }) => {
    setFormValues({ popupIds, measureKey });
  }, []);

  const graphLabel = useMemo(() => {
    return (
      measureOptions.find((option) => option.value === formValues.measureKey)
        ?.label || measureOptions[0].label
    );
  }, [formValues.measureKey]);

  return (
    <AreaGraph
      title={`日別推移｜${graphLabel}`}
      label={`${graphLabel}`}
      dataArray={dataArray}
      graphCustomizedButtonProps={{
        selectFields: [
          {
            name: "popupIds",
            label: "ポップアップ名",
            options: graphOptions,
            isMultiple: true,
          },
          {
            name: "measureKey",
            label: "項目",
            options: measureOptions,
            isMultiple: false,
          },
        ],
        formValues,
        onSubmit: handleSubmit,
      }}
    />
  );
};
