/**
 * @generated SignedSource<<d41f3989a98120a83e8d86aa96cd9c6c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DistributionStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type GroupContentJudgeStatus = "PENDING" | "APPROVED" | "%future added value";
export type PageKind = "LANDING" | "FORM" | "CONFIRM" | "THANKS" | "%future added value";
export type CreatePopupGroupSettingInput = {
  clientMutationId?: string | null;
  popupGroupSetId: string;
  popupGroupId: string;
};
export type PopupGroupLinkFormContainer_Mutation$variables = {
  input: CreatePopupGroupSettingInput;
  connections: ReadonlyArray<string>;
};
export type PopupGroupLinkFormContainer_MutationVariables = PopupGroupLinkFormContainer_Mutation$variables;
export type PopupGroupLinkFormContainer_Mutation$data = {
  readonly createPopupGroupSetting: {
    readonly popupGroupSettingEdge: {
      readonly node: {
        readonly id: string;
        readonly popupGroupSet: {
          readonly id: string;
          readonly name: string;
          readonly memo: string | null;
        };
        readonly popupGroup: {
          readonly id: string;
          readonly slug: string;
          readonly name: string;
          readonly status: DistributionStatus;
          readonly judgeStatus: GroupContentJudgeStatus | null;
          readonly thumbnailImageUrl: string | null;
          readonly popups: {
            readonly totalCount: number;
          } | null;
          readonly page: {
            readonly id: string;
            readonly slug: string;
            readonly kind: PageKind;
            readonly name: string;
            readonly url: string;
            readonly sortNumber: number;
            readonly funnel: {
              readonly id: string;
              readonly slug: string;
              readonly name: string;
              readonly siteUrl: string;
            };
          };
          readonly " $fragmentSpreads": FragmentRefs<"PopupGroupBulkEditFormContainer_popupGroup">;
        };
      } | null;
    };
  } | null;
};
export type PopupGroupLinkFormContainer_MutationResponse = PopupGroupLinkFormContainer_Mutation$data;
export type PopupGroupLinkFormContainer_Mutation = {
  variables: PopupGroupLinkFormContainer_MutationVariables;
  response: PopupGroupLinkFormContainer_Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "memo",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "PopupGroupSet",
  "kind": "LinkedField",
  "name": "popupGroupSet",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "judgeStatus",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnailImageUrl",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "PopupConnection",
  "kind": "LinkedField",
  "name": "popups",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "Page",
  "kind": "LinkedField",
  "name": "page",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    (v4/*: any*/),
    (v12/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sortNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Funnel",
      "kind": "LinkedField",
      "name": "funnel",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        (v7/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "siteUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = [
  (v12/*: any*/),
  (v3/*: any*/)
],
v15 = [
  (v3/*: any*/),
  (v4/*: any*/)
],
v16 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "targetKind",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "targetValue",
    "storageKey": null
  },
  (v3/*: any*/)
],
v17 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "referrer",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "matchOp",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PopupGroupLinkFormContainer_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CreatePopupGroupSettingPayload",
        "kind": "LinkedField",
        "name": "createPopupGroupSetting",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PopupGroupSettingEdge",
            "kind": "LinkedField",
            "name": "popupGroupSettingEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PopupGroupSetting",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PopupGroup",
                    "kind": "LinkedField",
                    "name": "popupGroup",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v7/*: any*/),
                      (v4/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v13/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "PopupGroupBulkEditFormContainer_popupGroup"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PopupGroupLinkFormContainer_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CreatePopupGroupSettingPayload",
        "kind": "LinkedField",
        "name": "createPopupGroupSetting",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PopupGroupSettingEdge",
            "kind": "LinkedField",
            "name": "popupGroupSettingEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PopupGroupSetting",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PopupGroup",
                    "kind": "LinkedField",
                    "name": "popupGroup",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v7/*: any*/),
                      (v4/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "ageRanges",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "annualIncomes",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "browsers",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DeliverDayOfWeek",
                        "kind": "LinkedField",
                        "name": "deliverDayOfWeeks",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "weekOfMonth",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "daysOfWeek",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PopupGroupDeliverExcludeUrl",
                        "kind": "LinkedField",
                        "name": "deliverExcludeUrls",
                        "plural": true,
                        "selections": (v14/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserLabel",
                        "kind": "LinkedField",
                        "name": "deliverExcludeLabels",
                        "plural": true,
                        "selections": (v15/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PopupGroupDeliverTargetUrl",
                        "kind": "LinkedField",
                        "name": "deliverTargetUrls",
                        "plural": true,
                        "selections": (v14/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserLabel",
                        "kind": "LinkedField",
                        "name": "deliverTargetLabels",
                        "plural": true,
                        "selections": (v15/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "deliverKind",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "devices",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PopupGroupDisplayTargetContent",
                        "kind": "LinkedField",
                        "name": "displayTargetContents",
                        "plural": true,
                        "selections": (v16/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PopupGroupDisableTargetElement",
                        "kind": "LinkedField",
                        "name": "disableTargetElements",
                        "plural": true,
                        "selections": (v16/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "platforms",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "genders",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isMulti",
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "repeat",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endDate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endTime",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "redisplayPopupTime",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Source",
                        "kind": "LinkedField",
                        "name": "sources",
                        "plural": true,
                        "selections": (v15/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Source",
                        "kind": "LinkedField",
                        "name": "excludeSources",
                        "plural": true,
                        "selections": (v15/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startDate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startTime",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PopupGroupExcludeReferrer",
                        "kind": "LinkedField",
                        "name": "excludeReferrers",
                        "plural": true,
                        "selections": (v17/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PopupGroupTargetCookie",
                        "kind": "LinkedField",
                        "name": "targetCookies",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "key",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "value",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "matchType",
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PopupGroupReferrer",
                        "kind": "LinkedField",
                        "name": "referrers",
                        "plural": true,
                        "selections": (v17/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "visitCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "visitMatchType",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendEdge",
            "key": "",
            "kind": "LinkedHandle",
            "name": "popupGroupSettingEdge",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3b6f5612c476d1d8f0cf80eac2d0edb0",
    "id": null,
    "metadata": {},
    "name": "PopupGroupLinkFormContainer_Mutation",
    "operationKind": "mutation",
    "text": "mutation PopupGroupLinkFormContainer_Mutation(\n  $input: CreatePopupGroupSettingInput!\n) {\n  createPopupGroupSetting(input: $input) {\n    popupGroupSettingEdge {\n      node {\n        id\n        popupGroupSet {\n          id\n          name\n          memo\n        }\n        popupGroup {\n          id\n          slug\n          name\n          status\n          judgeStatus\n          thumbnailImageUrl\n          popups {\n            totalCount\n          }\n          page {\n            id\n            slug\n            kind\n            name\n            url\n            sortNumber\n            funnel {\n              id\n              slug\n              name\n              siteUrl\n            }\n          }\n          ...PopupGroupBulkEditFormContainer_popupGroup\n        }\n      }\n    }\n  }\n}\n\nfragment PopupGroupBulkEditFormContainer_popupGroup on PopupGroup {\n  slug\n  ageRanges\n  annualIncomes\n  browsers\n  deliverDayOfWeeks {\n    weekOfMonth\n    daysOfWeek\n  }\n  deliverExcludeUrls {\n    url\n    id\n  }\n  deliverExcludeLabels {\n    id\n    name\n  }\n  deliverTargetUrls {\n    url\n    id\n  }\n  deliverTargetLabels {\n    id\n    name\n  }\n  deliverKind\n  devices\n  displayTargetContents {\n    targetKind\n    targetValue\n    id\n  }\n  disableTargetElements {\n    targetKind\n    targetValue\n    id\n  }\n  platforms\n  genders\n  id\n  isMulti\n  memo\n  name\n  repeat\n  endDate\n  endTime\n  page {\n    id\n    funnel {\n      id\n    }\n  }\n  redisplayPopupTime\n  sources {\n    id\n    name\n  }\n  excludeSources {\n    id\n    name\n  }\n  startDate\n  startTime\n  status\n  excludeReferrers {\n    referrer\n    matchOp\n  }\n  targetCookies {\n    key\n    value\n    matchType\n    id\n  }\n  referrers {\n    referrer\n    matchOp\n  }\n  visitCount\n  visitMatchType\n}\n"
  }
};
})();

(node as any).hash = "1ebb9958fa0323456adac2ff543c6897";

export default node;
