import { useToast } from "@chakra-ui/react";
import { FC, useCallback } from "react";
import { graphql } from "react-relay";

import { PopupGroupSetEditFormContainer_Mutation } from "~/src/__relay_artifacts__/PopupGroupSetEditFormContainer_Mutation.graphql";
import { useFormErrorHandler } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";

import { PopupSetForm, PopupSetFormProps } from "./presentations";

export type Props = {
  onClose: () => void;
  popupGroupSet: { id: string; name: string; memo: string | null };
};

const mutation = graphql`
  mutation PopupGroupSetEditFormContainer_Mutation(
    $input: UpdatePopupGroupSetInput!
  ) {
    updatePopupGroupSet(input: $input) {
      popupGroupSet {
        id
        name
        memo
      }
    }
  }
`;

export const PopupGroupSetEditFormContainer: FC<Props> = ({
  onClose,
  popupGroupSet,
}) => {
  const toast = useToast();
  const { onFormError } = useFormErrorHandler();
  const mutate =
    useMutationCommit<PopupGroupSetEditFormContainer_Mutation>(mutation);
  const initialValues = {
    name: popupGroupSet.name,
    memo: popupGroupSet.memo || "",
  };

  const handleSubmit = useCallback<PopupSetFormProps["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const res = await mutate({
          variables: {
            input: {
              popupGroupSetId: popupGroupSet.id,
              name: values.name,
              memo: values.memo,
            },
          },
        });
        if (!res.updatePopupGroupSet?.popupGroupSet)
          throw new Error("assertion failed");
        toast({ title: "ポップアップセットを更新しました", status: "success" });
        onClose();
      } catch (err) {
        onFormError(err, setErrors);
      }
    },
    [mutate, onFormError, toast, onClose, popupGroupSet.id]
  );

  return (
    <PopupSetForm
      onSubmit={handleSubmit}
      onCancelClick={onClose}
      initialValues={initialValues}
    />
  );
};
