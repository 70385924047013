/**
 * @generated SignedSource<<ff7fe11d019859a02a6d2b4dc2571f1d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UserRole = "SUPER" | "ADMIN_ALL" | "ADMIN" | "JUDGE" | "CLIENT" | "SAAS_CLIENT" | "%future added value";
export type HeaderNavigation_Query$variables = {};
export type HeaderNavigation_QueryVariables = HeaderNavigation_Query$variables;
export type HeaderNavigation_Query$data = {
  readonly notifications: {
    readonly totalCount: number;
  } | null;
  readonly viewer: {
    readonly role: UserRole;
    readonly seenNotificationsCount: number;
  };
};
export type HeaderNavigation_QueryResponse = HeaderNavigation_Query$data;
export type HeaderNavigation_Query = {
  variables: HeaderNavigation_QueryVariables;
  response: HeaderNavigation_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "NotificationConnection",
  "kind": "LinkedField",
  "name": "notifications",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "seenNotificationsCount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "HeaderNavigation_Query",
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "HeaderNavigation_Query",
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "37c72ed2517ad3bba8809c723bb4dcfd",
    "id": null,
    "metadata": {},
    "name": "HeaderNavigation_Query",
    "operationKind": "query",
    "text": "query HeaderNavigation_Query {\n  notifications {\n    totalCount\n  }\n  viewer {\n    role\n    seenNotificationsCount\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "c7306ed33f09d8879e2a7b6e7ba331bb";

export default node;
