import { array, number, object, string } from "yup";

import {
  AbtestScenarioPageContentTextReplaceActionKind,
  AbtestScenarioPageContentTextReplaceTargetKind,
  AbtestScenarioPageKind,
  InputMaybe,
  Scalars,
} from "~/src/__generated__/schema";

export type ContentWithScenarioField = {
  id?: string;
  beforeSrc?: string | null;
  image?: InputMaybe<Scalars["Upload"]>;
  imageUrl?: string | null;
};

export type ContentWithRedirectField = {
  linkUrl: string | null;
  isInheritQuery: boolean;
};

export type ContentWithTextReplaceField = {
  id?: string;
  targetType?: AbtestScenarioPageContentTextReplaceTargetKind;
  actionType?: AbtestScenarioPageContentTextReplaceActionKind;
  targetValue?: string;
  targetAttribute?: string;
  replaceValue?: string | null;
};

export type FormValues = {
  id?: string;
  title: string | null;
  kind: AbtestScenarioPageKind;
  redirectContents?: ContentWithRedirectField;
  textReplaceContents?: ContentWithTextReplaceField[];
  scenarioContents?: ContentWithScenarioField[];
  weight: number | null;
  originalPatternWeight: number;
};

export type ArrayFormValues = {
  formValues: FormValues[];
};

export const validationSchema = object({
  title: string().nullable().max(50),
  kind: string().required(),
  contents: array().of(object({})),
  weight: number().nullable(),
  originalPatternWeight: number().nullable(),
});

export const arrayValidationSchema = object().shape({
  formValues: array()
    .of(
      object().shape({
        title: string().nullable().max(50),
        kind: string().required(),
        contents: array().of(object({})),
        weight: number().nullable(),
        originalPatternWeight: number().nullable(),
      })
    )
    .min(1),
});
