/**
 * @generated SignedSource<<020e9d754cc208a14cb35f70ab91dec2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Device = "MOBILE" | "TABLET" | "PC" | "%future added value";
export type ReportAnalyzerInput = {
  startOn: string;
  endOn: string;
  device?: Device | null;
  sourceId?: string | null;
  funnelId?: string | null;
};
export type ABTestPatternTableDataRow_RefetchQuery$variables = {
  input: ReportAnalyzerInput;
  id: string;
};
export type ABTestPatternTableDataRow_RefetchQueryVariables = ABTestPatternTableDataRow_RefetchQuery$variables;
export type ABTestPatternTableDataRow_RefetchQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"ABTestPatternTableDataRow_abtestPattern">;
  } | null;
};
export type ABTestPatternTableDataRow_RefetchQueryResponse = ABTestPatternTableDataRow_RefetchQuery$data;
export type ABTestPatternTableDataRow_RefetchQuery = {
  variables: ABTestPatternTableDataRow_RefetchQueryVariables;
  response: ABTestPatternTableDataRow_RefetchQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ABTestPatternTableDataRow_RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": (v3/*: any*/),
            "kind": "FragmentSpread",
            "name": "ABTestPatternTableDataRow_abtestPattern"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ABTestPatternTableDataRow_RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isArchive",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "kind": "ScalarField",
                "name": "uniqueUserCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "kind": "ScalarField",
                "name": "cvUserCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "kind": "ScalarField",
                "name": "cvr",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "kind": "ScalarField",
                "name": "averageStayTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "kind": "ScalarField",
                "name": "scrollRate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "kind",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "judgeStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "weight",
                "storageKey": null
              }
            ],
            "type": "AbtestScenarioPage",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e6486b194c7a0a7df48aa27f00ccc01b",
    "id": null,
    "metadata": {},
    "name": "ABTestPatternTableDataRow_RefetchQuery",
    "operationKind": "query",
    "text": "query ABTestPatternTableDataRow_RefetchQuery(\n  $input: ReportAnalyzerInput!\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...ABTestPatternTableDataRow_abtestPattern_2VV6jB\n    id\n  }\n}\n\nfragment ABTestPatternTableDataRow_abtestPattern_2VV6jB on AbtestScenarioPage {\n  id\n  slug\n  title\n  status\n  isArchive\n  uniqueUserCount(input: $input)\n  cvUserCount(input: $input)\n  cvr(input: $input)\n  averageStayTime(input: $input)\n  scrollRate(input: $input)\n  kind\n  judgeStatus\n  weight\n}\n"
  }
};
})();

(node as any).hash = "398abde6d8ccba69c869a321a01305e0";

export default node;
