/**
 * @generated SignedSource<<514194da998c4a110d81261d33fa8162>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type HeaderSiteSelect_Query$variables = {
  slug: string;
};
export type HeaderSiteSelect_QueryVariables = HeaderSiteSelect_Query$variables;
export type HeaderSiteSelect_Query$data = {
  readonly site: {
    readonly name: string;
    readonly slug: string;
    readonly id: string;
  };
};
export type HeaderSiteSelect_QueryResponse = HeaderSiteSelect_Query$data;
export type HeaderSiteSelect_Query = {
  variables: HeaderSiteSelect_QueryVariables;
  response: HeaderSiteSelect_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "slug",
        "variableName": "slug"
      }
    ],
    "concreteType": "Site",
    "kind": "LinkedField",
    "name": "site",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "slug",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HeaderSiteSelect_Query",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HeaderSiteSelect_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1b38439cd485a2a41829d654a3f4aa13",
    "id": null,
    "metadata": {},
    "name": "HeaderSiteSelect_Query",
    "operationKind": "query",
    "text": "query HeaderSiteSelect_Query(\n  $slug: String!\n) {\n  site(slug: $slug) {\n    name\n    slug\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "19750014f8c33e0ee3143bf0cf5dd443";

export default node;
