import { DescriptionOutlined as DescriptionOutlinedIcon } from "@mui/icons-material";
import { FC, useMemo } from "react";

import { Modal } from "~/src/components/common/Modal";
import {
  TableDataColumn,
  TableDataRow,
} from "~/src/components/common/tables/Table";
import { TableActions } from "~/src/components/common/tables/TableActions";
import { SnippetCard } from "~/src/components/features/global/SnippetCard";
import { useOpenState } from "~/src/lib/hooks";

type User = {
  name: string | null;
  email: string;
  company: string | null;
  tagScript: string;
};

export type Props = {
  user: User;
};

export const AccountTableDataRow: FC<Props> = ({ user }) => {
  const { isOpen, onOpen, onClose } = useOpenState();

  const actionMenus = useMemo(
    () => [
      {
        label: "タグ",
        icon: <DescriptionOutlinedIcon />,
        onClick: () => onOpen(),
      },
    ],
    [onOpen]
  );

  return (
    <TableDataRow height={"80px"}>
      <TableDataColumn type="text" data={user.name || "未設定"} />
      <TableDataColumn type="text" data={user.company || "未設定"} />
      <TableDataColumn type="text" data={user.email} />
      <TableDataColumn
        type="actions"
        data={
          <>
            <TableActions actionMenus={actionMenus} />
            <Modal isOpen={isOpen} onClose={onClose} title="タグ">
              <SnippetCard snippet={user.tagScript} />
            </Modal>
          </>
        }
      />
    </TableDataRow>
  );
};
