/**
 * @generated SignedSource<<88444c5e770cfc3d4d38199c163bd7b2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DeliverKind = "EQUAL" | "WEIGHT" | "%future added value";
export type ABTestPatternCreatePageContainer_Query$variables = {
  slug: string;
};
export type ABTestPatternCreatePageContainer_QueryVariables = ABTestPatternCreatePageContainer_Query$variables;
export type ABTestPatternCreatePageContainer_Query$data = {
  readonly abtestScenarioPageGroup: {
    readonly id: string;
    readonly title: string;
    readonly slug: string;
    readonly deliverKind: DeliverKind | null;
    readonly page: {
      readonly id: string;
    };
  } | null;
};
export type ABTestPatternCreatePageContainer_QueryResponse = ABTestPatternCreatePageContainer_Query$data;
export type ABTestPatternCreatePageContainer_Query = {
  variables: ABTestPatternCreatePageContainer_QueryVariables;
  response: ABTestPatternCreatePageContainer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "slug",
        "variableName": "slug"
      }
    ],
    "concreteType": "AbtestScenarioPageGroup",
    "kind": "LinkedField",
    "name": "abtestScenarioPageGroup",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "slug",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deliverKind",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Page",
        "kind": "LinkedField",
        "name": "page",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ABTestPatternCreatePageContainer_Query",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ABTestPatternCreatePageContainer_Query",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "c47d1f1bc8121caa7e3edf728f3d36c6",
    "id": null,
    "metadata": {},
    "name": "ABTestPatternCreatePageContainer_Query",
    "operationKind": "query",
    "text": "query ABTestPatternCreatePageContainer_Query(\n  $slug: String!\n) {\n  abtestScenarioPageGroup(slug: $slug) {\n    id\n    title\n    slug\n    deliverKind\n    page {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "092d1a8f0e6e0f8ab3c343d87b27ec7e";

export default node;
