import { Box } from "@chakra-ui/react";

import { chakraFactory } from "~/src/lib/chakra-ui";

import { columnStyles } from "../styles";

export const TableEmptyColumn = chakraFactory(Box, {
  baseStyle: {
    ...columnStyles,
    flex: "initial",
    width: "30px",
    minWidth: "30px",
    background: "#DBDBDB",
    border: "1px solid #DBDBDB",
  },
});
