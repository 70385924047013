import { Box, Checkbox, HStack, Image, VStack, Wrap } from "@chakra-ui/react";
import { FC } from "react";

import { UserRole } from "~/src/__generated__/schema";
import { chakraFactory } from "~/src/lib/chakra-ui";

type PopupImage = {
  alt: string | null;
  linkUrl: string | null;
  imageData: string | null;
  imageUrl: string | null;
  isInheritQuery: boolean | null;
  honeycombCode: string | null;
};

export type Props = {
  popupImages: PopupImage[];
  role: UserRole;
};

const StyledFlexWrapperBox = chakraFactory(Box, {
  baseStyle: {
    width: "100%",
    display: "flex",
    marginBottom: "8px",
  },
});

const StyledWrapperBox = chakraFactory(Box, {
  baseStyle: {
    width: "100%",
    marginBottom: "8px",
  },
});

const StyledContentWrap = chakraFactory(Wrap, {
  baseStyle: {
    flex: "1",
    margin: "auto 0",
    fontSize: "13px",
    lineHeight: "17px",
    color: "#282828",
  },
});

const StyledLabelText = chakraFactory("span", {
  baseStyle: {
    height: "22px",
    fontSize: "13px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#000000",
  },
});

export const PopupPatternDetailImagesContainer: FC<Props> = ({
  popupImages,
  role,
}) => (
  <VStack mt={4}>
    {popupImages.map((popupImage, index) => (
      <StyledWrapperBox key={index}>
        <StyledFlexWrapperBox>
          <HStack
            spacing="8px"
            w={[120, 180, 230]}
            height="40px"
            alignItems="center"
          >
            <StyledLabelText>画像</StyledLabelText>
          </HStack>
          <StyledContentWrap>
            <Image
              src={popupImage.imageUrl || "未設定"}
              boxSize="150px"
              fallbackSrc="https://via.placeholder.com/150"
            />
          </StyledContentWrap>
        </StyledFlexWrapperBox>

        <StyledFlexWrapperBox>
          <HStack
            spacing="8px"
            w={[120, 180, 230]}
            height="40px"
            alignItems="center"
          >
            <StyledLabelText>画像が表示されない場合の表記</StyledLabelText>
          </HStack>
          <StyledContentWrap>
            <Box>{popupImage.alt || "未設定"}</Box>
          </StyledContentWrap>
        </StyledFlexWrapperBox>

        {(role === "ADMIN" ||
          role === "SUPER" ||
          role === "ADMIN_ALL" ||
          role === "CLIENT") && (
          <StyledFlexWrapperBox>
            <HStack
              spacing="8px"
              w={[120, 180, 230]}
              height="40px"
              alignItems="center"
            >
              <StyledLabelText>YDAコード</StyledLabelText>
            </HStack>
            <StyledContentWrap>
              <Box>{popupImage.honeycombCode || "未設定"}</Box>
            </StyledContentWrap>
          </StyledFlexWrapperBox>
        )}

        <StyledFlexWrapperBox>
          <HStack
            spacing="8px"
            w={[120, 180, 230]}
            height="40px"
            alignItems="center"
          >
            <StyledLabelText>画像クリック時のURL</StyledLabelText>
          </HStack>
          <StyledContentWrap>
            <VStack alignItems="baseline">
              {popupImage.isInheritQuery ? (
                <HStack>
                  <Checkbox isDisabled defaultIsChecked></Checkbox>
                  <Box fontSize="13px">
                    クエリパラメータを引き継ぐ(※ページの?以降の文字を引き継ぎます)
                  </Box>
                </HStack>
              ) : (
                <HStack>
                  <Checkbox isDisabled></Checkbox>
                  <Box fontSize="13px">
                    クエリパラメータを引き継ぐ(※ページの?以降の文字を引き継ぎます)
                  </Box>
                </HStack>
              )}
              <Box>{popupImage.linkUrl}</Box>
            </VStack>
          </StyledContentWrap>
        </StyledFlexWrapperBox>
      </StyledWrapperBox>
    ))}
  </VStack>
);
