import { List } from "@chakra-ui/react";
import { FC, useMemo } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";

import { SearchedSiteList_Query } from "~/src/__relay_artifacts__/SearchedSiteList_Query.graphql";

import { SearchedSiteListItem } from "./SearchedSiteListItem";

export type Props = {
  searchText: string;
  handleSelectSite: (selectedSlug: string) => void;
};

const query = graphql`
  query SearchedSiteList_Query($searchText: String) {
    viewer {
      sites(searchTerm: $searchText) {
        edges {
          node {
            id
            name
            slug
          }
        }
      }
    }
  }
`;

export const SearchedSiteList: FC<Props> = ({
  searchText,
  handleSelectSite,
}) => {
  const { viewer } = useLazyLoadQuery<SearchedSiteList_Query>(query, {
    searchText,
  });

  const sites = useMemo(() => {
    return (
      viewer.sites.edges?.map((edge) => {
        const node = edge?.node;
        if (!node) throw new Error("assertion failed");
        return node;
      }) || []
    );
  }, [viewer]);

  return (
    <List marginTop={"4"} spacing={3}>
      {sites.map((site, index) => (
        <SearchedSiteListItem
          key={index}
          slug={site.slug}
          name={site.name}
          handleSelectSite={handleSelectSite}
        />
      ))}
    </List>
  );
};
