/**
 * @generated SignedSource<<28886da9c2d444e7291a172791368b56>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AbtestScenarioPageKind = "DEFAULT" | "SCENARIO" | "REDIRECT" | "TEXT_REPLACE" | "%future added value";
export type DistributionStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type PatternContentJudgeStatus = "PENDING" | "APPROVED" | "REJECTED" | "%future added value";
export type AddAbtestScenarioPageInput = {
  clientMutationId?: string | null;
  abtestScenarioPageGroupId: string;
  pageId: string;
  title: string;
  kind: AbtestScenarioPageKind;
  status?: DistributionStatus | null;
  judgeStatus?: PatternContentJudgeStatus | null;
  weight?: number | null;
  linkUrl?: string | null;
  isInheritQuery?: number | null;
};
export type UpdateOriginalAbtestScenarioPageInput = {
  clientMutationId?: string | null;
  abtestScenarioPageGroupId: string;
  status?: DistributionStatus | null;
  weight?: number | null;
};
export type ABTestPatternCreatePageContainer_Mutation$variables = {
  input: AddAbtestScenarioPageInput;
  originalInput: UpdateOriginalAbtestScenarioPageInput;
  withOriginal: boolean;
};
export type ABTestPatternCreatePageContainer_MutationVariables = ABTestPatternCreatePageContainer_Mutation$variables;
export type ABTestPatternCreatePageContainer_Mutation$data = {
  readonly updateOriginalAbtestScenarioPage?: {
    readonly abtestScenarioPage: {
      readonly id: string;
      readonly weight: number | null;
    };
  } | null;
  readonly addAbtestScenarioPage: {
    readonly abtestScenarioPageEdge: {
      readonly node: {
        readonly id: string;
        readonly title: string | null;
        readonly slug: string;
      } | null;
    } | null;
  } | null;
};
export type ABTestPatternCreatePageContainer_MutationResponse = ABTestPatternCreatePageContainer_Mutation$data;
export type ABTestPatternCreatePageContainer_Mutation = {
  variables: ABTestPatternCreatePageContainer_MutationVariables;
  response: ABTestPatternCreatePageContainer_Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "originalInput"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "withOriginal"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "condition": "withOriginal",
    "kind": "Condition",
    "passingValue": true,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "originalInput"
          }
        ],
        "concreteType": "UpdateOriginalAbtestScenarioPagePayload",
        "kind": "LinkedField",
        "name": "updateOriginalAbtestScenarioPage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AbtestScenarioPage",
            "kind": "LinkedField",
            "name": "abtestScenarioPage",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "weight",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddAbtestScenarioPagePayload",
    "kind": "LinkedField",
    "name": "addAbtestScenarioPage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AbtestScenarioPageEdge",
        "kind": "LinkedField",
        "name": "abtestScenarioPageEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AbtestScenarioPage",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slug",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ABTestPatternCreatePageContainer_Mutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ABTestPatternCreatePageContainer_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "161ab1cbc3c50e0196b84e78634ee17f",
    "id": null,
    "metadata": {},
    "name": "ABTestPatternCreatePageContainer_Mutation",
    "operationKind": "mutation",
    "text": "mutation ABTestPatternCreatePageContainer_Mutation(\n  $input: AddAbtestScenarioPageInput!\n  $originalInput: UpdateOriginalAbtestScenarioPageInput!\n  $withOriginal: Boolean!\n) {\n  updateOriginalAbtestScenarioPage(input: $originalInput) @include(if: $withOriginal) {\n    abtestScenarioPage {\n      id\n      weight\n    }\n  }\n  addAbtestScenarioPage(input: $input) {\n    abtestScenarioPageEdge {\n      node {\n        id\n        title\n        slug\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "02073ee81f2721dbe10c2ae858c0236c";

export default node;
