import { HStack, Text } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/toast";
import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
} from "firebase/auth";
import { useFormikContext } from "formik";
import { FC, useCallback, useEffect, useState } from "react";

import { GhostButton, SolidButton } from "~/src/components/common/Button";
import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormikTextField } from "~/src/components/common/forms/FormikTextField";
import { Modal } from "~/src/components/common/Modal";

import { FormValues } from "./validationSchema";

export type Props = {
  reAuthenticated: boolean;
  isOpen: boolean;
  onClose: () => void;
  handleReAuthenticationStatus: (status: boolean) => void;
};

export const AccountReAuthenticationModal: FC<Props> = ({
  reAuthenticated,
  isOpen,
  onClose,
  handleReAuthenticationStatus,
}) => {
  const toast = useToast();
  const { values, submitForm } = useFormikContext<FormValues>();
  const [reAuthenticating, setReAuthenticating] = useState(false);

  useEffect(() => {
    if (reAuthenticated) {
      submitForm(); // Call handleSubmit when reAuthentication is done
    }
  }, [reAuthenticated, submitForm]);

  const handleSubmit = useCallback(async () => {
    setReAuthenticating(true);
    try {
      const auth = getAuth();
      const authUser = auth.currentUser;
      if (!auth || !authUser || !authUser.email)
        throw new Error(
          "再認証に失敗しました!現在の認証ユーザーが見つかりません。"
        );

      await reauthenticateWithCredential(
        authUser,
        EmailAuthProvider.credential(authUser.email, values?.reAuthPassword)
      );
      handleReAuthenticationStatus(true);
    } catch (error) {
      handleReAuthenticationStatus(false);
      toast({
        title: "再認証に失敗しました! " + error.message,
        status: "error",
      });
    }
    setReAuthenticating(false);
    onClose();
  }, [onClose, handleReAuthenticationStatus, toast, values]);

  return (
    <Modal title="再認証" isOpen={isOpen} onClose={onClose}>
      <Text mb={"2"} fontSize="sm">
        メールを変更するには再認証が必要です。
      </Text>
      <FormControl required label="パスワード">
        <FormikTextField
          type="password"
          mode="fill"
          name="reAuthPassword"
          placeholder="パスワード"
        />
      </FormControl>
      <HStack justifyContent={"flex-end"} mt={"2"}>
        <GhostButton onClick={onClose} mr="2">
          戻る
        </GhostButton>
        <SolidButton
          onClick={handleSubmit}
          disabled={reAuthenticating}
          isLoading={reAuthenticating}
        >
          保存
        </SolidButton>
      </HStack>
    </Modal>
  );
};
