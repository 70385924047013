import { FC } from "react";
import { NavLink } from "react-router-dom";

import {
  DistributionStatus,
  GroupContentJudgeStatus,
  PageKind,
  UserRole,
} from "~/src/__generated__/schema";
import {
  TableDataColumn,
  TableDataRow,
} from "~/src/components/common/tables/Table";
import { URLItem } from "~/src/components/common/URLItem";
import { DistributionStatusSwitch } from "~/src/components/features/global/DistributionStatusSwitch";

import { FunnelABTestGroupTableActions } from "./FunnelABTestGroupTableActions";

type ABTest = {
  id: string;
  title: string;
  slug: string;
  status: DistributionStatus;
  judgeStatus: GroupContentJudgeStatus | null;
  startDate: string | null;
  endDate: string | null;
  updatedAt: string | null;
  patternCount: number;
  isArchive: boolean;
};

type Page = {
  id: string;
  kind: PageKind;
  name: string;
  url: string;
  sortNumber: number;
};

type Props = {
  siteSlug: string;
  funnelId: string;
  abtest: ABTest;
  role: UserRole;
  page: Page;
  connectionId: string;
};

export const FunnelABTestGroupTableDataRow: FC<Props> = ({
  abtest,
  siteSlug,
  funnelId,
  role,
  page,
  connectionId,
}) => {
  return (
    <TableDataRow height={"80px"}>
      <TableDataColumn
        type="text"
        isLink
        data={
          <NavLink to={`/sites/${siteSlug}/abtests/${abtest.slug}`}>
            {abtest.title}
          </NavLink>
        }
      ></TableDataColumn>
      <TableDataColumn
        type="actions"
        data={
          <DistributionStatusSwitch
            id={abtest.id}
            name={abtest.title}
            isChecked={abtest.status === "ACTIVE"}
          />
        }
      ></TableDataColumn>
      {role !== "SAAS_CLIENT" && (
        <TableDataColumn
          type="text"
          data={abtest.judgeStatus === "APPROVED" ? "承認" : "未承認"}
        />
      )}
      <TableDataColumn
        type="text"
        data={
          <URLItem
            url={page.url}
            name={
              page.kind !== "FORM"
                ? page.name
                : page.name + "(" + (page.sortNumber - 1) + ")"
            }
          />
        }
      />
      <TableDataColumn type="text" data={abtest.patternCount} />
      <TableDataColumn type="date" data={abtest.startDate} />
      <TableDataColumn type="date" data={abtest.endDate} />
      <TableDataColumn type="date" data={abtest.updatedAt} />
      <TableDataColumn
        type="actions"
        data={
          <FunnelABTestGroupTableActions
            abtestScenarioPageGroup={{
              id: abtest.id,
              title: abtest.title,
              slug: abtest.slug,
            }}
            siteSlug={siteSlug}
            connectionId={connectionId}
            filtered={abtest.isArchive}
            currentValues={{
              funnelId,
              pageId: page.id,
              pageKind: page.kind,
            }}
          />
        }
      />
    </TableDataRow>
  );
};
