import {
  ArchiveOutlined as ArchiveOutlinedIcon,
  DeleteOutline as DeleteOutlineIcon,
  EditOutlined as EditOutlinedIcon,
  ListOutlined as ListOutlinedIcon,
  UnarchiveOutlined as UnarchiveOutlinedIcon,
} from "@mui/icons-material";
import { FC, useCallback, useMemo } from "react";
import { graphql, useFragment } from "react-relay";
import { NavLink } from "react-router-dom";

import { UserRole } from "~/src/__generated__/schema";
import { FunnelTableDataRow_funnel$key } from "~/src/__relay_artifacts__/FunnelTableDataRow_funnel.graphql";
import {
  TableDataCheckboxColumn,
  TableDataColumn,
  TableDataRow,
} from "~/src/components/common/tables/Table";
import { TableActions } from "~/src/components/common/tables/TableActions";
import { URLItem } from "~/src/components/common/URLItem";
import {
  useArchiveHandler,
  useUnarchiveHandler,
} from "~/src/components/features/archive";

export type Props = {
  funnelRef: FunnelTableDataRow_funnel$key;
  siteSlug: string;
  connectionId: string;
  onDeleteClick: (funnelId: string, connectionId: string) => void;
  userRole: UserRole;
  isChecked: boolean;
  onCheck: (funnelSlug: string, isChecked: boolean) => void;
};

const funnelFragment = graphql`
  fragment FunnelTableDataRow_funnel on Funnel {
    id
    slug
    name
    isArchive
    landingPage {
      id
      url
      tabletUrl
      mobileUrl
      urlMatchType
      hasForm
    }
    formPage {
      id
      url
      tabletUrl
      mobileUrl
      urlMatchType
      hasForm
    }
    confirmPage {
      id
      url
      tabletUrl
      mobileUrl
      urlMatchType
      hasForm
    }
    thanksPage {
      id
      url
      tabletUrl
      mobileUrl
      urlMatchType
      hasForm
    }
  }
`;

export const FunnelTableDataRow: FC<Props> = ({
  funnelRef,
  siteSlug,
  connectionId,
  onDeleteClick,
  userRole,
  isChecked,
  onCheck,
}) => {
  const funnel = useFragment(funnelFragment, funnelRef);
  const handleArchive = useArchiveHandler(funnel.id, connectionId);
  const handleUnarchive = useUnarchiveHandler(funnel.id, connectionId);

  const actionMenus = useMemo(() => {
    const menus = funnel.isArchive
      ? [
          {
            label: "削除",
            icon: <DeleteOutlineIcon />,
            onClick: () => onDeleteClick(funnel.id, connectionId),
          },
        ]
      : [
          {
            label: "入稿用リンク一覧",
            icon: <ListOutlinedIcon />,
            to: `/sites/${siteSlug}/funnels/${funnel.slug}/sources`,
          },
          {
            label: "編集",
            icon: <EditOutlinedIcon />,
            to: `/sites/${siteSlug}/funnels/${funnel.slug}/edit`,
          },
          {
            label: "削除",
            icon: <DeleteOutlineIcon />,
            onClick: () => onDeleteClick(funnel.id, connectionId),
          },
        ];

    if (userRole !== "ADMIN" && userRole !== "SUPER") return menus;

    if (funnel.isArchive) {
      menus.push({
        label: "アーカイブから戻す",
        icon: <UnarchiveOutlinedIcon />,
        onClick: () => handleUnarchive(),
      });
      return menus;
    }

    menus.push({
      label: "アーカイブ",
      icon: <ArchiveOutlinedIcon />,
      onClick: () => handleArchive(),
    });

    return menus;
  }, [
    funnel.isArchive,
    funnel.slug,
    funnel.id,
    siteSlug,
    userRole,
    onDeleteClick,
    connectionId,
    handleUnarchive,
    handleArchive,
  ]);

  const handleCheck = useCallback(() => {
    onCheck(funnel.slug, isChecked);
  }, [funnel.slug, isChecked, onCheck]);

  return (
    <TableDataRow>
      {(userRole === "ADMIN" || userRole === "SUPER") && (
        <TableDataCheckboxColumn isChecked={isChecked} onChange={handleCheck} />
      )}
      <TableDataColumn
        type="text"
        isLink
        data={
          <NavLink to={`/sites/${siteSlug}/funnels/${funnel.slug}`}>
            {funnel.name}
          </NavLink>
        }
      />
      <TableDataColumn
        type="text"
        data={
          <URLItem
            url={
              funnel.landingPage && funnel.landingPage.url
                ? funnel.landingPage.url.length > 75
                  ? funnel.landingPage.url.substring(0, 75) + "..."
                  : funnel.landingPage.url
                : "未設定"
            }
          />
        }
      />
      <TableDataColumn
        type="text"
        data={
          <URLItem
            url={
              funnel.formPage &&
              funnel.formPage.length > 0 &&
              funnel.formPage[0].url
                ? funnel.formPage[0].url.length > 75
                  ? funnel.formPage[0].url.substring(0, 75) + "..."
                  : funnel.formPage[0].url
                : "未設定"
            }
          />
        }
      />
      <TableDataColumn
        type="text"
        data={
          <URLItem
            url={
              funnel.confirmPage && funnel.confirmPage.url
                ? funnel.confirmPage.url.length > 75
                  ? funnel.confirmPage.url.substring(0, 75) + "..."
                  : funnel.confirmPage.url
                : "未設定"
            }
          />
        }
      />
      <TableDataColumn
        type="text"
        data={
          <URLItem
            url={
              funnel.thanksPage && funnel.thanksPage.url
                ? funnel.thanksPage.url.length > 75
                  ? funnel.thanksPage.url.substring(0, 75) + "..."
                  : funnel.thanksPage.url
                : "未設定"
            }
          />
        }
      />
      <TableDataColumn
        type="actions"
        data={<TableActions actionMenus={actionMenus} />}
      />
    </TableDataRow>
  );
};
