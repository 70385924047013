import { HStack } from "@chakra-ui/layout";
import { Radio, RadioGroup } from "@chakra-ui/react";
import { FC } from "react";

import { chakraFactory } from "~/src/lib/chakra-ui";

type Option = {
  readonly label: string;
  readonly value: string;
};

export type Props = {
  readonly name: string;
  readonly value: string;
  readonly isDisabled?: boolean;
  readonly options: readonly Option[];
  readonly onChange: (value: string) => void;
};

const StyledRadio = chakraFactory(Radio, {
  baseStyle: {
    cursor: "pointer",

    span: {
      fontSize: "13px",
    },
    position: "relative",

    "span:first-of-type": {
      background: "#FFFFFF",
      border: "2px solid #C4C4C4",
    },

    _checked: {
      "span:first-of-type": {
        background: "#2F68BA",
        border: "2px solid #2F68BA",
      },
    },

    _disabled: {
      cursor: "not-allowed",
    },
  },
});

export const RadioButtons: FC<Props> = ({
  name,
  value,
  isDisabled = false,
  options,
  onChange,
}) => (
  <RadioGroup
    name={name}
    value={value}
    onChange={onChange}
    isDisabled={isDisabled}
  >
    <HStack spacing="28px">
      {options.map((option) => (
        <StyledRadio key={option.value} value={option.value}>
          {option.label}
        </StyledRadio>
      ))}
    </HStack>
  </RadioGroup>
);
