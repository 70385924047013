import { useCallback, useEffect, useState } from "react";
import { useMount } from "react-use";
import { T } from "ts-toolbelt";

import { useQueryString } from "./useQueryString";

type TabType = {
  id: string;
  label: string;
};

type Options = {
  withUrl?: boolean;
};

export const useTabs = <TabsType extends readonly TabType[]>(
  tabs: TabsType,
  { withUrl = true }: Options = {}
) => {
  const {
    params: { tab },
    changeQueryString,
  } = useQueryString<{ tab?: string }>();
  const [tabState, setTabState] = useState(
    () => tabs.find((t) => t.id === tab)?.id
  );

  useMount(() => {
    if (tabState) return;
    const newTab = tabs[0].id;
    setTabState(newTab);
    withUrl && changeQueryString({ tab: newTab }, true);
  });

  useEffect(() => {
    setTabState(tabs.find((t) => t.id === tab)?.id);
  }, [tab, tabs]);

  const onTabChange = useCallback(
    (tab: TabType["id"]) => {
      setTabState(tab);
      withUrl && changeQueryString({ tab });
    },
    [changeQueryString, withUrl]
  );

  return {
    currentTabId: tabState as T.UnionOf<TabsType>["id"],
    onTabChange,
  };
};
