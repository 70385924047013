import * as yup from "yup";

export type FormValues = yup.Asserts<typeof validationSchema>;

// MEMO: https://github.com/macbee-planet/robee-web/issues/732
// yupが提供してくれているURLバリデーションだとIssueに記載しているようなURLを弾いてしまう 参考 : https://github.com/jquense/yup/blob/master/src/string.ts#L24
// Zodを参考にしたCustomなURLバリデーションを利用 参考 : https://github.com/colinhacks/zod/blob/master/src/types.ts#L688
const urlValidation = yup
  .string()
  .test("isURL", "正しいURLを入力してください", (value, _) => {
    try {
      if (!value) return true;
      new URL(value);
      return true;
    } catch (err) {
      return false;
    }
  });

const formUrlsSchema = yup.object({
  formUrlForPC: urlValidation,
  formUrlForMobile: urlValidation.nullable(),
  formUrlForTablet: urlValidation.nullable(),
  formUrlMatchType: yup.string().required(),
  formIsDisableWhiteout: yup.boolean().required(),
});

export const validationSchema = yup.object({
  title: yup.string().required(),
  landingUrlForPC: urlValidation.required(),
  landingUrlForMobile: urlValidation.nullable(),
  landingUrlForTablet: urlValidation.nullable(),
  landingUrlMatchType: yup.string().required(),
  landingQueryMatchType: yup.string().nullable(),
  landingUrlFormIncluded: yup.boolean().required(),
  landingIsDisableWhiteout: yup.boolean().required(),
  formUrls: yup.array().of(formUrlsSchema).required(),
  confirmUrlForPC: urlValidation,
  confirmUrlForMobile: urlValidation.nullable(),
  confirmUrlForTablet: urlValidation.nullable(),
  confirmUrlMatchType: yup.string().required(),
  confirmIsDisableWhiteout: yup.boolean().required(),
  thanksUrlForPC: urlValidation.required(),
  thanksUrlForMobile: urlValidation.nullable(),
  thanksUrlForTablet: urlValidation.nullable(),
  thanksUrlMatchType: yup.string().required(),
  thanksIsDisableWhiteout: yup.boolean().required(),
  isExperiment: yup.boolean().required(),
});
