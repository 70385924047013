/**
 * @generated SignedSource<<9f5b98a6d7c3877dfb5900c500132ea1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AbtestScenarioPageContentTextReplaceActionKind = "TEXT_REPLACEMENT" | "DIV_ELEMENT_ADDITION" | "ATTRIBUTE_REPLACEMENT" | "%future added value";
export type AbtestScenarioPageContentTextReplaceTargetKind = "ID" | "SELECTOR" | "XPATH" | "%future added value";
export type AbtestScenarioPageKind = "DEFAULT" | "SCENARIO" | "REDIRECT" | "TEXT_REPLACE" | "%future added value";
export type PageKind = "LANDING" | "FORM" | "CONFIRM" | "THANKS" | "%future added value";
export type ABTestPatternDetailPageContainer_Query$variables = {
  abtestSlug: string;
  abtestGroupSlug: string;
};
export type ABTestPatternDetailPageContainer_QueryVariables = ABTestPatternDetailPageContainer_Query$variables;
export type ABTestPatternDetailPageContainer_Query$data = {
  readonly abtestScenarioPageGroup: {
    readonly page: {
      readonly name: string;
      readonly kind: PageKind;
      readonly sortNumber: number;
      readonly url: string;
      readonly funnel: {
        readonly name: string;
        readonly siteUrl: string;
      };
    };
    readonly title: string;
  } | null;
  readonly abtestScenarioPage: {
    readonly id: string;
    readonly title: string | null;
    readonly slug: string;
    readonly kind: AbtestScenarioPageKind;
    readonly linkUrl: string | null;
    readonly isInheritQuery: boolean;
    readonly page: {
      readonly url: string;
    };
    readonly abtestScenarioPageContents: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id?: string;
          readonly beforeSrc?: string | null;
          readonly imageUrl?: string | null;
          readonly targetType?: AbtestScenarioPageContentTextReplaceTargetKind;
          readonly targetValue?: string;
          readonly actionType?: AbtestScenarioPageContentTextReplaceActionKind;
          readonly targetAttribute?: string;
          readonly replaceValue?: string | null;
        } | null;
      } | null> | null;
    };
  } | null;
};
export type ABTestPatternDetailPageContainer_QueryResponse = ABTestPatternDetailPageContainer_Query$data;
export type ABTestPatternDetailPageContainer_Query = {
  variables: ABTestPatternDetailPageContainer_QueryVariables;
  response: ABTestPatternDetailPageContainer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "abtestGroupSlug"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "abtestSlug"
},
v2 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "abtestGroupSlug"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sortNumber",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "siteUrl",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v9 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "abtestSlug"
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "linkUrl",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isInheritQuery",
  "storageKey": null
},
v14 = {
  "kind": "InlineFragment",
  "selections": [
    (v10/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "beforeSrc",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageUrl",
      "storageKey": null
    }
  ],
  "type": "AbtestScenarioPageContentScenario",
  "abstractKey": null
},
v15 = {
  "kind": "InlineFragment",
  "selections": [
    (v10/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "targetType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "targetValue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actionType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "targetAttribute",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "replaceValue",
      "storageKey": null
    }
  ],
  "type": "AbtestScenarioPageContentTextReplace",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ABTestPatternDetailPageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "AbtestScenarioPageGroup",
        "kind": "LinkedField",
        "name": "abtestScenarioPageGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Page",
            "kind": "LinkedField",
            "name": "page",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Funnel",
                "kind": "LinkedField",
                "name": "funnel",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "AbtestScenarioPage",
        "kind": "LinkedField",
        "name": "abtestScenarioPage",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          (v8/*: any*/),
          (v11/*: any*/),
          (v4/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Page",
            "kind": "LinkedField",
            "name": "page",
            "plural": false,
            "selections": [
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AbtestScenarioPageContentConnection",
            "kind": "LinkedField",
            "name": "abtestScenarioPageContents",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AbtestScenarioPageContentEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/),
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ABTestPatternDetailPageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "AbtestScenarioPageGroup",
        "kind": "LinkedField",
        "name": "abtestScenarioPageGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Page",
            "kind": "LinkedField",
            "name": "page",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Funnel",
                "kind": "LinkedField",
                "name": "funnel",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v7/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          (v8/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "AbtestScenarioPage",
        "kind": "LinkedField",
        "name": "abtestScenarioPage",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          (v8/*: any*/),
          (v11/*: any*/),
          (v4/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Page",
            "kind": "LinkedField",
            "name": "page",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AbtestScenarioPageContentConnection",
            "kind": "LinkedField",
            "name": "abtestScenarioPageContents",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AbtestScenarioPageContentEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      (v14/*: any*/),
                      (v15/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v10/*: any*/)
                        ],
                        "type": "Node",
                        "abstractKey": "__isNode"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "40e26a5847bb24d6ca9e3efa2a03129f",
    "id": null,
    "metadata": {},
    "name": "ABTestPatternDetailPageContainer_Query",
    "operationKind": "query",
    "text": "query ABTestPatternDetailPageContainer_Query(\n  $abtestSlug: String!\n  $abtestGroupSlug: String!\n) {\n  abtestScenarioPageGroup(slug: $abtestGroupSlug) {\n    page {\n      name\n      kind\n      sortNumber\n      url\n      funnel {\n        name\n        siteUrl\n        id\n      }\n      id\n    }\n    title\n    id\n  }\n  abtestScenarioPage(slug: $abtestSlug) {\n    id\n    title\n    slug\n    kind\n    linkUrl\n    isInheritQuery\n    page {\n      url\n      id\n    }\n    abtestScenarioPageContents {\n      edges {\n        node {\n          __typename\n          ... on AbtestScenarioPageContentScenario {\n            id\n            beforeSrc\n            imageUrl\n          }\n          ... on AbtestScenarioPageContentTextReplace {\n            id\n            targetType\n            targetValue\n            actionType\n            targetAttribute\n            replaceValue\n          }\n          ... on Node {\n            __isNode: __typename\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4bf59ea0b867fc9e5fc20b3e3dca7bec";

export default node;
