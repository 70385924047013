/**
 * @generated SignedSource<<b4dfa857bb75335f18b4039a43e539dd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DayOfWeek = "SUN" | "MON" | "TUE" | "WED" | "THU" | "FRI" | "SUT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ChatbotDeliverDayOfWeeks_chatbotGroup$data = {
  readonly startDate: string | null;
  readonly startTime: string | null;
  readonly endDate: string | null;
  readonly endTime: string | null;
  readonly deliverDayOfWeeks: ReadonlyArray<{
    readonly weekOfMonth: number | null;
    readonly daysOfWeek: ReadonlyArray<DayOfWeek>;
  }>;
  readonly " $fragmentType": "ChatbotDeliverDayOfWeeks_chatbotGroup";
};
export type ChatbotDeliverDayOfWeeks_chatbotGroup = ChatbotDeliverDayOfWeeks_chatbotGroup$data;
export type ChatbotDeliverDayOfWeeks_chatbotGroup$key = {
  readonly " $data"?: ChatbotDeliverDayOfWeeks_chatbotGroup$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChatbotDeliverDayOfWeeks_chatbotGroup">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChatbotDeliverDayOfWeeks_chatbotGroup",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DeliverDayOfWeek",
      "kind": "LinkedField",
      "name": "deliverDayOfWeeks",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "weekOfMonth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "daysOfWeek",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ChatbotGroup",
  "abstractKey": null
};

(node as any).hash = "110e381eabe54f4a5548a329fe181c01";

export default node;
