/**
 * @generated SignedSource<<cd34e425fbe8c60dd23bc16b670f49ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FunnelOrderField = "CREATED_AT" | "UPDATED_AT" | "NAME" | "%future added value";
export type OrderDirection = "ASC" | "DESC" | "%future added value";
export type FunnelOrder = {
  direction: OrderDirection;
  field: FunnelOrderField;
};
export type FunnelListTab_Query$variables = {
  count?: number | null;
  cursor?: string | null;
  onlyExperiment?: boolean | null;
  orderBy?: FunnelOrder | null;
  searchText?: string | null;
  withArchived?: boolean | null;
  id: string;
};
export type FunnelListTab_QueryVariables = FunnelListTab_Query$variables;
export type FunnelListTab_Query$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"FunnelListTab_site">;
  } | null;
};
export type FunnelListTab_QueryResponse = FunnelListTab_Query$data;
export type FunnelListTab_Query = {
  variables: FunnelListTab_QueryVariables;
  response: FunnelListTab_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": 120,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "onlyExperiment"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v5 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "searchText"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "withArchived"
},
v7 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v8 = {
  "kind": "Variable",
  "name": "onlyExperiment",
  "variableName": "onlyExperiment"
},
v9 = {
  "kind": "Variable",
  "name": "orderBy",
  "variableName": "orderBy"
},
v10 = {
  "kind": "Variable",
  "name": "searchText",
  "variableName": "searchText"
},
v11 = {
  "kind": "Variable",
  "name": "withArchived",
  "variableName": "withArchived"
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v16 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v8/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/),
  (v11/*: any*/)
],
v17 = [
  (v13/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "tabletUrl",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "mobileUrl",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "urlMatchType",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "hasForm",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FunnelListTab_Query",
    "selections": [
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "FunnelListTab_site"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "FunnelListTab_Query",
    "selections": [
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v12/*: any*/),
          (v13/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v14/*: any*/),
              (v15/*: any*/),
              {
                "alias": null,
                "args": (v16/*: any*/),
                "concreteType": "FunnelConnection",
                "kind": "LinkedField",
                "name": "funnels",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FunnelEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Funnel",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v15/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isArchive",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Page",
                            "kind": "LinkedField",
                            "name": "landingPage",
                            "plural": false,
                            "selections": (v17/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Page",
                            "kind": "LinkedField",
                            "name": "formPage",
                            "plural": true,
                            "selections": (v17/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Page",
                            "kind": "LinkedField",
                            "name": "confirmPage",
                            "plural": false,
                            "selections": (v17/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Page",
                            "kind": "LinkedField",
                            "name": "thanksPage",
                            "plural": false,
                            "selections": (v17/*: any*/),
                            "storageKey": null
                          },
                          (v12/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v16/*: any*/),
                "filters": [
                  "searchText",
                  "withArchived",
                  "onlyExperiment",
                  "orderBy"
                ],
                "handle": "connection",
                "key": "FunnelListTab_funnels",
                "kind": "LinkedHandle",
                "name": "funnels"
              }
            ],
            "type": "Site",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c37bfc26dd074730baf2d163a30c90e3",
    "id": null,
    "metadata": {},
    "name": "FunnelListTab_Query",
    "operationKind": "query",
    "text": "query FunnelListTab_Query(\n  $count: Int = 120\n  $cursor: String\n  $onlyExperiment: Boolean\n  $orderBy: FunnelOrder\n  $searchText: String = \"\"\n  $withArchived: Boolean\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...FunnelListTab_site_1nA64m\n    id\n  }\n}\n\nfragment FunnelListTab_site_1nA64m on Site {\n  id\n  name\n  slug\n  funnels(first: $count, after: $cursor, searchText: $searchText, withArchived: $withArchived, onlyExperiment: $onlyExperiment, orderBy: $orderBy) {\n    totalCount\n    edges {\n      node {\n        slug\n        id\n        name\n        ...FunnelTableDataRow_funnel\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment FunnelTableDataRow_funnel on Funnel {\n  id\n  slug\n  name\n  isArchive\n  landingPage {\n    id\n    url\n    tabletUrl\n    mobileUrl\n    urlMatchType\n    hasForm\n  }\n  formPage {\n    id\n    url\n    tabletUrl\n    mobileUrl\n    urlMatchType\n    hasForm\n  }\n  confirmPage {\n    id\n    url\n    tabletUrl\n    mobileUrl\n    urlMatchType\n    hasForm\n  }\n  thanksPage {\n    id\n    url\n    tabletUrl\n    mobileUrl\n    urlMatchType\n    hasForm\n  }\n}\n"
  }
};
})();

(node as any).hash = "1f2eebd35a4c6b392272a9b2cd68cadd";

export default node;
