import { formatNumber } from "accounting";
import { groupBy, prop } from "ramda";
import { FC, useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { PopupReportOrderValue } from "~/src/__generated__/schema";
import { PopupPageReport_site$key } from "~/src/__relay_artifacts__/PopupPageReport_site.graphql";

import { ColumnData } from "../../ReportTable";
import { HeaderOption } from "../../utils";

import { PopupReportGraph } from "./PopupReportGraph";
import { PopupReportTable } from "./PopupReportTable";

type Props = {
  onSortClick: (params: any) => void;
  siteRef: PopupPageReport_site$key;
};

const fragment = graphql`
  fragment PopupPageReport_site on Site {
    slug
    url
    reportPopup(kind: $kind, filter: $filter, order: $order) {
      rows {
        popupName
        popupGroupName
        popupImageUrl
        funnelName
        pageName
        pageUrl
        readyUser
        pageViewUser
        closeClick
        clickUser
        conversionUser
        impConversionUser
        readyConversionUser
        dropUser
        dropConversionUser
        uniqueUser
        totalConversionUser
        clickUserRate
        conversionClickRate
        conversionImpRate
        conversionDropRate
        totalConversionRate
        mobileRate
        pcRate
        tabletRate
        popupSlug
        popupGroupSlug
      }
      summary {
        popupName
        popupGroupName
        popupImageUrl
        funnelName
        readyUser
        pageViewUser
        closeClick
        clickUser
        conversionUser
        impConversionUser
        readyConversionUser
        dropUser
        dropConversionUser
        uniqueUser
        totalConversionUser
        clickUserRate
        conversionClickRate
        conversionImpRate
        conversionDropRate
        totalConversionRate
        mobileRate
        pcRate
        tabletRate
      }
      graph {
        popupId
        popupName
        popupGroupName
        popupImageUrl
        funnelName
        pageName
        readyUser
        pageViewUser
        closeClick
        clickUser
        conversionUser
        impConversionUser
        readyConversionUser
        dropUser
        dropConversionUser
        uniqueUser
        totalConversionUser
        clickUserRate
        conversionClickRate
        conversionImpRate
        conversionDropRate
        totalConversionRate
        mobileRate
        pcRate
        tabletRate
        accessDate
      }
      summaryGraph {
        readyUser
        pageViewUser
        closeClick
        clickUser
        conversionUser
        impConversionUser
        readyConversionUser
        dropUser
        dropConversionUser
        uniqueUser
        totalConversionUser
        clickUserRate
        conversionClickRate
        conversionImpRate
        conversionDropRate
        totalConversionRate
        mobileRate
        pcRate
        tabletRate
        accessDate
      }
    }
  }
`;

const headerRow: HeaderOption<PopupReportOrderValue>[] = [
  { label: "ポップアップパターン", orderField: "POPUP" },
  { label: "ポップアップ", orderField: "POPUP_GROUP" },
  { label: "ファネル", orderField: "FUNNEL" },
  { label: "ページ", orderField: "PAGE" },
  { label: "Ready人数", orderField: "READY_USER" },
  { label: "流入数", orderField: "UNIQUE_USER" },
  { label: "Imp人数", orderField: "PAGE_VIEW_USER" },
  { label: "閉じる", orderField: "CLOSE_CLICK" },
  { label: "クリック人数", orderField: "CLICK_USER" },
  { label: "CV(計)", orderField: "TOTAL_CONVERSION_USER" },
  { label: "CV(click)", orderField: "CONVERSION_USER" },
  { label: "CV(imp)", orderField: "IMP_CONVERSION_USER" },
  { label: "CV(ready)", orderField: "READY_CONVERSION_USER" },
  { label: "CTR", orderField: "CLICK_USER_RATE" },
  { label: "CVR(計)", orderField: "TOTAL_CONVERSION_RATE" },
  { label: "CVR(click)", orderField: "CONVERSION_CLICK_RATE" },
  { label: "CVR(imp)", orderField: "CONVERSION_IMP_RATE" },
  { label: "SP", orderField: "MOBILE_RATE" },
  { label: "TB", orderField: "TABLET_RATE" },
  { label: "PC", orderField: "PC_RATE" },
  { label: "離脱アクションしたユーザー数", orderField: "DROP_USER" },
  { label: "離脱アクションしたCV人数", orderField: "DROP_CONVERSION_USER" },
  { label: "離脱アクションしたCVR", orderField: "CONVERSION_DROP_RATE" },
];

const disableColumnOptions = [
  { label: "ポップアップ", value: 1 },
  { label: "ファネル", value: 2 },
  { label: "ページ", value: 3 },
  { label: "Ready人数", value: 4 },
  { label: "流入数", value: 5 },
  { label: "Imp人数", value: 6 },
  { label: "閉じる", value: 7 },
  { label: "クリック人数", value: 8 },
  { label: "CV(計)", value: 9 },
  { label: "CV(click)", value: 10 },
  { label: "CV(imp)", value: 11 },
  { label: "CV(ready)", value: 12 },
  { label: "CTR", value: 13 },
  { label: "CVR(計)", value: 14 },
  { label: "CVR(click)", value: 15 },
  { label: "CVR(imp)", value: 16 },
  { label: "SP", value: 17 },
  { label: "TB", value: 18 },
  { label: "PC", value: 19 },
  { label: "離脱アクションしたユーザー数", value: 20 },
  { label: "離脱アクションしたCV人数", value: 21 },
  { label: "離脱アクションしたCVR", value: 22 },
];

const csvHeaderRow = [
  "ポップアップパターン",
  "ポップアップ",
  "ファネル",
  "ページ",
  "Ready人数",
  "流入数",
  "Imp人数",
  "閉じる",
  "クリック人数",
  "CV(計)",
  "CV(click)",
  "CV(imp)",
  "CV(ready)",
  "CTR",
  "CVR(計)",
  "CVR(click)",
  "CVR(imp)",
  "SP",
  "TB",
  "PC",
  "離脱アクションしたユーザー数",
  "離脱アクションしたCV人数",
  "離脱アクションしたCVR",
];

export const PopupPageReport: FC<Props> = ({ siteRef, onSortClick }) => {
  const site = useFragment(fragment, siteRef);

  const isExistReportData = useMemo(() => {
    return site.reportPopup.graph.length > 0;
  }, [site.reportPopup.graph]);

  const rows = useMemo(() => {
    return site.reportPopup.rows.map((row) => {
      return [
        {
          type: "GROUP",
          value: "",
          columns: [
            {
              type: "NAV_LINK",
              value: row.popupName || "",
              url: `/sites/${site.slug}/popup_groups/${row.popupGroupSlug}/patterns/${row.popupSlug}`,
            },
            { type: "IMAGE", value: row.popupImageUrl || "" },
          ],
        },
        { type: "TEXT", value: row.popupGroupName || "" },
        { type: "URL_LINK", value: row.funnelName || "", url: site.url },
        { type: "URL_LINK", value: row.pageName || "", url: row.pageUrl },
        { type: "TEXT", value: formatNumber(row.readyUser) },
        { type: "TEXT", value: formatNumber(row.uniqueUser) },
        { type: "TEXT", value: formatNumber(row.pageViewUser) },
        { type: "TEXT", value: formatNumber(row.closeClick) },
        { type: "TEXT", value: formatNumber(row.clickUser) },
        { type: "TEXT", value: formatNumber(row.totalConversionUser) },
        { type: "TEXT", value: formatNumber(row.conversionUser) },
        { type: "TEXT", value: formatNumber(row.impConversionUser) },
        { type: "TEXT", value: formatNumber(row.readyConversionUser) },
        { type: "TEXT", value: row.clickUserRate.toString() + "%" },
        { type: "TEXT", value: row.totalConversionRate.toString() + "%" },
        { type: "TEXT", value: row.conversionClickRate.toString() + "%" },
        { type: "TEXT", value: row.conversionImpRate.toString() + "%" },
        { type: "TEXT", value: row.mobileRate.toString() + "%" },
        { type: "TEXT", value: row.tabletRate.toString() + "%" },
        { type: "TEXT", value: row.pcRate.toString() + "%" },
        { type: "TEXT", value: formatNumber(row.dropUser) },
        { type: "TEXT", value: formatNumber(row.dropConversionUser) },
        { type: "TEXT", value: row.conversionDropRate.toString() + "%" },
      ] as ColumnData[];
    });
  }, [site.reportPopup.rows, site.slug, site.url]);

  const footerRow = useMemo(() => {
    if (site.reportPopup.summary.length === 0) return [];
    const row = site.reportPopup.summary[0];
    return [
      { type: "TEXT", value: "" },
      { type: "TEXT", value: "" },
      { type: "TEXT", value: "" },
      { type: "TEXT", value: "合計" },
      { type: "TEXT", value: formatNumber(row.readyUser) },
      { type: "TEXT", value: formatNumber(row.uniqueUser) },
      { type: "TEXT", value: formatNumber(row.pageViewUser) },
      { type: "TEXT", value: formatNumber(row.closeClick) },
      { type: "TEXT", value: formatNumber(row.clickUser) },
      { type: "TEXT", value: formatNumber(row.totalConversionUser) },
      { type: "TEXT", value: formatNumber(row.conversionUser) },
      { type: "TEXT", value: formatNumber(row.impConversionUser) },
      { type: "TEXT", value: formatNumber(row.readyConversionUser) },
      { type: "TEXT", value: row.clickUserRate.toString() + "%" },
      { type: "TEXT", value: row.totalConversionRate.toString() + "%" },
      { type: "TEXT", value: row.conversionClickRate.toString() + "%" },
      { type: "TEXT", value: row.conversionImpRate.toString() + "%" },
      { type: "TEXT", value: row.mobileRate.toString() + "%" },
      { type: "TEXT", value: row.tabletRate.toString() + "%" },
      { type: "TEXT", value: row.pcRate.toString() + "%" },
      { type: "TEXT", value: formatNumber(row.dropUser) },
      { type: "TEXT", value: formatNumber(row.dropConversionUser) },
      { type: "TEXT", value: row.conversionDropRate.toString() + "%" },
    ] as ColumnData[];
  }, [site.reportPopup.summary]);

  const csvRows = useMemo(() => {
    const csvRows = rows.map((row) =>
      row.map((column) => {
        if (
          column.type === "GROUP" &&
          column.columns &&
          column.columns.length > 0
        ) {
          return column.columns[0].value;
        }
        return column.value;
      })
    );
    const csvFooterRow = footerRow.map((column) => column.value);
    csvRows.unshift(csvHeaderRow);
    csvRows.push(csvFooterRow);
    return csvRows
      .map((row) => row.map((cell) => cell.replaceAll(",", "")).join(","))
      .join("\n");
  }, [rows, footerRow]);

  const graphData = useMemo(() => {
    const data = groupBy<any, any>(prop("popupId"), site.reportPopup.graph);
    if (site.reportPopup.summaryGraph.length === 0) return data;
    data["summary"] = site.reportPopup.summaryGraph.map((row) => {
      return {
        popupName: "合計",
        accessDate: row.accessDate,
        readyUser: row.readyUser,
        uniqueUser: row.uniqueUser,
        pageViewUser: row.pageViewUser,
        closeClick: row.closeClick,
        clickUser: row.clickUser,
        totalConversionUser: row.totalConversionUser,
        conversionUser: row.conversionUser,
        impConversionUser: row.impConversionUser,
        readyConversionUser: row.readyConversionUser,
        clickUserRate: row.clickUserRate,
        totalConversionRate: row.totalConversionRate,
        conversionClickRate: row.conversionClickRate,
        conversionImpRate: row.conversionImpRate,
        mobileRate: row.mobileRate,
        tabletRate: row.tabletRate,
        pcRate: row.pcRate,
        dropUser: row.dropUser,
        dropConversionUser: row.dropConversionUser,
        conversionDropRate: row.conversionDropRate,
      };
    });
    return data;
  }, [site.reportPopup.graph, site.reportPopup.summaryGraph]);

  const graphOptions = useMemo(() => {
    const keys = Object.keys(graphData);
    return keys.map((key) => {
      const popupName = graphData[key][0].popupName;
      const pageName = graphData[key][0].pageName;
      const funnelName = graphData[key][0].funnelName;
      return {
        value: key.toString(),
        label:
          funnelName && pageName
            ? `${popupName}(${funnelName}, ${pageName})`
            : popupName,
      };
    });
  }, [graphData]);

  return (
    <>
      {isExistReportData && (
        <PopupReportGraph graphData={graphData} graphOptions={graphOptions} />
      )}
      <PopupReportTable
        rows={rows}
        footerRow={footerRow}
        headerRow={headerRow}
        onSortClick={onSortClick}
        disableColumnOptions={disableColumnOptions}
        csvRows={csvRows}
        cacheKey={"PopupPageReport"}
      />
    </>
  );
};
