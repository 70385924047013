import { Suspense } from "react";
import { Outlet } from "react-router-dom";

import { PageLoading } from "~/src/components/common/PageLoading";
import { HeaderOnlyLayoutContainer } from "~/src/components/features/global/HeaderOnlyLayout";

export const HeaderOnlyLayoutOutlet = () => (
  <Suspense fallback={<PageLoading />}>
    <HeaderOnlyLayoutContainer>
      <Suspense fallback={<PageLoading />}>
        <Outlet />
      </Suspense>
    </HeaderOnlyLayoutContainer>
  </Suspense>
);
