/**
 * @generated SignedSource<<36b38c8269101d8245bee3e7a641d4ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DeliverKind = "EQUAL" | "WEIGHT" | "%future added value";
export type UserRole = "SUPER" | "ADMIN_ALL" | "ADMIN" | "JUDGE" | "CLIENT" | "SAAS_CLIENT" | "%future added value";
export type ChatbotPatternCreatePageContainer_Query$variables = {
  slug: string;
};
export type ChatbotPatternCreatePageContainer_QueryVariables = ChatbotPatternCreatePageContainer_Query$variables;
export type ChatbotPatternCreatePageContainer_Query$data = {
  readonly viewer: {
    readonly role: UserRole;
  };
  readonly chatbotGroup: {
    readonly id: string;
    readonly name: string;
    readonly slug: string;
    readonly deliverKind: DeliverKind;
  } | null;
};
export type ChatbotPatternCreatePageContainer_QueryResponse = ChatbotPatternCreatePageContainer_Query$data;
export type ChatbotPatternCreatePageContainer_Query = {
  variables: ChatbotPatternCreatePageContainer_QueryVariables;
  response: ChatbotPatternCreatePageContainer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "slug",
      "variableName": "slug"
    }
  ],
  "concreteType": "ChatbotGroup",
  "kind": "LinkedField",
  "name": "chatbotGroup",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deliverKind",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChatbotPatternCreatePageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      (v3/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChatbotPatternCreatePageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      (v3/*: any*/)
    ]
  },
  "params": {
    "cacheID": "c8374a331fdcb724a21a9220d43aa905",
    "id": null,
    "metadata": {},
    "name": "ChatbotPatternCreatePageContainer_Query",
    "operationKind": "query",
    "text": "query ChatbotPatternCreatePageContainer_Query(\n  $slug: String!\n) {\n  viewer {\n    role\n    id\n  }\n  chatbotGroup(slug: $slug) {\n    id\n    name\n    slug\n    deliverKind\n  }\n}\n"
  }
};
})();

(node as any).hash = "76960fd160c97d58315d3a2c1fbfdee1";

export default node;
