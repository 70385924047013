/**
 * @generated SignedSource<<bb470c04ad875ffd0e22ea2dcf19bfe5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddAbtestScenarioPageContentScenarioInput = {
  clientMutationId?: string | null;
  abtestScenarioPageId: string;
  image?: File | Blog | null;
  beforeSrc: string;
};
export type ABTestPatternCreatePageContainer_ImageContentMutation$variables = {
  input: AddAbtestScenarioPageContentScenarioInput;
};
export type ABTestPatternCreatePageContainer_ImageContentMutationVariables = ABTestPatternCreatePageContainer_ImageContentMutation$variables;
export type ABTestPatternCreatePageContainer_ImageContentMutation$data = {
  readonly addAbtestScenarioPageContentScenario: {
    readonly abtestScenarioPageContentEdge: {
      readonly node: {
        readonly __typename: "AbtestScenarioPageContentScenario";
        readonly id: string;
        readonly image: string | null;
        readonly imageUrl: string | null;
        readonly beforeSrc: string | null;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      } | null;
    } | null;
  } | null;
};
export type ABTestPatternCreatePageContainer_ImageContentMutationResponse = ABTestPatternCreatePageContainer_ImageContentMutation$data;
export type ABTestPatternCreatePageContainer_ImageContentMutation = {
  variables: ABTestPatternCreatePageContainer_ImageContentMutationVariables;
  response: ABTestPatternCreatePageContainer_ImageContentMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "image",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "beforeSrc",
      "storageKey": null
    }
  ],
  "type": "AbtestScenarioPageContentScenario",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ABTestPatternCreatePageContainer_ImageContentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddAbtestScenarioPageContentScenarioPayload",
        "kind": "LinkedField",
        "name": "addAbtestScenarioPageContentScenario",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AbtestScenarioPageContentEdge",
            "kind": "LinkedField",
            "name": "abtestScenarioPageContentEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ABTestPatternCreatePageContainer_ImageContentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddAbtestScenarioPageContentScenarioPayload",
        "kind": "LinkedField",
        "name": "addAbtestScenarioPageContentScenario",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AbtestScenarioPageContentEdge",
            "kind": "LinkedField",
            "name": "abtestScenarioPageContentEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6144f0b5c665dc96182599d1ce26ddd2",
    "id": null,
    "metadata": {},
    "name": "ABTestPatternCreatePageContainer_ImageContentMutation",
    "operationKind": "mutation",
    "text": "mutation ABTestPatternCreatePageContainer_ImageContentMutation(\n  $input: AddAbtestScenarioPageContentScenarioInput!\n) {\n  addAbtestScenarioPageContentScenario(input: $input) {\n    abtestScenarioPageContentEdge {\n      node {\n        __typename\n        ... on AbtestScenarioPageContentScenario {\n          id\n          image\n          imageUrl\n          beforeSrc\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "dd7e7b1fcc0b53e111a27bc9636bae04";

export default node;
