import { useToast } from "@chakra-ui/toast";
import { DeleteOutline as DeleteOutlineIcon } from "@mui/icons-material";
import { FC, useCallback, useMemo } from "react";
import { graphql } from "react-relay";

import { PopupGroupSetDetailLinkedPopupGroupListTableActions_Mutation } from "~/src/__relay_artifacts__/PopupGroupSetDetailLinkedPopupGroupListTableActions_Mutation.graphql";
import { TableActions } from "~/src/components/common/tables/TableActions";
import { DeleteConfirmDialog } from "~/src/components/features/global/DeleteConfirmDialog";
import { useOpenState } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";

type Props = {
  popupGroupName: string;
  popupGroupSettingId: string;
  connectionId: string;
};

const mutation = graphql`
  mutation PopupGroupSetDetailLinkedPopupGroupListTableActions_Mutation(
    $connections: [ID!]!
    $input: DeletePopupGroupSettingInput!
  ) {
    deletePopupGroupSetting(input: $input) {
      deletedPopupGroupSettingId @deleteEdge(connections: $connections)
    }
  }
`;

export const PopupGroupSetDetailLinkedPopupGroupListTableActions: FC<Props> = ({
  popupGroupName,
  popupGroupSettingId,
  connectionId,
}) => {
  const toast = useToast();
  const { isOpen, onClose, onOpen } = useOpenState();

  const mutate =
    useMutationCommit<PopupGroupSetDetailLinkedPopupGroupListTableActions_Mutation>(
      mutation
    );

  const handleDelete = useCallback(async () => {
    try {
      const res = await mutate({
        variables: {
          input: {
            popupGroupSettingId,
          },
          connections: [connectionId],
        },
      });
      const deletedPopupGroupSettingId =
        res.deletePopupGroupSetting?.deletedPopupGroupSettingId;
      if (!deletedPopupGroupSettingId) throw new Error("assertion failed");

      toast({
        title: `${popupGroupName}との紐付けを取り消しました`,
        status: "success",
      });
    } catch (err) {
      toast({ title: "取り消しに失敗しました", status: "error" });
    }
  }, [connectionId, mutate, popupGroupName, popupGroupSettingId, toast]);

  const menus = useMemo(
    () => [
      {
        label: "削除",
        icon: <DeleteOutlineIcon />,
        onClick: () => onOpen(),
      },
    ],
    [onOpen]
  );

  return (
    <>
      <TableActions actionMenus={menus} />
      <DeleteConfirmDialog
        name={popupGroupName}
        isOpen={isOpen}
        onClose={onClose}
        onDelete={handleDelete}
      />
    </>
  );
};
