/**
 * @generated SignedSource<<4c9d8dcd2c95bfa3e70031211bc9f315>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteAbtestScenarioPageGroupInput = {
  clientMutationId?: string | null;
  abtestScenarioPageGroupId: string;
};
export type ABTestTableActions_DeleteMutation$variables = {
  connections: ReadonlyArray<string>;
  input: DeleteAbtestScenarioPageGroupInput;
};
export type ABTestTableActions_DeleteMutationVariables = ABTestTableActions_DeleteMutation$variables;
export type ABTestTableActions_DeleteMutation$data = {
  readonly deleteAbtestScenarioPageGroup: {
    readonly deletedAbtestScenarioPageGroupId: string;
  } | null;
};
export type ABTestTableActions_DeleteMutationResponse = ABTestTableActions_DeleteMutation$data;
export type ABTestTableActions_DeleteMutation = {
  variables: ABTestTableActions_DeleteMutationVariables;
  response: ABTestTableActions_DeleteMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedAbtestScenarioPageGroupId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ABTestTableActions_DeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteAbtestScenarioPageGroupPayload",
        "kind": "LinkedField",
        "name": "deleteAbtestScenarioPageGroup",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ABTestTableActions_DeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteAbtestScenarioPageGroupPayload",
        "kind": "LinkedField",
        "name": "deleteAbtestScenarioPageGroup",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "deleteEdge",
            "key": "",
            "kind": "ScalarHandle",
            "name": "deletedAbtestScenarioPageGroupId",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b89b7d2a2c136bdc3a27eab0ee3e7923",
    "id": null,
    "metadata": {},
    "name": "ABTestTableActions_DeleteMutation",
    "operationKind": "mutation",
    "text": "mutation ABTestTableActions_DeleteMutation(\n  $input: DeleteAbtestScenarioPageGroupInput!\n) {\n  deleteAbtestScenarioPageGroup(input: $input) {\n    deletedAbtestScenarioPageGroupId\n  }\n}\n"
  }
};
})();

(node as any).hash = "52f8ed66d897caa676ae89c1557b54f6";

export default node;
