import { Box, Spacer } from "@chakra-ui/react";
import {
  AnalyticsOutlined as AnalyticsOutlinedIcon,
  AutoAwesomeMotionOutlined as AutoAwesomeMotionOutlinedIcon,
  HomeOutlined as HomeOutlinedIcon,
  SafetyDividerOutlined as SafetyDividerOutlinedIcon,
  SettingsOutlined as SettingsOutlinedIcon,
  SmsOutlined as SmsOutlinedIcon,
  WebAssetOutlined as WebAssetOutlinedIcon,
} from "@mui/icons-material";
import { FC } from "react";
import { NavLink } from "react-router-dom";

import { UserRole } from "~/src/__generated__/schema";
import { IconButton } from "~/src/components/common/IconButton";
import { chakraFactory } from "~/src/lib/chakra-ui";

export type Props = {
  siteSlug: string;
  role: UserRole;
};

const StyledSidebarWrapperBox = chakraFactory(Box, {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: "72px",
    height: "100%",
    background: "#FCFCFC",
    py: "10px",
  },
});

const StyledNavLink = chakraFactory(NavLink, {
  baseStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "52px",

    svg: {
      color: "#979797",
    },

    "&.active, &:hover": {
      svg: {
        color: "#F0D209",
      },
    },
  },
});

export const Sidebar: FC<Props> = ({ siteSlug, role }) => (
  <StyledSidebarWrapperBox>
    <StyledNavLink to={`/sites/${siteSlug}`} end>
      <IconButton
        label="ダッシュボード"
        size="32px"
        icon={<HomeOutlinedIcon />}
        tooltipPlacement="right"
      />
    </StyledNavLink>
    <StyledNavLink to={`/sites/${siteSlug}/popup_groups`}>
      <IconButton
        label="ポップアップ"
        size="32px"
        icon={<WebAssetOutlinedIcon />}
        tooltipPlacement="right"
      />
    </StyledNavLink>
    {(role === "ADMIN" || role === "SUPER") && (
      <StyledNavLink to={`/sites/${siteSlug}/popup_group_sets`}>
        <IconButton
          label="ポップアップセット"
          size="32px"
          icon={<AutoAwesomeMotionOutlinedIcon />}
          tooltipPlacement="right"
        />
      </StyledNavLink>
    )}
    <StyledNavLink to={`/sites/${siteSlug}/abtests`}>
      <IconButton
        label="A/Bテスト"
        size="32px"
        icon={<SafetyDividerOutlinedIcon />}
        tooltipPlacement="right"
      />
    </StyledNavLink>
    <StyledNavLink to={`/sites/${siteSlug}/chatbots`}>
      <IconButton
        label="チャットボット"
        size="32px"
        icon={<SmsOutlinedIcon />}
        tooltipPlacement="right"
      />
    </StyledNavLink>
    <StyledNavLink to={`/sites/${siteSlug}/reports`}>
      <IconButton
        label="レポート"
        size="32px"
        icon={<AnalyticsOutlinedIcon />}
        tooltipPlacement="right"
      />
    </StyledNavLink>
    <Spacer />
    <StyledNavLink to={`/sites/${siteSlug}/settings`}>
      <IconButton
        label="設定"
        size="32px"
        icon={<SettingsOutlinedIcon />}
        tooltipPlacement="right"
      />
    </StyledNavLink>
  </StyledSidebarWrapperBox>
);
