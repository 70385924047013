import { Box, Flex, HStack, Spacer } from "@chakra-ui/react";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { graphql, usePaginationFragment } from "react-relay";
import { NavLink } from "react-router-dom";

import {
  AbtestScenarioPageGroupOrder,
  UserRole,
} from "~/src/__generated__/schema";
import { ABTestListPage_site$key } from "~/src/__relay_artifacts__/ABTestListPage_site.graphql";
import { SolidButton } from "~/src/components/common/Button";
import {
  Table,
  TableHeaderCheckboxColumn,
  TableHeaderColumn,
  TableHeaderRow,
} from "~/src/components/common/tables/Table";
import { useTableItemSelect } from "~/src/components/common/tables/TableItemSelect";
import {
  TablePagination,
  useTablePaginationProps,
} from "~/src/components/common/tables/TablePagination";
import { TableSearchField } from "~/src/components/common/tables/TableSearchField";
import { ListFilterFormValues as FormValues } from "~/src/components/features/filter";
import { PageLayout } from "~/src/components/features/global/PageLayout";
import { TableItemSelectButton } from "~/src/components/features/global/TableItemSelectButton";
import { useBulkCheckboxes } from "~/src/lib/hooks";
import { useUpdateDebounce } from "~/src/lib/react-use";

import { ABTestFilterButton } from "./ABTestFilterButton";
import { ABTestTableDataRow } from "./ABTestTableDataRow";
import { allTableItems, initialSelectedTableItems } from "./constants";

export type Props = {
  role: UserRole;
  siteRef: ABTestListPage_site$key;
  filterValues: FormValues;
  setFilterValues: Dispatch<SetStateAction<FormValues>>;
};

const siteFragment = graphql`
  fragment ABTestListPage_site on Site
  @refetchable(queryName: "ABTestListPage_Query")
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 120 }
    cursor: { type: "String" }
    searchTerm: { type: "String" }
    pageIds: { type: "[ID!]" }
    funnelIds: { type: "[ID!]" }
    sourceId: { type: "ID" }
    device: { type: "Device" }
    withArchived: { type: "Boolean" }
    status: { type: "DistributionStatus" }
    pageKind: { type: "PageKind" }
    order: {
      type: "AbtestScenarioPageGroupOrder"
      defaultValue: { field: STATUS, direction: DESC }
    }
  ) {
    id
    slug
    abtestScenarioPageGroups(
      first: $count
      after: $cursor
      searchTerm: $searchTerm
      pageIds: $pageIds
      funnelIds: $funnelIds
      sourceId: $sourceId
      device: $device
      withArchived: $withArchived
      orderBy: $order
      status: $status
      pageKind: $pageKind
    ) @connection(key: "ABTestListPage_abtestScenarioPageGroups") {
      __id
      totalCount
      edges {
        node {
          slug
          ...ABTestTableDataRow_abtestScenarioPageGroup
        }
      }
    }
  }
`;

export const ABTestListPage: FC<Props> = ({
  siteRef,
  role,
  filterValues,
  setFilterValues,
}) => {
  const [searchText, setSearchText] = useState("");
  const [sort, setSort] = useState<AbtestScenarioPageGroupOrder>({
    field: "STATUS",
    direction: "DESC",
  });
  const handleSortClick = useCallback((params) => setSort(params), []);
  const {
    data: site,
    loadNext,
    hasNext,
    refetch,
  } = usePaginationFragment(siteFragment, siteRef);
  const { tablePaginationProps } = useTablePaginationProps({
    totalCount: site.abtestScenarioPageGroups.totalCount,
    hasNext,
    loadNext,
    refetch,
  });
  const { selectedTableItems, dataColumns, onApplyClick } = useTableItemSelect({
    cacheKey: "ABTestListPage",
    initialSelectedTableItems,
  });

  const abtests = useMemo(() => {
    const from = tablePaginationProps.from;
    const to = tablePaginationProps.to;
    const egdes = site.abtestScenarioPageGroups.edges || [];
    return egdes.slice(from, to).map((edge) => {
      const node = edge?.node;
      if (!node) throw new Error("assertion failed");
      return node;
    });
  }, [
    site.abtestScenarioPageGroups.edges,
    tablePaginationProps.from,
    tablePaginationProps.to,
  ]);

  const {
    selectedSlugs,
    isAllChecked,
    handleAllCheckboxClick,
    handleCheckboxClick,
  } = useBulkCheckboxes(abtests);

  useUpdateDebounce(
    () =>
      refetch({
        searchTerm: searchText,
        count: tablePaginationProps.perPage,
        order: {
          field: sort.field,
          direction: sort.direction,
        },
        ...filterValues,
      }),
    500,
    [filterValues, refetch, searchText, tablePaginationProps.perPage, sort]
  );

  return (
    <PageLayout title="A/Bテスト">
      <Box my="16px">
        <HStack justifyContent="flex-end" mb="16px" spacing="16px">
          <NavLink to={`/sites/${site.slug}/abtests/new`}>
            <SolidButton>登録</SolidButton>
          </NavLink>
        </HStack>
        <Flex alignItems="flex-end">
          <TableSearchField searchText={searchText} onChange={setSearchText} />
          <Spacer />
          <HStack spacing="12px">
            <ABTestFilterButton
              siteSlug={site.slug}
              filterValues={filterValues}
              onSubmit={setFilterValues}
              onFilterChange={setFilterValues}
            />
            <TableItemSelectButton
              allTableItems={allTableItems}
              selectedTableItems={selectedTableItems}
              onApplyClick={onApplyClick}
            />
          </HStack>
        </Flex>
        <Table>
          <TableHeaderRow>
            <TableHeaderCheckboxColumn
              isChecked={isAllChecked}
              onChange={handleAllCheckboxClick}
            />
            <TableHeaderColumn>A/Bテスト名</TableHeaderColumn>
            <TableHeaderColumn field={"STATUS"} onSortClick={handleSortClick}>
              実施ステータス
            </TableHeaderColumn>
            {role !== "SAAS_CLIENT" && (
              <TableHeaderColumn>承認状態</TableHeaderColumn>
            )}
            {selectedTableItems.map((item) =>
              item.orderField ? (
                <TableHeaderColumn
                  key={item.label}
                  field={item.orderField}
                  onSortClick={handleSortClick}
                >
                  {item.label}
                </TableHeaderColumn>
              ) : (
                <TableHeaderColumn key={item.label}>
                  {item.label}
                </TableHeaderColumn>
              )
            )}
            <TableHeaderColumn>アクション</TableHeaderColumn>
          </TableHeaderRow>
          {abtests.map((abtest) => (
            <ABTestTableDataRow
              key={abtest.slug}
              abtestRef={abtest}
              siteSlug={site.slug}
              dataColumns={dataColumns}
              isChecked={selectedSlugs.includes(abtest.slug)}
              connectionId={site.abtestScenarioPageGroups.__id}
              onCheck={handleCheckboxClick}
              role={role}
            />
          ))}
        </Table>
        <TablePagination {...tablePaginationProps} />
      </Box>
    </PageLayout>
  );
};
