import { Box } from "@chakra-ui/react";
import { FC } from "react";

import { chakraFactory } from "~/src/lib/chakra-ui";

export type Props = {
  label: string;
};

const StyledWrapperBox = chakraFactory(Box, {
  baseStyle: {
    display: "inline-flex",
    alignItems: "center",
    width: "auto",
    minHeight: "24px",
    px: "16px",
    borderRadius: "12px",
    background: "#E0E0E0",
    fontSize: "10px",
    color: "#282828",

    "& + &": {
      marginLeft: "8px",
    },
  },
});

export const Chip: FC<Props> = ({ label }) => (
  <StyledWrapperBox>{label}</StyledWrapperBox>
);
