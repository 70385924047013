/**
 * @generated SignedSource<<00a737110c80ed662ae6eabe55d362b6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DeliverKind = "EQUAL" | "WEIGHT" | "%future added value";
export type PageKind = "LANDING" | "FORM" | "CONFIRM" | "THANKS" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ABTestPatternEditScreenContainer_abtestScenarioPageGroup$data = {
  readonly id: string;
  readonly title: string;
  readonly deliverKind: DeliverKind | null;
  readonly originalAbtestScenarioPage: {
    readonly id: string;
    readonly weight: number | null;
  };
  readonly page: {
    readonly name: string;
    readonly kind: PageKind;
    readonly sortNumber: number;
    readonly url: string;
    readonly funnel: {
      readonly name: string;
      readonly siteUrl: string;
    };
  };
  readonly " $fragmentType": "ABTestPatternEditScreenContainer_abtestScenarioPageGroup";
};
export type ABTestPatternEditScreenContainer_abtestScenarioPageGroup = ABTestPatternEditScreenContainer_abtestScenarioPageGroup$data;
export type ABTestPatternEditScreenContainer_abtestScenarioPageGroup$key = {
  readonly " $data"?: ABTestPatternEditScreenContainer_abtestScenarioPageGroup$data;
  readonly " $fragmentSpreads": FragmentRefs<"ABTestPatternEditScreenContainer_abtestScenarioPageGroup">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ABTestPatternEditScreenContainer_abtestScenarioPageGroup",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deliverKind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AbtestScenarioPage",
      "kind": "LinkedField",
      "name": "originalAbtestScenarioPage",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "weight",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Page",
      "kind": "LinkedField",
      "name": "page",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sortNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Funnel",
          "kind": "LinkedField",
          "name": "funnel",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "siteUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AbtestScenarioPageGroup",
  "abstractKey": null
};
})();

(node as any).hash = "6d67c3f8488c332f5113054b7d3e40ae";

export default node;
