export const formatStayTime = (time: number) => {
  const hours = Math.floor(time / (60 * 60));
  const minutes = Math.floor(time / 60) % 60;
  const seconds = Math.floor(time % 60);
  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
    seconds
  )}`;
};

const padTo2Digits = (num: number): string => {
  return num.toString().padStart(2, "0");
};
