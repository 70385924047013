/**
 * @generated SignedSource<<77136cdea5a183cfab9a62f2cbc39ae8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ChatbotPatternFilterButton_Query$variables = {};
export type ChatbotPatternFilterButton_QueryVariables = ChatbotPatternFilterButton_Query$variables;
export type ChatbotPatternFilterButton_Query$data = {
  readonly sources: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
      } | null;
    } | null> | null;
  };
};
export type ChatbotPatternFilterButton_QueryResponse = ChatbotPatternFilterButton_Query$data;
export type ChatbotPatternFilterButton_Query = {
  variables: ChatbotPatternFilterButton_QueryVariables;
  response: ChatbotPatternFilterButton_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SourceConnection",
    "kind": "LinkedField",
    "name": "sources",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SourceEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Source",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ChatbotPatternFilterButton_Query",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ChatbotPatternFilterButton_Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "83d354046587c4a8fb2bec0eff6bdd62",
    "id": null,
    "metadata": {},
    "name": "ChatbotPatternFilterButton_Query",
    "operationKind": "query",
    "text": "query ChatbotPatternFilterButton_Query {\n  sources {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e92a622b11fffdd0af9b1ffb2eb5333d";

export default node;
