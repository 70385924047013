import { Box, Flex, HStack } from "@chakra-ui/react";
import { FC, ReactElement } from "react";

import { chakraFactory } from "~/src/lib/chakra-ui";
import { nl2br } from "~/src/lib/utils";

import { Checkbox } from "../../Checkbox";
import { HelpIcon } from "../../HelpIcon";

export type Props = {
  label: string;
  as?: "label" | "div";
  help?: string | ReactElement;
  required?: boolean;
  dense?: boolean;
};

const StyledFlexWrapper = chakraFactory("div", {
  baseStyle: {
    width: "100%",
    display: "flex",
  },
});

const StyledLabelText = chakraFactory("label", {
  baseStyle: {
    height: "22px",
    fontSize: "13px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#000000",
  },
});

const StyledRequired = chakraFactory(Flex, {
  baseStyle: {
    width: "25px",
    height: "16px",
    background: "#D9534F",
    borderRadius: "2px",
    alignItems: "center",
  },
});

const StyledRequiredText = chakraFactory("p", {
  baseStyle: {
    width: "100%",
    transform: "scale(0.8)",
    fontSize: "10px",
    lineHeight: "12px",
    color: "#FFFFFF",
    textAlign: "center",
  },
});

export const FormControl: FC<Props> = ({
  children,
  as = "div",
  label,
  help,
  required = false,
  dense = false,
}) => (
  <StyledFlexWrapper as={as}>
    <HStack
      spacing="8px"
      width={dense ? "130px" : "260px"}
      height="40px"
      alignItems="center"
    >
      <StyledLabelText>{nl2br(label)}</StyledLabelText>
      {help && <HelpIcon text={help} />}
      <StyledRequired visibility={required ? "visible" : "hidden"}>
        <StyledRequiredText>必須</StyledRequiredText>
      </StyledRequired>
    </HStack>
    <Box flex="1" margin="auto 0">
      {children}
    </Box>
  </StyledFlexWrapper>
);

export type WithCheckboxProps = {
  isChecked: boolean;
  onCheck: () => void;
} & Props;

export const FormControlWithCheckbox: FC<WithCheckboxProps> = ({
  children,
  as = "div",
  label,
  help,
  required = false,
  dense = false,
  isChecked,
  onCheck,
}) => (
  <StyledFlexWrapper as={as}>
    <HStack alignItems={"baseline"}>
      <Checkbox isChecked={isChecked} onChange={onCheck}>
        <HStack
          spacing="8px"
          width={dense ? "130px" : "260px"}
          height="40px"
          alignItems="center"
        >
          <StyledLabelText as={"p"}>{nl2br(label)}</StyledLabelText>
          {help && <HelpIcon text={help} />}
          <StyledRequired visibility={required ? "visible" : "hidden"}>
            <StyledRequiredText>必須</StyledRequiredText>
          </StyledRequired>
        </HStack>
      </Checkbox>
    </HStack>
    <Box flex="1" margin="auto 0">
      {children}
    </Box>
  </StyledFlexWrapper>
);
