import qs from "qs";
import * as R from "ramda";
import { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router";

/**
 * parses number & boolean
 * see: https://github.com/ljharb/qs/issues/91#issuecomment-491391000
 */
const decoder = (str: string, _: any, charset: string) => {
  const strWithoutPlus = str.replace(/\+/g, " ");
  if (charset === "iso-8859-1") {
    // unescape never throws, no try...catch needed:
    return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape);
  }

  if (/^(\d+|\d*\.\d+)$/.test(str)) {
    return parseFloat(str);
  }

  const keywords = {
    true: true,
    false: false,
    null: null,
    undefined,
  };
  if (str in keywords) {
    // @ts-expect-error
    return keywords[str];
  }

  // utf-8
  try {
    return decodeURIComponent(strWithoutPlus);
  } catch (e) {
    return strWithoutPlus;
  }
};

export const useQueryString = <T = object>() => {
  const navigate = useNavigate();
  const location = useLocation();

  const params = useMemo(
    () => qs.parse(location.search, { ignoreQueryPrefix: true, decoder }),
    [location.search]
  );

  const changeQueryString = useCallback(
    (params: object, replace = false) => {
      const queryString = qs.stringify(
        // NOTE: omits falsey values without boolean
        R.pickBy((v) => typeof v === "boolean" || !!v, params),
        { addQueryPrefix: true }
      );
      navigate(location.pathname + queryString, { replace });
    },
    [location.pathname, navigate]
  );

  return {
    // @ts-expect-error
    params: params as T,
    changeQueryString,
  };
};
