import { format } from "date-fns";

import { reduceByMeasureKey } from "./reduceByMeasureKey";
import { GraphProp, MeasureKey } from "./types";

export const generateGraphDataArray = (
  reports: Record<any, any[]>[],
  measureKey: MeasureKey
): { data: GraphProp[] }[] => {
  const dataArray = reports.map((report) => {
    const results: GraphProp[] = [];
    Object.keys(report).forEach((key) => {
      const result = reduceByMeasureKey(report[key], measureKey);
      results.push({ x: format(new Date(key), "yyyy/MM/dd"), y: result });
    });
    return { data: results };
  });
  return dataArray;
};
