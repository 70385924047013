import { useToast } from "@chakra-ui/react";
import { FC, Suspense, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Modal } from "~/src/components/common/Modal";
import { PageLoading } from "~/src/components/common/PageLoading";
import { Select } from "~/src/components/common/Select";
import { chakraFactory } from "~/src/lib/chakra-ui";
import { useOpenState } from "~/src/lib/hooks";

import { PopupBulkArchiveModalContainer } from "./PopupBulkArchiveModalContainer";

const StyledSelect = chakraFactory(Select, {
  baseStyle: {
    width: "177px",
    height: "40px",
    paddingRight: "14px",
  },
});

export type Props = {
  selectedSlug: string[];
};

const operations = [
  { label: "実施ページ変更", value: "pageEdit" },
  { label: "アーカイブ", value: "archive" },
] as const;

export const PopupBulkOperaionSelect: FC<Props> = ({ selectedSlug }) => {
  const toast = useToast();
  const { isOpen, onClose, onOpen } = useOpenState();

  const { siteSlug = "" } = useParams();
  const navigate = useNavigate();

  const handleChange = useCallback(
    (option: typeof operations[number]) => {
      if (selectedSlug.length < 1) {
        toast({
          title: "1つ以上のポップアップを選択してください",
          status: "info",
        });
        return;
      }

      if (option.value === "pageEdit") {
        navigate(
          `/sites/${siteSlug}/popup_groups/bulk_page_edit/${selectedSlug}`
        );
      } else if (option.value === "archive") {
        onOpen();
      }
    },
    [navigate, onOpen, selectedSlug, siteSlug, toast]
  );

  return (
    <>
      <StyledSelect
        onChange={handleChange}
        options={operations}
        placeholder={"操作一覧"}
      />
      <Modal title="一括アーカイブ" isOpen={isOpen} onClose={onClose}>
        <Suspense fallback={<PageLoading />}>
          <PopupBulkArchiveModalContainer
            onClose={onClose}
            selectedSlug={selectedSlug}
          />
        </Suspense>
      </Modal>
    </>
  );
};
