import {
  ArrowDropDown as ArrowDropDownIcon,
  SearchOutlined as SearchOutlinedIcon,
} from "@mui/icons-material";
import {
  ChangeEventHandler,
  FC,
  Suspense,
  useCallback,
  useMemo,
  useState,
} from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useNavigate } from "react-router-dom";

import { HeaderSiteSelect_Query } from "~/src/__relay_artifacts__/HeaderSiteSelect_Query.graphql";
import { GhostButton } from "~/src/components/common/Button";
import { Modal } from "~/src/components/common/Modal";
import { PageLoading } from "~/src/components/common/PageLoading";
import { VerticalSeparator } from "~/src/components/common/Separator";
import { TextField } from "~/src/components/common/TextField";
import { useOpenState } from "~/src/lib/hooks";

import { SearchedSiteList } from "./SearchedSiteList";

export type Props = {
  siteSlug?: string;
};

const query = graphql`
  query HeaderSiteSelect_Query($slug: String!) {
    site(slug: $slug) {
      name
      slug
      id
    }
  }
`;

export const HeaderSiteSelect: FC<Props> = ({ siteSlug = "" }) => {
  const navigate = useNavigate();

  const { isOpen, onClose, onOpen } = useOpenState();

  const [searchText, setSearchText] = useState("");

  const handleSiteSelectChange = useCallback(
    (selectedSlug: string) => {
      onClose();
      navigate(`/sites/${selectedSlug}`);
    },
    [navigate, onClose]
  );

  const handleSearchTextChange = useCallback<
    ChangeEventHandler<HTMLInputElement>
  >((e) => setSearchText(e.target.value), []);

  const { site } = useLazyLoadQuery<HeaderSiteSelect_Query>(query, {
    slug: siteSlug,
  });

  const selectedSiteName = useMemo(() => site.name, [site]);

  return (
    <>
      <VerticalSeparator mx="10px" />
      <GhostButton border={"1px solid hsl(0, 0%, 80%)"} onClick={onOpen}>
        {selectedSiteName.length > 15
          ? selectedSiteName.substring(0, 15) + "..."
          : selectedSiteName}
        <ArrowDropDownIcon />
      </GhostButton>

      <Modal title="サイトの選択" isOpen={isOpen} onClose={onClose}>
        <TextField
          type="text"
          mode={"outline"}
          name={"search"}
          value={searchText}
          placeholder={"検索"}
          icon={<SearchOutlinedIcon />}
          onChange={handleSearchTextChange}
        />
        <Suspense fallback={<PageLoading />}>
          <SearchedSiteList
            searchText={searchText}
            handleSelectSite={handleSiteSelectChange}
          />
        </Suspense>
      </Modal>
    </>
  );
};
