import { useToast } from "@chakra-ui/react";
import { FC, useCallback, useMemo, useState } from "react";
import { graphql, useFragment, useLazyLoadQuery } from "react-relay";

import { DistributionStatus } from "~/src/__generated__/schema";
import { PopupGroupBulkEditFormContainer_Mutation } from "~/src/__relay_artifacts__/PopupGroupBulkEditFormContainer_Mutation.graphql";
import { PopupGroupBulkEditFormContainer_popupGroup$key } from "~/src/__relay_artifacts__/PopupGroupBulkEditFormContainer_popupGroup.graphql";
import { PopupGroupBulkEditFormContainer_Query } from "~/src/__relay_artifacts__/PopupGroupBulkEditFormContainer_Query.graphql";
import {
  PopupGroupForm,
  PopupGroupFormProps,
} from "~/src/components/features/popup";
import { useFormErrorHandler } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";
import { delayChunkPromise } from "~/src/lib/utils";

export type Props = {
  siteSlug: string;
  onClose: () => void;
  targetPopupGroups: {
    id: string;
    pageId: string;
    status: DistributionStatus;
  }[];
  popupGroupRef: PopupGroupBulkEditFormContainer_popupGroup$key;
};

const query = graphql`
  query PopupGroupBulkEditFormContainer_Query($siteSlug: String!) {
    site(slug: $siteSlug) {
      id
      name
      slug
      ...PopupGroupForm_site
    }
  }
`;

const fragment = graphql`
  fragment PopupGroupBulkEditFormContainer_popupGroup on PopupGroup {
    slug
    ageRanges
    annualIncomes
    browsers
    deliverDayOfWeeks {
      weekOfMonth
      daysOfWeek
    }
    deliverExcludeUrls {
      url
    }
    deliverExcludeLabels {
      id
      name
    }
    deliverTargetUrls {
      url
    }
    deliverTargetLabels {
      id
      name
    }
    deliverKind
    devices
    displayTargetContents {
      targetKind
      targetValue
    }
    disableTargetElements {
      targetKind
      targetValue
    }
    platforms
    genders
    id
    isMulti
    memo
    name
    repeat
    endDate
    endTime
    page {
      id
      funnel {
        id
      }
    }
    redisplayPopupTime
    slug
    sources {
      id
      name
    }
    excludeSources {
      id
      name
    }
    startDate
    startTime
    status
    excludeReferrers {
      referrer
      matchOp
    }
    targetCookies {
      key
      value
      matchType
    }
    referrers {
      referrer
      matchOp
    }
    visitCount
    visitMatchType
  }
`;

const mutation = graphql`
  mutation PopupGroupBulkEditFormContainer_Mutation(
    $input: UpdatePopupGroupInput!
  ) {
    updatePopupGroup(input: $input) {
      popupGroup {
        slug
        ...PopupGroupBulkEditFormContainer_popupGroup
      }
    }
  }
`;

export const PopupGroupBulkEditFormContainer: FC<Props> = ({
  siteSlug,
  popupGroupRef,
  onClose,
  targetPopupGroups,
}) => {
  const toast = useToast();
  const { site } = useLazyLoadQuery<PopupGroupBulkEditFormContainer_Query>(
    query,
    {
      siteSlug,
    }
  );

  const popupGroup = useFragment(fragment, popupGroupRef);

  const [repeat, setRepeat] = useState(popupGroup.repeat);

  const handleRepeatChange = useCallback(() => {
    setRepeat(!repeat);
  }, [repeat]);

  const mutate =
    useMutationCommit<PopupGroupBulkEditFormContainer_Mutation>(mutation);

  const { onFormError } = useFormErrorHandler();

  const handleSubmit = useCallback<PopupGroupFormProps["onSubmit"]>(
    async ({ funnelId, ...values }, { setErrors }) => {
      try {
        const mutations: Promise<{}>[] = targetPopupGroups.map((popupGroup) => {
          return mutate({
            variables: {
              input: {
                ...values,
                popupGroupId: popupGroup.id,
                pageId: popupGroup.pageId,
                status: popupGroup.status,
                isMulti: values.isMulti === "true",
                startDate: values.startDate?.toISOString() || null,
                startTime: values.startTime?.toISOString() || null,
                endDate: values.endDate?.toISOString() || null,
                endTime: values.endTime?.toISOString() || null,
                deliverDayOfWeeks: values.deliverDayOfWeeks,
              },
            },
          });
        });

        await delayChunkPromise(mutations);

        toast({ title: "ポップアップを一括更新しました", status: "success" });
        onClose();
      } catch (err) {
        onFormError(err, setErrors);
      }
    },
    [mutate, onFormError, targetPopupGroups, toast, onClose]
  );

  const sourceIds = useMemo(
    () =>
      popupGroup.sources?.map((node) => {
        return node.id;
      }) || [],
    [popupGroup.sources]
  );

  const excludeSourceIds = useMemo(
    () =>
      popupGroup.excludeSources?.map((node) => {
        return node.id;
      }) || [],
    [popupGroup.excludeSources]
  );

  const deliverTargetLabelIds = useMemo(
    () => popupGroup.deliverTargetLabels.map((label) => label.id),
    [popupGroup.deliverTargetLabels]
  );

  const deliverExcludeLabelIds = useMemo(
    () => popupGroup.deliverExcludeLabels.map((label) => label.id),
    [popupGroup.deliverExcludeLabels]
  );

  const initialValues = useMemo(
    () => ({
      ageRanges: popupGroup.ageRanges || [],
      annualIncomes: popupGroup.annualIncomes || [],
      browsers: popupGroup.browsers || [],
      deliverDayOfWeeks: repeat ? popupGroup.deliverDayOfWeeks : null,
      deliverExcludeUrls: popupGroup.deliverExcludeUrls,
      deliverExcludeLabelIds,
      deliverTargetUrls: popupGroup.deliverTargetUrls,
      deliverTargetLabelIds,
      deliverKind: popupGroup.deliverKind,
      devices: popupGroup.devices || [],
      displayTargetContents: popupGroup.displayTargetContents || [],
      disableTargetElements: popupGroup.disableTargetElements || [],
      endDate:
        repeat && popupGroup.endDate ? new Date(popupGroup.endDate) : null,
      endTime:
        repeat && popupGroup.endTime ? new Date(popupGroup.endTime) : null,
      excludeReferrers: popupGroup.excludeReferrers,
      funnelId: popupGroup.page?.funnel.id,
      genders: popupGroup.genders || [],
      isMulti: popupGroup.isMulti.toString(),
      memo: popupGroup.memo,
      name: popupGroup.name,
      pageId: popupGroup.page?.id,
      platforms: popupGroup.platforms || [],
      status: popupGroup.status,
      redisplayPopupTime: popupGroup.redisplayPopupTime,
      referrers: popupGroup.referrers,
      sourceIds,
      excludeSourceIds,
      startDate:
        repeat && popupGroup.startDate ? new Date(popupGroup.startDate) : null,
      startTime:
        repeat && popupGroup.startTime ? new Date(popupGroup.startTime) : null,
      targetCookies: popupGroup.targetCookies,
      visitCount: popupGroup.visitCount,
      visitMatchType: popupGroup.visitMatchType,
    }),
    [
      deliverExcludeLabelIds,
      deliverTargetLabelIds,
      popupGroup,
      repeat,
      sourceIds,
      excludeSourceIds,
    ]
  );

  return (
    <PopupGroupForm
      initialValues={initialValues as any}
      siteRef={site}
      repeat={repeat}
      onRepeatChange={handleRepeatChange}
      onCancelClick={onClose}
      onSubmit={handleSubmit}
      isFunnelDisabled
      isHideFunnelForm
    />
  );
};
