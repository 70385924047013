import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import * as R from "ramda";
import { FC, useCallback, useEffect, useState } from "react";

import { GhostButton, SolidButton } from "~/src/components/common/Button";
import { Modal } from "~/src/components/common/Modal";
import {
  HorizontalSeparator,
  VerticalSeparator,
} from "~/src/components/common/Separator";
import { chakraFactory } from "~/src/lib/chakra-ui";

import {
  SelectableItem,
  SelectedItem,
  SelectedItemPreview,
} from "./TableItems";
import { swap } from "./utils";

type TableItem = {
  label: string;
  value: string;
  orderField?: string;
};

export type Props = {
  isOpen: boolean;
  allTableItems: TableItem[];
  initialSelectedItems: TableItem[];
  onClose: () => void;
  onApplyClick: (selectedItems: TableItem[]) => void;
};

const StyledListBox = chakraFactory(Box, {
  baseStyle: {
    width: "50%",
    height: "100%",
    overflow: "auto",
    padding: "8px",
  },
});

const StyledDescriptionText = chakraFactory(Text, {
  baseStyle: {
    fontSize: "13px",
    display: "flex",
    alignItems: "center",
    color: "#000000",
    margin: "16px 0",
  },
});

/** 全項目と選択中の項目を元に選択可能な項目の配列を返す */
const getSelectableItems = (
  allTableItems: TableItem[],
  selectedItems: TableItem[]
) => {
  const selectedItemValues = selectedItems.map((item) => item.value);
  return allTableItems.filter(
    (tableItem) => !selectedItemValues.includes(tableItem.value)
  );
};

export const TableItemSelect: FC<Props> = ({
  isOpen,
  allTableItems,
  initialSelectedItems,
  onClose,
  onApplyClick,
}) => {
  const [selectedItems, setSelectedItems] =
    useState<TableItem[]>(initialSelectedItems);
  const [selectableItems, setSelectableItems] = useState(() =>
    getSelectableItems(allTableItems, initialSelectedItems)
  );

  const handleApplyClick = useCallback(
    () => onApplyClick(selectedItems),
    [onApplyClick, selectedItems]
  );

  const handleSelectableItemClick = useCallback(
    (index: number) => {
      const targetSelectableItem = selectableItems[index];
      setSelectableItems(R.remove(index, 1, selectableItems));
      setSelectedItems((items) => [...items, targetSelectableItem]);
    },
    [selectableItems]
  );

  const handleSelectedItemHover = useCallback(
    (fromIndex: number, toIndex: number) => {
      setSelectedItems((items) => swap(fromIndex, toIndex, items));
    },
    []
  );

  const handleSelectedItemDeleteClick = useCallback(
    (index: number) => {
      const newSelectedItems = R.remove(index, 1, selectedItems);
      setSelectedItems(newSelectedItems);
      setSelectableItems(getSelectableItems(allTableItems, newSelectedItems));
    },
    [selectedItems, allTableItems]
  );

  useEffect(() => {
    setSelectedItems(initialSelectedItems);
    setSelectableItems(getSelectableItems(allTableItems, initialSelectedItems));
  }, [allTableItems, initialSelectedItems]);

  return (
    <Modal
      disableHorizontalSeparatorMargin
      title="項目変更"
      isOpen={isOpen}
      onClose={onClose}
    >
      <SelectedItemPreview />
      <HStack height="400px">
        <StyledListBox>
          <StyledDescriptionText>指標を選んでください。</StyledDescriptionText>
          <VStack alignItems="flex-start">
            {selectableItems.map((selectableItem, i) => (
              <SelectableItem
                key={i}
                label={selectableItem.label}
                index={i}
                onClick={handleSelectableItemClick}
              />
            ))}
          </VStack>
        </StyledListBox>
        <VerticalSeparator height="100%" />
        <StyledListBox>
          <StyledDescriptionText>
            選択された指標が反映されます。
          </StyledDescriptionText>
          <VStack alignItems="flex-start">
            {selectedItems.map((selectedItem, i) => (
              <SelectedItem
                key={i}
                label={selectedItem.label}
                index={i}
                onSelectedItemHover={handleSelectedItemHover}
                onDeleteClick={handleSelectedItemDeleteClick}
              />
            ))}
          </VStack>
        </StyledListBox>
      </HStack>
      <HorizontalSeparator />
      <HStack mt="16px" spacing="8px" justifyContent="flex-end">
        <GhostButton onClick={onClose}>戻る</GhostButton>
        <SolidButton onClick={handleApplyClick}>適用</SolidButton>
      </HStack>
    </Modal>
  );
};
