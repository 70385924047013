/**
 * @generated SignedSource<<112d6903042f9eac0c11115033cc1d79>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChatbotReportTable_site$data = {
  readonly reportChatbot: any;
  readonly " $fragmentType": "ChatbotReportTable_site";
};
export type ChatbotReportTable_site = ChatbotReportTable_site$data;
export type ChatbotReportTable_site$key = {
  readonly " $data"?: ChatbotReportTable_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChatbotReportTable_site">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "input"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChatbotReportTable_site",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "input",
          "variableName": "input"
        }
      ],
      "kind": "ScalarField",
      "name": "reportChatbot",
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "fe601734c82aabb927503fe9ca227270";

export default node;
