import { MeasureKey } from "./types";

export const reduceByMeasureKey = (
  reports: any[],
  selectedMeasureKey: MeasureKey
): number => {
  const uniqueUser = reports.reduce((prev, curr) => prev + curr.unique_user, 0);

  switch (selectedMeasureKey) {
    case "UNIQUE_USER":
      return uniqueUser;
    case "CONVERSION_USER":
      return reports.reduce((prev, curr) => prev + curr.conversion_user, 0);
    case "CONVERSION_USER_RATE":
      const conversionUser = reports.reduce(
        (prev, curr) => prev + curr.conversion_user,
        0
      );
      return Number(conversionUser) / Number(uniqueUser);
    case "LP_DROP_RATE":
      const landingPageDropUser = reports.reduce(
        (prev, curr) => prev + curr.lp_drop_user,
        0
      );
      return Number(landingPageDropUser) / Number(uniqueUser);
    case "LP_SCROLL_RATE":
      const landingPageTotalScrollRate = reports.reduce(
        (prev, curr) => prev + curr.lp_total_scroll_rate,
        0
      );
      const landingPageView = reports.reduce(
        (prev, curr) => prev + curr.landing_page_view,
        0
      );
      if (Number(landingPageView) === 0) return 0;
      return Number(landingPageTotalScrollRate) / Number(landingPageView);
    case "LP_AVERAGE_STAY_TIME":
      const landingPageTotalStayTime = reports.reduce(
        (prev, curr) => prev + curr.lp_total_stay_time,
        0
      );
      return Number(landingPageTotalStayTime) / Number(uniqueUser);
    case "FORM_DROP_RATE":
      const formDropUser = reports.reduce(
        (prev, curr) => prev + curr.form_drop_user,
        0
      );
      const formUniqueUser = reports.reduce(
        (prev, curr) => prev + curr.form_unique_user,
        0
      );
      return Number(formDropUser) / Number(formUniqueUser);
    case "MOBILE_RATE":
      const mobileReports = reports.filter((row) => row.device === "mobile");
      const mobileUniqueUser = mobileReports.reduce(
        (prev, curr) => prev + curr.unique_user,
        0
      );
      return Number(mobileUniqueUser) / Number(uniqueUser);
    case "TABLET_RATE":
      const tabletReports = reports.filter((row) => row.device === "tablet");
      const tabletUniqueUser = tabletReports.reduce(
        (prev, curr) => prev + curr.unique_user,
        0
      );
      return Number(tabletUniqueUser) / Number(uniqueUser);
    default:
      const pcReports = reports.filter((row) => row.device === "pc");
      const pcUniqueUser = pcReports.reduce(
        (prev, curr) => prev + curr.unique_user,
        0
      );
      return Number(pcUniqueUser) / Number(uniqueUser);
  }
};
