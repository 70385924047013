import { useToast } from "@chakra-ui/react";
import { FC, useCallback, useMemo } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";

import { SiteEditFormContainer_Mutation } from "~/src/__relay_artifacts__/SiteEditFormContainer_Mutation.graphql";
import { SiteEditFormContainer_Query } from "~/src/__relay_artifacts__/SiteEditFormContainer_Query.graphql";
import { useFormErrorHandler } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";

import { SiteEditForm, SiteEditFormProps } from "./presentations";

export type Props = {
  siteSlug: string;
  onClose: () => void;
};

const query = graphql`
  query SiteEditFormContainer_Query($siteSlug: String!) {
    site(slug: $siteSlug) {
      id
      name
      url
      slug
    }
  }
`;

const mutation = graphql`
  mutation SiteEditFormContainer_Mutation($input: UpdateSiteInput!) {
    updateSite(input: $input) {
      site {
        id
        name
        url
        slug
      }
    }
  }
`;

export const SiteEditFormContainer: FC<Props> = ({ siteSlug, onClose }) => {
  const toast = useToast();
  const { onFormError } = useFormErrorHandler();
  const { site } = useLazyLoadQuery<SiteEditFormContainer_Query>(query, {
    siteSlug,
  });
  const updateSiteMutation =
    useMutationCommit<SiteEditFormContainer_Mutation>(mutation);

  const initialValues = useMemo(
    () => ({
      name: site.name,
      url: site.url,
    }),
    [site]
  );

  const handleSubmit = useCallback<SiteEditFormProps["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        // TODO: handle not found
        if (site === null) throw new Error("record not found");

        const res = await updateSiteMutation({
          variables: {
            input: {
              ...values,
              siteId: site.id,
            },
          },
        });
        if (!res?.updateSite?.site) throw new Error("assertion failed");
        toast({ title: "サイトを更新しました", status: "success" });
        onClose();
      } catch (err) {
        onFormError(err, setErrors);
      }
    },
    [site, updateSiteMutation, toast, onFormError, onClose]
  );

  return (
    <SiteEditForm
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onCancelClick={onClose}
    />
  );
};
