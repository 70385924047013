/**
 * @generated SignedSource<<a3134f15731c493fbb53f9adbdd02d02>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Device = "MOBILE" | "TABLET" | "PC" | "%future added value";
export type DistributionStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type OrderDirection = "ASC" | "DESC" | "%future added value";
export type PopupOrderField = "CREATED_AT" | "UPDATED_AT" | "KIND" | "NAME" | "%future added value";
export type ReportAnalyzerInput = {
  startOn: string;
  endOn: string;
  device?: Device | null;
  sourceId?: string | null;
  funnelId?: string | null;
};
export type PopupOrder = {
  direction: OrderDirection;
  field: PopupOrderField;
};
export type PopupPatternTable_RefetchQuery$variables = {
  analyzerInput: ReportAnalyzerInput;
  count?: number | null;
  cursor?: string | null;
  orderBy?: PopupOrder | null;
  status?: DistributionStatus | null;
  withArchived?: boolean | null;
  id: string;
};
export type PopupPatternTable_RefetchQueryVariables = PopupPatternTable_RefetchQuery$variables;
export type PopupPatternTable_RefetchQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"PopupPatternTable_popupGroup">;
  } | null;
};
export type PopupPatternTable_RefetchQueryResponse = PopupPatternTable_RefetchQuery$data;
export type PopupPatternTable_RefetchQuery = {
  variables: PopupPatternTable_RefetchQueryVariables;
  response: PopupPatternTable_RefetchQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "analyzerInput"
},
v1 = {
  "defaultValue": 120,
  "kind": "LocalArgument",
  "name": "count"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v4 = {
  "defaultValue": {
    "direction": "ASC",
    "field": "KIND"
  },
  "kind": "LocalArgument",
  "name": "orderBy"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "withArchived"
},
v7 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v8 = {
  "kind": "Variable",
  "name": "orderBy",
  "variableName": "orderBy"
},
v9 = {
  "kind": "Variable",
  "name": "status",
  "variableName": "status"
},
v10 = {
  "kind": "Variable",
  "name": "withArchived",
  "variableName": "withArchived"
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v14 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v8/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/)
],
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v16 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "analyzerInput"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PopupPatternTable_RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "analyzerInput",
                "variableName": "analyzerInput"
              },
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "PopupPatternTable_popupGroup"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "PopupPatternTable_RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v11/*: any*/),
          (v12/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v13/*: any*/),
              {
                "alias": null,
                "args": (v14/*: any*/),
                "concreteType": "PopupConnection",
                "kind": "LinkedField",
                "name": "popups",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PopupEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Popup",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/),
                          (v13/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "weight",
                            "storageKey": null
                          },
                          (v15/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isArchive",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "kind",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "judgeStatus",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": (v16/*: any*/),
                            "kind": "ScalarField",
                            "name": "clickCvr",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": (v16/*: any*/),
                            "kind": "ScalarField",
                            "name": "clickCvUserCount",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": (v16/*: any*/),
                            "kind": "ScalarField",
                            "name": "clickUserCount",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": (v16/*: any*/),
                            "kind": "ScalarField",
                            "name": "clickUserRate",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": (v16/*: any*/),
                            "kind": "ScalarField",
                            "name": "cvr",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": (v16/*: any*/),
                            "kind": "ScalarField",
                            "name": "impressionCvUserCount",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": (v16/*: any*/),
                            "kind": "ScalarField",
                            "name": "impressionCvr",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": (v16/*: any*/),
                            "kind": "ScalarField",
                            "name": "impressionUserCount",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": (v16/*: any*/),
                            "kind": "ScalarField",
                            "name": "readyCvUserCount",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": (v16/*: any*/),
                            "kind": "ScalarField",
                            "name": "totalCvUserCount",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": (v16/*: any*/),
                            "kind": "ScalarField",
                            "name": "totalUserCount",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "thumbnailImageUrl",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Page",
                            "kind": "LinkedField",
                            "name": "page",
                            "plural": false,
                            "selections": [
                              (v13/*: any*/),
                              (v15/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Funnel",
                                "kind": "LinkedField",
                                "name": "funnel",
                                "plural": false,
                                "selections": [
                                  (v15/*: any*/),
                                  (v13/*: any*/),
                                  (v12/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v12/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v14/*: any*/),
                "filters": [
                  "withArchived",
                  "orderBy",
                  "status"
                ],
                "handle": "connection",
                "key": "PopupPatternTable_popupGroup_popups",
                "kind": "LinkedHandle",
                "name": "popups"
              }
            ],
            "type": "PopupGroup",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2f835db0d36367a2c8ad88b48b46144f",
    "id": null,
    "metadata": {},
    "name": "PopupPatternTable_RefetchQuery",
    "operationKind": "query",
    "text": "query PopupPatternTable_RefetchQuery(\n  $analyzerInput: ReportAnalyzerInput!\n  $count: Int = 120\n  $cursor: String\n  $orderBy: PopupOrder = {field: KIND, direction: ASC}\n  $status: DistributionStatus\n  $withArchived: Boolean\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...PopupPatternTable_popupGroup_2hjeFd\n    id\n  }\n}\n\nfragment PopupPatternTableDataRow_popup_9vDjd on Popup {\n  id\n  slug\n  name\n  status\n  isArchive\n  kind\n  judgeStatus\n  weight\n  clickCvr(input: $analyzerInput)\n  clickCvUserCount(input: $analyzerInput)\n  clickUserCount(input: $analyzerInput)\n  clickUserRate(input: $analyzerInput)\n  cvr(input: $analyzerInput)\n  impressionCvUserCount(input: $analyzerInput)\n  impressionCvr(input: $analyzerInput)\n  impressionUserCount(input: $analyzerInput)\n  readyCvUserCount(input: $analyzerInput)\n  totalCvUserCount(input: $analyzerInput)\n  totalUserCount(input: $analyzerInput)\n  thumbnailImageUrl\n  page {\n    slug\n    name\n    funnel {\n      name\n      slug\n      id\n    }\n    id\n  }\n}\n\nfragment PopupPatternTable_popupGroup_2hjeFd on PopupGroup {\n  slug\n  popups(first: $count, after: $cursor, withArchived: $withArchived, orderBy: $orderBy, status: $status) {\n    totalCount\n    edges {\n      node {\n        id\n        slug\n        title\n        weight\n        ...PopupPatternTableDataRow_popup_9vDjd\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "7128c145900f3a1ae1d98130c3a51280";

export default node;
