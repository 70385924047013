import { array, date, number, object, string } from "yup";

import {
  AgeRange,
  AnnualIncome,
  Browser,
  DeliverDayOfWeekInput,
  DeliverKind,
  Device,
  Gender,
  Platform,
  PopupGroupDeliverUrlInput,
  PopupGroupDisableTargetElement,
  PopupGroupDisplayTargetContent,
  PopupGroupExcludeReferrerInput,
  PopupGroupReferrerInput,
  PopupGroupTargetCookieInput,
  VisitMatch,
} from "~/src/__generated__/schema";

export type FormValues = {
  ageRanges: AgeRange[];
  annualIncomes: AnnualIncome[];
  browsers: Browser[];
  deliverKind: DeliverKind;
  deliverTargetUrls: PopupGroupDeliverUrlInput[];
  deliverExcludeUrls: PopupGroupDeliverUrlInput[];
  deliverTargetLabelIds: string[];
  deliverExcludeLabelIds: string[];
  deliverDayOfWeeks: DeliverDayOfWeekInput[];
  devices: Device[];
  displayTargetContents: PopupGroupDisplayTargetContent[];
  disableTargetElements: PopupGroupDisableTargetElement[];
  endDate: Date | null;
  endTime: Date | null;
  excludeReferrers: PopupGroupExcludeReferrerInput[];
  funnelId: string;
  genders: Gender[];
  isMulti: string;
  memo: string | null;
  name: string;
  pageId: string;
  startDate: Date | null;
  startTime: Date | null;
  platforms: Platform[];
  redisplayPopupTime: number;
  referrers: PopupGroupReferrerInput[];
  sourceIds: string[];
  excludeSourceIds: string[];
  targetCookies: PopupGroupTargetCookieInput[];
  visitCount: number | null;
  visitMatchType: VisitMatch;
};

export const validationSchema = object({
  ageRanges: array().of(string()),
  annualIncomes: array().of(string()),
  browsers: array().of(string()),
  deliverTargetLabelIdIds: array().of(string()),
  deliverExcludeLabelIdIds: array().of(string()),
  deliverKind: string().required(),
  devices: array().of(string()),
  endTime: string().nullable(),
  endDatetime: date().nullable(),
  genders: array().of(string()),
  isMulti: string().required(),
  memo: string().nullable(),
  name: string().required().max(50),
  sourceIds: array().of(string()),
  excludeSourceIds: array().of(string()),
  startDatetime: date().nullable(),
  startTime: string().nullable(),
  platforms: array().of(string()),
  visitCount: number().nullable(),
  visitMatchType: string().required(),
});
