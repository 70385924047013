import { graphql } from "react-relay";

import { useUpdateExcludedIpStatus_Mutation } from "~/src/__relay_artifacts__/useUpdateExcludedIpStatus_Mutation.graphql";
import { useMutationCommit } from "~/src/lib/react-relay";

const mutation = graphql`
  mutation useUpdateExcludedIpStatus_Mutation(
    $input: UpdateExcludedIpAddressInput!
  ) {
    updateExcludedIpAddress(input: $input) {
      excludedIpAddress {
        id
        ipAddress
        status
      }
    }
  }
`;

export const useUpdateExcludedIpStatus = () => {
  const updateExcludedIpStatus =
    useMutationCommit<useUpdateExcludedIpStatus_Mutation>(mutation);

  return { updateExcludedIpStatus };
};
