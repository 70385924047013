import { Box, BoxProps } from "@chakra-ui/react";
import accounting from "accounting";
import { format } from "date-fns";
import { FC, ReactNode, useMemo } from "react";

import { chakraFactory } from "~/src/lib/chakra-ui";

import { columnStyles } from "../styles";

export type Props =
  | ({
      className?: string;
      type: "text" | "actions" | "date";
      data: ReactNode;
      isLink?: boolean;
    } & BoxProps)
  | ({
      className?: string;
      type: "numeric";
      data: number;
      isLink?: boolean;
    } & BoxProps);

const getJustifyContentByType = (type?: Props["type"]) => {
  switch (type) {
    case "numeric":
      return "flex-end";
    case "actions":
    case "text":
      return "center";
    case "date":
    default:
      return "flex-start";
  }
};

const assignLinkStyle = (isLink?: Props["isLink"]) => {
  if (isLink === true) return { color: "#1867C0", textDecoration: "underline" };
  return {};
};

const StyledDataColumnBox = chakraFactory<Pick<Props, "type" | "isLink">>(Box, {
  baseStyle: (props) => ({
    ...columnStyles,
    justifyContent: getJustifyContentByType(props.type),
    fontSize: "11px",
    lineHeight: "16px",
    letterSpacing: "0.06em",
    ...assignLinkStyle(props.isLink),
  }),
});

export const TableDataColumn: FC<Props> = ({
  className,
  type,
  data,
  isLink,
  ...props
}) => {
  const displayedData = useMemo(() => {
    if (type === "numeric") return accounting.formatNumber(data as number);
    if (type === "date" && data !== null)
      return format(new Date(data as string), "yyyy-MM-dd HH:mm:ss");
    return data;
  }, [data, type]);

  return (
    <StyledDataColumnBox
      type={type}
      className={className}
      isLink={isLink}
      {...props}
    >
      {displayedData}
    </StyledDataColumnBox>
  );
};
