/**
 * @generated SignedSource<<b79d37e546804c1d39efa2f1e69f18e4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SearchedSiteList_Query$variables = {
  searchText?: string | null;
};
export type SearchedSiteList_QueryVariables = SearchedSiteList_Query$variables;
export type SearchedSiteList_Query$data = {
  readonly viewer: {
    readonly sites: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string;
          readonly slug: string;
        } | null;
      } | null> | null;
    };
  };
};
export type SearchedSiteList_QueryResponse = SearchedSiteList_Query$data;
export type SearchedSiteList_Query = {
  variables: SearchedSiteList_QueryVariables;
  response: SearchedSiteList_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchText"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "searchTerm",
      "variableName": "searchText"
    }
  ],
  "concreteType": "SiteConnection",
  "kind": "LinkedField",
  "name": "sites",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SiteEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Site",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "slug",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SearchedSiteList_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SearchedSiteList_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cbb63308e99fc153c8c0328cdf1b74c0",
    "id": null,
    "metadata": {},
    "name": "SearchedSiteList_Query",
    "operationKind": "query",
    "text": "query SearchedSiteList_Query(\n  $searchText: String\n) {\n  viewer {\n    sites(searchTerm: $searchText) {\n      edges {\n        node {\n          id\n          name\n          slug\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "f0b99a8e5b8d3282d7d25b94aaf21eab";

export default node;
