import { Flex } from "@chakra-ui/react";
import { format } from "date-fns";
import { useCallback, useContext, useState } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";

import { SiteReportOrder } from "~/src/__generated__/schema";
import { PublisherReportContainer_Query } from "~/src/__relay_artifacts__/PublisherReportContainer_Query.graphql";
import { DateRangeContext } from "~/src/components/features/global/HeaderNavigation/DateRangeField";

import { ReportFilterButton, ReportFilterValues } from "../ReportFilterButton";

import { PublisherReportGraph } from "./PublisherReportGraph";
import { PublisherReportTable } from "./PublisherReportTable";

const query = graphql`
  query PublisherReportContainer_Query($input: ReportInput!, $slug: String!) {
    site(slug: $slug) {
      ...PublisherReportTable_site
      ...PublisherReportGraph_site
    }
  }
`;

export const PublisherReportContainer = () => {
  const { siteSlug = "" } = useParams();

  const [sort, setSort] = useState<SiteReportOrder>({
    field: "UNIQUE_USER",
    direction: "DESC",
  });

  const [filterValues, setFilterValues] = useState<ReportFilterValues>({
    sourceId: null,
    device: null,
    funnelIds: [],
    pageKind: null,
  });

  const handleSortClick = useCallback((params) => setSort(params), []);

  const {
    dateRange: [startOn, endOn],
  } = useContext(DateRangeContext);

  const queryRef = useLazyLoadQuery<PublisherReportContainer_Query>(query, {
    slug: siteSlug,
    input: {
      startDate: format(startOn, "yyyy-MM-dd"),
      endDate: format(endOn, "yyyy-MM-dd"),
      sourceId: filterValues.sourceId,
      device: filterValues.device,
      funnelIds: filterValues.funnelIds,
      pageKind: filterValues.pageKind,
      orderBy: {
        direction: sort.direction,
        field: sort.field,
      },
    },
  });

  return (
    <>
      <Flex justifyContent={"end"} mt={4} mb={4}>
        <ReportFilterButton
          filterValues={filterValues}
          onSubmit={setFilterValues}
          onFilterChange={setFilterValues}
        />
      </Flex>
      <PublisherReportGraph siteRef={queryRef.site} />
      <PublisherReportTable
        siteRef={queryRef.site}
        onSortClick={handleSortClick}
      />
    </>
  );
};
