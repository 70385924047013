import { HStack, VStack } from "@chakra-ui/react";
import { FilterAltOutlined as FilterAltOutlinedIcon } from "@mui/icons-material";
import { Form, Formik } from "formik";
import { FC, useCallback, useMemo, useState } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";
import * as yup from "yup";

import { GraphQLEnums } from "~/src/__generated__/GraphQLEnums";
import { Device } from "~/src/__generated__/schema";
import { DashboardFilterButton_Query } from "~/src/__relay_artifacts__/DashboardFilterButton_Query.graphql";
import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormikFormButtons } from "~/src/components/common/forms/FormikFormButtons";
import { FormikSelectField } from "~/src/components/common/forms/FormikSelectField";
import { IconButton } from "~/src/components/common/IconButton";
import { Modal } from "~/src/components/common/Modal";
import { FilteredTag } from "~/src/components/features/filter";
import { useOpenState } from "~/src/lib/hooks";

export type FormValues = {
  sourceId: string | null;
  device: Device | null;
  funnelId: string | null;
  pageId: string | null;
};

export type Props = {
  filterValues: FormValues;
  onFilterChange: (filterValues: FormValues) => void;
  onSubmit: (values: FormValues) => Promise<void> | void;
};

const query = graphql`
  query DashboardFilterButton_Query($slug: String!) {
    sources {
      edges {
        node {
          id
          name
        }
      }
    }
    site(slug: $slug) {
      funnels {
        edges {
          node {
            id
            name
          }
        }
      }
      pages {
        edges {
          node {
            id
            name
            sortNumber
            kind
            funnel {
              id
            }
          }
        }
      }
    }
  }
`;

const validationSchema = yup.object({
  sourceId: yup.string().nullable(),
  device: yup.string().nullable(),
  funnelId: yup.string().nullable(),
  pageId: yup.string().nullable(),
});

export const DashboardFilterButton: FC<Props> = ({
  filterValues,
  onFilterChange,
  onSubmit,
}) => {
  const { siteSlug = "" } = useParams();
  const { isOpen, onClose, onOpen } = useOpenState();
  const [funnelValue, setFunnelValue] = useState(filterValues.funnelId);

  const data = useLazyLoadQuery<DashboardFilterButton_Query>(query, {
    slug: siteSlug,
  });

  const sourceOptions = useMemo(
    () => [
      { label: "すべて", value: null },
      ...(data.sources.edges || []).map((edge) => {
        const node = edge?.node;
        if (!node) throw new Error("assertion failed");
        return { label: node.name, value: node.id };
      }),
    ],
    [data.sources.edges]
  );

  const funnelOptions = useMemo(() => {
    return (
      data.site.funnels?.edges?.map((edge) => {
        const node = edge?.node;
        if (!node) throw new Error("assertion failed");
        return { label: node.name, value: node.id };
      }) || []
    );
  }, [data.site.funnels]);

  const pageOptions = useMemo(
    () =>
      data.site.pages?.edges
        ?.map((edge) => {
          const node = edge?.node;
          if (!node) throw new Error("assertion failed");
          const pageName =
            node.kind === "FORM"
              ? node.name + `(${node.sortNumber - 1})`
              : node.name;
          return { label: pageName, value: node.id, funnelId: node.funnel.id };
        })
        .filter((v) => v.funnelId === funnelValue) || [],
    [data.site.pages, funnelValue]
  );

  const deviceOptions = useMemo(
    () => [{ label: "すべて", value: null }, ...GraphQLEnums.Device],
    []
  );

  const handleFilterChange = useCallback(
    (values: Partial<FormValues>) => () => {
      onFilterChange({ ...filterValues, ...values });
    },
    [filterValues, onFilterChange]
  );

  const handleSubmit = useCallback(
    (values: FormValues) => {
      onSubmit(values);
      onClose();
    },
    [onClose, onSubmit]
  );

  return (
    <HStack>
      {filterValues.funnelId && (
        <FilteredTag
          name="ファネル"
          value={filterValues.funnelId}
          options={funnelOptions}
          onClear={handleFilterChange({ funnelId: null })}
        />
      )}
      {filterValues.pageId && (
        <FilteredTag
          name="ページ"
          value={filterValues.pageId}
          options={pageOptions}
          onClear={handleFilterChange({ pageId: null })}
        />
      )}
      {filterValues.sourceId && (
        <FilteredTag
          name="流入元"
          value={filterValues.sourceId}
          options={sourceOptions}
          onClear={handleFilterChange({ sourceId: null })}
        />
      )}
      {filterValues.device && (
        <FilteredTag
          name="デバイス"
          value={filterValues.device}
          options={deviceOptions}
          onClear={handleFilterChange({ device: null })}
        />
      )}
      <IconButton
        label="フィルタ"
        size="24px"
        icon={<FilterAltOutlinedIcon htmlColor="#282828" />}
        isActive={
          Object.values(filterValues).some((value) => value != null) || isOpen
        }
        onClick={onOpen}
      />
      <Modal title="フィルタ" isOpen={isOpen} onClose={onClose}>
        <Formik<FormValues>
          enableReinitialize
          initialValues={filterValues}
          validationSchema={validationSchema}
          validateOnMount={false}
          validateOnChange={false}
          onSubmit={handleSubmit}
        >
          <Form>
            <VStack spacing="16px">
              <FormControl label="ファネル">
                <FormikSelectField
                  name="funnelId"
                  options={funnelOptions}
                  onSetStateAction={setFunnelValue}
                />
              </FormControl>
              <FormControl label="ページ">
                <FormikSelectField name="pageId" options={pageOptions} />
              </FormControl>
              <FormControl label="流入元">
                <FormikSelectField name="sourceId" options={sourceOptions} />
              </FormControl>
              <FormControl label="デバイス">
                <FormikSelectField name="device" options={deviceOptions} />
              </FormControl>
            </VStack>
            <FormikFormButtons onCancelClick={onClose} />
          </Form>
        </Formik>
      </Modal>
    </HStack>
  );
};
