import { HStack } from "@chakra-ui/react";
import { format } from "date-fns";
import * as R from "ramda";
import { FC, useContext, useMemo } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";

import { Device } from "~/src/__generated__/schema";
import { SiteListCard_Query } from "~/src/__relay_artifacts__/SiteListCard_Query.graphql";
import { DateRangeContext } from "~/src/components/features/global/HeaderNavigation/DateRangeField";
import {
  SiteCard,
  SiteCardProps,
} from "~/src/components/features/sites/SiteCard";

type SiteListCardQueryParams = {
  searchText: string;
  sourceId: string | null;
  device: Device | null;
};

export type Props = {
  onEditClick: SiteCardProps["onEditClick"];
  params: SiteListCardQueryParams;
};

const query = graphql`
  query SiteListCard_Query(
    $input: ReportAnalyzerInput!
    $searchTerm: String
    $count: Int
    $cursor: String
  ) {
    viewer {
      id
      sites(searchTerm: $searchTerm, first: $count, after: $cursor)
        @connection(key: "SiteListCard_sites") {
        edges {
          node {
            slug
            name
            url
            uniqueUserCount(input: $input)
            conversionUserCount(input: $input)
            conversionUserRate(input: $input)
            popups {
              totalCount
            }
            abtestScenarioPages {
              totalCount
            }
            chatbots {
              totalCount
            }
            funnels(orderBy: { field: UPDATED_AT, direction: DESC }, first: 1) {
              edges {
                node {
                  id
                  landingPage {
                    imageUrl
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const SiteListCard: FC<Props> = ({ params, onEditClick }) => {
  const {
    dateRange: [startOn, endOn],
  } = useContext(DateRangeContext);

  const { viewer } = useLazyLoadQuery<SiteListCard_Query>(query, {
    input: {
      startOn: format(startOn, "yyyy-MM-dd"),
      endOn: format(endOn, "yyyy-MM-dd"),
      sourceId: params.sourceId,
      device: params.device,
    },
    searchTerm: params.searchText,
  });

  const sites = useMemo(() => {
    const edges = viewer.sites.edges || [];
    return edges.map((edge) => {
      const node = edge?.node;
      if (!node) throw new Error("assertion error");
      return {
        slug: node.slug,
        name: node.name,
        url: node.url,
        uu: node.uniqueUserCount,
        cv: node.conversionUserCount,
        cvr: node.conversionUserRate,
        popup: node.popups.totalCount,
        abTest: node.abtestScenarioPages.totalCount,
        chatBot: node.chatbots.totalCount,
        imageUrl:
          node.funnels.edges?.[0]?.node?.landingPage?.imageUrl || undefined,
      };
    });
  }, [viewer.sites.edges]);

  const splitBySites = useMemo(() => {
    return R.splitEvery(4, sites);
  }, [sites]);

  return (
    <>
      {splitBySites.map((filteredSites, i) => (
        <HStack key={i} spacing="26px" mb="32px">
          {filteredSites.map((site) => (
            <SiteCard {...site} key={site.slug} onEditClick={onEditClick} />
          ))}
        </HStack>
      ))}
    </>
  );
};
