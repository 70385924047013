import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { EditOutlined as EditOutlinedIcon } from "@mui/icons-material";
import { memo, useCallback } from "react";
import { NavLink } from "react-router-dom";

import { IconButton } from "~/src/components/common/IconButton";
import { chakraFactory } from "~/src/lib/chakra-ui";
import { useHover } from "~/src/lib/hooks";

import { SiteTable } from "./SiteTable";

export type Props = {
  slug: string;
  name: string;
  url: string;
  uu: number;
  cv: number;
  cvr: number;
  popup: number;
  abTest: number;
  chatBot: number;
  imageUrl?: string;
  onEditClick: (siteSlug: Props["slug"]) => void;
};

const StyledImage = chakraFactory("div", {
  baseStyle: {
    position: "relative",
    width: "100%",
    height: "287px",
    background: "#FAFAFA",
    border: "1px solid #DADADA",
    boxSizing: "border-box",
    borderRadius: "5px",
  },
});

const StyledImageOverlayLink = chakraFactory(NavLink, {
  baseStyle: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#888888",
    opacity: 0.9,
    borderRadius: "5px",
  },
});

const StyledImageOverlayText = chakraFactory(Text, {
  baseStyle: {
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "29px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.04em",
    color: "#FFFFFF",
  },
});

const StyledTitleText = chakraFactory(Text, {
  baseStyle: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "23px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.04em",
    color: "#282828",
  },
});

const StyledUrlText = chakraFactory(Text, {
  baseStyle: {
    fontSize: "14px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.04em",
    color: "#282828",
    mb: "14px",
  },
});

export const SiteCard = memo<Props>(
  ({
    slug,
    name,
    url,
    uu,
    cv,
    cvr,
    popup,
    abTest,
    chatBot,
    imageUrl,
    onEditClick,
  }) => {
    const [ref, hovered] = useHover<HTMLDivElement>();

    const handleEditClick = useCallback(() => {
      onEditClick(slug);
    }, [slug, onEditClick]);

    return (
      <Box w="300px">
        <StyledImage ref={ref}>
          <Image src={imageUrl} w={"100%"} h={"100%"} />
          {hovered && (
            <StyledImageOverlayLink to={`/sites/${slug}`}>
              <StyledImageOverlayText>ダッシュボードへ</StyledImageOverlayText>
            </StyledImageOverlayLink>
          )}
        </StyledImage>
        <Flex
          mt="10px"
          mb="4px"
          justifyContent="space-between"
          alignItems="center"
        >
          <StyledTitleText>
            {name.length > 15 ? name.substring(0, 15) + "..." : name}
          </StyledTitleText>
          <IconButton
            label="サイト編集"
            size="16px"
            icon={<EditOutlinedIcon htmlColor="#282828" />}
            onClick={handleEditClick}
          />
        </Flex>
        <StyledUrlText>{url}</StyledUrlText>
        <SiteTable
          uu={uu}
          cv={cv}
          cvr={cvr}
          popup={popup}
          abTest={abTest}
          chatBot={chatBot}
        />
      </Box>
    );
  }
);
