import * as yup from "yup";

export type FormValues = {
  ipAddress: string;
  status: "ACTIVE" | "INACTIVE";
};

export const validationSchema = yup.object({
  ipAddress: yup.string().required(),
  status: yup.string().oneOf(["ACTIVE", "INACTIVE"]).required(),
});
