/**
 * @generated SignedSource<<c0d98cb150f8853956baf4022786d3df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ScriptTagSettingTabContainer_site$data = {
  readonly id: string;
  readonly embedTag: string | null;
  readonly robeeTag: string;
  readonly " $fragmentType": "ScriptTagSettingTabContainer_site";
};
export type ScriptTagSettingTabContainer_site = ScriptTagSettingTabContainer_site$data;
export type ScriptTagSettingTabContainer_site$key = {
  readonly " $data"?: ScriptTagSettingTabContainer_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"ScriptTagSettingTabContainer_site">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScriptTagSettingTabContainer_site",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "embedTag",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "robeeTag",
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "8274c146737b0147f09dd88136527298";

export default node;
