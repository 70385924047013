import { format } from "date-fns";
import * as React from "react";

import { DateRangeContext } from "~/src/components/features/global/HeaderNavigation/DateRangeField";

import { useQueryString } from ".";

type Params = {
  startOn?: string | null;
  endOn?: string | null;
  [key: string]: unknown;
};

const isSameDate = (date: Date, other: Date): boolean => {
  return (
    date.getFullYear() === other.getFullYear() &&
    date.getMonth() === other.getMonth() &&
    date.getDate() === other.getDate()
  );
};

export const useDateRangeSearchParams = () => {
  const today = React.useMemo(() => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate());
  }, []);

  const { dateRange } = React.useContext(DateRangeContext);
  const { params: currentParams, changeQueryString } = useQueryString<Params>();

  const defaultDuration = React.useMemo(() => {
    const sixDaysAgo = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 6
    );
    return { startOn: sixDaysAgo, endOn: today };
  }, [today]);

  const attachDateRangeParams = React.useCallback(() => {
    const { startOn, endOn } = defaultDuration;
    if (
      !isSameDate(dateRange[0], startOn) ||
      !isSameDate(dateRange[1], endOn)
    ) {
      const params = {
        ...currentParams,
        startOn: format(dateRange[0], "yyyy-MM-dd"),
        endOn: format(dateRange[1], "yyyy-MM-dd"),
      };

      changeQueryString(params, true);
    }
  }, [changeQueryString, currentParams, dateRange, defaultDuration]);

  return { defaultDuration, attachDateRangeParams };
};
