import { Flex } from "@chakra-ui/react";
import { format } from "date-fns";
import { useCallback, useContext, useState } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";

import { SiteReportOrder } from "~/src/__generated__/schema";
import { AgeRangeReportContainer_Query } from "~/src/__relay_artifacts__/AgeRangeReportContainer_Query.graphql";
import { DateRangeContext } from "~/src/components/features/global/HeaderNavigation/DateRangeField";

import { ReportFilterButton, ReportFilterValues } from "../ReportFilterButton";

import { AgeRangeReportGraph } from "./AgeRangeReportGraph";
import { AgeRangeReportTable } from "./AgeRangeReportTable";

const query = graphql`
  query AgeRangeReportContainer_Query($input: ReportInput!, $slug: String!) {
    site(slug: $slug) {
      ...AgeRangeReportGraph_site
      ...AgeRangeReportTable_site
    }
  }
`;

export const AgeRangeReportContainer = () => {
  const { siteSlug = "" } = useParams();

  const [sort, setSort] = useState<SiteReportOrder>({
    field: "UNIQUE_USER",
    direction: "DESC",
  });

  const [filterValues, setFilterValues] = useState<ReportFilterValues>({
    sourceId: null,
    device: null,
    funnelIds: [],
    pageKind: null,
  });

  const handleSortClick = useCallback((params) => setSort(params), []);

  const {
    dateRange: [startOn, endOn],
  } = useContext(DateRangeContext);

  const queryRef = useLazyLoadQuery<AgeRangeReportContainer_Query>(query, {
    slug: siteSlug,
    input: {
      startDate: format(startOn, "yyyy-MM-dd"),
      endDate: format(endOn, "yyyy-MM-dd"),
      sourceId: filterValues.sourceId,
      device: filterValues.device,
      funnelIds: filterValues.funnelIds,
      pageKind: filterValues.pageKind,
      orderBy: {
        direction: sort.direction,
        field: sort.field,
      },
    },
  });

  return (
    <>
      <Flex justifyContent={"end"} mt={4} mb={4}>
        <ReportFilterButton
          filterValues={filterValues}
          onSubmit={setFilterValues}
          onFilterChange={setFilterValues}
        />
      </Flex>
      <AgeRangeReportGraph siteRef={queryRef.site} />
      <AgeRangeReportTable
        siteRef={queryRef.site}
        onSortClick={handleSortClick}
      />
    </>
  );
};
