import { Box } from "@chakra-ui/react";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { ComponentProps, FC } from "react";
import ReactSelect, { components as selectComponents } from "react-select";

import { chakraFactory } from "~/src/lib/chakra-ui";

import { customStyles } from "./styles";

export type Props = ComponentProps<typeof ReactSelect>;

const multiSelectCustomStyles: NonNullable<Props["styles"]> = {
  ...customStyles,
  multiValue: (provided) => ({
    ...provided,
    height: "24px",
    borderRadius: "12px",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "#282828",
    fontSize: "10px",

    svg: {
      margin: "4px 0",
      width: "16px",
      height: "16px",
      color: "#979797",
    },

    "&:hover": {
      color: "inherit",
      backgroundColor: "inherit",
      borderRadius: "12px",
      cursor: "pointer",
    },
  }),
};

const components: NonNullable<Props["components"]> = {
  IndicatorSeparator: () => null,
  MultiValueRemove: (props) => (
    <selectComponents.MultiValueRemove {...props}>
      <CancelIcon />
    </selectComponents.MultiValueRemove>
  ),
};

const StyledWrapper = chakraFactory<Props>(Box, {
  baseStyle: (props) => ({
    ...(props.isDisabled && {
      cursor: "not-allowed",
      opacity: 0.4,
    }),
  }),
});

export const MultipleSelect: FC<Props> = (props) => (
  <StyledWrapper isDisabled={props.isDisabled}>
    <ReactSelect
      isMulti
      isSearchable={false}
      closeMenuOnSelect={false}
      styles={multiSelectCustomStyles}
      components={components}
      {...props}
    />
  </StyledWrapper>
);
