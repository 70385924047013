import { Box, Center, Checkbox, HStack, Text, VStack } from "@chakra-ui/react";
import { FC } from "react";

import { DefinitionTeam } from "~/src/components/common/DefinitionTeam";
import { HorizontalSeparator } from "~/src/components/common/Separator";

export type Props = {
  readonly linkUrl: string | null;
  readonly isInheritQuery: boolean;
  pageUrl: string;
};

export const ABTestPatternRedirectDetailContainer: FC<Props> = ({
  linkUrl,
  isInheritQuery,
  pageUrl,
}) => {
  return (
    <>
      <DefinitionTeam label="テストの種類">リダイレクト</DefinitionTeam>
      <HorizontalSeparator />
      <DefinitionTeam label="ターゲット">
        <VStack w="100%">
          {isInheritQuery ? (
            <HStack marginTop={"15px"} alignSelf={"baseline"}>
              <Checkbox isDisabled defaultIsChecked></Checkbox>
              <Box fontSize="13px">
                クエリパラメータを引き継ぐ(※ページの?以降の文字を引き継ぎます)
              </Box>
            </HStack>
          ) : (
            <HStack marginTop={"15px"} alignSelf={"baseline"}>
              <Checkbox isDisabled></Checkbox>
              <Box fontSize="13px">
                クエリパラメータを引き継ぐ(※ページの?以降の文字を引き継ぎます)
              </Box>
            </HStack>
          )}
          <HStack w="100%">
            <Center w="80%" alignSelf={"baseline"}>
              {pageUrl}
            </Center>
            <Center width="10%">
              <Text>{"→"}</Text>
            </Center>
            <Center w="80%" alignSelf={"baseline"}>
              {linkUrl}
            </Center>
          </HStack>
        </VStack>
      </DefinitionTeam>
    </>
  );
};
