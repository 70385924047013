import { FC } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";

import { NotificationEditPageContainer_Query } from "~/src/__relay_artifacts__/NotificationEditPageContainer_Query.graphql";

import { NotificationEditPage } from "./presentations";

const query = graphql`
  query NotificationEditPageContainer_Query($slug: String!) {
    notification(slug: $slug) {
      id
      ...NotificationEditPage_notification
    }
  }
`;

export const NotificationEditPageContainer: FC = () => {
  const { notificationSlug = "" } = useParams();

  const { notification } =
    useLazyLoadQuery<NotificationEditPageContainer_Query>(query, {
      slug: notificationSlug,
    });
  if (!notification) throw new Error("assertion failed");

  return <NotificationEditPage notificationRef={notification} />;
};
