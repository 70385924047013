/**
 * @generated SignedSource<<6a9570003cf9148b8c602a1d52f29c6d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AbtestScenarioPageKind = "DEFAULT" | "SCENARIO" | "REDIRECT" | "TEXT_REPLACE" | "%future added value";
export type DistributionStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type PatternContentJudgeStatus = "PENDING" | "APPROVED" | "REJECTED" | "%future added value";
export type UpdateAbtestScenarioPageInput = {
  clientMutationId?: string | null;
  abtestScenarioPageId: string;
  isInheritQuery?: number | null;
  judgeStatus?: PatternContentJudgeStatus | null;
  kind?: AbtestScenarioPageKind | null;
  linkUrl?: string | null;
  status?: DistributionStatus | null;
  title?: string | null;
  weight?: number | null;
};
export type UpdateOriginalAbtestScenarioPageInput = {
  clientMutationId?: string | null;
  abtestScenarioPageGroupId: string;
  status?: DistributionStatus | null;
  weight?: number | null;
};
export type ABTestPatternEditScreenContainer_Mutation$variables = {
  input: UpdateAbtestScenarioPageInput;
  originalInput: UpdateOriginalAbtestScenarioPageInput;
  withOriginal: boolean;
};
export type ABTestPatternEditScreenContainer_MutationVariables = ABTestPatternEditScreenContainer_Mutation$variables;
export type ABTestPatternEditScreenContainer_Mutation$data = {
  readonly updateOriginalAbtestScenarioPage?: {
    readonly abtestScenarioPage: {
      readonly id: string;
      readonly weight: number | null;
    };
  } | null;
  readonly updateAbtestScenarioPage: {
    readonly abtestScenarioPage: {
      readonly id: string;
      readonly slug: string;
      readonly " $fragmentSpreads": FragmentRefs<"ABTestPatternEditScreenContainer_abtestScenarioPage">;
    } | null;
  } | null;
};
export type ABTestPatternEditScreenContainer_MutationResponse = ABTestPatternEditScreenContainer_Mutation$data;
export type ABTestPatternEditScreenContainer_Mutation = {
  variables: ABTestPatternEditScreenContainer_MutationVariables;
  response: ABTestPatternEditScreenContainer_Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "originalInput"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "withOriginal"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "weight",
  "storageKey": null
},
v3 = {
  "condition": "withOriginal",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "input",
          "variableName": "originalInput"
        }
      ],
      "concreteType": "UpdateOriginalAbtestScenarioPagePayload",
      "kind": "LinkedField",
      "name": "updateOriginalAbtestScenarioPage",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AbtestScenarioPage",
          "kind": "LinkedField",
          "name": "abtestScenarioPage",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ]
},
v4 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v6 = [
  (v1/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ABTestPatternEditScreenContainer_Mutation",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "UpdateAbtestScenarioPagePayload",
        "kind": "LinkedField",
        "name": "updateAbtestScenarioPage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AbtestScenarioPage",
            "kind": "LinkedField",
            "name": "abtestScenarioPage",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v5/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ABTestPatternEditScreenContainer_abtestScenarioPage"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ABTestPatternEditScreenContainer_Mutation",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "UpdateAbtestScenarioPagePayload",
        "kind": "LinkedField",
        "name": "updateAbtestScenarioPage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AbtestScenarioPage",
            "kind": "LinkedField",
            "name": "abtestScenarioPage",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "kind",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "linkUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isInheritQuery",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "page",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AbtestScenarioPageContentConnection",
                "kind": "LinkedField",
                "name": "abtestScenarioPageContents",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AbtestScenarioPageContentEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v1/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "imageUrl",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "beforeSrc",
                                "storageKey": null
                              }
                            ],
                            "type": "AbtestScenarioPageContentScenario",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v6/*: any*/),
                            "type": "AbtestScenarioPageContentRedirect",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v1/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "targetType",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "actionType",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "targetValue",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "targetAttribute",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "replaceValue",
                                "storageKey": null
                              }
                            ],
                            "type": "AbtestScenarioPageContentTextReplace",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v6/*: any*/),
                            "type": "Node",
                            "abstractKey": "__isNode"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "98c9fe7cea6d29b89028154b888121b0",
    "id": null,
    "metadata": {},
    "name": "ABTestPatternEditScreenContainer_Mutation",
    "operationKind": "mutation",
    "text": "mutation ABTestPatternEditScreenContainer_Mutation(\n  $input: UpdateAbtestScenarioPageInput!\n  $originalInput: UpdateOriginalAbtestScenarioPageInput!\n  $withOriginal: Boolean!\n) {\n  updateOriginalAbtestScenarioPage(input: $originalInput) @include(if: $withOriginal) {\n    abtestScenarioPage {\n      id\n      weight\n    }\n  }\n  updateAbtestScenarioPage(input: $input) {\n    abtestScenarioPage {\n      id\n      slug\n      ...ABTestPatternEditScreenContainer_abtestScenarioPage\n    }\n  }\n}\n\nfragment ABTestPatternEditScreenContainer_abtestScenarioPage on AbtestScenarioPage {\n  id\n  title\n  slug\n  kind\n  weight\n  linkUrl\n  isInheritQuery\n  page {\n    id\n  }\n  abtestScenarioPageContents {\n    edges {\n      node {\n        __typename\n        ... on AbtestScenarioPageContentScenario {\n          id\n          imageUrl\n          beforeSrc\n        }\n        ... on AbtestScenarioPageContentRedirect {\n          id\n        }\n        ... on AbtestScenarioPageContentTextReplace {\n          id\n          targetType\n          actionType\n          targetValue\n          targetAttribute\n          replaceValue\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d58636da11b72d96eec8628aa836c0d5";

export default node;
