/**
 * @generated SignedSource<<3c42ab667d4ce9bb8b82dca4a186ceb3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type HeaderOnlyLayoutContainer_Query$variables = {};
export type HeaderOnlyLayoutContainer_QueryVariables = HeaderOnlyLayoutContainer_Query$variables;
export type HeaderOnlyLayoutContainer_Query$data = {
  readonly viewer: {
    readonly company: string | null;
    readonly email: string;
  };
};
export type HeaderOnlyLayoutContainer_QueryResponse = HeaderOnlyLayoutContainer_Query$data;
export type HeaderOnlyLayoutContainer_Query = {
  variables: HeaderOnlyLayoutContainer_QueryVariables;
  response: HeaderOnlyLayoutContainer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "company",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "HeaderOnlyLayoutContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "HeaderOnlyLayoutContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6125eddc0df40b84164331c6453557ec",
    "id": null,
    "metadata": {},
    "name": "HeaderOnlyLayoutContainer_Query",
    "operationKind": "query",
    "text": "query HeaderOnlyLayoutContainer_Query {\n  viewer {\n    company\n    email\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "79f1192fc11ce0b41a753172837d1dc9";

export default node;
