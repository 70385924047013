/**
 * @generated SignedSource<<4127ccde95bfbfea2992b4822390ed80>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeliverKind = "EQUAL" | "WEIGHT" | "%future added value";
export type UpdateDeliverableInput = {
  clientMutationId?: string | null;
  deliverableId: string;
  deliverKind: DeliverKind;
};
export type PopupGroupDetailPageContainer_updateDeliverKindMutation$variables = {
  input: UpdateDeliverableInput;
};
export type PopupGroupDetailPageContainer_updateDeliverKindMutationVariables = PopupGroupDetailPageContainer_updateDeliverKindMutation$variables;
export type PopupGroupDetailPageContainer_updateDeliverKindMutation$data = {
  readonly updateDeliverable: {
    readonly deliverable: {
      readonly id: string;
      readonly deliverKind: DeliverKind | null;
    };
  } | null;
};
export type PopupGroupDetailPageContainer_updateDeliverKindMutationResponse = PopupGroupDetailPageContainer_updateDeliverKindMutation$data;
export type PopupGroupDetailPageContainer_updateDeliverKindMutation = {
  variables: PopupGroupDetailPageContainer_updateDeliverKindMutationVariables;
  response: PopupGroupDetailPageContainer_updateDeliverKindMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deliverKind",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PopupGroupDetailPageContainer_updateDeliverKindMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateDeliverablePayload",
        "kind": "LinkedField",
        "name": "updateDeliverable",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "deliverable",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PopupGroupDetailPageContainer_updateDeliverKindMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateDeliverablePayload",
        "kind": "LinkedField",
        "name": "updateDeliverable",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "deliverable",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7951440848dedd413ad52775549243a6",
    "id": null,
    "metadata": {},
    "name": "PopupGroupDetailPageContainer_updateDeliverKindMutation",
    "operationKind": "mutation",
    "text": "mutation PopupGroupDetailPageContainer_updateDeliverKindMutation(\n  $input: UpdateDeliverableInput!\n) {\n  updateDeliverable(input: $input) {\n    deliverable {\n      __typename\n      id\n      deliverKind\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a7f909a41f9c30d5ce7a177bbe003b22";

export default node;
