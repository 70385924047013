import { Box, Flex, HStack, Image, Spacer, VStack } from "@chakra-ui/react";
import { FC, Suspense, useMemo } from "react";
import { graphql, usePaginationFragment } from "react-relay";
import { NavLink } from "react-router-dom";

import { PopupGroupSetDetailLinkedPopupGroupListTable_popupGroupSet$key } from "~/src/__relay_artifacts__/PopupGroupSetDetailLinkedPopupGroupListTable_popupGroupSet.graphql";
import { PopupGroupSetDetailLinkedPopupGroupListTableRefetchQuery } from "~/src/__relay_artifacts__/PopupGroupSetDetailLinkedPopupGroupListTableRefetchQuery.graphql";
import { OutlineButton } from "~/src/components/common/Button";
import { Modal } from "~/src/components/common/Modal";
import { PageLoading } from "~/src/components/common/PageLoading";
import {
  Table,
  TableDataColumn,
  TableDataRow,
  TableHeaderColumn,
  TableHeaderRow,
} from "~/src/components/common/tables/Table";
import {
  TablePagination,
  useTablePaginationProps,
} from "~/src/components/common/tables/TablePagination";
import { URLItem } from "~/src/components/common/URLItem";
import { PopupGroupLinkFormContainer } from "~/src/components/features/popupSet";
import { useOpenState } from "~/src/lib/hooks";

import { PopupGroupBulkEditFormContainer } from "./PopupGroupBulkEditFormContainer";
import { PopupGroupSetDetailLinkedPopupGroupListTableActions } from "./PopupGroupSetDetailLinkedPopupGroupListTableActions";

export type Props = {
  siteSlug: string;
  popupGroupSetRef: PopupGroupSetDetailLinkedPopupGroupListTable_popupGroupSet$key;
};

const fragment = graphql`
  fragment PopupGroupSetDetailLinkedPopupGroupListTable_popupGroupSet on PopupGroupSet
  @refetchable(
    queryName: "PopupGroupSetDetailLinkedPopupGroupListTableRefetchQuery"
  )
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 120 }
    cursor: { type: "String" }
  ) {
    id
    popupGroupSettings(first: $count, after: $cursor)
      @connection(
        key: "PopupGroupSetDetailLinkedPopupGroupListTable_popupGroupSet_popupGroupSettings"
      ) {
      __id
      totalCount
      edges {
        node {
          id
          popupGroup {
            id
            slug
            name
            status
            thumbnailImageUrl
            judgeStatus
            page {
              id
              kind
              name
              url
              sortNumber
              funnel {
                id
                name
                siteUrl
              }
            }
            ...PopupGroupBulkEditFormContainer_popupGroup
          }
        }
      }
    }
  }
`;

export const PopupGroupSetDetailLinkedPopupGroupListTable: FC<Props> = ({
  siteSlug,
  popupGroupSetRef,
}) => {
  const popupLinkModalOpenState = useOpenState();
  const popupBulkEditModalOpenState = useOpenState();

  const {
    data: popupGroupSet,
    hasNext,
    loadNext,
    refetch,
  } = usePaginationFragment<
    PopupGroupSetDetailLinkedPopupGroupListTableRefetchQuery,
    PopupGroupSetDetailLinkedPopupGroupListTable_popupGroupSet$key
  >(fragment, popupGroupSetRef);
  const { tablePaginationProps } = useTablePaginationProps({
    hasNext,
    loadNext,
    refetch,
    totalCount: popupGroupSet.popupGroupSettings?.totalCount || 0,
  });

  const popupGroupSettings = useMemo(() => {
    return (
      popupGroupSet.popupGroupSettings.edges?.map((edge) => {
        const node = edge?.node;
        if (!node) throw new Error("assertion failed");
        return node;
      }) || []
    );
  }, [popupGroupSet.popupGroupSettings]);

  const popupGroupIds = useMemo(() => {
    return popupGroupSettings.map(
      (popupGroupSetting) => popupGroupSetting.popupGroup.id
    );
  }, [popupGroupSettings]);

  const popupGroups = useMemo(() => {
    return popupGroupSettings.map((popupGroupSetting) => {
      return {
        id: popupGroupSetting.popupGroup.id,
        pageId: popupGroupSetting.popupGroup.page.id,
        status: popupGroupSetting.popupGroup.status,
      };
    });
  }, [popupGroupSettings]);

  return (
    <>
      <Flex alignItems="flex-end" marginTop={"8"}>
        <Box color="#282828" fontWeight="bold">
          設定しているポップアップ一覧
        </Box>
        <Spacer />
        <HStack spacing={3}>
          <OutlineButton onClick={popupLinkModalOpenState.onOpen}>
            ポップアップの紐付け
          </OutlineButton>
          <OutlineButton
            onClick={popupBulkEditModalOpenState.onOpen}
            isDisabled={popupGroupSettings.length < 1}
          >
            ポップアップの一括編集
          </OutlineButton>
          <NavLink
            to={`/sites/${siteSlug}/popup_group_sets/${popupGroupSet.id}/popup_sets`}
          >
            <OutlineButton isDisabled={popupGroupSettings.length === 0}>
              ポップアップパターンセット
            </OutlineButton>
          </NavLink>
        </HStack>
      </Flex>

      <Table>
        <TableHeaderRow>
          <TableHeaderColumn>ポップアップ名</TableHeaderColumn>
          <TableHeaderColumn>ステータス</TableHeaderColumn>
          <TableHeaderColumn>画像</TableHeaderColumn>
          <TableHeaderColumn>実施ファネル</TableHeaderColumn>
          <TableHeaderColumn>実施ページ</TableHeaderColumn>
          <TableHeaderColumn>アクション</TableHeaderColumn>
        </TableHeaderRow>
        {popupGroupSettings.map((popupGroupSetting, index) => (
          <TableDataRow height={"80px"} key={index}>
            <TableDataColumn
              type="text"
              isLink
              data={
                <NavLink
                  to={`/sites/${siteSlug}/popup_groups/${popupGroupSetting.popupGroup.slug}`}
                >
                  {popupGroupSetting.popupGroup.name}
                </NavLink>
              }
            />
            <TableDataColumn
              type="text"
              data={
                <VStack spacing={"0"}>
                  <Box
                    borderRadius={"xl"}
                    p={1}
                    align="center"
                    color={"white"}
                    bg={
                      popupGroupSetting.popupGroup.status === "ACTIVE"
                        ? "#4CAF50"
                        : "#979797"
                    }
                  >
                    {popupGroupSetting.popupGroup.status === "ACTIVE"
                      ? "実施中"
                      : "停止中"}
                  </Box>
                  <Box
                    borderRadius={"xl"}
                    p={1}
                    align="center"
                    color={"white"}
                    bg={
                      popupGroupSetting.popupGroup.judgeStatus === "APPROVED"
                        ? "#4CAF50"
                        : "#979797"
                    }
                  >
                    {popupGroupSetting.popupGroup.judgeStatus === "APPROVED"
                      ? "承認"
                      : "未承認"}
                  </Box>
                </VStack>
              }
            />
            <TableDataColumn
              type="text"
              data={
                popupGroupSetting.popupGroup.thumbnailImageUrl && (
                  <Image
                    src={popupGroupSetting.popupGroup.thumbnailImageUrl}
                    boxSize={"60px"}
                    fit={"contain"}
                  />
                )
              }
            />
            <TableDataColumn
              type="text"
              data={
                <URLItem
                  url={popupGroupSetting.popupGroup.page.funnel.siteUrl}
                  name={popupGroupSetting.popupGroup.page.funnel.name}
                />
              }
            />

            <TableDataColumn
              type="text"
              data={
                <URLItem
                  url={popupGroupSetting.popupGroup.page.url}
                  name={
                    popupGroupSetting.popupGroup.page.kind === "FORM"
                      ? `${popupGroupSetting.popupGroup.page.name}(${
                          popupGroupSetting.popupGroup.page.sortNumber - 1
                        })`
                      : popupGroupSetting.popupGroup.page.name
                  }
                />
              }
            />

            <TableDataColumn
              type="actions"
              data={
                <PopupGroupSetDetailLinkedPopupGroupListTableActions
                  popupGroupName={popupGroupSetting.popupGroup.name}
                  popupGroupSettingId={popupGroupSetting.id}
                  connectionId={popupGroupSet.popupGroupSettings.__id}
                />
              }
            />
          </TableDataRow>
        ))}
      </Table>
      <TablePagination {...tablePaginationProps} />

      <Modal
        title="ポップアップの紐付け"
        isOpen={popupLinkModalOpenState.isOpen}
        onClose={popupLinkModalOpenState.onClose}
        width={"1200px"}
      >
        <Suspense fallback={<PageLoading />}>
          <PopupGroupLinkFormContainer
            onClose={popupLinkModalOpenState.onClose}
            siteSlug={siteSlug}
            createdPopupGroupIds={popupGroupIds}
            popupGroupSetId={popupGroupSet.id}
          />
        </Suspense>
      </Modal>
      <Modal
        title={"ポップアップの一括編集"}
        isOpen={popupBulkEditModalOpenState.isOpen}
        onClose={popupBulkEditModalOpenState.onClose}
      >
        <Suspense fallback={<PageLoading />}>
          {popupGroupSettings.length > 0 && (
            <PopupGroupBulkEditFormContainer
              siteSlug={siteSlug}
              popupGroupRef={popupGroupSettings[0].popupGroup}
              targetPopupGroups={popupGroups}
              onClose={popupBulkEditModalOpenState.onClose}
            />
          )}
        </Suspense>
      </Modal>
    </>
  );
};
