import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { AbtestReportContainer } from "~/src/components/features/report/AbtestReport";
import { AgeRangeReportContainer } from "~/src/components/features/report/AgeRangeReport";
import { ChatbotReportContainer } from "~/src/components/features/report/ChatbotReport";
import { DailyReportContainer } from "~/src/components/features/report/DailyReport";
import { FunnelReportContainer } from "~/src/components/features/report/FunnelReport";
import { GenderReportContainer } from "~/src/components/features/report/GenderReport";
import { MonthlyReportContainer } from "~/src/components/features/report/MonthlyReport";
import { PopupReportContainer } from "~/src/components/features/report/PopupReport";
import { PrefectureReportContainer } from "~/src/components/features/report/PrefectureReport";
import { PublisherDetailReportContainer } from "~/src/components/features/report/PublisherDetailReport";
import { PublisherReportContainer } from "~/src/components/features/report/PublisherReport";

import { ReportPage } from "./presentations/ReportPage";

export const tabs = [
  { id: "funnel", label: "ファネル" },
  { id: "popup", label: "ポップアップ" },
  { id: "abtest", label: "A/Bテスト" },
  { id: "chatbot", label: "チャットボット" },
  { id: "daily", label: "日別" },
  { id: "monthly", label: "月別" },
  { id: "publisher", label: "媒体別" },
  { id: "publisher_detail", label: "媒体別詳細" },
  { id: "prefecture", label: "都道府県" },
  { id: "age_range", label: "年齢別" },
  { id: "gender", label: "性別" },
] as const;

type Kind =
  | "funnel"
  | "popup"
  | "abtest"
  | "chatbot"
  | "daily"
  | "monthly"
  | "publisher"
  | "publisher_detail"
  | "prefecture"
  | "age_range"
  | "gender";

export const ReportPageContainer = () => {
  const navigate = useNavigate();
  const { siteSlug = "", kind = "" } = useParams();
  const currentTabId = (kind || "funnel") as Kind;

  const handleTabChange = useCallback(
    (kind: string) => {
      if (kind === "funnel") {
        navigate(`/sites/${siteSlug}/reports`);
      } else {
        navigate(`/sites/${siteSlug}/reports/${kind}`);
      }
    },
    [navigate, siteSlug]
  );

  return (
    <ReportPage
      currentTabId={currentTabId}
      tabs={tabs}
      onTabChange={handleTabChange}
    >
      {currentTabId === "funnel" && <FunnelReportContainer />}
      {currentTabId === "popup" && <PopupReportContainer />}
      {currentTabId === "abtest" && <AbtestReportContainer />}
      {currentTabId === "chatbot" && <ChatbotReportContainer />}
      {currentTabId === "daily" && <DailyReportContainer />}
      {currentTabId === "monthly" && <MonthlyReportContainer />}
      {currentTabId === "publisher" && <PublisherReportContainer />}
      {currentTabId === "publisher_detail" && (
        <PublisherDetailReportContainer />
      )}
      {currentTabId === "prefecture" && <PrefectureReportContainer />}
      {currentTabId === "age_range" && <AgeRangeReportContainer />}
      {currentTabId === "gender" && <GenderReportContainer />}
    </ReportPage>
  );
};
