import { useToast } from "@chakra-ui/react";
import { format } from "date-fns";
import { FC, useCallback, useContext } from "react";
import { ConnectionHandler, graphql, useLazyLoadQuery } from "react-relay";

import { SiteCreateFormContainer_Mutation } from "~/src/__relay_artifacts__/SiteCreateFormContainer_Mutation.graphql";
import { SiteCreateFormContainer_Query } from "~/src/__relay_artifacts__/SiteCreateFormContainer_Query.graphql";
import { DateRangeContext } from "~/src/components/features/global/HeaderNavigation/DateRangeField";
import { useFormErrorHandler } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";

import { SiteCreateForm, SiteCreateFormProps } from "./presentations";

export type Props = {
  onClose: () => void;
};

const query = graphql`
  query SiteCreateFormContainer_Query {
    viewer {
      id
      role
    }
    viewers {
      edges {
        node {
          id
          name
          company
          email
        }
      }
    }
  }
`;

const mutation = graphql`
  mutation SiteCreateFormContainer_Mutation(
    $connections: [ID!]!
    $input: AddSiteInput!
    $reportInput: ReportAnalyzerInput!
  ) {
    addSite(input: $input) {
      site @appendNode(connections: $connections, edgeTypeName: "SiteEdge") {
        id
        name
        url
        slug
        uniqueUserCount(input: $reportInput)
        conversionUserCount(input: $reportInput)
        conversionUserRate(input: $reportInput)
        popups {
          totalCount
        }
        abtestScenarioPages {
          totalCount
        }
        chatbots {
          totalCount
        }
        funnels(orderBy: { field: UPDATED_AT, direction: DESC }, first: 1) {
          edges {
            node {
              id
              landingPage {
                imageUrl
              }
            }
          }
        }
      }
    }
  }
`;

export const SiteCreateFormContainer: FC<Props> = ({ onClose }) => {
  const toast = useToast();
  const { onFormError } = useFormErrorHandler();
  const {
    dateRange: [startOn, endOn],
  } = useContext(DateRangeContext);

  const { viewer } = useLazyLoadQuery<SiteCreateFormContainer_Query>(query, {});

  const createSiteMutation =
    useMutationCommit<SiteCreateFormContainer_Mutation>(mutation);

  const handleSubmit = useCallback<SiteCreateFormProps["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const connectionId = ConnectionHandler.getConnectionID(
          `client:${viewer.id}`,
          "SiteListCard_sites",
          { searchTerm: "" }
        );

        const res = await createSiteMutation({
          variables: {
            input: {
              name: values.name,
              url: values.url,
              userId: values.userId,
            },
            connections: [connectionId],
            reportInput: {
              // FIXME: period以外のargumentを反映(SiteFilterなどcomponentをいくつか見直す必要あり)
              startOn: format(startOn, "yyyy-MM-dd"),
              endOn: format(endOn, "yyyy-MM-dd"),
            },
          },
        });
        if (!res.addSite?.site) throw new Error("assertion failed");
        toast({ title: "サイトを作成しました", status: "success" });
        onClose();
      } catch (err) {
        onFormError(err, setErrors);
      }
    },
    [createSiteMutation, onFormError, toast, onClose, viewer.id, startOn, endOn]
  );

  return (
    <SiteCreateForm
      onSubmit={handleSubmit}
      onCancelClick={onClose}
      initialValues={{
        name: "",
        url: "",
        userId: viewer.id,
      }}
      role={viewer.role}
    />
  );
};
