import { Flex } from "@chakra-ui/react";
import { FC } from "react";

import { GhostButton, SolidButton } from "~/src/components/common/Button";

export type Props = {
  disabled: boolean;
  loading: boolean;
  onCancelClick: () => void;
};

export const FormButtons: FC<Props> = ({
  disabled,
  loading,
  onCancelClick,
}) => (
  <Flex mt="40px" justifyContent="flex-end">
    <GhostButton mr="8px" onClick={onCancelClick}>
      戻る
    </GhostButton>
    <SolidButton type="submit" isDisabled={disabled} isLoading={loading}>
      保存
    </SolidButton>
  </Flex>
);
