/**
 * @generated SignedSource<<370d836bd127b2f3655aca5261fd974c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Device = "MOBILE" | "TABLET" | "PC" | "%future added value";
export type OrderDirection = "ASC" | "DESC" | "%future added value";
export type PageKind = "LANDING" | "FORM" | "CONFIRM" | "THANKS" | "%future added value";
export type SiteReportOrderField = "UNIQUE_USER" | "CONVERSION" | "CVR" | "LP_DROP_RATE" | "LP_SCROLL_RATE" | "LP_AVERAGE_STAY_TIME" | "FORM_DROP_RATE" | "MOBILE_RATE" | "TABLET_RATE" | "PC_RATE" | "DEVICE" | "PAGE_VIEW_USER" | "USE_CHATBOT_USER" | "USE_CHATBOT_USER_RATE" | "CHATBOT_CVR" | "POPUP_READY_USER" | "CLOSE_POPUP" | "CLICK_POPUP" | "POPUP_CLICK_CONVERSION" | "POPUP_IMPRESSION_CONVERSION" | "POPUP_READY_CONVERSION" | "POPUP_DROP" | "POPUP_DROP_CONVERSION" | "POPUP_CONVERSION" | "POPUP_CTR" | "POPUP_CVR" | "POPUP_CLICK_CVR" | "POPUP_IMPRESSION_CVR" | "POPUP_DROP_CVR" | "%future added value";
export type ReportInput = {
  startDate?: string | null;
  endDate?: string | null;
  orderBy?: SiteReportOrder | null;
  device?: Device | null;
  sourceId?: string | null;
  funnelIds?: ReadonlyArray<string> | null;
  pageKind?: PageKind | null;
};
export type SiteReportOrder = {
  field: SiteReportOrderField;
  direction: OrderDirection;
};
export type FunnelReportContainer_Query$variables = {
  input: ReportInput;
  slug: string;
};
export type FunnelReportContainer_QueryVariables = FunnelReportContainer_Query$variables;
export type FunnelReportContainer_Query$data = {
  readonly site: {
    readonly funnels: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string;
        } | null;
      } | null> | null;
    };
    readonly " $fragmentSpreads": FragmentRefs<"FunnelReportGraph_site" | "FunnelReportTable_site">;
  };
};
export type FunnelReportContainer_QueryResponse = FunnelReportContainer_Query$data;
export type FunnelReportContainer_Query = {
  variables: FunnelReportContainer_QueryVariables;
  response: FunnelReportContainer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "FunnelConnection",
  "kind": "LinkedField",
  "name": "funnels",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FunnelEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Funnel",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FunnelReportContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "FunnelReportGraph_site"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "FunnelReportTable_site"
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FunnelReportContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "input",
                "variableName": "input"
              }
            ],
            "kind": "ScalarField",
            "name": "reportFunnel",
            "storageKey": null
          },
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "78c259d8c178a03eb4bc53d669b515eb",
    "id": null,
    "metadata": {},
    "name": "FunnelReportContainer_Query",
    "operationKind": "query",
    "text": "query FunnelReportContainer_Query(\n  $input: ReportInput!\n  $slug: String!\n) {\n  site(slug: $slug) {\n    ...FunnelReportGraph_site\n    ...FunnelReportTable_site\n    funnels {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment FunnelReportGraph_site on Site {\n  reportFunnel(input: $input)\n}\n\nfragment FunnelReportTable_site on Site {\n  reportFunnel(input: $input)\n}\n"
  }
};
})();

(node as any).hash = "baa921719d7dc6f703bd77b69a8b327a";

export default node;
