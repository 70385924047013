import { FC } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";

import { SettingsPageContainer_Query } from "~/src/__relay_artifacts__/SettingsPageContainer_Query.graphql";
import { ExcludedIPListTabContainer } from "~/src/components/features/settings/ExcludedIPListTab";
import { FunnelListTabContainer } from "~/src/components/features/settings/FunnelListTab";
import { ScriptTagSettingTabContainer } from "~/src/components/features/settings/ScriptTagSettingTab";
import { SourceListTabContainer } from "~/src/components/features/settings/SourceListTab";
import { useTabs } from "~/src/lib/hooks";
import { useSettingsPageParams } from "~/src/routes/hooks";

import { SettingsPage } from "./presentations";

const query = graphql`
  query SettingsPageContainer_Query($siteSlug: String!) {
    viewer {
      role
      ...FunnelListTabContainer_user
    }
    site(slug: $siteSlug) {
      ...FunnelListTabContainer_site
      ...ExcludedIPListTabContainer_site
      ...ScriptTagSettingTabContainer_site
    }
  }
`;

export const adminTabs = [
  { id: "funnels", label: "ファネル" },
  { id: "source", label: "流入元" },
  { id: "excluded_ip", label: "除外IP" },
  { id: "script_tag", label: "タグ" },
] as const;

export const clientTabs = [
  { id: "funnels", label: "ファネル" },
  { id: "source", label: "流入元" },
  { id: "excluded_ip", label: "除外IP" },
] as const;

export const SettingsPageContainer: FC = () => {
  const { siteSlug } = useSettingsPageParams();
  const { site, viewer } = useLazyLoadQuery<SettingsPageContainer_Query>(
    query,
    {
      siteSlug,
    }
  );
  const isAdmin = viewer.role === "ADMIN" || viewer.role === "SUPER";
  const { currentTabId, onTabChange } = useTabs(
    isAdmin ? adminTabs : clientTabs
  );

  return (
    <SettingsPage
      tabs={isAdmin ? adminTabs : clientTabs}
      currentTabId={currentTabId}
      onTabChange={onTabChange}
    >
      {currentTabId === "funnels" && (
        <FunnelListTabContainer siteRef={site} userRef={viewer} />
      )}
      {currentTabId === "source" && (
        <SourceListTabContainer siteSlug={siteSlug} />
      )}
      {currentTabId === "excluded_ip" && (
        <ExcludedIPListTabContainer siteRef={site} />
      )}
      {currentTabId === "script_tag" && (
        <ScriptTagSettingTabContainer siteRef={site} />
      )}
    </SettingsPage>
  );
};
