import {
  HStack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
} from "@chakra-ui/react";
import { FC, useCallback, useMemo, useState } from "react";

import { GhostButton, SolidButton } from "~/src/components/common/Button";
import { TableHeaderCheckboxColumn } from "~/src/components/common/tables/Table";

import { Popup, PopupLinkFormListItem } from "./PopupLinkFormListItem";

export type Props = {
  popups: Popup[];
  onClose: () => void;
  onClick: (ids: string[]) => Promise<void>;
};

export const PopupLinkFormList: FC<Props> = ({ popups, onClose, onClick }) => {
  const popupIds = useMemo(() => {
    return popups.map((popup) => popup.id);
  }, [popups]);

  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const handleAllCheck = useCallback(() => {
    const isAllIdsChecked = selectedIds.length === popupIds.length;
    const newSelectedIds = isAllIdsChecked ? [] : popupIds;
    setSelectedIds(newSelectedIds);
  }, [popupIds, setSelectedIds, selectedIds.length]);

  const handleCheck = useCallback(
    (id: string) => {
      const newSelectedIds = selectedIds.includes(id)
        ? selectedIds.filter((i) => i !== id)
        : [...selectedIds, id];
      setSelectedIds(newSelectedIds);
    },
    [selectedIds, setSelectedIds]
  );

  const handleClick = useCallback(() => {
    onClick(selectedIds);
  }, [selectedIds, onClick]);

  return (
    <>
      <HStack justifyContent={"flex-end"} mb={"2"}>
        <GhostButton onClick={onClose} mr="4">
          戻る
        </GhostButton>
        <SolidButton
          onClick={handleClick}
          isDisabled={selectedIds.length === 0}
        >
          保存
        </SolidButton>
      </HStack>
      <TableContainer>
        <Table size="sm">
          <Thead>
            <Th>
              <TableHeaderCheckboxColumn
                isChecked={selectedIds.length === popupIds.length}
                onChange={handleAllCheck}
              />
            </Th>
            <Th>名前</Th>
            <Th>ステータス</Th>
            <Th>画像</Th>
            <Th>実施ファネル</Th>
            <Th>実施ページ</Th>
          </Thead>
          <Tbody>
            {popups.map((popup) => (
              <PopupLinkFormListItem
                key={popup.id}
                popup={popup}
                isChecked={selectedIds.includes(popup.id)}
                onCheck={handleCheck}
              />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};
