/**
 * @generated SignedSource<<be37286b6fe91a72bad6cba04c261f79>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DistributionStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type ActivateDistributionStatusableInput = {
  clientMutationId?: string | null;
  distributionStatusableId: string;
};
export type DistributionStatusSwitch_ActivateMutation$variables = {
  input: ActivateDistributionStatusableInput;
};
export type DistributionStatusSwitch_ActivateMutationVariables = DistributionStatusSwitch_ActivateMutation$variables;
export type DistributionStatusSwitch_ActivateMutation$data = {
  readonly updateStatus: {
    readonly distributionStatusable: {
      readonly status: DistributionStatus;
    };
  } | null;
};
export type DistributionStatusSwitch_ActivateMutationResponse = DistributionStatusSwitch_ActivateMutation$data;
export type DistributionStatusSwitch_ActivateMutation = {
  variables: DistributionStatusSwitch_ActivateMutationVariables;
  response: DistributionStatusSwitch_ActivateMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DistributionStatusSwitch_ActivateMutation",
    "selections": [
      {
        "alias": "updateStatus",
        "args": (v1/*: any*/),
        "concreteType": "ActivateDistributionStatusablePayload",
        "kind": "LinkedField",
        "name": "activateDistributionStatusable",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "distributionStatusable",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DistributionStatusSwitch_ActivateMutation",
    "selections": [
      {
        "alias": "updateStatus",
        "args": (v1/*: any*/),
        "concreteType": "ActivateDistributionStatusablePayload",
        "kind": "LinkedField",
        "name": "activateDistributionStatusable",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "distributionStatusable",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "555874654135868c52b1444f82ee02b2",
    "id": null,
    "metadata": {},
    "name": "DistributionStatusSwitch_ActivateMutation",
    "operationKind": "mutation",
    "text": "mutation DistributionStatusSwitch_ActivateMutation(\n  $input: ActivateDistributionStatusableInput!\n) {\n  updateStatus: activateDistributionStatusable(input: $input) {\n    distributionStatusable {\n      __typename\n      status\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "25e7a0251b63ef03a69450386c1ec1d5";

export default node;
