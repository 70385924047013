/**
 * @generated SignedSource<<b7322e5741367004e8b1e39cdf3a4a63>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountListPageContainer_Query$variables = {
  searchTerm?: string | null;
};
export type AccountListPageContainer_QueryVariables = AccountListPageContainer_Query$variables;
export type AccountListPageContainer_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AccountListPage_viewers">;
};
export type AccountListPageContainer_QueryResponse = AccountListPageContainer_Query$data;
export type AccountListPageContainer_Query = {
  variables: AccountListPageContainer_QueryVariables;
  response: AccountListPageContainer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchTerm"
  }
],
v1 = {
  "kind": "Variable",
  "name": "searchTerm",
  "variableName": "searchTerm"
},
v2 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 120
  },
  (v1/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountListPageContainer_Query",
    "selections": [
      {
        "args": [
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "AccountListPage_viewers"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountListPageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "UserConnection",
        "kind": "LinkedField",
        "name": "viewers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "company",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tagScript",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "filters": [
          "searchTerm"
        ],
        "handle": "connection",
        "key": "AccountListPage_viewers",
        "kind": "LinkedHandle",
        "name": "viewers"
      }
    ]
  },
  "params": {
    "cacheID": "0f4f4d0064efada3cf6eb22aa20b65d2",
    "id": null,
    "metadata": {},
    "name": "AccountListPageContainer_Query",
    "operationKind": "query",
    "text": "query AccountListPageContainer_Query(\n  $searchTerm: String\n) {\n  ...AccountListPage_viewers_1CW4ID\n}\n\nfragment AccountListPage_viewers_1CW4ID on Query {\n  viewers(first: 120, searchTerm: $searchTerm) {\n    totalCount\n    edges {\n      node {\n        id\n        name\n        email\n        company\n        tagScript\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "91f52a8bbf412ff32ecb45ad5419c9fb";

export default node;
