import { includes } from "ramda";
import { FC } from "react";

import {
  AbtestReportOrderValue,
  PopupReportOrderValue,
  SiteReportOrderField,
} from "~/src/__generated__/schema";
import {
  SortParams,
  TableHeaderColumn,
  TableHeaderHorizontalStickyColumn,
  TableHeaderRow,
} from "~/src/components/common/tables/Table";

import { HeaderOption } from "../utils/types";

export type Prop = {
  disableColumns: number[];
  onSortClick: <F, D>(params: SortParams<F, D>) => void;
  headerOptions: HeaderOption<
    SiteReportOrderField | AbtestReportOrderValue | PopupReportOrderValue
  >[];
};

export const ReportTableHeaderRow: FC<Prop> = ({
  disableColumns,
  onSortClick,
  headerOptions,
}) => {
  return (
    <TableHeaderRow>
      {headerOptions
        .filter((_, index) => !includes(index, disableColumns))
        .map((header, i) =>
          header.orderField ? (
            i === 0 ? (
              <TableHeaderHorizontalStickyColumn
                key={i}
                field={header.orderField}
                onSortClick={onSortClick}
              >
                {header.label}
              </TableHeaderHorizontalStickyColumn>
            ) : (
              <TableHeaderColumn
                key={i}
                field={header.orderField}
                onSortClick={onSortClick}
              >
                {header.label}
              </TableHeaderColumn>
            )
          ) : i === 0 ? (
            <TableHeaderHorizontalStickyColumn key={i}>
              {header.label}
            </TableHeaderHorizontalStickyColumn>
          ) : (
            <TableHeaderColumn key={i}>{header.label}</TableHeaderColumn>
          )
        )}
    </TableHeaderRow>
  );
};
