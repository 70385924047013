import { SystemStyleObject } from "@chakra-ui/react";

export const buttonStyles: SystemStyleObject = {
  minWidth: "75px",
  minHeight: "40px",
  fontWeight: "500",
  fontSize: "13px",
  lineHeight: "19px",
  display: "flex",
  alignItems: "center",
  letterSpacing: "0.04em",
};
