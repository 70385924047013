/**
 * @generated SignedSource<<60d93c358669aa71eab6f7bcfb56d853>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExcludedIPEditPageContainer_Query$variables = {
  slug: string;
};
export type ExcludedIPEditPageContainer_QueryVariables = ExcludedIPEditPageContainer_Query$variables;
export type ExcludedIPEditPageContainer_Query$data = {
  readonly excludedIpAddress: {
    readonly " $fragmentSpreads": FragmentRefs<"ExcludedIPEditScreenContainer_excludedIpAddress">;
  } | null;
};
export type ExcludedIPEditPageContainer_QueryResponse = ExcludedIPEditPageContainer_Query$data;
export type ExcludedIPEditPageContainer_Query = {
  variables: ExcludedIPEditPageContainer_QueryVariables;
  response: ExcludedIPEditPageContainer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExcludedIPEditPageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ExcludedIpAddress",
        "kind": "LinkedField",
        "name": "excludedIpAddress",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ExcludedIPEditScreenContainer_excludedIpAddress"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExcludedIPEditPageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ExcludedIpAddress",
        "kind": "LinkedField",
        "name": "excludedIpAddress",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ipAddress",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8ed9b284f5c63a129161e00ed37a4109",
    "id": null,
    "metadata": {},
    "name": "ExcludedIPEditPageContainer_Query",
    "operationKind": "query",
    "text": "query ExcludedIPEditPageContainer_Query(\n  $slug: String!\n) {\n  excludedIpAddress(slug: $slug) {\n    ...ExcludedIPEditScreenContainer_excludedIpAddress\n    id\n  }\n}\n\nfragment ExcludedIPEditScreenContainer_excludedIpAddress on ExcludedIpAddress {\n  id\n  ipAddress\n  status\n}\n"
  }
};
})();

(node as any).hash = "6846e3792c5561fb7664f4691b7b516b";

export default node;
