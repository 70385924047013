import "~/src/lib/yup/setLocale";

import { FC } from "react";

import { useUser } from "~/src/contexts";
import { RelayEnvironmentProvider } from "~/src/lib/react-relay";

export const FirebaseRelayAdapter: FC = ({ children }) => {
  const userContext = useUser();

  if (userContext === null) throw new Error("user context must be set.");
  const { user } = userContext;

  if (user === null) return <>{children}</>;
  const { idToken } = user;

  return (
    <RelayEnvironmentProvider apiKey={idToken}>
      {children}
    </RelayEnvironmentProvider>
  );
};
