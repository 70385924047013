import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { Suspense } from "react";

import { PageLoading } from "~/src/components/common/PageLoading";

import { ABTestDefaultByDateReportContainer } from "./ABTestDefaultByDateReportContainer";
import { ABTestDefaultReportContainer } from "./ABTestDefaultReportContainer";
import { ABTestPatternNameByDateReportContainer } from "./ABTestPatternNameByDateReportContainer";
import { ABTestPatternNameReportContainer } from "./ABTestPatternNameReportContainer";

export const AbtestReportContainer = () => {
  return (
    <Tabs isFitted variant="enclosed">
      <TabList>
        <Tab>通常レポート</Tab>
        <Tab>通常レポート(日付別)</Tab>
        <Tab>パターン名別レポート</Tab>
        <Tab>パターン名別レポート(日付別)</Tab>
      </TabList>

      <TabPanels>
        <TabPanel>
          <Suspense fallback={<PageLoading />}>
            <ABTestDefaultReportContainer />
          </Suspense>
        </TabPanel>
        <TabPanel>
          <Suspense fallback={<PageLoading />}>
            <ABTestDefaultByDateReportContainer />
          </Suspense>
        </TabPanel>
        <TabPanel>
          <Suspense fallback={<PageLoading />}>
            <ABTestPatternNameReportContainer />
          </Suspense>
        </TabPanel>
        <TabPanel>
          <Suspense fallback={<PageLoading />}>
            <ABTestPatternNameByDateReportContainer />
          </Suspense>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
