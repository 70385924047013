import { useField } from "formik";
import { FC } from "react";

import {
  RadioButtons,
  RadioButtonsProps,
} from "~/src/components/common/RadioButtons";

export type Props = {
  readonly name: string;
  readonly isDisabled?: boolean;
  readonly options: RadioButtonsProps["options"];
};

export const FormikRadioButtonsField: FC<Props> = ({
  name,
  isDisabled,
  options,
}) => {
  const [{ value }, , { setValue }] = useField<string>(name);

  return (
    <RadioButtons
      name={name}
      value={value}
      isDisabled={isDisabled}
      options={options}
      onChange={setValue}
    />
  );
};
