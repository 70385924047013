/**
 * @generated SignedSource<<31b9ff05220d85e21162784e556e934e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DistributionStatus = "ACTIVE" | "INACTIVE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ChatbotTableDataRow_chatbotGroup$data = {
  readonly chatbots: {
    readonly totalCount: number;
  };
  readonly endDate: string | null;
  readonly id: string;
  readonly name: string;
  readonly slug: string;
  readonly startDate: string | null;
  readonly updatedAt: string;
  readonly status: DistributionStatus;
  readonly isArchive: boolean;
  readonly thumbnailImageUrl: string | null;
  readonly page: {
    readonly name: string;
    readonly slug: string;
    readonly funnel: {
      readonly name: string;
      readonly slug: string;
    };
  };
  readonly " $fragmentType": "ChatbotTableDataRow_chatbotGroup";
};
export type ChatbotTableDataRow_chatbotGroup = ChatbotTableDataRow_chatbotGroup$data;
export type ChatbotTableDataRow_chatbotGroup$key = {
  readonly " $data"?: ChatbotTableDataRow_chatbotGroup$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChatbotTableDataRow_chatbotGroup">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChatbotTableDataRow_chatbotGroup",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "kind",
          "value": "B_PATTERN"
        }
      ],
      "concreteType": "ChatbotConnection",
      "kind": "LinkedField",
      "name": "chatbots",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": "chatbots(kind:\"B_PATTERN\")"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isArchive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnailImageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Page",
      "kind": "LinkedField",
      "name": "page",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Funnel",
          "kind": "LinkedField",
          "name": "funnel",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ChatbotGroup",
  "abstractKey": null
};
})();

(node as any).hash = "535be531634b003c7963511791b4df26";

export default node;
