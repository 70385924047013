import { useToast } from "@chakra-ui/toast";
import {
  ArchiveOutlined as ArchiveOutlinedIcon,
  ContentCopyOutlined as ContentCopyOutlinedIcon,
  DeleteOutlined as DeleteOutlinedIcon,
  EditOutlined as EditOutlinedIcon,
  UnarchiveOutlined as UnarchiveOutlinedIcon,
} from "@mui/icons-material";
import { format } from "date-fns";
import { FC, useCallback, useContext, useMemo } from "react";
import { graphql } from "react-relay";

import { ABTestPatternTableActions_CopyMutation } from "~/src/__relay_artifacts__/ABTestPatternTableActions_CopyMutation.graphql";
import { ABTestPatternTableActions_DeleteMutation } from "~/src/__relay_artifacts__/ABTestPatternTableActions_DeleteMutation.graphql";
import { TableActions } from "~/src/components/common/tables/TableActions";
import {
  useArchiveHandler,
  useUnarchiveHandler,
} from "~/src/components/features/archive";
import { DeleteConfirmDialog } from "~/src/components/features/global/DeleteConfirmDialog";
import { DateRangeContext } from "~/src/components/features/global/HeaderNavigation/DateRangeField";
import { useOpenState } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";

type Props = {
  abtest: {
    id: string;
    slug: string;
    title: string | null;
    isArchive: boolean;
  };
  siteSlug: string;
  abtestGroupSlug: string;
  connectionId: string;
};

const deleteMutation = graphql`
  mutation ABTestPatternTableActions_DeleteMutation(
    $input: DeleteAbtestScenarioPageInput!
    $connections: [ID!]!
  ) {
    deleteAbtestScenarioPage(input: $input) {
      deletedAbtestScenarioPageId @deleteEdge(connections: $connections)
    }
  }
`;

const copyMutation = graphql`
  mutation ABTestPatternTableActions_CopyMutation(
    $input: CopyAbtestScenarioPageInput!
    $connections: [ID!]!
    $analyzerInput: ReportAnalyzerInput!
  ) {
    copyAbtestScenarioPage(input: $input) {
      abtestScenarioPage
        @appendNode(
          connections: $connections
          edgeTypeName: "AbtestScenarioPageEdge"
        ) {
        id
        slug
        title
        status
        isArchive
        uniqueUserCount(input: $analyzerInput)
        cvUserCount(input: $analyzerInput)
        cvr(input: $analyzerInput)
        averageStayTime(input: $analyzerInput)
        scrollRate(input: $analyzerInput)
        kind
        judgeStatus
        weight
      }
    }
  }
`;

export const ABTestPatternTableAcitons: FC<Props> = ({
  siteSlug,
  abtestGroupSlug,
  abtest,
  connectionId,
}) => {
  const {
    dateRange: [startOn, endOn],
  } = useContext(DateRangeContext);

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useOpenState();

  const deleteMutate =
    useMutationCommit<ABTestPatternTableActions_DeleteMutation>(deleteMutation);
  const copyMutate =
    useMutationCommit<ABTestPatternTableActions_CopyMutation>(copyMutation);

  const handleArchive = useArchiveHandler(abtest.id, connectionId);
  const handleUnarchive = useUnarchiveHandler(abtest.id, connectionId);

  const handleDelete = useCallback(async () => {
    try {
      const res = await deleteMutate({
        variables: {
          input: {
            abtestScenarioPageId: abtest.id,
          },
          connections: [connectionId],
        },
      });
      if (!res.deleteAbtestScenarioPage?.deletedAbtestScenarioPageId)
        throw new Error("assertion failed");

      toast({
        title: `${abtest.title}を削除しました`,
        status: "success",
      });
    } catch (err) {
      toast({ title: "削除に失敗しました", status: "error" });
    }
  }, [connectionId, deleteMutate, abtest.id, abtest.title, toast]);

  const handleCopy = useCallback(async () => {
    try {
      const res = await copyMutate({
        variables: {
          input: {
            abtestScenarioPageId: abtest.id,
          },
          analyzerInput: {
            startOn: format(startOn, "yyyy-MM-dd"),
            endOn: format(endOn, "yyyy-MM-dd"),
          },
          connections: [connectionId],
        },
      });
      const copiedAbtestScenarioPage =
        res.copyAbtestScenarioPage?.abtestScenarioPage;
      if (!copiedAbtestScenarioPage) throw new Error("assertion failed");

      toast({
        title: `${abtest.title}をコピーしました`,
        status: "success",
      });
    } catch (err) {
      toast({ title: "コピーに失敗しました", status: "error" });
    }
  }, [
    copyMutate,
    abtest.id,
    abtest.title,
    startOn,
    endOn,
    connectionId,
    toast,
  ]);

  const actionMenus = useMemo(
    () => [
      {
        label: "編集",
        icon: <EditOutlinedIcon />,
        to: `/sites/${siteSlug}/abtests/${abtestGroupSlug}/patterns/${abtest.slug}/edit`,
      },
      abtest.isArchive
        ? [
            {
              label: "アーカイブから戻す",
              icon: <UnarchiveOutlinedIcon />,
              onClick: () => handleUnarchive(),
            },
            {
              label: "削除",
              icon: <DeleteOutlinedIcon />,
              onClick: () => onOpen(),
            },
          ]
        : [
            {
              label: "アーカイブ",
              icon: <ArchiveOutlinedIcon />,
              onClick: () => handleArchive(),
            },
            {
              label: "コピー",
              icon: <ContentCopyOutlinedIcon />,
              onClick: () => handleCopy(),
            },
          ],
    ],
    [
      siteSlug,
      abtestGroupSlug,
      abtest.slug,
      abtest.isArchive,
      handleUnarchive,
      onOpen,
      handleArchive,
      handleCopy,
    ]
  );

  return (
    <>
      <TableActions actionMenus={actionMenus.flat()} />
      <DeleteConfirmDialog
        name={abtest.title}
        isOpen={isOpen}
        onClose={onClose}
        onDelete={handleDelete}
      />
    </>
  );
};
