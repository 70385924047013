import { useField } from "formik";
import * as React from "react";

import {
  TimePicker,
  TimePickerProps,
} from "~/src/components/common/datepicker/TimePicker";

type Props = {
  name: string;
  disabled?: boolean;
  placeholder?: string;
};

export const FormikTimeField: React.FC<Props> = ({
  name,
  disabled,
  placeholder,
}) => {
  const [{ value }, { error, touched }, { setValue }] = useField<Date | null>(
    name
  );

  const handleChange = React.useCallback<TimePickerProps["onChange"]>(
    (value) => {
      setValue(value);
    },
    [setValue]
  );

  return (
    <TimePicker
      value={value}
      onChange={handleChange}
      error={touched ? error : undefined}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
};
