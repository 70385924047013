/**
 * @generated SignedSource<<3c7e65d46387a14a04aa4e98bd7b1e9c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CopyAbtestScenarioPageGroupInput = {
  clientMutationId?: string | null;
  abtestScenarioPageGroupId: string;
  pageId: string;
};
export type ABTestGroupCopyButton_Mutation$variables = {
  input: CopyAbtestScenarioPageGroupInput;
};
export type ABTestGroupCopyButton_MutationVariables = ABTestGroupCopyButton_Mutation$variables;
export type ABTestGroupCopyButton_Mutation$data = {
  readonly copyAbtestScenarioPageGroup: {
    readonly abtestScenarioPageGroup: {
      readonly id: string;
      readonly title: string;
      readonly slug: string;
    } | null;
  } | null;
};
export type ABTestGroupCopyButton_MutationResponse = ABTestGroupCopyButton_Mutation$data;
export type ABTestGroupCopyButton_Mutation = {
  variables: ABTestGroupCopyButton_MutationVariables;
  response: ABTestGroupCopyButton_Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CopyAbtestScenarioPageGroupPayload",
    "kind": "LinkedField",
    "name": "copyAbtestScenarioPageGroup",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AbtestScenarioPageGroup",
        "kind": "LinkedField",
        "name": "abtestScenarioPageGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ABTestGroupCopyButton_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ABTestGroupCopyButton_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "004e3537599dedf1f341c516e2e15f06",
    "id": null,
    "metadata": {},
    "name": "ABTestGroupCopyButton_Mutation",
    "operationKind": "mutation",
    "text": "mutation ABTestGroupCopyButton_Mutation(\n  $input: CopyAbtestScenarioPageGroupInput!\n) {\n  copyAbtestScenarioPageGroup(input: $input) {\n    abtestScenarioPageGroup {\n      id\n      title\n      slug\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0dbdd193229d79292f025577d8329173";

export default node;
