import { ButtonProps } from "@chakra-ui/react";
import { FC } from "react";

import { chakraFactory } from "~/src/lib/chakra-ui";

import { OutlineButton } from "./OutlineButton";

export type Props = {
  icon: React.ReactNode;
} & ButtonProps;

const StyledOutlineButton = chakraFactory(OutlineButton, {
  baseStyle: {
    height: "30px",
    minHeight: "30px",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "10px",
    color: "#1867C0",
    px: "8px",

    svg: {
      mr: "4px",
      width: "14px",
      height: "14px",
    },
  },
});

export const SymbolOutlineButton: FC<Props> = ({
  children,
  icon,
  ...props
}) => (
  <StyledOutlineButton {...props}>
    {icon}
    {children}
  </StyledOutlineButton>
);
