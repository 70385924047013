/**
 * @generated SignedSource<<955a3ca120d26f8ba0540c32270160a2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FunnelTableSection_site$data = {
  readonly landingPageUniqueUser: number;
  readonly formPageUniqueUser: ReadonlyArray<number>;
  readonly confirmPageUniqueUser: number;
  readonly thanksPageUniqueUser: number;
  readonly uniqueUserCountPerFunnel: ReadonlyArray<any>;
  readonly " $fragmentType": "FunnelTableSection_site";
};
export type FunnelTableSection_site = FunnelTableSection_site$data;
export type FunnelTableSection_site$key = {
  readonly " $data"?: FunnelTableSection_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"FunnelTableSection_site">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "analyzerInput"
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "analyzerInput"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "FunnelTableSection_site",
  "selections": [
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "landingPageUniqueUser",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "formPageUniqueUser",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "confirmPageUniqueUser",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "thanksPageUniqueUser",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "uniqueUserCountPerFunnel",
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};
})();

(node as any).hash = "1a67d844089f2997f6e2feef9a3e187e";

export default node;
