import { useToast } from "@chakra-ui/toast";
import { useCallback } from "react";
import { graphql } from "react-relay";

import { useArchiveHandler_Mutation } from "~/src/__relay_artifacts__/useArchiveHandler_Mutation.graphql";
import { useMutationCommit } from "~/src/lib/react-relay";

const mutation = graphql`
  mutation useArchiveHandler_Mutation(
    $input: ArchiveArchivableInput!
    $connections: [ID!]!
  ) {
    archiveArchivable(input: $input) {
      archivable {
        id @deleteEdge(connections: $connections)
        isArchive
      }
    }
  }
`;

export const useArchiveHandler = (id: string, connectionId: string) => {
  const toast = useToast();
  const mutate = useMutationCommit<useArchiveHandler_Mutation>(mutation);

  const handleArchive = useCallback(async () => {
    try {
      const res = await mutate({
        variables: {
          input: {
            archivableId: id,
          },
          connections: [connectionId],
        },
      });
      const archivable = res.archiveArchivable?.archivable;
      if (!archivable) throw new Error("assertion failed");

      toast({
        title: "アーカイブしました",
        status: "success",
      });
    } catch (err) {
      toast({
        title: "アーカイブに失敗しました",
        status: "error",
      });
    }
  }, [connectionId, id, mutate, toast]);

  return handleArchive;
};
