import {
  TableDataColumn,
  TableHeaderColumn,
} from "~/src/components/common/tables/Table";
import { chakraFactory } from "~/src/lib/chakra-ui";

const URL_COLUMN_FLEX = 3;

export const StyledUrlHeaderColumn = chakraFactory(TableHeaderColumn, {
  baseStyle: {
    flex: URL_COLUMN_FLEX,
  },
});

export const StyledUrlDataColumn = chakraFactory(TableDataColumn, {
  baseStyle: {
    flex: URL_COLUMN_FLEX,
  },
});
