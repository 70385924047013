import { useToast } from "@chakra-ui/react";
import { FC, useCallback } from "react";
import { ConnectionHandler, graphql } from "react-relay";

import { PopupGroupSetCreateFormContainer_Mutation } from "~/src/__relay_artifacts__/PopupGroupSetCreateFormContainer_Mutation.graphql";
import { useFormErrorHandler } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";

import { PopupSetForm, PopupSetFormProps } from "./presentations";

export type Props = {
  onClose: () => void;
  siteId: string;
};

const mutation = graphql`
  mutation PopupGroupSetCreateFormContainer_Mutation(
    $input: CreatePopupGroupSetInput!
    $connections: [ID!]!
  ) {
    createPopupGroupSet(input: $input) {
      popupGroupSetEdge @appendEdge(connections: $connections) {
        node {
          id
          name
          memo
        }
      }
    }
  }
`;

export const PopupGroupSetCreateFormContainer: FC<Props> = ({
  onClose,
  siteId,
}) => {
  const toast = useToast();
  const { onFormError } = useFormErrorHandler();
  const mutate =
    useMutationCommit<PopupGroupSetCreateFormContainer_Mutation>(mutation);

  const handleSubmit = useCallback<PopupSetFormProps["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const connectionId = ConnectionHandler.getConnectionID(
          siteId,
          "PopupGroupSetListPage_popupGroupSets"
        );
        const res = await mutate({
          variables: {
            input: {
              siteId,
              name: values.name,
              memo: values.memo,
            },
            connections: [connectionId],
          },
        });
        if (!res.createPopupGroupSet?.popupGroupSetEdge)
          throw new Error("assertion failed");
        toast({ title: "ポップアップセットを作成しました", status: "success" });
        onClose();
      } catch (err) {
        onFormError(err, setErrors);
      }
    },
    [mutate, onFormError, toast, onClose, siteId]
  );

  return <PopupSetForm onSubmit={handleSubmit} onCancelClick={onClose} />;
};
