import { Formik } from "formik";
import { FC } from "react";

import { ReplyGroupForm } from "./ReplyGroupForm";
import { ReplyGroupFormValues as FormValues } from "./types";
import { ReplyGroupValidationSchema as validationSchema } from "./validationSchema";

export type Props = {
  onClose: () => void;
  onSubmit: (values: FormValues) => void;
  initialValues: FormValues;
};

export const ReplyGroupFormContainer: FC<Props> = ({
  onClose,
  onSubmit,
  initialValues,
}) => {
  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      <ReplyGroupForm onClose={onClose} />
    </Formik>
  );
};
