import { useToast } from "@chakra-ui/toast";
import {
  DeleteOutline as DeleteOutlineIcon,
  EditOutlined as EditOutlinedIcon,
} from "@mui/icons-material";
import { FC, useCallback, useMemo } from "react";
import { graphql } from "react-relay";

import { PopupGroupSetTableActions_DeleteMutation } from "~/src/__relay_artifacts__/PopupGroupSetTableActions_DeleteMutation.graphql";
import { Modal } from "~/src/components/common/Modal";
import { TableActions } from "~/src/components/common/tables/TableActions";
import { DeleteConfirmDialog } from "~/src/components/features/global/DeleteConfirmDialog";
import { PopupGroupSetEditFormContainer } from "~/src/components/features/popupSet";
import { useOpenState } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";

type Props = {
  popupGroupSet: { id: string; name: string; memo: string | null };
  connectionId: string;
};

const deleteMutation = graphql`
  mutation PopupGroupSetTableActions_DeleteMutation(
    $connections: [ID!]!
    $input: DeletePopupGroupSetInput!
  ) {
    deletePopupGroupSet(input: $input) {
      deletedPopupGroupSetId @deleteEdge(connections: $connections)
    }
  }
`;

export const PopupGroupSetTableActions: FC<Props> = ({
  popupGroupSet,
  connectionId,
}) => {
  const toast = useToast();
  const deleteModalOpenState = useOpenState();
  const editModalOpenState = useOpenState();

  const deleteMutate =
    useMutationCommit<PopupGroupSetTableActions_DeleteMutation>(deleteMutation);

  const handleDelete = useCallback(async () => {
    try {
      const res = await deleteMutate({
        variables: {
          input: {
            popupGroupSetId: popupGroupSet.id,
          },
          connections: [connectionId],
        },
      });
      const deletedPopupGroupSetId =
        res.deletePopupGroupSet?.deletedPopupGroupSetId;
      if (!deletedPopupGroupSetId) throw new Error("assertion failed");

      toast({
        title: `${popupGroupSet.name}を削除しました`,
        status: "success",
      });
    } catch (err) {
      toast({ title: "削除に失敗しました", status: "error" });
    }
  }, [connectionId, deleteMutate, popupGroupSet.id, popupGroupSet.name, toast]);

  const menus = useMemo(
    () => [
      {
        label: "編集",
        icon: <EditOutlinedIcon />,
        onClick: () => editModalOpenState.onOpen(),
      },
      {
        label: "削除",
        icon: <DeleteOutlineIcon />,
        onClick: () => deleteModalOpenState.onOpen(),
      },
    ],
    [deleteModalOpenState, editModalOpenState]
  );

  return (
    <>
      <TableActions actionMenus={menus} />
      <DeleteConfirmDialog
        name={popupGroupSet.name}
        isOpen={deleteModalOpenState.isOpen}
        onClose={deleteModalOpenState.onClose}
        onDelete={handleDelete}
      />
      <Modal
        title="ポップアップセットの編集"
        isOpen={editModalOpenState.isOpen}
        onClose={editModalOpenState.onClose}
      >
        <PopupGroupSetEditFormContainer
          onClose={editModalOpenState.onClose}
          popupGroupSet={popupGroupSet}
        />
      </Modal>
    </>
  );
};
