import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  SystemStyleObject,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ChangeEventHandler, FC, FocusEventHandler } from "react";

import { chakraFactory } from "~/src/lib/chakra-ui";

export type Props = {
  className?: string;
  type: "number" | "text" | "password";
  name: string;
  mode: "outline" | "fill";
  value: string;
  placeholder?: string;
  rightAdornment?: string;
  icon?: JSX.Element;
  error?: string;
  disabled?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
};

const getInputProps = ({
  mode,
  error,
}: Pick<Props, "mode" | "error">): SystemStyleObject => {
  switch (mode) {
    case "fill": {
      const borderColor = error ? "#E21010" : "#D0D0D0";
      return {
        background: "#FAFAFA",
        border: "1px solid #D0D0D0",

        "&:focus": {
          borderColor,
          boxShadow: `0 0 0 1px ${borderColor}`,
        },
      };
    }
    case "outline":
    default: {
      const borderColor = error ? "#E21010" : "#DADADA";
      return {
        border: "1px solid #DADADA",

        "&:focus": {
          borderColor,
          boxShadow: `0 0 0 1px ${borderColor}`,
        },
      };
    }
  }
};

type StyledInputProps = Pick<
  Props,
  "mode" | "error" | "icon" | "rightAdornment"
>;

const StyledInput = chakraFactory<StyledInputProps>(Input, {
  baseStyle: (props) => ({
    ...getInputProps(props),
    width: "100%",
    height: "40px",
    borderRadius: "2px",
    fontSize: "13px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.04em",
    boxSizing: "border-box",
    pr: props.rightAdornment ? "44px" : "10px",
    pl: props.icon ? "36px" : "10px",
    borderColor: props.error ? "#E21010" : undefined,

    "&::placeholder": {
      color: "#797979",
    },
  }),
});

const StyledErrorText = chakraFactory(Text, {
  baseStyle: {
    fontSize: "10px",
    lineHeight: "14px",
    display: "flex",
    alignItems: "center",
    my: 0,
    mt: "8px",
    color: "#D9534F",
    alignSelf: "start",
  },
});

const StyledInputLeftElement = chakraFactory(InputLeftElement, {
  baseStyle: {
    w: "40px",
    h: "40px",
    color: "#797979",
    pointerEvents: "none",

    svg: {
      width: "20px",
      height: "20px",
    },
  },
});

const StyledInputRightElement = chakraFactory(InputRightElement, {
  baseStyle: {
    fontSize: "12px",
    color: "#797979",
    pointerEvents: "none",
    marginRight: "4px",
  },
});

export const TextField: FC<Props> = ({
  className,
  type,
  name,
  value,
  mode,
  placeholder,
  rightAdornment,
  icon,
  error,
  disabled = false,
  onBlur,
  onChange,
}) => (
  <VStack className={className} spacing="8px">
    <InputGroup>
      {icon && <StyledInputLeftElement>{icon}</StyledInputLeftElement>}
      <StyledInput
        type={type}
        name={name}
        value={value}
        mode={mode}
        placeholder={placeholder}
        rightAdornment={rightAdornment}
        icon={icon}
        error={error}
        isDisabled={disabled}
        onBlur={onBlur}
        onChange={onChange}
      />
      {rightAdornment && (
        <StyledInputRightElement>{rightAdornment}</StyledInputRightElement>
      )}
    </InputGroup>
    {!!error && <StyledErrorText>{error}</StyledErrorText>}
  </VStack>
);
