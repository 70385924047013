import {
  Modal as ChakraModal,
  Image,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { FC } from "react";

import { PopupCloseButtonPosition } from "~/src/__generated__/schema";
import { chakraFactory } from "~/src/lib/chakra-ui";

import closeIcon from "./assets/icon_close.png";

export type Props = {
  isOpen: boolean;
  width?: string;
  onClose: () => void;
  closeButtonPosition: PopupCloseButtonPosition;
};

const StyledModalContent = chakraFactory(ModalContent, {
  baseStyle: {
    borderRadius: 0,
    backgroundColor: "transparent",
  },
});

const StyledCloseLink = chakraFactory("a", {
  baseStyle: {
    display: "inline-block",
    padding: 0,
    marginBottom: "4px",
    backgroundColor: "transparent",
  },
});

const StyledImage = chakraFactory(Image, {
  baseStyle: {
    display: "inline-block",
    maxWidth: "25px",
  },
});

export const PopupPreviewModal: FC<Props> = ({
  children,
  isOpen,
  width = "450px",
  onClose,
  closeButtonPosition,
}) =>
  isOpen ? (
    <ChakraModal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <StyledModalContent maxWidth={width}>
        {closeButtonPosition === "TOP_LEFT" && (
          <StyledCloseLink width={width} onClick={onClose} textAlign={"left"}>
            <StyledImage src={closeIcon} />
          </StyledCloseLink>
        )}
        {closeButtonPosition === "TOP_RIGHT" && (
          <StyledCloseLink width={width} onClick={onClose} textAlign={"right"}>
            <StyledImage src={closeIcon} />
          </StyledCloseLink>
        )}
        {children}
        {closeButtonPosition === "BOTTOM_LEFT" && (
          <StyledCloseLink width={width} onClick={onClose} textAlign={"left"}>
            <StyledImage src={closeIcon} />
          </StyledCloseLink>
        )}
        {closeButtonPosition === "BOTTOM_RIGHT" && (
          <StyledCloseLink width={width} onClick={onClose} textAlign={"right"}>
            <StyledImage src={closeIcon} />
          </StyledCloseLink>
        )}
      </StyledModalContent>
    </ChakraModal>
  ) : null;
