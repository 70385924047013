import { Box, HStack } from "@chakra-ui/layout";
import { FC } from "react";
import { graphql, useFragment } from "react-relay";
import { NavLink } from "react-router-dom";

import { AccountDetailPage_viewer$key } from "~/src/__relay_artifacts__/AccountDetailPage_viewer.graphql";
import { OutlineButton } from "~/src/components/common/Button";
import { DefinitionTeam } from "~/src/components/common/DefinitionTeam";
import { SectionCard } from "~/src/components/common/SectionCard";
import { PageLayout } from "~/src/components/features/global/PageLayout";

import { AccountSwitch } from "./AccountSwitch";

export type Props = {
  userRef: AccountDetailPage_viewer$key;
};

const fragment = graphql`
  fragment AccountDetailPage_viewer on User {
    id
    name
    email
    company
    tel
    isHoneycomb
    itpUrls
    isSecure
    isMacbeeServer
    userTag
    isCrossDomainQuery
    isCrossDomainFragment
  }
`;

export const AccountDetailPage: FC<Props> = ({ userRef }) => {
  const user = useFragment(fragment, userRef);

  return (
    <PageLayout title="アカウント設定">
      <Box my="16px">
        <HStack justifyContent="flex-end" mb="16px" spacing="16px">
          <NavLink to={`/account/edit`}>
            <OutlineButton>編集</OutlineButton>
          </NavLink>
        </HStack>
      </Box>

      <SectionCard>
        <DefinitionTeam label="会社名">{user.company}</DefinitionTeam>
        <DefinitionTeam label="担当者名">{user.name}</DefinitionTeam>
        <DefinitionTeam label="メールアドレス">{user.email}</DefinitionTeam>
        <DefinitionTeam label="電話番号">{user.tel}</DefinitionTeam>
        <DefinitionTeam label="ハニカム連携">
          <AccountSwitch
            isChecked={user.isHoneycomb}
            input={{ isHoneycomb: !user.isHoneycomb }}
          />
        </DefinitionTeam>
        <DefinitionTeam label="ITP対応用エンドポイント">
          {user.itpUrls || "未設定"}
        </DefinitionTeam>
        <DefinitionTeam label="CookieのSecure設定">
          <AccountSwitch
            isChecked={user.isSecure}
            input={{ isSecure: !user.isSecure }}
          />
        </DefinitionTeam>
        <DefinitionTeam label="マクビーのサーバでITP対応">
          <AccountSwitch
            isChecked={user.isMacbeeServer}
            input={{ isMacbeeServer: !user.isMacbeeServer }}
          />
        </DefinitionTeam>
        <DefinitionTeam label="クロスドメイン(クエリ)">
          <AccountSwitch
            isChecked={user.isCrossDomainQuery}
            input={{ isCrossDomainQuery: !user.isCrossDomainQuery }}
          />
        </DefinitionTeam>
        <DefinitionTeam label="クロスドメイン(ハッシュ文字)">
          <AccountSwitch
            isChecked={user.isCrossDomainFragment}
            input={{ isCrossDomainFragment: !user.isCrossDomainFragment }}
          />
        </DefinitionTeam>
        <DefinitionTeam label="Javascriptタグ">
          {user.userTag != null && user.userTag.length > 0
            ? "設定済み"
            : "未設定"}
        </DefinitionTeam>
      </SectionCard>
    </PageLayout>
  );
};
