import { useCallback } from "react";
import { GraphQLTaggedNode, useRelayEnvironment } from "react-relay";
import commitMutation from "relay-commit-mutation-promise";
import { MutationConfig, MutationParameters } from "relay-runtime";

type Configs<T extends MutationParameters> = Omit<
  MutationConfig<T>,
  "onError" | "onCompleted" | "mutation"
>;

export const useMutationCommit = <T extends MutationParameters>(
  mutation: GraphQLTaggedNode
) => {
  const environment = useRelayEnvironment();

  const commit = useCallback(
    (configs: Configs<T>) =>
      commitMutation<T>(environment, {
        mutation,
        ...configs,
      }),
    [environment, mutation]
  );

  return commit;
};
