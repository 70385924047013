import { Box, HStack, VStack } from "@chakra-ui/layout";
import {
  AddCircle as AddCircleIcon,
  DeleteOutline as DeleteOutlineIcon,
} from "@mui/icons-material";
import { FieldArray, FieldArrayRenderProps, useField } from "formik";
import { FC, useCallback } from "react";

import { SymbolOutlineButton } from "~/src/components/common/Button";
import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormikTextField } from "~/src/components/common/forms/FormikTextField";
import { IconButton } from "~/src/components/common/IconButton";
import { chakraFactory } from "~/src/lib/chakra-ui";

import { ReplyFormValues } from "./types";

const LabelWrapper = chakraFactory("p", {
  baseStyle: {
    width: "90%",
    height: "22px",
    fontSize: "13px",
    color: "#000000",
    alignItems: "center",
    justifyContent: "center",
  },
});

export type Props = {
  name: string;
};

export const ApiResultForm: FC<Props> = ({ name }) => {
  const [results] = useField<ReplyFormValues[]>(name);

  const render = useCallback(
    (arrayHelpers: FieldArrayRenderProps) => (
      <>
        {results.value &&
          results.value.length > 0 &&
          results.value.map((_, index) => (
            <Box border="1px solid #eee" width={"100%"} p="3" m="1" key={index}>
              <HStack>
                <LabelWrapper>API結果判定</LabelWrapper>
                <IconButton
                  size="24px"
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={() => arrayHelpers.remove(index)}
                  icon={<DeleteOutlineIcon />}
                />
              </HStack>

              <VStack>
                <FormControl label="実行結果">
                  <FormikTextField
                    type="text"
                    mode="fill"
                    name={`${name}.${index}.result`}
                  />
                </FormControl>
                <FormControl label="結果を表示するメッセージの番号">
                  <FormikTextField
                    type="number"
                    mode="fill"
                    name={`${name}.${index}.nextMessageNumber`}
                  />
                </FormControl>
              </VStack>
            </Box>
          ))}
        <SymbolOutlineButton
          alignSelf={"flex-end"}
          marginRight={"10%"}
          icon={<AddCircleIcon />}
          // eslint-disable-next-line react/jsx-no-bind
          onClick={() => {
            arrayHelpers.push({
              result: "",
              nextMessageNumber: 0,
            });
          }}
        >
          API結果追加
        </SymbolOutlineButton>
      </>
    ),
    [name, results.value]
  );

  return <FieldArray name={name} render={render} />;
};
