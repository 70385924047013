import { Box } from "@chakra-ui/react";
import { concat, pick } from "ramda";
import { FC, useCallback, useMemo } from "react";
import { Edge, Node } from "react-flow-renderer";
import { graphql, useFragment } from "react-relay";
import { useNavigate, useParams } from "react-router-dom";

import { ChatbotApiResultInput, UserRole } from "~/src/__generated__/schema";
import { ChatbotPatternEditScreenContainer_chatbot$key } from "~/src/__relay_artifacts__/ChatbotPatternEditScreenContainer_chatbot.graphql";
import { ChatbotPatternEditScreenContainer_DeleteChatMessageMutation } from "~/src/__relay_artifacts__/ChatbotPatternEditScreenContainer_DeleteChatMessageMutation.graphql";
import { ChatbotPatternEditScreenContainer_Mutation } from "~/src/__relay_artifacts__/ChatbotPatternEditScreenContainer_Mutation.graphql";
import { NodeData } from "~/src/components/features/chatbot/ChatbotFlowForms";
import {
  ChatMessageReplyFormValues,
  ChatbotPatternForm,
  ChatbotPatternFormProps,
  FlowEdgeFormValues,
  FlowNodeFormValues,
} from "~/src/components/features/chatbot/ChatbotPatternForm";
import { PageLayout } from "~/src/components/features/global/PageLayout";
import { useFormErrorHandler } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";
import { delayChunkPromise } from "~/src/lib/utils";

const initialNode: Node<NodeData> = {
  id: "launch_chat_message",
  type: "input",
  data: { label: "チャット開始", message: null, replyGroup: null },
  position: { x: 0, y: 0 },
};

const initialEdge: Edge<any> = {
  id: "reactflow__edge-launch_chat_message-launch_chat_message",
  source: "launch_chat_message",
  target: "1",
  type: "buttonEdge",
};

export type Props = {
  role: UserRole;
  chatbotRef: ChatbotPatternEditScreenContainer_chatbot$key;
};

const fragments = {
  chatbot: graphql`
    fragment ChatbotPatternEditScreenContainer_chatbot on Chatbot {
      id
      chatbotGroup {
        id
        deliverKind
        originalChatbot {
          weight
        }
      }
      actionKind
      botImageAlt
      botImageCache
      botImageUrl
      botKind
      botTextColor
      botBackgroundColor
      colorKind
      chatFormColor
      clientTextColor
      clientBackgroundColor
      displayKind
      honeycombCode
      kind
      name
      notFoundMessage
      openButtonText
      openButtonImageCache
      openButtonImageUrl
      slug
      status
      title
      titleTextColor
      timing
      toFormButtonImageCache
      weight
      submitMethodKind
      xOffset
      xOffsetUnit
      yOffset
      yOffsetUnit
      windowColor
      questionCsvUrl
      questionFinishMessage
      toFormButtonImageUrl
      honeycombCode
      chatMessageFlowNodes {
        nodeId
        kind
        position {
          x
          y
        }
        chatMessage {
          id
          kind
          message
          isHasReply
          isSaveReply
          isInheritQuery
          isTransferReply
          displaySpeed
          coordinate
          messageIndex
          nextMessageIndex
          submitUrl
          chatMessageImage {
            image
            alt
            id
            imageUrl
            isInheritQuery
            linkUrl
          }
        }
        replyGroup {
          id
          kind
          label
          number
          divergingPoint
          coordinate
          replies {
            id
            kind
            messageIndex
            nextMessageIndex
            errorMessage
            label
            name
            value
            regularExpression
            placeholder
            replyNodeId
            apiAction
            chatbotApiResults {
              nextMessageNumber
              result
            }
          }
        }
      }
      chatMessageReplyFlowNodes {
        nodeId
        nodeLabel
        parentNode
        kind
      }
      chatMessageFlowEdges {
        source
        target
      }
      chatMessageReplyFlowEdges {
        source
        target
      }
    }
  `,
};

const mutation = graphql`
  mutation ChatbotPatternEditScreenContainer_Mutation(
    $input: UpdateChatbotInput!
    $originalInput: UpdateOriginalChatbotInput!
    $withOriginal: Boolean!
  ) {
    updateOriginalChatbot(input: $originalInput) @include(if: $withOriginal) {
      chatbot {
        id
        weight
      }
    }
    updateChatbot(input: $input) {
      chatbot {
        slug
        id
        ...ChatbotPatternEditScreenContainer_chatbot
      }
    }
  }
`;

const deleteMutation = graphql`
  mutation ChatbotPatternEditScreenContainer_DeleteChatMessageMutation(
    $input: DeleteChatMessageInput!
  ) {
    deleteChatMessage(input: $input) {
      deletedChatMessageId
    }
  }
`;

export const ChatbotPatternEditScreenContainer: FC<Props> = ({
  chatbotRef,
  role,
}) => {
  const { siteSlug = "", chatbotSlug = "", patternSlug = "" } = useParams();
  const navigate = useNavigate();
  const chatbot = useFragment(fragments.chatbot, chatbotRef);
  const { onFormError } = useFormErrorHandler();
  const mutate =
    useMutationCommit<ChatbotPatternEditScreenContainer_Mutation>(mutation);
  const deleteChatMessageMutate =
    useMutationCommit<ChatbotPatternEditScreenContainer_DeleteChatMessageMutation>(
      deleteMutation
    );

  const handleCancel = useCallback(() => navigate(-1), [navigate]);

  const handleSubmit = useCallback<ChatbotPatternFormProps["onSubmit"]>(
    async (
      {
        botImage,
        botImageUrl,
        openButtonImage,
        openButtonImageUrl,
        toFormButtonImage,
        toFormButtonImageUrl,
        originalPatternWeight,
        questionCsv,
        questionCsvUrl,
        flowNodes,
        flowEdges,
        replies,
        deleteChatMessageCandidateIds,
        chatbotApiResults,
        ...values
      },
      { setErrors }
    ) => {
      try {
        if (deleteChatMessageCandidateIds) {
          const deleteChatMessageIds = deleteChatMessageCandidateIds.filter(
            (id) =>
              flowNodes.find((flowNode) => flowNode.chatMessage?.id === id) ===
              undefined
          );

          const deleteMutations: Promise<{}>[] = deleteChatMessageIds.map(
            (chatMessageId) => {
              return deleteChatMessageMutate({
                variables: {
                  input: {
                    chatMessageId,
                  },
                },
              });
            }
          );
          await delayChunkPromise(deleteMutations);
        }

        const uploadables: Record<string, File> = {};
        if (botImage) uploadables[`variables.input.botImage`] = botImage;
        if (openButtonImage)
          uploadables[`variables.input.openButtonImage`] = openButtonImage;
        if (toFormButtonImage)
          uploadables[`variables.input.toFormButtonImage`] = toFormButtonImage;
        if (questionCsv)
          uploadables[`variables.input.questionCsv`] = questionCsv;

        flowNodes.forEach((node, index) => {
          if (
            node.chatMessage.chatMessageImage &&
            node.chatMessage.chatMessageImage.image
          ) {
            uploadables[
              `variables.input.chatMessageNodes.${index}.chatMessage.chatMessageImage.image`
            ] = node.chatMessage.chatMessageImage.image;
          }
        });

        // // TODO: do not use any and handle other fields
        const res = await mutate({
          variables: {
            input: {
              chatbotId: chatbot.id,
              chatMessageNodes: flowNodes,
              chatMessageEdges: flowEdges,
              chatMessageReplies: replies,
              chatbotApiResults,
              ...values,
            },
            withOriginal: chatbot.chatbotGroup.deliverKind === "WEIGHT",
            originalInput: {
              chatbotGroupId: chatbot.chatbotGroup.id,
              weight: originalPatternWeight,
            },
          },
          uploadables,
        });

        if (!res.updateChatbot || !res.updateChatbot?.chatbot) {
          throw new Error("assertion failed");
        }

        const { slug } = res.updateChatbot.chatbot;

        navigate(`/sites/${siteSlug}/chatbots/${chatbotSlug}/patterns/${slug}`);
      } catch (err) {
        onFormError(err, setErrors);
      }
    },
    [
      chatbot.chatbotGroup.deliverKind,
      chatbot.chatbotGroup.id,
      chatbot.id,
      chatbotSlug,
      mutate,
      navigate,
      onFormError,
      siteSlug,
      deleteChatMessageMutate,
    ]
  );

  const nodes = useMemo(() => {
    const chatMessageNodes = chatbot.chatMessageFlowNodes.map((node) => {
      return {
        id: node.nodeId,
        type: node.kind,
        data: {
          label: `${node.kind} node`,
          message: node.chatMessage,
          replyGroup: node.replyGroup,
          reply: null,
        },
        position: { x: node.position.x, y: node.position.y },
      } as Node<NodeData>;
    });
    const chatMessageReplyNodes = chatbot.chatMessageReplyFlowNodes.map(
      (node, index) => {
        return {
          id: node.nodeId,
          type: node.kind,
          position: { x: 15, y: 70 + 40 * (index + 1) },
          data: {
            label: node.nodeLabel,
          },
          parentNode: node.parentNode,
        } as Node<NodeData>;
      }
    );
    const nodes = concat(chatMessageNodes, chatMessageReplyNodes);
    if (nodes.length === 0) return [initialNode];

    const minX = chatMessageNodes.reduce(
      (prev, curr) => (prev > curr.position.x ? curr.position.x : prev),
      10000
    );
    const minY = chatMessageNodes.reduce(
      (prev, curr) => (prev > curr.position.y ? curr.position.y : prev),
      10000
    );
    initialNode.position.x = minX - 100;
    initialNode.position.y = minY - 100;
    return concat([initialNode], nodes);
  }, [chatbot]);

  const edges = useMemo(() => {
    const chatMessageEdges = chatbot.chatMessageFlowEdges.map((edge) => {
      return {
        id: "reactflow__edge-" + edge.source,
        type: "buttonEdge",
        ...edge,
      } as Edge<any>;
    });
    const chatMessageReplyEdges = chatbot.chatMessageReplyFlowEdges.map(
      (edge) => {
        return {
          id: "reactflow__edge-" + edge.source,
          type: "buttonEdge",
          ...edge,
        } as Edge<any>;
      }
    );
    const edges = concat(chatMessageEdges, chatMessageReplyEdges);
    if (edges.length === 0) return [initialEdge];
    return concat([initialEdge], edges);
  }, [chatbot]);

  const chatMessageIds = useMemo(() => {
    return chatbot.chatMessageFlowNodes.map((node) => node.chatMessage.id);
  }, [chatbot]);

  const flowNodes = useMemo(() => {
    const messageNodes = nodes.filter((node) => node.type !== "REPLY");
    return messageNodes.map((node) => {
      const flowNode = {
        nodeId: node.id,
        kind: node.type,
        x: node.position.x,
        y: node.position.y,
        chatMessage: {
          id: node.data.message?.id,
          messageType: node.data.message?.kind,
          message: node.data.message?.message,
          submitUrl: node.data.message?.submitUrl,
          isInheritQuery: node.data.message?.isInheritQuery,
          hasReply: node.data.replyGroup ? true : false,
          saveReply: node.data.message?.isSaveReply,
          displaySpeed: node.data.message?.displaySpeed,
          transferReply: node.data.message?.isTransferReply,
          chatMessageImage: node.data.message?.chatMessageImage,
        },
      } as FlowNodeFormValues;

      if (node.data.replyGroup) {
        flowNode.chatMessageReplyGroup = {
          id: node.data.replyGroup.id,
          replyGroupType: node.data.replyGroup.kind,
          label: node.data.replyGroup.label,
          divergingPoint: node.data.replyGroup.divergingPoint,
        };
      }

      return flowNode;
    });
  }, [nodes]);

  const flowEdges = useMemo(() => {
    return edges.map((edge) => {
      return {
        edgeId: edge.source,
        source: edge.source.includes("_reply_")
          ? Number(edge.source.split("_reply_").pop())
          : Number(edge.source),
        target: Number(edge.target),
      } as FlowEdgeFormValues;
    });
  }, [edges]);

  const replies = useMemo(() => {
    const replies: ChatMessageReplyFormValues[] = [];
    const messageNodes = nodes.filter((node) => node.type !== "REPLY");
    messageNodes.forEach((node) => {
      if (node.data.replyGroup) {
        const replyGroup = node.data.replyGroup;
        const parentNodeId = node.id;

        replyGroup.replies.forEach((reply) => {
          replies.push({
            id: reply.id,
            parentNodeId,
            replyType: reply.kind,
            replyNodeId: reply.replyNodeId || "",
            ...pick(
              [
                "name",
                "value",
                "label",
                "regularExpression",
                "placeholder",
                "errorMessage",
                "apiAction",
              ],
              reply
            ),
          });
        });
      }
    });
    return replies;
  }, [nodes]);

  const chatbotApiResults = useMemo(() => {
    const chatbotApiResults: ChatbotApiResultInput[] = [];
    const messageNodes = nodes.filter((node) => node.type !== "REPLY");
    messageNodes.forEach((node) => {
      if (node.data.replyGroup) {
        const replyGroup = node.data.replyGroup;

        replyGroup.replies.forEach((reply, index) => {
          reply.chatbotApiResults?.forEach((apiResult) => {
            chatbotApiResults.push({
              replyIndex: index,
              ...pick(["result", "nextMessageNumber"], apiResult),
            });
          });
        });
      }
    });
    return chatbotApiResults;
  }, [nodes]);

  const initialValues = useMemo(
    () => ({
      actionKind: chatbot.actionKind || "",
      botKind: chatbot.botKind || "",
      colorKind: chatbot.colorKind || "",
      displayKind: chatbot.displayKind || "",
      name: chatbot.name || "",
      openButtonText: chatbot.openButtonText || "",
      notFoundMessage: chatbot.notFoundMessage || "",
      submitMethodKind: chatbot.submitMethodKind || "",
      title: chatbot.title || "",
      weight: chatbot.weight || 1,
      xOffset: chatbot.xOffset,
      xOffsetUnit: chatbot.xOffsetUnit || "px",
      yOffset: chatbot.yOffset,
      yOffsetUnit: chatbot.yOffsetUnit || "px",
      questionFinishMessage: chatbot.questionFinishMessage || "",
      windowColor: chatbot.windowColor,
      titleTextColor: chatbot.titleTextColor,
      timing: chatbot.timing,
      chatFormColor: chatbot.chatFormColor,
      botTextColor: chatbot.botTextColor,
      botBackgroundColor: chatbot.botBackgroundColor,
      clientTextColor: chatbot.clientTextColor,
      clientBackgroundColor: chatbot.clientBackgroundColor,
      originalPatternWeight: chatbot.chatbotGroup.originalChatbot.weight,

      toFormButtonImageUrl: chatbot.toFormButtonImageUrl,
      openButtonImageUrl: chatbot.openButtonImageUrl,
      botImageUrl: chatbot.botImageUrl,
      questionCsvUrl: chatbot.questionCsvUrl,
      honeycombCode: chatbot.honeycombCode,
      flowNodes,
      flowEdges,
      deleteChatMessageCandidateIds: chatMessageIds,
      replies,
      chatbotApiResults,
    }),
    [chatbot, flowNodes, flowEdges, chatMessageIds, replies, chatbotApiResults]
  );

  return (
    <PageLayout
      title="チャットボットパターン設定"
      breadcrumbs={[
        { label: "チャットボット一覧", path: `/sites/${siteSlug}/chatbots` },
        {
          label: "チャットボット詳細",
          path: `/sites/${siteSlug}/chatbots/${chatbotSlug}`,
        },
        {
          label: "パターン編集",
          path: `/sites/${siteSlug}/chatbots/${chatbotSlug}/patterns/${patternSlug}/edit`,
        },
      ]}
    >
      <Box my="16px" p="16px">
        <ChatbotPatternForm
          // TODO: do not use any
          initialValues={initialValues as any}
          enableWeightFields={chatbot.chatbotGroup.deliverKind === "WEIGHT"}
          onCancelClick={handleCancel}
          onSubmit={handleSubmit}
          role={role}
          nodes={nodes}
          edges={edges}
        />
      </Box>
    </PageLayout>
  );
};
