import { useField } from "formik";
import { FC } from "react";

import { Switch } from "~/src/components/common/Switch";

export type Props = {
  name: string;
};

export const FormikSwitchField: FC<Props> = ({ name }) => {
  const [{ value, onChange }] = useField<boolean>(name);

  return <Switch name={name} isChecked={value} onChange={onChange} />;
};
