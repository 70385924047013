import { FC, useCallback, useMemo } from "react";
import { graphql, useFragment } from "react-relay";
import { useNavigate } from "react-router-dom";

import { NotificationEditPage_Mutation } from "~/src/__relay_artifacts__/NotificationEditPage_Mutation.graphql";
import { NotificationEditPage_notification$key } from "~/src/__relay_artifacts__/NotificationEditPage_notification.graphql";
import { PageLayout } from "~/src/components/features/global/PageLayout";
import {
  NotificationForm,
  NotificationFormProps,
} from "~/src/components/features/settings/NotificationForms/presentations";
import { useFormErrorHandler } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";

type Props = {
  notificationRef: NotificationEditPage_notification$key;
};

const fragment = graphql`
  fragment NotificationEditPage_notification on Notification {
    id
    title
    body
    category
    publishedAt
    slug
  }
`;

const mutation = graphql`
  mutation NotificationEditPage_Mutation($input: UpdateNotificationInput!) {
    updateNotification(input: $input) {
      notification {
        id
        title
        body
        category
        publishedAt
        slug
      }
    }
  }
`;

export const NotificationEditPage: FC<Props> = ({ notificationRef }) => {
  const navigate = useNavigate();
  const { onFormError } = useFormErrorHandler();
  const notification = useFragment(fragment, notificationRef);
  const mutate = useMutationCommit<NotificationEditPage_Mutation>(mutation);

  const initialValues = useMemo(
    () => ({
      body: notification.body || "",
      category: notification.category || "UPGRADE",
      publishedAt: notification.publishedAt,
      title: notification.title,
    }),
    [notification]
  );

  const handleCancelClick = useCallback(() => navigate(-1), [navigate]);

  const handleSubmit = useCallback<NotificationFormProps["onSubmit"]>(
    async ({ ...values }, { setErrors }) => {
      try {
        const res = await mutate({
          variables: {
            input: { ...values, notificationId: notification.id },
          },
        });
        const updatedNotification = res.updateNotification?.notification;
        if (!updatedNotification) throw new Error("assertion failed");
        navigate(`/notifications`);
      } catch (err) {
        onFormError(err, setErrors);
      }
    },
    [mutate, notification.id, navigate, onFormError]
  );

  return (
    <PageLayout title="お知らせ">
      <NotificationForm
        initialValues={initialValues}
        onCancelClick={handleCancelClick}
        onSubmit={handleSubmit}
      />
    </PageLayout>
  );
};
