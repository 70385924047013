import { HStack, ListItem, UnorderedList, useToast } from "@chakra-ui/react";
import { ArchiveOutlined as ArchiveIcon } from "@mui/icons-material";
import { FC, Suspense, useCallback } from "react";
import { graphql } from "react-relay";

import { FunnelBulkArchiveButton_Mutation } from "~/src/__relay_artifacts__/FunnelBulkArchiveButton_Mutation.graphql";
import {
  GhostButton,
  SolidButton,
  SymbolSolidButton,
} from "~/src/components/common/Button";
import { Modal } from "~/src/components/common/Modal";
import { PageLoading } from "~/src/components/common/PageLoading";
import { useOpenState } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";
import { delayChunkPromise } from "~/src/lib/utils";

type Funnel = {
  id: string;
  slug: string;
  name: string;
};

export type Props = {
  selectedFunnels: Funnel[];
  connectionId: string;
};

const mutation = graphql`
  mutation FunnelBulkArchiveButton_Mutation(
    $input: ArchiveArchivableInput!
    $connections: [ID!]!
  ) {
    archiveArchivable(input: $input) {
      archivable {
        id @deleteEdge(connections: $connections)
      }
    }
  }
`;

export const FunnelBulkArchiveButton: FC<Props> = ({
  selectedFunnels,
  connectionId,
}) => {
  const toast = useToast();
  const { isOpen, onClose, onOpen } = useOpenState();
  const mutate = useMutationCommit<FunnelBulkArchiveButton_Mutation>(mutation);

  const handleChange = useCallback(() => {
    if (selectedFunnels.length < 1) {
      toast({
        title: "1つ以上のポップアップを選択してください",
        status: "info",
      });
      return;
    }

    onOpen();
  }, [onOpen, selectedFunnels, toast]);

  const handleArchive = useCallback(async () => {
    try {
      const mutations: Promise<{}>[] = selectedFunnels.map((selectedFunnel) => {
        return mutate({
          variables: {
            input: {
              archivableId: selectedFunnel.id,
            },
            connections: [connectionId],
          },
        });
      });
      await delayChunkPromise(mutations);
      toast({
        title: "ファネルを一括アーカイブしました",
        status: "success",
      });
      onClose();
    } catch (err) {
      toast({
        title: "ファネルの一括アーカイブに失敗しました",
        status: "error",
      });
    }
  }, [mutate, selectedFunnels, connectionId, toast, onClose]);

  return (
    <>
      <SymbolSolidButton
        height={"40px"}
        marginRight={"14px"}
        icon={<ArchiveIcon />}
        isActive={isOpen}
        onClick={handleChange}
        isDisabled={selectedFunnels.length < 1}
      >
        一括アーカイブ
      </SymbolSolidButton>
      <Modal title="一括アーカイブ" isOpen={isOpen} onClose={onClose}>
        <Suspense fallback={<PageLoading />}>
          選択したファネルをアーカイブしますか？
          <UnorderedList marginTop={"4"} spacing={"2"}>
            {selectedFunnels.map((funnel, index) => (
              <ListItem key={index}>{funnel.name}</ListItem>
            ))}
          </UnorderedList>
          <HStack mt="116px" spacing="8px" justifyContent="flex-end">
            <GhostButton onClick={onClose}>戻る</GhostButton>
            <SolidButton onClick={handleArchive}>適用</SolidButton>
          </HStack>
        </Suspense>
      </Modal>
    </>
  );
};
