/**
 * @generated SignedSource<<03308b71e24d3cce636eb17ec67340e1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AbtestReportKind = "ABTEST_TITLE" | "ABTEST_ID" | "%future added value";
export type AbtestReportOrderValue = "ABTEST" | "ABTEST_GROUP" | "FUNNEL" | "DATE" | "UNIQUE_USER" | "CONVERSION_USER" | "CVR" | "AVERAGE_STAY_TIME" | "MOBILE_RATE" | "TABLET_RATE" | "PC_RATE" | "%future added value";
export type Device = "MOBILE" | "TABLET" | "PC" | "%future added value";
export type PageKind = "LANDING" | "FORM" | "CONFIRM" | "THANKS" | "%future added value";
export type AbtestReportKindInput = {
  value: AbtestReportKind;
  isDateExist: boolean;
};
export type AbtestReportFilterInput = {
  startDate: string;
  endDate: string;
  device?: Device | null;
  source?: string | null;
  funnelIds?: ReadonlyArray<string> | null;
  pageKind?: PageKind | null;
  abtestScenarioPageIds?: ReadonlyArray<string> | null;
  abtestScenarioPageTitles?: ReadonlyArray<string> | null;
};
export type AbtestReportOrderInput = {
  value: AbtestReportOrderValue;
  isDesc?: boolean | null;
};
export type ABTestDefaultByDateReportContainer_Query$variables = {
  kind: AbtestReportKindInput;
  filter: AbtestReportFilterInput;
  order?: AbtestReportOrderInput | null;
  slug: string;
};
export type ABTestDefaultByDateReportContainer_QueryVariables = ABTestDefaultByDateReportContainer_Query$variables;
export type ABTestDefaultByDateReportContainer_Query$data = {
  readonly site: {
    readonly abtestScenarioPageGroups: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly title: string;
          readonly abtestScenarioPages: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly id: string;
                readonly title: string | null;
              } | null;
            } | null> | null;
          } | null;
        } | null;
      } | null> | null;
    };
    readonly " $fragmentSpreads": FragmentRefs<"ABTestDefaultByDateReport_site">;
  };
};
export type ABTestDefaultByDateReportContainer_QueryResponse = ABTestDefaultByDateReportContainer_Query$data;
export type ABTestDefaultByDateReportContainer_Query = {
  variables: ABTestDefaultByDateReportContainer_QueryVariables;
  response: ABTestDefaultByDateReportContainer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "kind"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "slug"
},
v4 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "AbtestScenarioPageGroupConnection",
  "kind": "LinkedField",
  "name": "abtestScenarioPageGroups",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AbtestScenarioPageGroupEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AbtestScenarioPageGroup",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "AbtestScenarioPageConnection",
              "kind": "LinkedField",
              "name": "abtestScenarioPages",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AbtestScenarioPageEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AbtestScenarioPage",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v5/*: any*/),
                        (v6/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "abtestScenarioPageTitle",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "abtestScenarioPageGroupTitle",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "funnelName",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accessDate",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uniqueUser",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "conversionUser",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cvr",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "averageStayTime",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pcRate",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mobileRate",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tabletRate",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ABTestDefaultByDateReportContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ABTestDefaultByDateReport_site"
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "ABTestDefaultByDateReportContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "filter",
                "variableName": "filter"
              },
              {
                "kind": "Variable",
                "name": "kind",
                "variableName": "kind"
              },
              {
                "kind": "Variable",
                "name": "order",
                "variableName": "order"
              }
            ],
            "concreteType": "AbtestReport",
            "kind": "LinkedField",
            "name": "reportAbtest",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AbtestReportRow",
                "kind": "LinkedField",
                "name": "rows",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "abtestScenarioPageSlug",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "abtestScenarioPageGroupSlug",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AbtestReportRow",
                "kind": "LinkedField",
                "name": "summary",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "49c6801cf4e74f75fd401a3150e0c816",
    "id": null,
    "metadata": {},
    "name": "ABTestDefaultByDateReportContainer_Query",
    "operationKind": "query",
    "text": "query ABTestDefaultByDateReportContainer_Query(\n  $kind: AbtestReportKindInput!\n  $filter: AbtestReportFilterInput!\n  $order: AbtestReportOrderInput\n  $slug: String!\n) {\n  site(slug: $slug) {\n    ...ABTestDefaultByDateReport_site\n    abtestScenarioPageGroups {\n      edges {\n        node {\n          id\n          title\n          abtestScenarioPages {\n            edges {\n              node {\n                id\n                title\n              }\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment ABTestDefaultByDateReport_site on Site {\n  slug\n  url\n  reportAbtest(kind: $kind, filter: $filter, order: $order) {\n    rows {\n      abtestScenarioPageTitle\n      abtestScenarioPageGroupTitle\n      funnelName\n      accessDate\n      uniqueUser\n      conversionUser\n      cvr\n      averageStayTime\n      pcRate\n      mobileRate\n      tabletRate\n      abtestScenarioPageSlug\n      abtestScenarioPageGroupSlug\n    }\n    summary {\n      abtestScenarioPageTitle\n      abtestScenarioPageGroupTitle\n      funnelName\n      accessDate\n      uniqueUser\n      conversionUser\n      cvr\n      averageStayTime\n      pcRate\n      mobileRate\n      tabletRate\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "34340bf1c69e75dd118c5398e808f9d2";

export default node;
