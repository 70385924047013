import { Text, VStack } from "@chakra-ui/react";
import { AddCircleOutlined as AddCircleOutlinedIcon } from "@mui/icons-material";
import { FieldArray, FieldArrayRenderProps, useFormikContext } from "formik";
import { FC, Fragment, useCallback } from "react";

import { UserRole } from "~/src/__generated__/schema";
import { SymbolOutlineButton } from "~/src/components/common/Button";
import { HorizontalSeparator } from "~/src/components/common/Separator";

import { FormValues } from "../../validationSchema";

import { PopupImageFormContainer } from "./PopupImageFormContainer";

type Props = {
  role: UserRole;
};

export const PopupImageArrayFormContainer: FC<Props> = ({ role }) => {
  const { values } = useFormikContext<FormValues>();

  const render = useCallback(
    (arrayHelpers: FieldArrayRenderProps) => (
      <>
        {(values.popupImages || []).map((popupImage, index) => {
          return (
            <Fragment key={index}>
              <PopupImageFormContainer
                index={index}
                // eslint-disable-next-line react/jsx-no-bind
                onDelete={() => {
                  arrayHelpers.remove(index);
                }}
                role={role}
              />
              <HorizontalSeparator />
            </Fragment>
          );
        })}

        <VStack alignSelf={"flex-start"} alignItems={"flex-start"}>
          <SymbolOutlineButton
            icon={<AddCircleOutlinedIcon />}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => {
              if (values.popupImages.length < 8) arrayHelpers.push({});
            }}
            isDisabled={values.popupImages.length >= 8}
          >
            画像を追加
          </SymbolOutlineButton>
          <Text fontSize={"10px"}>※ 画像は最大5枚まで追加可能です。</Text>
        </VStack>
      </>
    ),
    [role, values.popupImages]
  );

  return <FieldArray name="popupImages" render={render} />;
};
