import { Image } from "@chakra-ui/react";
import { format } from "date-fns";
import { FC, Fragment, useCallback, useContext, useMemo } from "react";
import { graphql, useRefetchableFragment } from "react-relay";
import { NavLink } from "react-router-dom";

import {
  PatternContentJudgeStatus,
  UserRole,
} from "~/src/__generated__/schema";
import { PopupPatternTableDataRow_popup$key } from "~/src/__relay_artifacts__/PopupPatternTableDataRow_popup.graphql";
import {
  PopupPatternTableDataRow_RefetchQuery as RefetchQuery,
  PopupPatternTableDataRow_RefetchQueryVariables as RefetchVariables,
} from "~/src/__relay_artifacts__/PopupPatternTableDataRow_RefetchQuery.graphql";
import {
  TableDataColumn,
  TableDataRow,
} from "~/src/components/common/tables/Table";
import { DistributionStatusSwitch } from "~/src/components/features/global/DistributionStatusSwitch";
import { DateRangeContext } from "~/src/components/features/global/HeaderNavigation/DateRangeField";
import { useUpdateDebounce } from "~/src/lib/react-use";

import { FormValues } from "./PopupPatternFilterButton";
import { PopupPatternTableAcitons } from "./PopupPatternTableActions";
import { ROW_HEIGHT } from "./styles";

export type Props = {
  popupRef: PopupPatternTableDataRow_popup$key;
  siteSlug: string;
  popupGroupSlug: string;
  dataColumns: string[];
  connectionId: string;
  filterValues: Pick<FormValues, "sourceId" | "device">;
  role: UserRole;
};

const popupFragment = graphql`
  fragment PopupPatternTableDataRow_popup on Popup
  @refetchable(queryName: "PopupPatternTableDataRow_RefetchQuery")
  @argumentDefinitions(input: { type: "ReportAnalyzerInput!" }) {
    id
    slug
    name
    status
    isArchive
    kind
    judgeStatus
    weight
    clickCvr(input: $input)
    clickCvUserCount(input: $input)
    clickUserCount(input: $input)
    clickUserRate(input: $input)
    cvr(input: $input)
    impressionCvUserCount(input: $input)
    impressionCvr(input: $input)
    impressionUserCount(input: $input)
    readyCvUserCount(input: $input)
    totalCvUserCount(input: $input)
    totalUserCount(input: $input)
    thumbnailImageUrl
    page {
      slug
      name
      funnel {
        name
        slug
      }
    }
  }
`;

const toStringFromJudgeStatus = (status: PatternContentJudgeStatus): string => {
  switch (status) {
    case "APPROVED":
      return "承認";
    case "REJECTED":
      return "却下";
    default:
      return "未承認";
  }
};

export const PopupPatternTableDataRow: FC<Props> = ({
  siteSlug,
  popupGroupSlug,
  popupRef,
  dataColumns,
  connectionId,
  filterValues,
  role,
}) => {
  const {
    dateRange: [startOn, endOn],
  } = useContext(DateRangeContext);

  const [popup, refetch] = useRefetchableFragment<
    RefetchQuery,
    PopupPatternTableDataRow_popup$key
  >(popupFragment, popupRef);

  const refetchVariables = useMemo<Partial<RefetchVariables>>(
    () => ({
      input: {
        startOn: format(startOn, "yyyy-MM-dd"),
        endOn: format(endOn, "yyyy-MM-dd"),
        sourceId: filterValues.sourceId,
        device: filterValues.device,
      },
    }),
    [endOn, filterValues.device, filterValues.sourceId, startOn]
  );

  const handleRefetch = useCallback(() => {
    refetch(refetchVariables);
  }, [refetch, refetchVariables]);

  useUpdateDebounce(
    () => {
      handleRefetch();
    },
    100,
    [handleRefetch]
  );

  return (
    <TableDataRow height={ROW_HEIGHT}>
      <TableDataColumn
        type="text"
        isLink={popup.kind === "B_PATTERN"}
        data={
          popup.kind === "B_PATTERN" ? (
            <NavLink
              to={`/sites/${siteSlug}/popup_groups/${popupGroupSlug}/patterns/${popup.slug}`}
            >
              {popup.name}
            </NavLink>
          ) : (
            popup.name
          )
        }
      />
      <TableDataColumn
        type="actions"
        data={
          <DistributionStatusSwitch
            id={popup.id}
            name={popup.name || "ポップアップ"}
            isChecked={popup.status === "ACTIVE"}
          />
        }
      />
      {role !== "SAAS_CLIENT" && (
        <TableDataColumn
          type="text"
          data={toStringFromJudgeStatus(popup.judgeStatus)}
        />
      )}
      <TableDataColumn
        type="text"
        data={popup.weight ? popup.weight : "ランダム配信"}
      />
      <TableDataColumn
        type="text"
        data={
          popup.thumbnailImageUrl && (
            <Image
              src={popup.thumbnailImageUrl}
              boxSize={"60px"}
              fit={"contain"}
            />
          )
        }
      />
      {(dataColumns || []).map((dataColumn) => (
        <Fragment key={dataColumn}>
          {dataColumn === "totalUserCount" && (
            <TableDataColumn type="text" data={popup.totalUserCount} />
          )}
          {dataColumn === "impressionUserCount" && (
            <TableDataColumn type="text" data={popup.impressionUserCount} />
          )}
          {dataColumn === "clickUserCount" && (
            <TableDataColumn type="text" data={popup.clickUserCount} />
          )}
          {dataColumn === "totalCV" && (
            <TableDataColumn type="text" data={popup.totalCvUserCount} />
          )}
          {dataColumn === "clickCV" && (
            <TableDataColumn type="text" data={popup.clickCvUserCount} />
          )}
          {dataColumn === "impressionCV" && (
            <TableDataColumn type="text" data={popup.impressionCvUserCount} />
          )}
          {dataColumn === "readyCV" && (
            <TableDataColumn type="text" data={popup.readyCvUserCount} />
          )}
          {dataColumn === "CTR" && (
            <TableDataColumn
              type="text"
              data={`${popup.clickUserRate.toFixed(2)}%`}
            />
          )}
          {dataColumn === "CVR" && (
            <TableDataColumn type="text" data={`${popup.cvr.toFixed(2)}%`} />
          )}
          {dataColumn === "clickCVR" && (
            <TableDataColumn
              type="text"
              data={`${popup.clickCvr.toFixed(2)}%`}
            />
          )}
          {dataColumn === "impressionCVR" && (
            <TableDataColumn
              type="text"
              data={`${popup.impressionCvr.toFixed(2)}%`}
            />
          )}
        </Fragment>
      ))}
      <TableDataColumn
        type="actions"
        data={
          popup.kind === "B_PATTERN" && (
            <PopupPatternTableAcitons
              siteSlug={siteSlug}
              popupGroupSlug={popupGroupSlug}
              popup={popup}
              connectionId={connectionId}
            />
          )
        }
      />
    </TableDataRow>
  );
};
