import { Box, SimpleGrid } from "@chakra-ui/react";
import { format } from "date-fns";
import { zipWith } from "ramda";
import { FC, useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { FunnelTransitionReportSection_site$key } from "~/src/__relay_artifacts__/FunnelTransitionReportSection_site.graphql";
import { AreaGraph } from "~/src/components/features/dashboard/AreaGraph";

export type Props = { siteRef: FunnelTransitionReportSection_site$key };

const fragment = graphql`
  fragment FunnelTransitionReportSection_site on Site {
    dailyLandingPageDropRates(input: $analyzerInput)
    dailyAverageStayTimes(input: $analyzerInput)
    dailyAverageConversionTimes(input: $analyzerInput)
  }
`;

export const FunnelTransitionReportSection: FC<Props> = ({ siteRef }) => {
  const site = useFragment(fragment, siteRef);

  const landingPageDropRates = useMemo(() => {
    const dates = site.dailyLandingPageDropRates.xAxis.categories;
    const datas = site.dailyLandingPageDropRates.series[0].data;
    return zipWith<any, any, any>(
      (x, y) => {
        return { x: format(new Date(x), "yyyy/MM/dd"), y };
      },
      dates,
      datas
    );
  }, [site.dailyLandingPageDropRates]);

  const averageStayTimes = useMemo(() => {
    const dates = site.dailyAverageStayTimes.xAxis.categories;
    const datas = site.dailyAverageStayTimes.series[0].data;
    return zipWith<any, any, any>(
      (x, y) => {
        return { x: format(new Date(x), "yyyy/MM/dd"), y };
      },
      dates,
      datas
    );
  }, [site.dailyAverageStayTimes]);

  const averageConversionTimes = useMemo(() => {
    const dates = site.dailyAverageConversionTimes.xAxis.categories;
    const datas = site.dailyAverageConversionTimes.series[0].data;
    return zipWith<any, any, any>(
      (x, y) => {
        return { x: format(new Date(x), "yyyy/MM/dd"), y };
      },
      dates,
      datas
    );
  }, [site.dailyAverageConversionTimes]);

  return (
    <SimpleGrid columns={2} spacing={8} mt={3}>
      <Box>
        <AreaGraph
          title="日別推移｜LP離脱率"
          toolTipTitle="LP離脱率(%)"
          data={landingPageDropRates}
        />
      </Box>
      <Box>
        <AreaGraph
          title="日別推移｜平均滞在時間"
          toolTipTitle="平均滞在時間(秒)"
          data={averageStayTimes}
        />
      </Box>
      <Box>
        <AreaGraph
          title="日別推移｜CVまでの平均時間"
          toolTipTitle="CVまでの平均時間(秒)"
          data={averageConversionTimes}
        />
      </Box>
    </SimpleGrid>
  );
};
