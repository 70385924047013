import "~/src/lib/yup/setLocale";

import { ChakraProvider } from "@chakra-ui/react";
import { FC } from "react";
import { CookiesProvider } from "react-cookie";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { BrowserRouter } from "react-router-dom";

import { DateRangeProvider } from "~/src/components/features/global/HeaderNavigation";
import { UserProvider } from "~/src/contexts";
import { MuiThemeProvider } from "~/src/lib/mui";
import { RootRoutes } from "~/src/routes";
import { theme } from "~/src/styles";

import { FirebaseRelayAdapter } from "./FirebaseRelayAdapter";

export const App: FC = () => {
  return (
    <BrowserRouter>
      <MuiThemeProvider>
        <ChakraProvider theme={theme}>
          <CookiesProvider>
            <DndProvider backend={HTML5Backend}>
              <DateRangeProvider>
                <UserProvider>
                  <FirebaseRelayAdapter>
                    <RootRoutes />
                  </FirebaseRelayAdapter>
                </UserProvider>
              </DateRangeProvider>
            </DndProvider>
          </CookiesProvider>
        </ChakraProvider>
      </MuiThemeProvider>
    </BrowserRouter>
  );
};
