import { SearchOutlined as SearchOutlinedIcon } from "@mui/icons-material";
import { ChangeEventHandler, FC, useCallback } from "react";

import { TextField } from "~/src/components/common/TextField";
import { chakraFactory } from "~/src/lib/chakra-ui";

export type Props = {
  searchText: string;
  onChange: (value: string) => void;
};

const StyledTextField = chakraFactory(TextField, {
  baseStyle: {
    width: "300px",
  },
});

export const TableSearchField: FC<Props> = ({ searchText, onChange }) => {
  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => onChange(e.target.value),
    [onChange]
  );

  return (
    <StyledTextField
      type="text"
      mode="outline"
      name="search"
      value={searchText}
      placeholder="検索"
      icon={<SearchOutlinedIcon />}
      onChange={handleChange}
    />
  );
};
