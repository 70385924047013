/**
 * @generated SignedSource<<048d28192aeaef3bf3a35ddf8d789f12>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ABTestPatternNameReport_site$data = {
  readonly slug: string;
  readonly url: string;
  readonly reportAbtest: {
    readonly rows: ReadonlyArray<{
      readonly abtestScenarioPageTitle: string | null;
      readonly abtestScenarioPageGroupTitle: string | null;
      readonly funnelName: string | null;
      readonly accessDate: string | null;
      readonly uniqueUser: number;
      readonly conversionUser: number;
      readonly cvr: number;
      readonly averageStayTime: number;
      readonly pcRate: number;
      readonly mobileRate: number;
      readonly tabletRate: number;
      readonly abtestScenarioPageSlug: string | null;
      readonly abtestScenarioPageGroupSlug: string | null;
    }>;
    readonly summary: ReadonlyArray<{
      readonly abtestScenarioPageTitle: string | null;
      readonly abtestScenarioPageGroupTitle: string | null;
      readonly funnelName: string | null;
      readonly accessDate: string | null;
      readonly uniqueUser: number;
      readonly conversionUser: number;
      readonly cvr: number;
      readonly averageStayTime: number;
      readonly pcRate: number;
      readonly mobileRate: number;
      readonly tabletRate: number;
    }>;
    readonly graph: ReadonlyArray<{
      readonly abtestScenarioPageId: string | null;
      readonly abtestScenarioPageTitle: string | null;
      readonly abtestScenarioPageGroupTitle: string | null;
      readonly funnelName: string | null;
      readonly accessDate: string | null;
      readonly uniqueUser: number;
      readonly conversionUser: number;
      readonly cvr: number;
      readonly averageStayTime: number;
      readonly pcRate: number;
      readonly mobileRate: number;
      readonly tabletRate: number;
    }>;
    readonly summaryGraph: ReadonlyArray<{
      readonly accessDate: string | null;
      readonly uniqueUser: number;
      readonly conversionUser: number;
      readonly cvr: number;
      readonly averageStayTime: number;
      readonly pcRate: number;
      readonly mobileRate: number;
      readonly tabletRate: number;
    }>;
  };
  readonly " $fragmentType": "ABTestPatternNameReport_site";
};
export type ABTestPatternNameReport_site = ABTestPatternNameReport_site$data;
export type ABTestPatternNameReport_site$key = {
  readonly " $data"?: ABTestPatternNameReport_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"ABTestPatternNameReport_site">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "abtestScenarioPageTitle",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "abtestScenarioPageGroupTitle",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "funnelName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accessDate",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uniqueUser",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "conversionUser",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cvr",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "averageStayTime",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pcRate",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mobileRate",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tabletRate",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "filter"
    },
    {
      "kind": "RootArgument",
      "name": "kind"
    },
    {
      "kind": "RootArgument",
      "name": "order"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ABTestPatternNameReport_site",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "filter"
        },
        {
          "kind": "Variable",
          "name": "kind",
          "variableName": "kind"
        },
        {
          "kind": "Variable",
          "name": "order",
          "variableName": "order"
        }
      ],
      "concreteType": "AbtestReport",
      "kind": "LinkedField",
      "name": "reportAbtest",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AbtestReportRow",
          "kind": "LinkedField",
          "name": "rows",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            (v9/*: any*/),
            (v10/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "abtestScenarioPageSlug",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "abtestScenarioPageGroupSlug",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AbtestReportRow",
          "kind": "LinkedField",
          "name": "summary",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            (v9/*: any*/),
            (v10/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AbtestReportRow",
          "kind": "LinkedField",
          "name": "graph",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "abtestScenarioPageId",
              "storageKey": null
            },
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            (v9/*: any*/),
            (v10/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AbtestReportRow",
          "kind": "LinkedField",
          "name": "summaryGraph",
          "plural": true,
          "selections": [
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            (v9/*: any*/),
            (v10/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};
})();

(node as any).hash = "5e531d04542f76a0e4ed50b6a1c54b99";

export default node;
