import { Box, Flex, HStack, Text, VStack } from "@chakra-ui/react";
import { Form, Formik, FormikConfig } from "formik";
import { FC } from "react";
import { Edge, Node } from "react-flow-renderer";

import { GraphQLEnums } from "~/src/__generated__/GraphQLEnums";
import { UserRole } from "~/src/__generated__/schema";
import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormControlGroup } from "~/src/components/common/forms/FormControlGroup";
import { FormikColorField } from "~/src/components/common/forms/FormikColorField";
import { FormikFormButtons } from "~/src/components/common/forms/FormikFormButtons";
import { FormikSelectField } from "~/src/components/common/forms/FormikSelectField";
import { FormikTextField } from "~/src/components/common/forms/FormikTextField";
import { HorizontalSeparator } from "~/src/components/common/Separator";
import { ImageUploadField } from "~/src/components/features/uploads";

import { NodeData } from "../ChatbotFlowForms";

import { ChatbotFlowForm } from "./ChatbotFlowForm";
import { QuestionCsvField } from "./QuestionCsvField";
import { FormValues, validationSchema } from "./validationSchema";

export type Props = {
  role: UserRole;
  initialValues?: FormValues;
  enableWeightFields: boolean;
  onCancelClick: () => void;
  onSubmit: FormikConfig<FormValues>["onSubmit"];
  nodes?: Node<NodeData>[];
  edges?: Edge<any>[];
};

const defaultInitialValues: FormValues = {
  actionKind: "BUTTON_CLICK",
  timing: 0,
  botKind: "FORM",
  colorKind: "DEFAULT",
  displayKind: "LEFT_BOTTOM",
  name: "",
  openButtonText: "",
  notFoundMessage: "",
  submitMethodKind: "GET",
  title: "",
  weight: 1,
  xOffset: null,
  xOffsetUnit: "px",
  yOffset: null,
  yOffsetUnit: "px",
  questionFinishMessage: "",
  windowColor: "#ffffff",
  titleTextColor: "#ffffff",
  chatFormColor: "#ffffff",
  botTextColor: "#ffffff",
  botBackgroundColor: "#ffffff",
  clientTextColor: "#ffffff",
  clientBackgroundColor: "#ffffff",
  toFormButtonImageUrl: undefined,
  openButtonImageUrl: undefined,
  botImageUrl: undefined,
  botImage: undefined,
  openButtonImage: undefined,
  toFormButtonImage: undefined,
  originalPatternWeight: 1,
  questionCsvUrl: undefined,
  questionCsv: undefined,
  honeycombCode: null,
  flowNodes: [],
  flowEdges: [],
  replies: [],
  chatbotApiResults: [],
};

const unitOptions = [
  { label: "px", value: "px" },
  { label: "%", value: "%" },
];

export const ChatbotPatternForm: FC<Props> = ({
  initialValues = defaultInitialValues,
  enableWeightFields,
  onCancelClick,
  onSubmit,
  role,
  nodes = [],
  edges = [],
}) => (
  <Formik<FormValues>
    enableReinitialize
    initialValues={initialValues}
    validationSchema={validationSchema}
    validateOnMount={false}
    validateOnChange={false}
    onSubmit={onSubmit}
  >
    {({ values }) => (
      <Form>
        <VStack spacing="24px" alignItems="flex-start">
          {enableWeightFields && (
            <FormControlGroup>
              <FormControl required label="パターン名">
                <Text fontSize={"13px"} fontWeight={400}>
                  デフォルト
                </Text>
              </FormControl>
              <FormControl required label="配信比率">
                <FormikTextField
                  type="number"
                  mode="fill"
                  name="originalPatternWeight"
                />
                <Text fontSize={"13px"} textColor={"#D9534F"}>
                  ※0以上の数値を入力してください（より大きな数が優先されます）
                  <br />
                  ※0は配信対象外です
                </Text>
              </FormControl>
            </FormControlGroup>
          )}

          <FormControlGroup>
            <FormControl required label="パターン名">
              <FormikTextField
                type="text"
                mode="fill"
                name="name"
                rightAdornment={`${values.name.length}/50`}
              />
            </FormControl>
            {enableWeightFields && (
              <FormControl required label="配信比率">
                <FormikTextField type="number" mode="fill" name="weight" />
                <Text fontSize={"13px"} textColor={"#D9534F"}>
                  ※0以上の数値を入力してください（より大きな数が優先されます）
                  <br />
                  ※0は配信対象外です
                </Text>
              </FormControl>
            )}

            <Box mt={4} />
            <HorizontalSeparator />
            <Box mb={4} />

            <FormControl label="表示場所">
              <FormikSelectField
                name="displayKind"
                options={GraphQLEnums.ChatbotDisplayKind}
              />
              <VStack mt={"8px"}>
                <FormControl
                  label={`画面${
                    values.displayKind.split("_")[0] === "LEFT" ? "左" : "右"
                  }端から`}
                  dense
                >
                  <HStack>
                    <Box w={"100%"} minW={"100px"} maxW={"200px"}>
                      <FormikTextField
                        type="number"
                        mode="fill"
                        name="xOffset"
                      />
                    </Box>
                    <Box minW={"80px"}>
                      <FormikSelectField
                        name="xOffsetUnit"
                        options={unitOptions}
                      />
                    </Box>
                  </HStack>
                </FormControl>
                <FormControl
                  label={`画面${
                    values.displayKind.split("_")[1] === "TOP" ? "上" : "下"
                  }端から`}
                  dense
                >
                  <HStack>
                    <Box w={"100%"} minW={"100px"} maxW={"200px"}>
                      <FormikTextField
                        type="number"
                        mode="fill"
                        name="yOffset"
                      />
                    </Box>
                    <Box minW={"80px"}>
                      <FormikSelectField
                        name="yOffsetUnit"
                        options={unitOptions}
                      />
                    </Box>
                  </HStack>
                </FormControl>
              </VStack>
            </FormControl>

            <FormControl label="表示タイミング">
              <Flex>
                <Box w={"100%"} minW={"100px"} maxW={"200px"}>
                  <FormikSelectField
                    name="actionKind"
                    options={GraphQLEnums.ChatbotActionKind}
                  />
                </Box>
                {values.actionKind === "DURATION" && (
                  <Box w={"100%"} minW={"100px"} maxW={"200px"}>
                    <FormikTextField type="number" mode="fill" name="timing" />
                  </Box>
                )}
              </Flex>
            </FormControl>

            <FormControl label="配色デザイン">
              <FormikSelectField
                name="colorKind"
                options={GraphQLEnums.ChatbotColorKind}
              />

              {values.colorKind === "CUSTOM_COLOR" && (
                <VStack spacing="16px" mt="16px">
                  <FormControl label="外枠の色">
                    <FormikColorField name="windowColor" />
                  </FormControl>
                  <FormControl label="ヘッダーテキストの色">
                    <FormikColorField name="titleTextColor" />
                  </FormControl>
                  <FormControl label="背景色">
                    <FormikColorField name="chatFormColor" />
                  </FormControl>
                  <FormControl label="ボットの文字色">
                    <FormikColorField name="botTextColor" />
                  </FormControl>
                  <FormControl label="吹き出し 背景色">
                    <FormikColorField name="botBackgroundColor" />
                  </FormControl>
                  <FormControl label="エンドユーザー 文字色">
                    <FormikColorField name="clientTextColor" />
                  </FormControl>
                  <FormControl label="エンドユーザー 吹き出し背景色">
                    <FormikColorField name="clientBackgroundColor" />
                  </FormControl>
                </VStack>
              )}
            </FormControl>

            {(role === "ADMIN" ||
              role === "SUPER" ||
              role === "ADMIN_ALL" ||
              role === "CLIENT") && (
              <FormControl label="YDAコード">
                <FormikTextField
                  name="honeycombCode"
                  type="text"
                  mode="fill"
                  placeholder="iframeで読み込むURL"
                />
              </FormControl>
            )}

            <FormControl label="ボタンに表示するテキスト">
              <FormikTextField
                type="text"
                mode="fill"
                name="openButtonText"
                rightAdornment={`${values.openButtonText.length}/15`}
              />
            </FormControl>

            <FormControl label="ボタンに表示する画像">
              <ImageUploadField
                name={"openButtonImage"}
                src={initialValues.openButtonImageUrl}
              />
            </FormControl>

            <FormControl label="ボットヘッダーに表示するテキスト">
              <FormikTextField
                type="text"
                mode="fill"
                name="title"
                rightAdornment={`${values.title.length}/15`}
              />
            </FormControl>

            <FormControl label="トークアイコン画像">
              <ImageUploadField
                name={"botImage"}
                src={initialValues.botImageUrl}
              />
            </FormControl>

            <Box mt={4} />
            <HorizontalSeparator />
            <Box mb={4} />

            {values.botKind === "QUESTION" && (
              <>
                <FormControl required label="質問データ">
                  <QuestionCsvField url={initialValues.questionCsvUrl} />
                </FormControl>
                <FormControl label="質問が見つからない時のメッセージ">
                  <FormikTextField
                    type="text"
                    mode="fill"
                    name="notFoundMessage"
                    rightAdornment={`${values.notFoundMessage.length}/50`}
                  />
                </FormControl>
                <FormControl label="質問終了時のメッセージ">
                  <FormikTextField
                    type="text"
                    mode="fill"
                    name="questionFinishMessage"
                    rightAdornment={`${values.questionFinishMessage.length}/50`}
                  />
                </FormControl>
              </>
            )}
            {(values.botKind === "QUESTION_AND_FORM" ||
              values.botKind === "FORM_AND_QUESTION") && (
              <>
                <FormControl label="フォーム送信メソッド">
                  <FormikSelectField
                    name="submitMethodKind"
                    options={GraphQLEnums.ChatbotSubmitMethodKind}
                  />
                </FormControl>
                <FormControl label={"質問からフォームに\n移動するボタン画像"}>
                  <ImageUploadField
                    name={"toFormButtonImage"}
                    src={initialValues.toFormButtonImageUrl}
                  />
                </FormControl>
                <FormControl required label="質問データ">
                  <QuestionCsvField url={initialValues.questionCsvUrl} />
                </FormControl>
                <FormControl label="質問が見つからない時のメッセージ">
                  <FormikTextField
                    type="text"
                    mode="fill"
                    name="notFoundMessage"
                    rightAdornment={`${values.notFoundMessage.length}/50`}
                  />
                </FormControl>
                <FormControl label="質問終了時のメッセージ">
                  <FormikTextField
                    type="text"
                    mode="fill"
                    name="questionFinishMessage"
                    rightAdornment={`${values.questionFinishMessage.length}/50`}
                  />
                </FormControl>
              </>
            )}
            {values.botKind !== "QUESTION" && (
              <>
                <FormControl label="シナリオ設定"></FormControl>
                <ChatbotFlowForm nodes={nodes} edges={edges} />
              </>
            )}
          </FormControlGroup>

          <FormikFormButtons onCancelClick={onCancelClick} />
        </VStack>
      </Form>
    )}
  </Formik>
);
