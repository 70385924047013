/**
 * @generated SignedSource<<2b1d0739fd3ad42ec99f5f067bbc2879>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AbtestScenarioPageContentTextReplaceActionKind = "TEXT_REPLACEMENT" | "DIV_ELEMENT_ADDITION" | "ATTRIBUTE_REPLACEMENT" | "%future added value";
export type AbtestScenarioPageContentTextReplaceTargetKind = "ID" | "SELECTOR" | "XPATH" | "%future added value";
export type UpdateAbtestScenarioPageContentTextReplaceInput = {
  clientMutationId?: string | null;
  abtestScenarioPageContentId: string;
  targetType?: AbtestScenarioPageContentTextReplaceTargetKind | null;
  actionType?: AbtestScenarioPageContentTextReplaceActionKind | null;
  targetValue?: string | null;
  targetAttribute?: string | null;
  replaceValue?: string | null;
};
export type ABTestPatternEditScreenContainer_UpdateTextReplaceContentMutation$variables = {
  input: UpdateAbtestScenarioPageContentTextReplaceInput;
};
export type ABTestPatternEditScreenContainer_UpdateTextReplaceContentMutationVariables = ABTestPatternEditScreenContainer_UpdateTextReplaceContentMutation$variables;
export type ABTestPatternEditScreenContainer_UpdateTextReplaceContentMutation$data = {
  readonly updateAbtestScenarioPageTextReplace: {
    readonly abtestScenarioPageContent: {
      readonly __typename: "AbtestScenarioPageContentTextReplace";
      readonly id: string;
      readonly targetType: AbtestScenarioPageContentTextReplaceTargetKind;
      readonly targetValue: string;
      readonly targetAttribute: string;
      readonly actionType: AbtestScenarioPageContentTextReplaceActionKind;
      readonly replaceValue: string | null;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null;
  } | null;
};
export type ABTestPatternEditScreenContainer_UpdateTextReplaceContentMutationResponse = ABTestPatternEditScreenContainer_UpdateTextReplaceContentMutation$data;
export type ABTestPatternEditScreenContainer_UpdateTextReplaceContentMutation = {
  variables: ABTestPatternEditScreenContainer_UpdateTextReplaceContentMutationVariables;
  response: ABTestPatternEditScreenContainer_UpdateTextReplaceContentMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "targetType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "targetValue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "targetAttribute",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actionType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "replaceValue",
      "storageKey": null
    }
  ],
  "type": "AbtestScenarioPageContentTextReplace",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ABTestPatternEditScreenContainer_UpdateTextReplaceContentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAbtestScenarioPageContentTextReplacePayload",
        "kind": "LinkedField",
        "name": "updateAbtestScenarioPageTextReplace",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "abtestScenarioPageContent",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ABTestPatternEditScreenContainer_UpdateTextReplaceContentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAbtestScenarioPageContentTextReplacePayload",
        "kind": "LinkedField",
        "name": "updateAbtestScenarioPageTextReplace",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "abtestScenarioPageContent",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "25d22f4462f9420ec344aeb81c459ecd",
    "id": null,
    "metadata": {},
    "name": "ABTestPatternEditScreenContainer_UpdateTextReplaceContentMutation",
    "operationKind": "mutation",
    "text": "mutation ABTestPatternEditScreenContainer_UpdateTextReplaceContentMutation(\n  $input: UpdateAbtestScenarioPageContentTextReplaceInput!\n) {\n  updateAbtestScenarioPageTextReplace(input: $input) {\n    abtestScenarioPageContent {\n      __typename\n      ... on AbtestScenarioPageContentTextReplace {\n        id\n        targetType\n        targetValue\n        targetAttribute\n        actionType\n        replaceValue\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "62bcb8e8a3873207c0b7169af604997f";

export default node;
