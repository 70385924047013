import { format } from "date-fns";
import { FC, useContext, useEffect } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";

import { DashboardPageContainer_Query } from "~/src/__relay_artifacts__/DashboardPageContainer_Query.graphql";
import { DateRangeContext } from "~/src/components/features/global/HeaderNavigation/DateRangeField";
import { PageLayout } from "~/src/components/features/global/PageLayout";
import { useDateRangeSearchParams } from "~/src/lib/hooks";

import { ReportListContainer } from "./presentations";

const query = graphql`
  query DashboardPageContainer_Query(
    $slug: String!
    $analyzerInput: ReportAnalyzerInput!
  ) {
    viewer {
      id
    }
    ...ReportListContainer_query
  }
`;

export type Props = {};

export const DashboardPageContainer: FC<Props> = () => {
  const { siteSlug = "" } = useParams();
  const { dateRange } = useContext(DateRangeContext);
  const { attachDateRangeParams } = useDateRangeSearchParams();

  const queryRef = useLazyLoadQuery<DashboardPageContainer_Query>(query, {
    slug: siteSlug,
    analyzerInput: {
      startOn: format(dateRange[0], "yyyy-MM-dd"),
      endOn: format(dateRange[1], "yyyy-MM-dd"),
    },
  });

  useEffect(() => {
    attachDateRangeParams();
  });

  return (
    <PageLayout title="ダッシュボード">
      <ReportListContainer queryRef={queryRef} />
    </PageLayout>
  );
};
