/**
 * @generated SignedSource<<a41a262409b59136eae024aaa27b992a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AgeRangeReportGraph_site$data = {
  readonly reportAgeRange: any;
  readonly " $fragmentType": "AgeRangeReportGraph_site";
};
export type AgeRangeReportGraph_site = AgeRangeReportGraph_site$data;
export type AgeRangeReportGraph_site$key = {
  readonly " $data"?: AgeRangeReportGraph_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"AgeRangeReportGraph_site">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "input"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AgeRangeReportGraph_site",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "input",
          "variableName": "input"
        }
      ],
      "kind": "ScalarField",
      "name": "reportAgeRange",
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "4297b8efab1626f2e43304f43d243f7a";

export default node;
