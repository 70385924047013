import { Box, Flex, HStack } from "@chakra-ui/layout";
import { FC, useEffect, useMemo, useState } from "react";
import { graphql, usePaginationFragment } from "react-relay";
import { NavLink } from "react-router-dom";

import { NotificationListPage_Mutation } from "~/src/__relay_artifacts__/NotificationListPage_Mutation.graphql";
import { NotificationListPage_root$key } from "~/src/__relay_artifacts__/NotificationListPage_root.graphql";
import { SolidButton } from "~/src/components/common/Button";
import {
  Table,
  TableHeaderColumn,
  TableHeaderRow,
} from "~/src/components/common/tables/Table";
import {
  TablePagination,
  useTablePaginationProps,
} from "~/src/components/common/tables/TablePagination";
import { PageLayout } from "~/src/components/features/global/PageLayout";
import { useMutationCommit } from "~/src/lib/react-relay";
import { useUpdateDebounce } from "~/src/lib/react-use";

import {
  FormValues as FilterValues,
  NotificationFilterButton,
} from "./NotificationFilterButton";
import { NotificationTableDataRow } from "./NotificationTableDataRow";

type Props = {
  rootRef: NotificationListPage_root$key;
};

const fragment = graphql`
  fragment NotificationListPage_root on Query
  @refetchable(queryName: "NotificationListPagePaginationQuery")
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 120 }
    cursor: { type: "String" }
    published: { type: "Boolean" }
  ) {
    notifications(first: $count, after: $cursor, published: $published)
      @connection(key: "NotificationListPage_root_notifications") {
      __id
      totalCount
      edges {
        node {
          id
          ...NotificationTableDataRow_notification
        }
      }
    }
    viewer {
      role
      seenNotificationsCount
    }
  }
`;

const mutation = graphql`
  mutation NotificationListPage_Mutation($input: MarkAsReadNotificationInput!) {
    markAsReadNotification(input: $input) {
      user {
        seenNotificationsCount
      }
    }
  }
`;

export const NotificationListPage: FC<Props> = ({ rootRef }) => {
  const [filterValues, setFilterValues] = useState<FilterValues>({
    published: true,
  });

  const {
    data: root,
    loadNext,
    hasNext,
    refetch,
  } = usePaginationFragment(fragment, rootRef);
  const { tablePaginationProps } = useTablePaginationProps({
    totalCount: root.notifications?.totalCount || 0,
    hasNext,
    loadNext,
    refetch,
  });

  const notificationsCount = root.notifications?.totalCount || 0;
  const user = root.viewer;
  const seenNotificationCount = user.seenNotificationsCount;

  const notifications = useMemo(() => {
    const from = tablePaginationProps.from;
    const to = tablePaginationProps.to;
    const edges = root.notifications?.edges || [];
    return edges.slice(from, to).map((edge) => {
      const node = edge?.node;
      if (!node) throw new Error("assertion failed");
      return node;
    });
  }, [
    root.notifications?.edges,
    tablePaginationProps.from,
    tablePaginationProps.to,
  ]);

  const mutate = useMutationCommit<NotificationListPage_Mutation>(mutation);

  useEffect(() => {
    if (filterValues.published && notificationsCount > seenNotificationCount) {
      mutate({ variables: { input: {} } });
    }
  }, [
    filterValues.published,
    mutate,
    notificationsCount,
    seenNotificationCount,
  ]);

  useUpdateDebounce(
    () => {
      refetch({
        count: tablePaginationProps.perPage,
        ...filterValues,
      });
    },
    500,
    [filterValues, refetch, tablePaginationProps.perPage]
  );

  return (
    <PageLayout title="お知らせ">
      <Box my="16px">
        {user.role === "SUPER" && (
          <>
            <HStack justifyContent="flex-end" mb="16px">
              <NavLink to="/notifications/new">
                <SolidButton>追加</SolidButton>
              </NavLink>
            </HStack>
            <Flex justifyContent="flex-end">
              <HStack spacing="12px">
                <NotificationFilterButton
                  filterValues={filterValues}
                  onSubmit={setFilterValues}
                />
              </HStack>
            </Flex>
          </>
        )}

        <Table>
          <TableHeaderRow>
            <TableHeaderColumn>カテゴリ</TableHeaderColumn>
            <TableHeaderColumn>お知らせ</TableHeaderColumn>
            <TableHeaderColumn>公開日</TableHeaderColumn>
            {user.role === "SUPER" && (
              <TableHeaderColumn>アクション</TableHeaderColumn>
            )}
          </TableHeaderRow>
          {notifications.map((notification) => (
            <NotificationTableDataRow
              key={notification.id}
              notificationRef={notification}
              connectionId={root.notifications?.__id || ""}
              withActions={user.role === "SUPER"}
            />
          ))}
        </Table>
        <TablePagination {...tablePaginationProps} />
      </Box>
    </PageLayout>
  );
};
