import { VStack } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { FC, useCallback, useMemo, useState } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";
import * as yup from "yup";

import { GraphQLEnums } from "~/src/__generated__/GraphQLEnums";
import { PageKind } from "~/src/__generated__/schema";
import { CopyModal_Query } from "~/src/__relay_artifacts__/CopyModal_Query.graphql";
import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormikFormButtons } from "~/src/components/common/forms/FormikFormButtons";
import { FormikSelectField } from "~/src/components/common/forms/FormikSelectField";
import { Modal } from "~/src/components/common/Modal";

export type FormValues = {
  funnelId: string;
  pageKind: PageKind;
  pageId: string;
};

export type Props = {
  initialValues: FormValues;
  onSubmit: (pageId: string) => Promise<void> | void;
  isOpen: boolean;
  onClose: () => void;
};

const query = graphql`
  query CopyModal_Query($siteSlug: String!) {
    site(slug: $siteSlug) {
      pages {
        edges {
          node {
            id
            name
            kind
            sortNumber
            funnel {
              id
              name
            }
          }
        }
      }
      funnels {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

const validationSchema = yup.object({
  funnelId: yup.string().nullable(),
  pageId: yup.string().required(),
  pageKind: yup.string().nullable(),
});

export const CopyModal: FC<Props> = ({
  isOpen,
  onClose,
  initialValues,
  onSubmit,
}) => {
  const { siteSlug = "" } = useParams();
  const [funnelValue, setFunnelValue] = useState<string>(
    initialValues.funnelId
  );
  const [pageKindValue, setPageKindValue] = useState<string>(
    initialValues.pageKind
  );

  const { site } = useLazyLoadQuery<CopyModal_Query>(query, {
    siteSlug,
  });

  const funnelOptions = useMemo(
    () => [
      ...(site.funnels.edges || []).map((edge) => {
        const node = edge?.node;
        if (!node) throw new Error("assertion failed");
        return { label: node.name, value: node.id };
      }),
    ],
    [site.funnels.edges]
  );

  const pageKindOptions = useMemo(
    () => [{ label: "すべて", value: null }, ...GraphQLEnums.PageKind],
    []
  );

  const pageOptions = useMemo(() => {
    return [
      ...(site.pages.edges || []).map((edge) => {
        const node = edge?.node;
        if (!node) throw new Error("assertion failed");
        return {
          label:
            node.kind === "FORM"
              ? `${node.name}(${node.sortNumber - 1})[${node.funnel.name}]`
              : `${node.name}(${node.funnel.name})`,
          value: node.id,
          funnelId: node.funnel.id,
          pageKind: node.kind,
        };
      }),
    ]
      .filter((v) => v.funnelId === funnelValue)
      .filter((v) => v.pageKind === pageKindValue);
  }, [site.pages.edges, funnelValue, pageKindValue]);

  const handleSubmit = useCallback(
    (values: FormValues) => {
      onSubmit(values.pageId);
      onClose();
    },
    [onClose, onSubmit]
  );

  return (
    <Modal title="コピー" isOpen={isOpen} onClose={onClose}>
      <Formik<FormValues>
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount={false}
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        <Form>
          <VStack spacing="16px">
            <FormControl label="ファネル">
              <FormikSelectField
                name="funnelId"
                options={funnelOptions}
                onSetStateAction={setFunnelValue}
              />
            </FormControl>
            <FormControl label="ページタイプ">
              <FormikSelectField
                name="pageKind"
                options={pageKindOptions}
                onSetStateAction={setPageKindValue}
              />
            </FormControl>
            <FormControl label="ページ">
              <FormikSelectField name="pageId" options={pageOptions} />
            </FormControl>
          </VStack>
          <FormikFormButtons onCancelClick={onClose} />
        </Form>
      </Formik>
    </Modal>
  );
};
