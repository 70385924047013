/**
 * @generated SignedSource<<dac8c61ca0d8cf8d94afb8e1daa81bbd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UserRole = "SUPER" | "ADMIN_ALL" | "ADMIN" | "JUDGE" | "CLIENT" | "SAAS_CLIENT" | "%future added value";
export type SiteCreateFormContainer_Query$variables = {};
export type SiteCreateFormContainer_QueryVariables = SiteCreateFormContainer_Query$variables;
export type SiteCreateFormContainer_Query$data = {
  readonly viewer: {
    readonly id: string;
    readonly role: UserRole;
  };
  readonly viewers: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string | null;
        readonly company: string | null;
        readonly email: string;
      } | null;
    } | null> | null;
  };
};
export type SiteCreateFormContainer_QueryResponse = SiteCreateFormContainer_Query$data;
export type SiteCreateFormContainer_Query = {
  variables: SiteCreateFormContainer_QueryVariables;
  response: SiteCreateFormContainer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "role",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "UserConnection",
    "kind": "LinkedField",
    "name": "viewers",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "company",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SiteCreateFormContainer_Query",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SiteCreateFormContainer_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7f6d23bd51c40d4117b8268e7d4663dd",
    "id": null,
    "metadata": {},
    "name": "SiteCreateFormContainer_Query",
    "operationKind": "query",
    "text": "query SiteCreateFormContainer_Query {\n  viewer {\n    id\n    role\n  }\n  viewers {\n    edges {\n      node {\n        id\n        name\n        company\n        email\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b9fe08d7483e151f34c2afa3b649e5b6";

export default node;
