import {
  Cancel as CancelIcon,
  DragIndicator as DragIndicatorIcon,
} from "@mui/icons-material";
import { memo, useCallback, useEffect, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";

import { DndItemTypes } from "~/src/lib/react-dnd";

import { StyledTableItemIconButton, StyledTableItemWrapperBox } from "./styles";
import { DraggableItemObject } from "./types";

export type Props = {
  label: string;
  index: number;
  onSelectedItemHover: (fromIndex: number, toIndex: number) => void;
  onDeleteClick: (index: number) => void;
};

export const SelectedItem = memo<Props>(
  ({ label, index, onSelectedItemHover, onDeleteClick }) => {
    const ref = useRef<HTMLDivElement>(null);

    const [{ opacity, cursor }, dragRef, preview] = useDrag(
      () => ({
        type: DndItemTypes.TABLE_ITEM_SELECT_ITEM,
        item: { label, index } as DraggableItemObject,
        collect: (monitor) => ({
          opacity: monitor.isDragging() ? 0.5 : 1,
          cursor: monitor.isDragging() ? "grabbing" : "grab",
        }),
      }),
      [label, index]
    );

    const [, dropRef] = useDrop(
      () => ({
        accept: DndItemTypes.TABLE_ITEM_SELECT_ITEM,
        hover: (item: DraggableItemObject, monitor) => {
          if (!ref.current) return;
          const dragIndex = item.index;
          const hoverIndex = index;
          if (dragIndex === hoverIndex) return;

          const hoverBoundingRect = ref.current?.getBoundingClientRect();
          const hoverMiddleY =
            (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
          const clientOffset = monitor.getClientOffset();
          const hoverClientY = (clientOffset?.y || 0) - hoverBoundingRect.top;
          if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
          if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

          onSelectedItemHover(dragIndex, hoverIndex);
          item.index = hoverIndex;
        },
      }),
      [index, onSelectedItemHover]
    );

    const handleDeleteClick = useCallback(
      () => onDeleteClick(index),
      [index, onDeleteClick]
    );

    useEffect(() => {
      preview(getEmptyImage(), { captureDraggingState: true });
    }, [preview]);

    return (
      <StyledTableItemWrapperBox
        ref={dragRef(dropRef(ref))}
        cursor={cursor}
        opacity={opacity}
      >
        <DragIndicatorIcon />
        {label}
        <StyledTableItemIconButton
          size="16px"
          icon={<CancelIcon />}
          onClick={handleDeleteClick}
        />
      </StyledTableItemWrapperBox>
    );
  }
);
