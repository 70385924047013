import { HStack } from "@chakra-ui/react";
import { FC, ReactNode } from "react";
import { NavLink } from "react-router-dom";

import { IconButton } from "~/src/components/common/IconButton";
import { chakraFactory } from "~/src/lib/chakra-ui";

type ActionMenu = {
  label: string;
  icon: ReactNode;
  to?: string;
  onClick?: () => void;
};

export type Props = {
  actionMenus: ActionMenu[];
};

const StyledIconButton = chakraFactory(IconButton, {
  baseStyle: {
    width: "28px",
    height: "28px",
  },
});

export const TableActions: FC<Props> = ({ actionMenus }) => (
  <HStack spacing="12px">
    {actionMenus.map((menu, i) =>
      menu.to ? (
        <NavLink to={menu.to} key={i}>
          <StyledIconButton label={menu.label} size="24px" icon={menu.icon} />
        </NavLink>
      ) : (
        <StyledIconButton
          key={i}
          size="24px"
          label={menu.label}
          icon={menu.icon}
          onClick={menu.onClick}
        />
      )
    )}
  </HStack>
);
