import { FC } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";

import { AccountDetailPageContainer_Query } from "~/src/__relay_artifacts__/AccountDetailPageContainer_Query.graphql";

import { AccountDetailPage } from "./presentations";

const query = graphql`
  query AccountDetailPageContainer_Query {
    viewer {
      id
      ...AccountDetailPage_viewer
    }
  }
`;

export const AccountDetailPageContainer: FC = () => {
  const { viewer } = useLazyLoadQuery<AccountDetailPageContainer_Query>(
    query,
    {}
  );

  return <AccountDetailPage userRef={viewer} />;
};
