/**
 * @generated SignedSource<<ae2d9a5a392ea137db7051d8483f92e4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Device = "MOBILE" | "TABLET" | "PC" | "%future added value";
export type AddSiteInput = {
  clientMutationId?: string | null;
  name: string;
  url: string;
  userId: string;
};
export type ReportAnalyzerInput = {
  startOn: string;
  endOn: string;
  device?: Device | null;
  sourceId?: string | null;
  funnelId?: string | null;
};
export type SiteCreateFormContainer_Mutation$variables = {
  connections: ReadonlyArray<string>;
  input: AddSiteInput;
  reportInput: ReportAnalyzerInput;
};
export type SiteCreateFormContainer_MutationVariables = SiteCreateFormContainer_Mutation$variables;
export type SiteCreateFormContainer_Mutation$data = {
  readonly addSite: {
    readonly site: {
      readonly id: string;
      readonly name: string;
      readonly url: string;
      readonly slug: string;
      readonly uniqueUserCount: number;
      readonly conversionUserCount: number;
      readonly conversionUserRate: number;
      readonly popups: {
        readonly totalCount: number;
      };
      readonly abtestScenarioPages: {
        readonly totalCount: number;
      };
      readonly chatbots: {
        readonly totalCount: number;
      };
      readonly funnels: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly landingPage: {
              readonly imageUrl: string | null;
            } | null;
          } | null;
        } | null> | null;
      };
    };
  } | null;
};
export type SiteCreateFormContainer_MutationResponse = SiteCreateFormContainer_Mutation$data;
export type SiteCreateFormContainer_Mutation = {
  variables: SiteCreateFormContainer_MutationVariables;
  response: SiteCreateFormContainer_Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "reportInput"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "reportInput"
  }
],
v7 = {
  "alias": null,
  "args": (v6/*: any*/),
  "kind": "ScalarField",
  "name": "uniqueUserCount",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": (v6/*: any*/),
  "kind": "ScalarField",
  "name": "conversionUserCount",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": (v6/*: any*/),
  "kind": "ScalarField",
  "name": "conversionUserRate",
  "storageKey": null
},
v10 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "PopupConnection",
  "kind": "LinkedField",
  "name": "popups",
  "plural": false,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "AbtestScenarioPageConnection",
  "kind": "LinkedField",
  "name": "abtestScenarioPages",
  "plural": false,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "ChatbotConnection",
  "kind": "LinkedField",
  "name": "chatbots",
  "plural": false,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v14 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": {
      "direction": "DESC",
      "field": "UPDATED_AT"
    }
  }
],
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageUrl",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SiteCreateFormContainer_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddSitePayload",
        "kind": "LinkedField",
        "name": "addSite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Site",
            "kind": "LinkedField",
            "name": "site",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              {
                "alias": null,
                "args": (v14/*: any*/),
                "concreteType": "FunnelConnection",
                "kind": "LinkedField",
                "name": "funnels",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FunnelEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Funnel",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Page",
                            "kind": "LinkedField",
                            "name": "landingPage",
                            "plural": false,
                            "selections": [
                              (v15/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "funnels(first:1,orderBy:{\"direction\":\"DESC\",\"field\":\"UPDATED_AT\"})"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SiteCreateFormContainer_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddSitePayload",
        "kind": "LinkedField",
        "name": "addSite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Site",
            "kind": "LinkedField",
            "name": "site",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              {
                "alias": null,
                "args": (v14/*: any*/),
                "concreteType": "FunnelConnection",
                "kind": "LinkedField",
                "name": "funnels",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FunnelEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Funnel",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Page",
                            "kind": "LinkedField",
                            "name": "landingPage",
                            "plural": false,
                            "selections": [
                              (v15/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "funnels(first:1,orderBy:{\"direction\":\"DESC\",\"field\":\"UPDATED_AT\"})"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "site",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "SiteEdge"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8fbabe791b8b35de6b06fdd358d08ab2",
    "id": null,
    "metadata": {},
    "name": "SiteCreateFormContainer_Mutation",
    "operationKind": "mutation",
    "text": "mutation SiteCreateFormContainer_Mutation(\n  $input: AddSiteInput!\n  $reportInput: ReportAnalyzerInput!\n) {\n  addSite(input: $input) {\n    site {\n      id\n      name\n      url\n      slug\n      uniqueUserCount(input: $reportInput)\n      conversionUserCount(input: $reportInput)\n      conversionUserRate(input: $reportInput)\n      popups {\n        totalCount\n      }\n      abtestScenarioPages {\n        totalCount\n      }\n      chatbots {\n        totalCount\n      }\n      funnels(orderBy: {field: UPDATED_AT, direction: DESC}, first: 1) {\n        edges {\n          node {\n            id\n            landingPage {\n              imageUrl\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "861afb183cf1f27e42ea324974df2a19";

export default node;
