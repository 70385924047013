/**
 * @generated SignedSource<<f9f7bff6ed167f08e3bc0b2d129fbbdc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DeliverKind = "EQUAL" | "WEIGHT" | "%future added value";
export type PageKind = "LANDING" | "FORM" | "CONFIRM" | "THANKS" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PopupGroupPatternEditScreenContainer_popupGroup$data = {
  readonly id: string;
  readonly name: string;
  readonly deliverKind: DeliverKind;
  readonly originalPopup: {
    readonly weight: number | null;
  };
  readonly page: {
    readonly name: string;
    readonly kind: PageKind;
    readonly sortNumber: number;
    readonly url: string;
    readonly funnel: {
      readonly name: string;
      readonly siteUrl: string;
    };
  };
  readonly " $fragmentType": "PopupGroupPatternEditScreenContainer_popupGroup";
};
export type PopupGroupPatternEditScreenContainer_popupGroup = PopupGroupPatternEditScreenContainer_popupGroup$data;
export type PopupGroupPatternEditScreenContainer_popupGroup$key = {
  readonly " $data"?: PopupGroupPatternEditScreenContainer_popupGroup$data;
  readonly " $fragmentSpreads": FragmentRefs<"PopupGroupPatternEditScreenContainer_popupGroup">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PopupGroupPatternEditScreenContainer_popupGroup",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deliverKind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Popup",
      "kind": "LinkedField",
      "name": "originalPopup",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "weight",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Page",
      "kind": "LinkedField",
      "name": "page",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sortNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Funnel",
          "kind": "LinkedField",
          "name": "funnel",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "siteUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PopupGroup",
  "abstractKey": null
};
})();

(node as any).hash = "bc6ac55e1e671474d8b7cce3bc65fd2f";

export default node;
