/**
 * @generated SignedSource<<0e2ae66994a4070cfdb6907eaeaef29d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PopupGroupEditScreenContainer_site$data = {
  readonly id: string;
  readonly name: string;
  readonly slug: string;
  readonly " $fragmentSpreads": FragmentRefs<"PopupGroupForm_site">;
  readonly " $fragmentType": "PopupGroupEditScreenContainer_site";
};
export type PopupGroupEditScreenContainer_site = PopupGroupEditScreenContainer_site$data;
export type PopupGroupEditScreenContainer_site$key = {
  readonly " $data"?: PopupGroupEditScreenContainer_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"PopupGroupEditScreenContainer_site">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PopupGroupEditScreenContainer_site",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PopupGroupForm_site"
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "f4085bc7a4bffabd2f945fa3eff475bf";

export default node;
