import { Box } from "@chakra-ui/react";
import { FC, useCallback, useMemo } from "react";
import { graphql, useFragment } from "react-relay";
import { useNavigate, useParams } from "react-router-dom";

import { ChatbotEditScreenContainer_chatbotGroup$key } from "~/src/__relay_artifacts__/ChatbotEditScreenContainer_chatbotGroup.graphql";
import { ChatbotEditScreenContainer_Mutation } from "~/src/__relay_artifacts__/ChatbotEditScreenContainer_Mutation.graphql";
import { ChatbotEditScreenContainer_site$key } from "~/src/__relay_artifacts__/ChatbotEditScreenContainer_site.graphql";
import {
  ChatbotFirstStepForm,
  Props as ChatbotFirstStepFormProps,
} from "~/src/components/features/chatbot/ChatbotForms/presentations/ChatbotFirstStepForm";
import { PageLayout } from "~/src/components/features/global/PageLayout";
import { useFormErrorHandler } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";

export type Props = {
  chatbotGroupRef: ChatbotEditScreenContainer_chatbotGroup$key;
  siteRef: ChatbotEditScreenContainer_site$key;
};

const fragments = {
  chatbotGroup: graphql`
    fragment ChatbotEditScreenContainer_chatbotGroup on ChatbotGroup {
      id
      name
      slug
      page {
        id
        funnel {
          id
        }
      }
      browsers
      devices
      genders
      ageRanges
      deliverKind
      platforms
      repeat
      startDate
      startTime
      endDate
      endTime
      status
      sources {
        id
        name
      }
      excludeReferrers {
        referrer
        matchOp
      }
      referrers {
        referrer
        matchOp
      }
      visitCount
      visitMatchType
      deliverTargetUrls {
        url
      }
      deliverExcludeUrls {
        url
      }
      deliverTargetLabels {
        id
        name
      }
      deliverExcludeLabels {
        id
        name
      }
      targetCookies {
        key
        value
        matchType
      }
      deliverDayOfWeeks {
        weekOfMonth
        daysOfWeek
      }
    }
  `,
  site: graphql`
    fragment ChatbotEditScreenContainer_site on Site {
      id
      name
      slug
      ...ChatbotFirstStepForm_site
    }
  `,
};

// platformsとlineCodes{code}が違う
const mutation = graphql`
  mutation ChatbotEditScreenContainer_Mutation(
    $input: UpdateChatbotGroupInput!
  ) {
    updateChatbotGroup(input: $input) {
      chatbotGroup {
        slug
        ...ChatbotEditScreenContainer_chatbotGroup
      }
    }
  }
`;

export const ChatbotEditScreenContainer: FC<Props> = ({
  chatbotGroupRef,
  siteRef,
}) => {
  const { chatbotSlug = "", siteSlug = "" } = useParams();

  const chatbotGroup = useFragment(fragments.chatbotGroup, chatbotGroupRef);

  const site = useFragment(fragments.site, siteRef);

  const navigate = useNavigate();

  const handleCancel = useCallback(() => navigate(-1), [navigate]);

  const mutate =
    useMutationCommit<ChatbotEditScreenContainer_Mutation>(mutation);

  const { onFormError } = useFormErrorHandler();

  const handleSubmit = useCallback<ChatbotFirstStepFormProps["onSubmit"]>(
    async ({ funnelId, repeat, ...values }, { setErrors }) => {
      try {
        const res = await mutate({
          variables: {
            input: {
              ...values,
              chatbotGroupId: chatbotGroup.id,
              startDate: repeat
                ? values.startDate?.toISOString() || null
                : null,
              startTime: repeat
                ? values.startTime?.toISOString() || null
                : null,
              endDate: repeat ? values.endDate?.toISOString() || null : null,
              endTime: repeat ? values.endTime?.toISOString() || null : null,
              deliverDayOfWeeks: repeat ? values.deliverDayOfWeeks : [],
            },
          },
        });

        const chatbogGroup = res.updateChatbotGroup?.chatbotGroup;
        if (!chatbogGroup) {
          throw new Error("assertion failed");
        }

        navigate(`/sites/${site.slug}/chatbots/${chatbogGroup.slug}`);
      } catch (err) {
        onFormError(err, setErrors);
      }
      return Promise.resolve();
    },
    [mutate, chatbotGroup, navigate, site.slug, onFormError]
  );

  const sourceIds = useMemo(
    () =>
      chatbotGroup.sources?.map((node) => {
        return node.id;
      }) || [],
    [chatbotGroup.sources]
  );

  const deliverTargetLabelIds = useMemo(
    () => chatbotGroup.deliverTargetLabels.map((label) => label.id),
    [chatbotGroup.deliverTargetLabels]
  );

  const deliverExcludeLabelIds = useMemo(
    () => chatbotGroup.deliverExcludeLabels.map((label) => label.id),
    [chatbotGroup.deliverExcludeLabels]
  );

  const initialValues = {
    name: chatbotGroup.name,
    pageId: chatbotGroup.page.id,
    funnelId: chatbotGroup.page.funnel.id,
    platforms: chatbotGroup.platforms,
    browsers: chatbotGroup.browsers,
    devices: chatbotGroup.devices,
    genders: chatbotGroup.genders,
    ageRanges: chatbotGroup.ageRanges || [],
    referrers: chatbotGroup.referrers,
    excludeReferrers: chatbotGroup.excludeReferrers,
    sourceIds,
    deliverKind: chatbotGroup.deliverKind,
    visitCount: chatbotGroup.visitCount,
    visitMatchType: chatbotGroup.visitMatchType,
    deliverTargetUrls: chatbotGroup.deliverTargetUrls,
    deliverExcludeUrls: chatbotGroup.deliverExcludeUrls,
    deliverTargetLabelIds,
    deliverExcludeLabelIds,
    targetCookies: chatbotGroup.targetCookies,
    repeat: chatbotGroup.repeat,
    startDate: chatbotGroup.startDate ? new Date(chatbotGroup.startDate) : null,
    endDate: chatbotGroup.endDate ? new Date(chatbotGroup.endDate) : null,
    startTime: chatbotGroup.startTime ? new Date(chatbotGroup.startTime) : null,
    endTime: chatbotGroup.endTime ? new Date(chatbotGroup.endTime) : null,
    deliverDayOfWeeks: chatbotGroup.deliverDayOfWeeks,
    status: chatbotGroup.status,
  };

  return (
    <PageLayout
      title="基本設定"
      breadcrumbs={[
        { label: "チャットボット一覧", path: `/sites/${siteSlug}/chatbots` },
        {
          label: "チャットボット編集",
          path: `/sites/${siteSlug}/chatbots/${chatbotSlug}/edit`,
        },
      ]}
    >
      <Box my="16px" p="16px">
        <ChatbotFirstStepForm
          // TODO: do not use any
          initialValues={initialValues as any}
          siteRef={site}
          onCancelClick={handleCancel}
          onSubmit={handleSubmit}
          isFunnelDisabled
        />
      </Box>
    </PageLayout>
  );
};
