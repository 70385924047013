import { Box, HStack, VStack } from "@chakra-ui/layout";
import { DeleteOutline as DeleteOutlineIcon } from "@mui/icons-material";
import { useField } from "formik";
import { FC, useCallback } from "react";

import { GraphQLEnums } from "~/src/__generated__/GraphQLEnums";
import { VisitMatch } from "~/src/__generated__/schema";
import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormikSelectField } from "~/src/components/common/forms/FormikSelectField";
import { FormikTextField } from "~/src/components/common/forms/FormikTextField";
import { IconButton } from "~/src/components/common/IconButton";
import { Switch } from "~/src/components/common/Switch";
import { useOpenState } from "~/src/lib/hooks";

type Props = {
  label: string;
};

export const VisitCountField: FC<Props> = ({ label }) => {
  const [visitCount, , { setValue: setVisitCount }] = useField<number | null>(
    "visitCount"
  );
  const [, , { setValue: setVisitMatchType }] =
    useField<VisitMatch>("visitMatchType");

  const { isOpen, onOpen, onClose } = useOpenState(visitCount.value != null);

  const handleChange = useCallback(() => {
    if (isOpen) {
      onClose();
      setVisitCount(null);
      setVisitMatchType("EQUAL_MATCH_TYPE");
    } else {
      onOpen();
    }
  }, [isOpen, onClose, onOpen, setVisitCount, setVisitMatchType]);

  return (
    <FormControl label={label}>
      <Box width="100%" display="block">
        <Switch isChecked={isOpen} onChange={handleChange} />
      </Box>
      <VStack width="100%">
        {isOpen && (
          <HStack width="100%">
            <Box flex={1}>
              <FormikTextField
                type="number"
                mode="fill"
                name={`visitCount`}
                placeholder="半角数字で入力"
              />
            </Box>

            <Box width={120}>
              <FormikSelectField
                mode="fill"
                name={`visitMatchType`}
                options={GraphQLEnums.VisitMatch}
              />
            </Box>
            <Box>
              <IconButton size="24px" icon={<DeleteOutlineIcon />} disabled />
            </Box>
          </HStack>
        )}
      </VStack>
    </FormControl>
  );
};
