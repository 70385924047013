import { Box, HStack } from "@chakra-ui/layout";
import { useField } from "formik";
import { FC } from "react";

import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormikCheckboxField } from "~/src/components/common/forms/FormikCheckboxField";
import { FormikTextField } from "~/src/components/common/forms/FormikTextField";
import { ImageUploadField } from "~/src/components/features/uploads";

export const ChatMessageImageForm: FC = () => {
  const [imageUrl] = useField<string>(`chatMessageImage.imageUrl`);

  return (
    <>
      <FormControl label={"画像"}>
        <HStack justifyContent={"space-between"}>
          <Box>
            <ImageUploadField
              src={imageUrl.value}
              name={"chatMessageImage.image"}
            />
          </Box>
        </HStack>
      </FormControl>
      <FormControl label="画像が表示されない場合の表記">
        <FormikTextField
          type="text"
          mode="fill"
          name={"chatMessageImage.alt"}
        />
      </FormControl>
      <FormControl label="画像クリック時のURL">
        <FormikCheckboxField
          name={"chatMessageImage.isInheritQuery"}
          label="クエリパラメータを引き継ぐ（※ページの?以降の文字を引き継ぎます）"
        />
        <FormikTextField
          type="text"
          mode="fill"
          name={"chatMessageImage.linkUrl"}
          placeholder="https://"
        />
      </FormControl>
    </>
  );
};
