import { Box, Button, FormControl } from "@chakra-ui/react";
import { Form, Formik, FormikConfig } from "formik";
import { FC } from "react";

import { FormikTextField } from "~/src/components/common/forms/FormikTextField";
import { chakraFactory } from "~/src/lib/chakra-ui";

import { CommonCard } from "../CommonCard";

import { FormValues, validationSchema } from "./validationSchema";

export type Props = {
  onSubmit: FormikConfig<FormValues>["onSubmit"];
};

const StyledLoginButton = chakraFactory(Button, {
  baseStyle: {
    width: "201px",
    height: "48px",
    backgroundColor: "#F0D209",
    marginTop: "16px",

    _focus: {
      boxShadow: "none",
    },
  },
});

const initialValues = {
  email: "",
};

export const ForgotPasswordForm: FC<Props> = ({ onSubmit }) => {
  return (
    <CommonCard>
      <Formik<FormValues>
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount={false}
        validateOnChange={false}
        onSubmit={onSubmit}
      >
        <Form>
          <Box height="30px" />
          <FormControl required label="メールアドレス">
            <FormikTextField
              type="text"
              mode="fill"
              name="email"
              placeholder="メールアドレス"
            />
          </FormControl>
          <Box height="30px" />
          <StyledLoginButton type="submit">メールを送信する</StyledLoginButton>
        </Form>
      </Formik>
    </CommonCard>
  );
};
