import { Flex } from "@chakra-ui/react";
import { SearchOutlined as SearchOutlinedIcon } from "@mui/icons-material";
import { ChangeEventHandler, FC, Suspense, useCallback, useState } from "react";

import { SolidButton } from "~/src/components/common/Button";
import { PageLoading } from "~/src/components/common/PageLoading";
import { TextField } from "~/src/components/common/TextField";
import { SiteCardProps } from "~/src/components/features/sites/SiteCard";
import { chakraFactory } from "~/src/lib/chakra-ui";

import { FormValues, SiteFilterButton } from "./SiteFilterButton";
import { SiteListCard } from "./SiteListCard";

export type Props = {
  onCreateClick: () => void;
  onEditClick: SiteCardProps["onEditClick"];
};

const StyledTextField = chakraFactory(TextField, {
  baseStyle: {
    width: "292px",
    height: "40px",
  },
});

export const SiteListTab: FC<Props> = ({ onCreateClick, onEditClick }) => {
  const [searchText, setSearchText] = useState("");
  const [siteFilter, setSiteFilter] = useState<FormValues>({
    sourceId: null,
    device: null,
  });

  const handleSearchTextChange = useCallback<
    ChangeEventHandler<HTMLInputElement>
  >((e) => setSearchText(e.target.value), []);

  return (
    <>
      <Flex justifyContent="flex-end" mb="24px">
        <SolidButton onClick={onCreateClick}>登録</SolidButton>
      </Flex>
      <Flex mb="16px" justifyContent="space-between">
        <StyledTextField
          type="text"
          mode="outline"
          name="search"
          value={searchText}
          placeholder="検索"
          icon={<SearchOutlinedIcon />}
          onChange={handleSearchTextChange}
        />
        <SiteFilterButton
          filterValues={siteFilter}
          onFilterChange={setSiteFilter}
          onSubmit={setSiteFilter}
        />
      </Flex>
      <Suspense fallback={<PageLoading />}>
        <SiteListCard
          params={{
            searchText,
            sourceId: siteFilter.sourceId,
            device: siteFilter.device,
          }}
          onEditClick={onEditClick}
        />
      </Suspense>
    </>
  );
};
