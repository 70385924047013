import { useToast } from "@chakra-ui/react";
import { FC, useCallback, useMemo } from "react";
import { graphql, useFragment } from "react-relay";
import { useNavigate, useParams } from "react-router-dom";

import { SourceEditScreenContainer_Mutation } from "~/src/__relay_artifacts__/SourceEditScreenContainer_Mutation.graphql";
import { SourceEditScreenContainer_source$key } from "~/src/__relay_artifacts__/SourceEditScreenContainer_source.graphql";
import { useFormErrorHandler } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";

import {
  SourceForm,
  SourceFormProps,
} from "../../features/settings/SourceForms/presentations";

export type Props = {
  sourceRef: SourceEditScreenContainer_source$key;
};

const fragment = graphql`
  fragment SourceEditScreenContainer_source on Source {
    id
    name
    tag
    description
  }
`;

const mutation = graphql`
  mutation SourceEditScreenContainer_Mutation($input: UpdateSourceInput!) {
    updateSource(input: $input) {
      source {
        ...SourceEditScreenContainer_source
      }
    }
  }
`;
export const SourceEditScreenContainer: FC<Props> = ({ sourceRef }) => {
  const { siteSlug = "" } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const { onFormError } = useFormErrorHandler();
  const source = useFragment(fragment, sourceRef);

  const initialValues = useMemo(
    () =>
      ({
        name: source?.name,
        tag: source.tag,
        description: source.description,
      } as const),
    [source]
  );

  const updateSourceMutation =
    useMutationCommit<SourceEditScreenContainer_Mutation>(mutation);

  const handleSubmit = useCallback<SourceFormProps["onSubmit"]>(
    async ({ ...values }, { setErrors }) => {
      try {
        const res = await updateSourceMutation({
          variables: {
            input: {
              description: values.description || "",
              name: values.name,
              tag: values.tag,
              sourceId: source.id,
            },
          },
        });

        if (!res.updateSource?.source) {
          throw new Error("assertion failed");
        }
        toast({ title: "流入元を更新しました", status: "success" });
        navigate(`/sites/${siteSlug}/settings?tab=source`);
      } catch (err) {
        onFormError(err, setErrors);
      }
    },
    [onFormError, siteSlug, toast, navigate, updateSourceMutation, source]
  );

  const handleCancel = useCallback(() => navigate(-1), [navigate]);

  return (
    <SourceForm
      initialValues={initialValues}
      onCancelClick={handleCancel}
      onSubmit={handleSubmit}
    />
  );
};
