/**
 * @generated SignedSource<<9141d82e633e89e34894350ea1d6d278>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AgeRange = "LT12" | "GT12LT19" | "GT20LT24" | "GT25LT29" | "GT30LT34" | "GT35LT39" | "GT40LT44" | "GT45LT49" | "GT50LT54" | "GT55LT59" | "%future added value";
export type AnnualIncome = "LT200" | "GT200LT400" | "GT400LT600" | "GT600LT800" | "GT800LT1000" | "GT1000LT1200" | "GT1200LT1500" | "GT1500LT2000" | "GT2000" | "%future added value";
export type Browser = "IE" | "CHROME" | "SAFARI" | "FIREFOX" | "EDGE" | "OTHER" | "%future added value";
export type DeliverKind = "EQUAL" | "WEIGHT" | "%future added value";
export type Device = "MOBILE" | "TABLET" | "PC" | "%future added value";
export type DistributionStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type Gender = "MALE" | "FEMALE" | "%future added value";
export type GroupContentJudgeStatus = "PENDING" | "APPROVED" | "%future added value";
export type Platform = "IOS" | "WINDOWS" | "ANDROID" | "MAC" | "LINUX" | "OTHER" | "%future added value";
export type VisitMatch = "EQUAL_MATCH_TYPE" | "OVER_MATCH_TYPE" | "UNDER_MATCH_TYPE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PopupGroupDetailSection_popupGroup$data = {
  readonly ageRanges: ReadonlyArray<AgeRange>;
  readonly annualIncomes: ReadonlyArray<AnnualIncome>;
  readonly browsers: ReadonlyArray<Browser>;
  readonly devices: ReadonlyArray<Device>;
  readonly deliverKind: DeliverKind;
  readonly genders: ReadonlyArray<Gender>;
  readonly id: string;
  readonly isMulti: boolean;
  readonly judgeStatus: GroupContentJudgeStatus | null;
  readonly memo: string | null;
  readonly name: string;
  readonly page: {
    readonly name: string;
    readonly funnel: {
      readonly slug: string;
      readonly name: string;
    };
  };
  readonly platforms: ReadonlyArray<Platform>;
  readonly repeat: boolean;
  readonly redisplayPopupTime: number;
  readonly slug: string;
  readonly status: DistributionStatus;
  readonly visitMatchType: VisitMatch | null;
  readonly " $fragmentSpreads": FragmentRefs<"PopupGroupDeliverDayOfWeeks_popupGroup" | "PopupDistributionSettingSection_popupGroup">;
  readonly " $fragmentType": "PopupGroupDetailSection_popupGroup";
};
export type PopupGroupDetailSection_popupGroup = PopupGroupDetailSection_popupGroup$data;
export type PopupGroupDetailSection_popupGroup$key = {
  readonly " $data"?: PopupGroupDetailSection_popupGroup$data;
  readonly " $fragmentSpreads": FragmentRefs<"PopupGroupDetailSection_popupGroup">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PopupGroupDetailSection_popupGroup",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ageRanges",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "annualIncomes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "browsers",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "devices",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deliverKind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "genders",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isMulti",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "judgeStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "memo",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Page",
      "kind": "LinkedField",
      "name": "page",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Funnel",
          "kind": "LinkedField",
          "name": "funnel",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "platforms",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "repeat",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "redisplayPopupTime",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "visitMatchType",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PopupGroupDeliverDayOfWeeks_popupGroup"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PopupDistributionSettingSection_popupGroup"
    }
  ],
  "type": "PopupGroup",
  "abstractKey": null
};
})();

(node as any).hash = "c19e5636ab1b772478ba96c81ccdb348";

export default node;
