import { Box, Flex, HStack, Image, Spacer, VStack } from "@chakra-ui/react";
import { uniq } from "ramda";
import { FC, Suspense, useMemo } from "react";
import { graphql, usePaginationFragment } from "react-relay";
import { NavLink } from "react-router-dom";

import { PopupSetDetailLinkedPopupListTable_popupSet$key } from "~/src/__relay_artifacts__/PopupSetDetailLinkedPopupListTable_popupSet.graphql";
import { PopupSetDetailLinkedPopupListTableRefetchQuery } from "~/src/__relay_artifacts__/PopupSetDetailLinkedPopupListTableRefetchQuery.graphql";
import { OutlineButton } from "~/src/components/common/Button";
import { Modal } from "~/src/components/common/Modal";
import { PageLoading } from "~/src/components/common/PageLoading";
import {
  Table,
  TableDataColumn,
  TableDataRow,
  TableHeaderColumn,
  TableHeaderRow,
} from "~/src/components/common/tables/Table";
import {
  TablePagination,
  useTablePaginationProps,
} from "~/src/components/common/tables/TablePagination";
import { URLItem } from "~/src/components/common/URLItem";
import { PopupLinkFormContainer } from "~/src/components/features/popupSet";
import { useOpenState } from "~/src/lib/hooks";

import { PopupBulkEditFormContainer } from "./PopupBulkEditFormContainer";
import { PopupSetDetailLinkedPopupListTableActions } from "./PopupSetDetailLinkedPopupListTableActions";

export type Props = {
  siteSlug: string;
  popupGroupSetId: string;
  popupSetRef: PopupSetDetailLinkedPopupListTable_popupSet$key;
};

const fragment = graphql`
  fragment PopupSetDetailLinkedPopupListTable_popupSet on PopupSet
  @refetchable(queryName: "PopupSetDetailLinkedPopupListTableRefetchQuery")
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 120 }
    cursor: { type: "String" }
  ) {
    id
    popupSettings(first: $count, after: $cursor)
      @connection(
        key: "PopupSetDetailLinkedPopupListTable_popupSet_popupSettings"
      ) {
      __id
      totalCount
      edges {
        node {
          id
          popup {
            id
            slug
            name
            thumbnailImageUrl
            status
            judgeStatus
            popupGroup {
              id
              slug
              name
              deliverKind
            }
            popupImages {
              id
            }
            page {
              id
              kind
              name
              url
              sortNumber
              funnel {
                id
                name
                siteUrl
              }
            }
            ...PopupBulkEditFormContainer_popup
            ...PopupBulkEditFormContainer_popup
          }
        }
      }
    }
  }
`;

export const PopupSetDetailLinkedPopupListTable: FC<Props> = ({
  siteSlug,
  popupGroupSetId,
  popupSetRef,
}) => {
  const popupLinkModalOpenState = useOpenState();
  const popupBulkEditModalOpenState = useOpenState();

  const {
    data: popupSet,
    hasNext,
    loadNext,
    refetch,
  } = usePaginationFragment<
    PopupSetDetailLinkedPopupListTableRefetchQuery,
    PopupSetDetailLinkedPopupListTable_popupSet$key
  >(fragment, popupSetRef);
  const { tablePaginationProps } = useTablePaginationProps({
    hasNext,
    loadNext,
    refetch,
    totalCount: popupSet.popupSettings?.totalCount || 0,
  });

  const popupSettings = useMemo(() => {
    return (
      popupSet.popupSettings.edges?.map((edge) => {
        const node = edge?.node;
        if (!node) throw new Error("assertion failed");
        return node;
      }) || []
    );
  }, [popupSet.popupSettings]);

  const popups = useMemo(() => {
    return popupSettings.map((popupSetting, index) => {
      return {
        id: popupSetting.popup.id,
        popupImages: popupSetting.popup.popupImages
          ? popupSetting.popup.popupImages.map((popupImage, index) => {
              return { id: popupImage.id, index };
            })
          : [],
        isEditTarget: index === 0 ? true : false,
      };
    });
  }, [popupSettings]);

  const popupIds = useMemo(() => {
    return popupSettings.map((popupSetting) => popupSetting.popup.id);
  }, [popupSettings]);

  const deliverKinds = useMemo(() => {
    const deliverKinds = popupSettings.map(
      (popupSetting) => popupSetting.popup.popupGroup.deliverKind
    );
    return uniq(deliverKinds);
  }, [popupSettings]);

  return (
    <>
      <Flex alignItems="flex-end" marginTop={"8"}>
        <Box color="#282828" fontWeight="bold">
          設定しているポップアップパターン一覧
        </Box>
        <Spacer />
        <HStack spacing={3}>
          <OutlineButton onClick={popupLinkModalOpenState.onOpen}>
            ポップアップパターンの紐付け
          </OutlineButton>
          <OutlineButton
            onClick={popupBulkEditModalOpenState.onOpen}
            isDisabled={popupSettings.length === 0}
          >
            ポップアップパターンの一括編集
          </OutlineButton>
        </HStack>
      </Flex>

      <Table>
        <TableHeaderRow>
          <TableHeaderColumn>ポップアップパターン名</TableHeaderColumn>
          <TableHeaderColumn>ステータス</TableHeaderColumn>
          <TableHeaderColumn>画像</TableHeaderColumn>
          <TableHeaderColumn>ポップアップ名</TableHeaderColumn>
          <TableHeaderColumn>実施ファネル</TableHeaderColumn>
          <TableHeaderColumn>実施ページ</TableHeaderColumn>
          <TableHeaderColumn>アクション</TableHeaderColumn>
        </TableHeaderRow>
        {popupSettings.map((popupSetting, index) => (
          <TableDataRow height={"80px"} key={index}>
            <TableDataColumn
              type="text"
              isLink
              data={
                <NavLink
                  to={`/sites/${siteSlug}/popup_groups/${popupSetting.popup.popupGroup.slug}/patterns/${popupSetting.popup.slug}`}
                >
                  {popupSetting.popup.name}
                </NavLink>
              }
            />
            <TableDataColumn
              type="text"
              data={
                <VStack spacing={"0"}>
                  <Box
                    borderRadius={"xl"}
                    p={1}
                    align="center"
                    color={"white"}
                    bg={
                      popupSetting.popup.status === "ACTIVE"
                        ? "#4CAF50"
                        : "#979797"
                    }
                  >
                    {popupSetting.popup.status === "ACTIVE"
                      ? "実施中"
                      : "停止中"}
                  </Box>
                  <Box
                    borderRadius={"xl"}
                    p={1}
                    align="center"
                    color={"white"}
                    bg={
                      popupSetting.popup.judgeStatus === "APPROVED"
                        ? "#4CAF50"
                        : "#979797"
                    }
                  >
                    {popupSetting.popup.judgeStatus === "APPROVED"
                      ? "承認"
                      : "未承認"}
                  </Box>
                </VStack>
              }
            />
            <TableDataColumn
              type={"text"}
              data={
                popupSetting.popup.thumbnailImageUrl && (
                  <Image
                    src={popupSetting.popup.thumbnailImageUrl}
                    boxSize={"60px"}
                    fit={"contain"}
                  />
                )
              }
            />
            <TableDataColumn
              type="text"
              isLink
              data={
                <NavLink
                  to={`/sites/${siteSlug}/popup_groups/${popupSetting.popup.popupGroup.slug}`}
                >
                  {popupSetting.popup.popupGroup.name}
                </NavLink>
              }
            />
            <TableDataColumn
              type="text"
              data={
                <URLItem
                  url={popupSetting.popup.page.funnel.siteUrl}
                  name={popupSetting.popup.page.funnel.name}
                />
              }
            />

            <TableDataColumn
              type="text"
              data={
                <URLItem
                  url={popupSetting.popup.page.url}
                  name={
                    popupSetting.popup.page.kind === "FORM"
                      ? `${popupSetting.popup.page.name}(${
                          popupSetting.popup.page.sortNumber - 1
                        })`
                      : popupSetting.popup.page.name
                  }
                />
              }
            />

            <TableDataColumn
              type="actions"
              data={
                <PopupSetDetailLinkedPopupListTableActions
                  popupName={popupSetting.popup.name || "未設定"}
                  popupSettingId={popupSetting.id}
                  connectionId={popupSet.popupSettings.__id}
                />
              }
            />
          </TableDataRow>
        ))}
      </Table>
      <TablePagination {...tablePaginationProps} />

      <Modal
        title="ポップアップパターンの紐付け"
        isOpen={popupLinkModalOpenState.isOpen}
        onClose={popupLinkModalOpenState.onClose}
        width={"1200px"}
      >
        <Suspense fallback={<PageLoading />}>
          <PopupLinkFormContainer
            onClose={popupLinkModalOpenState.onClose}
            siteSlug={siteSlug}
            popupGroupSetId={popupGroupSetId}
            createdPopupIds={popupIds}
            popupSetId={popupSet.id}
          />
        </Suspense>
      </Modal>
      <Modal
        title="ポップアップパターンの一括編集"
        isOpen={popupBulkEditModalOpenState.isOpen}
        onClose={popupBulkEditModalOpenState.onClose}
      >
        <Suspense fallback={<PageLoading />}>
          {popupSettings.length > 0 && (
            <PopupBulkEditFormContainer
              onClose={popupBulkEditModalOpenState.onClose}
              deliverKind={deliverKinds.length > 0 ? deliverKinds[0] : "EQUAL"}
              targetPopups={popups}
              popupRef={popupSettings[0].popup}
            />
          )}
        </Suspense>
      </Modal>
    </>
  );
};
