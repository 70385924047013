import { DownloadOutlined as DownloadOutlinedIcon } from "@mui/icons-material";
import { format } from "date-fns";
import { includes } from "ramda";
import { FC, useMemo } from "react";
import { CSVLink } from "react-csv";

import { IconButton } from "~/src/components/common/IconButton";

export type Props = {
  name: string;
  data: string;
  filteredColumns?: number[];
};

export const CSVDownloadLink: FC<Props> = ({ name, data, filteredColumns }) => {
  const csvData = useMemo(() => {
    if (!filteredColumns) return data;
    const rows = data
      .split(/\r\n|\n|\r/g)
      .map((rowString) => rowString.split(","));
    return rows
      .map((row) => {
        return row.filter((_, i) => !includes(i, filteredColumns)).join(",");
      })
      .join("\n");
  }, [data, filteredColumns]);

  return (
    <CSVLink
      filename={`${name}-${format(new Date(), "yyyy-MM-dd")}.csv`}
      data={csvData}
    >
      <IconButton size="24px" icon={<DownloadOutlinedIcon />} />
    </CSVLink>
  );
};
