import { useToast } from "@chakra-ui/toast";
import {
  ArchiveOutlined as ArchiveOutlinedIcon,
  ContentCopyOutlined as ContentCopyOutlinedIcon,
  DeleteOutline as DeleteOutlineIcon,
  UnarchiveOutlined as UnarchiveOutlinedIcon,
} from "@mui/icons-material";
import { FC, useCallback, useMemo } from "react";
import { graphql } from "react-relay";

import { ChatbotTableActions_CopyMutation as CopyMutation } from "~/src/__relay_artifacts__/ChatbotTableActions_CopyMutation.graphql";
import { ChatbotTableActions_DeleteMutation as DeleteMutation } from "~/src/__relay_artifacts__/ChatbotTableActions_DeleteMutation.graphql";
import { TableActions } from "~/src/components/common/tables/TableActions";
import {
  useArchiveHandler,
  useUnarchiveHandler,
} from "~/src/components/features/archive";
import { DeleteConfirmDialog } from "~/src/components/features/global/DeleteConfirmDialog";
import { useOpenState } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";

type Props = {
  chatbotGroup: { id: string; name: string; slug: string };
  connectionId: string;
  filtered: boolean;
};

const deleteMutation = graphql`
  mutation ChatbotTableActions_DeleteMutation(
    $connections: [ID!]!
    $input: DeleteChatbotGroupInput!
  ) {
    deleteChatbotGroup(input: $input) {
      deletedChatbotGroupId @deleteEdge(connections: $connections)
    }
  }
`;

const copyMutation = graphql`
  mutation ChatbotTableActions_CopyMutation(
    $connections: [ID!]!
    $input: CopyChatbotGroupInput!
  ) {
    copyChatbotGroup(input: $input) {
      chatbotGroup
        @appendNode(
          connections: $connections
          edgeTypeName: "ChatbotGroupEdge"
        ) {
        id
        name
        slug
        chatbots {
          totalCount
        }
        endDate
        startDate
        updatedAt
        status
        page {
          name
          slug
          funnel {
            name
            slug
          }
        }
      }
    }
  }
`;

export const ChatbotTableActions: FC<Props> = ({
  chatbotGroup,
  connectionId,
  filtered,
}) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useOpenState();

  const deleteMutate = useMutationCommit<DeleteMutation>(deleteMutation);
  const copyMutate = useMutationCommit<CopyMutation>(copyMutation);

  const handleArchive = useArchiveHandler(chatbotGroup.id, connectionId);
  const handleUnarchive = useUnarchiveHandler(chatbotGroup.id, connectionId);

  const handleDelete = useCallback(async () => {
    try {
      const res = await deleteMutate({
        variables: {
          input: {
            chatbotGroupId: chatbotGroup.id,
          },
          connections: [connectionId],
        },
      });
      const deletedChatbotGroupId =
        res.deleteChatbotGroup?.deletedChatbotGroupId;
      if (!deletedChatbotGroupId) throw new Error("assertion failed");

      toast({
        title: `${chatbotGroup.name}を削除しました`,
        status: "success",
      });
    } catch (err) {
      toast({ title: "削除に失敗しました", status: "error" });
    }
  }, [connectionId, deleteMutate, chatbotGroup.id, chatbotGroup.name, toast]);

  const handleCopy = useCallback(async () => {
    try {
      const res = await copyMutate({
        variables: {
          input: {
            chatbotGroupId: chatbotGroup.id,
          },
          connections: [connectionId],
        },
      });
      const copiedChatbotGroup = res.copyChatbotGroup?.chatbotGroup;
      if (!copiedChatbotGroup) throw new Error("assertion failed");

      toast({
        title: `${copiedChatbotGroup.name}をコピーしました`,
        status: "success",
      });
    } catch (err) {
      toast({ title: "コピーに失敗しました", status: "error" });
    }
  }, [chatbotGroup.id, connectionId, copyMutate, toast]);

  const defaultActionMenus = useMemo(
    () => [
      {
        label: "コピー",
        icon: <ContentCopyOutlinedIcon />,
        onClick: () => handleCopy(),
      },
      {
        label: "アーカイブ",
        icon: <ArchiveOutlinedIcon />,
        onClick: () => handleArchive(),
      },
    ],
    [handleArchive, handleCopy]
  );

  const archivedActionMenus = useMemo(
    () => [
      {
        label: "アーカイブから戻す",
        icon: <UnarchiveOutlinedIcon />,
        onClick: () => handleUnarchive(),
      },
      {
        label: "削除",
        icon: <DeleteOutlineIcon />,
        onClick: () => onOpen(),
      },
    ],
    [handleUnarchive, onOpen]
  );

  return (
    <>
      <TableActions
        actionMenus={filtered ? archivedActionMenus : defaultActionMenus}
      />
      <DeleteConfirmDialog
        name={chatbotGroup.name}
        isOpen={isOpen}
        onClose={onClose}
        onDelete={handleDelete}
      />
    </>
  );
};
