/**
 * @generated SignedSource<<6c4f8236ed03ea2c4f5fc9fa25197f4a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AgeRange = "LT12" | "GT12LT19" | "GT20LT24" | "GT25LT29" | "GT30LT34" | "GT35LT39" | "GT40LT44" | "GT45LT49" | "GT50LT54" | "GT55LT59" | "%future added value";
export type AnnualIncome = "LT200" | "GT200LT400" | "GT400LT600" | "GT600LT800" | "GT800LT1000" | "GT1000LT1200" | "GT1200LT1500" | "GT1500LT2000" | "GT2000" | "%future added value";
export type Browser = "IE" | "CHROME" | "SAFARI" | "FIREFOX" | "EDGE" | "OTHER" | "%future added value";
export type DayOfWeek = "SUN" | "MON" | "TUE" | "WED" | "THU" | "FRI" | "SUT" | "%future added value";
export type DeliverKind = "EQUAL" | "WEIGHT" | "%future added value";
export type Device = "MOBILE" | "TABLET" | "PC" | "%future added value";
export type DistributionStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type Gender = "MALE" | "FEMALE" | "%future added value";
export type MatchOp = "EQUAL" | "CONTAINS" | "%future added value";
export type Platform = "IOS" | "WINDOWS" | "ANDROID" | "MAC" | "LINUX" | "OTHER" | "%future added value";
export type VisitMatch = "EQUAL_MATCH_TYPE" | "OVER_MATCH_TYPE" | "UNDER_MATCH_TYPE" | "%future added value";
export type UpdateChatbotGroupInput = {
  clientMutationId?: string | null;
  chatbotGroupId: string;
  ageRanges?: ReadonlyArray<AgeRange> | null;
  annualIncomes?: ReadonlyArray<AnnualIncome> | null;
  browsers?: ReadonlyArray<Browser> | null;
  deliverDayOfWeeks?: ReadonlyArray<DeliverDayOfWeekInput> | null;
  deliverTargetUrls?: ReadonlyArray<ChatbotGroupDeliverUrlInput> | null;
  deliverExcludeUrls?: ReadonlyArray<ChatbotGroupDeliverUrlInput> | null;
  deliverKind?: DeliverKind | null;
  deliverTargetLabelIds?: ReadonlyArray<string> | null;
  deliverExcludeLabelIds?: ReadonlyArray<string> | null;
  devices?: ReadonlyArray<Device> | null;
  endDate?: string | null;
  endTime?: string | null;
  excludeHolidays?: boolean | null;
  excludeReferrers?: ReadonlyArray<ChatbotGroupExcludeReferrerInput> | null;
  genders?: ReadonlyArray<Gender> | null;
  name?: string | null;
  pageId?: string | null;
  platforms?: ReadonlyArray<Platform> | null;
  referrers?: ReadonlyArray<ChatbotGroupReferrerInput> | null;
  sourceIds?: ReadonlyArray<string> | null;
  startDate?: string | null;
  startTime?: string | null;
  status?: DistributionStatus | null;
  targetCookies?: ReadonlyArray<ChatbotGroupTargetCookieInput> | null;
  visitCount?: number | null;
  visitMatchType?: VisitMatch | null;
};
export type DeliverDayOfWeekInput = {
  weekOfMonth?: number | null;
  daysOfWeek: ReadonlyArray<DayOfWeek>;
};
export type ChatbotGroupDeliverUrlInput = {
  url: string;
};
export type ChatbotGroupExcludeReferrerInput = {
  referrer: string;
  matchOp: MatchOp;
};
export type ChatbotGroupReferrerInput = {
  referrer: string;
  matchOp: MatchOp;
};
export type ChatbotGroupTargetCookieInput = {
  key: string;
  value: string;
  matchType: boolean;
};
export type ChatbotEditScreenContainer_Mutation$variables = {
  input: UpdateChatbotGroupInput;
};
export type ChatbotEditScreenContainer_MutationVariables = ChatbotEditScreenContainer_Mutation$variables;
export type ChatbotEditScreenContainer_Mutation$data = {
  readonly updateChatbotGroup: {
    readonly chatbotGroup: {
      readonly slug: string;
      readonly " $fragmentSpreads": FragmentRefs<"ChatbotEditScreenContainer_chatbotGroup">;
    } | null;
  } | null;
};
export type ChatbotEditScreenContainer_MutationResponse = ChatbotEditScreenContainer_Mutation$data;
export type ChatbotEditScreenContainer_Mutation = {
  variables: ChatbotEditScreenContainer_MutationVariables;
  response: ChatbotEditScreenContainer_Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  (v3/*: any*/),
  (v4/*: any*/)
],
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "referrer",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "matchOp",
    "storageKey": null
  }
],
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  },
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChatbotEditScreenContainer_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateChatbotGroupPayload",
        "kind": "LinkedField",
        "name": "updateChatbotGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ChatbotGroup",
            "kind": "LinkedField",
            "name": "chatbotGroup",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ChatbotEditScreenContainer_chatbotGroup"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChatbotEditScreenContainer_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateChatbotGroupPayload",
        "kind": "LinkedField",
        "name": "updateChatbotGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ChatbotGroup",
            "kind": "LinkedField",
            "name": "chatbotGroup",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "page",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Funnel",
                    "kind": "LinkedField",
                    "name": "funnel",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "browsers",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "devices",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "genders",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ageRanges",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deliverKind",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "platforms",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "repeat",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Source",
                "kind": "LinkedField",
                "name": "sources",
                "plural": true,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ChatbotGroupExcludeReferrer",
                "kind": "LinkedField",
                "name": "excludeReferrers",
                "plural": true,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ChatbotGroupReferrer",
                "kind": "LinkedField",
                "name": "referrers",
                "plural": true,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "visitCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "visitMatchType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ChatbotGroupDeliverTargetUrl",
                "kind": "LinkedField",
                "name": "deliverTargetUrls",
                "plural": true,
                "selections": (v7/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ChatbotGroupDeliverExcludeUrl",
                "kind": "LinkedField",
                "name": "deliverExcludeUrls",
                "plural": true,
                "selections": (v7/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserLabel",
                "kind": "LinkedField",
                "name": "deliverTargetLabels",
                "plural": true,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserLabel",
                "kind": "LinkedField",
                "name": "deliverExcludeLabels",
                "plural": true,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ChatbotGroupTargetCookie",
                "kind": "LinkedField",
                "name": "targetCookies",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "key",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "matchType",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DeliverDayOfWeek",
                "kind": "LinkedField",
                "name": "deliverDayOfWeeks",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "weekOfMonth",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "daysOfWeek",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f3c4824cc164eb86fdfcb4305bf96dd3",
    "id": null,
    "metadata": {},
    "name": "ChatbotEditScreenContainer_Mutation",
    "operationKind": "mutation",
    "text": "mutation ChatbotEditScreenContainer_Mutation(\n  $input: UpdateChatbotGroupInput!\n) {\n  updateChatbotGroup(input: $input) {\n    chatbotGroup {\n      slug\n      ...ChatbotEditScreenContainer_chatbotGroup\n      id\n    }\n  }\n}\n\nfragment ChatbotEditScreenContainer_chatbotGroup on ChatbotGroup {\n  id\n  name\n  slug\n  page {\n    id\n    funnel {\n      id\n    }\n  }\n  browsers\n  devices\n  genders\n  ageRanges\n  deliverKind\n  platforms\n  repeat\n  startDate\n  startTime\n  endDate\n  endTime\n  status\n  sources {\n    id\n    name\n  }\n  excludeReferrers {\n    referrer\n    matchOp\n  }\n  referrers {\n    referrer\n    matchOp\n  }\n  visitCount\n  visitMatchType\n  deliverTargetUrls {\n    url\n    id\n  }\n  deliverExcludeUrls {\n    url\n    id\n  }\n  deliverTargetLabels {\n    id\n    name\n  }\n  deliverExcludeLabels {\n    id\n    name\n  }\n  targetCookies {\n    key\n    value\n    matchType\n    id\n  }\n  deliverDayOfWeeks {\n    weekOfMonth\n    daysOfWeek\n  }\n}\n"
  }
};
})();

(node as any).hash = "53c5a92a0af87dbe00ca88037e3c70b0";

export default node;
