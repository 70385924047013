/**
 * @generated SignedSource<<45ea451ec9f030dc89f8f60dcd3b6a1f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PublisherDetailReportGraph_site$data = {
  readonly reportSource: any;
  readonly " $fragmentType": "PublisherDetailReportGraph_site";
};
export type PublisherDetailReportGraph_site = PublisherDetailReportGraph_site$data;
export type PublisherDetailReportGraph_site$key = {
  readonly " $data"?: PublisherDetailReportGraph_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"PublisherDetailReportGraph_site">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "input"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PublisherDetailReportGraph_site",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "input",
          "variableName": "input"
        }
      ],
      "kind": "ScalarField",
      "name": "reportSource",
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "c9ecb550abadaa32097a68ba683d04cb";

export default node;
