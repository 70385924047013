import { array, boolean, number, object, string } from "yup";

import {
  ChatMessageFlowNodeKind,
  ChatMessageImageInput,
  ChatMessageKind,
  ChatMessageReplyGroupKind,
  ChatMessageReplyKind,
  ChatbotActionKind,
  ChatbotApiResultInput,
  ChatbotBotKind,
  ChatbotColorKind,
  ChatbotDisplayKind,
  ChatbotSubmitMethodKind,
} from "~/src/__generated__/schema";

export type ChatMessageFormValues = {
  id: string | null;
  displaySpeed: number;
  hasReply: boolean;
  isInheritQuery: boolean;
  message?: string | null;
  messageType: ChatMessageKind;
  saveReply: boolean;
  submitUrl?: string | null;
  transferReply: boolean;
  chatMessageImage?: ChatMessageImageInput;
};

export type ChatMessageReplyFormValues = {
  id: string | null;
  apiAction?: string | null;
  errorMessage?: string | null;
  label?: string | null;
  name: string;
  placeholder?: string | null;
  regularExpression?: string | null;
  replyType: ChatMessageReplyKind;
  value?: string | null;
  replyNodeId: string;
  parentNodeId: string;
};

export type ChatMessageReplyGroupFormValues = {
  id: string | null;
  divergingPoint: boolean;
  label?: string | null;
  replyGroupType: ChatMessageReplyGroupKind;
};

export type FlowNodeFormValues = {
  kind: ChatMessageFlowNodeKind;
  nodeId: string;
  x: number;
  y: number;
  chatMessage: ChatMessageFormValues;
  chatMessageReplyGroup?: ChatMessageReplyGroupFormValues;
};

export type FlowEdgeFormValues = {
  edgeId: string;
  source: number;
  target: number;
};

export type FormValues = {
  actionKind: ChatbotActionKind;
  timing: number | null;
  botKind: ChatbotBotKind;
  botImage: File | undefined;
  botImageUrl: string | undefined;
  colorKind: ChatbotColorKind;
  displayKind: ChatbotDisplayKind;
  name: string;
  openButtonText: string;
  openButtonImage: File | undefined;
  openButtonImageUrl: string | undefined;
  notFoundMessage: string;
  submitMethodKind: ChatbotSubmitMethodKind;
  title: string;
  toFormButtonImage: File | undefined;
  toFormButtonImageUrl: string | undefined;
  weight: number;
  xOffset: number | null;
  xOffsetUnit: "px" | "%";
  yOffset: number | null;
  yOffsetUnit: "px" | "%";
  questionFinishMessage: string;
  windowColor: string;
  titleTextColor: string;
  chatFormColor: string;
  botTextColor: string;
  botBackgroundColor: string;
  clientTextColor: string;
  clientBackgroundColor: string;
  originalPatternWeight: number;
  questionCsv: File | undefined;
  questionCsvUrl: string | undefined;
  honeycombCode: string | null;
  flowNodes: FlowNodeFormValues[];
  flowEdges: FlowEdgeFormValues[];
  replies: ChatMessageReplyFormValues[];
  deleteChatMessageCandidateIds?: string[];
  chatbotApiResults: ChatbotApiResultInput[];
};

export const chatMessageValidationSchema = object({
  messageType: string().required(),
  message: string().nullable(),
  submitUrl: string().url().nullable(),
  isInheritQuery: boolean().required(),
  hasReply: boolean().required(),
  saveReply: boolean().required(),
  displaySpeed: number().required(),
  transferReply: boolean().required(),
});

export const chatMessageReplyValidationSchema = object({
  replyType: string().required(),
  label: string().nullable(),
  name: string().required(),
  value: string().nullable(),
  regularExpression: string().nullable(),
  placeholder: string().nullable(),
  errorMessage: string().nullable(),
  apiAction: string().nullable(),
});

export const chatMessageReplyGroupValidationSchema = object({
  replyGroupType: string().required(),
  label: string().nullable(),
  divergingPoint: boolean().required(),
  chatMessageReplies: array().of(chatMessageReplyValidationSchema).min(1),
});

export const flowNodeValidationSchema = object({
  nodeId: string().required(),
  kind: string().required(),
  x: number().required(),
  y: number().required(),
  parentNode: number().nullable(),
  chatMessage: chatMessageValidationSchema.required(),
  chatMessageReplyGroup: chatMessageReplyGroupValidationSchema.nullable(),
});

export const flowEdgeValidationSchema = object({
  edgeId: string().required(),
  source: number().required(),
  target: number().required(),
});

export const validationSchema = object({
  actionKind: string(),
  timing: number().nullable(),
  botKind: string(),
  colorKind: string(),
  displayKind: string(),
  name: string().required().max(50),
  openButtonText: string(),
  notFoundMessage: string(),
  submitMethodKind: string(),
  title: string(),
  weight: number().required(),
  xOffset: number().nullable(),
  xOffsetUnit: string(),
  yOffset: number().nullable(),
  yOffsetUnit: string(),
  questionFinishMessage: string(),
  windowColor: string().max(7),
  titleTextColor: string().max(7),
  chatFormColor: string().max(7),
  botTextColor: string().max(7),
  botBackgroundColor: string().max(7),
  clientTextColor: string().max(7),
  clientBackgroundColor: string().max(7),
  originalPatternWeight: number().required(),
  honeycombCode: string().url().nullable(),
});
