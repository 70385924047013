import { useState } from "react";
import { useMount, useUpdateEffect } from "react-use";

import { ABTestReportFilterFormValues } from "~/src/components/features/report/AbtestReport";
import { PopupReportFilterFormValues } from "~/src/components/features/report/PopupReport";

type ABTestReportParameters = {
  cacheKey: string;
  initialFilterValues: ABTestReportFilterFormValues;
};

type PopupReportParameters = {
  cacheKey: string;
  initialFilterValues: PopupReportFilterFormValues;
};

// FIXME: 共通化

export const useABTestReportFilterCache = ({
  cacheKey,
  initialFilterValues,
}: ABTestReportParameters) => {
  const localStorageKey = `filter-values-of-${cacheKey}`;
  const [filterValues, setFilterValues] =
    useState<ABTestReportFilterFormValues>(initialFilterValues);

  // マウント時に localStorage のキャッシュから値を復元する
  useMount(() => {
    try {
      const cache = localStorage.getItem(localStorageKey);
      cache && setFilterValues(JSON.parse(cache));
    } catch {
      localStorage.removeItem(localStorageKey);
    }
  });

  // アイテムが更新された際に localStorage にキャッシュを保存する
  useUpdateEffect(() => {
    const jsonString = JSON.stringify(filterValues);
    localStorage.setItem(localStorageKey, jsonString);
  }, [localStorageKey, filterValues]);

  return {
    filterValues,
    setFilterValues,
  };
};

export const usePopupReportFilterCache = ({
  cacheKey,
  initialFilterValues,
}: PopupReportParameters) => {
  const localStorageKey = `filter-values-of-${cacheKey}`;
  const [filterValues, setFilterValues] =
    useState<PopupReportFilterFormValues>(initialFilterValues);

  // マウント時に localStorage のキャッシュから値を復元する
  useMount(() => {
    try {
      const cache = localStorage.getItem(localStorageKey);
      cache && setFilterValues(JSON.parse(cache));
    } catch {
      localStorage.removeItem(localStorageKey);
    }
  });

  // アイテムが更新された際に localStorage にキャッシュを保存する
  useUpdateEffect(() => {
    const jsonString = JSON.stringify(filterValues);
    localStorage.setItem(localStorageKey, jsonString);
  }, [localStorageKey, filterValues]);

  return {
    filterValues,
    setFilterValues,
  };
};
