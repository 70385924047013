/**
 * @generated SignedSource<<8d10df6f65bf5214fd143b7b0acbada0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GenderReportGraph_site$data = {
  readonly reportGender: any;
  readonly " $fragmentType": "GenderReportGraph_site";
};
export type GenderReportGraph_site = GenderReportGraph_site$data;
export type GenderReportGraph_site$key = {
  readonly " $data"?: GenderReportGraph_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"GenderReportGraph_site">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "input"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "GenderReportGraph_site",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "input",
          "variableName": "input"
        }
      ],
      "kind": "ScalarField",
      "name": "reportGender",
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "699edd9812dee751574f87652d30d52a";

export default node;
