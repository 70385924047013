import { FC } from "react";

import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormikTextareaField } from "~/src/components/common/forms/FormikTextareaField";

export const PopupHtmlFormContainer: FC = () => {
  return (
    <FormControl label="HTML">
      <FormikTextareaField mode="fill" name={`htmlContent`} />
    </FormControl>
  );
};
