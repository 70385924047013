/**
 * @generated SignedSource<<e97ff93952d4ab2e2f595a46ec5da66e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Device = "MOBILE" | "TABLET" | "PC" | "%future added value";
export type ReportAnalyzerInput = {
  startOn: string;
  endOn: string;
  device?: Device | null;
  sourceId?: string | null;
  funnelId?: string | null;
};
export type DashboardPageContainer_Query$variables = {
  slug: string;
  analyzerInput: ReportAnalyzerInput;
};
export type DashboardPageContainer_QueryVariables = DashboardPageContainer_Query$variables;
export type DashboardPageContainer_Query$data = {
  readonly viewer: {
    readonly id: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ReportListContainer_query">;
};
export type DashboardPageContainer_QueryResponse = DashboardPageContainer_Query$data;
export type DashboardPageContainer_Query = {
  variables: DashboardPageContainer_QueryVariables;
  response: DashboardPageContainer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "analyzerInput"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "slug"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "viewer",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "analyzerInput"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardPageContainer_Query",
    "selections": [
      (v3/*: any*/),
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ReportListContainer_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "DashboardPageContainer_Query",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "slug",
            "variableName": "slug"
          }
        ],
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "kind": "ScalarField",
            "name": "dailyLandingPageDropRates",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "kind": "ScalarField",
            "name": "dailyAverageStayTimes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "kind": "ScalarField",
            "name": "dailyAverageConversionTimes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "kind": "ScalarField",
            "name": "landingPageUniqueUser",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "kind": "ScalarField",
            "name": "formPageUniqueUser",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "kind": "ScalarField",
            "name": "confirmPageUniqueUser",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "kind": "ScalarField",
            "name": "thanksPageUniqueUser",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "kind": "ScalarField",
            "name": "uniqueUserCountPerFunnel",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bfceabad5664cd00358a963dc24b02f7",
    "id": null,
    "metadata": {},
    "name": "DashboardPageContainer_Query",
    "operationKind": "query",
    "text": "query DashboardPageContainer_Query(\n  $slug: String!\n  $analyzerInput: ReportAnalyzerInput!\n) {\n  viewer {\n    id\n  }\n  ...ReportListContainer_query\n}\n\nfragment FunnelTableSection_site on Site {\n  landingPageUniqueUser(input: $analyzerInput)\n  formPageUniqueUser(input: $analyzerInput)\n  confirmPageUniqueUser(input: $analyzerInput)\n  thanksPageUniqueUser(input: $analyzerInput)\n  uniqueUserCountPerFunnel(input: $analyzerInput)\n}\n\nfragment FunnelTransitionReportSection_site on Site {\n  dailyLandingPageDropRates(input: $analyzerInput)\n  dailyAverageStayTimes(input: $analyzerInput)\n  dailyAverageConversionTimes(input: $analyzerInput)\n}\n\nfragment ReportListContainer_query on Query {\n  site(slug: $slug) {\n    ...FunnelTransitionReportSection_site\n    ...FunnelTableSection_site\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ed039704eee5f88ac5d206033424061b";

export default node;
