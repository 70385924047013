import { Center, useToast } from "@chakra-ui/react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { SigninForm, SigninFormProps } from "~/src/components/features/auth";

export type Props = {};

export const SigninPage: FC<Props> = () => {
  const navigate = useNavigate();

  const toast = useToast();

  const handleSubmit = useCallback<SigninFormProps["onSubmit"]>(
    async (values) => {
      try {
        const auth = getAuth();
        await signInWithEmailAndPassword(auth, values.email, values.password);
        navigate("/", { replace: true });
      } catch (err) {
        toast({
          title: "サインインに失敗しました",
          status: "error",
        });
      }
    },
    [navigate, toast]
  );

  return (
    <Center h={"100%"}>
      <SigninForm onSubmit={handleSubmit} />
    </Center>
  );
};
