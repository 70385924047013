import { FC } from "react";
import { Link } from "react-router-dom";

import { chakraFactory } from "~/src/lib/chakra-ui";

import logoImage from "./assets/logo.png";

const StyledLogoImage = chakraFactory("img", {
  baseStyle: {
    height: "24px",
  },
});

export const HeaderLogo: FC = () => (
  <Link to="/">
    <StyledLogoImage src={logoImage} alt="robee logo" />
  </Link>
);
