import { useFormikContext } from "formik";
import { dissoc, pick } from "ramda";
import { FC, useCallback } from "react";
import { Edge, Node } from "react-flow-renderer";

import { ChatbotApiResultInput } from "~/src/__generated__/schema";
import {
  NodeData,
  ChatbotFlowForm as Renderer,
} from "~/src/components/features/chatbot/ChatbotFlowForms";

import {
  ChatMessageReplyFormValues,
  FlowEdgeFormValues,
  FlowNodeFormValues,
  FormValues,
} from "./validationSchema";

export type Props = {
  nodes: Node<NodeData>[];
  edges: Edge<any>[];
};

const initialNodes: Node<NodeData>[] = [
  {
    id: "launch_chat_message",
    type: "input",
    data: { label: "チャット開始", message: null, replyGroup: null },
    position: { x: 10, y: 10 },
  },
];

export const ChatbotFlowForm: FC<Props> = ({ nodes, edges }) => {
  const { setFieldValue } = useFormikContext<FormValues>();

  const handleFlowDataChange = useCallback(
    (nodes: Node<NodeData>[], edges: Edge<any>[]) => {
      const messageNodes = nodes.filter((node) => node.type !== "REPLY");

      const flowNodes = messageNodes.map((node) => {
        const flowNode = {
          nodeId: node.id,
          kind: node.type,
          x: node.position.x,
          y: node.position.y,
          chatMessage: {
            id: node.data.message?.id,
            messageType: node.data.message?.kind,
            message: node.data.message?.message,
            submitUrl: node.data.message?.submitUrl,
            isInheritQuery: node.data.message?.isInheritQuery,
            hasReply: node.data.replyGroup ? true : false,
            saveReply: node.data.message?.isSaveReply,
            displaySpeed: node.data.message?.displaySpeed,
            transferReply: node.data.message?.isTransferReply,
            chatMessageImage: !node.data.message?.chatMessageImage
              ? node.data.message?.chatMessageImage
              : dissoc("imageUrl", node.data.message.chatMessageImage),
          },
        } as FlowNodeFormValues;

        if (node.data.replyGroup) {
          flowNode.chatMessageReplyGroup = {
            id: node.data.replyGroup.id,
            replyGroupType: node.data.replyGroup.kind,
            label: node.data.replyGroup.label,
            divergingPoint: node.data.replyGroup.divergingPoint,
          };
        }
        return flowNode;
      });

      const flowEdges = edges.map((edge) => {
        return {
          edgeId: edge.source,
          source: edge.source.includes("_reply_")
            ? Number(edge.source.split("_reply_").pop())
            : Number(edge.source),
          target: Number(edge.target),
        } as FlowEdgeFormValues;
      });

      const replies: ChatMessageReplyFormValues[] = [];
      const chatbotApiResults: ChatbotApiResultInput[] = [];

      messageNodes.forEach((node) => {
        if (node.data.replyGroup) {
          const replyGroup = node.data.replyGroup;
          const parentNodeId = node.id;

          replyGroup.replies.forEach((reply, index) => {
            replies.push({
              id: reply.id,
              parentNodeId,
              replyType: reply.kind,
              replyNodeId: reply.replyNodeId || "",
              ...pick(
                [
                  "name",
                  "value",
                  "label",
                  "regularExpression",
                  "placeholder",
                  "errorMessage",
                  "apiAction",
                ],
                reply
              ),
            });

            reply.chatbotApiResults?.forEach((apiResult) => {
              chatbotApiResults.push({
                replyIndex: index,
                ...apiResult,
              });
            });
          });
        }
      });

      setFieldValue("flowNodes", flowNodes);
      setFieldValue("flowEdges", flowEdges);
      setFieldValue("replies", replies);
      setFieldValue("chatbotApiResults", chatbotApiResults);
    },
    [setFieldValue]
  );

  return (
    <Renderer
      disabled={false}
      flowChange={handleFlowDataChange}
      defaultNodes={nodes.length === 0 ? initialNodes : nodes}
      defaultEdges={edges}
    />
  );
};
