/**
 * @generated SignedSource<<21555a82a7dfe91107086e6a64841e82>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type NotificationCategory = "UPGRADE" | "SUPPORT" | "INCIDENT" | "EVENT" | "OTHER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type NotificationEditPage_notification$data = {
  readonly id: string;
  readonly title: string;
  readonly body: string | null;
  readonly category: NotificationCategory | null;
  readonly publishedAt: string;
  readonly slug: string;
  readonly " $fragmentType": "NotificationEditPage_notification";
};
export type NotificationEditPage_notification = NotificationEditPage_notification$data;
export type NotificationEditPage_notification$key = {
  readonly " $data"?: NotificationEditPage_notification$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationEditPage_notification">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotificationEditPage_notification",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "body",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "category",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publishedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    }
  ],
  "type": "Notification",
  "abstractKey": null
};

(node as any).hash = "52cda27a30262ac0c1a5f912da757287";

export default node;
