/**
 * @generated SignedSource<<07da344e438daca5314e3b87bd041ca3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ABTestEditScreenContainer_site$data = {
  readonly id: string;
  readonly name: string;
  readonly slug: string;
  readonly " $fragmentSpreads": FragmentRefs<"ABTestForm_site">;
  readonly " $fragmentType": "ABTestEditScreenContainer_site";
};
export type ABTestEditScreenContainer_site = ABTestEditScreenContainer_site$data;
export type ABTestEditScreenContainer_site$key = {
  readonly " $data"?: ABTestEditScreenContainer_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"ABTestEditScreenContainer_site">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ABTestEditScreenContainer_site",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ABTestForm_site"
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "efba8b75030707efaae0a5a1464752aa";

export default node;
