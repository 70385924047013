import {
  Modal as ChakraModal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { FC } from "react";

import { chakraFactory } from "~/src/lib/chakra-ui";

import { HorizontalSeparator } from "../Separator";

export type Props = {
  isOpen: boolean;
  title: string;
  width?: string;
  disableHorizontalSeparatorMargin?: boolean;
  onClose: () => void;
};

const StyledModalContent = chakraFactory(ModalContent, {
  baseStyle: {
    padding: "24px",
    borderRadius: 0,
  },
});

const StyledModalHeader = chakraFactory(ModalHeader, {
  baseStyle: {
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "26px",
    display: "flex",
    alignItems: "center",
    color: "#000000",
    padding: 0,
    mb: "16px",
  },
});

export const Modal: FC<Props> = ({
  children,
  isOpen,
  title,
  width = "80vw",
  disableHorizontalSeparatorMargin = false,
  onClose,
}) =>
  isOpen ? (
    <ChakraModal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <StyledModalContent maxWidth={width}>
        <StyledModalHeader>{title}</StyledModalHeader>
        <HorizontalSeparator
          mb={disableHorizontalSeparatorMargin ? "0" : "24px"}
        />
        {children}
      </StyledModalContent>
    </ChakraModal>
  ) : null;
