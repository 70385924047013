import { Box } from "@chakra-ui/react";
import { FC } from "react";

import { Checkbox, CheckboxProps } from "~/src/components/common/Checkbox";
import { chakraFactory } from "~/src/lib/chakra-ui";

import { columnStyles } from "../styles";

export type Props = {
  isChecked: CheckboxProps["isChecked"];
  onChange: CheckboxProps["onChange"];
};

const StyledDataColumnBox = chakraFactory(Box, {
  baseStyle: {
    ...columnStyles,
    justifyContent: "center",
    maxWidth: "40px",
    minWidth: "40px",
  },
});

export const TableDataCheckboxColumn: FC<Props> = ({ isChecked, onChange }) => (
  <StyledDataColumnBox>
    <Checkbox iconType="check" isChecked={isChecked} onChange={onChange} />
  </StyledDataColumnBox>
);
