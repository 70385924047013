/**
 * @generated SignedSource<<a9ff5f41ce26e2a62af92cb930002b9b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UserRole = "SUPER" | "ADMIN_ALL" | "ADMIN" | "JUDGE" | "CLIENT" | "SAAS_CLIENT" | "%future added value";
export type FunnelCreateFormContainer_Query$variables = {
  siteSlug: string;
};
export type FunnelCreateFormContainer_QueryVariables = FunnelCreateFormContainer_Query$variables;
export type FunnelCreateFormContainer_Query$data = {
  readonly viewer: {
    readonly role: UserRole;
  };
  readonly site: {
    readonly id: string;
  };
};
export type FunnelCreateFormContainer_QueryResponse = FunnelCreateFormContainer_Query$data;
export type FunnelCreateFormContainer_Query = {
  variables: FunnelCreateFormContainer_QueryVariables;
  response: FunnelCreateFormContainer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "siteSlug"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "slug",
      "variableName": "siteSlug"
    }
  ],
  "concreteType": "Site",
  "kind": "LinkedField",
  "name": "site",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FunnelCreateFormContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      (v3/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FunnelCreateFormContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      (v3/*: any*/)
    ]
  },
  "params": {
    "cacheID": "41f8200c6aa420ce17aae45f8bd63dae",
    "id": null,
    "metadata": {},
    "name": "FunnelCreateFormContainer_Query",
    "operationKind": "query",
    "text": "query FunnelCreateFormContainer_Query(\n  $siteSlug: String!\n) {\n  viewer {\n    role\n    id\n  }\n  site(slug: $siteSlug) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "c60d07419e48af45d24448c0e5199c16";

export default node;
