export const allTableItems = [
  { label: "アクセスユーザー数", value: "totalUserCount" },
  { label: "表示人数", value: "impressionUserCount" },
  { label: "クリック人数", value: "clickUserCount" },
  { label: "CV（合計）", value: "totalCV" },
  { label: "CV（クリック）", value: "clickCV" },
  { label: "CV（表示回数）", value: "impressionCV" },
  { label: "CV（ready）", value: "readyCV" },
  { label: "CTR", value: "CTR" },
  { label: "CVR（合計）", value: "CVR" },
  { label: "CVR（クリック）", value: "clickCVR" },
  { label: "CVR（表示回数）", value: "impressionCVR" },
];

export const initialSelectedTableItems = allTableItems;
