/**
 * @generated SignedSource<<5eefe35de7532ffbae4018cfd03a8c83>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateSourceTagInput = {
  clientMutationId?: string | null;
  sourceTagId: string;
  sourceId: string;
  key?: string | null;
  value?: string | null;
};
export type FunnelSourceEditFormContainer_Mutation$variables = {
  input: UpdateSourceTagInput;
};
export type FunnelSourceEditFormContainer_MutationVariables = FunnelSourceEditFormContainer_Mutation$variables;
export type FunnelSourceEditFormContainer_Mutation$data = {
  readonly updateSourceTag: {
    readonly sourceTag: {
      readonly id: string;
      readonly key: string | null;
      readonly value: string | null;
      readonly slug: string;
      readonly source: {
        readonly id: string;
        readonly name: string;
      };
      readonly pageUrl: string;
    } | null;
  } | null;
};
export type FunnelSourceEditFormContainer_MutationResponse = FunnelSourceEditFormContainer_Mutation$data;
export type FunnelSourceEditFormContainer_Mutation = {
  variables: FunnelSourceEditFormContainer_MutationVariables;
  response: FunnelSourceEditFormContainer_Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateSourceTagPayload",
    "kind": "LinkedField",
    "name": "updateSourceTag",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SourceTag",
        "kind": "LinkedField",
        "name": "sourceTag",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "key",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Source",
            "kind": "LinkedField",
            "name": "source",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pageUrl",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FunnelSourceEditFormContainer_Mutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FunnelSourceEditFormContainer_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "e7eda214e157b88e21caca0c329a36e0",
    "id": null,
    "metadata": {},
    "name": "FunnelSourceEditFormContainer_Mutation",
    "operationKind": "mutation",
    "text": "mutation FunnelSourceEditFormContainer_Mutation(\n  $input: UpdateSourceTagInput!\n) {\n  updateSourceTag(input: $input) {\n    sourceTag {\n      id\n      key\n      value\n      slug\n      source {\n        id\n        name\n      }\n      pageUrl\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f084e6381b5bcb76a65c2e724d7e2c0a";

export default node;
