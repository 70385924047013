import {
  As,
  CSSObject,
  ChakraComponent,
  SystemStyleObject,
  chakra,
  shouldForwardProp,
} from "@chakra-ui/react";
import isValidHTMLProp from "@emotion/is-prop-valid";

type StyleResolverProps = {
  __css?: SystemStyleObject;
  sx?: SystemStyleObject;
  theme: any;
  css?: CSSObject;
} & SystemStyleObject;

type StyledOptions<P> = {
  shouldForwardProp?(prop: string): boolean;
  label?: string;
  baseStyle?:
    | SystemStyleObject
    | ((props: StyleResolverProps & P) => SystemStyleObject);
};

// warning抑制のためのフィルタ(挙動に問題があれば削除可)
const NO_FORWARDED_PROPS = [
  "isLink",
  "isFocusable",
  "isIndeterminate",
  "rightAdornment",
  "stringMap",
  "withColumnNumbersRow",
];

const customShouldForwardProp = (prop: string) => {
  if (NO_FORWARDED_PROPS.includes(prop)) return false;
  if (isValidHTMLProp(prop)) return true;
  if (shouldForwardProp(prop)) return true;
  return false;
};

/**
 * `chakra` 関数に Props の型を Generics で渡しても baseStyle 内で補完されないので
 * 型ごと上書きして補完が効くようにしている
 * @see https://chakra-ui.com/docs/features/chakra-factory
 */
export const chakraFactory = <P = {}, T extends As = As>(
  component: T,
  options: StyledOptions<P>
) =>
  // @ts-expect-error
  chakra(component, {
    ...options,
    shouldForwardProp: customShouldForwardProp,
  }) as ChakraComponent<T, P>;
