import { groupBy, prop, uniqBy } from "ramda";
import { FC, useCallback, useMemo, useState } from "react";
import { graphql, useFragment } from "react-relay";

import { MonthlyReportGraph_site$key } from "~/src/__relay_artifacts__/MonthlyReportGraph_site.graphql";

import { AreaGraph } from "../AreaGraph";
import {
  GraphProp,
  MeasureKey,
  findGraphTitleByMeasureKey,
  isMeasureKey,
  measureOptions,
  reduceByMeasureKey,
} from "../utils";

import { Source } from "./types";

const fragment = graphql`
  fragment MonthlyReportGraph_site on Site {
    reportMonthly(input: $input)
  }
`;

export type Prop = {
  siteRef: MonthlyReportGraph_site$key;
};

type FormValues = {
  accessMonth: string | null;
  measureKey: MeasureKey | null;
};

export const MonthlyReportGraph: FC<Prop> = ({ siteRef }) => {
  const { reportMonthly } = useFragment<typeof siteRef>(fragment, siteRef);

  const [formValues, setFormValues] = useState<FormValues>({
    accessMonth: null,
    measureKey: null,
  });

  const sources: Source[] = useMemo(() => {
    const uniqueSources = uniqBy<any, any>(
      prop("access_month"),
      reportMonthly.daily_report
    );
    return uniqueSources.map((row) => {
      return { id: row.access_month, accessMonth: row.access_month };
    });
  }, [reportMonthly]);

  const dailyReports = useMemo(() => {
    if (formValues.accessMonth === null) {
      return groupBy<any, any>(
        prop("access_month"),
        reportMonthly.daily_report
      );
    }
    const reports = reportMonthly.daily_report as any[];
    const filteredReports = reports.filter(
      (row) => row.access_month === formValues.accessMonth
    );
    return groupBy<any, any>(prop("access_month"), filteredReports);
  }, [reportMonthly, formValues]);

  const graphReports = useMemo(() => {
    const results: GraphProp[] = [];
    Object.keys(dailyReports).forEach((key) => {
      const result = reduceByMeasureKey(
        dailyReports[key],
        formValues.measureKey || "UNIQUE_USER"
      );
      results.push({ x: key, y: result });
    });
    return results;
  }, [dailyReports, formValues]);

  const sourceOptions = useMemo(() => {
    return sources.map((source) => {
      return { label: source.accessMonth, value: source.id };
    });
  }, [sources]);

  const handleSubmit = useCallback(({ accessMonth, measureKey }) => {
    if (measureKey === null || isMeasureKey(measureKey, measureOptions)) {
      setFormValues({ accessMonth, measureKey });
    }
  }, []);

  return (
    <AreaGraph
      title={`月別推移｜${findGraphTitleByMeasureKey(formValues.measureKey)}`}
      label={`${findGraphTitleByMeasureKey(formValues.measureKey)}`}
      dataArray={[{ data: graphReports }]}
      graphCustomizedButtonProps={{
        selectFields: [
          {
            name: "accessMonth",
            label: "アクセス月",
            options: sourceOptions,
            isMultiple: false,
          },
          {
            name: "measureKey",
            label: "項目",
            options: measureOptions,
            isMultiple: false,
          },
        ],
        formValues,
        onSubmit: handleSubmit,
      }}
    />
  );
};
