import { useToast } from "@chakra-ui/react";
import { FC, useCallback } from "react";
import { ConnectionHandler, graphql, useLazyLoadQuery } from "react-relay";
import { useNavigate } from "react-router";

import { ExcludedIPCreateFormContainer_Mutation } from "~/src/__relay_artifacts__/ExcludedIPCreateFormContainer_Mutation.graphql";
import { ExcludedIPCreateFormContainer_Query } from "~/src/__relay_artifacts__/ExcludedIPCreateFormContainer_Query.graphql";
import { useFormErrorHandler } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";

import { ExcludedIPForm, ExcludedIPFormProps } from "./presentations";

type Props = {
  siteSlug: string;
};

const query = graphql`
  query ExcludedIPCreateFormContainer_Query($siteSlug: String!) {
    site(slug: $siteSlug) {
      id
    }
  }
`;

const mutation = graphql`
  mutation ExcludedIPCreateFormContainer_Mutation(
    $input: CreateExcludedIpAddressInput!
    $connections: [ID!]!
  ) {
    createExcludedIpAddress(input: $input) {
      excludedIpAddressEdge @appendEdge(connections: $connections) {
        node {
          id
          ipAddress
          status
        }
      }
    }
  }
`;

export const ExcludedIPCreateFormContainer: FC<Props> = ({ siteSlug }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const { onFormError } = useFormErrorHandler();
  const { site } = useLazyLoadQuery<ExcludedIPCreateFormContainer_Query>(
    query,
    { siteSlug }
  );
  const createExcludedIpAddressMutation =
    useMutationCommit<ExcludedIPCreateFormContainer_Mutation>(mutation);

  const handleCancel = useCallback(() => navigate(-1), [navigate]);

  const handleSubmit = useCallback<ExcludedIPFormProps["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const connectionId = ConnectionHandler.getConnectionID(
          site.id,
          "ExcludedIPListTab_excludedIpAddresses"
        );
        const result = await createExcludedIpAddressMutation({
          variables: {
            connections: [connectionId],
            input: {
              siteId: site.id,
              ipAddress: values.ipAddress,
              status: values.status,
            },
          },
        });
        if (!result.createExcludedIpAddress?.excludedIpAddressEdge) {
          throw new Error("assertion failed");
        }
        toast({ title: "除外IPを作成しました", status: "success" });
        navigate(`/sites/${siteSlug}/settings?tab=excluded_ip`);
      } catch (err) {
        onFormError(err, setErrors);
      }
    },
    [
      createExcludedIpAddressMutation,
      navigate,
      onFormError,
      site.id,
      siteSlug,
      toast,
    ]
  );

  return (
    <ExcludedIPForm onCancelClick={handleCancel} onSubmit={handleSubmit} />
  );
};
