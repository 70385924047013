import { Box, Image, Td, Tr } from "@chakra-ui/react";
import { FC, useCallback } from "react";

import { DistributionStatus, PageKind } from "~/src/__generated__/schema";
import { PatternContentJudgeStatus } from "~/src/__relay_artifacts__/PopupLinkFormContainer_Query.graphql";
import { TableDataCheckboxColumn } from "~/src/components/common/tables/Table";

export type Popup = {
  id: string;
  name: string | null;
  funnelName: string;
  pageName: string;
  status: DistributionStatus;
  judgeStatus: PatternContentJudgeStatus;
  imageUrl: string | null;
  pageKind: PageKind;
  sortNumber: number;
};

export type Props = {
  popup: Popup;
  isChecked: boolean;
  onCheck: (id: string) => void;
};

export const PopupLinkFormListItem: FC<Props> = ({
  popup,
  isChecked,
  onCheck,
}) => {
  const handleCheck = useCallback(() => {
    onCheck(popup.id);
  }, [onCheck, popup.id]);

  return (
    <Tr>
      <Td>
        <TableDataCheckboxColumn isChecked={isChecked} onChange={handleCheck} />
      </Td>
      <Td>{popup.name}</Td>
      <Td>
        <Box
          borderRadius={"xl"}
          p={1}
          align="center"
          color={"white"}
          bg={popup.status === "ACTIVE" ? "#4CAF50" : "#979797"}
        >
          {popup.status === "ACTIVE" ? "実施中" : "停止中"}
        </Box>
        <Box
          borderRadius={"xl"}
          p={1}
          align="center"
          color={"white"}
          bg={popup.judgeStatus === "APPROVED" ? "#4CAF50" : "#979797"}
        >
          {popup.judgeStatus === "APPROVED" ? "承認" : "未承認"}
        </Box>
      </Td>
      <Td>
        {popup.imageUrl && (
          <Image src={popup.imageUrl} boxSize={"60px"} fit={"contain"} />
        )}
      </Td>
      <Td>{popup.funnelName}</Td>
      <Td>
        {popup.pageKind === "FORM"
          ? `${popup.pageName}(${popup.sortNumber})`
          : popup.pageName}
      </Td>
    </Tr>
  );
};
