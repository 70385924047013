import { SiteReportOrderField } from "~/src/__generated__/schema";

import { HeaderOption } from "./types";

export const measureOptions = [
  { label: "流入数", value: "UNIQUE_USER" },
  { label: "CV-U", value: "CONVERSION_USER" },
  { label: "CVR", value: "CONVERSION_USER_RATE" },
  { label: "LP離脱率", value: "LP_DROP_RATE" },
  { label: "LP読了率", value: "LP_SCROLL_RATE" },
  { label: "LP平均滞在時間", value: "LP_AVERAGE_STAY_TIME" },
  { label: "EF離脱率", value: "FORM_DROP_RATE" },
  { label: "SP", value: "MOBILE_RATE" },
  { label: "TB", value: "TABLET_RATE" },
  { label: "PC", value: "PC_RATE" },
] as const;

export const disableColumnOptions = [
  { label: "流入数", value: 1 },
  { label: "CV-U", value: 2 },
  { label: "CVR", value: 3 },
  { label: "LP離脱率", value: 4 },
  { label: "LP読了率", value: 5 },
  { label: "LP平均滞在時間", value: 6 },
  { label: "EF離脱率", value: 7 },
  { label: "SP", value: 8 },
  { label: "TB", value: 9 },
  { label: "PC", value: 10 },
] as const;

export const headerOptionsForFunnelReport: HeaderOption<SiteReportOrderField>[] =
  [
    { label: "ファネル" },
    { label: "流入数", orderField: "UNIQUE_USER" },
    { label: "CV-U", orderField: "CONVERSION" },
    { label: "CVR", orderField: "CVR" },
    { label: "LP離脱率", orderField: "LP_DROP_RATE" },
    { label: "LP読了率", orderField: "LP_SCROLL_RATE" },
    { label: "LP平均滞在時間", orderField: "LP_AVERAGE_STAY_TIME" },
    { label: "EF離脱率", orderField: "FORM_DROP_RATE" },
    { label: "SP", orderField: "MOBILE_RATE" },
    { label: "TB", orderField: "TABLET_RATE" },
    { label: "PC", orderField: "PC_RATE" },
  ];

export const headerOptionsForABTestReport: HeaderOption<SiteReportOrderField>[] =
  [
    { label: "A/Bテスト名" },
    { label: "A/Bテストグループ名" },
    { label: "流入数", orderField: "UNIQUE_USER" },
    { label: "CV-U", orderField: "CONVERSION" },
    { label: "CVR", orderField: "CVR" },
    { label: "LP平均滞在時間", orderField: "LP_AVERAGE_STAY_TIME" },
    { label: "SP", orderField: "MOBILE_RATE" },
    { label: "TB", orderField: "TABLET_RATE" },
    { label: "PC", orderField: "PC_RATE" },
  ];

export const headerOptionsForChatbotReport: HeaderOption<SiteReportOrderField>[] =
  [
    { label: "チャットボット名" },
    { label: "チャットボットグループ名" },
    { label: "配信人数", orderField: "PAGE_VIEW_USER" },
    { label: "利用人数", orderField: "USE_CHATBOT_USER" },
    { label: "CV数", orderField: "CONVERSION" },
    { label: "CTR", orderField: "USE_CHATBOT_USER_RATE" },
    { label: "CVR", orderField: "CHATBOT_CVR" },
    { label: "SP", orderField: "MOBILE_RATE" },
    { label: "TB", orderField: "TABLET_RATE" },
    { label: "PC", orderField: "PC_RATE" },
  ];

export const headerOptionsForGenderReport: HeaderOption<SiteReportOrderField>[] =
  [
    { label: "性別" },
    { label: "流入数", orderField: "UNIQUE_USER" },
    { label: "CV-U", orderField: "CONVERSION" },
    { label: "CVR", orderField: "CVR" },
    { label: "LP離脱率", orderField: "LP_DROP_RATE" },
    { label: "LP読了率", orderField: "LP_SCROLL_RATE" },
    { label: "LP平均滞在時間", orderField: "LP_AVERAGE_STAY_TIME" },
    { label: "EF離脱率", orderField: "FORM_DROP_RATE" },
    { label: "SP", orderField: "MOBILE_RATE" },
    { label: "TB", orderField: "TABLET_RATE" },
    { label: "PC", orderField: "PC_RATE" },
  ];

export const headerOptionsForPrefectureReport: HeaderOption<SiteReportOrderField>[] =
  [
    { label: "都道府県" },
    { label: "流入数", orderField: "UNIQUE_USER" },
    { label: "CV-U", orderField: "CONVERSION" },
    { label: "CVR", orderField: "CVR" },
    { label: "LP離脱率", orderField: "LP_DROP_RATE" },
    { label: "LP読了率", orderField: "LP_SCROLL_RATE" },
    { label: "LP平均滞在時間", orderField: "LP_AVERAGE_STAY_TIME" },
    { label: "EF離脱率", orderField: "FORM_DROP_RATE" },
    { label: "SP", orderField: "MOBILE_RATE" },
    { label: "TB", orderField: "TABLET_RATE" },
    { label: "PC", orderField: "PC_RATE" },
  ];

export const headerOptionsForPublisherReport: HeaderOption<SiteReportOrderField>[] =
  [
    { label: "ドメイン" },
    { label: "流入数", orderField: "UNIQUE_USER" },
    { label: "CV-U", orderField: "CONVERSION" },
    { label: "CVR", orderField: "CVR" },
    { label: "LP離脱率", orderField: "LP_DROP_RATE" },
    { label: "LP読了率", orderField: "LP_SCROLL_RATE" },
    { label: "LP平均滞在時間", orderField: "LP_AVERAGE_STAY_TIME" },
    { label: "EF離脱率", orderField: "FORM_DROP_RATE" },
    { label: "SP", orderField: "MOBILE_RATE" },
    { label: "TB", orderField: "TABLET_RATE" },
    { label: "PC", orderField: "PC_RATE" },
  ];

export const headerOptionsForPublisherDetailReport: HeaderOption<SiteReportOrderField>[] =
  [
    { label: "ドメイン" },
    { label: "ページ" },
    { label: "流入数", orderField: "UNIQUE_USER" },
    { label: "CV-U", orderField: "CONVERSION" },
    { label: "CVR", orderField: "CVR" },
    { label: "LP離脱率", orderField: "LP_DROP_RATE" },
    { label: "LP読了率", orderField: "LP_SCROLL_RATE" },
    { label: "LP平均滞在時間", orderField: "LP_AVERAGE_STAY_TIME" },
    { label: "EF離脱率", orderField: "FORM_DROP_RATE" },
    { label: "SP", orderField: "MOBILE_RATE" },
    { label: "TB", orderField: "TABLET_RATE" },
    { label: "PC", orderField: "PC_RATE" },
  ];

export const headerOptionsForDailyReport: HeaderOption<SiteReportOrderField>[] =
  [
    { label: "日付" },
    { label: "流入数", orderField: "UNIQUE_USER" },
    { label: "CV-U", orderField: "CONVERSION" },
    { label: "CVR", orderField: "CVR" },
    { label: "LP離脱率", orderField: "LP_DROP_RATE" },
    { label: "LP読了率", orderField: "LP_SCROLL_RATE" },
    { label: "LP平均滞在時間", orderField: "LP_AVERAGE_STAY_TIME" },
    { label: "EF離脱率", orderField: "FORM_DROP_RATE" },
    { label: "SP", orderField: "MOBILE_RATE" },
    { label: "TB", orderField: "TABLET_RATE" },
    { label: "PC", orderField: "PC_RATE" },
  ];

export const headerOptionsForMonthlyReport: HeaderOption<SiteReportOrderField>[] =
  [
    { label: "アクセス月" },
    { label: "流入数", orderField: "UNIQUE_USER" },
    { label: "CV-U", orderField: "CONVERSION" },
    { label: "CVR", orderField: "CVR" },
    { label: "LP離脱率", orderField: "LP_DROP_RATE" },
    { label: "LP読了率", orderField: "LP_SCROLL_RATE" },
    { label: "LP平均滞在時間", orderField: "LP_AVERAGE_STAY_TIME" },
    { label: "EF離脱率", orderField: "FORM_DROP_RATE" },
    { label: "SP", orderField: "MOBILE_RATE" },
    { label: "TB", orderField: "TABLET_RATE" },
    { label: "PC", orderField: "PC_RATE" },
  ];

export const headerOptionsForAgeRangeReport: HeaderOption<SiteReportOrderField>[] =
  [
    { label: "年齢層" },
    { label: "流入数", orderField: "UNIQUE_USER" },
    { label: "CV-U", orderField: "CONVERSION" },
    { label: "CVR", orderField: "CVR" },
    { label: "LP離脱率", orderField: "LP_DROP_RATE" },
    { label: "LP読了率", orderField: "LP_SCROLL_RATE" },
    { label: "LP平均滞在時間", orderField: "LP_AVERAGE_STAY_TIME" },
    { label: "EF離脱率", orderField: "FORM_DROP_RATE" },
    { label: "SP", orderField: "MOBILE_RATE" },
    { label: "TB", orderField: "TABLET_RATE" },
    { label: "PC", orderField: "PC_RATE" },
  ];

export const headerOptionsForPopupReport: HeaderOption<SiteReportOrderField>[] =
  [
    { label: "ポップアップ名" },
    { label: "流入数", orderField: "UNIQUE_USER" },
    { label: "Ready人数", orderField: "POPUP_READY_USER" },
    { label: "Imp人数", orderField: "PAGE_VIEW_USER" },
    { label: "閉じる", orderField: "CLOSE_POPUP" },
    { label: "クリック人数", orderField: "CLICK_POPUP" },

    { label: "CV(計)", orderField: "POPUP_CONVERSION" },
    { label: "CV(click+imp)" },
    { label: "CV(click)", orderField: "POPUP_CLICK_CONVERSION" },
    { label: "CV(imp)", orderField: "POPUP_IMPRESSION_CONVERSION" },
    { label: "CV(ready)", orderField: "POPUP_READY_CONVERSION" },

    { label: "CTR", orderField: "POPUP_CTR" },
    { label: "CVR(計)", orderField: "POPUP_CVR" },
    { label: "CVR(click+imp)" },
    { label: "CVR(click)", orderField: "POPUP_CLICK_CVR" },
    { label: "CVR(imp)", orderField: "POPUP_IMPRESSION_CVR" },
    { label: "CVR(ready)" },

    { label: "SP", orderField: "MOBILE_RATE" },
    { label: "TB", orderField: "TABLET_RATE" },
    { label: "PC", orderField: "PC_RATE" },

    { label: "離脱アクションしたユーザ数", orderField: "POPUP_DROP" },
    { label: "離脱アクションしたCV人数", orderField: "POPUP_DROP_CONVERSION" },
    { label: "離脱アクションしたCVR", orderField: "POPUP_DROP_CVR" },

    { label: "ポップアップ画像URL" },
    { label: "実施ページURL" },
  ];
