import { useToast } from "@chakra-ui/react";
import { FC, useCallback } from "react";
import { graphql } from "react-relay";
import { useNavigate } from "react-router";

import { SourceCreateFormContainer_Mutation } from "~/src/__relay_artifacts__/SourceCreateFormContainer_Mutation.graphql";
import { useFormErrorHandler } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";

import { SourceForm, SourceFormProps } from "./presentations";

export type Props = {
  siteSlug: string;
};

const mutation = graphql`
  mutation SourceCreateFormContainer_Mutation($input: CreateSourceInput!) {
    createSource(input: $input) {
      source {
        id
        name
        description
        tag
        slug
      }
    }
  }
`;

export const SourceCreateFormContainer: FC<Props> = ({ siteSlug }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const { onFormError } = useFormErrorHandler();

  const createSourceMutation =
    useMutationCommit<SourceCreateFormContainer_Mutation>(mutation);

  const handleSubmit = useCallback<SourceFormProps["onSubmit"]>(
    async (values, { setErrors }) => {
      try {
        const { createSource } = await createSourceMutation({
          variables: {
            input: {
              name: values.name,
              description: values.description,
              tag: values.tag,
            },
          },
        });
        if (!createSource?.source) throw new Error("assertion failed");
        toast({ title: "流入元を作成しました", status: "success" });
        navigate(`/sites/${siteSlug}/settings?tab=source`);
      } catch (err) {
        onFormError(err, setErrors);
      }
    },
    [createSourceMutation, navigate, onFormError, siteSlug, toast]
  );

  const handleCancel = useCallback(() => navigate(-1), [navigate]);

  return <SourceForm onCancelClick={handleCancel} onSubmit={handleSubmit} />;
};
