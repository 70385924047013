import { Box, Radio, RadioProps } from "@chakra-ui/react";
import { FC } from "react";

import { chakraFactory } from "~/src/lib/chakra-ui";

import { columnStyles } from "../styles";

export type Props = {
  isChecked: RadioProps["isChecked"];
  onChange: RadioProps["onChange"];
};

const StyledDataColumnBox = chakraFactory(Box, {
  baseStyle: {
    ...columnStyles,
    justifyContent: "center",
    maxWidth: "40px",
    minWidth: "40px",
  },
});

export const TableDataRadioColumn: FC<Props> = ({ isChecked, onChange }) => (
  <StyledDataColumnBox>
    <Radio isChecked={isChecked} onChange={onChange} />
  </StyledDataColumnBox>
);
