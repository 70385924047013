/**
 * @generated SignedSource<<37ef5e18361001a09b067c1e525f012d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type HasForm = "HAS_FORM" | "NO_FORM" | "%future added value";
export type UrlMatch = "EQUAL_TYPE" | "HAS_ID_TYPE" | "EQUAL_OR_HAS_ID_TYPE" | "STARTS_WITH_PREFIX_MATCH" | "REGEX_MATCH_TYPE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type FunnelTableDataRow_funnel$data = {
  readonly id: string;
  readonly slug: string;
  readonly name: string;
  readonly isArchive: boolean;
  readonly landingPage: {
    readonly id: string;
    readonly url: string;
    readonly tabletUrl: string | null;
    readonly mobileUrl: string | null;
    readonly urlMatchType: UrlMatch;
    readonly hasForm: HasForm | null;
  } | null;
  readonly formPage: ReadonlyArray<{
    readonly id: string;
    readonly url: string;
    readonly tabletUrl: string | null;
    readonly mobileUrl: string | null;
    readonly urlMatchType: UrlMatch;
    readonly hasForm: HasForm | null;
  }> | null;
  readonly confirmPage: {
    readonly id: string;
    readonly url: string;
    readonly tabletUrl: string | null;
    readonly mobileUrl: string | null;
    readonly urlMatchType: UrlMatch;
    readonly hasForm: HasForm | null;
  } | null;
  readonly thanksPage: {
    readonly id: string;
    readonly url: string;
    readonly tabletUrl: string | null;
    readonly mobileUrl: string | null;
    readonly urlMatchType: UrlMatch;
    readonly hasForm: HasForm | null;
  } | null;
  readonly " $fragmentType": "FunnelTableDataRow_funnel";
};
export type FunnelTableDataRow_funnel = FunnelTableDataRow_funnel$data;
export type FunnelTableDataRow_funnel$key = {
  readonly " $data"?: FunnelTableDataRow_funnel$data;
  readonly " $fragmentSpreads": FragmentRefs<"FunnelTableDataRow_funnel">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "tabletUrl",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "mobileUrl",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "urlMatchType",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "hasForm",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FunnelTableDataRow_funnel",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isArchive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Page",
      "kind": "LinkedField",
      "name": "landingPage",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Page",
      "kind": "LinkedField",
      "name": "formPage",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Page",
      "kind": "LinkedField",
      "name": "confirmPage",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Page",
      "kind": "LinkedField",
      "name": "thanksPage",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Funnel",
  "abstractKey": null
};
})();

(node as any).hash = "ccbab18e1d981daf23217dba88bac4d5";

export default node;
