import { PopoverContent as ChakraPopoverContent } from "@chakra-ui/react";
import { FC } from "react";

import { chakraFactory } from "~/src/lib/chakra-ui";

export type Props = {
  className?: string;
};

const StyledPopoverContent = chakraFactory(ChakraPopoverContent, {
  baseStyle: {
    border: "none",
    borderRadius: "none",
    background: "#FFFFFF",
    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25) !important",
  },
});

export const PopoverContent: FC<Props> = ({ children, className }) => (
  <StyledPopoverContent className={className}>{children}</StyledPopoverContent>
);
