import { format } from "date-fns";
import { FC, Fragment, useCallback, useContext, useMemo } from "react";
import { graphql, useRefetchableFragment } from "react-relay";
import { NavLink } from "react-router-dom";

import { ChatbotPatternTableDataRow_chatbot$key } from "~/src/__relay_artifacts__/ChatbotPatternTableDataRow_chatbot.graphql";
import {
  ChatbotPatternTableDataRow_RefetchQuery as RefetchQuery,
  ChatbotPatternTableDataRow_RefetchQueryVariables as RefetchVariables,
} from "~/src/__relay_artifacts__/ChatbotPatternTableDataRow_RefetchQuery.graphql";
import {
  TableDataColumn,
  TableDataRow,
} from "~/src/components/common/tables/Table";
import { DistributionStatusSwitch } from "~/src/components/features/global/DistributionStatusSwitch";
import { DateRangeContext } from "~/src/components/features/global/HeaderNavigation/DateRangeField";
import { useUpdateDebounce } from "~/src/lib/react-use";

import { FormValues } from "./ChatbotPatternFilterButton";
import { ChatbotPatternTableAcitons } from "./ChatbotPatternTableActions";

type Props = {
  chatbotRef: ChatbotPatternTableDataRow_chatbot$key;
  siteSlug: string;
  chatbotGroupSlug: string;
  connectionId: string;
  dataColumns: string[];
  filterValues: Pick<FormValues, "sourceId" | "device">;
};

const fragment = graphql`
  fragment ChatbotPatternTableDataRow_chatbot on Chatbot
  @refetchable(queryName: "ChatbotPatternTableDataRow_RefetchQuery")
  @argumentDefinitions(input: { type: "ReportAnalyzerInput!" }) {
    id
    slug
    name
    status
    isArchive
    weight
    kind
    pvUserCount(input: $input)
    startUserCount(input: $input)
    cvUserCount(input: $input)
    ctr(input: $input)
    cvr(input: $input)
  }
`;

export const ChatbotPatternTableDataRow: FC<Props> = ({
  siteSlug,
  chatbotGroupSlug,
  chatbotRef,
  connectionId,
  dataColumns,
  filterValues,
}) => {
  const {
    dateRange: [startOn, endOn],
  } = useContext(DateRangeContext);

  const [chatbot, refetch] = useRefetchableFragment<
    RefetchQuery,
    ChatbotPatternTableDataRow_chatbot$key
  >(fragment, chatbotRef);

  const refetchVariables = useMemo<Partial<RefetchVariables>>(
    () => ({
      input: {
        startOn: format(startOn, "yyyy-MM-dd"),
        endOn: format(endOn, "yyyy-MM-dd"),
        sourceId: filterValues.sourceId,
        device: filterValues.device,
      },
    }),
    [endOn, filterValues.device, filterValues.sourceId, startOn]
  );

  const handleRefetch = useCallback(() => {
    refetch(refetchVariables);
  }, [refetch, refetchVariables]);

  useUpdateDebounce(
    () => {
      handleRefetch();
    },
    100,
    [handleRefetch]
  );

  return (
    <TableDataRow>
      <TableDataColumn
        type="text"
        isLink={chatbot.kind === "B_PATTERN"}
        data={
          chatbot.kind === "B_PATTERN" ? (
            <NavLink
              to={`/sites/${siteSlug}/chatbots/${chatbotGroupSlug}/patterns/${chatbot.slug}`}
            >
              {chatbot.name}
            </NavLink>
          ) : (
            chatbot.name
          )
        }
      />
      <TableDataColumn
        type="actions"
        data={
          <DistributionStatusSwitch
            id={chatbot.id}
            name={chatbot.name}
            isChecked={chatbot.status === "ACTIVE"}
          />
        }
      />
      <TableDataColumn
        type="text"
        data={chatbot.weight ? chatbot.weight : "ランダム配信"}
      />
      {dataColumns.map((dataColumn) => (
        <Fragment key={dataColumn}>
          {dataColumn === "pvUserCount" && (
            <TableDataColumn type="text" data={chatbot.pvUserCount} />
          )}
          {dataColumn === "startUserCount" && (
            <TableDataColumn type="text" data={chatbot.startUserCount} />
          )}
          {dataColumn === "cvUserCount" && (
            <TableDataColumn type="text" data={chatbot.cvUserCount} />
          )}
          {dataColumn === "ctr" && (
            <TableDataColumn type="text" data={chatbot.ctr} />
          )}
          {dataColumn === "cvr" && (
            <TableDataColumn type="text" data={chatbot.cvr} />
          )}
        </Fragment>
      ))}
      <TableDataColumn
        type="actions"
        data={
          chatbot.kind === "B_PATTERN" && (
            <ChatbotPatternTableAcitons
              chatbot={chatbot}
              siteSlug={siteSlug}
              chatbotGroupSlug={chatbotGroupSlug}
              connectionId={connectionId}
            />
          )
        }
      />
    </TableDataRow>
  );
};
