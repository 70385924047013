import { Box, Center, HStack, VStack } from "@chakra-ui/layout";
import { Text } from "@chakra-ui/react";
import {
  AddCircle as AddCircleIcon,
  DeleteOutline as DeleteOutlineIcon,
} from "@mui/icons-material";
import { FieldArray, FieldArrayRenderProps, useField } from "formik";
import { FC, Fragment, useCallback } from "react";

import { SymbolOutlineButton } from "~/src/components/common/Button";
import { FormControl } from "~/src/components/common/forms/FormControl";
import { IconButton } from "~/src/components/common/IconButton";

import { ContentWithScenarioField } from "../../validationSchema";

import { ScenarioFieldBeforeImageList } from "./ScenarioFieldBeforeImageList";
import { ScenarioFieldImageUpladForm } from "./ScenarioFieldImageUploadForm";

type Props = {
  name: string;
};

export const ScenarioFieldArrayContainer: FC<Props> = ({ name }) => {
  const [scenarioFields] = useField<ContentWithScenarioField[]>(name);

  const render = useCallback(
    (arrayHelpers: FieldArrayRenderProps) => (
      <>
        <VStack width="100%">
          {scenarioFields.value &&
            scenarioFields.value.length > 0 &&
            scenarioFields.value.map((field, index) => (
              <Fragment key={index}>
                <HStack width={"100%"}>
                  <Box flex={1} textAlign={"end"}>
                    <IconButton
                      size="24px"
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={() => arrayHelpers.remove(index)}
                      icon={<DeleteOutlineIcon />}
                    />
                  </Box>
                </HStack>
                <FormControl label={`ターゲット - ${index}`}>
                  <HStack>
                    <ScenarioFieldBeforeImageList />
                    <Center width="10%" h="256px">
                      <Text>{"→"}</Text>
                    </Center>
                    <ScenarioFieldImageUpladForm
                      name={name}
                      index={index}
                      imageUrl={field.imageUrl || ""}
                    />
                  </HStack>
                </FormControl>
              </Fragment>
            ))}
        </VStack>
        <Box width="100%">
          <SymbolOutlineButton
            icon={<AddCircleIcon />}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => arrayHelpers.push("")}
          >
            ターゲット追加
          </SymbolOutlineButton>
        </Box>
      </>
    ),
    [scenarioFields, name]
  );

  return <FieldArray name={name} render={render} />;
};
