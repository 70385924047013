import { FC } from "react";

import { SourceCreateFormContainer } from "~/src/components/features/settings/SourceForms";
import { useSourceCreatePageParams } from "~/src/routes/hooks";

import { SourceCreatePage } from "./presentations";

export const SourceCreatePageContainer: FC = () => {
  const { siteSlug } = useSourceCreatePageParams();

  return (
    <SourceCreatePage siteSlug={siteSlug}>
      <SourceCreateFormContainer siteSlug={siteSlug} />
    </SourceCreatePage>
  );
};
