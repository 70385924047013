import { useToast } from "@chakra-ui/toast";
import {
  ContentCopyOutlined as CopyOutlinedIcon,
  DeleteOutline as DeleteOutlineIcon,
  EditOutlined as EditOutlinedIcon,
} from "@mui/icons-material";
import { FC, useCallback, useMemo } from "react";
import { graphql } from "react-relay";

import { PopupSetTableActions_CopyMutation } from "~/src/__relay_artifacts__/PopupSetTableActions_CopyMutation.graphql";
import { PopupSetTableActions_Mutation } from "~/src/__relay_artifacts__/PopupSetTableActions_Mutation.graphql";
import { Modal } from "~/src/components/common/Modal";
import { TableActions } from "~/src/components/common/tables/TableActions";
import { DeleteConfirmDialog } from "~/src/components/features/global/DeleteConfirmDialog";
import { PopupSetEditFormContainer } from "~/src/components/features/popupSet";
import { useOpenState } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";

type Props = {
  popupSet: { id: string; name: string; memo: string | null };
  connectionId: string;
};

const copyMutation = graphql`
  mutation PopupSetTableActions_CopyMutation(
    $connections: [ID!]!
    $input: CopyPopupSetInput!
  ) {
    copyPopupSet(input: $input) {
      popupSetEdge @appendEdge(connections: $connections) {
        node {
          id
          name
          memo
        }
      }
    }
  }
`;

const mutation = graphql`
  mutation PopupSetTableActions_Mutation(
    $connections: [ID!]!
    $input: DeletePopupSetInput!
  ) {
    deletePopupSet(input: $input) {
      deletedPopupSetId @deleteEdge(connections: $connections)
    }
  }
`;

export const PopupSetTableActions: FC<Props> = ({ popupSet, connectionId }) => {
  const toast = useToast();
  const deleteModalOpenState = useOpenState();
  const editModalOpenState = useOpenState();

  const mutate = useMutationCommit<PopupSetTableActions_Mutation>(mutation);

  const copyMutate =
    useMutationCommit<PopupSetTableActions_CopyMutation>(copyMutation);

  const handleCopy = useCallback(async () => {
    try {
      const res = await copyMutate({
        variables: {
          input: {
            popupSetId: popupSet.id,
          },
          connections: [connectionId],
        },
      });
      const copiedPopupSetEdge = res.copyPopupSet?.popupSetEdge;
      if (!copiedPopupSetEdge) throw new Error("assertion failed");

      toast({
        title: `${popupSet.name}をコピーしました`,
        status: "success",
      });
    } catch (err) {
      toast({ title: "コピーに失敗しました", status: "error" });
    }
  }, [toast, popupSet.id, connectionId, copyMutate, popupSet.name]);

  const handleDelete = useCallback(async () => {
    try {
      const res = await mutate({
        variables: {
          input: {
            popupSetId: popupSet.id,
          },
          connections: [connectionId],
        },
      });
      const deletedPopupSetId = res.deletePopupSet?.deletedPopupSetId;
      if (!deletedPopupSetId) throw new Error("assertion failed");

      toast({
        title: `${popupSet.name}を削除しました`,
        status: "success",
      });
    } catch (err) {
      toast({ title: "削除に失敗しました", status: "error" });
    }
  }, [connectionId, mutate, popupSet.id, popupSet.name, toast]);

  const menus = useMemo(
    () => [
      {
        label: "編集",
        icon: <EditOutlinedIcon />,
        onClick: () => editModalOpenState.onOpen(),
      },
      {
        label: "コピー",
        icon: <CopyOutlinedIcon />,
        onClick: () => handleCopy(),
      },
      {
        label: "削除",
        icon: <DeleteOutlineIcon />,
        onClick: () => deleteModalOpenState.onOpen(),
      },
    ],
    [deleteModalOpenState, editModalOpenState, handleCopy]
  );

  return (
    <>
      <TableActions actionMenus={menus} />
      <DeleteConfirmDialog
        name={popupSet.name}
        isOpen={deleteModalOpenState.isOpen}
        onClose={deleteModalOpenState.onClose}
        onDelete={handleDelete}
      />
      <Modal
        title="ポップアップパターンセットの編集"
        isOpen={editModalOpenState.isOpen}
        onClose={editModalOpenState.onClose}
      >
        <PopupSetEditFormContainer
          onClose={editModalOpenState.onClose}
          popupSet={popupSet}
        />
      </Modal>
    </>
  );
};
