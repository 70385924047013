import { Box, Center, VStack } from "@chakra-ui/layout";
import { FormLabel, Text } from "@chakra-ui/react";
import { AddCircleOutlined as AddCircleOutlinedIcon } from "@mui/icons-material";
import { useFormikContext } from "formik";
import { FC, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

import { SymbolOutlineButton } from "~/src/components/common/Button";
import { chakraFactory } from "~/src/lib/chakra-ui";

type Props = {
  name: string;
  index: number;
  imageUrl?: string;
};

const StyledLabel = chakraFactory(FormLabel, {
  baseStyle: {
    fontSize: "unset",
  },
});

export const ScenarioFieldImageUpladForm: FC<Props> = ({
  name,
  index,
  imageUrl,
}) => {
  const [image, setImage] = useState<string>();
  const { setFieldValue } = useFormikContext();
  const [dropped, setDropped] = useState(false);

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length !== 0) {
        setImage(URL.createObjectURL(acceptedFiles[0]));
        setFieldValue(`${name}.${index}.image`, acceptedFiles[0]);
        setDropped(true);
      }
    },
    [setFieldValue, name, index, setDropped]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Center
      width="45%"
      h="256px"
      backgroundColor="blackAlpha.100"
      {...getRootProps()}
    >
      <VStack>
        <Box maxW={"30%"}>
          <img src={dropped ? image : imageUrl} alt="" />
        </Box>
        <input {...getInputProps({ multiple: false })} />
        <Text>{"ここにファイルをドロップ"}</Text>
        <Text>{"または"}</Text>
        {isDragActive ? (
          <>Drop the files here ...</>
        ) : (
          <Center>
            <SymbolOutlineButton icon={<AddCircleOutlinedIcon />}>
              <StyledLabel margin="auto">{"ファイルを選択"}</StyledLabel>
            </SymbolOutlineButton>
          </Center>
        )}
      </VStack>
    </Center>
  );
};
