/**
 * @generated SignedSource<<98180434c4289600cebadbafebb8a2ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AbtestScenarioPageGroupReferrerReportOrderField = "UNIQUE_USER" | "CONVERSION_USER" | "CONVERSION_USER_RATE" | "%future added value";
export type OrderDirection = "ASC" | "DESC" | "%future added value";
export type AbtestScenarioPageGroupReferrerReportOrder = {
  direction: OrderDirection;
  field: AbtestScenarioPageGroupReferrerReportOrderField;
};
export type ABTestPatternReferrerReportTable_RefetchQuery$variables = {
  endDate?: string | null;
  order?: AbtestScenarioPageGroupReferrerReportOrder | null;
  startDate?: string | null;
  id: string;
};
export type ABTestPatternReferrerReportTable_RefetchQueryVariables = ABTestPatternReferrerReportTable_RefetchQuery$variables;
export type ABTestPatternReferrerReportTable_RefetchQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"ABTestPatternReferrerReportTable_abtestScenarioPageGroup">;
  } | null;
};
export type ABTestPatternReferrerReportTable_RefetchQueryResponse = ABTestPatternReferrerReportTable_RefetchQuery$data;
export type ABTestPatternReferrerReportTable_RefetchQuery = {
  variables: ABTestPatternReferrerReportTable_RefetchQueryVariables;
  response: ABTestPatternReferrerReportTable_RefetchQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v5 = [
  {
    "kind": "Variable",
    "name": "endDate",
    "variableName": "endDate"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  },
  {
    "kind": "Variable",
    "name": "startDate",
    "variableName": "startDate"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ABTestPatternReferrerReportTable_RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": (v5/*: any*/),
            "kind": "FragmentSpread",
            "name": "ABTestPatternReferrerReportTable_abtestScenarioPageGroup"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ABTestPatternReferrerReportTable_RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v5/*: any*/),
                "kind": "ScalarField",
                "name": "reportReferrer",
                "storageKey": null
              }
            ],
            "type": "AbtestScenarioPageGroup",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e2486f08e6e0abb4e5fb0bf0dfa38d39",
    "id": null,
    "metadata": {},
    "name": "ABTestPatternReferrerReportTable_RefetchQuery",
    "operationKind": "query",
    "text": "query ABTestPatternReferrerReportTable_RefetchQuery(\n  $endDate: ISO8601Date\n  $order: AbtestScenarioPageGroupReferrerReportOrder\n  $startDate: ISO8601Date\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...ABTestPatternReferrerReportTable_abtestScenarioPageGroup_1DyTq8\n    id\n  }\n}\n\nfragment ABTestPatternReferrerReportTable_abtestScenarioPageGroup_1DyTq8 on AbtestScenarioPageGroup {\n  reportReferrer(startDate: $startDate, endDate: $endDate, order: $order)\n  id\n}\n"
  }
};
})();

(node as any).hash = "b9c399d635f7ee0bc680663596870340";

export default node;
