import { FC, useEffect, useMemo } from "react";
import { graphql, usePaginationFragment } from "react-relay";

import { UserRole } from "~/src/__generated__/schema";
import {
  ABTestPatternTable_abtestScenarioPageGroup,
  ABTestPatternTable_abtestScenarioPageGroup$key,
} from "~/src/__relay_artifacts__/ABTestPatternTable_abtestScenarioPageGroup.graphql";
import {
  Table,
  TableHeaderColumn,
  TableHeaderRow,
} from "~/src/components/common/tables/Table";
import {
  TablePagination,
  useTablePaginationProps,
} from "~/src/components/common/tables/TablePagination";
import { useUpdateDebounce } from "~/src/lib/react-use";

import { FormValues } from "./ABTestPatternFilterButton";
import { ABTestPatternTableDataRow } from "./ABTestPatternTableDataRow";

type Props = {
  abtestScenarioPageGroupRef: ABTestPatternTable_abtestScenarioPageGroup$key;
  filterValues: FormValues;
  siteSlug: string;
  headerColumns: string[];
  dataColumns: string[];
  onAbtestsChange: (abtestScenarioPages: ABTestScenarioPage[]) => void;
  role: UserRole;
};

export type ABTestScenarioPage = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<
        ABTestPatternTable_abtestScenarioPageGroup["abtestScenarioPages"]
      >["edges"]
    >[0]
  >["node"]
>;

const fragment = graphql`
  fragment ABTestPatternTable_abtestScenarioPageGroup on AbtestScenarioPageGroup
  @refetchable(queryName: "ABTestPatternTable_Query")
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 120 }
    cursor: { type: "String" }
    withArchived: { type: "Boolean" }
    analyzerInput: { type: "ReportAnalyzerInput!" }
    status: { type: "DistributionStatus" }
  ) {
    slug
    abtestScenarioPages(
      first: $count
      after: $cursor
      withArchived: $withArchived
      orderBy: { field: KIND, direction: ASC }
      status: $status
    )
      @connection(
        key: "ABTestPatternTable_abtestScenarioPageGroup_abtestScenarioPages"
      ) {
      __id
      totalCount
      edges {
        node {
          id
          slug
          title
          weight
          ...ABTestPatternTableDataRow_abtestPattern
            @arguments(input: $analyzerInput)
        }
      }
    }
  }
`;

export const ABTestPatternTable: FC<Props> = ({
  abtestScenarioPageGroupRef,
  siteSlug,
  filterValues,
  headerColumns,
  dataColumns,
  onAbtestsChange,
  role,
}) => {
  const {
    data: abtestScenarioPageGroup,
    hasNext,
    loadNext,
    refetch,
  } = usePaginationFragment(fragment, abtestScenarioPageGroupRef);
  const { tablePaginationProps } = useTablePaginationProps({
    hasNext,
    loadNext,
    refetch,
    totalCount: abtestScenarioPageGroup.abtestScenarioPages?.totalCount || 0,
  });

  const abtestPatterns = useMemo(() => {
    const from = tablePaginationProps.from;
    const to = tablePaginationProps.to;
    const egdes = abtestScenarioPageGroup.abtestScenarioPages?.edges || [];
    return egdes.slice(from, to).map((edge) => {
      const node = edge?.node;
      if (!node) throw new Error("assertion failed");
      return node;
    });
  }, [
    abtestScenarioPageGroup.abtestScenarioPages?.edges,
    tablePaginationProps.from,
    tablePaginationProps.to,
  ]);

  useEffect(() => {
    onAbtestsChange(abtestPatterns);
  }, [abtestPatterns, onAbtestsChange]);

  useUpdateDebounce(
    () =>
      refetch({
        count: tablePaginationProps.perPage,
        ...filterValues,
      }),
    500,
    [filterValues, refetch, tablePaginationProps.perPage]
  );

  return (
    <>
      <Table>
        <TableHeaderRow>
          <TableHeaderColumn>パターン名</TableHeaderColumn>
          <TableHeaderColumn>実施ステータス</TableHeaderColumn>
          {role !== "SAAS_CLIENT" && (
            <TableHeaderColumn>承認状態</TableHeaderColumn>
          )}
          <TableHeaderColumn>配信比率</TableHeaderColumn>
          {headerColumns.map((headerColumn) => (
            <TableHeaderColumn key={headerColumn}>
              {headerColumn}
            </TableHeaderColumn>
          ))}
          <TableHeaderColumn>アクション</TableHeaderColumn>
        </TableHeaderRow>
        {abtestPatterns.map((abtestPattern) => {
          return (
            <ABTestPatternTableDataRow
              key={abtestPattern.slug}
              dataColumns={dataColumns}
              abtestPatternRef={abtestPattern}
              siteSlug={siteSlug}
              abtestSlug={abtestScenarioPageGroup.slug}
              filterValues={filterValues}
              connectionId={
                abtestScenarioPageGroup.abtestScenarioPages?.__id || ""
              }
              role={role}
            />
          );
        })}
      </Table>
      <TablePagination {...tablePaginationProps} />
    </>
  );
};
