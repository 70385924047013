import { VStack } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { FC, useCallback, useMemo, useState } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";
import * as yup from "yup";

import { GraphQLEnums } from "~/src/__generated__/GraphQLEnums";
import { PopupDefaultReportFilterButtonForm_Query } from "~/src/__relay_artifacts__/PopupDefaultReportFilterButtonForm_Query.graphql";
import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormikFormButtons } from "~/src/components/common/forms/FormikFormButtons";
import { FormikMultipleSelectField } from "~/src/components/common/forms/FormikMultipleSelectField";
import { FormikSelectField } from "~/src/components/common/forms/FormikSelectField";

import { PopupReportFilterFormValues as FormValues } from "./types";

export type Props = {
  filterValues: FormValues;
  onSubmit: (values: FormValues) => Promise<void> | void;
  onClose: () => void;
};

const query = graphql`
  query PopupDefaultReportFilterButtonForm_Query(
    $slug: String!
    $pageKind: PageKind
    $funnelIds: [ID!]
  ) {
    sources {
      edges {
        node {
          id
          name
        }
      }
    }
    site(slug: $slug) {
      funnels {
        edges {
          node {
            id
            name
          }
        }
      }
      popups(pageKind: $pageKind, funnelIds: $funnelIds) {
        edges {
          node {
            id
            name
            page {
              kind
              name
              sortNumber
              funnel {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

const validationSchema = yup.object({
  sourceId: yup.string().nullable(),
  device: yup.string().nullable(),
  funnelIds: yup.array().of(yup.string()).nullable(),
  pageKind: yup.string().nullable(),
  popupIds: yup.array().of(yup.string()).nullable(),
});

export const PopupDefaultReportFilterButtonForm: FC<Props> = ({
  filterValues,
  onClose,
  onSubmit,
}) => {
  const { siteSlug = "" } = useParams();
  const [funnelValues, setFunnelValues] = useState(filterValues.funnelIds);
  const [pageKindValue, setPageKindValue] = useState(filterValues.pageKind);
  const [popupValues, setPopupValues] = useState(filterValues.popupIds);

  const data = useLazyLoadQuery<PopupDefaultReportFilterButtonForm_Query>(
    query,
    {
      slug: siteSlug,
      funnelIds: funnelValues,
      pageKind: pageKindValue,
    }
  );

  const sourceOptions = useMemo(
    () => [
      { label: "すべて", value: null },
      ...(data.sources.edges || []).map((edge) => {
        const node = edge?.node;
        if (!node) throw new Error("assertion failed");
        return { label: node.name, value: node.id };
      }),
    ],
    [data.sources.edges]
  );

  const funnelOptions = useMemo(() => {
    return (
      data.site.funnels?.edges?.map((edge) => {
        const node = edge?.node;
        if (!node) throw new Error("assertion failed");
        return { label: node.name, value: node.id };
      }) || []
    );
  }, [data.site.funnels]);

  const pageKindOptions = useMemo(
    () => [{ label: "すべて", value: null }, ...GraphQLEnums.PageKind],
    []
  );

  const deviceOptions = useMemo(
    () => [{ label: "すべて", value: null }, ...GraphQLEnums.Device],
    []
  );

  const popupOptions = useMemo(() => {
    return [
      ...(data.site.popups.edges || []).map((edge) => {
        const node = edge?.node;
        if (!node) throw new Error("assertion failed");

        const pageName =
          node.page.kind === "FORM"
            ? `${node.page.name}(${(node.page.sortNumber - 1).toString()})`
            : node.page.name;
        return {
          label: `${node.name || ""}(${node.page.funnel.name})[${pageName}]`,
          value: node.id,
          funnelId: node.page.funnel.id,
          pageKind: node.page.kind,
        };
      }),
    ];
  }, [data.site.popups]);

  const handleSubmit = useCallback(
    (values: FormValues) => {
      onSubmit(values);
      onClose();
    },
    [onClose, onSubmit]
  );

  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={filterValues}
      validationSchema={validationSchema}
      validateOnMount={false}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      <Form>
        <VStack spacing="16px">
          <FormControl label="ファネル">
            <FormikMultipleSelectField
              name="funnelIds"
              options={funnelOptions}
              onSetStateAction={setFunnelValues}
              isDisabled={popupValues.length > 0}
            />
          </FormControl>
          <FormControl label="ページタイプ">
            <FormikSelectField
              name="pageKind"
              options={pageKindOptions}
              onSetStateAction={setPageKindValue}
              isDisabled={popupValues.length > 0}
            />
          </FormControl>
          <FormControl label="ポップアップ">
            <FormikMultipleSelectField
              name="popupIds"
              options={popupOptions}
              onSetStateAction={setPopupValues}
            />
          </FormControl>
          <FormControl label="流入元">
            <FormikSelectField name="sourceId" options={sourceOptions} />
          </FormControl>
          <FormControl label="デバイス">
            <FormikSelectField name="device" options={deviceOptions} />
          </FormControl>
        </VStack>
        <FormikFormButtons onCancelClick={onClose} />
      </Form>
    </Formik>
  );
};
