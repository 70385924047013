import { Box, Flex } from "@chakra-ui/layout";
import { useField } from "formik";
import { FC } from "react";

import { TextField, TextFieldProps } from "~/src/components/common/TextField";
import { chakraFactory } from "~/src/lib/chakra-ui";

export type Props = {
  mode?: TextFieldProps["mode"];
  name: TextFieldProps["name"];
  placeholder?: TextFieldProps["placeholder"];
  disabled?: boolean;
};

const ColorBox = chakraFactory(Box, {
  baseStyle: {
    width: "52px",
    height: "40px",
    borderWidth: "1px 0px 1px 1px",
    borderStyle: "solid",
    borderColor: "#DADADA",
  },
});

export const FormikColorField: FC<Props> = ({
  mode = "fill",
  name,
  placeholder = "#ffffff",
  disabled = false,
}) => {
  const [{ value, onBlur, onChange }, { error, touched }] =
    useField<string>(name);

  return (
    <Flex spacing="0px">
      <ColorBox bgColor={value} />
      <Box width="100%">
        <TextField
          type="text"
          mode={mode}
          name={name}
          value={value || "#"}
          placeholder={placeholder}
          error={touched ? error : undefined}
          disabled={disabled}
          onBlur={onBlur}
          onChange={onChange}
        />
      </Box>
    </Flex>
  );
};
