import { FormControl, FormLabel, Input, InputGroup } from "@chakra-ui/react";
import { AddCircleOutlined as AddCircleOutlinedIcon } from "@mui/icons-material";
import { useFormikContext } from "formik";
import { ChangeEvent, useCallback, useState } from "react";

import { SymbolOutlineButton } from "~/src/components/common/Button";
import { chakraFactory } from "~/src/lib/chakra-ui";

type Props = {
  name: string;
  src?: string;
};

const StyledInput = chakraFactory(Input, {
  baseStyle: {
    display: "none",
  },
});

const StyledLabel = chakraFactory(FormLabel, {
  baseStyle: {
    fontSize: "unset",
  },
});

const readFile = async (file: File | Blob): Promise<string | null> => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result as string));
    reader.readAsDataURL(file);
  });
};

export const ImageUploadField: React.FC<Props> = ({ src, name }) => {
  const [previewUrl, setPreviewUrl] = useState<string>();

  const { setFieldValue } = useFormikContext();

  const handleUpload = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      try {
        const file = e?.target?.files && e.target.files[0];
        if (!file) return;

        const image = await readFile(file);
        if (!image) throw new Error("assertion failed.");
        setPreviewUrl(image);

        setFieldValue(name, file);
      } catch (error) {
        console.error("error", error);
      }
    },
    [name, setFieldValue]
  );

  const imageSrc = !!previewUrl ? previewUrl : src;

  return (
    <>
      <FormControl>
        <InputGroup>
          <StyledInput type="file" accept="image/*" onChange={handleUpload} />
          <SymbolOutlineButton icon={<AddCircleOutlinedIcon />}>
            <StyledLabel margin="auto">画像アップロード</StyledLabel>
          </SymbolOutlineButton>
        </InputGroup>
      </FormControl>
      <img src={imageSrc} alt="" />
    </>
  );
};
