import { Box, HStack } from "@chakra-ui/react";
import { FC, useMemo, useState } from "react";
import { graphql, usePaginationFragment } from "react-relay";
import { NavLink } from "react-router-dom";

import { ExcludedIPListTab_site$key } from "~/src/__relay_artifacts__/ExcludedIPListTab_site.graphql";
import { SolidButton } from "~/src/components/common/Button";
import {
  Table,
  TableHeaderColumn,
  TableHeaderRow,
} from "~/src/components/common/tables/Table";
import {
  TablePagination,
  useTablePaginationProps,
} from "~/src/components/common/tables/TablePagination";
import { TableSearchField } from "~/src/components/common/tables/TableSearchField";

import {
  ExcludedIPTableDataRow,
  Props as ExcludedIPTableDataRowProps,
} from "./ExcludedIPTableDataRow";
import { StyledExcludeHeaderColumn } from "./styles";

export type Props = {
  siteRef: ExcludedIPListTab_site$key;
  onExcludedIPDeleteClick: ExcludedIPTableDataRowProps["onDeleteClick"];
  onExcludedIPExcludeChange: ExcludedIPTableDataRowProps["onExcludeChange"];
};

const siteFragment = graphql`
  fragment ExcludedIPListTab_site on Site
  @refetchable(queryName: "ExcludedIPListTab_Query")
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 120 }
    cursor: { type: "String" }
  ) {
    id
    slug
    excludedIpAddresses(first: $count, after: $cursor)
      @connection(key: "ExcludedIPListTab_excludedIpAddresses") {
      __id
      totalCount
      edges {
        node {
          slug
          ...ExcludedIPTableDataRow_excludedIpAddress
        }
      }
    }
  }
`;

export const ExcludedIPListTab: FC<Props> = ({
  siteRef,
  onExcludedIPDeleteClick,
  onExcludedIPExcludeChange,
}) => {
  const [searchText, setSearchText] = useState("");
  const {
    data: site,
    loadNext,
    hasNext,
    refetch,
  } = usePaginationFragment(siteFragment, siteRef);
  const { tablePaginationProps } = useTablePaginationProps({
    totalCount: site.excludedIpAddresses.totalCount,
    hasNext,
    loadNext,
    refetch,
  });

  const excludedIps = useMemo(() => {
    const from = tablePaginationProps.from;
    const to = tablePaginationProps.to;
    const edges = site.excludedIpAddresses.edges || [];
    return edges.slice(from, to).map((edge) => {
      const node = edge?.node;
      if (!node) throw new Error("assertion error");
      return node;
    });
  }, [
    site.excludedIpAddresses.edges,
    tablePaginationProps.from,
    tablePaginationProps.to,
  ]);

  return (
    <Box my="16px">
      <HStack justifyContent="flex-end" mb="16px" spacing="16px">
        <NavLink to={`/sites/${site.slug}/excluded_ips/new`}>
          <SolidButton>登録</SolidButton>
        </NavLink>
      </HStack>
      <TableSearchField searchText={searchText} onChange={setSearchText} />
      <Table>
        <TableHeaderRow>
          <TableHeaderColumn>IPアドレス</TableHeaderColumn>
          <TableHeaderColumn>登録日時</TableHeaderColumn>
          <TableHeaderColumn>最終更新日時</TableHeaderColumn>
          <StyledExcludeHeaderColumn>除外</StyledExcludeHeaderColumn>
          <TableHeaderColumn>アクション</TableHeaderColumn>
        </TableHeaderRow>
        {excludedIps.map((excludedIp) => (
          <ExcludedIPTableDataRow
            key={excludedIp.slug}
            excludedIpAddressRef={excludedIp}
            siteSlug={site.slug}
            connectionId={site.excludedIpAddresses.__id}
            onDeleteClick={onExcludedIPDeleteClick}
            onExcludeChange={onExcludedIPExcludeChange}
          />
        ))}
      </Table>
      <TablePagination {...tablePaginationProps} />
    </Box>
  );
};
