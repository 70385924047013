/**
 * @generated SignedSource<<9bb0d577a14238704213511db5f63ed6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChatbotListPage_site$data = {
  readonly slug: string;
  readonly chatbotGroups: {
    readonly __id: string;
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly slug: string;
        readonly " $fragmentSpreads": FragmentRefs<"ChatbotTableDataRow_chatbotGroup">;
      } | null;
    } | null> | null;
  };
  readonly id: string;
  readonly " $fragmentType": "ChatbotListPage_site";
};
export type ChatbotListPage_site = ChatbotListPage_site$data;
export type ChatbotListPage_site$key = {
  readonly " $data"?: ChatbotListPage_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChatbotListPage_site">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "chatbotGroups"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 120,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "device"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "funnelIds"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "pageIds"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "pageKind"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchTerm"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sourceId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "status"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "withArchived"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./ChatbotListPage_Query.graphql'),
      "identifierField": "id"
    }
  },
  "name": "ChatbotListPage_site",
  "selections": [
    (v1/*: any*/),
    {
      "alias": "chatbotGroups",
      "args": [
        {
          "kind": "Variable",
          "name": "device",
          "variableName": "device"
        },
        {
          "kind": "Variable",
          "name": "funnelIds",
          "variableName": "funnelIds"
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": {
            "direction": "DESC",
            "field": "END_ON"
          }
        },
        {
          "kind": "Variable",
          "name": "pageIds",
          "variableName": "pageIds"
        },
        {
          "kind": "Variable",
          "name": "pageKind",
          "variableName": "pageKind"
        },
        {
          "kind": "Variable",
          "name": "searchTerm",
          "variableName": "searchTerm"
        },
        {
          "kind": "Variable",
          "name": "sourceId",
          "variableName": "sourceId"
        },
        {
          "kind": "Variable",
          "name": "status",
          "variableName": "status"
        },
        {
          "kind": "Variable",
          "name": "withArchived",
          "variableName": "withArchived"
        }
      ],
      "concreteType": "ChatbotGroupConnection",
      "kind": "LinkedField",
      "name": "__ChatbotListPage_chatbotGroups_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ChatbotGroupEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ChatbotGroup",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                (v1/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ChatbotTableDataRow_chatbotGroup"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__id",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    },
    (v2/*: any*/)
  ],
  "type": "Site",
  "abstractKey": null
};
})();

(node as any).hash = "71e0f0df12c07d1ecc3c016509f052d9";

export default node;
