/**
 * @generated SignedSource<<b1cbfd56f20d7d75942279c12d2181f2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SourceEditScreenContainer_source$data = {
  readonly id: string;
  readonly name: string;
  readonly tag: string;
  readonly description: string;
  readonly " $fragmentType": "SourceEditScreenContainer_source";
};
export type SourceEditScreenContainer_source = SourceEditScreenContainer_source$data;
export type SourceEditScreenContainer_source$key = {
  readonly " $data"?: SourceEditScreenContainer_source$data;
  readonly " $fragmentSpreads": FragmentRefs<"SourceEditScreenContainer_source">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SourceEditScreenContainer_source",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tag",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "type": "Source",
  "abstractKey": null
};

(node as any).hash = "2c44669edd1baa5158d152e32fe5b9e5";

export default node;
