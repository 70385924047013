import { useCallback, useState } from "react";

export const useOpenState = (initialState = false) => {
  const [isOpen, setOpen] = useState(initialState);

  const onOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onToggle = useCallback((state: boolean) => {
    setOpen(state);
  }, []);

  return { isOpen, onOpen, onClose, onToggle };
};
