import { useToast } from "@chakra-ui/react";
import { FC, useCallback } from "react";
import { graphql } from "react-relay";

import { DistributionStatusSwitch_ActivateMutation as ActivateMutation } from "~/src/__relay_artifacts__/DistributionStatusSwitch_ActivateMutation.graphql";
import { DistributionStatusSwitch_DeactivateMutation as DeactivateMutation } from "~/src/__relay_artifacts__/DistributionStatusSwitch_DeactivateMutation.graphql";
import { Switch, SwitchProps } from "~/src/components/common/Switch";
import { useMutationCommit } from "~/src/lib/react-relay";

type Props = {
  id: string;
  name: string;
  isChecked: boolean;
} & Pick<SwitchProps, "isDisabled" | "isReadOnly">;

const activateMutation = graphql`
  mutation DistributionStatusSwitch_ActivateMutation(
    $input: ActivateDistributionStatusableInput!
  ) {
    updateStatus: activateDistributionStatusable(input: $input) {
      distributionStatusable {
        status
      }
    }
  }
`;

const deactivateMutation = graphql`
  mutation DistributionStatusSwitch_DeactivateMutation(
    $input: DeactivateDistributionStatusableInput!
  ) {
    updateStatus: deactivateDistributionStatusable(input: $input) {
      distributionStatusable {
        status
      }
    }
  }
`;

export const DistributionStatusSwitch: FC<Props> = ({
  id,
  name,
  isChecked,
  ...props
}) => {
  const toast = useToast();

  const activate = useMutationCommit<ActivateMutation>(activateMutation);
  const deactivate = useMutationCommit<DeactivateMutation>(deactivateMutation);

  const handleStatusChange = useCallback(async () => {
    try {
      const variables = { input: { distributionStatusableId: id } };
      const res = await (isChecked
        ? deactivate({ variables })
        : activate({ variables }));
      const statusable = res.updateStatus?.distributionStatusable;
      if (!statusable) throw new Error("assertion failed");

      toast({
        title: `${name}のステータスを更新しました`,
        status: "success",
      });
    } catch (err) {
      toast({
        title: `${name}のステータスの更新に失敗しました`,
        status: "error",
      });
    }
  }, [activate, deactivate, id, isChecked, name, toast]);

  return (
    <Switch isChecked={isChecked} onChange={handleStatusChange} {...props} />
  );
};
