import { Flex } from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import { usePapaParse } from "react-papaparse";
import { graphql, useRefetchableFragment } from "react-relay";

import { ABTestPatternReferrerReportTable_abtestScenarioPageGroup$key } from "~/src/__relay_artifacts__/ABTestPatternReferrerReportTable_abtestScenarioPageGroup.graphql";
import { ABTestPatternReferrerReportTable_RefetchQuery } from "~/src/__relay_artifacts__/ABTestPatternReferrerReportTable_RefetchQuery.graphql";
import {
  Table,
  TableDataColumn,
  TableDataRow,
  TableHeaderColumn,
  TableHeaderRow,
} from "~/src/components/common/tables/Table";
import {
  TablePagination,
  useTableLocalPaginationProps,
} from "~/src/components/common/tables/TablePagination";
// FIXME
import { CSVDownloadLink } from "~/src/components/features/report/CSVDownloadLink";

type Props = {
  abtestScenarioPageGroupRef: ABTestPatternReferrerReportTable_abtestScenarioPageGroup$key;
};

const fragment = graphql`
  fragment ABTestPatternReferrerReportTable_abtestScenarioPageGroup on AbtestScenarioPageGroup
  @refetchable(queryName: "ABTestPatternReferrerReportTable_RefetchQuery")
  @argumentDefinitions(
    startDate: { type: "ISO8601Date" }
    endDate: { type: "ISO8601Date" }
    order: { type: "AbtestScenarioPageGroupReferrerReportOrder" }
  ) {
    reportReferrer(startDate: $startDate, endDate: $endDate, order: $order)
  }
`;

export const ABTestPatternReferrerReportTable: FC<Props> = ({
  abtestScenarioPageGroupRef,
}) => {
  const [abtestScenarioPageGroup] = useRefetchableFragment<
    ABTestPatternReferrerReportTable_RefetchQuery,
    ABTestPatternReferrerReportTable_abtestScenarioPageGroup$key
  >(fragment, abtestScenarioPageGroupRef);

  const { readString } = usePapaParse();

  const [data, setData] = useState<{
    headers: string[];
    rows: string[][];
  }>({
    headers: [],
    rows: [],
  });

  const { tableLocalPaginationProps } = useTableLocalPaginationProps({
    totalCount: data.rows.length,
  });

  useEffect(() => {
    readString(abtestScenarioPageGroup.reportReferrer, {
      worker: true,
      complete: (results: any) => {
        const [headers, ...rest] = results.data;
        setData({ headers, rows: rest });
      },
    });
  }, [readString, abtestScenarioPageGroup]);

  return (
    <>
      <Flex justifyContent="flex-end">
        <CSVDownloadLink
          name={"ABテスト流入元別レポート"}
          data={abtestScenarioPageGroup.reportReferrer}
        />
      </Flex>

      <Table>
        <TableHeaderRow>
          {data.headers.map((header, i) => (
            <TableHeaderColumn key={i}>{header}</TableHeaderColumn>
          ))}
        </TableHeaderRow>
        {data.rows
          .slice(tableLocalPaginationProps.from, tableLocalPaginationProps.to)
          .map((row, i) => (
            <TableDataRow key={i}>
              {row.map((cell, i) => (
                <TableDataColumn
                  type={"text"}
                  data={cell.length > 65 ? cell.substring(0, 65) + "..." : cell}
                  key={i}
                />
              ))}
            </TableDataRow>
          ))}
      </Table>
      <TablePagination {...tableLocalPaginationProps} />
    </>
  );
};
