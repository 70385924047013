import {
  HStack,
  Select,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
} from "@chakra-ui/react";
import { includes } from "ramda";
import {
  ChangeEvent,
  ChangeEventHandler,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";

import { GraphQLEnums } from "~/src/__generated__/GraphQLEnums";
import { DistributionStatus, PageKind } from "~/src/__generated__/schema";
import { GhostButton, SolidButton } from "~/src/components/common/Button";
import { TableHeaderCheckboxColumn } from "~/src/components/common/tables/Table";
import { TableSearchField } from "~/src/components/common/tables/TableSearchField";

import {
  PopupGroup,
  PopupGroupLinkFormListItem,
} from "./PopupGroupLinkFormListItem";

export type Props = {
  onClose: () => void;
  onSubmit: (ids: string[]) => Promise<void>;
  popupGroups: PopupGroup[];
  setPageKind: Dispatch<SetStateAction<PageKind | undefined>>;
  searchText: string;
  setSearchText: Dispatch<SetStateAction<string>>;
  setDistributionStatus: Dispatch<
    SetStateAction<DistributionStatus | undefined>
  >;
};

export const PopupGroupLinkFormList: FC<Props> = ({
  onClose,
  onSubmit,
  popupGroups,
  setPageKind,
  searchText,
  setSearchText,
  setDistributionStatus,
}) => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const pageKindOptions = useMemo(() => [...GraphQLEnums.PageKind], []);

  const distributionStatusOptions = useMemo(
    () => [...GraphQLEnums.DistributionStatus],
    []
  );

  const popupGroupIds = useMemo(() => {
    return popupGroups.map((popupGroup) => popupGroup.id);
  }, [popupGroups]);

  const handleSelect = useCallback<ChangeEventHandler<HTMLSelectElement>>(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const value = e.target.value;
      const isPageKind = includes(
        value,
        GraphQLEnums.PageKind.map((v) => v.value)
      );
      setPageKind(isPageKind ? (value as PageKind) : undefined);
      setSelectedIds([]);
    },
    [setPageKind, setSelectedIds]
  );

  const handleAllCheck = useCallback(() => {
    const isAllIdsChecked = selectedIds.length === popupGroupIds.length;
    const newSelectedIds = isAllIdsChecked ? [] : popupGroupIds;
    setSelectedIds(newSelectedIds);
  }, [selectedIds, popupGroupIds, setSelectedIds]);

  const handleCheck = useCallback(
    (id: string) => {
      const newSelectedIds = includes(id, selectedIds)
        ? selectedIds.filter((i) => i !== id)
        : [id, ...selectedIds];
      setSelectedIds(newSelectedIds);
    },
    [selectedIds, setSelectedIds]
  );

  const handleClick = useCallback(() => {
    onSubmit(selectedIds);
  }, [onSubmit, selectedIds]);

  const handleSelectDistributionStatus = useCallback<
    ChangeEventHandler<HTMLSelectElement>
  >(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const value = e.target.value;
      const isDistributionStatus = includes(
        value,
        GraphQLEnums.DistributionStatus.map((v) => v.value)
      );
      setDistributionStatus(
        isDistributionStatus ? (value as DistributionStatus) : undefined
      );
    },
    [setDistributionStatus]
  );

  return (
    <>
      <HStack justifyContent={"flex-end"} mb={6}>
        <GhostButton onClick={onClose}>戻る</GhostButton>
        <SolidButton
          onClick={handleClick}
          isDisabled={selectedIds.length === 0}
        >
          保存
        </SolidButton>
      </HStack>
      <TableSearchField searchText={searchText} onChange={setSearchText} />
      <Select placeholder="ページタイプ" onChange={handleSelect}>
        {pageKindOptions.map((o, i) => (
          <option key={i} value={o.value}>
            {o.label}
          </option>
        ))}
      </Select>
      <Select
        placeholder="配信ステータス"
        mb={8}
        onChange={handleSelectDistributionStatus}
      >
        {distributionStatusOptions.map((o, i) => (
          <option key={i} value={o.value}>
            {o.label}
          </option>
        ))}
      </Select>
      <TableContainer>
        <Table size="sm">
          <Thead>
            <Th>
              <TableHeaderCheckboxColumn
                isChecked={selectedIds.length === popupGroupIds.length}
                onChange={handleAllCheck}
              />
            </Th>
            <Th>ポップアップ名</Th>
            <Th>ステータス</Th>
            <Th>画像</Th>
            <Th>パターン数</Th>
            <Th>実施ファネル</Th>
            <Th>実施ページ</Th>
          </Thead>
          <Tbody>
            {popupGroups.map((popupGroup) => (
              <PopupGroupLinkFormListItem
                key={popupGroup.id}
                popupGroup={popupGroup}
                onChange={handleCheck}
                isChecked={includes(popupGroup.id, selectedIds)}
              />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};
