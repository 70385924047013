import { Box } from "@chakra-ui/react";

import { chakraFactory } from "~/src/lib/chakra-ui";

import { rowStyles } from "../styles";

export const TableDataRow = chakraFactory(Box, {
  baseStyle: {
    ...rowStyles,

    "&:hover": {
      backgroundColor: "#FAFAFA",
    },
  },
});
