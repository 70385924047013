import { Box, HStack, useToast } from "@chakra-ui/react";
import { FC, useCallback, useMemo, useState } from "react";
import { graphql, usePaginationFragment } from "react-relay/hooks";
import { NavLink } from "react-router-dom";

import { SourceListTab_Mutation } from "~/src/__relay_artifacts__/SourceListTab_Mutation.graphql";
import { SourceListTab_root$key } from "~/src/__relay_artifacts__/SourceListTab_root.graphql";
import { SolidButton } from "~/src/components/common/Button";
import {
  Table,
  TableHeaderColumn,
  TableHeaderRow,
} from "~/src/components/common/tables/Table";
import {
  TablePagination,
  useTablePaginationProps,
} from "~/src/components/common/tables/TablePagination";
import { TableSearchField } from "~/src/components/common/tables/TableSearchField";
import { useMutationCommit } from "~/src/lib/react-relay";

import { SourceTableDataRow } from "./SourceTableDataRow";
import { StyledDescriptionHeaderColumn } from "./styles";

export type Props = {
  siteSlug: string;
  rootRef: SourceListTab_root$key;
};

const rootFragment = graphql`
  fragment SourceListTab_root on Query
  @refetchable(queryName: "SourceListTab_Query")
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 120 }
    cursor: { type: "String" }
  ) {
    sources(first: $count, after: $cursor)
      @connection(key: "SourceListTab_sources") {
      totalCount
      __id
      edges {
        node {
          slug
          ...SourceTableDataRow_source
        }
      }
    }
  }
`;

const mutation = graphql`
  mutation SourceListTab_Mutation(
    $input: DeleteSourceInput!
    $connections: [ID!]!
  ) {
    deleteSource(input: $input) {
      deletedSourceId @deleteEdge(connections: $connections)
    }
  }
`;

export const SourceListTab: FC<Props> = ({ siteSlug, rootRef }) => {
  const toast = useToast();
  const [searchText, setSearchText] = useState("");
  const {
    data: root,
    loadNext,
    hasNext,
    refetch,
  } = usePaginationFragment(rootFragment, rootRef);
  const { tablePaginationProps } = useTablePaginationProps({
    totalCount: root.sources.totalCount,
    hasNext,
    loadNext,
    refetch,
  });

  const sources = useMemo(() => {
    const from = tablePaginationProps.from;
    const to = tablePaginationProps.to;
    const edges = root.sources?.edges || [];
    return edges.slice(from, to).map((edge) => {
      const node = edge?.node;
      if (!node) throw new Error("assertion failed");
      return node;
    });
  }, [root.sources?.edges, tablePaginationProps.from, tablePaginationProps.to]);

  const mutate = useMutationCommit<SourceListTab_Mutation>(mutation);

  const handleSourceDeleteClick = useCallback(
    async (sourceId: string) => {
      try {
        const res = await mutate({
          variables: {
            input: {
              sourceId,
            },
            connections: [root.sources.__id],
          },
        });
        const deletedSourceId = res.deleteSource?.deletedSourceId;
        if (!deletedSourceId) {
          throw new Error("assertion failed");
        }
        toast({ title: "流入元を削除しました", status: "success" });
      } catch (err) {
        toast({ title: "流入元の削除に失敗しました", status: "error" });
      }
    },
    [root.sources.__id, mutate, toast]
  );

  return (
    <Box my={"16px"}>
      <HStack justifyContent="flex-end" mb="16px" spacing="16px">
        <NavLink to={`/sites/${siteSlug}/sources/new`}>
          <SolidButton>登録</SolidButton>
        </NavLink>
      </HStack>
      <TableSearchField searchText={searchText} onChange={setSearchText} />
      <Table>
        <TableHeaderRow>
          <TableHeaderColumn>流入元</TableHeaderColumn>
          <TableHeaderColumn>タグ</TableHeaderColumn>
          <StyledDescriptionHeaderColumn>説明</StyledDescriptionHeaderColumn>
          <TableHeaderColumn>アクション</TableHeaderColumn>
        </TableHeaderRow>
        {sources.map((source) => (
          <SourceTableDataRow
            key={source.slug}
            siteSlug={siteSlug}
            sourceRef={source}
            onDeleteClick={handleSourceDeleteClick}
          />
        ))}
      </Table>
      <TablePagination {...tablePaginationProps} />
    </Box>
  );
};
