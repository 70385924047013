import { Box, Divider } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/toast";
import { FC, useCallback } from "react";
import { graphql, useFragment } from "react-relay";
import { useNavigate } from "react-router";

import { ScriptTagSettingTabContainer_Mutation } from "~/src/__relay_artifacts__/ScriptTagSettingTabContainer_Mutation.graphql";
import { ScriptTagSettingTabContainer_site$key } from "~/src/__relay_artifacts__/ScriptTagSettingTabContainer_site.graphql";
import { useMutationCommit } from "~/src/lib/react-relay";

import {
  RobeeTagCard,
  ScriptTagSettingForm,
  ScriptTagSettingFormProps,
} from "./presentations";

export type Props = {
  siteRef: ScriptTagSettingTabContainer_site$key;
};

const fragment = graphql`
  fragment ScriptTagSettingTabContainer_site on Site {
    id
    embedTag
    robeeTag
  }
`;

const mutation = graphql`
  mutation ScriptTagSettingTabContainer_Mutation(
    $siteId: ID!
    $userTag: String
  ) {
    upsertUserTag(input: { siteId: $siteId, userTag: $userTag }) {
      site {
        id
        embedTag
      }
    }
  }
`;

export const ScriptTagSettingTabContainer: FC<Props> = ({ siteRef }) => {
  const site = useFragment(fragment, siteRef);
  const navigate = useNavigate();
  const toast = useToast();

  const handleCancel = useCallback(() => navigate(-1), [navigate]);

  const mutate =
    useMutationCommit<ScriptTagSettingTabContainer_Mutation>(mutation);

  const handleSubmit = useCallback<ScriptTagSettingFormProps["onSubmit"]>(
    async ({ userTag }) => {
      try {
        const res = await mutate({
          variables: {
            siteId: site.id,
            userTag,
          },
        });
        const upsertedSited = res.upsertUserTag?.site;
        if (!upsertedSited) throw new Error("assertion failed");

        toast({
          title: `javascriptタグを設定しました`,
          status: "success",
        });
      } catch (err) {
        toast({ title: "javascriptタグの設定に失敗しました", status: "error" });
      }
    },
    [mutate, site.id, toast]
  );

  return (
    <Box>
      <RobeeTagCard snippet={site.robeeTag} />
      <Divider mb={16} mt={16} />
      <ScriptTagSettingForm
        initialValues={{
          userTag: site.embedTag,
        }}
        onSubmit={handleSubmit}
        onCancelClick={handleCancel}
      />
    </Box>
  );
};
