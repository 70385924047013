/**
 * @generated SignedSource<<15f14c15df27c9eaa810df67ef8453ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PublisherDetailReportTable_site$data = {
  readonly reportSource: any;
  readonly " $fragmentType": "PublisherDetailReportTable_site";
};
export type PublisherDetailReportTable_site = PublisherDetailReportTable_site$data;
export type PublisherDetailReportTable_site$key = {
  readonly " $data"?: PublisherDetailReportTable_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"PublisherDetailReportTable_site">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "input"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PublisherDetailReportTable_site",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "input",
          "variableName": "input"
        }
      ],
      "kind": "ScalarField",
      "name": "reportSource",
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "4b87b96d7b4a5bbdaaf7e414933c0a4d";

export default node;
