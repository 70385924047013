import { useField } from "formik";
import { Dispatch, FC, SetStateAction, useCallback, useMemo } from "react";

import { Select, SelectProps } from "~/src/components/common/Select";

export type Props = {
  name: string;
  onSetStateAction?: Dispatch<SetStateAction<any>>;
} & SelectProps;

export const FormikSelectField: FC<Props> = ({ name, options, ...props }) => {
  const [{ value, onBlur }, { error, touched }, { setValue }] =
    useField<string>(name);

  const { onSetStateAction } = props;

  const calculatedValue = useMemo(() => {
    if (!options) return undefined;
    return options.find((option) => option.value === value);
  }, [options, value]);

  const handleChange = useCallback(
    (option: any) => {
      if (onSetStateAction) {
        onSetStateAction(option?.value);
      }
      setValue(option?.value);
    },
    [setValue, onSetStateAction]
  );

  return (
    <Select
      {...props}
      name={name}
      options={options}
      value={calculatedValue}
      error={touched ? error : undefined}
      onBlur={onBlur}
      onChange={handleChange}
    />
  );
};
