import { Image } from "@chakra-ui/react";
import { FC, Fragment, useCallback, useMemo } from "react";
import { graphql, useFragment } from "react-relay";
import { NavLink } from "react-router-dom";

import { UserRole } from "~/src/__generated__/schema";
import { PopupTableDataRow_popupGroup$key } from "~/src/__relay_artifacts__/PopupTableDataRow_popupGroup.graphql";
import {
  TableDataCheckboxColumn,
  TableDataColumn,
  TableDataRow,
} from "~/src/components/common/tables/Table";
import { URLItem } from "~/src/components/common/URLItem";
import { DistributionStatusSwitch } from "~/src/components/features/global/DistributionStatusSwitch";

import { PopupTableActions } from "./PopupTableActions";
import { ROW_HEIGHT } from "./styles";

export type Props = {
  role: UserRole;
  popupRef: PopupTableDataRow_popupGroup$key;
  siteSlug: string;
  dataColumns: string[];
  isChecked: boolean;
  connectionId: string;
  onCheck: (popupSlug: string, isChecked: boolean) => void;
};

const popupGroupFragment = graphql`
  fragment PopupTableDataRow_popupGroup on PopupGroup {
    endDate
    id
    memo
    name
    page {
      id
      slug
      name
      kind
      sortNumber
      url
      funnel {
        id
        name
        slug
        siteUrl
      }
    }
    isArchive
    popups(kind: B_PATTERN) {
      totalCount
    }
    slug
    status
    startDate
    thumbnailImageUrl
    updatedAt
    judgeStatus
  }
`;

export const PopupTableDataRow: FC<Props> = ({
  siteSlug,
  popupRef,
  dataColumns,
  isChecked,
  connectionId,
  onCheck,
  role,
}) => {
  const popupGroup = useFragment(popupGroupFragment, popupRef);

  const handleCheck = useCallback(
    () => onCheck(popupGroup.slug, isChecked),
    [popupGroup.slug, onCheck, isChecked]
  );

  const popupGroupMemo = useMemo(
    () => popupGroup.memo || "",
    [popupGroup.memo]
  );

  return (
    <TableDataRow height={ROW_HEIGHT}>
      <TableDataCheckboxColumn isChecked={isChecked} onChange={handleCheck} />
      <TableDataColumn
        type="text"
        isLink
        data={
          <NavLink to={`/sites/${siteSlug}/popup_groups/${popupGroup.slug}`}>
            {popupGroup.name}
          </NavLink>
        }
      />
      <TableDataColumn
        type="actions"
        data={
          <DistributionStatusSwitch
            id={popupGroup.id}
            name={popupGroup.name}
            isChecked={popupGroup.status === "ACTIVE"}
          />
        }
      />
      {role !== "SAAS_CLIENT" && (
        <TableDataColumn
          type="text"
          data={popupGroup.judgeStatus === "APPROVED" ? "承認" : "未承認"}
        />
      )}
      <TableDataColumn
        type="text"
        data={
          popupGroup.thumbnailImageUrl && (
            <Image
              src={popupGroup.thumbnailImageUrl}
              boxSize={"60px"}
              fit={"contain"}
            />
          )
        }
      />
      {dataColumns.map((dataColumn) => (
        <Fragment key={dataColumn}>
          {dataColumn === "funnelTitle" && (
            <TableDataColumn
              type="text"
              data={
                <URLItem
                  url={popupGroup.page.funnel.siteUrl}
                  name={popupGroup.page.funnel.name}
                />
              }
            />
          )}
          {dataColumn === "funnelPage" && (
            <TableDataColumn
              type="text"
              data={
                <URLItem
                  url={popupGroup.page.url}
                  name={
                    popupGroup.page.kind !== "FORM"
                      ? popupGroup.page.name
                      : popupGroup.page.name +
                        "(" +
                        (popupGroup.page.sortNumber - 1) +
                        ")"
                  }
                />
              }
            />
          )}
          {dataColumn === "patternCount" && (
            <TableDataColumn
              type="numeric"
              data={popupGroup.popups?.totalCount || 0}
            />
          )}
          {dataColumn === "periodSince" && (
            <TableDataColumn type="text" data={popupGroup.startDate} />
          )}
          {dataColumn === "periodTill" && (
            <TableDataColumn type="text" data={popupGroup.endDate} />
          )}
          {dataColumn === "memo" && (
            <TableDataColumn
              type="text"
              data={
                popupGroupMemo.length > 30
                  ? popupGroupMemo.substring(0, 30) + "..."
                  : popupGroupMemo
              }
            />
          )}
          {dataColumn === "updatedAt" && (
            <TableDataColumn type="date" data={popupGroup.updatedAt} />
          )}
        </Fragment>
      ))}
      <TableDataColumn
        type="actions"
        data={
          <PopupTableActions
            popupGroup={popupGroup}
            connectionId={connectionId}
            filtered={popupGroup.isArchive}
            currentValues={{
              funnelId: popupGroup.page.funnel.id,
              pageKind: popupGroup.page.kind,
              pageId: popupGroup.page.id,
            }}
          />
        }
      />
    </TableDataRow>
  );
};
