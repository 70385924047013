import { Box, HStack } from "@chakra-ui/react";
import { DeleteOutline as DeleteOutlineIcon } from "@mui/icons-material";
import * as React from "react";

import { GraphQLEnums } from "~/src/__generated__/GraphQLEnums";
import { FormikCheckBoxesField } from "~/src/components/common/forms/FormikCheckBoxesField";
import { FormikSelectField } from "~/src/components/common/forms/FormikSelectField";
import { IconButton } from "~/src/components/common/IconButton";

type Props = {
  fieldName: {
    weekOfMonth: string;
    daysOfWeek: string;
  };
  disabled?: boolean;
  onDelete?: () => void;
};

const weekOptions = [
  { label: "毎週", value: null },
  { label: "第1週", value: 1 },
  { label: "第2週", value: 2 },
  { label: "第3週", value: 3 },
  { label: "第4週", value: 4 },
  { label: "第5週", value: 5 },
];
const daysOptions = GraphQLEnums.DayOfWeek.slice();

export const DeliverDayOfWeeksField: React.FC<Props> = ({
  fieldName,
  disabled = false,
  onDelete,
}) => {
  return (
    <HStack width="100%">
      <Box w={"100%"} maxW={"200px"} minW={"100px"}>
        <FormikSelectField
          name={fieldName.weekOfMonth}
          mode="fill"
          options={weekOptions}
          defaultValue={weekOptions[0]}
          isDisabled={disabled}
        />
      </Box>
      <FormikCheckBoxesField
        name={fieldName.daysOfWeek}
        options={daysOptions}
        disabled={disabled}
      />
      {onDelete && (
        <Box>
          <IconButton
            size="24px"
            onClick={onDelete}
            icon={<DeleteOutlineIcon />}
            disabled={disabled}
          />
        </Box>
      )}
    </HStack>
  );
};
