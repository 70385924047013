import {
  DeleteOutline as DeleteOutlineIcon,
  EditOutlined as EditOutlinedIcon,
} from "@mui/icons-material";
import { FC, useCallback, useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import {
  ExcludedIPTableDataRow_excludedIpAddress,
  ExcludedIPTableDataRow_excludedIpAddress$key,
} from "~/src/__relay_artifacts__/ExcludedIPTableDataRow_excludedIpAddress.graphql";
import { Switch } from "~/src/components/common/Switch";
import {
  TableDataColumn,
  TableDataRow,
} from "~/src/components/common/tables/Table";
import { TableActions } from "~/src/components/common/tables/TableActions";

import { StyledExcludeDataColumn } from "./styles";

export type Props = {
  excludedIpAddressRef: ExcludedIPTableDataRow_excludedIpAddress$key;
  siteSlug: string;
  connectionId: string;
  onDeleteClick: (excludedIpId: string, connectionId: string) => void;
  onExcludeChange: (_: ExcludedIPTableDataRow_excludedIpAddress) => void;
};

const excludedIpAddressFragment = graphql`
  fragment ExcludedIPTableDataRow_excludedIpAddress on ExcludedIpAddress {
    id
    ipAddress
    status
    slug
    createdAt
    updatedAt
  }
`;

export const ExcludedIPTableDataRow: FC<Props> = ({
  excludedIpAddressRef,
  siteSlug,
  connectionId,
  onDeleteClick,
  onExcludeChange,
}) => {
  const excludedIpAddress = useFragment(
    excludedIpAddressFragment,
    excludedIpAddressRef
  );

  const actionMenus = useMemo(
    () => [
      {
        label: "編集",
        icon: <EditOutlinedIcon />,
        to: `/sites/${siteSlug}/excluded_ips/${excludedIpAddress.slug}/edit`,
      },
      {
        label: "削除",
        icon: <DeleteOutlineIcon />,
        onClick: () => onDeleteClick(excludedIpAddress.id, connectionId),
      },
    ],
    [
      siteSlug,
      excludedIpAddress.slug,
      excludedIpAddress.id,
      onDeleteClick,
      connectionId,
    ]
  );

  const handleExcludeChange = useCallback(
    () => onExcludeChange(excludedIpAddress),
    [excludedIpAddress, onExcludeChange]
  );

  return (
    <TableDataRow>
      <TableDataColumn type="text" data={excludedIpAddress.ipAddress} />
      <TableDataColumn type="date" data={excludedIpAddress.createdAt} />
      <TableDataColumn type="date" data={excludedIpAddress.updatedAt} />
      <StyledExcludeDataColumn
        type="actions"
        data={
          <Switch
            name="isExcluded"
            isChecked={excludedIpAddress.status === "ACTIVE"}
            onChange={handleExcludeChange}
          />
        }
      />
      <TableDataColumn
        type="actions"
        data={<TableActions actionMenus={actionMenus} />}
      />
    </TableDataRow>
  );
};
