import { Box, HStack } from "@chakra-ui/react";
import { FC, useMemo } from "react";
import { graphql, usePaginationFragment } from "react-relay";
import { NavLink } from "react-router-dom";

import { PopupGroupSetListPage_site$key } from "~/src/__relay_artifacts__/PopupGroupSetListPage_site.graphql";
import { SolidButton } from "~/src/components/common/Button";
import { Modal } from "~/src/components/common/Modal";
import {
  Table,
  TableDataColumn,
  TableDataRow,
  TableHeaderColumn,
  TableHeaderRow,
} from "~/src/components/common/tables/Table";
import {
  TablePagination,
  useTablePaginationProps,
} from "~/src/components/common/tables/TablePagination";
import { PageLayout } from "~/src/components/features/global/PageLayout";
import { PopupGroupSetCreateFormContainer } from "~/src/components/features/popupSet";
import { useOpenState } from "~/src/lib/hooks";

import { PopupGroupSetTableActions } from "./PopupGroupSetTableActions";

export type Props = {
  siteRef: PopupGroupSetListPage_site$key;
};

const fragment = graphql`
  fragment PopupGroupSetListPage_site on Site
  @refetchable(queryName: "PopupGroupSetListPageRefetchQuery")
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 120 }
    cursor: { type: "String" }
  ) {
    id
    slug
    popupGroupSets(first: $count, after: $cursor)
      @connection(key: "PopupGroupSetListPage_popupGroupSets") {
      __id
      totalCount
      edges {
        node {
          id
          name
          memo
        }
      }
    }
  }
`;

export const PopupGroupSetList: FC<Props> = ({ siteRef }) => {
  const { isOpen, onClose, onOpen } = useOpenState();

  const {
    data: site,
    loadNext,
    hasNext,
    refetch,
  } = usePaginationFragment(fragment, siteRef);
  const { tablePaginationProps } = useTablePaginationProps({
    totalCount: site.popupGroupSets.totalCount,
    hasNext,
    loadNext,
    refetch,
  });
  const popupGroupSets = useMemo(() => {
    const from = tablePaginationProps.from;
    const to = tablePaginationProps.to;
    const egdes = site.popupGroupSets.edges || [];
    return egdes.slice(from, to).map((edge) => {
      const node = edge?.node;
      if (!node) throw new Error("assertion failed");
      return node;
    });
  }, [
    site.popupGroupSets.edges,
    tablePaginationProps.from,
    tablePaginationProps.to,
  ]);

  return (
    <>
      <PageLayout title="ポップアップセット">
        <Box my="16px">
          <HStack justifyContent="flex-end" mb="16px" spacing="16px">
            <SolidButton onClick={onOpen}>作成</SolidButton>
          </HStack>
          <Table>
            <TableHeaderRow>
              <TableHeaderColumn>ポップアップセット名</TableHeaderColumn>
              <TableHeaderColumn>メモ</TableHeaderColumn>
              <TableHeaderColumn>アクション</TableHeaderColumn>
            </TableHeaderRow>
            {popupGroupSets.map((popupGroupSet, index) => (
              <TableDataRow height={"80px"} key={index}>
                <TableDataColumn
                  type="text"
                  isLink
                  data={
                    <NavLink
                      to={`/sites/${site.slug}/popup_group_sets/${popupGroupSet.id}`}
                    >
                      {popupGroupSet.name}
                    </NavLink>
                  }
                />
                <TableDataColumn
                  type="text"
                  data={
                    popupGroupSet.memo && popupGroupSet.memo.length > 30
                      ? popupGroupSet.memo.substring(0, 30) + "..."
                      : popupGroupSet.memo
                  }
                />

                <TableDataColumn
                  type="actions"
                  data={
                    <PopupGroupSetTableActions
                      popupGroupSet={popupGroupSet}
                      connectionId={site.popupGroupSets.__id}
                    />
                  }
                />
              </TableDataRow>
            ))}
          </Table>
          <TablePagination {...tablePaginationProps} />
        </Box>
      </PageLayout>
      <Modal title="ポップアップセットの作成" isOpen={isOpen} onClose={onClose}>
        <PopupGroupSetCreateFormContainer onClose={onClose} siteId={site.id} />
      </Modal>
    </>
  );
};
