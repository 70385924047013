import { FC } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";

import { FunnelSourceCreatePageContainer_Query } from "~/src/__relay_artifacts__/FunnelSourceCreatePageContainer_Query.graphql";
import { FunnelSourceCreateFormContainer } from "~/src/components/features/settings/FunnelSourceForms";
import { useFunnelSourceCreatePageParams } from "~/src/routes/hooks";

import { FunnelSourceCreatePage } from "./presentations";

const query = graphql`
  query FunnelSourceCreatePageContainer_Query($funnelSlug: String!) {
    funnel(slug: $funnelSlug) {
      ...FunnelSourceCreateFormContainer_funnel
    }
    ...FunnelSourceCreateFormContainer_root
  }
`;

export const FunnelSourceCreatePageContainer: FC = () => {
  const { siteSlug, funnelSlug } = useFunnelSourceCreatePageParams();
  const queryRef = useLazyLoadQuery<FunnelSourceCreatePageContainer_Query>(
    query,
    {
      funnelSlug,
    }
  );

  return (
    <FunnelSourceCreatePage siteSlug={siteSlug} funnelSlug={funnelSlug}>
      <FunnelSourceCreateFormContainer
        siteSlug={siteSlug}
        funnelSlug={funnelSlug}
        funnelRef={queryRef.funnel}
        sourceRef={queryRef}
      />
    </FunnelSourceCreatePage>
  );
};
