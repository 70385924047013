import {
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckboxProps,
} from "@chakra-ui/react";
import { Check as CheckIcon } from "@mui/icons-material";
import { FC } from "react";

import { chakraFactory } from "~/src/lib/chakra-ui";

export type Props = {
  iconType?: "check" | "hyphen";
} & ChakraCheckboxProps;

const StyledCheckbox = chakraFactory(ChakraCheckbox, {
  baseStyle: {
    borderColor: "#DADADA",

    svg: {
      width: "12px",
      height: "12px",
    },

    _hover: {
      cursor: "pointer",
    },
  },
});

const StyledCheckIcon = chakraFactory(CheckIcon, {});

const StyledVerticalLine = chakraFactory("div", {
  baseStyle: {
    width: "8px",
    height: "1px",
    bg: "white",
  },
});

export const Checkbox: FC<Props> = ({
  children,
  iconType = "check",
  ...props
}) => {
  return (
    <StyledCheckbox
      icon={iconType === "check" ? <StyledCheckIcon /> : <StyledVerticalLine />}
      {...props}
    >
      {children}
    </StyledCheckbox>
  );
};
