import {
  HasForm,
  PageAttributes,
  QueryMatch,
  UrlMatch,
} from "~/src/__generated__/schema";

export const stringConvertToUrlMatchType = (type: string): UrlMatch => {
  switch (type) {
    case "EQUAL_TYPE":
      return "EQUAL_TYPE";
    case "HAS_ID_TYPE":
      return "HAS_ID_TYPE";
    case "EQUAL_OR_HAS_ID_TYPE":
      return "EQUAL_OR_HAS_ID_TYPE";
    case "STARTS_WITH_PREFIX_MATCH":
      return "STARTS_WITH_PREFIX_MATCH";
    case "REGEX_MATCH_TYPE":
      return "REGEX_MATCH_TYPE";
    default:
      return "%future added value";
  }
};

export const toQueryMatchType = (
  type: string | undefined | null
): QueryMatch => {
  switch (type) {
    case "INCLUDING_MATCH":
      return "INCLUDING_MATCH";
    case "PREFIX_MATCH":
      return "PREFIX_MATCH";
    default:
      return "EQUAL_MATCH";
  }
};

export const fromQueryMatchType = (
  type: QueryMatch | undefined | null
): string => {
  switch (type) {
    case "INCLUDING_MATCH":
      return "INCLUDING_MATCH";
    case "PREFIX_MATCH":
      return "PREFIX_MATCH";
    default:
      return "EQUAL_MATCH";
  }
};

export const stringToHasForm = (hasForm: string): HasForm => {
  switch (hasForm) {
    case "HAS_FORM":
      return "HAS_FORM";
    default:
      return "NO_FORM";
  }
};

export const urlMatchConvertToString = (type: UrlMatch | undefined): string => {
  switch (type) {
    case "EQUAL_TYPE":
      return "EQUAL_TYPE";
    case "HAS_ID_TYPE":
      return "HAS_ID_TYPE";
    case "EQUAL_OR_HAS_ID_TYPE":
      return "EQUAL_OR_HAS_ID_TYPE";
    case "STARTS_WITH_PREFIX_MATCH":
      return "STARTS_WITH_PREFIX_MATCH";
    case "REGEX_MATCH_TYPE":
      return "REGEX_MATCH_TYPE";
    default:
      return "%future added value";
  }
};
type ResponseForms = {
  readonly id: string;
  readonly name: string;
  readonly url: string;
  readonly mobileUrl: string | null;
  readonly tabletUrl: string | null;
  readonly urlMatchType: UrlMatch;
  readonly isDisableWhiteout: boolean;
};

export const formConvertToPageAttributes = (
  values:
    | {
        formUrlForPC: string | undefined;
        formUrlForMobile: string | null | undefined;
        formUrlForTablet: string | null | undefined;
        formUrlMatchType: string;
        formIsDisableWhiteout: boolean;
      }[]
    | null
    | undefined,
  res?: readonly ResponseForms[] | null
): PageAttributes[] => {
  if (!values) {
    return [];
  }

  const attrs: PageAttributes[] = [];
  // 全カラムで初期値が入っているため必ずデータが存在するが
  // 任意項目であるのに、PageAttributesを流用する都合上「!」を利用
  values.forEach((value, i) => {
    if (res && res[i]) {
      const attr = {
        id: res[i].id,
        name: res[i].name,
        url: value.formUrlForPC || "",
        mobileUrl: value.formUrlForMobile,
        tabletUrl: value.formUrlForTablet,
        urlMatchType: stringConvertToUrlMatchType(value.formUrlMatchType),
        isDisableWhiteout: value.formIsDisableWhiteout,
      };
      attrs.push(attr);
    } else {
      const attr = {
        name: "入力フォームページ",
        url: value.formUrlForPC || "",
        mobileUrl: value.formUrlForMobile,
        tabletUrl: value.formUrlForTablet,
        urlMatchType: stringConvertToUrlMatchType(value.formUrlMatchType),
        isDisableWhiteout: value.formIsDisableWhiteout,
      };
      if (attr.url !== "") {
        attrs.push(attr);
      }
    }
  });

  return attrs;
};

type UpdateForm = {
  formUrlForPC: string;
  formUrlForMobile: string | null;
  formUrlForTablet: string | null;
  formUrlMatchType: string;
  formIsDisableWhiteout: boolean;
};

export const responseConvertToForm = (
  values: readonly ResponseForms[] | null | undefined
): UpdateForm[] => {
  if (!values) {
    return [];
  }

  const attrs: UpdateForm[] = [];

  values.forEach((value) => {
    attrs.push({
      formUrlForPC: value.url,
      formUrlForMobile: value.mobileUrl,
      formUrlForTablet: value.tabletUrl,
      formUrlMatchType: urlMatchConvertToString(value.urlMatchType),
      formIsDisableWhiteout: value.isDisableWhiteout,
    });
  });

  return attrs;
};

export const hasFormConvertToBoolean = (
  type: HasForm | null | undefined
): boolean => {
  switch (type) {
    case "HAS_FORM":
      return true;
    case "NO_FORM":
      return false;
    default:
      return false;
  }
};
