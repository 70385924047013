import { useToast } from "@chakra-ui/react";
import { FC, Suspense, useCallback } from "react";

import { Modal } from "~/src/components/common/Modal";
import { PageLoading } from "~/src/components/common/PageLoading";
import { Select } from "~/src/components/common/Select";
import { chakraFactory } from "~/src/lib/chakra-ui";
import { useOpenState } from "~/src/lib/hooks";

import {
  FormValues,
  TransferSourceTagsModalContainer,
} from "./TransferSourceTagsModalContainer";

const StyledSelect = chakraFactory(Select, {
  baseStyle: {
    width: "350px",
    height: "40px",
    paddingRight: "14px",
  },
});

export type Props = {
  sourceTagIds: string[];
  currentValues: FormValues;
};

const operations = [
  {
    label: "指定したファネルへの入稿用リンクの一括コピー",
    value: "transferSourceTags",
  },
] as const;

export const TransferSourceTagsSelect: FC<Props> = ({
  sourceTagIds,
  currentValues,
}) => {
  const toast = useToast();
  const { isOpen, onClose, onOpen } = useOpenState();

  const handleChange = useCallback(
    (option: typeof operations[number]) => {
      if (sourceTagIds.length < 1) {
        toast({
          title: "1つ以上の入稿用リンクを選択してください",
          status: "info",
        });
        return;
      }

      if (option.value === "transferSourceTags") {
        onOpen();
      }
    },
    [onOpen, sourceTagIds, toast]
  );

  return (
    <>
      <StyledSelect
        onChange={handleChange}
        options={operations}
        placeholder={"操作一覧"}
      />
      <Modal
        title="指定したファネルへの入稿用リンクの一括コピー"
        isOpen={isOpen}
        onClose={onClose}
      >
        <Suspense fallback={<PageLoading />}>
          <TransferSourceTagsModalContainer
            onClose={onClose}
            sourceTagIds={sourceTagIds}
            initialValues={currentValues}
          />
        </Suspense>
      </Modal>
    </>
  );
};
