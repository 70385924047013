import { Link, Text, VStack } from "@chakra-ui/react";
import { FC } from "react";

import { FileUploadField } from "~/src/components/features/uploads";
import { chakraFactory } from "~/src/lib/chakra-ui";

type Props = {
  url?: string;
};

const StyledLink = chakraFactory(Link, {
  baseStyle: {
    color: "#1867C0",
  },
});

export const QuestionCsvField: FC<Props> = ({ url }) => {
  return (
    <VStack alignItems={"flex-start"}>
      <FileUploadField name="questionCsv" accept="text/csv" />
      {url && url.length > 0 ? (
        <Text fontSize={"10px"}>
          <StyledLink href={url}>運用中のCSVをダウンロード</StyledLink>
        </Text>
      ) : (
        process.env.REACT_APP_API_ENDPOINT && (
          <Text fontSize={"10px"}>
            CSVファイルをアップロードしてください。サンプルは
            <StyledLink
              href={`${process.env.REACT_APP_API_ENDPOINT}/question_sample.csv`}
            >
              こちら
            </StyledLink>
            からダウンロードしてください。
          </Text>
        )
      )}
    </VStack>
  );
};
